import { AppointmentStaff, isStaffTeam } from "../../libs/booking";
import { MotionUser } from "../../gql/graphql";
import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";
import * as React from "react";

type Props = {
  staff: AppointmentStaff;
  staffById: Partial<Record<string, MotionUser>>;
};

/**
 * Renders the staff (member or team) information for an appointment.
 */
export default function AppointmentStaffInfo(props: Props) {
  const { staff, staffById } = props;
  const staffIds = isStaffTeam(staff)
    ? [staff.leadStaffId, staff.supportingStaffId]
    : [staff];

  return (
    <Box display="flex" gap={1} alignItems="center" minWidth={0}>
      <AvatarGroup>
        {staffIds.map((staffId) => {
          const staff = staffById[staffId];
          return (
            <Avatar
              key={staffId}
              src={staff?.avatarUrl || undefined}
              sx={{ width: 36, height: 36 }}
            >
              <Typography sx={{ fontSize: 16 }}>
                {(staff?.displayName || staffId)
                  .split(" ")
                  .map((n) => n[0])
                  .join("")}
              </Typography>
            </Avatar>
          );
        })}
      </AvatarGroup>
      <Box display="flex" flexDirection="column" minWidth={0}>
        {staffIds.map((staffId) => {
          const staff = staffById[staffId];
          return (
            <Box
              key={staffId}
              display="flex"
              gap={1}
              minWidth={0}
              alignItems="baseline"
            >
              <Typography
                variant="body1"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {staff?.displayName || staffId}
              </Typography>
              {!!staff?.credentialsAbbreviation && (
                <Typography variant="body2" color="textSecondary">
                  {staff.credentialsAbbreviation}
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
