/**
 * Utility functions for the new (2024) booking logic.
 */
import {
  AppointmentMedium,
  AppointmentType,
  MedicalAppointment,
  EventFieldsFragment,
  AvailableSlot,
  EffectiveTimeSlotFieldsFragment,
  PatientCommunicationPreferences,
  TargetWeek,
} from "../gql/graphql";
import { AppointmentConfigMap } from "./scheduleTemplates";
import { MotionWeekDay, overlaps } from "./time";
import { DateTime } from "luxon";
import { difference, some } from "lodash";

export type SearchParams = {
  appointmentType: AppointmentType;
  medium: AppointmentMedium;
  clinicIds: ReadonlySet<string>;
  multiDisciplinary?: boolean;
};

export function isSearchValid(
  params: Partial<SearchParams>,
): params is SearchParams {
  return (
    params.appointmentType !== undefined &&
    params.medium !== undefined &&
    params.clinicIds !== undefined &&
    (params.medium !== AppointmentMedium.InClinic || params.clinicIds.size > 0)
  );
}

/**
 * Represents a tentative selection when booking/rescheduling an appointment.
 */
export type SlotSelection = AvailableSlotSelection | ManualSlotSelection;

type AvailableSlotSelection = {
  type: "available";
  slot: AvailableSlot;
};

type ManualSlotSelection = {
  type: "manual";
  weekday: MotionWeekDay;
  week: TargetWeek;
};

export function isAvailableSlot(
  selection: SlotSelection,
): selection is AvailableSlotSelection {
  return selection.type === "available";
}

export function getAppointmentTypeLabel(
  appointmentType: AppointmentType,
  configs: AppointmentConfigMap,
  includeDuration: boolean = true,
): string {
  const config = configs[appointmentType];
  // Fallback to internal name if config is not available
  if (!config) {
    return getSunsettedAppointmentTypeLabel(appointmentType) ?? appointmentType;
  }
  // TODO(PFA-1167) non-nullable durationInMinutes
  const duration =
    includeDuration && !!config.durationInMinutes
      ? ` (${config.durationInMinutes} min)`
      : "";
  return config.internalName + duration;
}

/**
 * Get the label for an appointment type that has been sunsetted, or null if
 * none exists.
 * Needed to show a user-friendly label for historical appointments using
 * a configuration no longer returned by the API.
 */
function getSunsettedAppointmentTypeLabel(
  appointmentType: AppointmentType,
): string | null {
  switch (appointmentType) {
    case AppointmentType.External:
      return "External";
    case AppointmentType.HealthCoachSpecialist:
      return "Health Coach Specialist";
    case AppointmentType.HealthCoachSpecialistFollowup:
      return "Health Coach Specialist Follow-Up";
    case AppointmentType.L3Followup:
    case AppointmentType.L3WithMdFollowup:
    case AppointmentType.L3WithPaFollowup:
      return "L3 Follow-Up";
    case AppointmentType.Motion_360:
      return "Motion 360";
    case AppointmentType.OpioidTaperFollowupMd:
    case AppointmentType.OpioidTaperFollowupPa:
      return "Opioid Taper Follow-Up";
    case AppointmentType.OpioidTaperMd:
    case AppointmentType.OpioidTaperPa:
      return "Opioid Taper";
    case AppointmentType.PainCoach:
      return "Pain Coach";
    case AppointmentType.PainCoachFollowup:
      return "Pain Coach Follow-Up";
    case AppointmentType.Specialist:
      return "Specialist";
    case AppointmentType.L3WithMd:
    case AppointmentType.L3WithPa:
      return "L3";
    case AppointmentType.Md:
    case AppointmentType.Pa:
      return "New Patient";
    case AppointmentType.MdFollowup:
    case AppointmentType.PaFollowup:
      return "Follow-Up";
    default:
      return null;
  }
}

/**
 * The host of an appointment, either a single provider or a team.
 */
export type AppointmentStaff = string | StaffTeam;
export type StaffTeam = {
  leadStaffId: string;
  supportingStaffId: string;
};

export function isStaffTeam(staff: AppointmentStaff): staff is StaffTeam {
  return typeof staff !== "string";
}

export function toStaffTeamOrNull(
  staff: AppointmentStaff | null,
): StaffTeam | null {
  if (staff === null || typeof staff === "string") {
    return null;
  }
  return staff;
}

export function toStaffIdOrNull(staff: AppointmentStaff | null): string | null {
  if (staff === null || typeof staff !== "string") {
    return null;
  }
  return staff;
}

// ----------------------------------------------------
// -- Events (MedicalAppointment / NonPatientEvents) --
// ----------------------------------------------------
export function isMedicalAppointment(
  event: EventFieldsFragment,
): event is MedicalAppointment {
  return event.__typename === "MedicalAppointment";
}

// --------------------------
// -- Effective Time Slots --
// --------------------------
export function getEffectiveSlotName(
  slot: EffectiveTimeSlotFieldsFragment,
  appointmentTypeConfigurations: AppointmentConfigMap,
): string {
  if (slot.__typename === "EffectiveAppointmentSlot") {
    return getAppointmentTypeLabel(
      slot.appointmentType,
      appointmentTypeConfigurations,
      false,
    );
  }
  if (slot.__typename === "EffectiveUnavailableTimeSlot") {
    return slot.name;
  }
  return "unknown";
}

// -----------------------
// -- Booking conflicts --
// -----------------------
export enum BookingIssue {
  DoubleBooking,
  MultipleLocations,
}

/**
 * Validate a tentative appointment against existing events and time slots.
 */
export function findBookingIssues(
  tentativeAppointmentStart: DateTime,
  tentativeAppointmentLocationId: string | null,
  appointmentDurationInMinutes: number,
  leadEvents: EventFieldsFragment[],
  leadTimeSlots: EffectiveTimeSlotFieldsFragment[],
  supportingEvents?: EventFieldsFragment[],
): ReadonlySet<BookingIssue> {
  if (tentativeAppointmentStart < DateTime.now()) {
    // Retroactive appointment, no need to check for issues.
    return new Set();
  }
  const tentativeAppointment = {
    startTime: tentativeAppointmentStart.toUnixInteger(),
    endTime: tentativeAppointmentStart
      .plus({
        minutes: appointmentDurationInMinutes,
      })
      .toUnixInteger(),
  };

  const bookedOrScheduledLocations = new Set<string>();
  if (tentativeAppointmentLocationId) {
    bookedOrScheduledLocations.add(tentativeAppointmentLocationId);
  }

  const leadAndSupportingEvents = [...leadEvents, ...(supportingEvents ?? [])];
  const issues = new Set<BookingIssue>();
  if (
    some(leadAndSupportingEvents, (event) =>
      overlaps(tentativeAppointment, event),
    )
  ) {
    issues.add(BookingIssue.DoubleBooking);
  }

  leadEvents.forEach((event) => {
    if (isMedicalAppointment(event) && !!event.clinicId) {
      bookedOrScheduledLocations.add(event.clinicId);
    }
  });

  leadTimeSlots.forEach((slot) => {
    if (slot.__typename === "EffectiveAppointmentSlot" && !!slot.locationId) {
      bookedOrScheduledLocations.add(slot.locationId);
    }
  });

  if (bookedOrScheduledLocations.size > 1 && !!tentativeAppointmentLocationId) {
    issues.add(BookingIssue.MultipleLocations);
  }
  return issues;
}

export function allIssuesAcknowledged(
  bookingIssues: ReadonlySet<BookingIssue>,
  issuesAcknowledged: ReadonlySet<BookingIssue>,
): boolean {
  return (
    difference(Array.from(bookingIssues), Array.from(issuesAcknowledged))
      .length === 0
  );
}

/**
 * Check if a tentative appointment's location conflicts with the given
 * slot or event.
 */
export function locationConflicts(
  tentativeAppointmentLocation: string | null | undefined,
  slotOrEvent: EffectiveTimeSlotFieldsFragment | EventFieldsFragment,
): boolean {
  if (!tentativeAppointmentLocation) {
    return false;
  }

  if (slotOrEvent.__typename === "EffectiveAppointmentSlot") {
    return (
      !!slotOrEvent.locationId &&
      slotOrEvent.locationId !== tentativeAppointmentLocation
    );
  }

  if (slotOrEvent.__typename === "MedicalAppointment") {
    return (
      !!slotOrEvent.clinicId &&
      slotOrEvent.clinicId !== tentativeAppointmentLocation
    );
  }

  return false;
}

// ---------------------------
// -- Patient Notifications --
// ---------------------------
/**
 * Get the notifications that are relevant for the given operation.
 */
export function getNotificationSettings(
  operation: "create" | "reschedule" | "cancel",
  newAppointmentStart: DateTime | null,
  patientPreferences: PatientCommunicationPreferences,
): {
  relevantNotifications: Partial<Record<AppointmentNotificationType, boolean>>;
  notifiedVia: string[];
  disabledReason?: string;
} {
  const via = [
    ...(patientPreferences.consentsToEmails ? ["Email"] : []),
    ...(patientPreferences.consentsToSMS ? ["SMS"] : []),
  ];

  const disabledReason =
    via.length === 0
      ? "The patient does not consent to being contacted via email or SMS."
      : !!newAppointmentStart && newAppointmentStart < DateTime.now()
      ? "No notification will be sent for retroactive appointment operations."
      : undefined;

  if (operation === "cancel") {
    return {
      relevantNotifications: {
        [AppointmentNotificationType.CONFIRM_CANCEL]: !disabledReason,
      },
      notifiedVia: via,
      disabledReason,
    };
  }

  if (operation === "reschedule") {
    return {
      relevantNotifications: {
        [AppointmentNotificationType.CONFIRM_RESCHEDULE]: !disabledReason,
      },
      notifiedVia: via,
      disabledReason,
    };
  }

  return {
    relevantNotifications: {
      [AppointmentNotificationType.CONFIRM_NEW_APPOINTMENT]: !disabledReason,
      [AppointmentNotificationType.REMIND_24H]: !disabledReason,
      [AppointmentNotificationType.REMIND_1H]: !disabledReason,
    },
    notifiedVia: via,
    disabledReason,
  };
}

export enum AppointmentNotificationType {
  CONFIRM_NEW_APPOINTMENT = "CONFIRM_NEW_APPOINTMENT",
  CONFIRM_RESCHEDULE = "CONFIRM_RESCHEDULE",
  CONFIRM_CANCEL = "CONFIRM_CANCEL",
  REMIND_24H = "REMIND_24H",
  REMIND_1H = "REMIND_1H",
}
