import { AvailableSlot } from "../../../../../../../gql/graphql";
import {
  getHeightInPixels,
  MotionTimezone,
} from "../../../../../../../libs/time";
import { Box, Button, Popover, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import {
  AppointmentStaff,
  SearchParams,
} from "../../../../../../../libs/booking";
import { getAppointmentTypeColor } from "../../../../../../../libs/colors";
import { DateTime, Interval } from "luxon";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { grey } from "@mui/material/colors";
import {
  useCachedAppointmentTypeConfigurations,
  useCachedClinics,
  useCachedMotionStaff,
} from "../../../../../../../hooks/commonQueries";
import AppointmentMediumInfo from "../../../../../../AppointmentMediumInfo";
import AppointmentStaffInfo from "../../../../../../AppointmentStaffInfo";

type Props = {
  staff: AppointmentStaff;
  slot: AvailableSlot;
  searchParameters: SearchParams;
  timezone: MotionTimezone;
  onSelect: () => void;
};

/**
 * Renders a single available slot.
 */
export default function StaffAvailableSlot(props: Props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const heightInPixels = getHeightInPixels(props.slot);
  const dense = heightInPixels <= 30;

  const borderColor = !!anchorEl
    ? theme.palette.primary.main
    : getAppointmentTypeColor(props.searchParameters.appointmentType, "dark");

  return (
    <>
      <Box
        height={`${heightInPixels}px`}
        overflow="hidden"
        display="flex"
        alignItems="stretch"
        paddingX={1}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Box
          mb="1px"
          paddingY={dense ? 0 : 0.5}
          paddingX={0.5}
          border={`2px solid ${borderColor}`}
          borderRadius={1}
          bgcolor="#fff"
          flex={1}
          display="flex"
          gap={0.5}
          alignItems="flex-start"
          sx={{ cursor: "pointer" }}
          minWidth={0}
        >
          <EventAvailableIcon fontSize="small" sx={{ color: grey[500] }} />
          <Typography
            variant="body2"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {toShortTime(props.timezone, props.slot.startTime)}
          </Typography>
        </Box>
      </Box>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <SlotDetails
          staff={props.staff}
          slot={props.slot}
          timezone={props.timezone}
          searchParameters={props.searchParameters}
          onSelect={props.onSelect}
        />
      </Popover>
    </>
  );
}

function SlotDetails(props: {
  staff: AppointmentStaff;
  slot: AvailableSlot;
  timezone: MotionTimezone;
  searchParameters: SearchParams;
  onSelect: () => void;
}) {
  const appointmentTypeConfig = useCachedAppointmentTypeConfigurations(true);
  const appointmentType = props.searchParameters.appointmentType;
  const start = DateTime.fromSeconds(props.slot.startTime);
  const end = DateTime.fromSeconds(props.slot.endTime);

  return (
    <Box p={2} display="flex" flexDirection="column" gap={2} maxWidth={300}>
      <Typography variant="body1">
        {appointmentTypeConfig[appointmentType]?.internalName ||
          appointmentType}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {Interval.fromDateTimes(start, end).toLocaleString({
          weekday: "short",
          day: "2-digit",
          month: "short",
          hour: "numeric",
          minute: "numeric",
          timeZone: props.timezone,
          timeZoneName: "short",
        })}
      </Typography>
      <AppointmentStaffInfo
        staff={props.staff}
        staffById={useCachedMotionStaff()}
      />
      <AppointmentMediumInfo
        medium={props.searchParameters.medium}
        clinicId={props.slot.locationId || null}
        clinicsById={useCachedClinics()}
        typography="body2"
      />
      <Button variant="contained" onClick={props.onSelect}>
        Select and Continue
      </Button>
    </Box>
  );
}

function toShortTime(timezone: MotionTimezone, startTime: number) {
  const start = DateTime.fromSeconds(startTime, { zone: timezone });
  return start
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(":00", "")
    .replace(/\s(?=am|pm)/gi, "")
    .toLowerCase();
}
