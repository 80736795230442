import { AppointmentStaff, isStaffTeam } from "../../../../../../libs/booking";
import { DateTime, Duration } from "luxon";
import { Avatar, Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  getHeightInPixels,
  getTopPositionInPixels,
  maxScheduleHour,
  minScheduleHour,
  MotionTimezone,
} from "../../../../../../libs/time";
import GridLines from "../../../../../ManageSchedules/ScheduleDraftEditor/GridLines";
import React, { useEffect, useRef, useState } from "react";
import GridTimes from "../../../../../ManageSchedules/ScheduleDraftEditor/GridTimes";
import StaffScheduleDay from "../../StaffScheduleGrid/StaffScheduleDay";
import {
  AppointmentType,
  EffectiveTimeSlotFieldsFragment,
  EventFieldsFragment,
} from "../../../../../../gql/graphql";
import StaffMemberDailySlots from "./StaffMemberDailySlots";
import GridToastBase from "../../StaffScheduleGrid/GridToastBase";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useCachedMotionStaff } from "../../../../../../hooks/commonQueries";

type Props = {
  staff: AppointmentStaff;
  tentativeAppointmentStart: DateTime | null;
  tentativeAppointmentDuration: Duration;
  tentativeAppointmentLocationId: string | null;
  tentativeAppointmentType: AppointmentType;
  timezone: MotionTimezone;
  // Assumed to be sanitized and filtered by day
  leadStaffEvents: Array<EventFieldsFragment>;
  leadStaffTimeSlots: Array<EffectiveTimeSlotFieldsFragment>;
  rescheduledAppointmentId: string | undefined;
  // Assumed to be sanitized and filtered by day
  supportingStaffEvents: Array<EventFieldsFragment> | null;
  supportingStaffTimeSlots: Array<EffectiveTimeSlotFieldsFragment> | null;
  toast?: string | React.ReactNode;
};

const timeLabelsWidth = 55;

/**
 * Single-day view into a provider or team's full schedule.
 */
export default function DailyScheduleView(props: Props) {
  const tentativeAppointmentRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setIsScrolled(event.currentTarget.scrollTop > 10);
  };

  const tentativeAppointmentStartTime = props.tentativeAppointmentStart
    ? props.tentativeAppointmentStart.setZone(props.timezone).toUnixInteger()
    : null;
  const tentativeAppointment = tentativeAppointmentStartTime
    ? {
        startTime: tentativeAppointmentStartTime,
        endTime:
          tentativeAppointmentStartTime +
          props.tentativeAppointmentDuration.as("seconds"),
      }
    : null;

  useEffect(() => {
    if (props.tentativeAppointmentStart && tentativeAppointmentRef.current) {
      tentativeAppointmentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [props.tentativeAppointmentStart]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight={0}
      flex={1}
      position="relative"
    >
      {/*  Header, pinned */}
      <Box
        display="flex"
        p={0.5}
        borderBottom={isScrolled ? "1px solid #dedede" : "none"}
        position="relative"
      >
        <Box display="flex" width={timeLabelsWidth} justifyContent="center">
          <Tooltip
            arrow
            placement="right"
            title={
              "Patient appointments and blocked calendar times are visible in " +
              "the foreground, planned schedule slots are in the background."
            }
          >
            <IconButton size="small">
              <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
        <Header staff={props.staff} />
      </Box>
      {!!props.toast && (
        <Box
          position="absolute"
          top={50}
          left="50%"
          sx={{ transform: "translateX(-50%)" }}
          zIndex={1000}
        >
          {typeof props.toast === "string" ? (
            <GridToastBase>{props.toast}</GridToastBase>
          ) : (
            props.toast
          )}
        </Box>
      )}
      {/*  Time grid, scrollable */}
      <Box
        display="flex"
        minHeight={0}
        sx={{ overflowY: "auto" }}
        flex={1}
        pt={1}
        pb={2}
        onScroll={handleScroll}
      >
        <Box width={timeLabelsWidth}>
          <GridTimes startHour={minScheduleHour} endHour={maxScheduleHour} />
        </Box>
        <Box position="relative" flex={1}>
          <Box position="absolute" top={0} left={0} width="100%">
            <GridLines startHour={minScheduleHour} endHour={maxScheduleHour} />
          </Box>
          <Box position="absolute" top={0} left={0} width="100%">
            <Box display="flex" gap={0.5} paddingX={1}>
              <StaffMemberDailySlots
                minHour={minScheduleHour}
                maxHour={maxScheduleHour}
                timezone={props.timezone}
                slots={props.leadStaffTimeSlots}
                tentativeAppointmentLocationId={
                  props.tentativeAppointmentLocationId
                }
              />
              {props.supportingStaffTimeSlots && (
                <StaffMemberDailySlots
                  minHour={minScheduleHour}
                  maxHour={maxScheduleHour}
                  timezone={props.timezone}
                  slots={props.supportingStaffTimeSlots}
                />
              )}
            </Box>
          </Box>
          <StaffScheduleDay
            staff={props.staff}
            minHour={minScheduleHour}
            maxHour={maxScheduleHour}
            timezone={props.timezone}
            leadStaffEvents={props.leadStaffEvents}
            supportingStaffEvents={props.supportingStaffEvents}
            sideGutters={true}
            tentativeAppointmentLocationId={
              props.tentativeAppointmentLocationId
            }
            rescheduledAppointmentId={props.rescheduledAppointmentId}
            style="contrast"
          />
          {tentativeAppointment && (
            <Box
              position="absolute"
              top={getTopPositionInPixels(
                tentativeAppointment,
                props.timezone,
                minScheduleHour,
              )}
              left={0}
              right={0}
              paddingX={"15%"}
              ref={tentativeAppointmentRef}
              height={0}
              sx={{ pointerEvents: "none" }}
            >
              <Box
                height={`${getHeightInPixels(tentativeAppointment)}px`}
                border={(theme) => `3px dashed ${theme.palette.primary.main}`}
                borderRadius={1}
                boxSizing="border-box"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

function Header(props: { staff: AppointmentStaff }) {
  const { staff } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="center" flex={1}>
      <Box display="flex" justifyContent="space-evenly" alignSelf="stretch">
        {isStaffTeam(staff) ? (
          <>
            <AvatarWithTooltip staffId={staff.leadStaffId} />
            <AvatarWithTooltip staffId={staff.supportingStaffId} />
          </>
        ) : (
          <AvatarWithTooltip staffId={staff} />
        )}
      </Box>
    </Box>
  );
}

export function AvatarWithTooltip(props: {
  staffId: string;
  size?: "sm" | "xs";
}) {
  const { staffId } = props;
  const staffById = useCachedMotionStaff();
  const staffName =
    staffId in staffById ? staffById[staffId].displayName : staffId;
  const staffAvatarUrl =
    staffId in staffById ? staffById[staffId].avatarUrl : undefined;
  const sizeInPx = props.size === "xs" ? 24 : 30;
  const fontSize = props.size === "xs" ? 12 : 14;
  return (
    <Tooltip title={staffName}>
      <Avatar
        src={staffAvatarUrl ?? undefined}
        sx={{ width: sizeInPx, height: sizeInPx }}
      >
        <Typography sx={{ fontSize }}>
          {staffName
            .split(" ")
            .map((n) => n[0])
            .join("")}
        </Typography>
      </Avatar>
    </Tooltip>
  );
}
