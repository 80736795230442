// @flow
import React from "react";
import { ASSIGNED_PROVIDERS_QUERY } from "../../../../libs/queries";
import { Alert, Button, TextField } from "@mui/material";
import { Box, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { useQuery } from "@apollo/client";
import DetailedAlert from "../../../DetailedAlert";
import type {
  AssignedProvidersForPatientQuery,
  ExternalProvider,
  Patient,
} from "../../../../generated/flow_types";
import type { ApolloResult } from "../../../../flow-typed/apollo";
import {find} from "lodash";

type Props = {
  patient: Patient,
  selectedProviderId: ?string,
  onSelectedProviderChange: (provider: ExternalProvider) => void,
};

/** External provider selector for new appointment. */
export default function AppointmentProviderSelector(props: Props) {
  const {
    data,
    loading,
    error,
    refetch,
  }: ApolloResult<AssignedProvidersForPatientQuery> = useQuery(
    ASSIGNED_PROVIDERS_QUERY,
    {
      variables: { patientId: props.patient.id },
      notifyOnNetworkStatusChange: true,
    }
  );
  const assignedProviders = data?.assignedProvidersForPatient || [];

  const onSelectedProviderChange = (e) => {
    const providerId = e.target.value || null;
    const provider = find(assignedProviders, provider => provider.id === providerId);
    props.onSelectedProviderChange(provider);
  };

  const handleRefresh = () => {
    props.onSelectedProviderChange("");
    refetch();
  };

  if (error) {
    return (
      <DetailedAlert
        severity="error"
        message="Oops! Could not load the patient's assigned providers."
        retry={(_) => refetch()}
      />
    );
  }

  if (loading) {
    return (
      <TextField
        id="outlined-basic"
        label="External Provider"
        variant="outlined"
        defaultValue="Loading..."
        disabled={true}
        sx={{ width: "100%" }}
      />
    );
  }

  if (assignedProviders.length === 0) {
    return (
      <Alert severity="warning">
        This patient has no assigned provider in Welkin. You can only book an
        appointment with an assigned provider.
      </Alert>
    );
  }

  return (
    <Box sx={{ width: 1 }}>
      <FormControl sx={{ width: 1 }}>
        <InputLabel id="provider-label">External Provider</InputLabel>
        <Select
          labelId="provider-label"
          label="External Provider"
          value={props.selectedProviderId || ''}
          onChange={onSelectedProviderChange}
        >
          {assignedProviders.map((provider) => (
            <MenuItem key={provider.id} value={provider.id}>
              {provider.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        onClick={handleRefresh}
        style={{ marginTop: "8px", float: "right" }}
      >
        Refresh Practitioners
      </Button>
    </Box>
  );
}
