// @flow
import React from "react";
import { Route, Switch } from "react-router-dom";
import { AuthLanding } from "./components/AuthLanding/AuthLanding";
import App from "./App";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { motionMuiTheme } from "./theme";
import AuthManager from "./components/AuthManager/AuthManager";
import ApolloWrapper from "./components/ApolloWrapper";

/** Adds unsecure routes to the (secure) app. */
export const AppWrapper = () => {
  const Health = () => <>UP</>;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={motionMuiTheme}>
        <Switch>
          <Route path="/health" component={Health} />
          <Route path="/auth-landing" component={AuthLanding} />
          {/*Everything else requires authentication*/}
          <Route path="*" component={AppWithAuthentication} />
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const AppWithAuthentication = () => {
  return (
    <AuthManager>
      <ApolloWrapper>
        <App />
      </ApolloWrapper>
    </AuthManager>
  );
};
