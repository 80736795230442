import { ApolloError, useMutation } from "@apollo/client";
import { graphql } from "../gql";
import {
  AppointmentNotificationType,
  AppointmentStaff,
  BookingIssue,
  findBookingIssues,
  isStaffTeam,
  SearchParams,
} from "../libs/booking";
import { DateTime } from "luxon";
import {
  EffectiveTimeSlotFieldsFragment,
  EventFieldsFragment,
  TargetWeek,
} from "../gql/graphql";
import { useQuery } from "@apollo/client/react/hooks";
import {
  maxScheduleHour,
  minScheduleHour,
  MotionTimezone,
  MotionWeekDay,
  sanitizeAndGroupByDay,
  validateAndGroupByDay,
} from "../libs/time";
import { useMemo } from "react";
import { PATIENT_APPOINTMENTS } from "../components/PatientActions/PatientSchedule/UpcomingAppointments";

const BOOK_APPOINTMENT = graphql(`
  mutation BookNewAppointment(
    $patientId: String!
    $appointmentType: AppointmentType!
    $appointmentMedium: AppointmentMedium!
    $startTime: Long!
    $leadPractitionerId: String!
    $supportingPractitionerId: String
    $locationId: String
    $patientInstructions: String
    $ignoreDoubleBooking: Boolean!
    $ignoreLocationConflict: Boolean!
    $notifyPatient: Boolean!
    $remindPatient24h: Boolean!
    $remindPatient1h: Boolean!
  ) {
    bookNewAppointment(
      patientId: $patientId
      appointmentType: $appointmentType
      appointmentMedium: $appointmentMedium
      startTime: $startTime
      leadPractitionerId: $leadPractitionerId
      supportingPractitionerId: $supportingPractitionerId
      locationId: $locationId
      patientInstructions: $patientInstructions
      ignoreDoubleBooking: $ignoreDoubleBooking
      ignoreLocationConflict: $ignoreLocationConflict
      notifyPatient: $notifyPatient
      remindPatient24h: $remindPatient24h
      remindPatient1h: $remindPatient1h
    ) {
      ...MedicalAppointmentFields
    }
  }
`);

const RESCHEDULE_APPOINTMENT = graphql(`
  mutation RescheduleExistingAppointment(
    $appointmentId: String!
    $startTime: Long!
    $ignoreDoubleBooking: Boolean!
    $ignoreLocationConflict: Boolean!
    $notifyPatient: Boolean!
  ) {
    rescheduleExistingAppointment(
      appointmentId: $appointmentId
      startTime: $startTime
      ignoreDoubleBooking: $ignoreDoubleBooking
      ignoreLocationConflict: $ignoreLocationConflict
      notifyPatient: $notifyPatient
    ) {
      ...MedicalAppointmentFields
    }
  }
`);

/**
 * Utility hook for creating or rescheduling an appointment.
 * Abstracts the specifics of the mutation to the component.
 */
export function useBookOrRescheduleMutation(
  patientId: string,
  searchParameters: SearchParams,
  startTime: DateTime | null,
  staff: AppointmentStaff,
  locationId: string | null,
  patientInstructions: string | null,
  issuesAcknowledged: ReadonlySet<BookingIssue>,
  notifications: Partial<Record<AppointmentNotificationType, boolean>>,
  rescheduledAppointmentId?: string,
): {
  loading: boolean;
  error?: ApolloError;
  bookOrReschedule: () => Promise<any>;
} {
  const [reschedule, { loading: rescheduleLoading, error: rescheduleError }] =
    useMutation(RESCHEDULE_APPOINTMENT, {
      variables: {
        appointmentId: rescheduledAppointmentId || "shouldNotBeUsed",
        startTime: startTime?.toUnixInteger() || 0,
        ignoreDoubleBooking: issuesAcknowledged.has(BookingIssue.DoubleBooking),
        ignoreLocationConflict: issuesAcknowledged.has(
          BookingIssue.MultipleLocations,
        ),
        notifyPatient:
          !!notifications[AppointmentNotificationType.CONFIRM_RESCHEDULE],
      },
      refetchQueries: [
        {
          query: PATIENT_APPOINTMENTS,
          variables: { patientId, includePastAppointments: false },
        },
      ],
    });

  const leadPractitionerId = isStaffTeam(staff) ? staff.leadStaffId : staff;
  const supportingPractitionerId = isStaffTeam(staff)
    ? staff.supportingStaffId
    : null;

  const [book, { loading: bookLoading, error: bookError }] = useMutation(
    BOOK_APPOINTMENT,
    {
      variables: {
        patientId,
        appointmentType: searchParameters.appointmentType,
        appointmentMedium: searchParameters.medium,
        startTime: startTime?.toUnixInteger() || 0,
        leadPractitionerId: leadPractitionerId,
        supportingPractitionerId: supportingPractitionerId,
        locationId,
        patientInstructions: patientInstructions || null,
        ignoreDoubleBooking: issuesAcknowledged.has(BookingIssue.DoubleBooking),
        ignoreLocationConflict: issuesAcknowledged.has(
          BookingIssue.MultipleLocations,
        ),
        notifyPatient:
          !!notifications[AppointmentNotificationType.CONFIRM_NEW_APPOINTMENT],
        remindPatient24h:
          !!notifications[AppointmentNotificationType.REMIND_24H],
        remindPatient1h: !!notifications[AppointmentNotificationType.REMIND_1H],
      },
      refetchQueries: [
        {
          query: PATIENT_APPOINTMENTS,
          variables: { patientId, includePastAppointments: false },
        },
      ],
    },
  );

  if (!startTime) {
    // Not ready to call
    return {
      loading: false,
      error: undefined,
      bookOrReschedule: () => Promise.resolve(),
    };
  }
  return {
    loading: rescheduledAppointmentId ? rescheduleLoading : bookLoading,
    error: rescheduledAppointmentId ? rescheduleError : bookError,
    bookOrReschedule: rescheduledAppointmentId ? reschedule : book,
  };
}

graphql(`
  fragment EffectiveTimeSlotFields on EffectiveTimeSlot {
    startTime
    endTime
    ... on EffectiveAppointmentSlot {
      appointmentType
      locationId
      supportedMediums
    }
    ... on EffectiveUnavailableTimeSlot {
      endTime
      name
      startTime
    }
  }
`);

// Combined query to retrieve everything we need for a single provider
export const GET_PROVIDER_EVENTS_SCHEDULE = graphql(`
  query GetProviderEventsAndSchedule($staffId: String!, $week: TargetWeek!) {
    providerWeeklyEvents(staffId: $staffId, week: $week) {
      ...EventFields
    }
    providerEffectiveTimeSlots(staffId: $staffId, week: $week) {
      ...EffectiveTimeSlotFields
    }
  }
`);

/**
 * Utility hook to retrieve the daily schedule for the given staff member(s).
 * Handles single and multi-provider teams.
 * Events are sanitized, time slots are validated.
 */
export function useDailyScheduleInfo(
  staff: AppointmentStaff,
  week: TargetWeek,
  day: MotionWeekDay,
  timezone: MotionTimezone,
  tentativeAppointmentStartTime: DateTime | null,
  tentativeLocationId: string | null,
  appointmentDurationInMinutes: number,
  fetchPolicy?: "cache-first" | "cache-and-network" | "cache-only",
  minHour: number = minScheduleHour,
  maxHour: number = maxScheduleHour,
): {
  loading: boolean;
  error?: ApolloError;
  data: {
    leadStaffEvents?: EventFieldsFragment[];
    leadTimeSlots?: EffectiveTimeSlotFieldsFragment[];
    supportingStaffEvents?: EventFieldsFragment[] | null;
    supportingStaffTimeSlots?: EffectiveTimeSlotFieldsFragment[] | null;
    bookingIssues: ReadonlySet<BookingIssue> | null;
  };
  refetch: () => Promise<void>;
} {
  const leadPractitionerId = isStaffTeam(staff) ? staff.leadStaffId : staff;
  const supportingPractitionerId = isStaffTeam(staff)
    ? staff.supportingStaffId
    : null;

  const {
    data: leadData,
    loading: leadLoading,
    error: leadError,
    refetch: leadRefetch,
  } = useQuery(GET_PROVIDER_EVENTS_SCHEDULE, {
    variables: {
      staffId: leadPractitionerId,
      week: week,
    },
    fetchPolicy: fetchPolicy,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: supportingData,
    loading: supportingLoading,
    error: supportingError,
    refetch: supportingRefetch,
  } = useQuery(GET_PROVIDER_EVENTS_SCHEDULE, {
    skip: !supportingPractitionerId,
    variables: {
      staffId: supportingPractitionerId || "skippedIfNull",
      week: week,
    },
    fetchPolicy: fetchPolicy,
    notifyOnNetworkStatusChange: true,
  });

  const dailyDataLead = useMemo(
    () => compileForDay(week, day, timezone, leadData, minHour, maxHour),
    [leadData, day, timezone],
  );
  const dailyDataSupporting = useMemo(
    () => compileForDay(week, day, timezone, supportingData, minHour, maxHour),
    [supportingData, day, timezone],
  );
  const bookingIssues = useMemo(() => {
    if (!dailyDataLead || !tentativeAppointmentStartTime) {
      return null;
    }
    return findBookingIssues(
      tentativeAppointmentStartTime,
      tentativeLocationId,
      appointmentDurationInMinutes,
      dailyDataLead.events,
      dailyDataLead.timeSlots,
      dailyDataSupporting?.events,
    );
  }, [
    dailyDataLead,
    tentativeAppointmentStartTime,
    tentativeLocationId,
    appointmentDurationInMinutes,
  ]);

  const refetch = async () => {
    if (supportingPractitionerId) {
      await Promise.all([leadRefetch(), supportingRefetch()]);
    } else {
      await leadRefetch();
    }
  };

  return {
    loading: leadLoading || supportingLoading,
    error: leadError || supportingError,
    data: {
      leadStaffEvents: dailyDataLead?.events,
      leadTimeSlots: dailyDataLead?.timeSlots,
      supportingStaffEvents: dailyDataSupporting?.events,
      supportingStaffTimeSlots: dailyDataSupporting?.timeSlots,
      bookingIssues: bookingIssues,
    },
    refetch,
  };
}

/**
 * Compile the daily schedule from the provider's weekly events and time slots.
 * Events are sanitized.
 */
function compileForDay(
  week: TargetWeek,
  day: MotionWeekDay,
  timezone: MotionTimezone,
  data:
    | {
        providerWeeklyEvents: EventFieldsFragment[];
        providerEffectiveTimeSlots: EffectiveTimeSlotFieldsFragment[];
      }
    | undefined,
  minHour: number,
  maxHour: number,
) {
  if (!data) {
    return data;
  }
  return {
    events:
      sanitizeAndGroupByDay(
        data.providerWeeklyEvents,
        week,
        timezone,
        minHour,
        maxHour,
      )[day] || [],
    // Time slots shouldn't need sanitization but might fall outside the
    // min/max hours because of timezone differences.
    timeSlots:
      validateAndGroupByDay(
        data.providerEffectiveTimeSlots,
        week,
        timezone,
        minHour,
        maxHour,
      )[day] || [],
  };
}
