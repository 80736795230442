import React from "react";
import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Link,
  Grid,
  Breadcrumbs,
} from "@mui/material";
import { filter, sortBy } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Alert } from "@mui/material";
import DetailedAlert from "../../../DetailedAlert";
import { grey } from "@mui/material/colors";
import { DateTime } from "luxon";
import PatientCommunicationAlerts from "../PatientCommunicationAlerts";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { maxPatientScreenWidth } from "../../../../theme";
import { Patient } from "../../../../gql/graphql";
import { graphql } from "../../../../gql";
import { enableNewBooking } from "../../../../config";
import { AppointmentDescription } from "../../Common/AppointmentDescription";

type Props = {
  patient: Patient;
};

const styles = {
  head: {
    backgroundColor: grey[200],
    fontWeight: "bold",
  },
};

graphql(`
  fragment AppointmentHostInfoFields on AppointmentHostInfo {
    ... on StaffMemberHostInfo {
      staff {
        id
        displayName
        practitionerType
      }
      supportingStaff {
        id
        displayName
        practitionerType
      }
    }
    ... on ExternalProviderHostInfo {
      provider {
        id
        displayName
      }
      timezone
    }
  }
`);

graphql(`
  fragment MedicalAppointmentFields on MedicalAppointment {
    id
    description
    appointmentType
    appointmentMedium
    startTime
    endTime
    hostInfo {
      ...AppointmentHostInfoFields
    }
    externalNotes
    clinicId
    cancelled
  }
`);

export const PATIENT_APPOINTMENTS = graphql(`
  query patientAppointmentsAdmin(
    $patientId: String!
    $includePastAppointments: Boolean!
  ) {
    appointmentsForPatientAdmin(
      patientId: $patientId
      includePastAppointments: $includePastAppointments
    ) {
      ...MedicalAppointmentFields
    }
  }
`);

export default function UpcomingAppointments(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { data, loading, error, refetch } = useQuery(PATIENT_APPOINTMENTS, {
    variables: {
      patientId: props.patient.id,
      includePastAppointments: false,
    },
    notifyOnNetworkStatusChange: true,
  });

  const renderHeader = () => {
    const handleCreateAction = () => {
      if (enableNewBooking()) {
        history.push(`${location.pathname}/create`);
      } else {
        history.push(`${location.pathname}/new`);
      }
    };

    return (
      <Box mb={1}>
        {query.get("booked") === "success" && (
          <Box mb={1}>
            <Alert
              severity="success"
              onClose={(_) => history.push(location.pathname)}
            >
              Appointment booked!
            </Alert>
          </Box>
        )}
        {query.get("canceled") === "success" && (
          <Box mb={1}>
            <Alert
              severity="success"
              onClose={(_) => history.push(location.pathname)}
            >
              Appointment canceled
            </Alert>
          </Box>
        )}
        <Grid container mb={1}>
          <Grid item xs={6}>
            <Typography variant="h6" color="primary" sx={{ marginBottom: 2 }}>
              Upcoming Appointments
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <Button
                color="primary"
                aria-label="Create appointment"
                onClick={handleCreateAction}
                variant="contained"
              >
                <AddIcon /> New Appointment
              </Button>
            </Box>
          </Grid>
        </Grid>
        <PatientCommunicationAlerts patient={props.patient} />
      </Box>
    );
  };

  if (loading) {
    return (
      <BreadcrumbWrapper>
        {renderHeader()}
        <CircularProgress />
      </BreadcrumbWrapper>
    );
  }

  if (error) {
    return (
      <BreadcrumbWrapper>
        {renderHeader()}
        <DetailedAlert
          message="Oops! Failed to load appointments. Please try again."
          retry={() => refetch()}
          additionalDetails={error}
        />
      </BreadcrumbWrapper>
    );
  }

  const sortedAppointments = sortBy(
    data?.appointmentsForPatientAdmin,
    (a) => a.startTime,
  );

  return (
    <BreadcrumbWrapper>
      {renderHeader()}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.head}>Date ▴</TableCell>
              <TableCell sx={styles.head}>Description</TableCell>
              <TableCell sx={styles.head} />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAppointments.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <i>No upcoming appointments</i>
                </TableCell>
              </TableRow>
            )}
            {sortedAppointments.map((appointment) => (
              <TableRow key={appointment.id}>
                <TableCell>
                  <FormattedDateTime
                    unixTime={appointment.startTime}
                    timezone={
                      props.patient.communicationPreferences.timezone ||
                      "America/New_York"
                    }
                  />
                </TableCell>
                <TableCell>
                  <AppointmentDescription appointment={appointment} />
                </TableCell>
                <TableCell>
                  <Link
                    component={NavLink}
                    href={`${location.pathname}/${appointment.id}`}
                    to={`${location.pathname}/${appointment.id}`}
                  >
                    view/manage
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 3 }}>
        <PastAppointments
          patientId={props.patient.id}
          patientTimezone={
            props.patient.communicationPreferences.timezone ||
            "America/New_York"
          }
        />
      </Box>
    </BreadcrumbWrapper>
  );
}

type PastAppointmentProps = {
  patientId: string;
  patientTimezone: string;
};

const PastAppointments = (props: PastAppointmentProps) => {
  const location = useLocation();
  const { data, loading, error, refetch } = useQuery(PATIENT_APPOINTMENTS, {
    variables: {
      patientId: props.patientId,
      includePastAppointments: true,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-only",
    nextFetchPolicy: "cache-first",
  });

  if (loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <DetailedAlert
        message="Oops! Failed to load past appointments. Please try again."
        retry={() => refetch()}
        additionalDetails={error}
      />
    );
  }

  if (!data) {
    return (
      <Button onClick={(_) => refetch()} size="small">
        Show Past Appointments
      </Button>
    );
  }

  const sortedPastAppointments = sortBy(
    filter(
      data?.appointmentsForPatientAdmin,
      (a) => DateTime.fromSeconds(a.endTime) < DateTime.now(),
    ),
    (a) => a.startTime,
  );

  if (!sortedPastAppointments.length) {
    return (
      <Typography variant="body2" style={{ color: "grey" }}>
        No past appointments
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
        Past Appointments
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.head}>Date ▴</TableCell>
              <TableCell sx={styles.head}>Description</TableCell>
              <TableCell sx={styles.head}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPastAppointments.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <i>No past appointments</i>
                </TableCell>
              </TableRow>
            )}
            {sortedPastAppointments.map((appointment) => (
              <TableRow key={appointment.id}>
                <TableCell>
                  <FormattedDateTime
                    unixTime={appointment.startTime}
                    timezone={props.patientTimezone || "America/New_York"}
                  />
                </TableCell>
                <TableCell>
                  <AppointmentDescription appointment={appointment} />
                </TableCell>
                <TableCell>
                  <Link
                    component={NavLink}
                    href={`${location.pathname}/${appointment.id}`}
                    to={`${location.pathname}/${appointment.id}`}
                  >
                    view details
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

function FormattedDateTime(props: { unixTime: number; timezone: string }) {
  return (
    <>
      {DateTime.fromSeconds(props.unixTime)
        .setZone(props.timezone)
        .toFormat("fff")}
    </>
  );
}

function BreadcrumbWrapper(props: { children: React.ReactNode }) {
  return (
    <Box
      display="flex"
      flex={1}
      sx={{ overflowY: "auto" }}
      justifyContent="center"
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        flex={1}
        maxWidth={maxPatientScreenWidth}
      >
        <Breadcrumbs>
          <Link
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            component="button"
            color="inherit"
            underline="none"
          >
            <CalendarMonthOutlinedIcon sx={{ height: 18, width: 18 }} />
            Schedule
          </Link>
        </Breadcrumbs>
        <Paper
          sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 1 }}
        >
          {props.children}
        </Paper>
      </Box>
    </Box>
  );
}
