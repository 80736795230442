// @flow
import React from "react";
import type {Assessment} from "../../../../generated/flow_types";
import {Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import VisibilityChip from "../VisibilityChip";
import {DateTime} from "luxon";
import {grey} from "@mui/material/colors";
import {useHistory, useRouteMatch} from "react-router-dom";

type Props = {
  sortedCompletedAssessments: Array<Assessment>,
};

export default function CompletedAssessments(props: Props) {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={styles.header}>
              Assessment
            </TableCell>
            <TableCell style={styles.header}>
              Shared With
            </TableCell>
            <TableCell style={styles.header}>
              Completed On ▼
            </TableCell>
            <TableCell style={styles.header}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.sortedCompletedAssessments.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="body2" color="text.secondary">
                  No assessments completed
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            props.sortedCompletedAssessments.map(assessment => (
              <TableRow key={assessment.id}>
                <TableCell>
                  {assessment.name}
                </TableCell>
                <TableCell>
                  <VisibilityChip patientFacing={!!assessment.patientAccessToken}/>
                </TableCell>
                <TableCell>
                  {DateTime.fromSeconds(assessment.updatedAt).toLocaleString(DateTime.DATETIME_MED)}
                </TableCell>
                <TableCell>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={_ => history.push(`/patient/${match.params.patientId}/assessments/${assessment.id}`)}
                  >
                    Review
                  </Link>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const styles = {
  header: {
    backgroundColor: grey[200],
    fontWeight: "bold"
  },
};
