// @flow checked 01/24/23
import React from "react";
import { useQuery } from "@apollo/client/react/hooks";
import _, { keyBy } from "lodash";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DetailedAlert from "../../../../DetailedAlert";
import { GET_APPOINTMENT_TYPE_CONFIGURATIONS } from "../../../../../libs/queries";
import type {
  AppointmentMedium,
  AppointmentType,
  AppointmentTypeConfiguration,
  PractitionerType,
} from "../../../../../generated/flow_types";
import { getPractitionerTypeName } from "../../../../../libs/scheduling";
import AppointmentMediumLabel from "../../AppointmentMediumLabel";
import SelectAppointmentType from "../../../../SelectAppointmentType";

type Props = {
  appointmentConfig: ?AppointmentTypeConfiguration,
  onAppointmentConfigChange: (AppointmentTypeConfiguration) => void,
  appointmentMedium: ?AppointmentMedium,
  onAppointmentMediumChange: (AppointmentMedium) => void,
  supportingPractitionerType: ?PractitionerType,
  onSupportingPractitionerTypeChange: (?PractitionerType) => void,
};

const FirstStep = (props: Props) => {
  const { data, error, loading, refetch } = useQuery(
    GET_APPOINTMENT_TYPE_CONFIGURATIONS,
  );

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (error || !data) {
    return (
      <DetailedAlert
        message="Oops! Failed to get Appointment Types. Please try again."
        retry={() => refetch()}
        additionalDetails={error}
      />
    );
  }

  const configByAppointmentType = keyBy(
    data?.appointmentTypeConfigurations,
    (config) => config.appointmentType,
  );

  const handleAppointmentTypeChange = (type: AppointmentType) => {
    props.onAppointmentConfigChange(configByAppointmentType[type]);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectAppointmentType
            beta={false}
            value={props.appointmentConfig?.appointmentType || null}
            onChange={handleAppointmentTypeChange}
          />
        </Grid>
        <Grid item xs={6}>
          {props.appointmentConfig && (
            <>
              <Grid container alignItems="center">
                <Tooltip
                  arrow
                  color="primary"
                  placement="top"
                  title="Based on Appointment Type"
                >
                  <IconButton>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Typography
                  color="primary"
                  style={{ fontSize: 20, fontWeight: 600 }}
                  variant="p"
                >
                  Lead Practitioner Type
                </Typography>
              </Grid>
              <Typography color="primary" mt={1} pl={1} variant="p">
                {getPractitionerTypeName(
                  props.appointmentConfig?.leadPractitionerType,
                )}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel
              htmlFor="demo-simple-select-helper3"
              id="demo-simple-select-helper-label3"
            >
              Appointment Medium
            </InputLabel>
            <Select
              disabled={!props.appointmentConfig}
              labelId="demo-simple-select-helper-label3"
              id="demo-simple-select-helper3"
              label="Appointment Medium"
              name="appointmentMedium"
              value={props.appointmentMedium || ""}
            >
              {props.appointmentConfig?.supportedMediums?.map((medium) => (
                <MenuItem
                  key={medium}
                  onClick={() => props.onAppointmentMediumChange(medium)}
                  value={medium}
                >
                  <AppointmentMediumLabel
                    appointmentMedium={medium}
                    iconOnly={false}
                  />
                </MenuItem>
              ))}
            </Select>
            <InputLabel id="demo-simple-select-helper-label3">
              Appointment Medium
            </InputLabel>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {!!props.appointmentConfig?.supportingPractitionerTypes?.length && (
            <FormControl fullWidth>
              <InputLabel
                htmlFor="demo-simple-select-helper3"
                id="demo-simple-select-helper-label4"
              >
                Supporting Practitioner Type (Optional)
              </InputLabel>
              <Select
                disabled={!props.appointmentConfig}
                labelId="demo-simple-select-helper-label4"
                id="demo-simple-select-helper3"
                label="Supporting Practitioner Type (Optional)"
                value={props.supportingPractitionerType || ""}
              >
                <MenuItem
                  onClick={() => props.onSupportingPractitionerTypeChange(null)}
                  value={""}
                >
                  None
                </MenuItem>
                {props.appointmentConfig.supportingPractitionerTypes.map(
                  (type) => (
                    <MenuItem
                      key={type}
                      onClick={() =>
                        props.onSupportingPractitionerTypeChange(type)
                      }
                      value={type}
                    >
                      {getPractitionerTypeName(type)}
                    </MenuItem>
                  ),
                )}
              </Select>
              <InputLabel id="demo-simple-select-helper-label4">
                Supporting Practitioner Type (Optional)
              </InputLabel>
            </FormControl>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FirstStep;
