import * as React from "react";
import { useContext } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Patient } from "../../../gql/graphql";
import {
  ExperimentalFeature,
  useExperimentalFeatures,
} from "../../../hooks/experimentalFeatures";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { SendbirdConversationContext } from "../../sendbird/SendbirdConversationProvider/SendbirdConversationProvider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import SelfImprovementOutlinedIcon from "@mui/icons-material/SelfImprovementOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";

type PatientDrawerProps = {
  patient: Patient;
};

export default function PatientDrawer(props: PatientDrawerProps) {
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <Paper
      square
      sx={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <Box px={2} py={3} sx={{ minWidth: 180, width: "15vw", maxWidth: 280 }}>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ wordBreak: "break-word" }}
        >
          {props.patient.firstName} {props.patient.lastName}
        </Typography>
      </Box>
      <Divider />
      <List sx={{ paddingTop: 0, overflowY: "auto" }}>
        {getPatientRoutes().map((route) => (
          <ListItem key={route.path} disablePadding>
            <ListItemButton
              selected={route.active}
              onClick={(_) => history.push(`${match.url}/${route.path}`)}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText>{route.label}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

type PatientRoute = {
  path: string;
  label: string;
  icon: React.ReactNode;
  active: boolean;
};
function getPatientRoutes(): PatientRoute[] {
  const { checkExperimentalFeatureEnabled } = useExperimentalFeatures();
  const location = useLocation();
  const currentLocation = location.pathname;

  const conversationContext = useContext(SendbirdConversationContext);
  const unreadMessageCount = conversationContext?.unreadMessageCount;

  return [
    {
      path: "info",
      label: "Info",
      icon: <InfoOutlinedIcon />,
    },
    {
      path: "chat",
      label: unreadMessageCount ? `Chat (${unreadMessageCount})` : "Chat",
      icon: <ChatOutlinedIcon />,
    },
    {
      path: "exercises",
      label: "Exercises",
      icon: <SelfImprovementOutlinedIcon />,
    },
    {
      path: "schedule",
      label: "Schedule",
      icon: <CalendarMonthOutlinedIcon />,
    },
    {
      path: "assessments",
      label: "Assessments",
      icon: <AssignmentOutlinedIcon />,
    },
    {
      path: "records",
      label: "Records",
      icon: <DescriptionOutlinedIcon />,
      hidden: !checkExperimentalFeatureEnabled(
        ExperimentalFeature.patientRecords,
      ),
    },
    {
      path: "visit-notes",
      label: "Visit Notes",
      icon: <NoteAltOutlinedIcon />,
    },
  ]
    .filter((route) => !route.hidden)
    .map((route) => ({
      ...route,
      active: currentLocation.indexOf(`/${route.path}`) > 0,
    }));
}
