import * as React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Snackbar,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useQuery } from "@apollo/client/react/hooks";
import { useMutation } from "@apollo/client";
import {
  CLAIM_CONVERSATION,
  CLOSE_CONVERSATION,
} from "../../../../libs/queries";
import { useEffect } from "react";
import DetailedAlert from "../../../DetailedAlert";
import { CONVERSATION } from "../index";
import InfoIcon from "@mui/icons-material/Info";
import { DateTime } from "luxon";
import { ResolveConversationWarningModal } from "./ResolveConversationWarningModal";
import {
  ConversationQuery,
  ConversationQueryVariables,
} from "../../../../gql/graphql";
import { useAuthentication } from "../../../../hooks/authHooks";

type Props = {
  memberId: string;
  poll: boolean;
};

export const ManageConversationButton = (props: Props) => {
  const { session } = useAuthentication();
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & Element) | null
  >(null);
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const { data, error, loading, refetch } = useQuery<
    ConversationQuery,
    ConversationQueryVariables
  >(CONVERSATION, {
    variables: {
      memberId: props.memberId,
    },
    pollInterval: props.poll ? 10000 : undefined,
  });
  const [claimConversation, { error: claimError, loading: claimLoading }] =
    useMutation(CLAIM_CONVERSATION, {
      variables: {
        memberId: props.memberId,
      },
      refetchQueries: [
        {
          query: CONVERSATION,
          variables: { memberId: props.memberId },
        },
      ],
      awaitRefetchQueries: true,
    });
  const [closeConversation, { error: closeError, loading: closeLoading }] =
    useMutation(CLOSE_CONVERSATION, {
      variables: {
        memberId: props.memberId,
      },
      refetchQueries: [
        {
          query: CONVERSATION,
          variables: { memberId: props.memberId },
        },
      ],
      awaitRefetchQueries: true,
    });

  const [errorToDisplay, setErrorToDisplay] = React.useState<string | null>(
    null,
  );
  useEffect(() => {
    if (claimError) {
      setErrorToDisplay("Failed to claim conversation. Please try again");
    }
    if (closeError) {
      setErrorToDisplay("Failed to resolve conversation. Please try again");
    }
  }, [claimError, closeError]);

  const currentSub = session?.getIdToken()?.payload?.sub;
  const currentUserIsOwner =
    !!currentSub && data?.conversation?.ownerId === currentSub;
  const conversationResolved = !data?.conversation?.state;

  const handleClick: React.MouseEventHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClaim = () => {
    if (currentUserIsOwner) {
      return;
    }
    setAnchorEl(null);
    claimConversation().catch((_) => {});
  };

  const handleClose = () => {
    if (!data?.conversation?.state) {
      return;
    }
    setAnchorEl(null);
    closeConversation().catch((_) => {});
  };

  const onClickResolve = async () => {
    const { data } = await refetch();
    if (!data?.conversation?.lastMotionMessageTime) {
      handleClose();
      return;
    }

    /** We need to show a modal warning the user that no text reminders will be sent to the patient if:
      - The last message is Motion-sent and
      - The last message was sent after the lastMemberReadTime
      - We’re still within the 72h window of reminders being sent
    */
    const minDate = DateTime.fromMillis(0);
    const conversation = data!.conversation!;
    const lastMotionMessageTime = DateTime.fromISO(
      conversation.lastMotionMessageTime!,
    );
    const lastMemberMessageTime = conversation.lastMemberMessageTime
      ? DateTime.fromISO(conversation.lastMemberMessageTime)
      : minDate;
    const lastMemberReadTime = conversation.lastMemberReadTime
      ? DateTime.fromISO(conversation.lastMemberReadTime)
      : minDate;

    const mostRecentMessageIsFromMotion =
      lastMotionMessageTime > lastMemberMessageTime;
    const lastMessageWasSentAfterLastMemberReadTime =
      mostRecentMessageIsFromMotion &&
      lastMotionMessageTime > lastMemberReadTime;
    const within72hReminderWindow =
      mostRecentMessageIsFromMotion &&
      lastMotionMessageTime.diffNow("hours").hours < 72;

    const memberMightHaveUnreadMessage =
      lastMessageWasSentAfterLastMemberReadTime && within72hReminderWindow;

    if (memberMightHaveUnreadMessage) {
      setShowWarningModal(true);
    } else {
      handleClose();
    }
  };

  if (error) {
    return (
      <DetailedAlert
        message="Failed to load conversation"
        additionalDetails={error}
        severity="error"
        retry={() => refetch().catch((_) => {})}
      />
    );
  }

  return (
    <Box display="flex" flexDirection="row" gap={1} alignItems="center">
      {!!data?.conversation?.ownerName && (
        <Typography variant="body2" color="text.secondary">
          Claimed by: {currentUserIsOwner ? "You" : data.conversation.ownerName}
        </Typography>
      )}
      <LoadingButton
        loading={!data || claimLoading || closeLoading}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant="contained"
      >
        Manage Conversation
      </LoadingButton>
      {showWarningModal && (
        <ResolveConversationWarningModal
          submit={handleClose}
          goBack={() => setShowWarningModal(false)}
        />
      )}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={(_) => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box>
          <Box
            p={1}
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
          >
            <InfoIcon color="info" />
            <Box pr={1}>
              <Typography variant="body2" color="text.secondary">
                Status: {conversationResolved ? "Resolved" : "Active"}
              </Typography>
              {!conversationResolved && (
                <>
                  <Typography variant="body2" color="text.secondary">
                    Claimed by: {data?.conversation?.ownerName || "No one"}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Last patient read:{" "}
                    {!!data?.conversation?.lastMemberReadTime
                      ? DateTime.fromISO(
                          data.conversation.lastMemberReadTime,
                        ).toRelative()
                      : "Unknown"}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
          <Divider />
          <List>
            <ListItem disablePadding={true}>
              <ListItemButton
                disabled={currentUserIsOwner}
                onClick={handleClaim}
              >
                <ListItemText
                  primary="Claim"
                  secondary={
                    conversationResolved
                      ? "This will reopen the conversation"
                      : null
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding={true} onClick={onClickResolve}>
              <ListItemButton
                sx={{ paddingRight: 10 }}
                disabled={!data?.conversation?.state}
              >
                <ListItemText primary="Resolve" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Popover>
      <Snackbar
        open={!!errorToDisplay}
        autoHideDuration={3000}
        onClose={(_) => setErrorToDisplay(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">{errorToDisplay}</Alert>
      </Snackbar>
    </Box>
  );
};
