import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { formsBaseUrl } from "../../../../config";
import { grey } from "@mui/material/colors";
import { graphql } from "../../../../gql";
import { useLazyQuery } from "@apollo/client";
import { useSearchParams } from "../../../../hooks/searchParams";
import DetailedAlert from "../../../DetailedAlert";

type Props = {
  onAssessmentChange: () => void;
};

const GET_ASSESSMENT_NAME = graphql(`
  query GetAssessmentName($assessmentId: String!) {
    assessment(assessmentId: $assessmentId) {
      id
      name
    }
  }
`);

/**
 * Displays an embedded assessment.
 */
export default function EmbeddedAssessment(props: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [getAssessmentName, { data, loading, error }] =
    useLazyQuery(GET_ASSESSMENT_NAME);
  const [dirty, setDirty] = React.useState(false);
  const [showConfirmClose, setShowConfirmClose] = React.useState(false);

  useEffect(() => {
    if (!searchParams.assessmentId) {
      return;
    }
    // Fetch assessment name
    getAssessmentName({
      variables: { assessmentId: searchParams.assessmentId },
    }).then(/* ignore promise results */);

    // Set up event listeners
    const assessmentEventListener = (event: MessageEvent) => {
      if (event.origin !== formsBaseUrl()) {
        return;
      }
      console.log("assessment event received", event);
      if (event.data.type === "com.motion.assessment_page_submitted") {
        props.onAssessmentChange();
      }
      if (event.data.type === "com.motion.assessment_page_changed") {
        setDirty(!!event.data.dirty);
      }
    };
    window.addEventListener("message", assessmentEventListener);
    return () => {
      window.removeEventListener("message", assessmentEventListener);
    };
  }, [searchParams.assessmentId]);

  const maybeClose = () => {
    if (dirty) {
      setShowConfirmClose(true);
      return;
    }
    confirmClose();
  };

  const confirmClose = () => {
    setShowConfirmClose(false);
    setDirty(false);
    setSearchParams({ focused: false, assessmentId: null });
  };

  if (!searchParams.assessmentId) {
    return (
      <Wrapper assessmentName={null} onClose={maybeClose}>
        <Box p={1}>
          <Typography variant="body1">
            Select an assessment to get started.
          </Typography>
        </Box>
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper assessmentName={null} onClose={maybeClose}>
        <Box p={1}>
          <DetailedAlert
            message="Oops! Something went wrong. Please close this assessment and try again."
            additionalDetails={error}
          />
        </Box>
      </Wrapper>
    );
  }

  if (loading || !data) {
    return (
      <Wrapper assessmentName={null} onClose={maybeClose}>
        <Box p={1}>
          <Typography variant="body1">Loading...</Typography>
        </Box>
      </Wrapper>
    );
  }

  const assessment = data.assessment;
  return (
    <Wrapper
      assessmentName={assessment.name}
      onClose={maybeClose}
      dirty={dirty}
    >
      <iframe
        src={`${formsBaseUrl()}/admin/assessments/${
          assessment.id
        }?embedded=true`}
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
      <Dialog open={showConfirmClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          <DialogContentText>
            You've updated assessment answers on the current page but haven't
            submitted them yet. These changes might be lost if you close the
            assessment now.
          </DialogContentText>
          <DialogContentText>
            Make sure to save assessment answers by pressing "Next" before
            closing the assessment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmClose(false)}>
            Back to assessment
          </Button>
          <Button color="error" onClick={confirmClose}>
            Close without saving changes
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
}

function Wrapper(props: {
  assessmentName: string | null;
  dirty?: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="1"
      sx={{ backgroundColor: "white" }}
      borderLeft={`1px solid ${grey[400]}`}
    >
      <Box
        display="flex"
        gap={1}
        sx={(theme) => ({ backgroundColor: theme.palette.primary.main })}
        p={1}
        alignItems="center"
      >
        {props.assessmentName && (
          <Typography variant="h6" color="white">
            {(props.assessmentName || "") + (props.dirty ? "*" : "")}
          </Typography>
        )}
        <Box flex={1} />
        <Button onClick={props.onClose} sx={{ color: "white" }}>
          Close
        </Button>
      </Box>
      {props.children}
    </Box>
  );
}
