import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import unregister from "./registerServiceWorker";
import { env } from "./config";
import { AppWrapper } from "./AppWrapper";

ReactDOM.render(
  <Router basename={env("REACT_APP_BASENAME", "/")}>
    <AppWrapper />
  </Router>,
  document.getElementById("root"),
);

// we don't need serviceworker yet, its caching makes it harder to get updates
//registerServiceWorker();
console.log("unregistering service worker");
unregister();
