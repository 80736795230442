import gql from "graphql-tag";

const conversationFields = gql`
  fragment ConversationFields on ActiveConversation {
    id
    member {
      id
      displayName
    }
    state
    ownerId
    ownerName
    lastEventTime
    lastMemberReadTime
    lastMemberMessageTime
    lastMotionMessageTime
  }
`;

export const CLAIM_CONVERSATION = gql`
  mutation ClaimConversation($memberId: String!) {
    claimConversation(memberId: $memberId)
  }
`;

export const CLOSE_CONVERSATION = gql`
  mutation CloseConversation($memberId: String!) {
    closeConversation(memberId: $memberId)
  }
`;

export const JOIN_CONVERSATION = gql`
  mutation JoinConversation($channelUrl: String!) {
    joinConversation(channelUrl: $channelUrl)
  }
`;

const appointmentTokenFields = gql`
  fragment AppointmentTokenFields on MedicalAppointmentToken {
    id
    patientId
    appointmentType
    available
    staffIds
    durationsInMinutes
    createdAt
    updatedAt
  }
`;

export const CREATE_MEDICAL_APPOINTMENT_TOKEN_MUTATION = gql`
  mutation CreateMedicalAppointmentToken(
    $patientId: String!
    $appointmentType: AppointmentType!
    $available: Int!
    $durationsInMinutes: [Int!]!
    $staffIds: [String!]!
  ) {
    createMedicalAppointmentToken(
      patientId: $patientId
      appointmentType: $appointmentType
      available: $available
      durationsInMinutes: $durationsInMinutes
      staffIds: $staffIds
    ) {
      ...AppointmentTokenFields
    }
  }
  ${appointmentTokenFields}
`;

export const MEDICAL_APPOINTMENT_TOKENS = gql`
  query medicalAppointmentTokens($patientId: String!) {
    medicalAppointmentTokens(patientId: $patientId) {
      ...AppointmentTokenFields
    }
  }
  ${appointmentTokenFields}
`;

export const SEND_SCHEDULE_LINK_TO_MEMBER_MUTATION = gql`
  mutation SendScheduleLinkToMember(
    $message: String!
    $memberId: String!
    $token: String!
  ) {
    sendScheduleLinkToMember(
      message: $message
      memberId: $memberId
      token: $token
    )
  }
`;

export const UPDATE_COMMUNICATION_PREFERENCES_MUTATION = gql`
  mutation UpdateCommunicationPreferences(
    $patientId: String!
    $consentsToSMS: Boolean!
    $consentsToEmail: Boolean!
  ) {
    updateCommunicationPreferences(
      patientId: $patientId
      consentsToSMS: $consentsToSMS
      consentsToEmail: $consentsToEmail
    )
  }
`;

export const RESCHEDULE_APPOINTMENT_MUTATION = gql`
  mutation rescheduleAppointmentAdmin(
    $appointmentId: String!
    $startTime: Long!
    $durationInMinutes: Int!
    $ignoreBusy: Boolean!
    $notifyPatient: Boolean!
  ) {
    rescheduleAppointmentAdmin(
      appointmentId: $appointmentId
      startTime: $startTime
      durationInMinutes: $durationInMinutes
      ignoreBusy: $ignoreBusy
      notifyPatient: $notifyPatient
    )
  }
`;

export const CANCEL_APPOINTMENT_MUTATION = gql`
  mutation cancelAppointmentMutation(
    $appointmentId: String!
    $notifyPatient: Boolean!
  ) {
    cancelAppointmentAdmin(
      appointmentId: $appointmentId
      notifyPatient: $notifyPatient
    )
  }
`;

export const STAFF_SCHEDULES_BY_TYPE = gql`
  query staffSchedulesByAppointmentType($appointmentType: AppointmentType) {
    staffSchedulesByAppointmentType(appointmentType: $appointmentType) {
      staffId
      displayName
      avatarUrl
      timezone
      appointmentTypes
      workingHours
      licensedStates
    }
  }
`;

export const SERVER_INFO_QUERY = gql`
  query ServerInfo {
    version
    namespace
  }
`;

export const DROP_CACHES_MUTATION = gql`
  mutation DropCaches {
    dropCaches
  }
`;

export const PATIENT_INFO_QUERY = gql`
  query PatientInfo($patientId: String!) {
    patient(patientId: $patientId) {
      id
      welkinPatientId
      elationPatientId
      firstName
      lastName
      zip
      risaltoUserId
      invitationId
      elationPatientId
      primaryCareTeamStaffId
      communicationPreferences {
        email
        phone
        timezone
        consentsToSMS
        consentsToEmails
      }
    }
  }
`;

const practitionerFieldsFragment = gql`
  fragment PractitionerFields on Practitioner {
    id
    displayName
    practitionerType
    avatarUrl
    licensedStates
  }
`;

const clinicFieldsFragment = gql`
  fragment ClinicFields on Clinic {
    id
    name
    addressLines
  }
`;

export const AVAILABLE_APPOINTMENT_SLOTS = gql`
  query AvailableAppointmentSlots(
    $appointmentType: AppointmentType!
    $appointmentMedium: AppointmentMedium!
    $searchFromDay: String!
    $filterByLeadPractitionerIds: [String!]!
    $supportingPractitionerType: PractitionerType
    $filterBySupportingPractitionerIds: [String!]!
    $filterByClinicIds: [String!]!
    $filterByTimeOfDay: [TimeOfDay!]!
  ) {
    availableAppointmentSlotsAdmin(
      appointmentType: $appointmentType
      appointmentMedium: $appointmentMedium
      searchFromDay: $searchFromDay
      filterByLeadPractitionerIds: $filterByLeadPractitionerIds
      supportingPractitionerType: $supportingPractitionerType
      filterBySupportingPractitionerIds: $filterBySupportingPractitionerIds
      filterByClinicIds: $filterByClinicIds
      filterByTimeOfDay: $filterByTimeOfDay
    ) {
      id
      appointmentType
      appointmentMedium
      startTime
      endTime
      leadPractitioner {
        ...PractitionerFields
      }
      supportingPractitioner {
        ...PractitionerFields
      }
      clinic {
        ...ClinicFields
      }
    }
  }
  ${practitionerFieldsFragment}
  ${clinicFieldsFragment}
`;

export const CREATE_APPOINTMENT_MUTATION = gql`
  mutation CreateAppointmentAdmin(
    $patientId: String!
    $appointmentType: AppointmentType!
    $appointmentMedium: AppointmentMedium!
    $startTime: Long!
    $leadPractitionerId: String!
    $supportingPractitionerId: String
    $locationId: String
    $appointmentDescription: String!
    $patientInstructions: String
    $ignoreBusy: Boolean!
    $notifyPatient: Boolean!
    $remindPatient24h: Boolean!
    $remindPatient1h: Boolean!
  ) {
    createAppointmentAdmin(
      patientId: $patientId
      appointmentType: $appointmentType
      appointmentMedium: $appointmentMedium
      startTime: $startTime
      leadPractitionerId: $leadPractitionerId
      supportingPractitionerId: $supportingPractitionerId
      locationId: $locationId
      appointmentDescription: $appointmentDescription
      patientInstructions: $patientInstructions
      ignoreBusy: $ignoreBusy
      notifyPatient: $notifyPatient
      remindPatient24h: $remindPatient24h
      remindPatient1h: $remindPatient1h
    )
  }
`;

export const CREATE_EXTERNAL_APPOINTMENT_MUTATION = gql`
  mutation CreateExternalAppointmentAdmin(
    $patientId: String!
    $startTime: Long!
    $durationInMinutes: Int!
    $appointmentDescription: String!
    $patientInstructions: String
    $externalProviderCrmId: String!
  ) {
    createExternalAppointmentAdmin(
      patientId: $patientId
      startTime: $startTime
      durationInMinutes: $durationInMinutes
      appointmentDescription: $appointmentDescription
      patientInstructions: $patientInstructions
      externalProviderCrmId: $externalProviderCrmId
    )
  }
`;

export const ASSIGNED_PROVIDERS_QUERY = gql`
  query assignedProvidersForPatient($patientId: String!) {
    assignedProvidersForPatient(patientId: $patientId) {
      id
      displayName
      providerType
      title
      addressLines
    }
  }
`;

export const GET_PATIENT_ID_BY_EXTERNAL_ID = gql`
  query patientIdByExternalId(
    $externalId: String!
    $patientIdType: PatientIdType!
  ) {
    patientIdByExternalId(
      externalId: $externalId
      patientIdType: $patientIdType
    )
  }
`;

export const GET_ELIGIBLE_PRACTITIONERS = gql`
  query getEligiblePractitioners(
    $appointmentType: AppointmentType!
    $appointmentMedium: AppointmentMedium!
    $supportingPractitionerType: PractitionerType
    $filterByClinicIds: [String!]!
  ) {
    eligiblePractitioners(
      appointmentType: $appointmentType
      appointmentMedium: $appointmentMedium
      supportingPractitionerType: $supportingPractitionerType
      filterByClinicIds: $filterByClinicIds
    ) {
      leadPractitioners {
        ...PractitionerFields
      }
      supportingPractitioners {
        ...PractitionerFields
      }
    }
  }

  ${practitionerFieldsFragment}
`;

export const GET_ALL_CLINICS = gql`
  query getAllClinics {
    clinics {
      ...ClinicFields
    }
  }
  ${clinicFieldsFragment}
`;

export const GET_CLINIC_BY_ID = gql`
  query clinicForIdQuery($clinicId: String!) {
    clinic(clinicId: $clinicId) {
      id
      name
      addressLines
    }
  }
`;

export const GET_ZOOM_MEETING_BY_ID = gql`
  query zoomMeetingForIdQuery($appointmentId: String!) {
    zoomMeeting(appointmentId: $appointmentId) {
      id
      joinUrl
      hostEmail
      settings {
        alternativeHosts
      }
    }
  }
`;

export const ADD_HOST_TO_ZOOM_MEETING = gql`
  mutation addStaffAsHost($staffId: String!, $appointmentId: String!) {
    tryAddStaffAsAlternativeHost(
      staffId: $staffId
      appointmentId: $appointmentId
    )
  }
`;

export const GET_APPOINTMENT_TYPE_CONFIGURATIONS = gql`
  query getAppointmentTypeConfigurations {
    appointmentTypeConfigurations {
      appointmentType
      internalName
      leadPractitionerType
      durationInMinutes
      supportingPractitionerTypes
      supportedMediums
    }
  }
`;

export const GET_ALL_STAFF_SCHEDULES = gql`
  query getAllStaffSchedules {
    staffSchedules {
      id
      staffId
      staffDisplayName
      plannedSchedules {
        inEffectAfter
        weeklySchedule {
          ...DailyScheduleFields
        }
        alternateWeeklySchedule {
          ...DailyScheduleFields
        }
      }
    }
  }

  fragment DailyScheduleFields on DailySchedule {
    dayOfWeek
    hours
  }
`;
