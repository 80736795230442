import React, { ReactNode } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Patient, VisitNoteStatus } from "../../../gql/graphql";
import { maxPatientScreenWidth } from "../../../theme";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import DetailedAlert from "../../DetailedAlert";
import { grey } from "@mui/material/colors";
import { sortBy } from "lodash";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { graphql } from "../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import { DateTime } from "luxon";
import VisitNoteStatusChip, {
  VisitNoteStatusLabel,
} from "./VisitNoteStatusChip";
import DeleteVisitNoteDialog from "./DeleteVisitNoteDialog";

type Props = {
  patient: Patient;
};

const VisitNote_AppointmentFields = graphql(`
  fragment VisitNote_AppointmentFields on MedicalAppointment {
    id
    startTime
  }
`);

const VisitNote_AssessmentFields = graphql(`
  fragment VisitNote_AssessmentFields on Assessment {
    id
    patientId
    name
    status
    createdAt
    updatedAt
    scoringOutput {
      exceptions {
        name
        bodyPart
      }
      pathways {
        name
        bodyPart
      }
    }
    patientAccessToken {
      expiresAt
    }
  }
`);

export const VisitNoteFields = graphql(`
  fragment VisitNoteFields on VisitNote {
    id
    patientId
    appointment {
      ...VisitNote_AppointmentFields
    }
    subjective
    objective
    assessmentPlan
    assessments {
      ...VisitNote_AssessmentFields
    }
    orders {
      orderId
      bodyParts
    }
    orderSuggestions {
      orderId
      visibility
      bodyParts
    }
    status
    createdAt
    createdByName
    completionMetadata {
      completedAt
      completedByName
    }
  }
`);

export const PATIENT_VISIT_NOTES = graphql(`
  query PatientVisitNotes($patientId: String!) {
    allVisitNotes(patientId: $patientId) {
      id
      createdByName
      status
      assessments {
        id
      }
      appointment {
        id
        startTime
      }
    }
  }
`);

export default function PatientVisitNotes(props: Props) {
  const match = useRouteMatch();
  const [deleteNoteId, setDeleteNoteId] = React.useState<string | null>(null);
  const { data, loading, error, refetch } = useQuery(PATIENT_VISIT_NOTES, {
    variables: { patientId: props.patient.id },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return (
      <BreadcrumbsWrapper>
        <CircularProgress />
      </BreadcrumbsWrapper>
    );
  }

  if (error) {
    return (
      <BreadcrumbsWrapper>
        <DetailedAlert
          message="Oops! Failed to load visit notes. Please try again."
          retry={() => refetch()}
          additionalDetails={error}
        />
      </BreadcrumbsWrapper>
    );
  }

  const sortedVisitNotes = sortBy(
    data?.allVisitNotes,
    (note) => note.appointment.startTime,
  ).reverse();
  return (
    <BreadcrumbsWrapper>
      {!!deleteNoteId && (
        <DeleteVisitNoteDialog
          visitNoteId={deleteNoteId}
          onClose={() => setDeleteNoteId(null)}
        />
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={styles.header}>Visit Date ▼</TableCell>
            <TableCell sx={styles.header}>Created By</TableCell>
            <TableCell sx={styles.header}>Status</TableCell>
            <TableCell sx={styles.header}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedVisitNotes.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  fontStyle="italic"
                >
                  No visit notes yet
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            sortedVisitNotes.map((note) => (
              <TableRow hover key={note.id}>
                <TableCell>
                  {DateTime.fromSeconds(
                    note.appointment.startTime,
                  ).toLocaleString(DateTime.DATETIME_MED)}
                </TableCell>
                <TableCell>{note.createdByName}</TableCell>
                <TableCell>
                  <VisitNoteStatusChip
                    status={
                      note.status === VisitNoteStatus.Submitted
                        ? VisitNoteStatusLabel.COMPLETED
                        : VisitNoteStatusLabel.IN_PROGRESS
                    }
                  />
                </TableCell>
                <TableCell>
                  <Box display="flex" gap={2} alignItems="center">
                    <Link component={RouterLink} to={`${match.url}/${note.id}`}>
                      {note.status === VisitNoteStatus.Submitted
                        ? "view"
                        : "edit"}
                    </Link>
                    {note.status !== VisitNoteStatus.Submitted && (
                      <Link
                        component="button"
                        color="error"
                        onClick={() => setDeleteNoteId(note.id)}
                      >
                        delete
                      </Link>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Box display="flex" justifyContent="flex-end">
        <Button
          component={RouterLink}
          to={`${match.url}/new`}
          variant="contained"
          startIcon={<AddOutlinedIcon />}
        >
          New Note
        </Button>
      </Box>
    </BreadcrumbsWrapper>
  );
}

const BreadcrumbsWrapper: React.FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      display="flex"
      flex={1}
      sx={{ overflowY: "auto" }}
      justifyContent="center"
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        flex={1}
        maxWidth={maxPatientScreenWidth}
      >
        <Breadcrumbs>
          <Link
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            component="button"
            color="inherit"
            underline="none"
          >
            <NoteAltOutlinedIcon sx={{ height: 18, width: 18 }} />
            Visit Notes
          </Link>
        </Breadcrumbs>
        <Paper
          sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 1 }}
        >
          <Typography variant="h6" color="primary">
            All Visit Notes
          </Typography>
          {children}
        </Paper>
      </Box>
    </Box>
  );
};

const styles = {
  header: {
    backgroundColor: grey[200],
    fontWeight: "bold",
  },
};
