import { LoadingButton } from "@mui/lab";
import { LoadingButtonProps } from "@mui/lab/LoadingButton/LoadingButton";
import React, { useEffect, useState } from "react";

type Props = LoadingButtonProps & {
  deferred: boolean;
};

/**
 * A loading button that defers the onClick event until `deferred` is false.
 * Useful for delaying an action while an auto-save mutation is running, without
 * visibly disabling the button.
 * If the button is disabled while deferred, the deferred event is cancelled.
 */
export default function DeferredButton(props: Props) {
  const [deferredEvent, setDeferredEvent] = useState<React.MouseEvent<
    HTMLButtonElement,
    MouseEvent
  > | null>(null);
  const { deferred, onClick, loading, disabled, ...loadingButtonOtherProps } =
    props;

  useEffect(() => {
    if (!deferred && deferredEvent) {
      onClick?.(deferredEvent);
      setDeferredEvent(null);
    }
    if (disabled) {
      setDeferredEvent(null);
    }
  }, [deferred, deferredEvent, onClick, disabled]);

  if (!onClick) {
    return <LoadingButton {...loadingButtonOtherProps} />;
  }

  const onButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (!deferred) {
      onClick(event);
    } else {
      setDeferredEvent(event);
    }
  };

  return (
    <LoadingButton
      onClick={onButtonClick}
      disabled={disabled}
      loading={!!deferredEvent || loading}
      {...loadingButtonOtherProps}
    />
  );
}
