import React from "react";
import { CircularProgress, Box } from "@mui/material";

export default function LoadingScreen() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{padding: 100}}>
      <CircularProgress />
    </Box>
  );
}
