import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";

type Props = {
  onClose: () => void;
  onSplit: (copyExistingSchedule: boolean) => void;
};

/**
 * Dialog to confirm splitting a simple schedule into an A/B week schedule.
 */
export function SplitScheduleDialog(props: Props) {
  const [choice, setChoice] = useState<"copy" | "doNotCopy">("copy");
  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Split Schedule</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1" color="text.secondary">
            This will create an alternate ("B" week) schedule. Do you want to
            copy your "A" week schedule to the new alternate schedule, or start
            with an empty schedule?
          </Typography>
          <RadioGroup
            aria-labelledby="operation-label"
            name="radio-buttons-group"
            value={choice}
            onChange={(event) =>
              setChoice(event.target.value as "copy" | "doNotCopy")
            }
          >
            <FormControlLabel
              value="copy"
              control={<Radio />}
              label={'Copy existing "A" schedule to "B"'}
            />
            <FormControlLabel
              value="doNoCopy"
              control={<Radio />}
              label={'Start with empty "B" schedule'}
            />
          </RadioGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => props.onSplit(choice === "copy")}
          color="primary"
        >
          Split
        </Button>
      </DialogActions>
    </Dialog>
  );
}
