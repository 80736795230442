import {
  AppointmentType,
  AppointmentTypeConfigurationFieldsFragment,
} from "../../gql/graphql";
import { graphql } from "../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import React, { useMemo } from "react";
import { keyBy, keys, sortBy } from "lodash";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DetailedAlert from "../DetailedAlert";
import { getAppointmentTypeLabel } from "../../libs/booking";

type Props = {
  /**
   * If true, sunsetted types are filtered out.
   * If false, beta types are filtered out.
   */
  beta: boolean;
  value: AppointmentType | null;
  onChange: (value: AppointmentType) => void;
  label?: string;
  error?: boolean;
  helperText?: string;
  shouldDisableType?: (
    typeConfig: AppointmentTypeConfigurationFieldsFragment,
  ) => boolean;
};

graphql(`
  fragment AppointmentTypeConfigurationFields on AppointmentTypeConfiguration {
    appointmentType
    internalName
    durationInMinutes
    leadPractitionerType
    supportedMediums
    supportingPractitionerTypes
  }
`);
export const GET_APPOINTMENT_TYPE_CONFIG = graphql(`
  query getAppointmentTypeConfig($beta: Boolean) {
    appointmentTypeConfigurations(beta: $beta) {
      ...AppointmentTypeConfigurationFields
    }
  }
`);

/**
 * Generic component to select appointment type.
 */
export default function SelectAppointmentType(props: Props) {
  const { data, loading, error, refetch } = useQuery(
    GET_APPOINTMENT_TYPE_CONFIG,
    {
      variables: { beta: props.beta },
    },
  );

  const appointmentTypeConfigs = useMemo(() => {
    const sorted = sortBy(
      data?.appointmentTypeConfigurations,
      (config) => config.internalName,
    );
    return keyBy(sorted, (config) => config.appointmentType);
  }, [data]);

  if (loading) {
    return (
      <Select value="loading" disabled fullWidth>
        <MenuItem value="loading">
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body1">Loading…</Typography>
            <CircularProgress size={18} color="inherit" />
          </Box>
        </MenuItem>
      </Select>
    );
  }

  if (error) {
    return (
      <Box flex={1}>
        <DetailedAlert
          message="Oops! Failed to load config. Please try again."
          additionalDetails={error}
          retry={() => refetch()}
        />
      </Box>
    );
  }

  const handleChange = (_: React.SyntheticEvent, value: string | null) => {
    if (!!value) {
      props.onChange(value as AppointmentType);
    }
  };

  const shouldDisableType = (appointmentType: AppointmentType) => {
    const config = appointmentTypeConfigs[appointmentType];
    return props.shouldDisableType?.(config) || false;
  };

  return (
    <Autocomplete
      options={keys(appointmentTypeConfigs) as Array<AppointmentType>}
      autoHighlight
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label || "Appointment Type"}
          error={props.error}
          helperText={props.helperText}
        />
      )}
      getOptionLabel={(type) =>
        getAppointmentTypeLabel(type as AppointmentType, appointmentTypeConfigs)
      }
      value={props.value}
      onChange={handleChange}
      disableClearable={props.value !== null}
      getOptionDisabled={shouldDisableType}
      fullWidth
    />
  );
}
