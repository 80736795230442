import { EffectiveTimeSlotFieldsFragment } from "../../../../../../../gql/graphql";
import { Box, Typography } from "@mui/material";
import React from "react";
import { getEffectiveSlotName } from "../../../../../../../libs/booking";
import {
  useCachedAppointmentTypeConfigurations,
  useCachedClinics,
} from "../../../../../../../hooks/commonQueries";
import { DateTime, Interval } from "luxon";
import { MotionTimezone } from "../../../../../../../libs/time";
import { getMediumName } from "../../../../../../../libs/scheduling";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

type Props = {
  slot: EffectiveTimeSlotFieldsFragment;
  flagLocation: boolean;
  timezone: MotionTimezone;
};

/**
 * Renders details for an effective time slot.
 */
export default function EffectiveSlotDetails(props: Props) {
  const { slot, flagLocation, timezone } = props;
  const appointmentConfig = useCachedAppointmentTypeConfigurations(true);
  const clinics = useCachedClinics();
  const start = DateTime.fromSeconds(slot.startTime, { zone: timezone });
  const end = DateTime.fromSeconds(slot.endTime, { zone: timezone });
  const locationId =
    slot.__typename === "EffectiveAppointmentSlot" ? slot.locationId : null;
  const clinic = !!locationId ? clinics[locationId] : null;
  const mediums =
    slot.__typename === "EffectiveAppointmentSlot"
      ? slot.supportedMediums
      : null;

  return (
    <Box p={2} display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        gap={2}
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="body1">
          {getEffectiveSlotName(props.slot, appointmentConfig)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {Interval.fromDateTimes(start, end).toLocaleString(
            DateTime.TIME_SIMPLE,
          )}
        </Typography>
      </Box>
      {locationId && (
        <Box display="flex" alignItems="center" gap={1}>
          {flagLocation && <WarningAmberIcon color="error" />}
          <Box display="flex" flexDirection="column">
            <Typography variant="body2" color="text.secondary">
              {clinic?.name || locationId}
            </Typography>
            {!!clinic && (
              <Typography variant="caption" color="text.secondary">
                {clinic.addressLines.join(", ")}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {mediums && (
        <Typography variant="body2" color="text.secondary">
          {mediums.map(getMediumName).join(", ")}
        </Typography>
      )}
    </Box>
  );
}
