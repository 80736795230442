import {
  AppointmentConfigMap,
  getPositionFromTop,
  HighlightRule,
  LocalSlot,
} from "../../../../libs/scheduleTemplates";
import { Box } from "@mui/material";
import TimeSlotView from "../TimeSlotView";
import { convertMinutesToHeight } from "../../../../libs/time";

type Props = {
  minHour: number;
  maxHour: number;
  slots: Array<LocalSlot>;
  appointmentTypeConfigurations: AppointmentConfigMap;
  onSlotClick: (anchorEl: HTMLElement, slot: LocalSlot) => void;
  highlightRule: HighlightRule | null;
};
export default function DailySlotsView(props: Props) {
  const heightInPixels = convertMinutesToHeight(
    (props.maxHour - props.minHour) * 60,
  );

  return (
    <Box
      height={`${heightInPixels}px`}
      borderLeft={(theme) => `1px solid ${theme.palette.divider}`}
      position="relative"
      flex={1}
    >
      {props.slots.map((slot) => (
        <Box
          key={slot.id}
          position="absolute"
          top={getPositionFromTop(props.minHour, slot)}
          width={(theme) => `calc(100% - ${theme.spacing(1)})`}
          right={0}
          sx={{ cursor: "pointer" }}
          onClick={(event) => props.onSlotClick(event.currentTarget, slot)}
        >
          <TimeSlotView
            slot={slot}
            appointmentTypeConfigurations={props.appointmentTypeConfigurations}
            highlightRule={props.highlightRule}
          />
        </Box>
      ))}
    </Box>
  );
}
