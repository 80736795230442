import { SearchParams } from "../../../../../../libs/booking";
import { AppointmentMedium, TargetWeek } from "../../../../../../gql/graphql";
import { useCachedMotionStaff } from "../../../../../../hooks/commonQueries";
import { graphql } from "../../../../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import { Box, CircularProgress, Typography } from "@mui/material";
import { StaffMemberRow } from "../../StaffBrowser/StaffMemberBrowser";

type Props = {
  patientId: string;
  searchParameters: SearchParams;
  week: TargetWeek;
  staffId: string;
};

export const GET_PROVIDER_AVAILABILITY = graphql(`
  query GetProviderAvailability(
    $staffId: String!
    $week: TargetWeek!
    $appointmentType: AppointmentType!
    $medium: AppointmentMedium!
    $locationIds: [String!]!
    $patientId: String!
  ) {
    providerAvailability(
      staffId: $staffId
      week: $week
      appointmentType: $appointmentType
      medium: $medium
      locationIds: $locationIds
      patientId: $patientId
    ) {
      ...ProviderAvailabilityFields
    }
  }
`);

/**
 * A staff "browser" displaying a single staff member, with their availability.
 */
export default function ReadOnlyStaffMemberBrowser(props: Props) {
  const staffById = useCachedMotionStaff();
  const { data, loading } = useQuery(GET_PROVIDER_AVAILABILITY, {
    variables: {
      staffId: props.staffId,
      week: props.week,
      appointmentType: props.searchParameters.appointmentType,
      medium: props.searchParameters.medium,
      locationIds:
        props.searchParameters.medium === AppointmentMedium.InClinic
          ? Array.from(props.searchParameters.clinicIds)
          : [],
      patientId: props.patientId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  // Ignore errors, let the grid component handle it.
  const availableSlots = data?.providerAvailability?.availableSlots?.length;
  const staff = staffById[props.staffId];

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="body1" fontWeight="bold">
          Provider
        </Typography>
        {loading && <CircularProgress size={16} />}
      </Box>
      <StaffMemberRow
        staff={staff}
        selected={true}
        onClick={() => {}}
        availableSlots={availableSlots}
        loadingAvailability={loading}
      />
    </Box>
  );
}
