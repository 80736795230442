// @flow
import React from "react";
import type {Patient} from "../../../generated/flow_types";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import AssessmentDetails from "./AssessmentDetailsScreen";
import {PatientAssessments} from "./PatientAssessments";

type Props = {
  patient: Patient
};

/** Routing switch for the "Assessments" tab */
export default function PatientAssessmentsSwitch(props: Props) {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:assessmentId`}>
        <AssessmentDetails />
      </Route>
      <Route exact path={`${match.path}/`}>
        <PatientAssessments patient={props.patient} />
      </Route>
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}
