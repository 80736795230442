import {
  EditableOrderLine,
  GroupOrderLineConfig,
  hasJointLevelOrder,
  LineErrors,
  OrderLineConfig,
} from "../../../../libs/orderLines";
import {
  Box,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  BodyPart,
  OrderConfig,
  OrderLevel,
  OrderSuggestion,
  OrderSuggestionVisibility,
} from "../../../../gql/graphql";
import JointMultiSelect from "../../../JointMultiSelect";
import StarIcon from "@mui/icons-material/Star";
import { grey, yellow } from "@mui/material/colors";

type Props = {
  lineConfig: OrderLineConfig;
  suggestions: { [orderId: string]: OrderSuggestion };
  value: EditableOrderLine;
  onChange: (line: EditableOrderLine) => void;
  onDelete: () => void;
  errors: LineErrors | null;
};

/** A single editable order line. */
export default function OrdersEditorLine(props: Props) {
  const selectedOrder = getCurrentOrder(props.lineConfig, props.value);
  const lineLabel = getLineLabel(props.lineConfig);

  const onSelectedOrderChange = (orderId: string) => {
    props.onChange({
      ...props.value,
      selectedOrderId: orderId,
    });
  };

  const onBodyPartsChange = (bodyParts: Array<BodyPart>) => {
    props.onChange({
      ...props.value,
      bodyParts: bodyParts,
    });
  };

  return (
    <Box
      py={1}
      px={1}
      display="flex"
      gap={1}
      alignItems="center"
      sx={{
        "&:hover": {
          "& .removeLink": {
            visibility: "visible",
          },
          backgroundColor: grey[50],
        },
      }}
    >
      <Typography variant="body1">•</Typography>
      {!!lineLabel && <Typography variant="body2">{lineLabel}</Typography>}
      {/*  Order Selection (group lines only) */}
      {props.lineConfig.type === "group" && (
        <GroupOrderSelector
          lineConfig={props.lineConfig}
          suggestions={props.suggestions}
          selectedOrderId={selectedOrder?.id ?? null}
          onSelectedOrderChange={onSelectedOrderChange}
        />
      )}
      {/*  Joint Selection */}
      {hasJointLevelOrder(props.lineConfig) &&
        selectedOrder?.level !== OrderLevel.Visit && (
          <JointMultiSelect
            value={props.value.bodyParts || []}
            onValueChange={onBodyPartsChange}
            error={props.errors?.jointError ?? null}
          />
        )}
      <Link
        variant="caption"
        component="button"
        onClick={props.onDelete}
        fontSize="small"
        className="removeLink"
        sx={{ visibility: "hidden", marginLeft: "auto" }}
      >
        remove
      </Link>
    </Box>
  );
}

function getLineLabel(lineConfig: OrderLineConfig): string | null {
  if (lineConfig.type === "standalone") {
    return lineConfig.order.name;
  }

  return null;
}

type GroupOrderSelectorProps = {
  lineConfig: GroupOrderLineConfig;
  suggestions: { [orderId: string]: OrderSuggestion };
  selectedOrderId: string | null;
  onSelectedOrderChange: (orderId: string) => void;
};

function GroupOrderSelector(props: GroupOrderSelectorProps) {
  return (
    <FormControl sx={{ minWidth: 150 }} size="small">
      <InputLabel
        id={`${props.lineConfig.group.id}-label`}
        sx={{ typography: "body2" }}
      >
        {props.lineConfig.group.name}
      </InputLabel>
      <Select
        labelId={`${props.lineConfig.group.id}-label`}
        label={props.lineConfig.group.name}
        value={props.selectedOrderId}
        onChange={(e) => props.onSelectedOrderChange(e.target.value!)}
        renderValue={(orderId) =>
          props.lineConfig.orders.find((order) => order.id === orderId)!.name
        }
        sx={{ backgroundColor: "white", typography: "body2" }}
      >
        {props.lineConfig.orders.map((order) => (
          <MenuItem key={order.id} value={order.id}>
            <Box display="flex" gap={2} alignItems="center">
              {order.name}
              {props.suggestions[order.id]?.visibility ===
                OrderSuggestionVisibility.Recommended && (
                <Tooltip title="Recommended">
                  <StarIcon sx={{ color: yellow[700] }} fontSize="small" />
                </Tooltip>
              )}
              {props.suggestions[order.id]?.visibility ===
                OrderSuggestionVisibility.AddOn && (
                <StarIcon sx={{ color: grey[400] }} fontSize="small" />
              )}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

/**
 * Returns the order currently represented by the given line.
 * For standalone lines, this is the line's order.
 * For group lines, it's the selected order (or null if not selected).
 */
function getCurrentOrder(
  lineConfig: OrderLineConfig,
  value: EditableOrderLine,
): OrderConfig | null {
  if (lineConfig.type === "standalone") {
    return lineConfig.order;
  }

  return (
    lineConfig.orders.find((order) => order.id === value?.selectedOrderId) ??
    null
  );
}
