// @flow
import React, { PureComponent } from "react";
import { isMember } from "../utils";
import styles from "../theme";

type Props = {
  message: Object,
  onPress: (message: Object) => void
};

type Image = {
  width: number,
  height: number
};

type State = Image;

class ImageMessageRow extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      width: 164 * 1.23786, // XXX: hardcoded aspec ratio
      height: 164
    };

    this.onImgLoad = this.onImgLoad.bind(this);
  }

  onImgLoad({ target: img }) {
    const { width, height } = this.imageResize(
      img.naturalWidth,
      img.naturalHeight
    );
    this.setState({ width, height });
  }

  imageResize = (originalWidth: number, originalHeight: number): Image => {
    const IMAGE_MAX_SIZE = 164;
    const scaleWidth = IMAGE_MAX_SIZE / originalWidth;
    const scaleHeight = IMAGE_MAX_SIZE / originalHeight;

    let scale = scaleWidth <= scaleHeight ? scaleWidth : scaleHeight;
    if (scale > 1) {
      scale = 1;
    }

    const width = originalWidth * scale;
    const height = originalHeight * scale;
    return { width, height };
  };

  openLink = () => {
    this.props.onPress(this.props.message);
  };

  render() {
    const { message } = this.props;
    const style = isMember(message) ? styles.other : styles.me;
    return (
      <div style={style.bubble}>
        <div onClick={this.openLink}>
          <img
            alt="[attached in chat]"
            src={message.url}
            onLoad={this.onImgLoad}
            width={this.state.width}
            height={this.state.height}
          />
        </div>
      </div>
    );
  }
}

export default ImageMessageRow;
