import { graphql } from "../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import DetailedAlert from "../../DetailedAlert";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { entries, find, findLast, groupBy, sortBy } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  MotionUserFieldsFragment,
  ScheduleFieldsForListFragment,
} from "../../../gql/graphql";
import { DateTime } from "luxon";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { blueGrey, teal } from "@mui/material/colors";
import { useCanManageSchedules } from "../../../hooks/authHooks";

// Limited fragment when listing schedules
graphql(`
  fragment ScheduleFieldsForList on Schedule {
    id
    staff {
      id
    }
    takesEffectDate
    isDraft
    timezone
  }
`);

export const ALL_SCHEDULES_AND_STAFF = graphql(`
  query GetAllSchedulesAndStaff {
    masqueradeableMotionUsers {
      ...MotionUserFields
    }
    allSchedules {
      ...ScheduleFieldsForList
    }
  }
`);

export default function BrowseSchedules() {
  const match = useRouteMatch<{ scheduleId: string }>();
  const canManageSchedules = useCanManageSchedules();
  const history = useHistory();
  const { data, loading, error, refetch } = useQuery(ALL_SCHEDULES_AND_STAFF, {
    notifyOnNetworkStatusChange: true,
  });
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    const selectedScheduleId = match.params.scheduleId;
    if (!selectedScheduleId || !data) {
      setSelected(null);
      return;
    }

    // Make sure the selected schedule's parent node is extended.
    const matchingSchedule = data.allSchedules.find(
      (schedule) => schedule.id === selectedScheduleId,
    );
    if (matchingSchedule) {
      const staffNodeId = matchingSchedule.staff.id;
      const scheduleNodeId = `${staffNodeId}_${matchingSchedule.takesEffectDate}`;
      const newExpanded = new Set(expanded);
      setExpanded(Array.from(newExpanded.add(staffNodeId)));
      setSelected(scheduleNodeId);
    } else {
      setSelected(null);
    }
  }, [data, match.params.scheduleId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error || !data) {
    return (
      <DetailedAlert
        message="Oops! Something went wrong"
        additionalDetails={error}
        retry={() => refetch()}
      />
    );
  }

  const allStaffSchedules = compileStaffSchedules(
    data.masqueradeableMotionUsers,
    data.allSchedules,
    /*includeDrafts = */ canManageSchedules,
  );

  const handleNodeSelect = (nodeId: string) => {
    const [staffId, startDate] = nodeId.split("_");
    const matchingSchedule = allStaffSchedules.find(
      (staffSchedule) => staffSchedule.staffId === staffId,
    )!.schedulesByStartDate[startDate]!;
    if (!matchingSchedule) {
      return;
    }
    if (matchingSchedule.publishedScheduleId) {
      history.push(`/schedules/${matchingSchedule.publishedScheduleId}`);
    } else if (matchingSchedule.draftScheduleId) {
      history.push(`/schedules/${matchingSchedule.draftScheduleId}`);
    }
  };

  const handleClickNew = (staffId: string) => {
    history.push(`/schedules/create?staffId=${staffId}`);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="h6">Schedules</Typography>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={(_, newExpanded) => setExpanded(newExpanded)}
        onNodeSelect={(_: any, nodeId: string) => handleNodeSelect(nodeId)}
      >
        {allStaffSchedules.map((staffSchedules) => (
          <TreeItem
            nodeId={staffSchedules.staffId}
            label={
              <StaffLabel
                staffName={staffSchedules.staffName}
                credentials={staffSchedules.staffCredentials}
              />
            }
            key={staffSchedules.staffId}
          >
            {entries(staffSchedules.schedulesByStartDate).map(
              ([startDate, schedules]) => (
                <TreeItem
                  nodeId={`${staffSchedules.staffId}_${startDate}`}
                  label={
                    <ScheduleLabel
                      startDate={startDate}
                      hasPublished={!!schedules.publishedScheduleId}
                      hasDraft={!!schedules.draftScheduleId}
                    />
                  }
                  key={startDate}
                />
              ),
            )}
            {canManageSchedules && (
              <Button
                size="small"
                sx={{ ml: 3 }}
                onClick={(_) => handleClickNew(staffSchedules.staffId)}
              >
                New…
              </Button>
            )}
          </TreeItem>
        ))}
      </TreeView>
      <Link
        href="https://form.asana.com/?k=7QQHQ_fLblrksMKAJ7Camw&d=436592629354945"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ marginTop: 2 }}
      >
        Request a new practitioner…
      </Link>
    </Box>
  );
}

type StaffSchedules = {
  staffId: string;
  staffName: string;
  staffCredentials: string | null;
  schedulesByStartDate: SchedulesByStartDate;
};

type SchedulesByStartDate = {
  [startDate: string]: {
    publishedScheduleId: string | null;
    draftScheduleId: string | null;
  };
};

/**
 * Compile staff schedules into a format suitable for the tree view.
 * The compiled output omits all schedules taking effect in the past, except:
 * - The most recent published schedule ("current"), if there is one.
 * - The draft that takes effect on the same day as the current one (if there
 *   is one), or the most recent draft if there is no current
 *   published schedule.
 */
export function compileStaffSchedules(
  allStaff: Array<MotionUserFieldsFragment>,
  allSchedules: Array<ScheduleFieldsForListFragment>,
  includeDrafts: boolean,
): Array<StaffSchedules> {
  const schedulesByStaffId = groupBy(
    allSchedules,
    (schedule) => schedule.staff.id,
  );
  const sortedUsers = sortBy(allStaff, (user) => user.displayName);

  return sortedUsers.map((user) => {
    const schedules = sortBy(
      schedulesByStaffId[user.id] || [],
      // Sorting by string, but should work with ISO
      (schedule) => schedule.takesEffectDate,
    );

    if (schedules.length === 0) {
      return {
        staffId: user.id,
        staffName: user.displayName,
        staffCredentials: user.credentialsAbbreviation || null,
        schedulesByStartDate: {},
      };
    }

    // Find "current" (last taking effect before now) published schedule.
    // Undefined if no current published schedule.
    const currentPublished = findLast(
      schedules,
      (schedule) =>
        !schedule.isDraft &&
        DateTime.fromISO(schedule.takesEffectDate) <= DateTime.now(),
    );
    const currentPublishedTakesEffect = !!currentPublished
      ? DateTime.fromISO(currentPublished.takesEffectDate)
      : undefined;

    // If there is a current published schedule, the current draft is the one
    // with the same takes effect date. Otherwise, the most recent draft that
    // takes effect before now (if any.)
    const currentDraft = currentPublished
      ? find(
          schedules,
          (schedule) =>
            schedule.isDraft &&
            schedule.takesEffectDate === currentPublished?.takesEffectDate,
        )
      : findLast(
          schedules,
          (schedule) =>
            schedule.isDraft &&
            DateTime.fromISO(schedule.takesEffectDate) <= DateTime.now(),
        );

    const schedulesByStartDate = schedules.reduce((acc, input) => {
      const takesEffectDate = DateTime.fromISO(input.takesEffectDate);
      if (
        // Published schedule older than current
        (!input.isDraft &&
          !!currentPublishedTakesEffect &&
          takesEffectDate < currentPublishedTakesEffect) ||
        // Draft schedule in the past, but not current
        (input.isDraft &&
          takesEffectDate < DateTime.now() &&
          input.id !== currentDraft?.id)
      ) {
        return acc;
      }
      if (!includeDrafts && input.isDraft) {
        return acc;
      }
      const existingSchedules = acc[input.takesEffectDate] || {
        publishedScheduleId: null,
        draftScheduleId: null,
      };
      return {
        ...acc,
        [input.takesEffectDate]: {
          publishedScheduleId: input.isDraft
            ? existingSchedules.publishedScheduleId
            : input.id,
          draftScheduleId: input.isDraft
            ? input.id
            : existingSchedules.draftScheduleId,
        },
      };
    }, {} as SchedulesByStartDate);

    return {
      staffId: user.id,
      staffName: user.displayName,
      staffCredentials: user.credentialsAbbreviation || null,
      schedulesByStartDate: schedulesByStartDate,
    };
  });
}

function StaffLabel(props: { staffName: string; credentials: string | null }) {
  return (
    <Box display="flex" alignItems="baseline" gap={1}>
      <Typography variant="body1">{props.staffName}</Typography>
      {!!props.credentials && (
        <Typography variant="caption" color="text.secondary">
          {props.credentials}
        </Typography>
      )}
    </Box>
  );
}

function ScheduleLabel(props: {
  startDate: string;
  hasPublished: boolean;
  hasDraft: boolean;
}) {
  const startDate = DateTime.fromISO(props.startDate);
  const label =
    startDate < DateTime.now()
      ? "Current"
      : `Upcoming: ${startDate.toFormat("MM/dd")}`;
  return (
    <Box display="flex" gap={1} alignItems="center">
      {label}
      {props.hasPublished && (
        <Tooltip title="Published">
          <EventAvailableIcon sx={{ color: teal[300] }} fontSize="small" />
        </Tooltip>
      )}
      {props.hasDraft && (
        <Tooltip title="Draft in Progress">
          <EditCalendarIcon sx={{ color: blueGrey[400] }} fontSize="small" />
        </Tooltip>
      )}
    </Box>
  );
}
