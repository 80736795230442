// @flow checked 01/24/23
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DateTime } from "luxon";
import { useMutation } from "@apollo/client";
import { initialState } from "./initialState";

import DetailedAlert from "../../../DetailedAlert";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  Modal,
  Paper,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FirstStep from "./Step1";
import SecondStep from "./Step2";
import ThirdStep from "./Step3";
import FourthStep from "./Step4";
import {
  AppointmentMediumValues,
  AppointmentTypeValues,
} from "../../../../generated/flow_types";
import {
  CREATE_APPOINTMENT_MUTATION,
  CREATE_EXTERNAL_APPOINTMENT_MUTATION,
} from "../../../../libs/queries";
import { TabNames } from "./initialState";
import type {
  AppointmentMedium,
  AppointmentSlot,
  AppointmentTypeConfiguration,
  Clinic,
  ExternalProvider,
  Patient,
  Practitioner,
  PractitionerType,
  TimeOfDay,
} from "../../../../generated/flow_types";
import { notNull } from "../../../../libs/util";
import { LoadingButton } from "@mui/lab";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { maxPatientScreenWidth } from "../../../../theme";
import { PATIENT_APPOINTMENTS } from "../UpcomingAppointments";

const steps = [
  "Appointment Type",
  "Date & Time",
  "Customize Messaging",
  "Review & Book",
];

const style = {
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  left: "50%",
  p: 2,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const disableNextButton = (state: DraftAppointment, step: number) => {
  const {
    appointmentDescription,
    appointmentMedium,
    selectedAppointmentConfig,
    clinic,
    durationInMinutes,
    externalProvider,
    leadPractitioner,
    patientInstructions,
    selectedSlot,
    supportingPractitioner,
    supportingPractitionerType,
    tab,
  } = state;

  if (step === 0) {
    return !appointmentMedium || !selectedAppointmentConfig;
  }

  if (step === 1) {
    if (
      notNull(selectedAppointmentConfig).appointmentType !==
      AppointmentTypeValues.External
    ) {
      if (tab === TabNames.availableSlots && !!selectedSlot) {
        return false;
      }

      if (tab === TabNames.specificTimes) {
        if (
          appointmentMedium === AppointmentMediumValues.InClinic &&
          !supportingPractitionerType
        ) {
          if (leadPractitioner && clinic) {
            return false;
          }
        }
        if (
          appointmentMedium !== AppointmentMediumValues.InClinic &&
          supportingPractitionerType
        ) {
          if (leadPractitioner && supportingPractitioner) {
            return false;
          }
        }

        if (
          appointmentMedium === AppointmentMediumValues.InClinic &&
          supportingPractitionerType
        ) {
          if (clinic && leadPractitioner && supportingPractitioner) {
            return false;
          }
        }
        if (
          appointmentMedium !== AppointmentMediumValues.InClinic &&
          !supportingPractitionerType
        ) {
          if (leadPractitioner) {
            return false;
          }
        }
      }

      return true;
    } else {
      return !durationInMinutes || !externalProvider;
    }
  }
  if (step === 2) {
    return !appointmentDescription || patientInstructions.length > 1024;
  }
};

type Props = {
  mockedState?: DraftAppointment,
  patient: Patient,
  step?: number,
};

const NewAppointment = ({ mockedState, patient, step }: Props) => {
  const history = useHistory();
  const match = useRouteMatch();

  const [appointmentDetails: DraftAppointment, setAppointmentDetails] =
    useState(mockedState || initialState);
  const [cancelModal, setCancelModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(step || 0);

  const displayTimezone =
    patient.communicationPreferences.timezone || "America/New_York";

  const {
    appointmentDescription,
    appointmentMedium,
    clinic,
    durationInMinutes,
    externalProvider,
    leadPractitioner,
    notifyPatient,
    patientInstructions,
    remindPatient1h,
    remindPatient24h,
    selectedAppointmentConfig,
    selectedSlot,
    startDay,
    startTime,
    supportingPractitioner,
    tab,
  } = appointmentDetails;

  const isExternal =
    selectedAppointmentConfig?.appointmentType ===
    AppointmentTypeValues.External;

  const [
    createStaffAppointment,
    {
      data: createStaffAppointmentResult,
      loading: internalLoading,
      error: internalError,
    },
  ] = useMutation(CREATE_APPOINTMENT_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: PATIENT_APPOINTMENTS,
        variables: { patientId: patient.id, includePastAppointments: false },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [
    createExternalAppointment,
    {
      data: createExternalAppointmentResult,
      loading: externalLoading,
      error: externalError,
    },
  ] = useMutation(CREATE_EXTERNAL_APPOINTMENT_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: PATIENT_APPOINTMENTS,
        variables: { patientId: patient.id, includePastAppointments: false },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    // Redirect on successfully booked.
    if (
      createStaffAppointmentResult?.createAppointmentAdmin ||
      createExternalAppointmentResult?.createExternalAppointmentAdmin
    ) {
      history.push(
        `/patient/${match.params.patientId}/schedule?booked=success`,
      );
    }
  }, [createStaffAppointmentResult, createExternalAppointmentResult]);

  const bookAppointment = () => {
    const appointmentStartTime = `${startDay} ${startTime}`;

    const longStartTime = DateTime.fromFormat(
      appointmentStartTime,
      "yyyy-MM-dd h:mm a",
      {
        zone: displayTimezone,
      },
    ).toUnixInteger();

    if (isExternal) {
      createExternalAppointment({
        variables: {
          appointmentDescription,
          durationInMinutes,
          externalProviderCrmId: notNull(externalProvider).id,
          patientId: patient.id,
          patientInstructions,
          startTime: longStartTime,
        },
      }).then(/*ignore promise result*/);
    } else {
      const availableSlotsTab = tab === TabNames.availableSlots;

      const locationId =
        appointmentMedium === AppointmentMediumValues.InClinic
          ? availableSlotsTab
            ? notNull(selectedSlot).clinic?.id
            : notNull(clinic).id
          : null;

      createStaffAppointment({
        variables: {
          appointmentDescription,
          appointmentMedium,
          appointmentType: notNull(selectedAppointmentConfig).appointmentType,
          ignoreBusy: !availableSlotsTab,
          leadPractitionerId: availableSlotsTab
            ? notNull(selectedSlot).leadPractitioner.id
            : notNull(leadPractitioner).id,
          notifyPatient,
          patientId: patient.id,
          patientInstructions,
          remindPatient1h,
          remindPatient24h,
          startTime: availableSlotsTab
            ? notNull(selectedSlot).startTime
            : longStartTime,
          supportingPractitionerId: availableSlotsTab
            ? notNull(selectedSlot).supportingPractitioner?.id
            : supportingPractitioner?.id,
          locationId,
        },
      }).then(/*ignore promise result*/);
    }
  };

  const handleBackButton = () => {
    setCurrentStep((step) => step - 1);
  };

  const handleConfirmCancelButton = () => {
    history.push(`/patient/${match.params.patientId}/schedule`);
  };

  const handleNextButton = () => {
    setCurrentStep((step) => step + 1);
  };

  const toggleModal = () => {
    setCancelModal((visible) => !visible);
  };

  const getConfigDefault = (
    config: AppointmentTypeConfiguration,
  ): DraftAppointment => {
    return {
      ...initialState,
      appointmentDescription: config.internalName,
      appointmentMedium:
        config.supportedMediums.length === 1 ? config.supportedMediums[0] : "",
      selectedAppointmentConfig: config,
    };
  };

  const handleAppointmentConfigChange = (
    config: AppointmentTypeConfiguration,
  ) => {
    setAppointmentDetails(getConfigDefault(config));
  };

  const handleAppointmentMediumChange = (medium: AppointmentMedium) => {
    // Reset everything to config defaults (which should be set) except for medium and supporting type
    setAppointmentDetails((state) => ({
      ...getConfigDefault(notNull(state.selectedAppointmentConfig)),
      appointmentMedium: medium,
      supportingPractitionerType: state.supportingPractitionerType,
    }));
  };

  const handleSupportingTypeChange = (type: ?PractitionerType) => {
    // Reset everything to config defaults (which should be set) except for medium and supporting type
    setAppointmentDetails((state) => ({
      ...getConfigDefault(notNull(state.selectedAppointmentConfig)),
      appointmentMedium: state.appointmentMedium,
      supportingPractitionerType: type,
    }));
  };

  const handleStartDayChange = (day: string) => {
    setAppointmentDetails((state) => ({
      ...state,
      startDay: day,
    }));
  };

  const handleStartTimeChange = (time: string) => {
    setAppointmentDetails((state) => ({
      ...state,
      startTime: time,
    }));
  };

  const handleDurationChange = (durationInMinutes: number) => {
    setAppointmentDetails((state) => ({
      ...state,
      durationInMinutes: durationInMinutes,
    }));
  };

  const handleExternalProviderChange = (externalProvider: ExternalProvider) => {
    setAppointmentDetails((state) => ({
      ...state,
      externalProvider: externalProvider,
    }));
  };

  const renderCurrentStep = (step) => {
    if (step === 0)
      return (
        <FirstStep
          appointmentConfig={appointmentDetails.selectedAppointmentConfig}
          onAppointmentConfigChange={handleAppointmentConfigChange}
          appointmentMedium={appointmentDetails.appointmentMedium}
          onAppointmentMediumChange={handleAppointmentMediumChange}
          supportingPractitionerType={
            appointmentDetails.supportingPractitionerType
          }
          onSupportingPractitionerTypeChange={handleSupportingTypeChange}
        />
      );
    if (step === 1)
      return (
        <SecondStep
          appointmentConfig={notNull(
            appointmentDetails.selectedAppointmentConfig,
          )}
          appointmentDetails={appointmentDetails}
          patient={patient}
          onStartDayChange={handleStartTimeChange}
          onStartTimeChange={handleStartTimeChange}
          onDurationChange={handleDurationChange}
          onExternalProviderChange={handleExternalProviderChange}
          setAppointmentDetails={setAppointmentDetails}
          displayTimezone={displayTimezone}
        />
      );
    if (step === 2)
      return (
        <ThirdStep
          appointmentDetails={{
            ...appointmentDetails,
            selectedAppointmentConfig: notNull(
              appointmentDetails.selectedAppointmentConfig,
            ),
          }}
          patient={patient}
          setAppointmentDetails={setAppointmentDetails}
        />
      );
    if (step === 3)
      return (
        <FourthStep
          appointmentDetails={appointmentDetails}
          patient={patient}
          displayTimezone={displayTimezone}
        />
      );
  };

  const backButtonDisabled = currentStep === 0;

  const lastStep = currentStep === 3;

  return (
    <BreadcrumbWrapper
      disableCancel={internalLoading || externalLoading}
      disableBack={backButtonDisabled || internalLoading || externalLoading}
      disableNext={disableNextButton(appointmentDetails, currentStep)}
      lastStep={lastStep}
      bookLoading={internalLoading || externalLoading}
      handleBack={handleBackButton}
      handleNext={handleNextButton}
      handleBook={bookAppointment}
      handleCancel={toggleModal}
      activeStep={currentStep}
    >
      <Typography variant="h6" color="primary">
        New Appointment
        {currentStep !== 0 &&
          selectedAppointmentConfig &&
          `: ${selectedAppointmentConfig.internalName}`}
      </Typography>
      <Snackbar
        open={!!internalError || !!externalError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <DetailedAlert
          message="Oops! Could not complete operation. Please try again."
          additionalDetails={internalError || externalError}
        />
      </Snackbar>
      {renderCurrentStep(currentStep)}
      <Modal onClose={toggleModal} open={cancelModal}>
        <Box sx={style}>
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>
              <Typography
                color="primary"
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Are you sure?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={toggleModal}>
                <CloseIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Information will be discarded and this appointment will not be
            booked.
          </Typography>
          <Grid container mt={3} style={{ textAlign: "right" }}>
            <Grid item xs={12}>
              <Button onClick={toggleModal} style={{ marginRight: "8px" }}>
                Keep Editing
              </Button>
              <Button color="error" onClick={handleConfirmCancelButton}>
                Yes, cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </BreadcrumbWrapper>
  );
};

export default NewAppointment;

export type TabName = $Values<typeof TabNames>;

export type DraftAppointment = {
  appointmentDescription: string,
  appointmentMedium: ?AppointmentMedium,
  clinic: ?Clinic,
  durationInMinutes: ?number, // External appointments only
  externalProvider: ?ExternalProvider, // External appointments only
  filterByClinicIds: string[],
  filterByTimeOfDay: TimeOfDay[],
  filterByLeadPractitionerIds: string[],
  filterBySupportingPractitionerIds: string[],
  leadPractitioner: ?Practitioner,
  notifyPatient: boolean,
  patientInstructions: string,
  // yyyy-mm-dd
  searchFromDay: string,
  selectedAppointmentConfig: ?AppointmentTypeConfiguration,
  selectedSlot: ?AppointmentSlot,
  remindPatient1h: boolean,
  remindPatient24h: boolean,
  // yyyy-mm-dd
  startDay: string,
  // E.g. '09:30 AM'
  startTime: string,
  supportingPractitioner: ?Practitioner,
  supportingPractitionerType: ?PractitionerType,
  tab: TabName,

  // New types
  availabilitySearch: ?AvailabilitySearch,
};

type AvailabilitySearch = {
  // yyyy-mm-dd
  searchFromDay: string,
  filterByClinicIds: string[],
  filterByTimeOfDay: TimeOfDay[],
  filterByLeadPractitionerIds: string[],
  filterBySupportingPractitionerIds: string[],
};

type BreadcrumbWrapperProps = {
  disableCancel: boolean,
  disableBack: boolean,
  disableNext: boolean,
  lastStep: boolean,
  bookLoading: boolean,
  handleBack: () => void,
  handleNext: () => void,
  handleBook: () => void,
  handleCancel: () => void,
  activeStep: number,
  children: any,
};

function BreadcrumbWrapper(props: BreadcrumbWrapperProps) {
  const match = useRouteMatch();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex={1}
    >
      {/* Scrollable box for everything except bottom action bar */}
      <Box
        display="flex"
        flex={1}
        sx={{ overflowY: "auto" }}
        justifyContent="center"
      >
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          gap={2}
          flex={1}
          maxWidth={maxPatientScreenWidth}
        >
          <Box display="flex">
            <Breadcrumbs
              separator={<NavigateNextOutlinedIcon fontSize="small" />}
            >
              <Link
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
                component="button"
                color="inherit"
                onClick={props.handleCancel}
              >
                <CalendarMonthOutlinedIcon sx={{ height: 18, width: 18 }} />
                Schedule
              </Link>
              <Link
                component="button"
                color="inherit"
                underline="none"
                sx={{ cursor: "unset" }}
              >
                New
              </Link>
            </Breadcrumbs>
            <Box sx={{ width: 150 }} />
            <Stepper activeStep={props.activeStep} sx={{ flex: 1 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Paper
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {props.children}
          </Paper>
        </Box>
      </Box>
      {/* Action bar, pinned to bottom */}
      <Box
        p={2}
        display="flex"
        gap={2}
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #dedede",
          borderLeft: "1px solid #dedede",
        }}
      >
        <Button
          color="error"
          onClick={props.handleCancel}
          variant="outlined"
          disabled={props.disableCancel}
        >
          Cancel
        </Button>
        <Box flex={1} />
        <Button
          disabled={props.disableBack}
          onClick={props.handleBack}
          variant="outlined"
        >
          Back
        </Button>
        {props.lastStep ? (
          <LoadingButton
            loading={props.bookLoading}
            onClick={props.handleBook}
            variant="contained"
            color="primary"
          >
            Book
          </LoadingButton>
        ) : (
          <LoadingButton
            disabled={props.disableNext}
            onClick={props.handleNext}
            variant="contained"
            color="primary"
          >
            Next
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}
