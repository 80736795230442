import {
  dateTimeFromTargetWeek,
  maxScheduleHour,
  minScheduleHour,
  MotionTimezone,
  MotionWeekDay,
  timezoneLabels,
} from "../../libs/time";
import {
  AppointmentMedium,
  Clinic,
  MotionUser,
  TargetWeek,
} from "../../gql/graphql";
import { DateTime } from "luxon";
import {
  AppointmentStaff,
  getAppointmentTypeLabel,
  SearchParams,
} from "../../libs/booking";
import { Box, Typography } from "@mui/material";
import { AppointmentConfigMap } from "../../libs/scheduleTemplates";
import AppointmentStaffInfo from "../AppointmentStaffInfo";
import AppointmentMediumInfo from "../AppointmentMediumInfo";
import SelectClinic from "../SelectClinic";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

type BaseProps = {
  timezone: MotionTimezone;
  searchParameters: SearchParams;
  staff: AppointmentStaff;
  clinicsById: Partial<Record<string, Clinic>>;
  staffById: Partial<Record<string, MotionUser>>;
  appointmentTypeConfiguration: AppointmentConfigMap;
};

type ReadOnlyProps = BaseProps & {
  editMode: "readonly";
  time: DateTime;
  clinicId: string | null;
};

type ManualEditProps = BaseProps & {
  editMode: "manual";
  week: TargetWeek;
  weekday: MotionWeekDay;
  selectedTime: DateTime | null;
  onSelectedTimeChange: (time: DateTime) => void;
  selectedClinicId: string | null;
  onSelectedClinicIdChange: (clinicId: string) => void;
  disabled?: boolean;
};

function isReadOnly(props: Props): props is ReadOnlyProps {
  return props.editMode === "readonly";
}

type Props = ReadOnlyProps | ManualEditProps;

/**
 * Display an appointment summary, either as a read-only view or as a view
 * that allows the user to update the time of day and clinic.
 */
export function AppointmentSummary(props: Props) {
  const handleClinicChange = (clinicId: string | null) => {
    if (clinicId && !isReadOnly(props)) {
      props.onSelectedClinicIdChange(clinicId);
    }
  };

  const handleTimeChange = (time: DateTime | null) => {
    // Reconcile with the day and timezone
    if (!time || isReadOnly(props) || !time.isValid) {
      return;
    }
    props.onSelectedTimeChange(
      dateTimeFromTargetWeek(
        props.timezone,
        props.week,
        props.weekday,
        time.hour,
        time.minute,
      ),
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="h6">Appointment Details</Typography>
      <Box
        display="grid"
        gridTemplateColumns="auto 1fr"
        columnGap={2}
        rowGap={1}
        alignItems="center"
      >
        <Typography variant="body1" fontWeight="bold">
          Type
        </Typography>
        <Typography variant="body1">
          {getAppointmentTypeLabel(
            props.searchParameters.appointmentType,
            props.appointmentTypeConfiguration,
          )}
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Provider(s)
        </Typography>
        <AppointmentStaffInfo staff={props.staff} staffById={props.staffById} />
        <Typography variant="body1" fontWeight="bold">
          Time
        </Typography>
        <Box display="flex" gap={2} alignItems="center">
          <Typography>
            {isReadOnly(props)
              ? props.time.toLocaleString({
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                  hour: "numeric",
                  minute: "numeric",
                  timeZone: props.timezone,
                  timeZoneName: "short",
                })
              : dateTimeFromTargetWeek(
                  props.timezone,
                  props.week,
                  props.weekday,
                ).toLocaleString({
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                })}
          </Typography>
          {!isReadOnly(props) && (
            <LocalizationProvider
              dateAdapter={AdapterLuxon}
              adapterLocale="en-us"
            >
              <TimePicker
                label={`${timezoneLabels[props.timezone]}`}
                minutesStep={5}
                value={props.selectedTime?.setZone(props.timezone) || null}
                onChange={handleTimeChange}
                timezone={props.timezone}
                disabled={props.disabled}
                thresholdToRenderTimeInASingleColumn={1000}
                minTime={DateTime.fromObject(
                  { hour: minScheduleHour },
                  { zone: props.timezone },
                )}
                maxTime={DateTime.fromObject(
                  { hour: maxScheduleHour },
                  { zone: props.timezone },
                )}
                skipDisabled={true}
              />
            </LocalizationProvider>
          )}
        </Box>
        <Typography variant="body1" fontWeight="bold">
          Location
        </Typography>
        {isReadOnly(props) ||
        props.searchParameters.medium !== AppointmentMedium.InClinic ||
        (props.searchParameters.clinicIds.size === 1 &&
          props.selectedClinicId !== null) ? (
          <AppointmentMediumInfo
            medium={props.searchParameters.medium}
            clinicId={
              isReadOnly(props) ? props.clinicId : props.selectedClinicId
            }
            clinicsById={props.clinicsById}
          />
        ) : (
          <SelectClinic
            multiple={false}
            selectedClinicId={props.selectedClinicId}
            onSelectedClinicChange={handleClinicChange}
            shouldDisableClinic={(clinicId) =>
              !props.searchParameters.clinicIds.has(clinicId)
            }
            disabled={props.disabled}
          />
        )}
      </Box>
    </Box>
  );
}
