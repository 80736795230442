import { TargetWeek } from "../../../../../gql/graphql";
import {
  AppointmentStaff,
  isStaffTeam,
  SearchParams,
  SlotSelection,
} from "../../../../../libs/booking";
import { BaseScheduleGrid } from "./BaseScheduleGrid";
import {
  maxScheduleHour,
  minScheduleHour,
  MotionTimezone,
} from "../../../../../libs/time";
import StaffTeamScheduleGrid from "./StaffTeamScheduleGrid";
import StaffMemberScheduleGrid from "./StaffMemberScheduleGrid";
import GridToastBase from "./GridToastBase";

type Props = {
  timezone: MotionTimezone;
  staff: AppointmentStaff | null;
  searchParameters: SearchParams;
  week: TargetWeek;
  patientId: string;
  onSelect: (selection: SlotSelection) => void;
  rescheduledAppointmentId: string | undefined;
};

/**
 * Renders a weekly schedule grid for a staff member or team.
 *
 */
export default function StaffScheduleGrid(props: Props) {
  if (props.staff == null) {
    return (
      <BaseScheduleGrid
        staff={null}
        minHour={minScheduleHour}
        maxHour={maxScheduleHour}
        week={props.week}
        toast={
          <GridToastBase severity="warning">
            Select a provider to get started
          </GridToastBase>
        }
      />
    );
  }

  if (isStaffTeam(props.staff)) {
    return (
      <StaffTeamScheduleGrid
        timezone={props.timezone}
        team={props.staff}
        week={props.week}
        searchParameters={props.searchParameters}
        patientId={props.patientId}
        onSelect={props.onSelect}
        rescheduledAppointmentId={props.rescheduledAppointmentId}
      />
    );
  }

  return (
    <StaffMemberScheduleGrid
      timezone={props.timezone}
      staffId={props.staff}
      week={props.week}
      searchParameters={props.searchParameters}
      patientId={props.patientId}
      onSelect={props.onSelect}
      rescheduledAppointmentId={props.rescheduledAppointmentId}
    />
  );
}
