import { formatMinutesSinceMidnight } from "../../../../libs/scheduleTemplates";
import { Box, Typography } from "@mui/material";
import { convertMinutesToHeight } from "../../../../libs/time";

type Props = {
  startHour: number;
  endHour: number;
};
export default function GridTimes(props: Props) {
  const heightInPixels = convertMinutesToHeight(60);
  const hours = props.endHour - props.startHour + 1;
  return (
    <Box display="flex" flexDirection="column">
      {Array.from({ length: hours }, (_, index) => (
        <Box
          key={index}
          height={index === hours - 1 ? "1px" : `${heightInPixels}px`}
          display="flex"
          gap={1}
          justifyContent="flex-end"
        >
          <Typography
            variant="caption"
            color="text.secondary"
            whiteSpace="nowrap"
            position="relative"
            top={-10}
          >
            {formatMinutesSinceMidnight((props.startHour + index) * 60)}
          </Typography>
          <Box
            borderTop={(theme) => `1px solid ${theme.palette.divider}`}
            boxSizing="border-box"
            width="5px"
          />
        </Box>
      ))}
    </Box>
  );
}
