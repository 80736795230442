import React, { useEffect, useState } from "react";
import { MinimalRisaltoScreen } from "../MinimalRisaltoUI/MinimalRisaltoScreen";
import {
  initializeSessionFromCode,
  TOKENS_REFRESHED_EVENT,
} from "../AuthManager/AuthManager";

/**
 * A component that expects an okta code in the URL and uses it to initialize
 * the session locally.
 * Note: The AuthManager component initiates this flow, but this final part
 * happens in a separate window, outside the AuthManager lifecycle (since it
 * is not itself an authenticated route.)
 */
export function AuthLanding() {
  const [authCompleted, setAuthCompleted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadTokensFromCode().then();
  }, []);

  const loadTokensFromCode = async () => {
    const code = new URL(document.location.href).searchParams.get("code");
    if (!code) {
      setError("Code not found");
      return;
    }

    initializeSessionFromCode(code)
      .then(completeAuth)
      .catch((error) => setError(error));
  };

  const completeAuth = async () => {
    window.opener.postMessage(TOKENS_REFRESHED_EVENT, document.location.origin);
    setTimeout(() => {
      window.close();
    }, 100);
    setAuthCompleted(true);
  };

  if (error) {
    return (
      <MinimalRisaltoScreen
        title="Ooops! Something went wrong."
        message={
          "Please close this window and reload the Risalto Portal. Error details: " +
          JSON.stringify(error)
        }
      />
    );
  }
  if (authCompleted) {
    return (
      <MinimalRisaltoScreen
        title="Successfully authenticated."
        message="This window will close automatically in a few seconds."
      />
    );
  }
  return (
    <MinimalRisaltoScreen
      title="Authenticating..."
      message="Please wait for the Okta authentication to complete."
    />
  );
}
