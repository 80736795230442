import { AppointmentMedium, Clinic } from "../../gql/graphql";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { getMediumName } from "../../libs/scheduling";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ComputerIcon from "@mui/icons-material/Computer";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";

type Props = {
  medium: AppointmentMedium;
  clinicId: string | null;
  clinicsById: Partial<Record<string, Clinic>>;
  typography?: "body1" | "body2";
};

/**
 * Renders the medium and clinic information for an appointment as a single line.
 */
export default function AppointmentMediumInfo(props: Props) {
  const { medium, clinicsById } = props;
  // Ignore clinicId if medium is not InClinic
  const clinicId =
    medium === AppointmentMedium.InClinic ? props.clinicId : null;
  const clinic =
    clinicId && clinicId in clinicsById ? clinicsById[clinicId]! : null;
  return (
    <Box display="flex" gap={1} alignItems="center">
      <MediumIcon medium={medium} fontSize="small" sx={{ color: grey[500] }} />
      {!!clinicId ? (
        <Box display="flex" flexDirection="column">
          <Typography variant={props.typography}>
            {clinic?.name || clinicId}
          </Typography>
          {!!clinic && (
            <Typography variant="caption" color="text.secondary">
              {clinic.addressLines.join(", ")}
            </Typography>
          )}
        </Box>
      ) : (
        <Typography variant={props.typography}>
          {getMediumName(medium)}
        </Typography>
      )}
    </Box>
  );
}

function MediumIcon(props: { medium: AppointmentMedium } & SvgIconProps) {
  const { medium, ...iconProps } = props;
  switch (props.medium) {
    case AppointmentMedium.Other:
      return null;
    case AppointmentMedium.Phone:
      return <LocalPhoneIcon {...iconProps} />;
    case AppointmentMedium.AtHome:
      return <HomeOutlinedIcon {...iconProps} />;
    case AppointmentMedium.Virtual:
      return <ComputerIcon {...iconProps} />;
    case AppointmentMedium.InClinic:
      return <HomeWorkOutlinedIcon {...iconProps} />;
  }
}
