// @flow
import React, { PureComponent } from "react";
import { isMember } from "../utils";
import styles from "../theme";

type Props = {
  message: Object
};

class MessageRow extends PureComponent<Props> {
  render() {
    const { message } = this.props;
    const style = isMember(message) ? styles.other : styles.me;
    return (
      <div style={style.bubble}>
        <div style={style.text}>{message.message}</div>
      </div>
    );
  }
}

export default MessageRow;
