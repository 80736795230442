import React, { useState } from "react";
import { graphql } from "../../../gql";
import {
  AppointmentMedium,
  AppointmentType,
  ScheduleFieldsFragment,
} from "../../../gql/graphql";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Paper,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  calculateOverlapIndent,
  duplicateDay,
  formatTimezone,
  LocalSlot,
  swapAppointmentTypes,
  swapLocations,
  toLocalSlots,
  toScheduleUpdate,
} from "../../../libs/scheduleTemplates";
import WeeklySlotsEdit from "./WeeklySlotsEdit";
import { useQuery } from "@apollo/client/react/hooks";
import DetailedAlert from "../../DetailedAlert";
import { isEmpty, keyBy, uniqueId } from "lodash";
import { SplitScheduleDialog } from "./SplitScheduleDialog";
import { MergeScheduleDialog } from "./MergeScheduleDialog";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { grey } from "@mui/material/colors";
import { useAutoMutation } from "../../../hooks/autosave";
import AutoSaveStatus from "../../AutoSaveStatus";
import BlockNavigation from "../../BlockNavigation";
import DiscardDraftDialog from "./DiscardDraftDialog";
import DeferredButton from "../../DeferredButton";
import { useHistory, useRouteMatch } from "react-router-dom";
import ScheduleHeader from "../ScheduleHeader";
import { GET_APPOINTMENT_TYPE_CONFIG } from "../../SelectAppointmentType";
import {
  maxBusinessHour,
  maxScheduleHour,
  minBusinessHour,
  minScheduleHour,
} from "../../../libs/time";

type Props = {
  scheduleDraft: ScheduleFieldsFragment;
};

export const UPDATE_SCHEDULE_DRAFT = graphql(`
  mutation updateScheduleDraft($schedule: ScheduleUpdate!) {
    updateScheduleDraft(schedule: $schedule) {
      ...ScheduleFields
    }
  }
`);

/**
 * Holds current state for the full draft, performs auto-mutations.
 */
export default function ScheduleDraftEditor(props: Props) {
  const match = useRouteMatch();
  const history = useHistory();
  const [draftSlots, setDraftSlots] = useState<{
    primary: {
      [slotId: string]: LocalSlot;
    };
    alternate: { [slotId: string]: LocalSlot } | null;
  }>(() => ({
    primary: keyBy(
      toLocalSlots(props.scheduleDraft.weeklySchedule),
      (slot) => slot.id,
    ),
    alternate: !props.scheduleDraft.alternateWeeklySchedule
      ? null
      : keyBy(
          toLocalSlots(props.scheduleDraft.alternateWeeklySchedule),
          (slot) => slot.id,
        ),
  }));

  const { data, loading, error, refetch } = useQuery(
    GET_APPOINTMENT_TYPE_CONFIG,
    {
      variables: { beta: true },
    },
  );

  const [focusedSchedule, setFocusedSchedule] = useState<
    "primary" | "alternate"
  >("primary");
  const [confirmSplitSchedule, setConfirmSplitSchedule] = useState(false);
  const [confirmMergeSchedule, setConfirmMergeSchedule] = useState(false);
  const [confirmDiscard, setConfirmDiscard] = useState(false);

  const autoSaveResults = useAutoMutation(UPDATE_SCHEDULE_DRAFT, {
    schedule: toScheduleUpdate(
      props.scheduleDraft.id,
      draftSlots.primary,
      draftSlots.alternate,
    ),
  });

  if (error) {
    return (
      <DetailedAlert
        message="Oops! Something went wrong. Please try again"
        additionalDetails={error}
        retry={() => refetch()}
      />
    );
  }

  if (loading || !data) {
    return <CircularProgress />;
  }

  // Applies a transformation to the focused schedule
  const updateFocusedSlots = (
    map: (prev: { [slotId: string]: LocalSlot }) => {
      [slotId: string]: LocalSlot;
    },
  ) => {
    setDraftSlots((prevDraft) => {
      return {
        primary:
          focusedSchedule === "primary" || !prevDraft.alternate
            ? map(prevDraft.primary)
            : prevDraft.primary,
        alternate:
          focusedSchedule === "alternate" && !!prevDraft.alternate
            ? map(prevDraft.alternate)
            : prevDraft.alternate,
      };
    });
  };

  const handleMoveSlot = (
    slotId: string,
    day: number,
    offsetMinutes: number,
    copy: boolean,
  ) => {
    updateFocusedSlots((prev) => {
      const slot = prev[slotId];
      const newSlotId = copy ? uniqueId() : slotId;
      return {
        ...prev,
        [newSlotId]: {
          ...slot,
          id: newSlotId,
          dayOfWeek: day,
          startMinutes: slot.startMinutes + offsetMinutes,
          endMinutes: slot.endMinutes + offsetMinutes,
        },
      };
    });
  };

  const handleSaveSlot = (slot: LocalSlot) => {
    updateFocusedSlots((prev) => {
      return {
        ...prev,
        [slot.id]: slot,
      };
    });
  };

  const handleDeleteSlot = (slotId: string) => {
    updateFocusedSlots((prev) => {
      const { [slotId]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleDuplicateDay = (
    sourceDayOfWeek: number,
    targetDaysOfWeek: Array<number>,
  ) => {
    updateFocusedSlots((prev) =>
      duplicateDay(prev, sourceDayOfWeek, targetDaysOfWeek),
    );
  };

  const handleSwapLocations = (
    originalLocationId: string,
    newLocationId: string,
  ) => {
    updateFocusedSlots((prev) =>
      swapLocations(prev, originalLocationId, newLocationId),
    );
  };

  const appointmentTypeConfigurations = keyBy(
    data.appointmentTypeConfigurations,
    (config) => config.appointmentType,
  );

  const handleSwapAppointmentTypes = (
    originalAppointmentType: AppointmentType,
    newAppointmentType: AppointmentType,
    mediumsUpdate: {
      mediums: ReadonlySet<AppointmentMedium>;
      locationId: string | null;
    } | null,
  ) => {
    updateFocusedSlots((prev) =>
      swapAppointmentTypes(
        prev,
        originalAppointmentType,
        newAppointmentType,
        mediumsUpdate,
        appointmentTypeConfigurations,
      ),
    );
  };

  const handleSplitSchedule = (copyExistingSchedule: boolean) => {
    setDraftSlots((prevDraft) => ({
      primary: prevDraft.primary,
      // No need to deep copy as slots are immutable.
      alternate: copyExistingSchedule ? { ...prevDraft.primary } : {},
    }));
    setConfirmSplitSchedule(false);
    setFocusedSchedule("alternate");
  };

  const handleMergeSchedule = (keepAlternate: boolean) => {
    setDraftSlots((prevDraft) => ({
      primary:
        keepAlternate && prevDraft.alternate
          ? prevDraft.alternate
          : prevDraft.primary,
      alternate: null,
    }));
    setConfirmMergeSchedule(false);
    setFocusedSchedule("primary");
  };

  const focusedSlots =
    focusedSchedule === "alternate" && !!draftSlots.alternate
      ? draftSlots.alternate
      : draftSlots.primary;
  const overlaps = calculateOverlapIndent(focusedSlots);

  const handleChangeAlternateSchedule = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked && !draftSlots.alternate) {
      setConfirmSplitSchedule(true);
    }
    if (!event.target.checked && draftSlots.alternate) {
      setConfirmMergeSchedule(true);
    }
  };

  const handleContinue = () => {
    history.push(`${match.url}/publish`);
  };

  const formattedTimezone = formatTimezone(props.scheduleDraft.timezone);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex={1}
      minHeight={0}
    >
      <BlockNavigation when={autoSaveResults.dirty} />
      {/*  Scrollable box for everything except the bottom action bar */}
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: 2,
          paddingTop: 2,
          margin: 1,
          minHeight: 0,
        }}
      >
        <ScheduleHeader schedule={props.scheduleDraft} />
        <Box display="flex" gap={2} alignItems="center">
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Switch
                  checked={!!draftSlots.alternate}
                  onChange={handleChangeAlternateSchedule}
                />
              }
              label="A/B Schedule"
              sx={{ marginRight: 1 }}
            />
            <Tooltip
              title={
                <>
                  <Typography variant="caption">
                    Starting from the first day of each month:
                  </Typography>
                  <ul style={{ margin: 0, paddingLeft: 15 }}>
                    <li>
                      <Typography variant="caption">
                        Week A: 1st, 3rd, 5th week of the month
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="caption">
                        Week B: 2nd, 4th week of the month
                      </Typography>
                    </li>
                  </ul>
                </>
              }
            >
              <HelpOutlineIcon fontSize="small" sx={{ color: grey[500] }} />
            </Tooltip>
          </Box>
          {!!draftSlots.alternate && (
            <Tabs
              value={focusedSchedule}
              onChange={(_, value) => setFocusedSchedule(value)}
            >
              <Tab label="Week A" value="primary" />
              <Tab label="Week B" value="alternate" />
            </Tabs>
          )}
          <Box flex={1} />
          <Typography
            variant="body2"
            color={
              formattedTimezone.differentFromLocal ? "error" : "text.secondary"
            }
          >
            All times shown in <strong>{formattedTimezone.label}</strong>
          </Typography>
        </Box>
        <Divider />
        <WeeklySlotsEdit
          minHour={minScheduleHour}
          businessHourStart={minBusinessHour}
          businessHourEnd={maxBusinessHour}
          maxHour={maxScheduleHour}
          appointmentTypeConfigurations={appointmentTypeConfigurations}
          slots={focusedSlots}
          onMoveSlot={handleMoveSlot}
          onSaveSlot={handleSaveSlot}
          onDeleteSlot={handleDeleteSlot}
          onDuplicateDay={handleDuplicateDay}
          onSwapLocations={handleSwapLocations}
          onSwapAppointmentTypes={handleSwapAppointmentTypes}
          overlaps={overlaps}
          providerSupportsVirtual={props.scheduleDraft.staff.activeOnZoom}
        />
      </Paper>
      {confirmSplitSchedule && (
        <SplitScheduleDialog
          onClose={() => setConfirmSplitSchedule(false)}
          onSplit={handleSplitSchedule}
        />
      )}
      {confirmMergeSchedule && (
        <MergeScheduleDialog
          onClose={() => setConfirmMergeSchedule(false)}
          onMerge={handleMergeSchedule}
        />
      )}
      {confirmDiscard && (
        <DiscardDraftDialog
          schedule={props.scheduleDraft}
          onClose={() => setConfirmDiscard(false)}
        />
      )}
      {/*  Action bar, pinned to bottom */}
      <Box
        p={2}
        display="flex"
        gap={2}
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #dedede",
        }}
      >
        <DeferredButton
          deferred={autoSaveResults.dirty}
          color="error"
          variant="outlined"
          onClick={() => setConfirmDiscard(true)}
        >
          Discard Draft
        </DeferredButton>
        <Box flex={1} />
        <AutoSaveStatus results={autoSaveResults} />
        {isEmpty(overlaps) ? (
          <DeferredButton
            deferred={autoSaveResults.dirty}
            disabled={!!autoSaveResults.error}
            variant="contained"
            onClick={handleContinue}
          >
            Review and Publish
          </DeferredButton>
        ) : (
          <Tooltip title="Resolve overlapping slots to continue">
            <span>
              <Button disabled={true} variant="contained">
                Review and Publish
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}
