import {
  BodyPart,
  OrderCategory,
  OrderConfig,
  OrderGroupConfig,
  OrderInput,
  OrderLevel,
  OrderSuggestion,
  OrderSuggestionVisibility,
} from "../gql/graphql";
import {
  entries,
  every,
  find,
  groupBy,
  keyBy,
  some,
  sortBy,
  uniq,
  values,
} from "lodash";

/**
 * An order line is a single editable entry in the Orders editor.
 * It represents either a single (standalone) order, or a group of orders.
 * It is either checked or unchecked, and may not be valid.
 */
export type OrderLineConfig = SingleOrderLineConfig | GroupOrderLineConfig;

export type SingleOrderLineConfig = {
  type: "standalone";
  lineId: string;
  category: OrderCategory;
  order: OrderConfig;
};

export type GroupOrderLineConfig = {
  type: "group";
  lineId: string;
  category: OrderCategory;
  orders: Array<OrderConfig>;
  group: OrderGroupConfig;
};

/**
 * Build the order line configuration given the orders and order groups.
 */
export function buildOrderLinesConfiguration(
  ordersConfig: Array<OrderConfig>,
  orderGroupsConfig: Array<OrderGroupConfig>,
): Array<OrderLineConfig> {
  const standaloneLines: Array<SingleOrderLineConfig> = ordersConfig
    .filter((order) => order.groupId === null)
    .map((order) => ({
      type: "standalone",
      lineId: "standalone-" + order.id,
      category: order.category,
      order,
    }));

  const groupsById = keyBy(orderGroupsConfig, (group) => group.id);
  const groupLines: Array<GroupOrderLineConfig> = entries(
    groupBy(
      ordersConfig.filter((order) => order.groupId !== null),
      (order) => order.groupId,
    ),
  ).map((entry) => {
    return {
      type: "group",
      lineId: "group-" + entry[0],
      category: getGroupLineCategory(entry[1]),
      orders: entry[1],
      group: groupsById[entry[0]],
    };
  });

  return sortBy([...standaloneLines, ...groupLines], (line): string => {
    switch (line.type) {
      case "standalone":
        return line.order.name;
      case "group":
        return line.group.name;
    }
  });
}

function getGroupLineCategory(orderConfigs: Array<OrderConfig>): OrderCategory {
  if (orderConfigs.length === 0) {
    throw Error("Cannot get category for empty group");
  }
  const categories = uniq(orderConfigs.map((order) => order.category));
  if (categories.length > 1) {
    console.error("Found a group with multiple categories: ", orderConfigs);
  }
  return categories[0];
}

export type EditableOrderLine = {
  lineId: string;
  bodyParts: Array<BodyPart>; // Always empty for visit-level orders.
  selectedOrderId: string;
};

/**
 * Get order lines for the persisted orders.
 */
export function getOrderLines(
  orders: Array<OrderInput>,
  orderLinesConfig: Array<OrderLineConfig>,
): Array<EditableOrderLine> {
  const ordersById = keyBy(orders, (order) => order.orderId);
  return orderLinesConfig.flatMap(
    (line: OrderLineConfig): Array<EditableOrderLine> => {
      const savedOrder = getSavedOrderOrNull(line, ordersById);
      if (!savedOrder) {
        return [];
      }
      switch (line.type) {
        case "standalone":
          return [
            {
              lineId: line.lineId,
              selectedOrderId: line.order.id,
              bodyParts: savedOrder.bodyParts,
            },
          ];
        case "group":
          return [
            {
              lineId: line.lineId,
              bodyParts: savedOrder.bodyParts,
              selectedOrderId: savedOrder.orderId,
            },
          ];
      }
    },
  );
}

/**
 * Returns the saved order matching the line,
 * or null if the line does not match a saved order.
 */
function getSavedOrderOrNull(
  line: OrderLineConfig,
  ordersById: { [orderId: string]: OrderInput },
): OrderInput | null {
  switch (line.type) {
    case "standalone":
      return ordersById[line.order.id] ?? null;
    case "group":
      const savedOrderId = find(line.orders, (order) => order.id in ordersById)
        ?.id;
      return savedOrderId ? ordersById[savedOrderId] : null;
  }
}

/**
 * Returns true if at least 1 order in the line is joint-level.
 */
export function hasJointLevelOrder(lineConfig: OrderLineConfig): boolean {
  if (lineConfig.type === "standalone") {
    return lineConfig.order.level === OrderLevel.Joint;
  }

  return some(lineConfig.orders, (order) => order.level === OrderLevel.Joint);
}

type SearchResult = {
  results: Array<OrderLineConfig>;
  totalOrders: number;
  matchingOrders: number;
};

/**
 * Returns a list of order lines that match the search string.
 * Matching is done on both order names and group names, and
 * is case-insensitive.
 */
export function filterBySearch(
  linesConfig: Array<OrderLineConfig>,
  search: string,
): SearchResult {
  const lowerCaseSearch = search.toLowerCase();
  return linesConfig.reduce<SearchResult>(
    (acc, line) => {
      if (line.type === "standalone") {
        if (line.order.name.toLowerCase().includes(lowerCaseSearch)) {
          return {
            results: [...acc.results, line],
            totalOrders: acc.totalOrders + 1,
            matchingOrders: acc.matchingOrders + 1,
          };
        }
        return {
          ...acc,
          totalOrders: acc.totalOrders + 1,
        };
      }
      if (line.group.name.toLowerCase().includes(lowerCaseSearch)) {
        return {
          results: [...acc.results, line],
          totalOrders: acc.totalOrders + line.orders.length,
          matchingOrders: acc.matchingOrders + line.orders.length,
        };
      }

      const matchingGroupOrders = line.orders.filter((order) =>
        order.name.toLowerCase().includes(lowerCaseSearch),
      );
      if (matchingGroupOrders.length > 0) {
        return {
          results: [...acc.results, { ...line, orders: matchingGroupOrders }],
          totalOrders: acc.totalOrders + line.orders.length,
          matchingOrders: acc.matchingOrders + matchingGroupOrders.length,
        };
      }
      return {
        ...acc,
        totalOrders: acc.totalOrders + line.orders.length,
      };
    },
    { results: [], totalOrders: 0, matchingOrders: 0 },
  );
}

export function getValidOrdersFromLines(
  lines: { [lineId: string]: EditableOrderLine },
  linesConfig: { [lineId: string]: OrderLineConfig },
): Array<OrderInput> {
  return values(lines).flatMap((line) => {
    if (!!getLineErrors(line, linesConfig[line.lineId])) {
      return [];
    }
    return [
      {
        orderId: line.selectedOrderId,
        bodyParts: line.bodyParts,
      },
    ];
  });
}

export type LineErrors = {
  jointError: string | null;
};

export function getLineErrors(
  line: EditableOrderLine,
  lineConfig: OrderLineConfig,
): LineErrors | null {
  const selectedOrder =
    lineConfig.type === "standalone"
      ? lineConfig.order
      : find(lineConfig.orders, (order) => order.id === line.selectedOrderId)!;

  const jointError =
    selectedOrder?.level === OrderLevel.Joint && line.bodyParts.length === 0
      ? "Please select a body part"
      : null;
  if (jointError) {
    return {
      jointError,
    };
  }
  return null;
}

export function getAllLineErrors(
  lines: { [lineId: string]: EditableOrderLine },
  linesConfig: { [lineId: string]: OrderLineConfig },
): { [lineId: string]: LineErrors } {
  return entries(lines).reduce<{ [lineId: string]: LineErrors }>(
    (acc, [lineId, line]) => {
      const errors = getLineErrors(line, linesConfig[line.lineId]);
      if (errors) {
        acc[lineId] = errors;
      }
      return acc;
    },
    {},
  );
}

export type SuggestedAction = AddLineSuggestion | ChangeLineSuggestion;
export type AddLineSuggestion = {
  type: "add";
  line: OrderLineConfig;
  visibility: "recommended" | "add-on";
};

export type ChangeLineSuggestion = {
  type: "change";
  line: GroupOrderLineConfig;
  oldOrderName: string;
};

export function isAddSuggestion(
  suggestion: SuggestedAction,
): suggestion is AddLineSuggestion {
  return suggestion.type === "add";
}

export function isChangeSuggestion(
  suggestion: SuggestedAction,
): suggestion is ChangeLineSuggestion {
  return suggestion.type === "change";
}

export function getSuggestedActions(
  lines: { [lineId: string]: EditableOrderLine },
  linesConfig: { [lineId: string]: OrderLineConfig },
  suggestions: { [orderId: string]: OrderSuggestion },
): Array<SuggestedAction> {
  const addLineSuggestions = getAddLineSuggestions(
    values(linesConfig),
    suggestions,
  );

  // "Change" suggestions are recommended group lines that are already in the order, but have a non-recommended
  // order selected.
  const changeLineSuggestions: Array<ChangeLineSuggestion> =
    addLineSuggestions.flatMap((suggestion) => {
      const selectedOrderId = lines[suggestion.line.lineId]?.selectedOrderId;
      if (
        suggestion.visibility === "add-on" ||
        suggestion.line.type === "standalone" ||
        !selectedOrderId
      ) {
        return [];
      }
      if (
        some(suggestion.line.orders, (order) => order.id === selectedOrderId)
      ) {
        return [];
      }
      return [
        {
          type: "change",
          line: suggestion.line,
          oldOrderName: (
            linesConfig[suggestion.line.lineId] as GroupOrderLineConfig
          ).orders.find((order) => order.id === selectedOrderId)!.name,
        },
      ];
    });

  const newAddLineSuggestions = addLineSuggestions.flatMap((suggestion) => {
    if (suggestion.line.lineId in lines) {
      return [];
    }
    return [suggestion];
  });

  return [...newAddLineSuggestions, ...changeLineSuggestions];
}

/**
 * Returns all "add line" suggestions, assuming no lines are currently selected.
 */
function getAddLineSuggestions(
  linesConfig: Array<OrderLineConfig>,
  suggestions: { [orderId: string]: OrderSuggestion },
): Array<AddLineSuggestion> {
  return linesConfig.flatMap((lineConfig): Array<AddLineSuggestion> => {
    // Standalone lines
    if (lineConfig.type === "standalone") {
      const visibility = suggestions[lineConfig.order.id]?.visibility;
      if (
        visibility === OrderSuggestionVisibility.Recommended ||
        visibility === OrderSuggestionVisibility.AddOn
      ) {
        return [
          {
            type: "add",
            line: lineConfig,
            visibility:
              visibility === OrderSuggestionVisibility.Recommended
                ? "recommended"
                : "add-on",
          },
        ];
      }
      return [];
    }

    // Group lines
    const recommendedOrders = lineConfig.orders.filter(
      (order) =>
        suggestions[order.id]?.visibility ===
        OrderSuggestionVisibility.Recommended,
    );
    const addOnOrders = lineConfig.orders.filter(
      (order) =>
        suggestions[order.id]?.visibility === OrderSuggestionVisibility.AddOn,
    );
    if (recommendedOrders.length === 0 && addOnOrders.length === 0) {
      return [];
    }
    return [
      {
        type: "add",
        line: {
          ...lineConfig,
          orders:
            recommendedOrders.length > 0 ? recommendedOrders : addOnOrders,
        },
        visibility: recommendedOrders.length > 0 ? "recommended" : "add-on",
      },
    ];
  });
}

/**
 * Returns a map of line IDs to order IDs for lines that have only
 * 1 suggested order.
 */
export function getAutoSelectedOrderIds(
  suggestions: Array<ChangeLineSuggestion>,
): {
  [lineId: string]: string;
} {
  const selectedOrderIds: { [lineId: string]: string } = {};
  suggestions.forEach((suggestion) => {
    if (suggestion.line.orders.length === 1) {
      selectedOrderIds[suggestion.line.lineId] = suggestion.line.orders[0].id;
    }
  });
  return selectedOrderIds;
}
