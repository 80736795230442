import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Link as RouterLink, useHistory } from "react-router-dom";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { useMutation } from "@apollo/client";
import {
  CREATE_DRAFT_FROM_SCHEDULE,
  updateApolloScheduleCache,
} from "../../CreateScheduleDraft";
import { LoadingButton } from "@mui/lab";
import { ScheduleFieldsFragment } from "../../../../gql/graphql";
import { useEffect, useState } from "react";

type Props = {
  publishedSchedule: ScheduleFieldsFragment;
  existingDraftScheduleId: string | null;
};

export default function OpenCreateDraftButton(props: Props) {
  const history = useHistory();
  const [createDraftFromSchedule, { error, loading }] = useMutation(
    CREATE_DRAFT_FROM_SCHEDULE,
    {
      update(cache, { data }) {
        if (data?.copyExistingSchedule) {
          updateApolloScheduleCache(cache, data.copyExistingSchedule);
        }
      },
    },
  );
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    setShowErrorDialog(!!error);
  }, [error]);

  if (props.existingDraftScheduleId) {
    return (
      <Button
        variant="outlined"
        size="small"
        component={RouterLink}
        to={`/schedules/${props.existingDraftScheduleId}`}
        startIcon={<EditCalendarIcon />}
      >
        Open Draft
      </Button>
    );
  }

  const createDraft = async () => {
    const results = await createDraftFromSchedule({
      variables: {
        targetStaffId: props.publishedSchedule.staff.id,
        sourceScheduleId: props.publishedSchedule.id,
        takesEffectDate: props.publishedSchedule.takesEffectDate,
        timezone: props.publishedSchedule.timezone,
      },
    });
    const newDraft = results.data?.copyExistingSchedule;
    if (newDraft) {
      history.push(`/schedules/${newDraft.id}`);
    }
  };

  return (
    <>
      <LoadingButton
        variant="outlined"
        size="small"
        loading={loading}
        onClick={createDraft}
        startIcon={<EditCalendarIcon />}
      >
        Edit as New Draft
      </LoadingButton>
      <Dialog open={showErrorDialog} onClose={() => setShowErrorDialog(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Oops! Something went wrong; please try again. If the problem
            persists, please refresh the page and check if the draft was
            created.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
