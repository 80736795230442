// @flow
import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client/react/hooks";
import { useMutation } from "@apollo/client";
import { filter } from "lodash";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import DetailedAlert from "../../../DetailedAlert";
import { LoadingButton } from "@mui/lab";
import {
  assessmentFragment,
  CREATE_ASSESSMENT_FOR_PATIENT,
} from "../PatientAssessments";
import Chip from "@mui/material/Chip";
import { ASSESSMENT_CONFIGURATIONS } from "../../PatientVisitNotes/VisitNoteEditor/AddAssessmentDialog";
import {
  ExperimentalFeature,
  useExperimentalFeatures,
} from "../../../../hooks/experimentalFeatures";

type Props = {
  patientId: string,
  onClose: (createdAssessmentId?: string) => void,
};

export default function CreateAssessmentDialog(props: Props) {
  const { checkExperimentalFeatureEnabled } = useExperimentalFeatures();
  const showBetaAssessments = checkExperimentalFeatureEnabled(
    ExperimentalFeature.betaAssessments,
  );
  const [patientFacing, setPatientFacing] = React.useState(false);
  const [selectedAssessmentType, setSelectedAssessmentType] =
    React.useState(null);
  const { data, loading, error, refetch } = useQuery(
    ASSESSMENT_CONFIGURATIONS,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const [
    createAssessmentForPatient,
    { data: createData, loading: createLoading, error: createError },
  ] = useMutation(CREATE_ASSESSMENT_FOR_PATIENT, {
    variables: {
      patientId: props.patientId,
      assessmentType: selectedAssessmentType,
      patientFacing: patientFacing,
    },
    notifyOnNetworkStatusChange: true,
    // Add new entry to cache
    update(cache, { data: { createAssessmentForPatient } }) {
      cache.modify({
        fields: {
          assessmentsForPatient(existingAssessmentsForPatient = []) {
            const newAssessmentRef = cache.writeFragment({
              data: createAssessmentForPatient,
              fragment: assessmentFragment,
              fragmentName: "AssessmentFields",
            });
            return [...existingAssessmentsForPatient, newAssessmentRef];
          },
        },
      });
    },
  });

  useEffect(() => {
    if (!!createData?.createAssessmentForPatient) {
      props.onClose(createData.createAssessmentForPatient.id);
    }
  }, [createData]);

  const handleClose = () => {
    if (createLoading) {
      return;
    }
    props.onClose();
  };

  const handlePatientFacingFilter = (patientFacing: Boolean) => {
    setPatientFacing(patientFacing);
    setSelectedAssessmentType(null);
  };

  // If we're creating a patient-facing assessment, only display the relevant configurations. Otherwise, display all.
  const configurations = filter(
    data?.assessmentConfigurations,
    (config) => config.patientFacing || !patientFacing,
  );
  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>New Motion Assessment</DialogTitle>
      {!!error ? (
        <DialogContent>
          <DetailedAlert
            message="Failed to load assessment configurations. Please try again."
            severity="error"
            retry={(_) =>
              refetch().catch((_) => {
                /*ignore*/
              })
            }
          />
        </DialogContent>
      ) : loading ? (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Typography variant="body1">Share with:</Typography>
              </Grid>
              <Grid item xs={8}>
                <ToggleButtonGroup
                  color="primary"
                  value={patientFacing ? "patient" : "provider"}
                  exclusive={true}
                  size="small"
                  disabled={createLoading}
                >
                  <ToggleButton
                    value={"patient"}
                    onClick={(_) => handlePatientFacingFilter(true)}
                  >
                    Patient
                  </ToggleButton>
                  <ToggleButton
                    value={"provider"}
                    onClick={(_) => handlePatientFacingFilter(false)}
                  >
                    Motion Staff
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  fontStyle="italic"
                >
                  This setting cannot be changed after creating the assessment!
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">Assessment:</Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <InputLabel id="assessment-type">Assessment</InputLabel>
                  <Select
                    value={selectedAssessmentType || ""}
                    onChange={(e) => setSelectedAssessmentType(e.target.value)}
                    label={"Assessment"}
                    disabled={createLoading}
                  >
                    {configurations
                      .filter((config) => !config.beta || showBetaAssessments)
                      .map((config) => (
                        <MenuItem key={config.id} value={config.id}>
                          {config.beta && (
                            <Chip
                              label="Beta 🚧"
                              size="small"
                              sx={{ marginRight: 1 }}
                            />
                          )}
                          {config.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {!!createError && (
              <Box mt={2}>
                <DetailedAlert
                  message="Oops! Something went wrong. Please reload the page and check if the assessment was created."
                  severity="error"
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={createLoading}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              disabled={!selectedAssessmentType}
              loading={createLoading}
              onClick={createAssessmentForPatient}
            >
              Create
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
