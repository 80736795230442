import { SchedulingConflict } from "../../../gql/graphql";
import { ApolloError } from "@apollo/client";
import DetailedAlert from "../../DetailedAlert";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { capitalize } from "lodash";
import { LoadingButton } from "@mui/lab";
import BlockNavigation from "../../BlockNavigation";

type Props = {
  operation: "publish" | "delete";
  scheduleId: string;
  conflictsQueryResults: {
    conflicts?: Array<SchedulingConflict>;
    loading: boolean;
    error?: ApolloError;
    refetch: () => void;
  };
  onSubmit: (ignoredConflicts: ReadonlySet<string>) => void;
  submitLoading: boolean;
  submitError?: ApolloError;
};

const headerStyle = {
  fontWeight: "bold",
  backgroundColor: grey[100],
};

export default function ScheduleConflicts(props: Props) {
  const [showConflictList, setShowConflictList] = React.useState(false);
  const [ignoredConflicts, setIgnoredConflicts] = React.useState<
    ReadonlySet<string>
  >(new Set());

  if (props.conflictsQueryResults.error) {
    return (
      <LayoutWrapper scheduleId={props.scheduleId} operation={props.operation}>
        <DetailedAlert
          message="Oops! Something went wrong. Please try again"
          additionalDetails={props.submitError}
          retry={props.conflictsQueryResults.refetch}
        />
      </LayoutWrapper>
    );
  }

  if (
    props.conflictsQueryResults.loading ||
    !props.conflictsQueryResults.conflicts
  ) {
    return (
      <LayoutWrapper scheduleId={props.scheduleId} operation={props.operation}>
        <Box display="flex" gap={2} alignItems="center">
          <Typography variant="body1">Checking for conflicts...</Typography>
          <CircularProgress size={24} />
        </Box>
      </LayoutWrapper>
    );
  }

  const toggleIgnoredConflict = (appointmentId: string, checked: boolean) => {
    if (checked) {
      setIgnoredConflicts((prev) => new Set(prev).add(appointmentId));
    } else {
      setIgnoredConflicts((prev) => {
        const next = new Set(prev);
        next.delete(appointmentId);
        return next;
      });
    }
  };

  const hasUnresolvedConflicts = props.conflictsQueryResults.conflicts.some(
    (conflict) => !ignoredConflicts.has(conflict.appointmentId),
  );

  const handleSubmit = () => {
    props.onSubmit(ignoredConflicts);
  };

  return (
    <LayoutWrapper
      scheduleId={props.scheduleId}
      onSubmit={handleSubmit}
      submitDisabled={
        props.conflictsQueryResults.loading ||
        !!props.conflictsQueryResults.error ||
        hasUnresolvedConflicts
      }
      submitDisabledReason={
        hasUnresolvedConflicts
          ? "All conflicts must be resolved or acknowledged"
          : undefined
      }
      submitLoading={props.submitLoading}
      submitError={props.submitError}
      operation={props.operation}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body1">Appointment Conflicts</Typography>
          <IconButton
            onClick={() => props.conflictsQueryResults.refetch()}
            size="small"
          >
            <RefreshIcon fontSize="inherit" />
          </IconButton>
        </Box>
        {props.conflictsQueryResults.conflicts.length === 0 ? (
          <Alert severity="success">
            No conflicts found. You can safely {props.operation} this schedule.
          </Alert>
        ) : (
          <>
            <Typography variant="body2" color="text.secondary">
              We found some existing appointments that might conflict with the
              updated schedule. Please resolve or acknowledge all issues before
              proceeding.
              {props.operation === "publish" && (
                <>
                  <br />
                  Your draft has been saved, so feel free to come back to this
                  page later if you'd like to reschedule any conflicting
                  appointments before publishing.
                </>
              )}
            </Typography>
            <Box display="flex" gap={2} alignItems="flex-start">
              <Box flex={1} maxWidth={1100}>
                <Table size="small" sx={{ flexShrink: 1 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell width={200} style={headerStyle}>
                        Acknowledge and Ignore?
                      </TableCell>
                      <TableCell width={150} style={headerStyle}>
                        Appointment
                      </TableCell>
                      <TableCell style={headerStyle}>Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.conflictsQueryResults.conflicts.map((conflict) => (
                      <TableRow key={conflict.appointmentId}>
                        <TableCell width={200}>
                          <Checkbox
                            checked={ignoredConflicts.has(
                              conflict.appointmentId,
                            )}
                            onChange={(_, checked) => {
                              toggleIgnoredConflict(
                                conflict.appointmentId,
                                checked,
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell width={150}>
                          <Button
                            size="small"
                            href={`/patient/${conflict.patientId}/schedule/${conflict.appointmentId}`}
                            target="_blank"
                            endIcon={<OpenInNewIcon />}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell>{conflict.reasons.join("; ")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowConflictList(true)}
              >
                View as List
              </Button>
            </Box>
            {showConflictList && (
              <ConflictListDialog
                conflicts={props.conflictsQueryResults.conflicts}
                onClose={() => setShowConflictList(false)}
              />
            )}
          </>
        )}
      </Box>
    </LayoutWrapper>
  );
}

function LayoutWrapper(props: {
  scheduleId: string;
  onSubmit?: () => void;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  submitDisabledReason?: string;
  submitError?: ApolloError;
  children: React.ReactNode;
  operation: "publish" | "delete";
}) {
  const history = useHistory();

  const handleBack = () => {
    history.push(`/schedules/${props.scheduleId}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex={1}
      minHeight={0}
    >
      <BlockNavigation when={!!props.submitLoading} />
      {/* Main content */}
      <Paper
        sx={{
          margin: 1,
          padding: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" color="primary" gutterBottom={false}>
          {props.operation === "publish" ? "Publish Draft" : "Delete Schedule"}
        </Typography>
        {props.children}
      </Paper>
      {/*  Action bar, pinned at bottom */}
      <Box
        p={2}
        display="flex"
        gap={2}
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #dedede",
        }}
        alignItems="center"
      >
        <Button
          variant="outlined"
          onClick={handleBack}
          disabled={props.submitLoading}
        >
          Back
        </Button>
        <Box flex={1} />
        {props.submitError && (
          <DetailedAlert
            message={
              `Oops! Failed to ${capitalize(props.operation)} the schedule. ` +
              "Please review the list of appointment conflicts for any new " +
              "issue and try again."
            }
            additionalDetails={props.submitError}
          />
        )}
        {props.submitDisabledReason && (
          <Typography variant="body2" color="text.secondary">
            {props.submitDisabledReason}
          </Typography>
        )}
        <LoadingButton
          variant="contained"
          color="primary"
          loading={props.submitLoading}
          disabled={props.submitDisabled || !props.onSubmit}
          onClick={props.onSubmit}
        >
          {props.operation}
        </LoadingButton>
      </Box>
    </Box>
  );
}

function ConflictListDialog(props: {
  conflicts: Array<SchedulingConflict>;
  onClose: () => void;
}) {
  return (
    <Dialog open={true} onClose={props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Appointment Conflicts</DialogTitle>
      <DialogContent>
        <Box sx={{ backgroundColor: grey[100], padding: 2 }}>
          <ul style={{ paddingLeft: 20, margin: 0 }}>
            {props.conflicts.map((conflict) => (
              <li key={conflict.appointmentId}>
                <a
                  href={`/patient/${conflict.patientId}/schedule/${conflict.appointmentId}`}
                  target="_blank"
                >
                  Appointment
                </a>
                {":\t"}
                <span>{conflict.reasons.join("; ")}</span>
              </li>
            ))}
          </ul>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
