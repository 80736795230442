import React, { memo, useEffect, useState } from "react";
import {
  OrderCategory,
  OrderInput,
  OrderSuggestion,
  VisitNoteOrder,
} from "../../../../gql/graphql";
import { Box, Button, CircularProgress } from "@mui/material";
import DetailedAlert from "../../../DetailedAlert";
import { useOrderLines } from "../../../../hooks/orderLinesConfiguration";
import {
  EditableOrderLine,
  getAllLineErrors,
  getValidOrdersFromLines,
  LineErrors,
} from "../../../../libs/orderLines";
import OrdersEditorSection from "./OrdersEditorSection";
import { isEmpty, keyBy, pickBy, size, values } from "lodash";
import FinalizeOrdersDialog from "./FinalizeOrdersDialog";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Grid from "@mui/material/Unstable_Grid2";
import OrderSuggestions from "./OrderSuggestions";
import Tile from "../../../Tile";
import {
  ExperimentalFeature,
  useExperimentalFeatures,
} from "../../../../hooks/experimentalFeatures";

type Props = {
  visitNoteId: string;
  persistedOrders: Array<VisitNoteOrder>;
  orderSuggestions: Array<OrderSuggestion>;
  onOrdersChange: (orders: Array<OrderInput>) => void;
  dirty: boolean;
};

/**
 * The editor component for visit note orders.
 */
function OrdersEditor(props: Props) {
  const { checkExperimentalFeatureEnabled } = useExperimentalFeatures();
  const [lines, setLines] = useState<{
    [lineId: string]: EditableOrderLine;
  } | null>(null);
  const [lineErrors, setLineErrors] = useState<{
    [lineId: string]: LineErrors;
  }>({});
  const [showFinalizeOrdersDialog, setShowFinalizeOrdersDialog] =
    useState(false);
  const {
    linesConfig,
    suggestionsByOrderId,
    lines: initialLines,
    loading,
    error,
    refetch,
  } = useOrderLines(props.persistedOrders, props.orderSuggestions);

  useEffect(() => {
    if (!initialLines || !linesConfig) {
      return;
    }
    setLines(initialLines);
    setLineErrors(getAllLineErrors(initialLines, linesConfig));
  }, [initialLines, linesConfig]);

  if (error && !loading) {
    return (
      <DetailedAlert
        message="Oops! Something went wrong. Please try again."
        retry={() => refetch().catch(() => {})}
      />
    );
  }

  if (loading || !linesConfig || !lines || !suggestionsByOrderId) {
    return <CircularProgress />;
  }

  const handleNewLines = (newLines: {
    [lineId: string]: EditableOrderLine;
  }) => {
    setLines(newLines);
    setLineErrors(getAllLineErrors(newLines, linesConfig));
    props.onOrdersChange(getValidOrdersFromLines(newLines, linesConfig));
  };
  const handleChangeLines = (updatedLines: Array<EditableOrderLine>) => {
    const newLines = {
      ...lines,
      ...keyBy(updatedLines, (line) => line.lineId),
    };
    handleNewLines(newLines);
  };

  const handleDeleteLine = (lineId: string) => {
    const newLines = pickBy(lines, (line) => line.lineId !== lineId);
    handleNewLines(newLines);
  };

  const showOrderSuggestions = checkExperimentalFeatureEnabled(
    ExperimentalFeature.showOldOrderSuggestions,
  );
  return (
    <Grid container spacing={2}>
      <Grid xs={12} lg={showOrderSuggestions ? 7 : 8} order={{ xs: 2, lg: 1 }}>
        <Tile title={`Selected Orders (${size(lines)})`} disableContentPadding>
          <Box display="flex" flexDirection="column" flex={1} gap={2} p={1}>
            {values(OrderCategory).map((category) => (
              <Box key={category}>
                <OrdersEditorSection
                  category={category}
                  linesConfig={linesConfig}
                  lines={lines}
                  lineErrors={lineErrors}
                  suggestions={suggestionsByOrderId}
                  onChangeLine={(line) => handleChangeLines([line])}
                  onDeleteLine={handleDeleteLine}
                />
              </Box>
            ))}
          </Box>
        </Tile>
      </Grid>
      <Grid xs={12} lg={showOrderSuggestions ? 5 : 4} order={{ xs: 1, lg: 2 }}>
        <OrderSuggestions
          visitNoteId={props.visitNoteId}
          lines={lines}
          linesConfig={linesConfig}
          suggestions={suggestionsByOrderId}
          onChangeLines={handleChangeLines}
        />
      </Grid>
      <Grid xs={12} order={{ xs: 3 }}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            disabled={props.dirty || !isEmpty(lineErrors)}
            onClick={() => setShowFinalizeOrdersDialog(true)}
            endIcon={<LockOutlinedIcon />}
          >
            {props.dirty ? "Saving..." : "Finalize Orders"}
          </Button>
        </Box>
        {showFinalizeOrdersDialog && (
          <FinalizeOrdersDialog
            visitNoteId={props.visitNoteId}
            persistedOrders={props.persistedOrders}
            onClose={() => setShowFinalizeOrdersDialog(false)}
          />
        )}
      </Grid>
    </Grid>
  );
}

/**
 * We use a memoized version to avoid re-rendering orders on each keystroke in
 * the free-text SOAP fields.
 */
export default memo(OrdersEditor);
