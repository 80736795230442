import { ScheduleFieldsFragment } from "../../../gql/graphql";
import { Box, Typography } from "@mui/material";
import { DateTime } from "luxon";
import Chip from "@mui/material/Chip";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { grey, teal } from "@mui/material/colors";
import ViewPublishedScheduleButton from "../ScheduleDraftEditor/ViewPublishedScheduleButton";
import React from "react";
import PublishedScheduleActions from "../ViewEditSchedule/PublishedScheduleActions";
import { useCanManageSchedules } from "../../../hooks/authHooks";

type Props = {
  schedule: ScheduleFieldsFragment;
};

/**
 * Header component shared by the schedule editor and viewer.
 */
export default function ScheduleHeader(props: Props) {
  const canManageSchedules = useCanManageSchedules();
  return (
    <Box display="flex" alignItems="baseline" gap={1}>
      <Typography variant="h6" color="primary" gutterBottom={false}>
        {props.schedule.staff.displayName}:{" "}
        {DateTime.fromISO(props.schedule.takesEffectDate).toLocaleString(
          DateTime.DATE_SHORT,
        )}
      </Typography>
      <Chip
        label={
          <Box display="flex" gap={1} alignItems="center">
            {props.schedule.isDraft ? (
              <EditCalendarIcon fontSize="inherit" sx={{ color: grey[700] }} />
            ) : (
              <EventAvailableIcon
                fontSize="inherit"
                sx={{ color: grey[700] }}
              />
            )}
            {props.schedule.isDraft ? "Draft" : "Published"}
          </Box>
        }
        size="small"
        sx={props.schedule.isDraft ? undefined : { backgroundColor: teal[100] }}
      />
      <Typography variant="caption" color="text.secondary">
        Last updated{" "}
        {DateTime.fromSeconds(props.schedule.updatedAt).toRelative()} by{" "}
        {props.schedule.updatedByName}
      </Typography>
      <Box flex={1} />
      {props.schedule.isDraft && (
        <ViewPublishedScheduleButton draftScheduleId={props.schedule.id} />
      )}
      {!props.schedule.isDraft && canManageSchedules && (
        <PublishedScheduleActions publishedSchedule={props.schedule} />
      )}
    </Box>
  );
}
