// @flow

import type {
  AppointmentMedium,
  PractitionerType,
} from "../generated/flow_types";
import {
  AppointmentMediumValues,
  PractitionerTypeValues,
} from "../generated/flow_types";

const practitionerTypeNames = {
  [PractitionerTypeValues.CareNavigator]: "Care Navigator",
  [PractitionerTypeValues.Md]: "MD",
  [PractitionerTypeValues.Pa]: "PA",
  [PractitionerTypeValues.HealthCoach]: "Health Coach",
  [PractitionerTypeValues.Pt]: "PT",
  [PractitionerTypeValues.PainCounselor]: "Pain Counselor",
  [PractitionerTypeValues.L3]: "L3",
};

const mediumNames = {
  [AppointmentMediumValues.Phone]: "Phone",
  [AppointmentMediumValues.Virtual]: "Virtual",
  [AppointmentMediumValues.InClinic]: "In Clinic",
  [AppointmentMediumValues.AtHome]: "In-Home",
  [AppointmentMediumValues.Other]: "Other",
};

export const getPractitionerTypeName: (?PractitionerType) => string = (
  type,
) => {
  if (!type) {
    return "";
  }
  return practitionerTypeNames[type] || type;
};

export const getMediumName: (AppointmentMedium) => string = (medium) => {
  return mediumNames[medium] || medium;
};
