import * as React from 'react';
import {Box, Breadcrumbs, Link} from "@mui/material";
import {Conversation} from "../../sendbird/Conversation";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import {maxPatientScreenWidth} from "../../../theme";

type PatientChatProps = {
  memberId: string
  showTextWillNotBeSentWarning: boolean
  showPhoneNumberMissingWarning: boolean
  pollConversationState: boolean,
  showLinkToProfile: boolean,
}
export default function PatientChat(props: PatientChatProps) {
  return (
    <Box display="flex" flex={1} justifyContent="center">
      <Box p={2} display="flex" flexDirection="column" gap={2} flex={1} maxWidth={maxPatientScreenWidth}>
        <Breadcrumbs>
          <Link sx={{ display: 'flex', alignItems: 'center', gap:1 }} component="button" color="inherit" underline="none">
            <ChatOutlinedIcon sx={{height: 18, width: 18}} />
            Chat
          </Link>
        </Breadcrumbs>
        <Conversation
          showTextWillNotBeSentWarning={props.showTextWillNotBeSentWarning}
          showPhoneNumberMissingWarning={props.showPhoneNumberMissingWarning}
          memberId={props.memberId}
          pollConversationState={props.pollConversationState}
          showLinkToProfile={props.showLinkToProfile}
        />
      </Box>
    </Box>
  );
}
