import { AppointmentNotificationType } from "../../libs/booking";
import { keys } from "lodash";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";

type Props = {
  notifications: Partial<Record<AppointmentNotificationType, boolean>>;
  onNotificationsChange: (
    notifications: Partial<Record<AppointmentNotificationType, boolean>>,
  ) => void;
  disabled?: boolean;
};

/**
 * Renders the given notifications as a group of switches.
 */
export default function AppointmentNotifications(props: Props) {
  const handleChangeNotification = (
    notification: AppointmentNotificationType,
    checked: boolean,
  ) => {
    props.onNotificationsChange({
      ...props.notifications,
      [notification]: checked,
    });
  };

  return (
    <FormGroup>
      {keys(props.notifications).map((notificationType) => {
        const type = notificationType as AppointmentNotificationType;
        return (
          <FormControlLabel
            key={type}
            control={
              <Switch
                checked={props.notifications[type]}
                onChange={(_, checked) =>
                  handleChangeNotification(type, checked)
                }
                size="small"
              />
            }
            label={notificationLabels[type]}
            disabled={props.disabled}
          />
        );
      })}
    </FormGroup>
  );
}

const notificationLabels: Record<AppointmentNotificationType, string> = {
  [AppointmentNotificationType.CONFIRM_NEW_APPOINTMENT]: "Confirmation",
  [AppointmentNotificationType.CONFIRM_RESCHEDULE]: "Rescheduling Confirmation",
  [AppointmentNotificationType.CONFIRM_CANCEL]: "Cancellation Confirmation",
  [AppointmentNotificationType.REMIND_24H]: "24h Reminder",
  [AppointmentNotificationType.REMIND_1H]: "1h Reminder",
};
