import React, { MutableRefObject, useEffect, useRef } from "react";
import { useAuthentication } from "../../hooks/authHooks";
import { ApolloClient, ApolloLink, ApolloProvider } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import introspectionResults from "../../generated/fragment-matcher";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { HttpLink } from "@apollo/client/link/http";
import { env, serverHttpUrl } from "../../config";

type Props = {
  children: React.ReactNode;
};
/**
 * Main component to manage the Apollo client.
 */
export default function ApolloWrapper(props: Props) {
  const { session, refreshSession } = useAuthentication();
  const idTokenRef = useRef<string | null>(
    session?.getIdToken()?.getJwtToken() || null,
  );

  useEffect(() => {
    if (!session) {
      return;
    }
    idTokenRef.current = session.getIdToken().getJwtToken();
  }, [session]);
  const refreshTokenRef = async () => {
    const session = await refreshSession();
    idTokenRef.current = session.getIdToken().getJwtToken();
  };
  const clientRef = useRef<ApolloClient<any> | null>(null);
  if (clientRef.current === null) {
    clientRef.current = createNewApolloClient(idTokenRef, refreshTokenRef);
  }
  return (
    <ApolloProvider client={clientRef.current}>{props.children}</ApolloProvider>
  );
}

const authorizationHeader = env(
  "REACT_APP_AUTHORIZATION_HEADER",
  "Authorization",
)!;

/**
 * Create a new ApolloClient instance with a retry link that triggers a token
 * refresh if the request fails with a 401 error.
 */
function createNewApolloClient(
  idTokenRef: MutableRefObject<string | null>,
  refreshTokenRef: () => Promise<void>,
) {
  const httpLink = new HttpLink({
    uri: `${serverHttpUrl()}/graphql`,
  });
  const authLink = setContext((_, { headers }) => {
    if (isExpired(idTokenRef.current)) {
      console.log("Preemptively refreshing token");
      return refreshTokenRef().then(() => ({
        headers: {
          ...headers,
          [authorizationHeader]: idTokenRef.current
            ? `Bearer ${idTokenRef.current}`
            : "",
        },
      }));
    }
    return {
      headers: {
        ...headers,
        [authorizationHeader]: `Bearer ${idTokenRef.current ?? ""}`,
      },
    };
  });
  const recoveryLink = new RetryLink({
    delay: {
      initial: 1,
    },
    attempts: {
      max: 2,
      retryIf: (error) => {
        console.log("retry error", error.statusCode, JSON.stringify(error));
        if (
          error.statusCode !== 401 &&
          error.errorCode !== "UNAUTHORIZED_ERROR"
        ) {
          return false;
        }
        // Try and refresh the token ref
        return refreshTokenRef()
          .then(() => true)
          .catch(() => false);
      },
    },
  });

  const debugMiddleware = new ApolloLink((operation, forward) => {
    if (process.env.REACT_APP_GRAPHQL_LOGS !== "true") {
      return forward(operation);
    }
    return forward(operation).map((response) => {
      const { operationName, variables } = operation;

      if (response.errors) {
        consoleLogColor(
          `GraphQL Operation: ${operationName}\nvariables:${JSON.stringify(
            variables,
          )}\nresponse:${JSON.stringify(response)}`,
          "white",
          "crimson ",
        );
      } else {
        consoleLogColor(
          `GraphQL Operation: ${operationName}\nvariables:${JSON.stringify(
            variables,
          )}\nresponse:${JSON.stringify(response)}`,
          "white",
          "teal",
        );
      }
      return response;
    });
  });

  return new ApolloClient({
    link: ApolloLink.from([debugMiddleware, recoveryLink, authLink, httpLink]),
    cache: new InMemoryCache({
      possibleTypes: introspectionResults.possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            // Allows Apollo to find the appointment by id from the cached appointmentsByPatientAdmin data.
            appointmentByIdAdmin: {
              read(existing, { args, toReference }) {
                if (args) {
                  return toReference({
                    __typename: "MedicalAppointment",
                    id: args.appointmentId,
                  });
                }
                return existing;
              },
            },
            // We could do something similar for assessments and visit notes.
          },
        },
      },
    }),
  });
}

function consoleLogColor(text: string, color: string, backgroundColor: string) {
  console.log(
    "%c%s",
    `background: ${backgroundColor}; color: ${color}; display: block;`,
    text,
  );
}

function isExpired(token: string | null) {
  if (!token) {
    return false; // Will be refreshed as part of the regular auth flow.
  }
  try {
    const expTimestamp = JSON.parse(atob(token.split(".")[1])).exp;
    return expTimestamp * 1000 < Date.now();
  } catch (e) {
    console.log("Error parsing token", e);
    return false; // Unexpected state. Don't force a refresh.
  }
}
