import { graphql } from "../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DetailedAlert from "../DetailedAlert";
import { keyBy, keys, sortBy } from "lodash";
import { useMemo } from "react";

type Props = {
  value: string | null;
  onChange: (value: string | null) => void;
  shouldDisableStaffMember?: (staffId: string) => boolean;
};

graphql(`
  fragment MotionUserFields on MotionUser {
    id
    displayName
    credentialsAbbreviation
    avatarUrl
    activeOnZoom
  }
`);
export const MOTION_STAFF = graphql(`
  query MotionStaff {
    masqueradeableMotionUsers {
      ...MotionUserFields
    }
  }
`);

/**
 * Generic component to select a staff member.
 */
export default function SelectStaff(props: Props) {
  const { data, loading, error, refetch } = useQuery(MOTION_STAFF, {
    notifyOnNetworkStatusChange: true,
  });

  const staffMembersById = useMemo(() => {
    const sortedByName = sortBy(
      data?.masqueradeableMotionUsers,
      (staff) => staff.displayName,
    );
    return keyBy(sortedByName, (staff) => staff.id);
  }, [data]);

  if (loading) {
    return (
      <Select value="loading" disabled fullWidth>
        <MenuItem value="loading">
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body1">Loading…</Typography>
            <CircularProgress size={18} color="inherit" />
          </Box>
        </MenuItem>
      </Select>
    );
  }

  if (error) {
    return (
      <DetailedAlert
        message="Oops! Failed to load staff members. Please try again."
        additionalDetails={error}
        retry={() => refetch()}
      />
    );
  }

  const shouldDisableStaffMember = (staffId: string) => {
    return props.shouldDisableStaffMember?.(staffId) || false;
  };

  return (
    <Autocomplete
      componentsProps={{
        popper: { sx: { minWidth: 300 } },
      }}
      options={keys(staffMembersById)}
      autoHighlight
      renderInput={(params) => <TextField label="Staff Member" {...params} />}
      getOptionLabel={(staffId) =>
        !!staffId ? staffMembersById[staffId].displayName : ""
      }
      renderOption={(props, staffId) => {
        const staff = staffMembersById[staffId];
        return (
          <MenuItem
            value={staffId}
            {...props}
            disabled={shouldDisableStaffMember(staffId)}
          >
            <Box display="flex" alignItems="center" gap={2} minWidth={0}>
              <Avatar src={staff.avatarUrl || undefined}>
                {staff.displayName
                  .split(" ")
                  .map((n) => n[0])
                  .join("")}
              </Avatar>
              <Typography
                variant="body1"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {staff.displayName}
              </Typography>
              {!!staff.credentialsAbbreviation && (
                <Typography variant="body2" color="textSecondary">
                  {staff.credentialsAbbreviation}
                </Typography>
              )}
            </Box>
          </MenuItem>
        );
      }}
      value={props.value}
      onChange={(_, value) => props.onChange(value)}
    />
  );
}
