import { DateTime } from "luxon";

const startTime = DateTime.fromObject({
  year: 2022,
  month: 1,
  day: 1,
  hour: 0,
  minute: 0,
  second: 0,
});

export const timestamps = [...new Array(96)].map((_, index) => ({
  id: index,
  name: startTime
    .plus({ minutes: index * 15 })
    .setLocale("en-US")
    .toLocaleString(DateTime.TIME_SIMPLE),
  minutesFromMidnight: index * 15,
  value: startTime
    .plus({ minutes: index * 15 })
    .setLocale("en-US")
    .toLocaleString(DateTime.TIME_SIMPLE),
}));
