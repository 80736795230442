import { useMutation } from "@apollo/client";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { graphql } from "../../../gql";
import { VisitNoteFieldsFragment } from "../../../gql/graphql";
import { LoadingButton } from "@mui/lab";

const UPDATE_STATUS_TO_READY_FOR_ELATION_MUTATION = graphql(`
  mutation UpdateStatusToReadyForElation($visitNoteId: String!) {
    updateStatusToReadyForElation(visitNoteId: $visitNoteId) {
      ...VisitNoteFields
    }
  }
`);

export const ReviewAndSubmitModal: React.FC<{
  note: VisitNoteFieldsFragment;
  goBack: () => void;
}> = (props) => {
  const [updateStatus, { loading, error }] = useMutation(
    UPDATE_STATUS_TO_READY_FOR_ELATION_MUTATION,
    {
      variables: { visitNoteId: props.note.id },
    },
  );

  return (
    <div>
      <Dialog open={true}>
        <DialogTitle>{"Proceed?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you advance to the next step, you will not be able to add any
            more assessments. You will still be able to make adjustments to the
            SOAP content.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={props.goBack}>
            Go Back
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() => {
              updateStatus();
            }}
          >
            Proceed
          </LoadingButton>
          {error && (
            <Alert severity="error">
              Oops! Something went wrong. Please try again.
            </Alert>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
