import {
  AppointmentStaff,
  SearchParams,
  toStaffIdOrNull,
  toStaffTeamOrNull,
} from "../../../../../libs/booking";
import StaffMemberBrowser from "./StaffMemberBrowser";
import StaffTeamBrowser from "./StaffTeamBrowser";
import { TargetWeek } from "../../../../../gql/graphql";

type Props = {
  patientId: string;
  searchParameters: SearchParams;
  week: TargetWeek;
  selectedStaff: AppointmentStaff | null;
  onSelect: (staff: AppointmentStaff) => void;
};

/**
 * Renders a staff browser, either single or multi depending on the search.
 */
export default function StaffBrowser(props: Props) {
  if (props.searchParameters.multiDisciplinary) {
    return (
      <StaffTeamBrowser
        patientId={props.patientId}
        searchParameters={props.searchParameters}
        week={props.week}
        selectedTeam={toStaffTeamOrNull(props.selectedStaff)}
        onSelect={props.onSelect}
      />
    );
  }
  return (
    <StaffMemberBrowser
      patientId={props.patientId}
      searchParameters={props.searchParameters}
      week={props.week}
      selectedStaffId={toStaffIdOrNull(props.selectedStaff)}
      onSelect={props.onSelect}
    />
  );
}
