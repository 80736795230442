import {
  AppointmentConfigMap,
  formatMinutesSinceMidnight,
  getDayOfWeekName,
  LocalAppointmentSlot,
  LocalSlot,
  LocalUnavailableSlot,
} from "../../../../libs/scheduleTemplates";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AppointmentSlotBuilder from "./AppointmentSlotBuilder";
import UnavailableSlotBuilder from "./UnavailableSlotBuilder";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

type BaseProps = {
  appointmentTypeConfigurations: AppointmentConfigMap;
  slots: { [slotId: string]: LocalSlot };
  onSaveSlot: (slot: LocalSlot) => void;
  onClose: () => void;
  providerSupportsVirtual: boolean;
};

type EditSlotProps = BaseProps & {
  operation: "edit";
  editSlotId: string;
  onDeleteSlot: () => void;
};

type AddSlotProps = BaseProps & {
  operation: "add";
  dayOfWeek: number;
  startMinutes: number;
};

export type AddEditSlotProps = EditSlotProps | AddSlotProps;

const TabNames = {
  Available: 0,
  Blocked: 1,
};

export default function AddEditSlotDialog(props: AddEditSlotProps) {
  const [activeTab, setActiveTab] = useState(() => {
    if (props.operation === "edit") {
      return props.slots[props.editSlotId]!.type === "appointment"
        ? TabNames.Available
        : TabNames.Blocked;
    }
    return TabNames.Available;
  });
  const [validAppointmentSlot, setValidAppointmentSlot] =
    useState<LocalAppointmentSlot | null>(null);
  const [validUnavailableSlot, setValidUnavailableSlot] =
    useState<LocalUnavailableSlot | null>(null);

  const handleSave = () => {
    if (activeTab === TabNames.Available && validAppointmentSlot) {
      props.onSaveSlot(validAppointmentSlot);
    }
    if (activeTab === TabNames.Blocked && validUnavailableSlot) {
      props.onSaveSlot(validUnavailableSlot);
    }
  };

  return (
    <Dialog open={true} onClose={props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box display="flex" gap={2} alignItems="center">
            {props.operation === "edit" ? "Edit Slot" : "Add Slot"}
            <Typography variant="caption" color="text.secondary">
              {formatSlotTime(props)}
            </Typography>
          </Box>
          <Box display="flex" gap={1}>
            {props.operation === "edit" && (
              <Tooltip title="Delete slot">
                <IconButton size="small" onClick={props.onDeleteSlot}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Close">
              <IconButton size="small" onClick={props.onClose}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Tabs
            value={activeTab}
            onChange={(_, newTab) => setActiveTab(newTab)}
            sx={(theme) => ({
              marginBottom: 2,
              borderBottom: `1px solid ${theme.palette.divider}`,
            })}
          >
            <Tab label="Available" />
            <Tab label="Blocked" />
          </Tabs>
          {activeTab === TabNames.Available ? (
            <AppointmentSlotBuilder
              {...props}
              onSlotChange={setValidAppointmentSlot}
            />
          ) : (
            <UnavailableSlotBuilder
              {...props}
              onSlotChange={setValidUnavailableSlot}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={
            (activeTab === TabNames.Available && !validAppointmentSlot) ||
            (activeTab === TabNames.Blocked && !validUnavailableSlot)
          }
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function formatSlotTime(props: AddEditSlotProps) {
  let start: { dayOfWeek: number; startMinutes: number };
  if (props.operation === "add") {
    start = {
      dayOfWeek: props.dayOfWeek,
      startMinutes: props.startMinutes,
    };
  } else {
    const slot = props.slots[props.editSlotId]!;
    start = {
      dayOfWeek: slot.dayOfWeek,
      startMinutes: slot.startMinutes,
    };
  }

  return `${getDayOfWeekName(
    start.dayOfWeek,
    "ccc",
  )} - ${formatMinutesSinceMidnight(start.startMinutes)}`;
}
