import React from "react";
import { blueGrey, grey } from "@mui/material/colors";
import { Box, Typography } from "@mui/material";
import { orderCategoryNames } from "../../libs/constants";

type Props = {
  title: string | React.ReactNode;
  children: React.ReactNode;
  maxContentHeight?: number;
  disableContentPadding?: boolean;
};
export default function Tile(props: Props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      sx={{
        border: `1px solid ${grey[400]}`,
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: blueGrey[50],
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          borderBottom: `1px solid ${grey[400]}`,
        }}
        p={1}
      >
        {typeof props.title === "string" ? (
          <Typography variant="body1">{props.title}</Typography>
        ) : (
          props.title
        )}
      </Box>
      <Box
        p={props.disableContentPadding ? 0 : 2}
        {...(props.maxContentHeight
          ? { maxHeight: props.maxContentHeight, sx: { overflowY: "auto" } }
          : {})}
      >
        {props.children}
      </Box>
    </Box>
  );
}
