import GridToastBase from "../GridToastBase";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";

type Props = {
  loading: boolean;
  error: boolean;
  retry: () => void;
};

export default function GridToastErrorOrLoading(props: Props) {
  if (props.loading) {
    return (
      <GridToastBase>
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body2" color="inherit">
            Refreshing…
          </Typography>
          <CircularProgress size={18} color="inherit" />
        </Box>
      </GridToastBase>
    );
  }

  if (props.error) {
    return (
      <GridToastBase severity="error">
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body2" color="inherit">
            Something went wrong.
          </Typography>
          <Button size="small" color="inherit" onClick={props.retry}>
            Retry
          </Button>
        </Box>
      </GridToastBase>
    );
  }

  return null;
}
