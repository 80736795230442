import moment from "moment-timezone";

export const formatTimestamp = timestamp => {
  return moment(timestamp)
    .local()
    .format("ddd, MMM Do h:mm a");
};

export const isMember = message => {
  // display all messages as from "me" except member messages
  return message.sender.userId.startsWith("member-");
};

export const metadata = message => {
  const timestamp = formatTimestamp(message.createdAt);

  const data = {
    nickname: message.sender.nickname,
    originalNickname: message.sender.nickname,
    profileUrl: message.sender.profileUrl,
    credentialsAbbreviation: message.sender.metaData?.credentials_abbreviation,
    timestamp: timestamp
  };

  if (message.sender.metaData && message.sender.metaData.external_profile_url) {
    data.nickname = message.sender.metaData.external_nickname;
    data.profileUrl = message.sender.metaData.external_profile_url;
  }

  if (
    message.data &&
    message.data.length > 0 &&
    message.sender.userId.startsWith("internal-")
  ) {
    // masquerade
    let parsedData = JSON.parse(message.data);
    if (parsedData.masquerade_as_user) {
      data.nickname = parsedData.masquerade_as_user.nickname;
      data.profileUrl = parsedData.masquerade_as_user.profile_url || "";
      data.credentialsAbbreviation = parsedData.masquerade_as_user.credentials_abbreviation;
    }
  }
  return data;
};

export const sbIsImageMessage = message => {
  return message.isFileMessage() && message.type.match(/^image\/.+$/);
};
