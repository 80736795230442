import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { graphql } from "../../gql";
import React, { useState } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import DetailedAlert from "../DetailedAlert";
import { useAuthentication } from "../../hooks/authHooks";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { grey } from "@mui/material/colors";
import LoadingButton from "@mui/lab/LoadingButton";
import { find, first } from "lodash";

const REPORT_CONFIGURATIONS = graphql(`
  query reportConfigurations {
    reportConfigurations {
      id
      name
      description
    }
  }
`);

const REPORT_QUERY = graphql(`
  query report($id: String!) {
    report(id: $id)
  }
`);

export default function ReportsScreen() {
  const [selectedReportId, setSelectedReportId] = useState<string | null>(null);
  const [acknowledgeAcceptableUseWarning, setAcknowledgeAcceptableUseWarning] =
    useState(false);
  const [acknowledgeAuditWarning, setAcknowledgeAuditWarning] = useState(false);
  const { session } = useAuthentication();

  const { data, loading, error, refetch } = useQuery(REPORT_CONFIGURATIONS);

  const [
    generateReport,
    { data: reportData, loading: reportLoading, error: reportError },
  ] = useLazyQuery(REPORT_QUERY, {
    fetchPolicy: "no-cache",
  });

  if (error) {
    return (
      <ReportsWrapper>
        <DetailedAlert
          message="Failed to load reports. Please try again."
          additionalDetails={error}
          retry={() => refetch()}
        />{" "}
      </ReportsWrapper>
    );
  }

  if (loading || !data) {
    return <ReportsWrapper>Loading...</ReportsWrapper>;
  }

  const handleGenerateReport = () => {
    setAcknowledgeAcceptableUseWarning(false);
    setAcknowledgeAuditWarning(false);
    if (selectedReportId) {
      generateReport({
        variables: { id: selectedReportId },
      }).then(/* ignore results*/);
    }
  };

  return (
    <ReportsWrapper>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" gap={2} alignItems="center">
          <Typography variant="body2">Select a report:</Typography>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="report">Report</InputLabel>
            <Select
              value={selectedReportId || ""}
              onChange={(e) => setSelectedReportId(e.target.value)}
              label="Report"
              renderValue={(value) =>
                find(
                  data?.reportConfigurations,
                  (report) => report.id === value,
                )!.name
              }
            >
              {data.reportConfigurations.map((report: any) => (
                <MenuItem key={report.id} value={report.id}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1">{report.name}</Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      maxWidth={400}
                      sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {report.description}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box display="flex" gap={2} alignItems="center">
        <Alert severity="warning" sx={{ maxWidth: 600 }}>
          <Box display="flex" flexDirection="column" gap={1}>
            Please acknowledge the following:
            <FormGroup sx={{ gap: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acknowledgeAcceptableUseWarning}
                    onChange={(e) =>
                      setAcknowledgeAcceptableUseWarning(e.target.checked)
                    }
                  />
                }
                label={
                  <Typography variant="body2">
                    I will only use data from this tool for insertion into the{" "}
                    <b>explicitly approved</b> Google Sheets document.{" "}
                    <b>Any other use is prohibited.</b>
                  </Typography>
                }
                componentsProps={{ typography: { variant: "body2" } }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acknowledgeAuditWarning}
                    onChange={(e) =>
                      setAcknowledgeAuditWarning(e.target.checked)
                    }
                  />
                }
                label={
                  <Typography variant="body2">
                    I understand that all my actions in this tool are logged, my
                    identity (<b>{session?.getIdToken().payload.email}</b>) is
                    recorded, and I am personally responsible for maintaining
                    data confidentiality.
                  </Typography>
                }
                componentsProps={{ typography: { variant: "body2" } }}
              />
            </FormGroup>
          </Box>
        </Alert>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          alignItems="flex-start"
        >
          <LoadingButton
            onClick={handleGenerateReport}
            disabled={
              !selectedReportId ||
              !acknowledgeAcceptableUseWarning ||
              !acknowledgeAuditWarning
            }
            loading={reportLoading}
            variant="contained"
          >
            Generate Report
          </LoadingButton>
          {/* Report error */}
          {!!reportError && (
            <DetailedAlert
              message={
                isRateLimited(reportError)
                  ? "You are being rate limited. Please try again later."
                  : "Failed to generate report. Please try again."
              }
              additionalDetails={reportError}
              retry={handleGenerateReport}
            />
          )}
        </Box>
      </Box>
      {/*  Report */}
      {reportData && reportData.report.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {reportData.report[0].map((value, index) => (
                  <TableCell
                    key={index}
                    sx={{ fontWeight: "bold", backgroundColor: grey[100] }}
                  >
                    {value}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData.report.slice(1).map((row: any, rowIndex: number) => (
                <TableRow key={rowIndex}>
                  {row.map((value: any, index: number) => (
                    <TableCell key={index}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </ReportsWrapper>
  );
}

function ReportsWrapper(props: { children: React.ReactNode }) {
  return (
    <Paper
      sx={{
        padding: 2,
        margin: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "flex-start",
      }}
    >
      <Typography variant="h6" color="primary">
        Reports
      </Typography>
      {props.children}
    </Paper>
  );
}

function isRateLimited(error: ApolloError): boolean {
  return error.graphQLErrors.some(
    (error) => error.extensions[""]?.errorCode === "G-8001",
  );
}
