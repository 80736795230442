// @flow
import React, { PureComponent } from "react";
import ImageMessageRow from "../ImageMessageRow";
import LinkMessageRow from "../LinkMessageRow";
import VideoCallRow from "../VideoCallRow";
import MessageRow from "../MessageRow";
import styles from "../theme";
import { metadata, isMember, sbIsImageMessage } from "../utils";
import {
  Avatar, Box, Typography
} from "@mui/material";

export class Message extends PureComponent {
  render() {
    const { message } = this.props;
    const style = isMember(message) ? styles.other : styles.me;
    const data = metadata(message);

    let row = null;
    if (message.customType === "LINK") {
      row = <LinkMessageRow message={message} />;
    } else if (message.customType === "TELEMED") {
      row = <VideoCallRow message={message} />;
    } else if (sbIsImageMessage(message)) {
      row = <ImageMessageRow message={message} onPress={this.props.onPress} />;
    } else {
      row = <MessageRow message={message} />;
    }

    return (
      <Box
        id={message.messageId}
        my={2}
        display="flex"
        gap={1}
        justifyContent={isMember(message) ? "flex-start": "flex-end"}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMember(message) ? "flex-start": "flex-end"}
          gap={1}
          sx={{maxWidth: "80%"}}
        >
          {!isMember(message) && (
            <Box display="flex" alignItems="baseline">
              <Typography sx={{fontSize: 12, color: "#3E4AB8"}}>{data.nickname}</Typography>
              {data.credentialsAbbreviation && (
                <Typography sx={{fontSize: 11, color: "#949494"}}>, {data.credentialsAbbreviation}</Typography>
              )}
            </Box>
          )}
          {row}
          <Typography sx={{fontSize: 11, color: "#bbb"}}>
            {data.timestamp +
              (data.originalNickname !== data.nickname ? " · Sent by " : "")}
            {data.originalNickname !== data.nickname && (
              <span style={{fontWeight: "bold"}}>
              {data.originalNickname}
            </span>
            )}
          </Typography>
        </Box>
        {!isMember(message) && (
          <Box mt={3.5}>
            <Avatar alt={data.nickname} src={data.profileUrl}>
              {data.nickname.split(" ").map(n => n[0]).join("")}
            </Avatar>
          </Box>
        )}
      </Box>
    );
  }
}
