// @flow
import * as React from 'react';
import {useEffect, useRef} from 'react';
import gql from "graphql-tag";
import {useMutation} from "@apollo/client";
import SendbirdChat, {BaseChannel, ConnectionHandler} from '@sendbird/chat';
import {GroupChannelHandler, GroupChannelModule} from '@sendbird/chat/groupChannel';
import {JOIN_CONVERSATION} from "../../../libs/queries";
import {defaultSendbirdContext, SendbirdConversationContext} from "./SendbirdConversationProvider";

const MAX_MESSAGES_TO_FETCH = 30;

const SENDBIRD_AUTH_MUTATION = gql`
    mutation SendBirdAuthForCurrentUser {
        sendBirdAuthForCurrentUser {
            appId
            userId
            accessToken
        }
    }
`;

type SendbirdConversationProviderProps = {
  memberId: ?string,
  children: React$Node
}


/**
 * Main component to manage a Sendbird connection for a single member.
 *
 */
export const SendbirdConversationProvider = (props: SendbirdConversationProviderProps) => {
  const [activeSendbird, setActiveSendbird] = React.useState(null);
  const channelRef = useRef(null);
  const [previousMessagesQuery, setPreviousMessagesQuery] = React.useState(null);
  const [messages, setMessages] = React.useState([]);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(null);
  const [typingMembers, setTypingMembers] = React.useState([]);
  const [initializationError, setInitializationError] = React.useState(null);
  const [messageLoadingError, setMessageLoadingError] = React.useState(null);

  const [getOrCreateAuth, {data, error, loading, called}] = useMutation(SENDBIRD_AUTH_MUTATION);
  const [joinConversation] = useMutation(JOIN_CONVERSATION, {
    variables: {
      channelUrl: props.memberId
    }
  });

  useEffect(() => {
    if (!props.memberId) {
      setInitializationError("This patient is not using the app yet.")
      return;
    }

    getOrCreateAuth().then(/* ignore promise results */)
  }, [!!props.memberId]);

  const auth = data?.sendBirdAuthForCurrentUser;

  // When auth is obtained, connect to SB
  useEffect(() => {
    if(!auth) {
      return;
    }

    const sb = SendbirdChat.init({
      appId: auth.appId,
      modules: [new GroupChannelModule()]
    });

    // Channel handler
    const channelHandler = new GroupChannelHandler({
      onMessageReceived: (channel, message) => {
        if (message.messageType === "admin" || !channelMatchesMember(channel, props.memberId)) {
          return;
        }
        setMessages(messages => [...messages, message]);
        setUnreadMessageCount(channel.unreadMessageCount);
      },
      onTypingStatusUpdated: (channel) => {
        if (!channelMatchesMember(channel, props.memberId)) {
          return;
        }
        setTypingMembers(channel.getTypingUsers().map(member => member.nickname));
      },
    });
    sb.groupChannel.addGroupChannelHandler(`incoming-messages-${new Date().getTime()}`, channelHandler);

    // Connection handler
    const connectionHandler = new ConnectionHandler({
      onReconnectStarted: () => {
        console.log("reconnect started");
      },
      onReconnectSucceeded: async () => {
        console.log("reconnect succeeded");
        await refreshMessages();
      },
      onReconnectFailed: () => {
        console.log("reconnect failed");
        setInitializationError("Error reconnecting to chat service. Please refresh the page");
      }
    });
    sb.addConnectionHandler(`connection-handler-${new Date().getTime()}`, connectionHandler);

    const connectToSendbird = async () => {
      try {
        await sb.connect(auth.userId, auth.accessToken);
        setActiveSendbird(sb);
      } catch (e) {
        console.log("Error connecting to SB", error);
        setInitializationError("Error connecting to chat service. Please refresh the page");
      }
    }

    connectToSendbird().then(/* ignore promise results */);
    return () => {
      if (sb.connectionState !== 'CLOSED') {
        sb.disconnect().then(/* ignore promise results */);
      }
    }
  }, [auth]);

  // When sb is connected (or memberId changes), tear down old channel and set up the channel
  useEffect(async () => {
    // Clear channel-specific state.
    channelRef.current = null;
    setInitializationError(null);
    setMessageLoadingError(null);
    setMessages([]);
    setPreviousMessagesQuery(null);
    setTypingMembers([]);
    setUnreadMessageCount(0);

    if (!activeSendbird || !props.memberId) {
      return;
    }

    let channel;
    try {
      channel = await activeSendbird.groupChannel.getChannel(`member-${props.memberId}`);
    } catch (e) {
      console.log("Error getting channel", e);
      if (e.code !== 400108) {
        setInitializationError("Cannot join the chat conversation (has the patient verified their account yet?). Please try again later");
        return;
      }
      // code 400108 is "Not authorized. User must be a member."
      try {
        await joinConversation();
      } catch (joinException) {
        console.log("Error joining conversation", joinException);
        setInitializationError("Unknown error joining the chat conversation. Please refresh the page");
        return;
      }
      try {
        channel = await activeSendbird.groupChannel.getChannel(`member-${props.memberId}`)
      } catch (retryException) {
        console.log("Error retrying join conversation", retryException);
        setInitializationError("Unknown error joining the chat conversation. Please refresh the page");
        return;
      }
    }

    channelRef.current = channel;
    await refreshMessages();

  }, [activeSendbird, props.memberId]);

  const isReady = !!channelRef.current;

  const refreshMessages = async () => {
    if (!channelRef.current) {
      return;
    }
    const channel = channelRef.current;
    // This fixes odd caching issues with channel data (unreadMessageCount)
    await channel.refresh();
    setUnreadMessageCount(channel.unreadMessageCount);
    const query = channel.createPreviousMessageListQuery({
      limit: MAX_MESSAGES_TO_FETCH
    });
    setPreviousMessagesQuery(query);
    try {
      const newMessages = await query.load();
      setMessages(newMessages);
    } catch (e) {
      setMessageLoadingError("Failed to load messages. Please refresh this page.");
    }
  }
  /**
   * Fetch messages in the current channel.
   * @param clear if true, clear the message list and refresh.
   * @returns {Promise<void>}
   */
  const fetchMoreMessages = async () => {
    if (!isReady) {
      return;
    }

    const query = previousMessagesQuery || channelRef.current.createPreviousMessageListQuery({
      limit: MAX_MESSAGES_TO_FETCH
    });
    setPreviousMessagesQuery(query);

    if (query.isLoading || !query.hasNext) {
      console.log("Not fetching more", {loading: query.isLoading, hasNext: query.hasNext});
      return;
    }

    try {
      const newMessages = await query.load();
      setMessages([...newMessages, ...messages]);
    } catch (e) {
      setMessageLoadingError("Failed to load messages. Please refresh this page.");
    }
  }

  const sendTextMessage = async (message: string, metadata: ?any) => {
    if (!isReady) {
      return;
    }

    return new Promise((resolve, reject) => {
      channelRef.current.sendUserMessage({
        message: message,
        data: !!metadata ? JSON.stringify(metadata) : null,
      })
        .onSucceeded(newMessage => {
          setMessages([...messages, newMessage]);
          resolve();
        })
        .onFailed(_ => {
          reject();
        });
    });
  };

  const sendImageMessage = async (file: File, metadata: ?any) => {
    if (!isReady) {
      return;
    }

    return new Promise((resolve, reject) => {
      channelRef.current.sendFileMessage({
        file,
        data: !!metadata ? JSON.stringify(metadata) : null,
        thumbnailSizes: [{maxWidth: 160, maxHeight: 160}]
      })
        .onSucceeded(newMessage => {
          setMessages([...messages, newMessage]);
          resolve();
        })
        .onFailed(_ => {
          reject();
        });
    });
  };

  const markAsRead = () => {
    channelRef.current.markAsRead();
    setUnreadMessageCount(0);
  }

  if (error || initializationError) {
    const context = {
      ...defaultSendbirdContext,
      error: initializationError || 'Error retrieving chat authentication. Please refresh the page.'
    };
    return (
      <SendbirdConversationContext.Provider value={context}>
        {props.children}
      </SendbirdConversationContext.Provider>
    );
  }

  if (!isReady) {
    return (
      <SendbirdConversationContext.Provider value={defaultSendbirdContext}>
        {props.children}
      </SendbirdConversationContext.Provider>
    );
  }

  const context = {
    ready: true,
    error: null,
    messages,
    typingMembers,
    unreadMessageCount,
    patientMember: channelRef.current?.members.find(member => member.userId.startsWith("member-")),
    fetchMore: fetchMoreMessages,
    sendTextMessage,
    sendImageMessage,
    markAsRead,
    startTyping: () => {channelRef.current?.startTyping()},
    endTyping: () => {channelRef.current?.endTyping()}
  };

  return (
    <SendbirdConversationContext.Provider value={context}>
      {props.children}
    </SendbirdConversationContext.Provider>
  );
}

function channelMatchesMember(channel: BaseChannel, memberId: ?string) {
  return channel.url.replace("member-", "") === memberId;
}
