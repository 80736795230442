import { compact, mapValues, values } from "lodash";
import { TargetWeek } from "../gql/graphql";
import { DateTime } from "luxon";

/**
 * The earliest hour (in the relevant timezone) shown in schedule components.
 */
export const minScheduleHour = 7;
/**
 * Start of business hours (in the relevant timezone.)
 */
export const minBusinessHour = 8;
/**
 * End of business hours (in the relevant timezone.)
 */
export const maxBusinessHour = 17;
/**
 * The latest hour (in the relevant timezone) shown in schedule components.
 */
export const maxScheduleHour = 18;

/**
 * Valid Motion week days (1=Monday, 6=Saturday.)
 * We avoid manipulating Sunday to prevent confusion with ISO week numbers.
 */
export type MotionWeekDay = 1 | 2 | 3 | 4 | 5 | 6;

function isValidWeekDay(day: number): day is MotionWeekDay {
  return day >= 1 && day <= 6;
}

/**
 * Subset of standard timezones supported by Motion.
 * ⚠ Only timezones that observe DST are supported at this time.
 */
export enum MotionTimezone {
  NEW_YORK = "America/New_York",
}

/**
 * User-friendly timezone labels.
 */
export const timezoneLabels: Record<MotionTimezone, string> = {
  [MotionTimezone.NEW_YORK]: "Eastern Time",
};

/**
 * Check if a timezone string is a valid Motion timezone.
 */
export function isValidMotionTimezone(
  timezone: string | null | undefined,
): timezone is MotionTimezone {
  return values(MotionTimezone).includes(timezone as MotionTimezone);
}

export function getMotionTimezoneOrNull(
  timezone: string | null | undefined,
): MotionTimezone | null {
  return isValidMotionTimezone(timezone) ? timezone : null;
}

// --------------------------
// --       ISO Week       --
// --------------------------
/**
 * Get the TargetWeek for the current week (in the timezone of the current user.)
 * If the current day is Sunday, this returns the week that starts on the following Monday.
 */
export function currentTargetWeek(): TargetWeek {
  return targetWeekFromDateTime(DateTime.now());
}

/**
 * Get the TargetWeek for the given dateTime, or the following Monday if
 * the given dateTime is a Sunday.
 * Note that this is sensitive to the timezone of the given dateTime.
 */
export function targetWeekFromDateTime(dateTime: DateTime): TargetWeek {
  if (dateTime.weekday === 7) {
    console.error(
      "Sundays are not supported in targetWeekFromDateTime. Defaulting to next Monday.",
    );
    dateTime = dateTime.plus({ days: 1 });
  }
  return {
    year: dateTime.weekYear,
    week: dateTime.weekNumber,
  };
}

/**
 * Get a DateTime for the given week and weekday (1=Monday, 6=Saturday.)
 * Note: Sundays (7) are not supported here, as luxon weeks start on Monday
 * (different from US standard.)
 */
export function dateTimeFromTargetWeek(
  timezone: MotionTimezone,
  week: TargetWeek,
  weekday: MotionWeekDay = 1,
  hour: number = 0,
  minute: number = 0,
): DateTime {
  return DateTime.fromObject(
    {
      weekNumber: week.week,
      weekYear: week.year,
      weekday,
      hour,
      minute,
    },
    { zone: timezone },
  );
}

export function incrementWeek(week: TargetWeek): TargetWeek {
  const arbitraryTimezone = MotionTimezone.NEW_YORK;
  return targetWeekFromDateTime(
    dateTimeFromTargetWeek(arbitraryTimezone, week).plus({ weeks: 1 }),
  );
}

export function decrementWeek(week: TargetWeek): TargetWeek {
  const arbitraryTimezone = MotionTimezone.NEW_YORK;
  return targetWeekFromDateTime(
    dateTimeFromTargetWeek(arbitraryTimezone, week).minus({ weeks: 1 }),
  );
}

// ----------------------------------
// --       Event operations       --
// ----------------------------------
/**
 * Groups generic events by day of the week.
 * This method filters out slots that fall outside the given week or work hours.
 */
export function validateAndGroupByDay<
  T extends { startTime: number; endTime: number },
>(
  slots: Array<T>,
  week: TargetWeek,
  timezone: MotionTimezone,
  minHour: number,
  maxHour: number,
): Partial<Record<MotionWeekDay, Array<T>>> {
  return slots.reduce(
    (acc, slot) => {
      const startDateTime = DateTime.fromSeconds(slot.startTime, {
        zone: timezone,
      });
      const endDateTime = DateTime.fromSeconds(slot.endTime, {
        zone: timezone,
      });
      if (!startDateTime.hasSame(endDateTime, "day")) {
        console.error("Unexpected multi-day slot", slot);
        return acc;
      }
      const weekday = startDateTime.weekday;
      if (!isValidWeekDay(weekday)) {
        console.error("Slot falls outside of expected work days", slot);
        return acc;
      }
      const minDateTime = dateTimeFromTargetWeek(
        timezone,
        week,
        weekday,
        minHour,
      );
      const maxDateTime = dateTimeFromTargetWeek(
        timezone,
        week,
        weekday,
        maxHour,
      );
      if (startDateTime < minDateTime || endDateTime > maxDateTime) {
        console.error("Slot falls outside of expected work hours", slot);
        return acc;
      }
      return addEvent(acc, slot, weekday);
    },
    {} as Partial<Record<MotionWeekDay, Array<T>>>,
  );
}

/**
 * Groups events by day of the week.
 * Events are truncated to fit within the min and max hour.
 * Events that span multiple days are split into multiple entries.
 * Events that fall outside the given week, or outside minHour and maxHour,
 * are removed.
 */
export function sanitizeAndGroupByDay<
  T extends { startTime: number; endTime: number },
>(
  events: Array<T>,
  week: TargetWeek,
  timezone: MotionTimezone,
  minHour: number,
  maxHour: number,
): Partial<Record<MotionWeekDay, Array<T>>> {
  const eventsByDay = events.reduce(
    (acc, event) => {
      const startDateTime = DateTime.fromSeconds(event.startTime, {
        zone: timezone,
      });
      const endDateTime = DateTime.fromSeconds(event.endTime, {
        zone: timezone,
      });

      // The event may span multiple days, so add it for each day it spans.
      let currentDay = startDateTime.startOf("day");
      let newAcc = { ...acc };
      while (currentDay < endDateTime) {
        newAcc = addEvent(newAcc, event, currentDay.weekday);
        currentDay = currentDay.plus({ days: 1 });
      }
      return newAcc;
    },
    {} as Partial<Record<MotionWeekDay, Array<T>>>,
  );
  return mapValues(eventsByDay, (events, dayOfWeek) => {
    const weekday = parseInt(dayOfWeek) as MotionWeekDay;
    const minDateTime = dateTimeFromTargetWeek(
      timezone,
      week,
      weekday,
      minHour,
    );
    const maxDateTime = dateTimeFromTargetWeek(
      timezone,
      week,
      weekday,
      maxHour,
    );
    return truncateEvents(events || [], minDateTime, maxDateTime);
  });
}

function addEvent<T extends any>(
  eventsByDay: Partial<Record<MotionWeekDay, Array<T>>>,
  event: T,
  day: number,
): Partial<Record<MotionWeekDay, Array<T>>> {
  if (!isValidWeekDay(day)) {
    return eventsByDay;
  }
  return {
    ...eventsByDay,
    [day]: [...(eventsByDay[day] || []), event],
  };
}

/**
 * Truncate events to fit within the given bounds.
 * Events that fall outside of min and max hour are removed.
 */
function truncateEvents<T extends { startTime: number; endTime: number }>(
  events: Array<T>,
  minDate: DateTime,
  maxDate: DateTime,
): Array<T> {
  return compact(events.map((event) => truncateEvent(event, minDate, maxDate)));
}

/**
 * Truncate an event to fit within the given bounds.
 * If the resulting event is fully outside the bounds, null is returned.
 */
function truncateEvent<T extends { startTime: number; endTime: number }>(
  event: T,
  minDate: DateTime,
  maxDate: DateTime,
): T | null {
  const minTimestamp = minDate.toUnixInteger();
  const maxTimestamp = maxDate.toUnixInteger();

  // Event fully out of bounds
  if (event.startTime >= maxTimestamp || event.endTime <= minTimestamp) {
    return null;
  }

  // Check if event is partially out of bounds
  const newEvent = {
    ...event,
    startTime: Math.max(event.startTime, minTimestamp),
    endTime: Math.min(event.endTime, maxTimestamp),
  };
  if (newEvent.startTime >= newEvent.endTime) {
    return null;
  }

  return newEvent;
}

// ---------------------------------------
// --       Time/pixel conversion       --
// ---------------------------------------
export const timeIncrementInMinutes = 5;
export const timeIncrementToPixels = 6;

export function convertMinutesToHeight(minutes: number): number {
  return (minutes / timeIncrementInMinutes) * timeIncrementToPixels;
}

export function getTopPositionInPixels<T extends { startTime: number }>(
  event: T,
  timezone: MotionTimezone,
  dayStartHour: number,
): number {
  const dateTime = DateTime.fromSeconds(event.startTime, { zone: timezone });
  return convertMinutesToHeight(
    (dateTime.hour - dayStartHour) * 60 + dateTime.minute,
  );
}

export function getHeightInPixels<
  T extends { startTime: number; endTime: number },
>(event: T): number {
  return convertMinutesToHeight(
    Math.round((event.endTime - event.startTime) / 60),
  );
}

// -------------------------
// --       General       --
// -------------------------
export function overlaps(
  eventA: { startTime: number; endTime: number },
  eventB: { startTime: number; endTime: number },
): boolean {
  return eventA.startTime < eventB.endTime && eventA.endTime > eventB.startTime;
}
