// @flow
// javascript manipulation functions

/**
 * Extract state from zipcode.
 * from https://stackoverflow.com/a/28823169
 */
export function getStateFromZip(zipString: ?string): ?{ st: string, state: string } {

  /* Ensure param is a string to prevent unpredictable parsing results */
  if (typeof zipString !== 'string') {
    console.log('Must pass the zipcode as a string.');
    return null;
  }

  /* Ensure we have exactly 5 characters to parse */
  if (zipString.length !== 5) {
    console.log('Must pass a 5-digit zipcode.');
    return null;
  }

  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipcode = parseInt(zipString, 10);

  /* Code cases alphabetized by state */
  if (zipcode >= 35000 && zipcode <= 36999) {
    return {
      st: 'AL',
      state: 'Alabama'
    }
  }

  if (zipcode >= 99500 && zipcode <= 99999) {
    return {
      st: 'AK',
      state: 'Alaska'
    };
  }
  if (zipcode >= 85000 && zipcode <= 86999) {
    return {
      st: 'AZ',
      state: 'Arizona'
    };
  }
  if (zipcode >= 71600 && zipcode <= 72999) {
    return {
      st: 'AR',
      state: 'Arkansas'
    };
  }
  if (zipcode >= 90000 && zipcode <= 96699) {
    return {
      st: 'CA',
      state: 'California'
    };
  }
  if (zipcode >= 80000 && zipcode <= 81999) {
    return {
      st: 'CO',
      state: 'Colorado'
    };
  }
  if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
    return {
      st: 'CT',
      state: 'Connecticut'
    };
  }
  if (zipcode >= 19700 && zipcode <= 19999) {
    return {
      st: 'DE',
      state: 'Delaware'
    };
  }
  if (zipcode >= 32000 && zipcode <= 34999) {
    return {
      st: 'FL',
      state: 'Florida'
    };
  }
  if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
    return {
      st: 'GA',
      state: 'Georgia'
    };
  }
  if (zipcode >= 96700 && zipcode <= 96999) {
    return {
      st: 'HI',
      state: 'Hawaii'
    };
  }
  if (zipcode >= 83200 && zipcode <= 83999) {
    return {
      st: 'ID',
      state: 'Idaho'
    };
  }
  if (zipcode >= 60000 && zipcode <= 62999) {
    return {
      st: 'IL',
      state: 'Illinois'
    };
  }
  if (zipcode >= 46000 && zipcode <= 47999) {
    return {
      st: 'IN',
      state: 'Indiana'
    };
  }
  if (zipcode >= 50000 && zipcode <= 52999) {
    return {
      st: 'IA',
      state: 'Iowa'
    };
  }
  if (zipcode >= 66000 && zipcode <= 67999) {
    return {
      st: 'KS',
      state: 'Kansas'
    };
  }
  if (zipcode >= 40000 && zipcode <= 42999) {
    return {
      st: 'KY',
      state: 'Kentucky'
    };
  }
  if (zipcode >= 70000 && zipcode <= 71599) {
    return {
      st: 'LA',
      state: 'Louisiana'
    };
  }
  if (zipcode >= 3900 && zipcode <= 4999) {
    return {
      st: 'ME',
      state: 'Maine'
    };
  }
  if (zipcode >= 20600 && zipcode <= 21999) {
    return {
      st: 'MD',
      state: 'Maryland'
    };
  }
  if ((zipcode >= 1000 && zipcode <= 2799) || (zipcode === 5501) || (zipcode === 5544)) {
    return {
      st: 'MA',
      state: 'Massachusetts'
    };
  }
  if (zipcode >= 48000 && zipcode <= 49999) {
    return {
      st: 'MI',
      state: 'Michigan'
    };
  }
  if (zipcode >= 55000 && zipcode <= 56899) {
    return {
      st: 'MN',
      state: 'Minnesota'
    };
  }
  if (zipcode >= 38600 && zipcode <= 39999) {
    return {
      st: 'MS',
      state: 'Mississippi'
    };
  }
  if (zipcode >= 63000 && zipcode <= 65999) {
    return {
      st: 'MO',
      state: 'Missouri'
    };
  }
  if (zipcode >= 59000 && zipcode <= 59999) {
    return {
      st: 'MT',
      state: 'Montana'
    };
  }
  if (zipcode >= 27000 && zipcode <= 28999) {
    return {
      st: 'NC',
      state: 'North Carolina'
    };
  }
  if (zipcode >= 58000 && zipcode <= 58999) {
    return {
      st: 'ND',
      state: 'North Dakota'
    };
  }
  if (zipcode >= 68000 && zipcode <= 69999) {
    return {
      st: 'NE',
      state: 'Nebraska'
    };
  }
  if (zipcode >= 88900 && zipcode <= 89999) {
    return {
      st: 'NV',
      state: 'Nevada'
    };
  }
  if (zipcode >= 3000 && zipcode <= 3899) {
    return {
      st: 'NH',
      state: 'New Hampshire'
    };
  }
  if (zipcode >= 7000 && zipcode <= 8999) {
    return {
      st: 'NJ',
      state: 'New Jersey'
    };
  }
  if (zipcode >= 87000 && zipcode <= 88499) {
    return {
      st: 'NM',
      state: 'New Mexico'
    };
  }
  if ((zipcode >= 10000 && zipcode <= 14999) || (zipcode === 6390) || (zipcode === 501) || (zipcode === 544)) {
    return {
      st: 'NY',
      state: 'New York'
    };
  }
  if (zipcode >= 43000 && zipcode <= 45999) {
    return {
      st: 'OH',
      state: 'Ohio'
    };
  }
  if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
    return {
      st: 'OK',
      state: 'Oklahoma'
    };
  }
  if (zipcode >= 97000 && zipcode <= 97999) {
    return {
      st: 'OR',
      state: 'Oregon'
    };
  }
  if (zipcode >= 15000 && zipcode <= 19699) {
    return {
      st: 'PA',
      state: 'Pennsylvania'
    };
  }
  if (zipcode >= 300 && zipcode <= 999) {
    return {
      st: 'PR',
      state: 'Puerto Rico'
    };
  }
  if (zipcode >= 2800 && zipcode <= 2999) {
    return {
      st: 'RI',
      state: 'Rhode Island'
    };
  }
  if (zipcode >= 29000 && zipcode <= 29999) {
    return {
      st: 'SC',
      state: 'South Carolina'
    };
  }
  if (zipcode >= 57000 && zipcode <= 57999) {
    return {
      st: 'SD',
      state: 'South Dakota'
    };
  }
  if (zipcode >= 37000 && zipcode <= 38599) {
    return {
      st: 'TN',
      state: 'Tennessee'
    };
  }
  if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) || (zipcode >= 88500 && zipcode <= 88599)) {
    return {
      st: 'TX',
      state: 'Texas'
    };
  }
  if (zipcode >= 84000 && zipcode <= 84999) {
    return {
      st: 'UT',
      state: 'Utah'
    };
  }
  if (zipcode >= 5000 && zipcode <= 5999) {
    return {
      st: 'VT',
      state: 'Vermont'
    };
  }
  if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || (zipcode === 20598)) {
    return {
      st: 'VA',
      state: 'Virginia'
    };
  }
  if ((zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999)) {
    return {
      st: 'DC',
      state: 'Washington DC'
    };
  }
  if (zipcode >= 98000 && zipcode <= 99499) {
    return {
      st: 'WA',
      state: 'Washington'
    };
  }
  if (zipcode >= 24700 && zipcode <= 26999) {
    return {
      st: 'WV',
      state: 'West Virginia'
    };
  }
  if (zipcode >= 53000 && zipcode <= 54999) {
    return {
      st: 'WI',
      state: 'Wisconsin'
    };
  }
  if (zipcode >= 82000 && zipcode <= 83199) {
    return {
      st: 'WY',
      state: 'Wyoming'
    };
  }

  return null;
}

/** A succinct way to tell flow that a nullable-type value is actually not null. */
export function notNull<T>(value: T, message?: string): $NonMaybeType<T> {
  if (!!value) return value;
  throw new Error(message || "Value is null or undefined");
}
