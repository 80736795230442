import {
  Patient,
  VisitNote_AssessmentFieldsFragment,
  VisitNoteFieldsFragment,
  VisitNoteOrder,
} from "../../../../gql/graphql";
import React from "react";
import {
  Alert,
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { sortBy } from "lodash";
import { grey } from "@mui/material/colors";
import { DateTime } from "luxon";
import Tile from "../../../Tile";
import OrdersReadOnly from "./OrdersReadOnly";
import { elationPortalBaseUrl } from "../../../../config";
import { useHistory, useLocation } from "react-router-dom";

type Props = {
  patient: Patient;
  note: VisitNoteFieldsFragment;
};

/**
 * Read-only component to display visit note info.
 * For use when viewing completed notes and reviewing notes in progress before submitting.
 */
export default function VisitNoteViewer(props: Props) {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const handleCloseSuccessAlert = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("submitted");
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {query.get("submitted") === "success" && (
        <Alert severity="success" onClose={handleCloseSuccessAlert}>
          Visit note submitted successfully!
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid xs={12} lg={7}>
          <SOAPSection
            subjective={props.note.subjective}
            objective={props.note.objective}
            assessmentPlan={props.note.assessmentPlan}
          />
        </Grid>
        <Grid xs={12} lg={5}>
          <Box display="flex" flexDirection="column" gap={2}>
            <GeneralInfo note={props.note} patient={props.patient} />
            <OrdersSection orders={props.note.orders} />
            <AssessmentsSection assessments={props.note.assessments} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function GeneralInfo(props: {
  note: VisitNoteFieldsFragment;
  patient: Patient;
}) {
  return (
    <Paper
      sx={{ display: "flex", flexDirection: "column", padding: 2, gap: 2 }}
    >
      <Typography variant="h6" color="primary">
        General Note Info
      </Typography>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body2">
            • <strong>Created By:</strong> {props.note.createdByName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            (
            {DateTime.fromSeconds(props.note.createdAt).toLocaleString(
              DateTime.DATETIME_SHORT,
            )}
            )
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body2">
            • <strong>Completed By:</strong>{" "}
            {props.note.completionMetadata?.completedByName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            (
            {DateTime.fromSeconds(
              props.note.completionMetadata?.completedAt,
            ).toLocaleString(DateTime.DATETIME_SHORT)}
            )
          </Typography>
        </Box>
        <Typography variant="body2">
          • <strong>Published Note:</strong>{" "}
          <Link
            href={`${elationPortalBaseUrl()}/patient/${
              props.patient.elationPatientId
            }`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ marginLeft: 1 }}
          >
            View in Elation
          </Link>
        </Typography>
      </Box>
    </Paper>
  );
}

type SOAPSectionProps = {
  subjective: string;
  objective: string;
  assessmentPlan: string;
};

function SOAPSection(props: SOAPSectionProps) {
  return (
    <Paper
      sx={{ display: "flex", flexDirection: "column", padding: 2, gap: 2 }}
    >
      <Typography variant="h6" color="primary">
        SOAP
      </Typography>
      <Tile title="Subjective" maxContentHeight={290}>
        <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
          {props.subjective}
        </Typography>
      </Tile>
      <Tile title="Objective" maxContentHeight={290}>
        <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
          {props.objective}
        </Typography>
      </Tile>
      <Tile title="Assessment/Plan" maxContentHeight={290}>
        <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
          {props.assessmentPlan}
        </Typography>
      </Tile>
    </Paper>
  );
}

type AssessmentsSectionProps = {
  assessments: Array<VisitNote_AssessmentFieldsFragment>;
};
const AssessmentsSection: React.FC<AssessmentsSectionProps> = (props) => {
  const sortedAssessments = sortBy(
    props.assessments,
    (assessment) => -assessment.updatedAt,
  );
  return (
    <Paper
      sx={{ display: "flex", flexDirection: "column", padding: 2, gap: 2 }}
    >
      <Box display="flex" alignItems="center" gap={3}>
        <Typography variant="h6" color="primary">
          Assessments
        </Typography>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ backgroundColor: grey[100] }}>
            <TableCell>Assessment</TableCell>
            <TableCell>Completed On ▼</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAssessments.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="body2" color="textSecondary">
                  No assessments to display
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            sortedAssessments.map((assessment) => (
              <TableRow key={assessment.id}>
                <TableCell>{assessment.name}</TableCell>
                <TableCell>
                  {DateTime.fromSeconds(assessment.updatedAt).toLocaleString(
                    DateTime.DATETIME_MED,
                  )}
                </TableCell>
                <TableCell>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/patient/${assessment.patientId}/assessments/${assessment.id}`}
                  >
                    View
                  </Link>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

function OrdersSection(props: { orders: Array<VisitNoteOrder> }) {
  return (
    <Paper
      sx={{ display: "flex", flexDirection: "column", padding: 2, gap: 2 }}
    >
      <Typography variant="h6" color="primary">
        Orders
      </Typography>
      <OrdersReadOnly orders={props.orders} />
    </Paper>
  );
}
