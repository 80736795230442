import {
  AppointmentMedium,
  AppointmentSlotUpdate,
  AppointmentType,
  AppointmentTypeConfigurationFieldsFragment,
  ScheduleDayFieldsFragment,
  ScheduleDayUpdate,
  ScheduleUpdate,
  TimeSlotFieldsFragment,
  TimeSlotUpdate,
  UnavailableTimeSlotUpdate,
} from "../gql/graphql";
import { DateTime } from "luxon";
import {
  entries,
  findLast,
  groupBy,
  includes,
  sortBy,
  uniqueId,
  values,
} from "lodash";
import {
  convertMinutesToHeight,
  isValidMotionTimezone,
  timezoneLabels,
} from "./time";

export type LocalSlot = LocalUnavailableSlot | LocalAppointmentSlot;

export type LocalUnavailableSlot = Readonly<{
  id: string;
  type: "unavailable";
  dayOfWeek: number; // ISO
  startMinutes: number;
  endMinutes: number;
  name: string;
}>;

export type LocalAppointmentSlot = Readonly<{
  id: string;
  type: "appointment";
  dayOfWeek: number; // ISO
  startMinutes: number;
  endMinutes: number;
  appointmentType: AppointmentType;
  locationId?: string | null;
  supportedMediums: Array<AppointmentMedium>;
}>;

export type HighlightRule =
  | HighlightLocationRule
  | HighlightAppointmentTypeRule
  | HighlightRemoteRule
  | HighlightInHome;

type HighlightLocationRule = {
  type: "location";
  locationId: string;
};
type HighlightAppointmentTypeRule = {
  type: "appointmentType";
  appointmentType: AppointmentType;
};
type HighlightRemoteRule = {
  type: "remote";
};
type HighlightInHome = {
  type: "inHome";
};

/**
 * Appointment configurations keyed by appointment type.
 * Configurations may not be defined for all appointment types.
 */
export type AppointmentConfigMap = Partial<
  Record<AppointmentType, AppointmentTypeConfigurationFieldsFragment>
>;

export function toLocalSlots(
  weeklySchedule: Array<ScheduleDayFieldsFragment>,
): Array<LocalSlot> {
  return weeklySchedule.flatMap((day) =>
    day.hours.map((slot) => toLocalSlot(day.dayOfWeek, slot)),
  );
}

function toLocalSlot(
  dayOfWeek: number,
  slot: TimeSlotFieldsFragment,
): LocalSlot {
  const startMinutes = slot.minutesSinceMidnight;
  const endMinutes = slot.minutesSinceMidnight + slot.durationInMinutes;

  if (slot.__typename === "AppointmentTimeSlot") {
    return {
      id: uniqueId(),
      type: "appointment",
      dayOfWeek,
      startMinutes,
      endMinutes,
      appointmentType: slot.appointmentType,
      locationId: slot.locationId,
      supportedMediums: slot.supportedMediums,
    };
  }
  if (slot.__typename === "UnavailableTimeSlot") {
    return {
      id: uniqueId(),
      type: "unavailable",
      dayOfWeek,
      startMinutes,
      endMinutes,
      name: slot.name,
    };
  }
  throw new Error(`Unknown slot type ${slot.__typename}`);
}

/**
 * Formats "minutes since midnight" to a string in the format "HH:mm AM"
 * @param minutes minutes since midnight
 */
export function formatMinutesSinceMidnight(minutes: number): string {
  if (minutes % 60 == 0) {
    return DateTime.fromObject({ hour: minutes / 60 }).toFormat("h a");
  }
  return DateTime.fromObject({
    hour: Math.floor(minutes / 60),
    minute: minutes % 60,
  }).toLocaleString(DateTime.TIME_SIMPLE);
}

export function getPositionFromTop(
  dayStartHour: number,
  slot: LocalSlot,
): number {
  return convertMinutesToHeight(slot.startMinutes - dayStartHour * 60);
}

/**
 * Identifies overlapping (incorrect) slots and returns an indentation level
 * for each overlapping slot that guarantees that all slots are visible in
 * the weekly view.
 * Note: naive but straightforward implementation. Does not guarantee the
 * smallest indentation level.
 */
export function calculateOverlapIndent(slots: {
  [slotId: string]: LocalSlot;
}): {
  [slotId: string]: number;
} {
  const groupedByDay = groupBy(values(slots), (slot) => slot.dayOfWeek);

  const overlaps: { [slotId: string]: number } = {};
  for (const dayOfWeek in groupedByDay) {
    const daySlots = groupedByDay[dayOfWeek];
    const sortedSlots = sortBy(daySlots, (slot) => slot.startMinutes);
    sortedSlots.forEach((slot, index) => {
      if (index == 0) {
        return;
      }
      if (slot.startMinutes < sortedSlots[index - 1].endMinutes) {
        overlaps[slot.id] = (overlaps[sortedSlots[index - 1].id] || 0) + 1;
      }
    });
  }
  return overlaps;
}

export function getDayOfWeekName(
  dayOfWeek: number,
  format: "cccc" | "ccc" = "cccc",
): string {
  return DateTime.fromObject({ weekday: dayOfWeek }).toFormat(format);
}

/**
 * Returns a shallow copy of the weeklySlots object, duplicating all slots
 * in sourceDayOfWeek to targetDaysOfWeeks.
 */
export function duplicateDay(
  weeklySlots: { [slotId: string]: LocalSlot },
  sourceDayOfWeek: number,
  targetDaysOfWeeks: Array<number>,
): { [slotId: string]: LocalSlot } {
  if (targetDaysOfWeeks.length === 0) {
    return weeklySlots;
  }

  const newSlots: { [slotId: string]: LocalSlot } = {};
  for (const slotId in weeklySlots) {
    const slot = weeklySlots[slotId];
    // Source day
    if (slot.dayOfWeek === sourceDayOfWeek) {
      newSlots[slotId] = slot;
      for (const targetDay of targetDaysOfWeeks) {
        const newId = uniqueId();
        newSlots[newId] = {
          ...slot,
          id: newId,
          dayOfWeek: targetDay,
        };
      }
    } else if (!includes(targetDaysOfWeeks, slot.dayOfWeek)) {
      newSlots[slotId] = slot;
    }
  }
  return newSlots;
}

/**
 * Returns a shallow copy of the weeklySlots object, swapping locations
 * for all matching slots.
 */
export function swapLocations(
  weeklySlots: { [slotId: string]: LocalSlot },
  originalLocationId: string,
  newLocationId: string,
): { [slotId: string]: LocalSlot } {
  const newSlots: { [slotId: string]: LocalSlot } = {};
  for (const slotId in weeklySlots) {
    const slot = weeklySlots[slotId];
    if (slot.type === "appointment" && slot.locationId === originalLocationId) {
      newSlots[slotId] = {
        ...slot,
        locationId: newLocationId,
      };
    } else {
      newSlots[slotId] = slot;
    }
  }
  return newSlots;
}

/**
 * Returns a shallow copy of the weeklySlots object, swapping appointment types
 * for all matching slots. Slot durations might be adjusted to match the
 * new appointment type.
 */
export function swapAppointmentTypes(
  weeklySlots: { [slotId: string]: LocalSlot },
  originalAppointmentType: AppointmentType,
  newAppointmentType: AppointmentType,
  mediumsUpdate: {
    mediums: ReadonlySet<AppointmentMedium>;
    locationId: string | null;
  } | null,
  appointmentTypeConfigurations: AppointmentConfigMap,
): { [slotId: string]: LocalSlot } {
  const newAppointmentTypeConfig =
    appointmentTypeConfigurations[newAppointmentType];
  if (!newAppointmentTypeConfig) {
    console.error(
      `No configuration found for appointment type ${newAppointmentType}. Ignoring swap.`,
    );
    return weeklySlots;
  }
  const newDuration = newAppointmentTypeConfig.durationInMinutes;
  const newSlots: { [slotId: string]: LocalSlot } = {};
  for (const slotId in weeklySlots) {
    const slot = weeklySlots[slotId];
    if (
      slot.type === "appointment" &&
      slot.appointmentType === originalAppointmentType
    ) {
      newSlots[slotId] = {
        ...slot,
        appointmentType: newAppointmentType,
        endMinutes: slot.startMinutes + newDuration,
        locationId: !!mediumsUpdate
          ? mediumsUpdate.locationId
          : slot.locationId,
        supportedMediums: !!mediumsUpdate
          ? Array.from(mediumsUpdate.mediums)
          : slot.supportedMediums,
      };
    } else {
      newSlots[slotId] = slot;
    }
  }
  return newSlots;
}

export function isHighlighted(
  slot: LocalSlot,
  rule: HighlightRule | null | undefined,
): boolean {
  if (!rule) {
    return false;
  }
  if (rule.type === "location") {
    return slot.type === "appointment" && slot.locationId === rule.locationId;
  }
  if (rule.type === "appointmentType") {
    return (
      slot.type === "appointment" &&
      slot.appointmentType === rule.appointmentType
    );
  }
  if (rule.type === "inHome") {
    return (
      slot.type === "appointment" &&
      slot.locationId === null &&
      slot.supportedMediums.includes(AppointmentMedium.AtHome)
    );
  }
  // Remote only
  return (
    slot.type === "appointment" &&
    slot.locationId === null &&
    !slot.supportedMediums.includes(AppointmentMedium.AtHome)
  );
}

export function toScheduleUpdate(
  scheduleId: string,
  weeklySlots: { [slotId: string]: LocalSlot },
  alternateWeeklySlots: { [slotId: string]: LocalSlot } | null,
): ScheduleUpdate {
  return {
    id: scheduleId,
    weeklySchedule: toScheduleDaysUpdate(weeklySlots),
    alternateWeeklySchedule: !alternateWeeklySlots
      ? null
      : toScheduleDaysUpdate(alternateWeeklySlots),
  };
}

function toScheduleDaysUpdate(weeklySlots: {
  [slotId: string]: LocalSlot;
}): Array<ScheduleDayUpdate> {
  const groupedByDay = groupBy(values(weeklySlots), (slot) => slot.dayOfWeek);
  return entries(groupedByDay).map(([dayOfWeek, slots]) => {
    const dayOfWeekNumber = parseInt(dayOfWeek);
    const daySlots = slots.map((slot) => toTimeSlotUpdate(slot));
    return {
      dayOfWeek: dayOfWeekNumber,
      hours: daySlots,
    };
  });
}

function toTimeSlotUpdate(slot: LocalSlot): TimeSlotUpdate {
  let appointmentSlotUpdate: AppointmentSlotUpdate | null = null;
  let unavailableTimeSlotUpdate: UnavailableTimeSlotUpdate | null = null;
  if (slot.type === "appointment") {
    appointmentSlotUpdate = {
      locationId: slot.locationId,
      appointmentType: slot.appointmentType,
      supportedMediums: slot.supportedMediums,
    };
  } else {
    unavailableTimeSlotUpdate = {
      name: slot.name,
    };
  }
  return {
    minutesSinceMidnight: slot.startMinutes,
    durationInMinutes: slot.endMinutes - slot.startMinutes,
    appointmentSlotUpdate,
    unavailableTimeSlotUpdate,
  };
}

export function formatTimezone(timezone: string): {
  label: string;
  differentFromLocal: boolean;
} {
  return {
    label: isValidMotionTimezone(timezone)
      ? timezoneLabels[timezone]
      : timezone,
    differentFromLocal: DateTime.local().zoneName !== timezone,
  };
}

export function getSlotName(
  slot: LocalSlot,
  appointmentTypeConfigurations: AppointmentConfigMap,
): string {
  if (slot.type === "unavailable") {
    return slot.name;
  }
  return (
    appointmentTypeConfigurations[slot.appointmentType]?.internalName ||
    slot.appointmentType
  );
}

/**
 * Checks if a draft can be created for the selected staff member and date,
 * given the existing schedules.
 * @param staffId the staff member for which the draft is created
 * @param startDate the start date of the tentative draft
 * @param allStaffSchedules exhaustive list of schedules for this staff member
 */
export function validateTentativeDraft(
  staffId: string | null,
  startDate: DateTime | null,
  allStaffSchedules: Array<{
    takesEffectDate: string;
    isDraft: boolean;
  }>,
): {
  allowed: boolean;
  message?: string;
} {
  if (!staffId || !startDate) {
    return {
      allowed: false,
    };
  }

  const schedulesSorted = sortBy(
    allStaffSchedules,
    (schedule) =>
      // Sorting by string, but should work with ISO
      schedule.takesEffectDate,
  );

  // No more than 1 draft per provider per date.
  const overwritesDraft = schedulesSorted.some(
    (schedule) =>
      schedule.takesEffectDate === startDate.toISODate() && schedule.isDraft,
  );
  if (overwritesDraft) {
    return {
      allowed: false,
      message: "A draft already exists for this date and provider.",
    };
  }

  // If there's already a schedule (draft or published) that starts in the past,
  // no other past draft can be created.
  const currentActiveTakesEffect = findLast(
    schedulesSorted.map((schedule) =>
      DateTime.fromISO(schedule.takesEffectDate),
    ),
    (takesEffectDate) => takesEffectDate <= DateTime.now(),
  );
  if (
    !!currentActiveTakesEffect &&
    !startDate.hasSame(currentActiveTakesEffect, "day") &&
    startDate < DateTime.now()
  ) {
    return {
      allowed: false,
      message:
        'Please update the "current" schedule instead (' +
        currentActiveTakesEffect.toISODate() +
        ").",
    };
  }

  // Otherwise, overwriting a published schedule is allowed.
  const overwritesPublished = schedulesSorted.some(
    (schedule) =>
      schedule.takesEffectDate === startDate.toISODate() && !schedule.isDraft,
  );
  return {
    allowed: true,
    message: overwritesPublished
      ? "This draft will overwrite an existing schedule when published."
      : undefined,
  };
}
