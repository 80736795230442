import {
  AppointmentConfigMap,
  formatMinutesSinceMidnight,
  getSlotName,
  LocalSlot,
} from "../../../../libs/scheduleTemplates";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_CLINICS } from "../../../SelectClinic";
import { find } from "lodash";
import { getMediumName } from "../../../../libs/scheduling";

type Props = {
  slot: LocalSlot;
  appointmentTypeConfigurations: AppointmentConfigMap;
};

/**
 * Read-only slot details
 */
export default function SlotDetails(props: Props) {
  const mediums =
    props.slot.type === "unavailable" ? [] : props.slot.supportedMediums;
  const locationId =
    props.slot.type === "unavailable" ? null : props.slot.locationId;

  const { data, loading } = useQuery(GET_CLINICS);
  const clinic = find(data?.clinics, (clinic) => clinic.id === locationId);

  return (
    <Box p={2} display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        gap={2}
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="body1">
          {getSlotName(props.slot, props.appointmentTypeConfigurations)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatMinutesSinceMidnight(props.slot.startMinutes)}
          {" - "}
          {formatMinutesSinceMidnight(props.slot.endMinutes)}
        </Typography>
      </Box>
      {locationId && (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2" color="text.secondary">
            {loading ? "Loading clinic info..." : clinic?.name || locationId}
          </Typography>
          {!!clinic && (
            <Typography variant="caption" color="text.secondary">
              {clinic.addressLines.join(", ")}
            </Typography>
          )}
        </Box>
      )}
      <Typography variant="body2" color="text.secondary">
        {mediums.map(getMediumName).join(", ")}
      </Typography>
    </Box>
  );
}
