import { OrderCategory } from "../../../../gql/graphql";
import {
  EditableOrderLine,
  filterBySearch,
  OrderLineConfig,
} from "../../../../libs/orderLines";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { orderCategoryNames } from "../../../../libs/constants";
import React, { ReactNode, useState } from "react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { blueGrey, grey } from "@mui/material/colors";
import { values } from "lodash";

type Props = {
  category: OrderCategory;
  linesConfig: { [lineId: string]: OrderLineConfig };
  visibleLines: { [lineId: string]: EditableOrderLine };
  onClose: (selected: { lineId: string; orderId: string } | null) => void;
};

/**
 * Renders a dialog to add an order.
 */
export default function AddOrderDialog(props: Props) {
  const [search, setSearch] = useState("");
  const [collapsedGroupLines, setCollapsedGroupLines] = useState<{
    [lineId: string]: boolean;
  }>({});
  const availableLinesToAdd = values(props.linesConfig).filter(
    (line) =>
      line.category === props.category && !(line.lineId in props.visibleLines),
  );

  if (availableLinesToAdd.length === 0) {
    return (
      <DialogWrapper
        category={props.category}
        onClose={() => props.onClose(null)}
      >
        <Typography variant="body1" color="textSecondary">
          No other orders available to add.
        </Typography>
      </DialogWrapper>
    );
  }

  const showHideGroup = (lineId: string) => {
    setCollapsedGroupLines({
      ...collapsedGroupLines,
      [lineId]: !collapsedGroupLines[lineId],
    });
  };

  const searchResults = filterBySearch(availableLinesToAdd, search);

  return (
    <DialogWrapper
      category={props.category}
      onClose={() => props.onClose(null)}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" gap={1} alignItems="center" mt={0.5}>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Orders"
            inputProps={{ autoFocus: true }}
            size="small"
          />
          <Typography variant="caption" color="textSecondary">
            Tab to navigate, Enter to select
          </Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body1" fontWeight="bold">
            Select an order to add:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {searchResults.matchingOrders} of {searchResults.totalOrders}{" "}
            visible
          </Typography>
        </Box>
        <List component="nav" disablePadding>
          {searchResults.results.map((line) => {
            if (line.type === "standalone") {
              return (
                <ListItemButton
                  key={line.lineId}
                  onClick={() =>
                    props.onClose({
                      lineId: line.lineId,
                      orderId: line.order.id,
                    })
                  }
                >
                  <Box flex={1} display="flex" gap={1} alignItems="center">
                    <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                      {line.order.name}
                    </ListItemText>
                    <Typography variant="caption" color="textSecondary">
                      click to add
                    </Typography>
                  </Box>
                </ListItemButton>
              );
            } else {
              return (
                <Box
                  key={line.lineId}
                  sx={{ border: `1px solid ${grey[400]}`, borderRadius: 1 }}
                  my={1}
                >
                  <ListItemButton
                    onClick={() => showHideGroup(line.lineId)}
                    sx={{
                      backgroundColor: blueGrey[50],
                      paddingY: 0.5,
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{ color: "textSecondary" }}
                    >
                      {line.group.name}
                    </ListItemText>
                    {collapsedGroupLines[line.lineId] ? (
                      <ExpandMore />
                    ) : (
                      <ExpandLess />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={!collapsedGroupLines[line.lineId]}
                    timeout="auto"
                  >
                    <List component="div" disablePadding>
                      {line.orders.map((order) => (
                        <ListItemButton
                          key={order.id}
                          onClick={(_) =>
                            props.onClose({
                              lineId: line.lineId,
                              orderId: order.id,
                            })
                          }
                        >
                          <Box
                            flex={1}
                            display="flex"
                            gap={1}
                            alignItems="center"
                          >
                            <ListItemText
                              primaryTypographyProps={{ variant: "body2" }}
                            >
                              {order.name}
                            </ListItemText>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              ml={1}
                            >
                              click to add
                            </Typography>
                          </Box>
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </Box>
              );
            }
          })}
        </List>
      </Box>
    </DialogWrapper>
  );
}

function DialogWrapper(props: {
  category: OrderCategory;
  onClose: () => void;
  children: ReactNode;
}) {
  return (
    <Dialog open={true} onClose={props.onClose} maxWidth="md" fullWidth>
      <DialogTitle>{orderCategoryNames[props.category]} Orders</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
