import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import AppointmentDateTimeSelector from "../AppointmentDateTimeSelector";
import PatientNotifications from "../PatientNotificationPreferences";
import {
  MedicalAppointmentFieldsFragment,
  PatientCommunicationPreferences,
} from "../../../../gql/graphql";
import { useMutation } from "@apollo/client";
import { RESCHEDULE_APPOINTMENT_MUTATION } from "../../../../libs/queries";
import { useHistory } from "react-router-dom";
import type {
  Notification,
  ToggleEvent,
} from "../PatientNotificationPreferences";
import { styles } from "./styles";
import { GET_APPOINTMENT_BY_ID } from "./index";

export type ProposedSlot = {
  startTimeInSeconds: number;
  durationInMinutes: number;
  checkedForAvailability: boolean;
};

export const NewSlot: React.FC<{
  appointment: MedicalAppointmentFieldsFragment;
  patientId: string;
  communicationPreferences: PatientCommunicationPreferences;
  rescheduledAppointmentNotificationDisabledReason: string | null;
  setRescheduleMode: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  appointment,
  patientId,
  setRescheduleMode,
  communicationPreferences,
  rescheduledAppointmentNotificationDisabledReason,
}) => {
  const history = useHistory();
  //begin with the current appointment's start time and duration
  const initialSlot = {
    startTimeInSeconds: appointment.startTime,
    durationInMinutes: Math.round(
      (appointment.endTime - appointment.startTime) / 60,
    ),
    checkedForAvailability: false,
  };

  const [newProposedSlot, setNewProposedSlot] =
    useState<ProposedSlot>(initialSlot);

  const resetSelectedSlot = () => {
    setNewProposedSlot(initialSlot);
  };

  const [
    rescheduleAppointment,
    {
      data: rescheduleAppointmentResult,
      loading: rescheduleAppointmentLoading,
      error: rescheduleAppointmentError,
    },
  ] = useMutation(RESCHEDULE_APPOINTMENT_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GET_APPOINTMENT_BY_ID,
        variables: { appointmentId: appointment.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [notifyPatientRescheduled, setNotifyPatientRescheduled] =
    useState(true);

  const handleSwitchRescheduledNotification = (checked: boolean) => {
    setNotifyPatientRescheduled(checked);
  };

  const rescheduleNotifications: Notification[] = [
    {
      name: "Rescheduling Confirmation",
      onSwitch: (event: ToggleEvent) => {
        handleSwitchRescheduledNotification(event.target.checked);
      },
      value: notifyPatientRescheduled,
    },
  ];

  const handleRescheduleAppointment = () => {
    rescheduleAppointment({
      variables: {
        appointmentId: appointment.id,
        startTime: newProposedSlot!!.startTimeInSeconds,
        durationInMinutes: newProposedSlot!!.durationInMinutes,
        ignoreBusy: !newProposedSlot!!.checkedForAvailability,
        notifyPatient: notifyPatientRescheduled,
      },
    }).then(/*ignore promise results*/);
  };

  useEffect(() => {
    if (rescheduleAppointmentResult?.rescheduleAppointmentAdmin) {
      setRescheduleMode(false);
      history.push(
        `/patient/${patientId}/schedule/${appointment.id}?rescheduled=success`,
      );
    }
  }, [rescheduleAppointmentResult]);

  // Returns true if the proposed slot is different from the current appointment.
  const isProposedSlotValid = (): boolean => {
    const proposedEndTime =
      newProposedSlot.startTimeInSeconds +
      newProposedSlot.durationInMinutes * 60;
    return (
      newProposedSlot.startTimeInSeconds !== appointment.startTime ||
      proposedEndTime !== appointment.endTime
    );
  };

  return (
    <>
      <Snackbar
        open={!!rescheduleAppointmentError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Oops! The operation failed. Please reload the page and try again.
        </Alert>
      </Snackbar>
      <Divider sx={{ marginY: 2 }} />
      <Typography variant="body1">New Appointment Time</Typography>
      <AppointmentDateTimeSelector
        staffId={(appointment.hostInfo as any).staff?.id}
        supportingPractitionerId={
          (appointment.hostInfo as any).supportingStaff?.id
        }
        supportingPractitionerType={
          (appointment.hostInfo as any).supportingStaff?.practitionerType
        }
        clinicId={appointment.clinicId || null}
        appointmentType={appointment.appointmentType}
        appointmentMedium={appointment.appointmentMedium}
        patientTimezone={communicationPreferences.timezone || null}
        defaultDurationInMinutes={30}
        selectedSlot={newProposedSlot}
        onSelectedSlotChanged={setNewProposedSlot}
        resetSelectedSlot={resetSelectedSlot}
      />
      <PatientNotifications
        communicationPreferences={communicationPreferences}
        notifications={rescheduleNotifications}
        disabledReason={rescheduledAppointmentNotificationDisabledReason}
      />
      <Box display="flex" justifyContent="flex-end" sx={{ marginTop: 1 }}>
        <Button
          variant="outlined"
          onClick={(_) => setRescheduleMode(false)}
          sx={styles.button}
          disabled={rescheduleAppointmentLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          sx={styles.button}
          disabled={!isProposedSlotValid()}
          loading={rescheduleAppointmentLoading}
          onClick={handleRescheduleAppointment}
        >
          Reschedule{" "}
          {isProposedSlotValid()
            ? DateTime.fromSeconds(newProposedSlot!!.startTimeInSeconds)
                .setZone(communicationPreferences.timezone || undefined)
                .toFormat("' on' MM/dd 'at' t ZZZZ")
            : null}
        </LoadingButton>
      </Box>
    </>
  );
};
