// @flow
import React, {useEffect, useState} from "react";
import {Typography, Box, Grid, Button, Snackbar} from "@mui/material";
import type {Patient} from "../../../flow-typed/graphql/patient";
import DetailedAlert from "../../DetailedAlert";
import gql from "graphql-tag";
import {useMutation} from "@apollo/client";
import {LoadingButton} from "@mui/lab";
import {PATIENT_INFO_QUERY} from "../../../libs/queries";
import { motionmskAppBaseUrl } from "../../../config";

type Props = {
  patient: Patient
};

const CREATE_INVITE_MUTATION = gql`
    mutation createPatientInvite($patientId: String!) {
        createInvite(patientId: $patientId)
    }
`;

export default function PatientInvite(props: Props) {
  const [showCopiedToast, setShowCopiedToast] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const url = props.patient.invitationId ? `${motionmskAppBaseUrl()}/Register?c=${String(props.patient.invitationId)}` : null;

  const [createPatientInvite, {loading: urlLoading, error: urlError}] = useMutation(CREATE_INVITE_MUTATION, {
    variables: {
      patientId: props.patient.id
    },
    refetchQueries: [{ 
        query: PATIENT_INFO_QUERY,
        variables: {patientId: props.patient.id}
    }],
    awaitRefetchQueries: true
  });

  useEffect(() => {
    setShowErrorSnackbar(!!urlError);
  }, [urlError]);

  const copyToClipboard = () => {
    if (!url) return; // unreachable condition
    navigator.clipboard
      .writeText(url)
      .then(setShowCopiedToast(true))
  }

  return (
    <>
      <Snackbar open={showErrorSnackbar} onClose={_ => setShowErrorSnackbar(false)}>
        <Box>
          <DetailedAlert
            message="Oops! Failed to create login URL."
            additionalDetails={createPatientInvite}
            retry={_ => createPatientInvite().catch(_ => {})}
          />
        </Box>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          {!props.patient.invitationId ? (
            <LoadingButton
              variant="contained"
              loading={urlLoading}
              onClick={createPatientInvite}
            >
              Create Login URL
            </LoadingButton>
          ) : (
            <Box display="flex">
              <Box flexShrink={1} sx={{overflow: "hidden", textOverflow: "ellipsis"}}>
                <Typography variant="body2" color="text.secondary" noWrap>
                  {url}
                </Typography>
              </Box>
              <Box mx={2} flexShrink={0}>
                <Button onClick={copyToClipboard} variant="outlined" size="small">
                  Copy to Clipboard
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={showCopiedToast}
        autoHideDuration={5000}
        onClose={_ => setShowCopiedToast(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        message="Copied to clipboard"
      />
    </>
  );
}
