// @flow
import React from "react";
import {getAbbreviatedNamedOffsetFromTimezone} from "../../../../libs/datetime";
import {Alert, Box, Typography} from "@mui/material";
import type {Patient} from "../../../../generated/flow_types";

type Props = {
  patient: Patient
};

export default function PatientCommunicationAlerts(props: Props) {
  let warnings = [];
  const patientTimezone = getAbbreviatedNamedOffsetFromTimezone(props.patient.communicationPreferences.timezone);
  if (!!props.patient.communicationPreferences.timezone && !patientTimezone) {
    // Note: the backend currently defaults to GMT if the timezone is invalid (not ideal), so this scenario shouldn't happen.
    warnings.push("The patient's timezone is invalid: 'props.patient.communicationPreferences.timezone'. " +
      "Please fix in Welkin before scheduling appointments, as this might lead to confusing timezones in " +
      "the patient's appointment reminder emails.");
  } else if (!patientTimezone) {
    warnings.push("The patient's timezone is unknown; defaulting to Eastern. Please set it in Welkin before " +
      "scheduling appointments, as this might lead to incorrect timezones in the patient's appointment reminder emails.");
  }
  if (!props.patient.communicationPreferences.email) {
    warnings.push("This patient's email address is unknown! Please set it in Welkin before scheduling appointments, " +
      "otherwise no confirmation/reminder emails can be sent to this patient.");
  }
  if (!props.patient.communicationPreferences.phone) {
    warnings.push("This patient's phone number is unknown. Please set it in Welkin, or make sure you have " +
      "a way of getting in touch with them before scheduling appointments that are expected to " +
      "happen over the phone.");
  }
  if (warnings.length === 0) {
    return null;
  }
  return (
    <Box sx={{marginBottom: 2}}>
      <Alert severity="warning">
        <strong>Please note:</strong>
        {warnings.map((warning, index) => (
          <Typography variant="body2" key={index}>• {warning}</Typography>
        ))}
      </Alert>
    </Box>
  );
};
