// @flow checked 01/24/23
import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ListItem,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "@apollo/client";
import { GET_ALL_CLINICS } from "../../../../../../../libs/queries";
import DetailedAlert from "../../../../../../DetailedAlert";

const style = {
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  left: "50%",
  p: 2,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: 368,
};

type Props = {
  filterByClinicIds: string[],
  onFiltersChange: (filterByClinicIds: string[]) => void,
  onClose: () => void,
}

const ClinicsFilterModal = (props: Props) => {
  const { data, error, loading, refetch } = useQuery(GET_ALL_CLINICS);
  const [allClinics, setAllClinics] = useState(false);
  const [clinics, setClinics] = useState(props.filterByClinicIds);

  const handleApply = () => {
    props.onFiltersChange(clinics);
    props.onClose();
  };

  const handleClear = () => {
    setClinics([]);
    setAllClinics(false);
  };

  const handleCheckbox = ({ target: { checked, name } }) => {
    if (name === "all") {
      if (checked) {
        setAllClinics(true);

        const allClinics = data.clinics?.map(({ id }) => id);

        setClinics(allClinics);
      } else {
        setAllClinics(false);
        setClinics([]);
      }

      return;
    }

    if (checked) {
      setClinics((state) => [...state, name]);
    } else {
      const newClinicsList = clinics.filter((clinic) => clinic !== name);
      setClinics(newClinicsList);
      setAllClinics(false);
    }
  };

  if (loading) {
    return (
      <Modal onClose={props.onClose} open={true}>
        <Box sx={{...style, display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  if (error || !data) {
    return (
      <Modal onClose={props.onClose} open={true}>
        <Box sx={style}>
          <DetailedAlert
            message="Oops! Failed to get Clinics. Please try again."
            retry={() => refetch()}
            additionalDetails={error}
          />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal onClose={props.onClose} open={true}>
      <Box sx={style}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
          <Typography color="primary" variant="h6" component="h2">
            Filter Clinics
          </Typography>
          <IconButton onClick={props.onClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
        <Box sx={{maxHeight: '60vh', overflowY: "auto"}}>
          <Grid item xs={12}>
            <FormControl fullWidth style={{ overflow: "auto" }}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={allClinics}
                  name="all"
                  label="Select All"
                  onChange={handleCheckbox}
                />
                <ListItem divider style={{ margin: "-12px 0 6px" }} />
                {data.clinics?.map(({ id, name }) => (
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={clinics.indexOf(id) > -1}
                    key={id}
                    label={name}
                    name={id}
                    onChange={handleCheckbox}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Box>
        <Grid container mt={3} style={{ textAlign: "right" }}>
          <Grid item xs={12}>
            <Button onClick={handleClear} style={{ marginRight: "8px" }}>
              CLEAR
            </Button>
            <Button onClick={handleApply} variant="contained">
              APPLY
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ClinicsFilterModal;
