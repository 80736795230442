import {
  AppointmentStaff,
  SearchParams,
  toStaffIdOrNull,
  toStaffTeamOrNull,
} from "../../../../../libs/booking";
import { TargetWeek } from "../../../../../gql/graphql";
import ReadOnlyStaffTeamBrowser from "./ReadOnlyStaffTeamBrowser";
import ReadOnlyStaffMemberBrowser from "./ReadOnlyStaffMemberBrowser";

type Props = {
  patientId: string;
  searchParameters: SearchParams;
  week: TargetWeek;
  selectedStaff: AppointmentStaff;
};

/**
 * Similar to StaffBrowser, but only displays the specified staff.
 */
export default function ReadOnlyStaffBrowser(props: Props) {
  if (props.searchParameters.multiDisciplinary) {
    return (
      <ReadOnlyStaffTeamBrowser
        team={toStaffTeamOrNull(props.selectedStaff)!}
      />
    );
  }

  return (
    <ReadOnlyStaffMemberBrowser
      patientId={props.patientId}
      searchParameters={props.searchParameters}
      week={props.week}
      staffId={toStaffIdOrNull(props.selectedStaff)!}
    />
  );
}
