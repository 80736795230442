import React, { ReactNode } from "react";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Collapse,
  Link,
  Paper,
} from "@mui/material";
import { graphql } from "../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import { maxPatientScreenWidth } from "../../../theme";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import { DateTime } from "luxon";
import DetailedAlert from "../../DetailedAlert";
import VisitNoteViewer from "./VisitNoteViewer";
import VisitNoteEditor from "./VisitNoteEditor";
import { Patient, VisitNoteStatus } from "../../../gql/graphql";
import VisitNoteFinalizer from "./VisitNoteFinalizer";
import EmbeddedAssessment from "./EmbeddedAssessment";
import { useSearchParams } from "../../../hooks/searchParams";

export const VISIT_NOTE_BY_ID = graphql(`
  query VisitNoteById($id: String!) {
    visitNoteById(id: $id) {
      ...VisitNoteFields
    }
  }
`);

type Props = {
  patient: Patient;
};

/**
 * Loads a read-only view of a completed visit note or the editor for an in-progress visit note.
 */
export default function ViewOrEditVisitNote(props: Props) {
  const [searchParams] = useSearchParams();
  const match = useRouteMatch<{ visitNoteId: string }>();
  const { data, loading, error, refetch } = useQuery(VISIT_NOTE_BY_ID, {
    variables: { id: match.params.visitNoteId },
  });
  if (loading) {
    return (
      <BreadcrumbsWrapper startTime={null}>
        <CircularProgress />
      </BreadcrumbsWrapper>
    );
  }

  const note = data?.visitNoteById;
  if (error || !note) {
    return (
      <BreadcrumbsWrapper startTime={null}>
        <Paper sx={{ padding: 2 }}>
          <DetailedAlert
            message="Oops! Failed to load visit note. Please retry."
            severity="error"
            additionalDetails={error}
            retry={() => refetch().catch(() => {})}
          />
        </Paper>
      </BreadcrumbsWrapper>
    );
  }

  // Read-only view for completed notes
  if (note.status == VisitNoteStatus.Submitted) {
    return (
      <BreadcrumbsWrapper startTime={note.appointment.startTime}>
        <VisitNoteViewer note={note} patient={props.patient} />
      </BreadcrumbsWrapper>
    );
  } else if (note.status == VisitNoteStatus.ReadyForElation) {
    return <VisitNoteFinalizer note={note} patient={props.patient} />;
  } else {
    // Edit view for in-progress notes
    return (
      <Box display="flex" flex={1}>
        <VisitNoteEditor note={note} />
        <Collapse in={searchParams.focused} orientation="horizontal">
          <Box sx={{ height: "100%", width: "33vw", maxWidth: 500 }}>
            <EmbeddedAssessment
              onAssessmentChange={() =>
                refetch().then(/* ignore promise results */)
              }
            />
          </Box>
        </Collapse>
      </Box>
    );
  }
}

function BreadcrumbsWrapper(props: {
  startTime: number | null;
  children?: ReactNode;
}) {
  return (
    <Box
      display="flex"
      flex={1}
      sx={{ overflowY: "auto" }}
      justifyContent="center"
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        flex={1}
        maxWidth={maxPatientScreenWidth}
      >
        <VisitNoteBreadcrumbs startTime={props.startTime} />
        {props.children}
      </Box>
    </Box>
  );
}

export function VisitNoteBreadcrumbs(props: { startTime: number | null }) {
  const match = useRouteMatch<{ patientId: string }>();
  return (
    <Breadcrumbs separator={<NavigateNextOutlinedIcon fontSize="small" />}>
      <Link
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
        component={RouterLink}
        color="inherit"
        to={`/patient/${match.params.patientId}/visit-notes`}
      >
        <NoteAltOutlinedIcon sx={{ height: 18, width: 18 }} />
        Visit Notes
      </Link>
      <Link
        component="button"
        color="inherit"
        underline="none"
        sx={{ cursor: "unset" }}
      >
        {props.startTime
          ? DateTime.fromSeconds(props.startTime).toLocaleString(
              DateTime.DATETIME_SHORT,
            )
          : "loading"}
      </Link>
    </Breadcrumbs>
  );
}
