import { green, grey, red } from "@mui/material/colors";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { ApolloError } from "@apollo/client/errors";

type Props = {
  results: {
    loading: boolean;
    error?: ApolloError;
    dirty: boolean;
    lastSaveTime: DateTime | null;
    retry: () => Promise<void>;
  };
};

/**
 * A component that displays the status of an auto-mutation (dirty, saving, saved, error).
 * If the mutation keeps failing for 1 minute, a warning dialog will be shown,
 * and the user will be given the option to reload the page or ignore the
 * warning for 5 minutes.
 */
export default function AutoSaveStatus(props: Props) {
  const [firstErrorTimestamp, setFirstErrorTimestamp] =
    useState<DateTime | null>(null);
  const [latestErrorTimestamp, setLatestErrorTimestamp] =
    useState<DateTime | null>(null);
  const [ignoreErrorsUntil, setIgnoreErrorsUntil] = useState<DateTime | null>(
    null,
  );

  useEffect(() => {
    if (!props.results.error) {
      return;
    }
    setLatestErrorTimestamp(DateTime.now());
    if (!firstErrorTimestamp) {
      setFirstErrorTimestamp(DateTime.now());
    }
  }, [props.results.error]);

  useEffect(() => {
    if (props.results.lastSaveTime) {
      setFirstErrorTimestamp(null);
      setLatestErrorTimestamp(null);
    }
  }, [props.results.lastSaveTime?.toUnixInteger()]);

  const errorSpanInMinutes =
    firstErrorTimestamp && latestErrorTimestamp
      ? latestErrorTimestamp.diff(firstErrorTimestamp, "minutes").minutes
      : 0;

  return (
    <>
      <Dialog
        open={
          errorSpanInMinutes > 1 &&
          (!ignoreErrorsUntil || ignoreErrorsUntil <= DateTime.now())
        }
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <Box display="flex" gap={2} flexDirection="column">
            <DialogContentText>
              Your latest changes have not been successfully saved yet. Please
              check your internet connection. If the problem persists, try
              reloading the page.
            </DialogContentText>
            <Alert severity="warning">
              If you reload the page, you will lose any changes made since{" "}
              {firstErrorTimestamp?.toLocaleString(DateTime.TIME_SIMPLE)}.
            </Alert>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => window.location.reload()}>
            Reload page
          </Button>
          <Button
            onClick={() =>
              setIgnoreErrorsUntil(DateTime.now().plus({ minutes: 5 }))
            }
          >
            Dismiss warning for 5 minutes
          </Button>
        </DialogActions>
      </Dialog>
      <SaveIndicator {...props} />
    </>
  );
}

function SaveIndicator(props: Props) {
  if (props.results.error) {
    return (
      <Box display="flex" gap={1} alignItems="center">
        <ReportProblemIcon sx={{ color: red[400] }} />
        <Box display="flex" flexDirection="column">
          {props.results.loading ? (
            <Typography variant="body2" color="error">
              Retrying...
            </Typography>
          ) : (
            <Typography
              variant="body2"
              color="error"
              onClick={(_) => props.results.retry()}
            >
              Failed to save! Keep editing or <u>click</u> to retry.
            </Typography>
          )}
          {props.results.lastSaveTime && (
            <Typography color="text.secondary" sx={{ fontSize: "x-small" }}>
              Last saved{" "}
              {props.results.lastSaveTime.toLocaleString(DateTime.TIME_SIMPLE)}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  let icon = <CloudDoneOutlinedIcon sx={{ color: green[300] }} />;
  let text = "Draft saved";
  if (props.results.loading) {
    icon = <CloudUploadOutlinedIcon sx={{ color: grey[500] }} />;
    text = "Saving...";
  } else if (props.results.dirty) {
    icon = <CloudOutlinedIcon sx={{ color: grey[500] }} />;
    text = "Saving...";
  }

  return (
    <Box display="flex" gap={1} alignItems="center">
      {icon}
      <Typography variant="body2" color="text.secondary">
        {text}
      </Typography>
    </Box>
  );
}
