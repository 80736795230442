import { Box } from "@mui/material";
import { convertMinutesToHeight } from "../../../../libs/time";

type Props = {
  startHour: number;
  endHour: number;
  backgroundColor?: string;
};

export default function GridLines(props: Props) {
  const heightInPixels = convertMinutesToHeight(60);
  const hours = props.endHour - props.startHour + 1;
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ backgroundColor: props.backgroundColor }}
    >
      {Array.from({ length: hours }, (_, index) => (
        <Box
          key={index}
          height={index === hours - 1 ? "1px" : `${heightInPixels}px`}
          borderTop={(theme) => `1px solid ${theme.palette.divider}`}
          display="flex"
          boxSizing="border-box"
        />
      ))}
    </Box>
  );
}
