import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { graphql } from "../../../../gql";
import { Reference, StoreObject, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import DetailedAlert from "../../../DetailedAlert";
import { ScheduleFieldsFragment } from "../../../../gql/graphql";

type Props = {
  schedule: ScheduleFieldsFragment;
  onClose: () => void;
};

export const DISCARD_SCHEDULE_DRAFT = graphql(`
  mutation DiscardScheduleDraft($scheduleId: String!) {
    discardScheduleDraft(scheduleId: $scheduleId)
  }
`);

/**
 * Dialog to permanently discard a draft schedule.
 */
export default function DiscardDraftDialog(props: Props) {
  const history = useHistory();
  const [discardDraft, { error, loading }] = useMutation(
    DISCARD_SCHEDULE_DRAFT,
    {
      variables: { scheduleId: props.schedule.id },
      update(cache) {
        // Evict by id
        cache.evict({ id: cache.identify(props.schedule) });
        // Remove from allSchedules
        cache.modify({
          fields: {
            allSchedules(existingSchedules, { readField }) {
              return existingSchedules.filter(
                (scheduleRef: Reference | StoreObject | undefined) =>
                  props.schedule.id !== readField("id", scheduleRef),
              );
            },
          },
        });
      },
    },
  );
  const handleDiscard = async () => {
    await discardDraft();
    history.push(`/schedules`);
  };

  const maybeClose = () => {
    if (!loading && !error) {
      props.onClose();
    }
  };

  return (
    <Dialog open={true} onClose={maybeClose}>
      <DialogTitle>Discard Draft</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>
            Are you sure you want to discard this draft? This action cannot be
            undone.
          </Typography>
          {error && (
            // Force the user to reload, as the schedule may in fact be deleted.
            <DetailedAlert
              message="Oops! Something went wrong. Please reload this page."
              additionalDetails={error}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={maybeClose} disabled={loading || !!error}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={handleDiscard}
          loading={loading}
          disabled={!!error}
        >
          Discard
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
