// @flow
import * as React from 'react';
import {useContext, useEffect, useRef, useState} from "react";
import {SendbirdConversationContext} from "../SendbirdConversationProvider/SendbirdConversationProvider";
import {Alert, Box, CircularProgress, Snackbar} from "@mui/material";
import {Message} from "../MessagesContainer";
import Compose from "../Compose";

type Props = {
  disableSendMessages: boolean,
  memberId: ?string
}

/**
 * Displays a full sendbird channel, with messages and compose bar.
 */
export const SendbirdChannel = (props: Props) => {
  const context = useContext(SendbirdConversationContext);
  const messageEndRef = useRef(null);
  const [pinnedMessageId, setPinnedMessageId] = useState(null);
  const pinnedMessageRef = useRef(null);
  const [lastMessageId, setLastMessageId] = useState(null);
  // Message compose state
  const [draftMessage, setDraftMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [sending, setSending] = useState(false);
  const [sendError, setSendError] = useState(null);

  useEffect(() => {
    if (context.messages.length === 0) {
      return;
    }

    context.markAsRead();
    const newLastMessageId = context.messages[context.messages.length - 1]
    if (lastMessageId !== newLastMessageId) {
      // New messages were added. Scroll to the bottom.
      const behavior = lastMessageId === null ? 'instant' : 'smooth';
      messageEndRef.current?.scrollIntoView({behavior});
    } else if (pinnedMessageId) {
      // Older messages added at the top. Keep the scroll on the previous first message.
      pinnedMessageRef.current?.scrollIntoView({behavior: 'instant'});
      setPinnedMessageId(null);
    }

    setLastMessageId(newLastMessageId);
  }, [context.messages]);

  // Typing status
  useEffect(() => {
    context.startTyping();
    const timeoutId = setTimeout(() => {
      context.endTyping();
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    }
  }, [draftMessage, selectedImage])

  if (context.error) {
    return (
      <Box flex="1 1 auto">
        <Alert severity="warning">{context.error}</Alert>
      </Box>
    );
  }

  if (!props.memberId) {
    return (
      <Box flex="1 1 auto">
        <Alert severity="warning">This patient is not using the app yet. To get them signed up, go to the Info tab and generate an invite link.</Alert>
      </Box>
    );
  }

  if (!context.ready) {
    return (
      <Box display="flex" justifyContent="center" p={1}  flex="1 1 auto">
        <CircularProgress/>
      </Box>
    );
  }

  const handleScroll = (e) => {
    if (e.target.scrollTop === 0 && context.messages.length > 0) {
      setPinnedMessageId(context.messages[0].messageId);
      context.fetchMore();
    }
  };

  const onMessagePress = (message) => {
    // set image state this.setState({ imageURL: message.url });
  }

  const handleSendMessage = (metadata: ?any) => {
    if (draftMessage.length === 0 && !selectedImage) {
      return;
    }

    setSending(true);

    if (draftMessage.length > 0) {
      context.sendTextMessage(draftMessage, metadata).then(() => {
        setDraftMessage('');
        setSelectedImage(null);
      }).catch((error) => {
        setSendError("Failed to send message. Please try again");
      }).finally(() => {
        setSending(false);
      });
      return;
    }

    // We need the image dimensions before sending to Sendbird
    const img = new Image();
    img.onload = () => {
      const imageMetadata = {...metadata, height: img.height, width: img.width};
      context.sendImageMessage(selectedImage, imageMetadata).then(() => {
        setDraftMessage('');
        setSelectedImage(null);
      }).catch((error) => {
        setSendError("Failed to send message. Please try again");
      }).finally(() => {
        setSending(false);
      });
    };
    img.src = URL.createObjectURL(selectedImage);
  }


  return (
    <Box display="flex" flexDirection="column" flex="1 1 auto" width={500} sx={{minHeight: 200}} alignItems="stretch">
      <Box
        onScroll={handleScroll}
        p={1}
        display="flex"
        flexDirection="column"
        flex="1 1 auto"
        sx={{minHeight: 200, overflowY: 'auto'}}
      >
        <Box>
          {context.messages.map((message, index) => (
            <Box
              key={message.messageId}
              ref={message.messageId === pinnedMessageId ? pinnedMessageRef : null}
            >
              <Message
                message={message}
                onPress={onMessagePress}
              />
            </Box>
          ))}
          <div ref={messageEndRef}/>
        </Box>
      </Box>
      <Box pt={1}>
        <Compose
          draftMessage={draftMessage}
          selectedFile={selectedImage}
          loading={sending}
          disabled={props.disableSendMessages}
          onDraftMessageChange={message => setDraftMessage(message)}
          onSelectedFileChange={file => setSelectedImage(file)}
          onSend={handleSendMessage}
        />
      </Box>
      <Snackbar
        open={!!sendError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={4000}
        onClose={() => setSendError(null)}
      >
        <Alert severity="error">{sendError}</Alert>
      </Snackbar>
    </Box>
  );
}
