import { graphql } from "../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import DetailedAlert from "../DetailedAlert";
import { values } from "lodash";
import {
  isValidMotionTimezone,
  MotionTimezone,
  timezoneLabels,
} from "../../libs/time";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";

type Props = {
  patientId: string;
  size?: "small" | "medium";
};

graphql(`
  fragment PatientFields on Patient {
    id
    welkinPatientId
    elationPatientId
    firstName
    lastName
    zip
    risaltoUserId
    invitationId
    elationPatientId
    primaryCareTeamStaffId
    communicationPreferences {
      email
      phone
      timezone
      consentsToSMS
      consentsToEmails
    }
  }
`);

export const GET_PATIENT = graphql(`
  query GetPatient($patientId: String!) {
    patient(patientId: $patientId) {
      ...PatientFields
    }
  }
`);

export const UPDATE_PATIENT_TIMEZONE = graphql(`
  mutation UpdatePatientTimezone($patientId: String!, $timezone: String!) {
    updatePatientTimezone(patientId: $patientId, timezone: $timezone) {
      ...PatientFields
    }
  }
`);

/**
 * A component to view and edit a patient's timezone.
 * The component will display invalid timezones with a warning, but only
 * valid timezones can be saved.
 */
export default function ViewEditPatientTimezone(props: Props) {
  const [selectedTimezone, setSelectedTimezone] = React.useState<string | null>(
    null,
  );
  const [showSaveError, setShowSaveError] = React.useState(false);

  const { patientId } = props;

  const { data, loading, error, refetch } = useQuery(GET_PATIENT, {
    variables: { patientId },
    notifyOnNetworkStatusChange: true,
  });

  const [
    updatePatientTimezone,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_PATIENT_TIMEZONE);

  const patientTimezone =
    data?.patient?.communicationPreferences?.timezone || null;
  const dirty = selectedTimezone !== patientTimezone;

  useEffect(() => {
    if (patientTimezone) {
      setSelectedTimezone(patientTimezone);
    }
  }, [patientTimezone]);

  useEffect(() => {
    setShowSaveError(!!mutationError);
  }, [mutationError]);

  useEffect(() => {
    if (!dirty) {
      setShowSaveError(false);
    }
  }, [dirty]);

  if (loading) {
    return (
      <TextField
        label="Patient Timezone"
        variant="outlined"
        value="Loading..."
        disabled={true}
        fullWidth
        InputProps={{
          endAdornment: <CircularProgress color="inherit" size={22} />,
        }}
        size={props.size}
      />
    );
  }

  if (error) {
    return (
      <Box>
        <DetailedAlert
          message="Oops! Failed to load patient timezone. Please try again."
          additionalDetails={error}
          retry={() => refetch()}
        />
      </Box>
    );
  }

  const handleCancel = () => {
    setSelectedTimezone(patientTimezone);
  };

  const handleSave = async () => {
    if (!dirty || !isValidMotionTimezone(selectedTimezone)) {
      return;
    }
    await updatePatientTimezone({
      variables: { patientId, timezone: selectedTimezone || "" },
    });
  };

  return (
    <Box display="flex" gap={1} flex={1} alignItems="flex-start">
      <FormControl
        fullWidth
        error={showSaveError}
        disabled={mutationLoading}
        size={props.size}
      >
        <InputLabel id="timezone-label">Patient Timezone</InputLabel>
        <Select
          value={selectedTimezone || ""}
          label="Patient Timezone"
          labelId="timezone-label"
          onChange={(e) => setSelectedTimezone(e.target.value)}
        >
          {values(MotionTimezone).map((tz) => (
            <MenuItem key={tz} value={tz}>
              {timezoneLabels[tz]}
            </MenuItem>
          ))}
          {!!selectedTimezone && !isValidMotionTimezone(selectedTimezone) && (
            <MenuItem value={selectedTimezone} disabled>
              <Box display="flex" gap={1} alignItems="center">
                {selectedTimezone}
                <Chip label="⚠ Please update" size="small" />
              </Box>
            </MenuItem>
          )}
        </Select>
        {showSaveError && (
          <FormHelperText>Failed to save. Please try again.</FormHelperText>
        )}
      </FormControl>
      {dirty && (
        <Box mt={props.size === "small" ? 0 : 1} display="flex" gap={1}>
          <Tooltip title="Cancel">
            <IconButton onClick={handleCancel} disabled={mutationLoading}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save">
            <IconButton onClick={handleSave} disabled={mutationLoading}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
