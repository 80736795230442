import { DateTime } from "luxon";
import { Alert, Button, Snackbar } from "@mui/material";
import { useAuthentication } from "../../hooks/authHooks";
import React from "react";

const refreshTokenExpirationInDays = 30;

/**
 * A snackbar to handle authentication messaging when the user is already
 * in the middle of a session.
 */
export default function AuthStatus() {
  const { session, startNewAuth, refreshTokenExpired, pendingOktaAuth } =
    useAuthentication();
  const lastAuth = session?.getIdToken()?.payload["auth_time"] || null;
  if (!lastAuth) {
    return null;
  }

  const lastAuthDate = DateTime.fromSeconds(lastAuth);
  const daysUntilExpiration = lastAuthDate
    .plus({ days: refreshTokenExpirationInDays })
    .diffNow("days");

  if (
    daysUntilExpiration.days >= 1 &&
    !refreshTokenExpired &&
    !pendingOktaAuth
  ) {
    return null;
  }
  const reAuthenticate = () => {
    // Refresh token shouldn't expire if user acts on the warning. If they
    // do, they're probably experiencing query failures,
    // so force log out (unmounting the app).
    startNewAuth(refreshTokenExpired).then();
  };

  if (pendingOktaAuth) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Alert severity="info">
          Please complete the login flow in the Okta window that just opened.
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Snackbar
      open={true}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      <Alert
        severity={refreshTokenExpired ? "error" : "warning"}
        action={
          <Button size="small" color="inherit" onClick={reAuthenticate}>
            Re-authenticate
          </Button>
        }
      >
        {refreshTokenExpired
          ? "Your session has expired. Please re-authenticate now."
          : "Your session will expire soon. Please re-authenticate now to avoid disruptions."}
      </Alert>
    </Snackbar>
  );
}
