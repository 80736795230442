import { Box, Typography } from "@mui/material";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ViewEditSchedule } from "./ViewEditSchedule";
import CreateScheduleDraft from "./CreateScheduleDraft";
import BrowseSchedules from "./BrowseSchedules";
import {
  useCanManageSchedules,
  useRoles,
  UserRole,
} from "../../hooks/authHooks";

/**
 * Main screen to browse and manage schedules.
 */
export function ManageSchedules() {
  const match = useRouteMatch();
  const canManageSchedules = useCanManageSchedules();
  return (
    <Switch>
      <Route exact path={`${match.path}/create`}>
        <WithSidePanel>
          {canManageSchedules ? (
            <CreateScheduleDraft />
          ) : (
            <Typography sx={{ margin: 2 }}>
              Oops! You don't have permissions to create new schedules.
            </Typography>
          )}
        </WithSidePanel>
      </Route>
      <Route path={`${match.path}/:scheduleId`}>
        <WithSidePanel>
          <ViewEditSchedule />
        </WithSidePanel>
      </Route>
      <Route exact path={match.path}>
        <WithSidePanel>
          <Box p={1}>
            <Typography variant="body2" color="text.secondary">
              Select a schedule to get started.
            </Typography>
          </Box>
        </WithSidePanel>
      </Route>
    </Switch>
  );
}

function WithSidePanel(props: { children: React.ReactNode }) {
  return (
    <Box
      flex={1}
      display="grid"
      gridTemplateColumns="minmax(min-content, 300px) 1fr"
      minHeight={0}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          overflowY: "auto",
          borderRight: "1px solid #dedede",
        }}
        p={2}
      >
        <BrowseSchedules />
      </Box>
      {props.children}
    </Box>
  );
}
