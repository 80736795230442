import { graphql } from "../../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

type Props = {
  draftScheduleId: string;
};

export const ALL_SCHEDULES_QUERY = graphql(`
  query GetAllSchedules {
    allSchedules {
      ...ScheduleFieldsForList
    }
  }
`);
/**
 * Renders a button to navigate to the given draft's published version.
 * If no published version exists, nothing is rendered.
 */
export default function ViewPublishedScheduleButton(props: Props) {
  const { data } = useQuery(ALL_SCHEDULES_QUERY);

  if (!data) {
    return null;
  }

  const draftSchedule = data.allSchedules.find(
    (schedule) => schedule.id === props.draftScheduleId,
  );
  if (!draftSchedule) {
    return null;
  }

  const publishedSchedule = data.allSchedules.find(
    (schedule) =>
      schedule.staff.id === draftSchedule.staff.id &&
      schedule.takesEffectDate === draftSchedule.takesEffectDate &&
      !schedule.isDraft,
  );

  if (!publishedSchedule) {
    return null;
  }

  return (
    <Button
      component={RouterLink}
      to={`/schedules/${publishedSchedule.id}`}
      variant="outlined"
      size="small"
    >
      View Published Schedule
    </Button>
  );
}
