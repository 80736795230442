import { AddEditSlotProps } from "./index";
import { LocalUnavailableSlot } from "../../../../libs/scheduleTemplates";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { keys, uniqueId } from "lodash";

export default function UnavailableSlotBuilder(
  props: AddEditSlotProps & {
    onSlotChange: (validSlot: LocalUnavailableSlot | null) => void;
  },
) {
  const editSlot =
    props.operation === "edit" ? props.slots[props.editSlotId] : null;
  const [duration, setDuration] = useState<{ hours: number; minutes: number }>(
    () => {
      if (editSlot === null) {
        return { hours: 0, minutes: 30 };
      }
      const durationInMinutes = editSlot.endMinutes - editSlot.startMinutes;
      return {
        hours: Math.floor(durationInMinutes / 60),
        minutes: durationInMinutes % 60,
      };
    },
  );
  const [name, setName] = useState(
    editSlot?.type === "unavailable" ? editSlot.name : null,
  );
  const [errors, setErrors] = useState<TentativeUnavailableSlotErrors>({});

  useEffect(() => {
    const results = tryBuildValidSlot(
      props,
      duration.hours,
      duration.minutes,
      name,
    );
    props.onSlotChange(results.validSlot);
    setErrors(results.errors);
  }, [duration, name]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Autocomplete
        options={["Lunch", "Other"]}
        freeSolo
        value={name}
        onChange={(_, newValue) => setName(newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Name" error={!!errors.nameError} />
        )}
      />
      <Box display="flex" alignItems="center" gap={2}>
        <Typography>Duration:</Typography>
        <FormControl sx={{ width: 100 }}>
          <InputLabel id="hours-label">Hours</InputLabel>
          <Select
            labelId="hours-label"
            label="Hours"
            value={duration.hours}
            onChange={(e) =>
              setDuration((duration) => ({
                hours: e.target.value as number,
                minutes:
                  (e.target.value as number) === 0 ? duration.minutes : 0,
              }))
            }
          >
            {Array.from({ length: 9 }, (_, i) => i).map((i) => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 100 }}>
          <InputLabel id="minutes-label">Minutes</InputLabel>
          <Select
            labelId="minutes-label"
            label="Mintues"
            value={duration.minutes}
            onChange={(e) =>
              setDuration((duration) => ({
                hours: duration.hours,
                minutes: e.target.value as number,
              }))
            }
          >
            {Array.from({ length: 12 }, (_, i) => i).map((i) => (
              <MenuItem key={i} value={i * 5}>
                {i * 5}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="body2" color="text.secondary">
          ({duration.hours * 60 + duration.minutes} minutes)
        </Typography>
      </Box>
      {errors.durationError && (
        <Typography color="error" variant="caption">
          {errors.durationError}
        </Typography>
      )}
    </Box>
  );
}

type TentativeUnavailableSlotErrors = {
  nameError?: string;
  durationError?: string;
};

function tryBuildValidSlot(
  props: AddEditSlotProps,
  durationHours: number,
  durationMinutes: number,
  name: string | null,
): {
  validSlot: LocalUnavailableSlot | null;
  errors: TentativeUnavailableSlotErrors;
} {
  const errors: TentativeUnavailableSlotErrors = {};
  if (name === null || name === "") {
    errors.nameError = "Name is required";
  }
  const durationInMinutes = durationHours * 60 + durationMinutes;
  if (durationInMinutes < 10) {
    errors.durationError = "Minimum duration is 10 minutes";
  }
  if (keys(errors).length > 0) {
    return { validSlot: null, errors };
  }

  if (props.operation === "edit") {
    const sourceSlot = props.slots[props.editSlotId]!;
    return {
      validSlot: {
        id: sourceSlot.id,
        type: "unavailable",
        dayOfWeek: sourceSlot.dayOfWeek,
        startMinutes: sourceSlot.startMinutes,
        endMinutes: sourceSlot.startMinutes + durationInMinutes,
        name: name!,
      },
      errors,
    };
  }

  return {
    validSlot: {
      id: uniqueId(),
      type: "unavailable",
      dayOfWeek: props.dayOfWeek,
      startMinutes: props.startMinutes,
      endMinutes: props.startMinutes + durationInMinutes,
      name: name!,
    },
    errors: {},
  };
}
