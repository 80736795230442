import React from "react";
import { Route } from "react-router-dom";

// TODO: this won't be needed after react-router 6
const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={routeProps => React.createElement(component, rest)}
    />
  );
};

export default PropsRoute;
