import React from "react";
import {
  AppointmentType,
  MedicalAppointmentFieldsFragment,
} from "../../../gql/graphql";

export function AppointmentDescription(props: {
  appointment: MedicalAppointmentFieldsFragment;
}) {
  if (props.appointment.appointmentType === AppointmentType.External) {
    return <>{`[External] ${props.appointment.description}`}</>;
  }

  return <>{props.appointment.description}</>;
}
