// @flow
import React from "react";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import type { ApolloResult } from "../../../../flow-typed/apollo";
import { ASSESSMENT_BY_ID_QUERY } from "../PatientAssessments";
import { useQuery } from "@apollo/client/react/hooks";
import type { AssessmentQuery } from "../../../../generated/flow_types";
import DetailedAlert from "../../../DetailedAlert";
import { DateTime } from "luxon";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { maxPatientScreenWidth } from "../../../../theme";

export default function AssessmentDetails() {
  const match = useRouteMatch();
  const assessmentId = match.params.assessmentId;

  const { data, loading, error, refetch }: ApolloResult<AssessmentQuery> =
    useQuery(ASSESSMENT_BY_ID_QUERY, {
      variables: {
        assessmentId: assessmentId,
      },
      notifyOnNetworkStatusChange: true,
    });

  if (loading) {
    return (
      <WithHeader>
        <CircularProgress />
      </WithHeader>
    );
  }

  if (error) {
    return (
      <WithHeader>
        <DetailedAlert
          message="Oops! Failed to load this assessment. Please try again."
          additionalDetails={error}
          retry={(_) => refetch().catch((_) => {})}
        />
      </WithHeader>
    );
  }

  return (
    <WithHeader assessmentName={data.assessment.name}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="body2" style={styles.header}>
            Assessment Name
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body2">{data.assessment.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" style={styles.header}>
            Created On
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body2">
            {DateTime.fromSeconds(data.assessment.createdAt).toLocaleString(
              DateTime.DATETIME_MED,
            )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" style={styles.header}>
            Completed On
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body2">
            {DateTime.fromSeconds(data.assessment.updatedAt).toLocaleString(
              DateTime.DATETIME_MED,
            )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" style={styles.header}>
            Answers
          </Typography>
        </Grid>
        <Grid item xs={10} display="flex" alignItems="flex-start">
          <Box>
            {data.assessment.outcome.formattedAnswers.map(
              (formatted, index) => (
                <Box key={index} mb={1}>
                  <Typography
                    variant="body2"
                    style={styles.question}
                    color="primary"
                  >
                    {formatted.label}
                  </Typography>
                  <Typography variant="body2" style={styles.answer}>
                    ▸ {formatted.value}
                  </Typography>
                </Box>
              ),
            )}
          </Box>
          {(!!data.assessment.outcome.formattedMetrics.length ||
            !!data.assessment.outcome.otherOutput.length) && (
            <Box
              style={{
                backgroundColor: "#f3f3f3",
                borderRadius: 5,
                minWidth: 250,
                maxWidth: 400,
              }}
              p={2}
              ml={1}
            >
              <Typography variant="body2" style={styles.header}>
                Outputs
              </Typography>
              <ul style={{ paddingLeft: "20px" }}>
                {data.assessment.outcome.formattedMetrics.map(
                  (formatted, index) => (
                    <li key={`metric_${index}`}>
                      <Box display="flex">
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          {formatted.label}:
                        </Typography>
                        <Typography variant="body2" ml={1}>
                          {formatted.value}
                        </Typography>
                      </Box>
                    </li>
                  ),
                )}
                {data.assessment.outcome.otherOutput.map((output, index) => (
                  <li key={`output_${index}`}>
                    <Typography variant="body2">{output}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Grid>
      </Grid>
    </WithHeader>
  );
}

const styles = {
  header: {
    fontWeight: "bold",
  },
  question: {
    fontStyle: "italic",
  },
  answer: {
    fontWeight: "bold",
  },
};

const WithHeader = (props: { assessmentName?: string, children: any }) => {
  const match = useRouteMatch();

  return (
    <Box
      display="flex"
      flex={1}
      sx={{ overflowY: "auto" }}
      justifyContent="center"
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        flex={1}
        maxWidth={maxPatientScreenWidth}
      >
        <Breadcrumbs separator={<NavigateNextOutlinedIcon fontSize="small" />}>
          <Link
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            component={RouterLink}
            color="inherit"
            to={`/patient/${match.params.patientId}/assessments`}
          >
            <AssignmentOutlinedIcon sx={{ height: 18, width: 18 }} />
            Assessments
          </Link>
          <Link
            component="button"
            color="inherit"
            underline="none"
            sx={{ cursor: "unset" }}
          >
            {props.assessmentName || "loading"}
          </Link>
        </Breadcrumbs>
        <Paper
          sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 1 }}
        >
          {props.children}
        </Paper>
      </Box>
    </Box>
  );
};
