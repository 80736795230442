import React, { useState } from "react";
import {
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LockIcon from "@mui/icons-material/Lock";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import {
  ExperimentalFeature,
  useExperimentalFeatures,
} from "./hooks/experimentalFeatures";
import { DateTime } from "luxon";
import { useAuthentication, useRoles, UserRole } from "./hooks/authHooks";
import { Link as RouterLink } from "react-router-dom";

type Props = {
  open: boolean;
  onClose: () => void;
  serverInfo: { version: string | null; namespace: string | null };
};

const styles = {
  item: {
    paddingRight: 10,
  },
};

export default function Sidebar(props: Props) {
  const { session, startNewAuth } = useAuthentication();
  const roles = useRoles();
  const [showToast, setShowToast] = useState<boolean>(false);
  const { checkExperimentalFeatureEnabled, toggleExperimentalFeature } =
    useExperimentalFeatures();

  const copyIdToClipboard = () => {
    navigator.clipboard
      .writeText(session?.getIdToken()?.getJwtToken() || "")
      .then((_) => setShowToast(true));
  };

  const onCloseToast = () => {
    setShowToast(false);
  };

  return (
    <React.Fragment>
      <Drawer anchor="left" open={props.open} onClose={props.onClose}>
        <List>
          <ListItem sx={styles.item}>
            <Typography component="span" color="textSecondary">
              <small>
                server: {props.serverInfo.version || ""}{" "}
                {props.serverInfo.namespace || ""}
              </small>
            </Typography>
          </ListItem>
          <ListItem
            component={RouterLink}
            to="/conversations"
            sx={{
              ...styles.item,
              color: "inherit",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <ListItemIcon>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText primary="Active Conversations" />
          </ListItem>
          <ListItem
            component={RouterLink}
            to="/schedules"
            sx={{
              ...styles.item,
              color: "inherit",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <ListItemIcon>
              <EditCalendarIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Schedules" />
          </ListItem>
          {!!session && roles.has(UserRole.Admin) && (
            <React.Fragment>
              <Divider />
              <ListItem>
                <ListItemText>
                  <Typography variant="body1">Debug</Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography variant="body2" color="text.secondary">
                    ID Token expires:{" "}
                    {DateTime.fromSeconds(
                      session.getIdToken().payload.exp,
                    ).toRelative()}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Last auth:{" "}
                    {DateTime.fromSeconds(
                      session.getIdToken().payload.auth_time,
                    ).toRelative()}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={copyIdToClipboard} sx={styles.item}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Copy id token" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={styles.item}
                  onClick={() => {
                    startNewAuth(true).then();
                  }}
                >
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <ListItemText primary="Log out" />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          )}
          <Divider />
          <ListItem>
            <ListItemText>
              <Typography variant="body1">Experimental Features</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkExperimentalFeatureEnabled(
                      ExperimentalFeature.patientRecords,
                    )}
                    onChange={(_) =>
                      toggleExperimentalFeature(
                        ExperimentalFeature.patientRecords,
                      )
                    }
                  />
                }
                label="Patient Records"
              />
            </FormGroup>
          </ListItem>
          <ListItem>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkExperimentalFeatureEnabled(
                      ExperimentalFeature.betaAssessments,
                    )}
                    onChange={(_) =>
                      toggleExperimentalFeature(
                        ExperimentalFeature.betaAssessments,
                      )
                    }
                  />
                }
                label="Show Beta Assessments"
              />
            </FormGroup>
          </ListItem>
        </List>
      </Drawer>
      <Snackbar
        open={showToast}
        autoHideDuration={6000}
        onClose={onCloseToast}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message="Copied to clipboard"
      />
    </React.Fragment>
  );
}
