import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { graphql } from "../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import EditPublishScheduleDraft from "../EditPublishScheduleDraft";
import ScheduleViewer from "../ScheduleViewer";
import DeletePublishedSchedule from "../DeletePublishedSchedule";
import { useCanManageSchedules } from "../../../hooks/authHooks";
import { useState } from "react";
import { ApolloError } from "@apollo/client";

graphql(`
  fragment TimeSlotFields on TimeSlot {
    minutesSinceMidnight
    durationInMinutes
    ... on AppointmentTimeSlot {
      appointmentType
      locationId
      supportedMediums
    }
    ... on UnavailableTimeSlot {
      name
    }
  }
`);

graphql(`
  fragment ScheduleDayFields on ScheduleDay {
    dayOfWeek
    hours {
      ...TimeSlotFields
    }
  }
`);

graphql(`
  fragment ScheduleFields on Schedule {
    id
    staff {
      id
      displayName
      activeOnZoom
    }
    isDraft
    takesEffectDate
    timezone
    weeklySchedule {
      ...ScheduleDayFields
    }
    alternateWeeklySchedule {
      ...ScheduleDayFields
    }
    updatedAt
    updatedByName
  }
`);

export const GET_SCHEDULE = graphql(`
  query getSchedule($scheduleId: String!) {
    schedule(scheduleId: $scheduleId) {
      ...ScheduleFields
    }
  }
`);

/**
 * Main component to retrieve a single schedule, and present the
 * viewer or editor based on draft status.
 */
export function ViewEditSchedule() {
  const match = useRouteMatch<{ scheduleId: string }>();
  const canManageSchedules = useCanManageSchedules();

  const { data, loading, error } = useQuery(GET_SCHEDULE, {
    variables: { scheduleId: match.params.scheduleId },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    return <ViewScheduleError error={error} />;
  }

  if (loading || !data) {
    return (
      <PaperWrapper>
        <CircularProgress />
      </PaperWrapper>
    );
  }

  const schedule = data.schedule;
  if (schedule.isDraft && canManageSchedules) {
    return <EditPublishScheduleDraft scheduleDraft={schedule} />;
  } else if (schedule.isDraft) {
    return (
      <Typography sx={{ margin: 2 }}>
        Oops! You don't have permissions to edit schedule drafts.
      </Typography>
    );
  }

  return (
    <Switch>
      <Route path={`${match.url}/delete`}>
        <DeletePublishedSchedule schedule={data.schedule} />
      </Route>
      <Route exact path={match.url}>
        <PaperWrapper>
          <ScheduleViewer schedule={data.schedule} />
        </PaperWrapper>
      </Route>
      {/* Remove any prefixes that might be left over when navigating back, e.g. /publish */}
      <Redirect to={match.url} />
    </Switch>
  );
}

function ViewScheduleError(props: { error: ApolloError }) {
  const handleGoBack = () => {
    window.location.assign("/schedules");
  };

  return (
    <PaperWrapper>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        sx={{ width: "100%" }}
      >
        <Box display="flex">
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={(_) => {
                  handleGoBack();
                }}
              >
                Go back
              </Button>
            }
          >
            <Box display="flex" justifyItems="center">
              Could not load schedule. It may have been deleted.
            </Box>
          </Alert>
        </Box>
      </Box>
    </PaperWrapper>
  );
}

function PaperWrapper(props: { children: React.ReactNode }) {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        margin: 1,
        flex: 1,
        minHeight: 0,
      }}
    >
      {props.children}
    </Paper>
  );
}
