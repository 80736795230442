// @flow
import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import type { Patient } from "../../../flow-typed/graphql/patient";
import { useQuery } from "@apollo/client/react/hooks";
import DetailedAlert from "../../DetailedAlert";
import gql from "graphql-tag";
import { partition } from "lodash";
import { AssessmentStatusValues } from "../../../generated/flow_types";
import type { Assessment } from "../../../generated/flow_types";
import { sortBy } from "lodash/collection";
import RefreshIcon from "@mui/icons-material/Refresh";
import PendingAssessmentsList from "./PendingAssessments";
import CompletedAssessments from "./CompletedAssessments";
import AssessmentActionsMenu from "./AssessmentActionsMenu";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { maxPatientScreenWidth } from "../../../theme";

type Props = {
  patient: Patient,
};

export const assessmentFragment = gql`
  fragment AssessmentFields on Assessment {
    id
    patientId
    assessmentType
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
    status
    patientAccessToken {
      token
      expiresAt
    }
  }
`;

export const PATIENT_ASSESSMENTS = gql`
  query assessmentsForPatient($patientId: String!) {
    assessmentsForPatient(patientId: $patientId) {
      ...AssessmentFields
    }
  }
  ${assessmentFragment}
`;

export const CREATE_ASSESSMENT_FOR_PATIENT = gql`
  mutation createAssessmentForPatient(
    $patientId: String!
    $assessmentType: String!
    $patientFacing: Boolean!
  ) {
    createAssessmentForPatient(
      patientId: $patientId
      assessmentType: $assessmentType
      patientFacing: $patientFacing
    ) {
      ...AssessmentFields
    }
  }
  ${assessmentFragment}
`;

export const ASSESSMENT_BY_ID_QUERY = gql`
  query assessment($assessmentId: String!) {
    assessment(assessmentId: $assessmentId) {
      ...AssessmentFields
      outcome {
        formattedAnswers {
          label
          value
        }
        formattedMetrics {
          label
          value
        }
        otherOutput
      }
    }
  }
  ${assessmentFragment}
`;

export function PatientAssessments(props: Props) {
  const [highlightedAssessmentId, setHighlightedAssessmentId] = useState(null);
  const { data, loading, error, refetch } = useQuery(PATIENT_ASSESSMENTS, {
    variables: {
      patientId: props.patient.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return (
      <BreadcrumbsWrapper>
        <CircularProgress />
      </BreadcrumbsWrapper>
    );
  }

  if (error) {
    return (
      <BreadcrumbsWrapper>
        <Paper>
          <DetailedAlert
            message="Failed to load assessments. Please try again."
            retry={(_) =>
              refetch().catch((_) => {
                /* ignore */
              })
            }
          />
        </Paper>
      </BreadcrumbsWrapper>
    );
  }

  const sortedByUpdateDateDesc = sortBy(
    data?.assessmentsForPatient,
    (a) => -1 * a.updatedAt,
  );
  const [completed: Assessment[], pending: Assessment[]] = partition(
    sortedByUpdateDateDesc,
    (a) => a.status === AssessmentStatusValues.Completed,
  );

  const handleNewAssessment = (assessmentId: string) => {
    setHighlightedAssessmentId(assessmentId);
    setTimeout(() => setHighlightedAssessmentId(null), 2500);
  };

  return (
    <BreadcrumbsWrapper
      actions={{
        patientId: props.patient.id,
        onRefreshClick: (_) => refetch(),
        onAssessmentCreated: handleNewAssessment,
        newVisitNoteDisabled: completed.length === 0,
      }}
    >
      <Paper
        sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 1 }}
      >
        <Typography variant="h6" mb={1} color="primary">
          In Progress
        </Typography>
        <PendingAssessmentsList
          patientId={props.patient.id}
          sortedPendingAssessments={pending}
          highlightedAssessmentId={highlightedAssessmentId}
        />
      </Paper>
      <Paper
        sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 1 }}
      >
        <Typography variant="h6" mb={1} color="primary">
          Completed
        </Typography>
        <CompletedAssessments sortedCompletedAssessments={completed} />
      </Paper>
    </BreadcrumbsWrapper>
  );
}

type BreadcrumbsWrapperProps = {
  actions?: {
    patientId: string,
    onRefreshClick: () => void,
    onAssessmentCreated: () => void,
    newVisitNoteDisabled: boolean,
  },
  children: any,
};
const BreadcrumbsWrapper = (props: BreadcrumbsWrapperProps) => {
  return (
    <Box
      display="flex"
      flex={1}
      sx={{ overflowY: "auto" }}
      justifyContent="center"
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        flex={1}
        maxWidth={maxPatientScreenWidth}
      >
        <Box display="flex" gap={2} justifyContent="space-between">
          <Breadcrumbs>
            <Link
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
              component="button"
              color="inherit"
              underline="none"
            >
              <AssignmentOutlinedIcon sx={{ height: 18, width: 18 }} />
              Assessments
            </Link>
          </Breadcrumbs>
          {!!props.actions && (
            <Box display="flex" gap={2}>
              <IconButton onClick={props.actions.onRefreshClick}>
                <RefreshIcon />
              </IconButton>
              <AssessmentActionsMenu
                patientId={props.actions.patientId}
                onAssessmentCreated={props.actions.onAssessmentCreated}
                newVisitNoteDisabled={!!props.actions.newVisitNoteDisabled}
              />
            </Box>
          )}
        </Box>
        {props.children}
      </Box>
    </Box>
  );
};
