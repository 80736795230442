import * as React from 'react';

export type SendbirdConversationContextContent = {
  // General state
  ready: boolean,
  error: string | null,

  // Channel state
  messages: Array<any>,
  typingMembers: Array<string>,
  unreadMessageCount: number | null,
  patientMember: {connectionState: string, nickname: string} | null,

  // Operations
  fetchMore: () => void,
  sendTextMessage: (text: string, metadata: any) => Promise<void>,
  sendImageMessage: (file: File, metadata: any) => Promise<void>,
  markAsRead: () => void,
  startTyping: () => void,
  endTyping: () => void,
};

export const defaultSendbirdContext: SendbirdConversationContextContent = {
  ready: false,
  error: null,
  messages: [],
  typingMembers: [],
  unreadMessageCount: 0,
  patientMember: null,
  fetchMore: () => {},
  sendTextMessage: (message: string, metadata: any) => Promise.resolve(),
  sendImageMessage: (file: File, metadata: any) => Promise.resolve(),
  markAsRead: () => {},
  startTyping: () => {},
  endTyping: () => {}
};

export const SendbirdConversationContext = React.createContext<SendbirdConversationContextContent|null>(defaultSendbirdContext);
