import {
  getTopPositionInPixels,
  MotionTimezone,
} from "../../../../../../../libs/time";
import { AvailableSlot } from "../../../../../../../gql/graphql";
import { Box } from "@mui/material";
import StaffAvailableSlot from "../StaffAvailableSlot";
import {
  AppointmentStaff,
  SearchParams,
} from "../../../../../../../libs/booking";

type Props = {
  staff: AppointmentStaff;
  timezone: MotionTimezone;
  availableSlots: Array<AvailableSlot>;
  searchParameters: SearchParams;
  minHour: number;
  maxHour: number;
  onSelect: (slot: AvailableSlot) => void;
};
/**
 * Renders available slots for a single day.
 */
export default function StaffDailyAvailableSlots(props: Props) {
  return (
    <>
      {props.availableSlots.map((slot) => (
        <Box
          key={slot.startTime}
          position="absolute"
          top={getTopPositionInPixels(slot, props.timezone, props.minHour)}
          width="100%"
        >
          <StaffAvailableSlot
            staff={props.staff}
            searchParameters={props.searchParameters}
            slot={slot}
            timezone={props.timezone}
            onSelect={() => props.onSelect(slot)}
          />
        </Box>
      ))}
    </>
  );
}
