import { useQuery } from "@apollo/client/react/hooks";
import { MOTION_STAFF } from "../components/SelectStaff";
import { useMemo } from "react";
import { keyBy, sortBy } from "lodash";
import { Clinic, MotionUser } from "../gql/graphql";
import { AppointmentConfigMap } from "../libs/scheduleTemplates";
import { GET_APPOINTMENT_TYPE_CONFIG } from "../components/SelectAppointmentType";
import { GET_CLINICS } from "../components/SelectClinic";

/**
 * Convenience hook for accessing the cached motion staff data.
 * The caller should ensure that the data is already cached.
 */
export const useCachedMotionStaff: () => Record<string, MotionUser> = () => {
  const { data } = useQuery(MOTION_STAFF, {
    // Assume already cached by a parent component.
    fetchPolicy: "cache-only",
  });
  if (!data) {
    console.warn(
      "Expected cached staff data to be present, but none was found.",
    );
  }

  return useMemo(() => {
    const sortedByName = sortBy(
      data?.masqueradeableMotionUsers,
      (staff) => staff.displayName,
    );
    return keyBy(sortedByName, (staff) => staff.id);
  }, [data]);
};

/**
 * Convenience hook for accessing the cached appointment type configurations.
 * The caller must ensure that the data is already cached.
 */
export function useCachedAppointmentTypeConfigurations(
  beta: boolean,
): AppointmentConfigMap {
  const { data } = useQuery(GET_APPOINTMENT_TYPE_CONFIG, {
    variables: {
      beta,
    },
    // Assume already cached by a parent component.
    fetchPolicy: "cache-only",
  });
  if (!data) {
    console.warn(
      "Expected cached appointment type configurations to be present, but none was found.",
    );
  }

  return useMemo(() => {
    const sortedByName = sortBy(
      data?.appointmentTypeConfigurations,
      (config) => config.internalName,
    );
    return keyBy(sortedByName, (config) => config.appointmentType);
  }, [data]);
}

/**
 * Convenience hook for accessing the cached clinics.
 * The caller must ensure that the data is already cached.
 */
export function useCachedClinics(): Partial<Record<string, Clinic>> {
  const { data } = useQuery(GET_CLINICS, {
    fetchPolicy: "cache-only",
  });
  if (!data) {
    console.warn("Expected cached clinics to be present, but none was found.");
  }

  return useMemo(() => {
    const sortedByName = sortBy(data?.clinics, (clinic) => clinic.name);
    return keyBy(sortedByName, (clinic) => clinic.id);
  }, [data]);
}
