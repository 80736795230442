// @flow checked 01/11/23
import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { StaticDatePicker } from '@mui/x-date-pickers'
import { DateTime } from "luxon";

import AppointmentProviderSelector from "../../../AppointmentProviderSelector";
import { appointmentDurations } from "../../../../../../libs/appointmentDuration";
import { timestamps } from "../../../../../../libs/timestamps";
import type {DraftAppointment} from "../../Stepper";
import type {ExternalProvider, Patient} from "../../../../../../generated/flow_types";
import moment from "moment-timezone";

type Props = {
  patient: Patient,
  appointmentDetails: DraftAppointment,
  onStartDayChange: (day: string) => void,
  onStartTimeChange: (day: string) => void,
  onDurationChange: (durationInMinutes: number) => void,
  onExternalProviderChange: (ExternalProvider) => void,
  displayTimezone: string,
};

const External = (props: Props) => {
  const {
    durationInMinutes,
    externalProvider,
    startDay,
    startTime,
  } = props.appointmentDetails;

  const handleDatePicker = (day: moment$Moment) => {
    const formattedDay = day.format("YYYY-MM-DD");
    props.onStartDayChange(formattedDay);
  };

  const displayZone = DateTime.fromISO(startDay, {zone: props.displayTimezone}).offsetNameShort;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "263px 361px",
        gap: 32,
        margin: "20px 0",
      }}
    >
      <div style={{ display: "grid", gap: 24, maxHeight: 250 }}>
        <FormControl fullWidth>
          <InputLabel
            htmlFor="demo-simple-select-helper1"
            id="demo-simple-select-helper-label1"
          >
            Duration
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label1"
            id="demo-simple-select-helper1"
            label="Duration"
            name="durationInMinutes"
            onChange={(e) => props.onDurationChange(e.target.value)}
            placeholder="Duration"
            value={durationInMinutes || ""}
          >
            {appointmentDurations.map(({ id, name, value }) => (
              <MenuItem key={id} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel
            htmlFor="demo-simple-select-helper2"
            id="demo-simple-select-helper-label2"
          >
            Start Time {displayZone}
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label2"
            id="demo-simple-select-helper2"
            label={`Start Time ${displayZone} * `}
            name="startTime"
            onChange={(e) => props.onStartTimeChange(e.target.value)}
            placeholder="Start Time"
            value={startTime}
          >
            {timestamps.map(({ id, name, value }) => (
              <MenuItem key={id} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <AppointmentProviderSelector
            onSelectedProviderChange={props.onExternalProviderChange}
            patient={props.patient}
            selectedProviderId={externalProvider?.id}
          />
        </FormControl>
      </div>
      <div
        style={{
          border: "1px solid #DBDDE5",
          borderRadius: "4px",
          height: 358,
          width: 320,
        }}
      >
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          onChange={handleDatePicker}
          slotProps={{ textField: { variant: 'outlined' } }}
          value={moment(startDay)}
        />
      </div>
    </div>
  );
};

export default External;
