import { MotionTimezone, MotionWeekDay } from "../../../../../libs/time";
import React, { useEffect, useState } from "react";
import { DateTime, Duration } from "luxon";
import {
  AppointmentNotificationType,
  AppointmentStaff,
  BookingIssue,
  getNotificationSettings,
  isAvailableSlot,
  isStaffTeam,
  SearchParams,
  SlotSelection,
} from "../../../../../libs/booking";
import { Alert, Box, Divider, TextField, Typography } from "@mui/material";
import {
  AppointmentMedium,
  Patient,
  TargetWeek,
} from "../../../../../gql/graphql";
import { AppointmentSummary } from "../../../../AppointmentSummary";
import {
  useCachedAppointmentTypeConfigurations,
  useCachedClinics,
  useCachedMotionStaff,
} from "../../../../../hooks/commonQueries";
import { isEqual } from "lodash";
import BookingIssuesSummary from "./BookingIssuesSummary";
import useDeepCompareEffect from "use-deep-compare-effect";
import AppointmentNotifications from "../../../../AppointmentNotifications";
import { LoadingButton } from "@mui/lab";
import {
  useBookOrRescheduleMutation,
  useDailyScheduleInfo,
} from "../../../../../hooks/bookingHooks";
import { useHistory } from "react-router-dom";
import DetailedAlert from "../../../../DetailedAlert";
import BlockNavigation from "../../../../BlockNavigation";
import DailyScheduleView from "./DailyScheduleView";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import GridToastErrorOrLoading from "../StaffScheduleGrid/GridToastErrorOrLoading";

type Props = {
  patient: Patient;
  timezone: MotionTimezone;
  searchParameters: SearchParams;
  slotSelection: SlotSelection;
  week: TargetWeek;
  staff: AppointmentStaff;
  rescheduledAppointmentId?: string;
};

/**
 * Create or reschedule an appointment.
 */
export default function FinalizeAppointment(props: Props) {
  const history = useHistory();
  const appointmentConfig =
    useCachedAppointmentTypeConfigurations(true)[
      props.searchParameters.appointmentType
    ]!;
  const [startTime, setStartTime] = useState<DateTime | null>(() => {
    if (isAvailableSlot(props.slotSelection)) {
      return DateTime.fromSeconds(props.slotSelection.slot.startTime, {
        zone: props.timezone,
      });
    }
    return null;
  });
  const [locationId, setLocationId] = useState<string | null>(() => {
    if (isAvailableSlot(props.slotSelection)) {
      return props.slotSelection.slot.locationId || null;
    }
    if (props.searchParameters.clinicIds.size === 1) {
      return props.searchParameters.clinicIds.values().next().value;
    }
    return null;
  });
  const [issuesAcknowledged, setIssuesAcknowledged] = useState<
    ReadonlySet<BookingIssue>
  >(new Set());
  const [patientInstructions, setPatientInstructions] = useState<string | null>(
    null,
  );
  const notificationSettings = getNotificationSettings(
    props.rescheduledAppointmentId ? "reschedule" : "create",
    startTime,
    props.patient.communicationPreferences,
  );
  const [notifications, setNotifications] = useState<
    Partial<Record<AppointmentNotificationType, boolean>>
  >(notificationSettings.relevantNotifications);

  // Reset notifications if the settings change.
  useDeepCompareEffect(() => {
    setNotifications(notificationSettings.relevantNotifications);
  }, [notificationSettings.relevantNotifications]);

  // TODO(PFA-1167): non-nullable durationInMinutes
  // NewAppointmentParamSelector guarantees that durationInMinutes is non-null.
  const appointmentDurationInMinutes: number =
    appointmentConfig.durationInMinutes!;

  const day = isAvailableSlot(props.slotSelection)
    ? (DateTime.fromSeconds(props.slotSelection.slot.startTime, {
        zone: props.timezone,
      }).weekday as MotionWeekDay)
    : props.slotSelection.weekday;

  const {
    loading: scheduleLoading,
    data: scheduleData,
    error: scheduleError,
    refetch: scheduleRefetch,
  } = useDailyScheduleInfo(
    props.staff,
    props.week,
    day,
    props.timezone,
    startTime,
    locationId,
    appointmentDurationInMinutes,
    "cache-and-network",
  );
  const bookingIssues = scheduleData.bookingIssues;

  useEffect(() => {
    // Reset acknowledged issues when booking issues change
    setIssuesAcknowledged(new Set());
  }, [bookingIssues]);

  const handleAcknowledgementChange = (
    issue: BookingIssue,
    acknowledged: boolean,
  ) => {
    setIssuesAcknowledged((prev) => {
      const next = new Set(prev);
      if (acknowledged) {
        next.add(issue);
      } else {
        next.delete(issue);
      }
      return next;
    });
  };

  const {
    bookOrReschedule,
    loading: bookOrRescheduleLoading,
    error: bookOrRescheduleError,
  } = useBookOrRescheduleMutation(
    props.patient.id,
    props.searchParameters,
    startTime,
    props.staff,
    locationId,
    patientInstructions,
    issuesAcknowledged,
    notifications,
    props.rescheduledAppointmentId,
  );

  const retroactive = !!startTime && startTime < DateTime.now();
  const readyToSubmit = checkReadyToSubmit(
    startTime,
    props.searchParameters.medium,
    locationId,
    bookingIssues,
    issuesAcknowledged,
  );

  const doBookOrReschedule = async () => {
    try {
      await bookOrReschedule();
    } catch (e) {
      console.error("Error booking or rescheduling appointment", e);
      // A new booking issue might have popped up. Refresh the schedule data.
      await scheduleRefetch();
      return;
    }
    // Redirect on success
    if (props.rescheduledAppointmentId) {
      history.push(
        `/patient/${props.patient.id}/schedule/${props.rescheduledAppointmentId}?rescheduled=success`,
      );
    } else {
      history.push(`/patient/${props.patient.id}/schedule?booked=success`);
    }
  };

  return (
    <Box display="flex" gap={1} minHeight={0}>
      <BlockNavigation
        when={bookOrRescheduleLoading}
        message="Please wait for the current operation to complete."
      />
      <Box flex="40%" display="flex" minHeight={0}>
        <DailyScheduleView
          staff={props.staff}
          tentativeAppointmentStart={startTime}
          tentativeAppointmentDuration={Duration.fromObject({
            minutes: appointmentDurationInMinutes,
          })}
          tentativeAppointmentLocationId={locationId}
          tentativeAppointmentType={props.searchParameters.appointmentType}
          timezone={props.timezone}
          leadStaffEvents={scheduleData.leadStaffEvents || []}
          leadStaffTimeSlots={scheduleData.leadTimeSlots || []}
          supportingStaffEvents={scheduleData.supportingStaffEvents || null}
          rescheduledAppointmentId={props.rescheduledAppointmentId}
          supportingStaffTimeSlots={
            scheduleData.supportingStaffTimeSlots || null
          }
          toast={
            <GridToastErrorOrLoading
              error={!!scheduleError}
              loading={scheduleLoading}
              retry={() => scheduleRefetch()}
            />
          }
        />
      </Box>
      <Box
        flex="60%"
        display="flex"
        flexDirection="column"
        gap={2}
        minHeight={0}
        sx={{ overflowY: "auto" }}
        paddingX={1}
        pb={2}
      >
        {isAvailableSlot(props.slotSelection) ? (
          // Read-only version of the appointment summary
          <AppointmentSummary
            editMode="readonly"
            timezone={props.timezone}
            searchParameters={props.searchParameters}
            staff={props.staff}
            clinicsById={useCachedClinics()}
            staffById={useCachedMotionStaff()}
            appointmentTypeConfiguration={useCachedAppointmentTypeConfigurations(
              true,
            )}
            time={startTime!}
            clinicId={props.slotSelection.slot.locationId || null}
          />
        ) : (
          // Editable version of the appointment summary
          <AppointmentSummary
            editMode="manual"
            timezone={props.timezone}
            searchParameters={props.searchParameters}
            staff={props.staff}
            clinicsById={useCachedClinics()}
            staffById={useCachedMotionStaff()}
            appointmentTypeConfiguration={useCachedAppointmentTypeConfigurations(
              true,
            )}
            week={props.week}
            weekday={props.slotSelection.weekday}
            selectedTime={startTime}
            onSelectedTimeChange={setStartTime}
            selectedClinicId={locationId}
            onSelectedClinicIdChange={setLocationId}
            disabled={bookOrRescheduleLoading}
          />
        )}
        <Divider />
        <BookingIssuesSummary
          loading={scheduleLoading}
          error={scheduleError}
          refetch={scheduleRefetch}
          bookingIssues={bookingIssues}
          issuesAcknowledged={issuesAcknowledged}
          onAcknowledgementChange={handleAcknowledgementChange}
          multiDisciplinary={isStaffTeam(props.staff)}
        />
        <Divider />
        {/* Additional details */}
        <Box display="grid" gridTemplateColumns="auto 1fr" gap={2}>
          {retroactive && (
            <Alert severity="warning" sx={{ gridColumn: "span 2" }}>
              No communication will be sent to the patient since the appointment
              is retroactive.
            </Alert>
          )}
          {/* New appointment fields. */}
          {!props.rescheduledAppointmentId && (
            <>
              {/* Patient Instructions */}
              <Typography variant="body1" fontWeight="bold">
                Patient Instructions
              </Typography>
              <TextField
                multiline
                label={"Patient Instructions (Optional)"}
                value={patientInstructions || ""}
                onChange={(e) => setPatientInstructions(e.target.value || null)}
                size="small"
                minRows={2}
                maxRows={5}
                disabled={retroactive || bookOrRescheduleLoading}
              />
            </>
          )}
          {/* Patient Notifications */}
          <Box display="column">
            <Typography variant="body1" fontWeight="bold">
              Notifications
            </Typography>
            {notificationSettings.notifiedVia.length > 0 && (
              <Typography variant="body2" color="textSecondary">
                Sent via: {notificationSettings.notifiedVia.join(", ")}
              </Typography>
            )}
          </Box>
          <AppointmentNotifications
            notifications={notifications}
            onNotificationsChange={setNotifications}
            disabled={
              !!notificationSettings.disabledReason || bookOrRescheduleLoading
            }
          />
        </Box>
        {/*  Actions */}
        <Box display="flex" justifyContent="flex-end" gap={2}>
          {bookOrRescheduleError && (
            <DetailedAlert
              message="Oops! Something went wrong. Please check for new booking conflicts and try again."
              additionalDetails={bookOrRescheduleError}
            />
          )}
          <LoadingButton
            disabled={!readyToSubmit}
            variant="contained"
            loading={bookOrRescheduleLoading}
            onClick={doBookOrReschedule}
            startIcon={<EventAvailableIcon />}
          >
            {getSubmitButtonLabel(
              !!props.rescheduledAppointmentId,
              startTime,
              props.timezone,
            )}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

function getSubmitButtonLabel(
  isRescheduleOperation: boolean,
  startTime: DateTime | null,
  timezone: MotionTimezone,
): string {
  const operationLabel = isRescheduleOperation ? "Reschedule" : "Book";
  if (startTime) {
    return `${operationLabel} on ${startTime
      .setZone(timezone)
      .toFormat("MM/dd 'at' t ZZZZ")}`;
  }
  return operationLabel;
}

/**
 * Check if we're ready to book / reschedule.
 */
function checkReadyToSubmit(
  startTime: DateTime | null,
  medium: AppointmentMedium,
  locationId: string | null,
  bookingIssues: ReadonlySet<BookingIssue> | null,
  issuesAcknowledged: ReadonlySet<BookingIssue>,
): boolean {
  if (startTime === null || bookingIssues === null) {
    return false;
  }

  if (medium === AppointmentMedium.InClinic && !locationId) {
    return false;
  }

  if (!isEqual(bookingIssues, issuesAcknowledged)) {
    return false;
  }

  return (
    !!startTime &&
    isEqual(bookingIssues, issuesAcknowledged) &&
    (!!locationId || medium !== AppointmentMedium.InClinic)
  );
}
