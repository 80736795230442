//@flow
import {DateTime} from "luxon";

/**
 * Util functions to deal with luxon DateTime objects
 */

export function getAbbreviatedNamedOffsetFromTimezone(timezone: ?string): ?string {
  if (!timezone) {
    return null;
  }

  const date = DateTime.now().setZone(timezone);
  if (!date.isValid) {
    console.warn('Incorrect timezone: ' + timezone);
    return null;
  }
  return date.offsetNameShort;
}
