import React from "react";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { StaticDatePicker } from '@mui/x-date-pickers'
import { DateTime } from "luxon";
import { useQuery } from "@apollo/client";
import { timestamps } from "../../../../../../libs/timestamps";
import {
  GET_ALL_CLINICS,
  GET_ELIGIBLE_PRACTITIONERS,
} from "../../../../../../libs/queries";
import {
  AppointmentMediumValues,
} from "../../../../../../generated/flow_types";
import type {Practitioner} from "../../../../../../generated/flow_types";
import {find} from "lodash";
import moment from "moment-timezone";

const SpecificTime = ({
  appointmentDetails,
  setAppointmentDetails,
  displayTimezone,
}) => {
  const {
    appointmentMedium,
    clinic,
    filterByClinicIds,
    leadPractitioner,
    selectedAppointmentConfig,
    startDay,
    startTime,
    supportingPractitioner,
    supportingPractitionerType,
  } = appointmentDetails;

  // TODO(PFA-729): handle errors/refresh
  const {
    data: clinicsData,
    loading: clinicsLoading,
  } = useQuery(GET_ALL_CLINICS);
  const clinics = clinicsData?.clinics || [];

  // TODO(PFA-729): handle errors/refetch
  const { data: practitionersData, loading: practitionersLoading } = useQuery(
    GET_ELIGIBLE_PRACTITIONERS,
    {
      variables: {
        appointmentMedium,
        appointmentType: selectedAppointmentConfig.appointmentType,
        filterByClinicIds,
        supportingPractitionerType,
      },
    }
  );
  const eligibleLeadPractitioners: Practitioner[] = practitionersData?.eligiblePractitioners?.leadPractitioners || [];
  const eligibleSupportingPractitioners: Practitioner[] = practitionersData?.eligiblePractitioners?.supportingPractitioners || [];

  const handleDatePicker = (selectedMoment) => {
    const formattedDay = selectedMoment.format("YYYY-MM-DD");

    setAppointmentDetails((state) => ({
      ...state,
      startDay: formattedDay,
    }));
  };

  const handleSelect = ({ target: { name, value } }) => {
    setAppointmentDetails((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const isInClinic = appointmentMedium === AppointmentMediumValues.InClinic;
  const hasSupportingPractitioner = !!supportingPractitionerType;
  const displayZone = DateTime.fromISO(startDay, {zone: displayTimezone}).offsetNameShort;

  const hasLeadPractitioners = !!eligibleLeadPractitioners.length;
  const hasSupportingPractitioners = !!eligibleSupportingPractitioners.length;

  const handleSelectLeadPractitioner = (e) => {
    const practitionerId = e.target.value;
    const practitioner = find(eligibleLeadPractitioners, p => p.id === practitionerId);
    setAppointmentDetails((state) => ({
      ...state,
      leadPractitioner: practitioner
    }))
  };

  const handleSelectSupportingPractitioner = (e) => {
    const practitionerId = e.target.value;
    const practitioner = find(eligibleSupportingPractitioners, p => p.id === practitionerId);
    setAppointmentDetails((state) => ({
      ...state,
      supportingPractitioner: practitioner
    }))
  };

  const handleSelectClinic = (e) => {
    const clinicId = e.target.value;
    const clinic = find(clinics, c => c.id === clinicId);
    setAppointmentDetails((state) => ({
      ...state,
      clinic: clinic
    }))
  };

  return (
    <div>
      <Alert severity="warning" sx={{marginTop: 1}}>
        Availability will <b>not</b> be checked! You're responsible for making sure the appointment
        can actually take place and will not conflict with any other appointment booked in the future.
      </Alert>
      <div
        style={{
          display: "grid",
          gap: 32,
          gridTemplateColumns: "287px 320px",
          margin: "20px 0",
          maxWidth: 400,
        }}
      >
        <div
          style={{
            alignSelf: "start",
            display: "grid",
            gap: 18,
          }}
        >
          <FormControl fullWidth>
            <InputLabel
              htmlFor="demo-simple-select-helper2"
              id="demo-simple-select-helper-label2"
            >
              Start Time ({displayZone}) *
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label2"
              id="demo-simple-select-helper2"
              label={`Start Time ${displayZone} * `}
              name="startTime"
              onChange={handleSelect}
              placeholder="Start Time * "
              value={startTime}
            >
              {timestamps.map(({ id, name, value }) => (
                <MenuItem key={id} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel
              htmlFor="demo-simple-select-helper3"
              id="demo-simple-select-helper-label3"
            >
              {practitionersLoading ? 'Loading... ' : 'Lead Practitioner *'}
            </InputLabel>
            <Select
              disabled={!hasLeadPractitioners}
              labelId="demo-simple-select-helper-label3"
              id="demo-simple-select-helper3"
              label={practitionersLoading ? 'Loading... ' : 'Lead Practitioner *'}
              onChange={handleSelectLeadPractitioner}
              placeholder={
                hasLeadPractitioners
                  ? "Lead Practitioner"
                  : "No Practitioners Available"
              }
              value={leadPractitioner?.id || ""}
            >
              {eligibleLeadPractitioners.map(
                (practitioner) => (
                  <MenuItem key={practitioner.id} value={practitioner.id}>
                    {practitioner.displayName}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          {hasSupportingPractitioner && (
            <FormControl fullWidth>
              <InputLabel
                htmlFor="demo-simple-select-helper4"
                id="demo-simple-select-helper-label4"
              >
                {practitionersLoading ? 'Loading... ' : 'Supporting Practitioner *'}
              </InputLabel>
              <Select
                disabled={!hasSupportingPractitioners}
                labelId="demo-simple-select-helper-label4"
                id="demo-simple-select-helper4"
                label={practitionersLoading ? 'Loading... ' : 'Supporting Practitioner *'}
                onChange={handleSelectSupportingPractitioner}
                placeholder={
                  hasSupportingPractitioners
                    ? "Lead Practitioner"
                    : "No Practitioners Available"
                }
                value={supportingPractitioner?.id || ""}
              >
                {eligibleSupportingPractitioners.map(
                  (practitioner) => (
                    <MenuItem key={practitioner.id} value={practitioner.id}>
                      {practitioner.displayName}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )}
          {isInClinic && (
            <FormControl fullWidth>
              <InputLabel
                htmlFor="demo-simple-select-helper5"
                id="demo-simple-select-helper-label5"
              >
                {clinicsLoading ? "Loading..." : "Clinic * "}
              </InputLabel>
              <Select
                disabled={!clinics.length}
                labelId="demo-simple-select-helper-label5"
                id="demo-simple-select-helper5"
                label={clinicsLoading ? "Loading..." : "Clinic * "}
                onChange={handleSelectClinic}
                placeholder={
                  !!clinics.length
                    ? "Clinic * "
                    : "No Clinic Available"
                }
                value={clinic?.id || ""}
              >
                {clinics.map((clinic) => (
                  <MenuItem key={clinic.id} value={clinic.id}>
                    {clinic.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div
          style={{
            border: "1px solid #DBDDE5",
            borderRadius: "4px",
            height: 361,
            width: 320,
          }}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="day"
            onChange={handleDatePicker}
            slotProps={{textField: { variant: 'outlined' }}}
            value={moment(startDay)}
          />
        </div>
      </div>
    </div>
  );
};

export default SpecificTime;
