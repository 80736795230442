import React from "react";
import StaffSchedules from "../StaffSchedules";
import { Box, Link, Typography } from "@mui/material";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { enableNewBooking } from "../../config";
import { Link as RouterLink } from "react-router-dom";

const Main = ({ userSession }: { userSession: CognitoUserSession }) => {
  const username = userSession.getIdToken().payload["cognito:username"];
  if (!username.toLowerCase().startsWith("okta_")) {
    return <div>Ooops! Something unexpected happened.</div>;
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box maxWidth={1100} flex={1} m={2}>
        <h1>Welcome {userSession.getIdToken().payload.given_name}!</h1>
        {enableNewBooking() ? (
          <Typography variant="body2" color="text.secondary">
            Looking for schedules? Head over to the{" "}
            <Link to="schedules" component={RouterLink}>
              Schedules page
            </Link>
            .
          </Typography>
        ) : (
          <StaffSchedules
            currentStaffId={username.toLowerCase().replace("okta_", "")}
          />
        )}
      </Box>
    </Box>
  );
};

export default Main;
