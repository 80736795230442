// @flow
import React from 'react';
import type {AppointmentMedium} from "../../../../generated/flow_types";
import {Box, Typography} from "@mui/material";
import {getMediumName} from "../../../../libs/scheduling";
import {AppointmentMediumValues} from "../../../../generated/flow_types";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import HomeIcon from "@mui/icons-material/Home";

type Props = {
  appointmentMedium: AppointmentMedium,
  iconOnly: boolean,
  color?: 'primary',
};

const AppointmentMediumLabel = (props: Props) => {
  const iconStyle = {
    color: '#8BA5CD',
    fontSize: 18,
    marginRight: 1
  };
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {props.appointmentMedium === AppointmentMediumValues.InClinic && (
        <FmdGoodOutlinedIcon sx={iconStyle}/>
      )}
      {props.appointmentMedium === AppointmentMediumValues.Virtual && (
        <VideocamOutlinedIcon sx={iconStyle}/>
      )}
      {props.appointmentMedium === AppointmentMediumValues.Phone && (
        <LocalPhoneOutlinedIcon sx={iconStyle}/>
      )}
      {props.appointmentMedium === AppointmentMediumValues.AtHome && (
        <HomeIcon sx={iconStyle}/>
      )}
      {!props.iconOnly && (
        <Typography color={props.color}>{getMediumName(props.appointmentMedium)}</Typography>
      )}
    </Box>
  );
};

export default AppointmentMediumLabel;
