import { AppointmentMedium } from "../../../../gql/graphql";
import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import SelectClinic from "../../../SelectClinic";

type Props = {
  allowedMediums: ReadonlySet<AppointmentMedium>;
  mediums: ReadonlySet<AppointmentMedium>;
  locationId: string | null;
  clinicError?: string;
  mediumError?: string;
  onChange: (
    mediums: ReadonlySet<AppointmentMedium>,
    locationId: string | null,
  ) => void;
};

/**
 * Subcomponent to select mediums and location.
 */
export default function SelectMediums(props: Props) {
  const handleChangeMedium = (medium: AppointmentMedium, checked: boolean) => {
    if (checked && !props.mediums.has(medium)) {
      props.onChange(new Set(props.mediums).add(medium), props.locationId);
    }

    if (!checked && props.mediums.has(medium)) {
      const newMediums = new Set(props.mediums);
      newMediums.delete(medium);
      props.onChange(newMediums, props.locationId);
    }
  };

  const handleChangeClinic = (newLocationId: string | null) => {
    let newMediums = new Set(props.mediums);
    if (
      newLocationId !== null &&
      !props.mediums.has(AppointmentMedium.InClinic)
    ) {
      newMediums.add(AppointmentMedium.InClinic);
    } else if (
      newLocationId === null &&
      props.mediums.has(AppointmentMedium.InClinic)
    ) {
      newMediums.delete(AppointmentMedium.InClinic);
    }
    props.onChange(newMediums, newLocationId);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="auto 1fr"
      columnGap={2}
      alignItems="center"
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={props.mediums.has(AppointmentMedium.InClinic)}
            onChange={(event) =>
              handleChangeMedium(
                AppointmentMedium.InClinic,
                event.target.checked,
              )
            }
            disabled={!props.allowedMediums.has(AppointmentMedium.InClinic)}
          />
        }
        label="In Clinic"
      />
      <SelectClinic
        multiple={false}
        selectedClinicId={props.locationId}
        onSelectedClinicChange={handleChangeClinic}
        size="small"
        disabled={!props.allowedMediums.has(AppointmentMedium.InClinic)}
        error={!!props.clinicError}
        helperText={props.clinicError || undefined}
        fullWidth
        sx={{ maxWidth: 300 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={props.mediums.has(AppointmentMedium.Virtual)}
            onChange={(event) =>
              handleChangeMedium(
                AppointmentMedium.Virtual,
                event.target.checked,
              )
            }
            disabled={!props.allowedMediums.has(AppointmentMedium.Virtual)}
          />
        }
        label="Virtual"
        sx={{ gridColumn: "span 2" }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={props.mediums.has(AppointmentMedium.Phone)}
            onChange={(event) =>
              handleChangeMedium(AppointmentMedium.Phone, event.target.checked)
            }
            disabled={!props.allowedMediums.has(AppointmentMedium.Phone)}
          />
        }
        label="Phone"
        sx={{ gridColumn: "span 2" }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={props.mediums.has(AppointmentMedium.AtHome)}
            onChange={(event) =>
              handleChangeMedium(AppointmentMedium.AtHome, event.target.checked)
            }
            disabled={!props.allowedMediums.has(AppointmentMedium.AtHome)}
          />
        }
        label="In-Home"
        sx={{ gridColumn: "span 2" }}
      />
      {!!props.mediumError && (
        <Typography
          color="error"
          variant="caption"
          sx={{ gridColumn: "span 2" }}
        >
          {props.mediumError}
        </Typography>
      )}
    </Box>
  );
}

export function validateMediums(
  mediums: ReadonlySet<AppointmentMedium>,
  locationId: string | null,
): {
  mediumsError?: string;
  clinicError?: string;
} {
  let mediumsError;
  let clinicError;

  if (mediums.size === 0) {
    mediumsError = "At least one medium must be selected";
  } else if (mediums.size > 1 && mediums.has(AppointmentMedium.AtHome)) {
    mediumsError = "In-home cannot be combined with other mediums";
  }

  if (locationId === null && mediums.has(AppointmentMedium.InClinic)) {
    clinicError = "A clinic must be selected";
  }

  return { mediumsError, clinicError };
}
