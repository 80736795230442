import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

const styles = {
  container: {
    backgroundColor: "#f5f5f5",
    height: "100vh",
  },
  card: {
    width: "450px",
    marginTop: "50px",
  },
};

type Props = {
  title: string;
  message?: string;
  children?: React.ReactNode;
};

/** Minimal Risalto-branded screen for unauthenticated routes. */
export const MinimalRisaltoScreen = ({ title, message, children }: Props) => {
  return (
    <Box sx={styles.container}>
      <Box display="flex" justifyContent="center">
        <Card sx={styles.card} variant="outlined">
          <CardMedia
            component="img"
            height="100"
            src={require("../../res/risalto_logo.png")}
            alt="risalto logo"
          />
          <CardContent>
            <Typography variant="h5" component="div">
              {title}
            </Typography>
            {message && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: 10 }}
              >
                {message}
              </Typography>
            )}
            {children}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
