import * as React from "react";
import {Chip} from "@mui/material";

const styles = {
  patient: {
    color: '#3441AF',
    backgroundColor: '#E0E4FF',
  }
}
export default function (props: { patientFacing: boolean }) {
  return <Chip
    size="small"
    label={props.patientFacing ? "patient" : "internal"}
    sx={props.patientFacing ? styles.patient : {} }
  />
}
