import {
  AppointmentConfigMap,
  HighlightRule,
  LocalSlot,
} from "../../../../libs/scheduleTemplates";
import { AppointmentMedium, AppointmentType } from "../../../../gql/graphql";
import { values } from "lodash";
import { useState } from "react";
import SwapLocationDialog from "./SwapLocationDialog";
import SwapAppointmentTypeDialog from "./SwapAppointmentTypeDialog";
import SlotInsights from "../../SlotInsights";

type Props = {
  slots: { [slotId: string]: LocalSlot };
  appointmentTypeConfigurations: AppointmentConfigMap;
  onSwapAppointmentTypes: (
    originalAppointmentType: AppointmentType,
    newAppointmentType: AppointmentType,
    mediumsUpdate: {
      mediums: ReadonlySet<AppointmentMedium>;
      locationId: string | null;
    } | null,
  ) => void;
  onSwapLocations: (originalLocationId: string, newLocationId: string) => void;
  onHighlightChange: (rule: HighlightRule | null) => void;
};

export default function WeeklyInsightsEdit(props: Props) {
  const [swappingAppointmentType, setSwappingAppointmentType] =
    useState<AppointmentType | null>(null);
  const [swappingLocationId, setSwappingLocationId] = useState<string | null>(
    null,
  );

  const handleSwapLocation = (newLocationId: string) => {
    if (swappingLocationId) {
      props.onSwapLocations(swappingLocationId, newLocationId);
    }
    setSwappingLocationId(null);
  };

  const handleSwapAppointmentType = (
    newAppointmentType: AppointmentType,
    mediumsUpdate: {
      mediums: ReadonlySet<AppointmentMedium>;
      locationId: string | null;
    } | null,
  ) => {
    if (swappingAppointmentType) {
      props.onSwapAppointmentTypes(
        swappingAppointmentType,
        newAppointmentType,
        mediumsUpdate,
      );
    }
    setSwappingAppointmentType(null);
  };

  return (
    <>
      <SlotInsights
        slots={values(props.slots)}
        appointmentTypeConfigurations={props.appointmentTypeConfigurations}
        onHighlightChange={props.onHighlightChange}
        onClickEditAppointmentType={setSwappingAppointmentType}
        onClickEditLocation={setSwappingLocationId}
      />
      {swappingLocationId && (
        <SwapLocationDialog
          originalLocationId={swappingLocationId}
          onClose={() => setSwappingLocationId(null)}
          onSubmit={handleSwapLocation}
        />
      )}
      {swappingAppointmentType && (
        <SwapAppointmentTypeDialog
          originalAppointmentType={swappingAppointmentType}
          appointmentTypeConfigurations={props.appointmentTypeConfigurations}
          onClose={() => setSwappingAppointmentType(null)}
          onSubmit={handleSwapAppointmentType}
        />
      )}
    </>
  );
}

export function formatDuration(durationInMinutes: number): string {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  let formattedString = "";
  if (hours > 0) {
    formattedString += `${hours}h`;
  }
  if (minutes > 0) {
    if (formattedString.length > 0) formattedString += " ";
    formattedString += `${minutes}min`;
  }

  return formattedString;
}
