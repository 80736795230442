import {
  AvailableSlot,
  EventFieldsFragment,
} from "../../../../../../gql/graphql";
import {
  convertMinutesToHeight,
  MotionTimezone,
} from "../../../../../../libs/time";
import { Box, Divider } from "@mui/material";
import StaffMemberDailyEvents from "./StaffMemberDailyEvents";
import StaffDailyAvailableSlots from "./StaffDailyAvailableSlots";
import { AppointmentStaff, SearchParams } from "../../../../../../libs/booking";

type BaseProps = {
  staff: AppointmentStaff;
  minHour: number;
  maxHour: number;
  timezone: MotionTimezone;
  // Assumed to be sanitized and filtered by day
  leadStaffEvents: Array<EventFieldsFragment>;
  // Assumed to be sanitized and filtered by day
  supportingStaffEvents: Array<EventFieldsFragment> | null;
  rescheduledAppointmentId: string | undefined;
  // If true, adds side gutters to each day's column
  sideGutters?: boolean;
  // The location id of an appointment being booked/rescheduled, if any.
  tentativeAppointmentLocationId?: string | null;
  style?: "normal" | "contrast";
};

type WithoutAvailabilityProps = BaseProps & {
  includeAvailability?: false;
};

type WithAvailabilityProps = BaseProps & {
  includeAvailability: true;
  searchParameters: SearchParams;
  // Assumed to be validated and filtered by day
  availableSlots: Array<AvailableSlot>;
  onSelect: (slot: AvailableSlot) => void;
};

function isWithAvailability(props: Props): props is WithAvailabilityProps {
  return (props as WithAvailabilityProps).includeAvailability;
}

type Props = WithoutAvailabilityProps | WithAvailabilityProps;

/**
 * Renders a single day of the schedule grid, for one or two staff members.
 */
export default function StaffScheduleDay(props: Props) {
  const heightInPixels = convertMinutesToHeight(
    (props.maxHour - props.minHour) * 60,
  );

  // Using height 0 to prevent the wrapper from blocking elements underneath.
  return (
    <Box height={0} position="relative" display="flex">
      {/* Left border */}
      <Divider orientation="vertical" sx={{ height: `${heightInPixels}px` }} />
      {/* Side by side events */}
      <Box
        height={0}
        flex={1}
        display="flex"
        paddingX={!!props.sideGutters ? "15%" : 1}
        gap={0.5}
      >
        <StaffMemberDailyEvents
          minHour={props.minHour}
          maxHour={props.maxHour}
          timezone={props.timezone}
          events={props.leadStaffEvents}
          style={props.style}
          tentativeAppointmentLocationId={props.tentativeAppointmentLocationId}
          rescheduledAppointmentId={props.rescheduledAppointmentId}
        />
        {props.supportingStaffEvents && (
          <StaffMemberDailyEvents
            minHour={props.minHour}
            maxHour={props.maxHour}
            timezone={props.timezone}
            events={props.supportingStaffEvents}
            style={props.style}
            rescheduledAppointmentId={props.rescheduledAppointmentId}
          />
        )}
      </Box>
      {isWithAvailability(props) && (
        <StaffDailyAvailableSlots
          staff={props.staff}
          timezone={props.timezone}
          availableSlots={props.availableSlots}
          searchParameters={props.searchParameters}
          minHour={props.minHour}
          maxHour={props.maxHour}
          onSelect={props.onSelect}
        />
      )}
    </Box>
  );
}
