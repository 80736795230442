import {
  maxScheduleHour,
  minScheduleHour,
  MotionTimezone,
} from "../../../../../../libs/time";
import { BaseScheduleGrid } from "../BaseScheduleGrid";
import { AppointmentMedium, TargetWeek } from "../../../../../../gql/graphql";
import { graphql } from "../../../../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import React from "react";
import { SearchParams, SlotSelection } from "../../../../../../libs/booking";
import { GET_PROVIDER_AVAILABILITY } from "../../ReadOnlyStaffBrowser/ReadOnlyStaffMemberBrowser";
import GridToastErrorOrLoading from "../GridToastErrorOrLoading";

type Props = {
  timezone: MotionTimezone;
  staffId: string;
  week: TargetWeek;
  searchParameters: SearchParams;
  patientId: string;
  onSelect: (selection: SlotSelection) => void;
  rescheduledAppointmentId: string | undefined;
};

graphql(`
  fragment EventFields on Event {
    ... on MedicalAppointment {
      id
      patientId
      startTime
      endTime
      appointmentType
      appointmentMedium
      clinicId
    }
    ... on NonPatientEvent {
      id
      staffId
      startTime
      endTime
    }
  }
`);

const GET_PROVIDER_WEEKLY_EVENTS = graphql(`
  query GetProviderWeeklyEvents($staffId: String!, $week: TargetWeek!) {
    providerWeeklyEvents(staffId: $staffId, week: $week) {
      ...EventFields
    }
  }
`);

export default function StaffMemberScheduleGrid(props: Props) {
  // Events
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(GET_PROVIDER_WEEKLY_EVENTS, {
    variables: {
      staffId: props.staffId,
      week: props.week,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  // Availability
  const {
    data: availabilityData,
    loading: availabilityLoading,
    error: availabilityError,
    refetch: availabilityRefetch,
  } = useQuery(GET_PROVIDER_AVAILABILITY, {
    variables: {
      staffId: props.staffId,
      week: props.week,
      appointmentType: props.searchParameters.appointmentType,
      medium: props.searchParameters.medium,
      locationIds:
        props.searchParameters.medium === AppointmentMedium.InClinic
          ? Array.from(props.searchParameters.clinicIds)
          : [],
      patientId: props.patientId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const refetchFailedQueries = () => {
    if (eventsError) {
      eventsRefetch().then(/* ignore results */);
    }
    if (availabilityError) {
      availabilityRefetch().then(/* ignore results */);
    }
  };

  return (
    <BaseScheduleGrid
      staff={props.staffId}
      minHour={minScheduleHour}
      maxHour={maxScheduleHour}
      week={props.week}
      timezone={props.timezone}
      searchParameters={props.searchParameters}
      toast={
        <GridToastErrorOrLoading
          loading={eventsLoading || availabilityLoading}
          error={!!eventsError || !!availabilityError}
          retry={refetchFailedQueries}
        />
      }
      leadStaffEvents={eventsData?.providerWeeklyEvents ?? []}
      supportingStaffEvents={null}
      rescheduledAppointmentId={props.rescheduledAppointmentId}
      availableSlots={
        availabilityData?.providerAvailability?.availableSlots ?? []
      }
      onSelect={props.onSelect}
    />
  );
}
