// @flow
import React from "react";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import {
  AppointmentMediumValues,
  AppointmentTypeValues,
} from "../../../../../generated/flow_types";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PersonIcon from "@mui/icons-material/Person";
import Slot from "../Slot";
import { DateTime } from "luxon";
import { TabNames } from "../initialState";
import AppointmentMediumLabel from "../../AppointmentMediumLabel";
import {getMediumName} from "../../../../../libs/scheduling";

const FourthStep = ({
  appointmentDetails: {
    appointmentMedium,
    appointmentDescription,
    clinic,
    durationInMinutes,
    externalProvider,
    leadPractitioner,
    notifyPatient,
    patientInstructions,
    remindPatient1h,
    remindPatient24h,
    selectedAppointmentConfig,
    selectedSlot,
    startDay,
    startTime: selectedStartTime,
    supportingPractitioner,
    tab,
  },
  patient,
  displayTimezone,
}) => {
  const isExternal =
    selectedAppointmentConfig.appointmentType ===
    AppointmentTypeValues.External;
  const isSpecificTime = tab === TabNames.specificTimes;
  const isClinicMedium = appointmentMedium === AppointmentMediumValues.InClinic;

  const startDateTime = isSpecificTime || isExternal
    ? DateTime.fromFormat(
      `${startDay} ${selectedStartTime}`,
      'yyyy-MM-dd h:mm a',
      {zone: displayTimezone})
    : DateTime.fromSeconds(selectedSlot.startTime, {zone: displayTimezone});

  const endDateTime = isSpecificTime || isExternal
    ? startDateTime.plus({minutes: (selectedAppointmentConfig.durationInMinutes || durationInMinutes)})
    : DateTime.fromSeconds(selectedSlot.endTime, {zone: displayTimezone});

  const date = startDateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
  const isRetroactive = startDateTime < DateTime.now();

  return (
    <div>
      <Typography
        color="primary"
        style={{ fontSize: 20, fontWeight: 500, marginBottom: 16 }}
      >
        Appointment Details
      </Typography>
      <div style={{ marginBottom: 36 }}>
        {isExternal ? (
          <div
            style={{
              alignItems: "center",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            <div
              style={{
                backgroundColor: "#F6F7FA",
                borderRadius: 8,
                display: "grid",
                padding: 12,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "grid",
                  gap: "8px",
                  gridTemplateColumns: "auto 1fr",
                }}
              >
                <AccessTimeIcon
                  sx={{ color: "#8BA5CD", fontSize: 18, marginRight: 1 }}
                />
                <Typography color="primary" style={{ fontSize: 20 }}>
                  {`${startDateTime.toFormat('h:mm a')}`} -{" "}
                  {`${endDateTime.toFormat('h:mm a ZZZZ')}`}
                </Typography>
                <DateRangeIcon
                  sx={{ color: "#8BA5CD", fontSize: 18, marginRight: 1 }}
                />
                <Typography color="primary">{date}</Typography>
                <PersonIcon
                  sx={{ color: "#8BA5CD", fontSize: 18, marginRight: 1 }}
                />
                <Typography color="primary">
                  {externalProvider.displayName}
                </Typography>
                {isClinicMedium && (
                  <>
                    <AppointmentMediumLabel appointmentMedium={appointmentMedium} iconOnly={true} />
                    <div>
                      <Typography color="primary" variant="caption">
                        {externalProvider.addressLines.join(", ")}
                      </Typography>
                    </div>
                  </>
                )}
                {!isClinicMedium && (
                  <>
                    <AppointmentMediumLabel appointmentMedium={appointmentMedium} iconOnly={true} />
                    <Typography color="primary">{getMediumName(appointmentMedium)}</Typography>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Slot
            appointmentMedium={selectedSlot?.appointmentMedium || appointmentMedium}
            clinic={selectedSlot?.clinic || clinic}
            startTime={startDateTime.toUnixInteger() }
            endTime={endDateTime.toUnixInteger()}
            leadPractitioner={selectedSlot?.leadPractitioner || leadPractitioner}
            supportingPractitioner={selectedSlot?.supportingPractitioner || supportingPractitioner}
            onClick={() => {}}
            showDate={true}
            displayTimezone={displayTimezone}
            selected={false}
          />
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled
              label="Appointment Description"
              value={appointmentDescription}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled
              label="Additional Notes (optional)"
              multiline
              rows={3}
              value={patientInstructions}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      {!isRetroactive &&
        (patient.communicationPreferences.consentsToSMS ||
          patient.communicationPreferences.consentsToEmails) && (
          <>
            <Typography
              color="primary"
              style={{ fontSize: 20, fontWeight: 500, marginTop: 48 }}
            >
              Notifications
            </Typography>
            {isExternal ? (
              <Typography color="primary" style={{ marginTop: 16 }}>
                External appointments don't support notifications yet.
              </Typography>
            ) : (
              <div>
                <Typography color="primary" style={{ marginTop: 16 }}>
                  {notifyPatient || remindPatient1h || remindPatient24h
                    ? "Sent via: Email & SMS"
                    : "No notifications selected"}
                </Typography>
                <div style={{ display: "grid", gap: 16, marginTop: 16 }}>
                  {notifyPatient && (
                    <span
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30px 1fr",
                      }}
                    >
                      <CheckCircleOutlineIcon style={{ color: "#61679e" }} />
                      <Typography color="primary">Confirmation</Typography>
                    </span>
                  )}
                  {remindPatient24h && (
                    <span
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30px 1fr",
                      }}
                    >
                      <CheckCircleOutlineIcon style={{ color: "#61679e" }} />
                      <Typography color="primary">24h Reminder</Typography>
                    </span>
                  )}
                  {remindPatient1h && (
                    <span
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30px 1fr",
                      }}
                    >
                      <CheckCircleOutlineIcon style={{ color: "#61679e" }} />
                      <Typography color="primary">1h Reminder</Typography>
                    </span>
                  )}
                </div>
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default FourthStep;
