import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { graphql } from "../../../gql";
import SelectStaff from "../../SelectStaff";
import { useSearchParams } from "../../../hooks/searchParams";
import {
  MotionUserFieldsFragment,
  ScheduleFieldsForListFragment,
  ScheduleFieldsFragment,
} from "../../../gql/graphql";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import DetailedAlert from "../../DetailedAlert";
import { keyBy, sortBy, values } from "lodash";
import Chip from "@mui/material/Chip";
import { ApolloCache, useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import { ALL_SCHEDULES_AND_STAFF } from "../BrowseSchedules";
import { validateTentativeDraft } from "../../../libs/scheduleTemplates";
import { MotionTimezone, timezoneLabels } from "../../../libs/time";

export const CREATE_EMPTY_DRAFT = graphql(`
  mutation CreateEmptyDraft(
    $targetStaffId: String!
    $takesEffectDate: String!
    $timezone: String!
  ) {
    createNewEmptySchedule(
      targetStaffId: $targetStaffId
      takesEffectDate: $takesEffectDate
      timezone: $timezone
    ) {
      ...ScheduleFields
    }
  }
`);

export const CREATE_DRAFT_FROM_SCHEDULE = graphql(`
  mutation CreateDraftFromSchedule(
    $targetStaffId: String!
    $sourceScheduleId: String!
    $takesEffectDate: String!
    $timezone: String!
  ) {
    copyExistingSchedule(
      sourceScheduleId: $sourceScheduleId
      targetStaffId: $targetStaffId
      takesEffectDate: $takesEffectDate
      timezone: $timezone
    ) {
      ...ScheduleFields
    }
  }
`);

/**
 *  Main component to create a new draft, either copying from an existing
 *  schedule or starting from scratch.
 */
export default function CreateScheduleDraft() {
  const history = useHistory();
  const [searchParams] = useSearchParams();
  const [selectedStaffId, setSelectedStaffId] = useState<string | null>(
    searchParams.staffId,
  );
  const [selectedStartDate, setSelectedStartDate] = useState<DateTime | null>(
    null,
  );
  const [sourceScheduleId, setSourceScheduleId] = useState<string | null>(null);
  const [timezone, setTimezone] = useState<MotionTimezone>(
    MotionTimezone.NEW_YORK,
  );

  useEffect(() => {
    if (searchParams.staffId) {
      setSelectedStaffId(searchParams.staffId);
    }
  }, [searchParams.staffId]);

  const { data, loading, error, refetch } = useQuery(ALL_SCHEDULES_AND_STAFF, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [
    createEmptyDraft,
    { error: createEmptyError, loading: createEmptyLoading },
  ] = useMutation(CREATE_EMPTY_DRAFT, {
    update(cache, { data }) {
      if (data?.createNewEmptySchedule) {
        updateApolloScheduleCache(cache, data.createNewEmptySchedule);
      }
    },
  });
  const [
    createDraftFromSchedule,
    { error: createCopyError, loading: createCopyLoading },
  ] = useMutation(CREATE_DRAFT_FROM_SCHEDULE, {
    update(cache, { data }) {
      if (data?.copyExistingSchedule) {
        updateApolloScheduleCache(cache, data.copyExistingSchedule);
      }
    },
  });

  if (loading) {
    return (
      <LayoutWrapper>
        <CircularProgress />
      </LayoutWrapper>
    );
  }

  const staffMembersById = keyBy(
    data?.masqueradeableMotionUsers,
    (user) => user.id,
  );

  const schedules: Array<ScheduleFieldsForListFragment> = sortBy(
    data?.allSchedules,
    (schedule) => getStaffName(schedule, staffMembersById),
    (schedule) => schedule.takesEffectDate,
  );

  const staffSchedules = schedules.filter(
    (schedule) => schedule.staff.id === selectedStaffId,
  );
  const validationResults = validateTentativeDraft(
    selectedStaffId,
    selectedStartDate,
    staffSchedules,
  );

  const timezoneDiffers =
    sourceScheduleId &&
    schedules.find((schedule) => schedule.id === sourceScheduleId)?.timezone !==
      timezone;

  const createDraft = async () => {
    if (!selectedStaffId || !selectedStartDate || !validationResults.allowed) {
      return;
    }

    let newSchedule: ScheduleFieldsFragment | undefined;
    if (!!sourceScheduleId) {
      newSchedule = (
        await createDraftFromSchedule({
          variables: {
            targetStaffId: selectedStaffId,
            sourceScheduleId,
            takesEffectDate: selectedStartDate.toISODate()!,
            timezone,
          },
        })
      ).data?.copyExistingSchedule;
    } else {
      newSchedule = (
        await createEmptyDraft({
          variables: {
            targetStaffId: selectedStaffId,
            takesEffectDate: selectedStartDate.toISODate()!,
            timezone,
          },
        })
      ).data?.createNewEmptySchedule;
    }

    if (newSchedule) {
      history.push(`/schedules/${newSchedule.id}`);
    }
  };
  return (
    <LayoutWrapper>
      <Box
        display="grid"
        gridTemplateColumns="minmax(auto, 250px) minmax(400px, min-content) auto"
        gap={2}
        alignItems="center"
      >
        {/* Staff member */}
        <Typography variant="body1">Provider:</Typography>
        <SelectStaff value={selectedStaffId} onChange={setSelectedStaffId} />
        <Box />

        {/* Start date */}
        <Typography variant="body1">Start Date (Mondays only):</Typography>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-us">
          <MobileDatePicker
            label="Start Date"
            shouldDisableDate={(day) => !isMonday(day as DateTime)}
            value={selectedStartDate}
            onChange={(date) => setSelectedStartDate(date)}
            slotProps={{ textField: { error: !validationResults.allowed } }}
          />
        </LocalizationProvider>
        <Box display="flex" gap={1} alignItems="center">
          {!!validationResults.message && (
            <Typography
              variant="body2"
              color={validationResults.allowed ? "inherit" : "error"}
            >
              {validationResults.message}
            </Typography>
          )}
        </Box>

        {/* Source schedule */}
        <Box display="flex" gap={1} flexDirection="column">
          <Typography variant="body1">Copy Schedule:</Typography>
          <Typography variant="body2" color="textSecondary">
            (optional) Select an existing schedule to copy from, or leave blank
            to start from scratch.
          </Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          {!!error && (
            <DetailedAlert
              message="Oops! Failed to load schedules. Please try again."
              additionalDetails={error}
              retry={() => refetch()}
            />
          )}
          {loading && (
            <Select value="loading" disabled fullWidth>
              <MenuItem value="loading">
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography variant="body1">Loading…</Typography>
                  <CircularProgress size={18} color="inherit" />
                </Box>
              </MenuItem>
            </Select>
          )}
          {!loading && !error && (
            <FormControl fullWidth>
              <InputLabel id="copy-from-label">
                Copy Schedule (optional)
              </InputLabel>
              <Select
                labelId="copy-from-label"
                label="Copy Schedule (optional)"
                value={sourceScheduleId || ""}
                onChange={(e) =>
                  setSourceScheduleId((e.target.value as string) || null)
                }
              >
                <MenuItem value="">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    fontStyle="italic"
                  >
                    Start from scratch
                  </Typography>
                </MenuItem>
                {schedules.map((schedule) => (
                  <MenuItem key={schedule.id} value={schedule.id}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography>
                        {getStaffName(schedule, staffMembersById)}
                      </Typography>
                      {getStaffCredentials(schedule, staffMembersById) && (
                        <Typography variant="body2" color="textSecondary">
                          , {getStaffCredentials(schedule, staffMembersById)}
                        </Typography>
                      )}
                      <Typography> — {schedule.takesEffectDate}</Typography>
                      {schedule.isDraft && (
                        <Chip label={"Draft"} size="small" />
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box />

        {/*  Timezone */}
        <Typography variant="body1">Timezone:</Typography>
        <FormControl fullWidth>
          <InputLabel id="tz-label">Timezone</InputLabel>
          <Select
            labelId="tz-label"
            label="Timezone"
            value={timezone}
            onChange={(e) => setTimezone(e.target.value as MotionTimezone)}
          >
            {values(MotionTimezone).map((tz) => (
              <MenuItem key={tz} value={tz}>
                {timezoneLabels[tz]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box display="flex" gap={1} alignItems="center">
          {timezoneDiffers && (
            <Alert severity="warning">
              Timezone differs from the source schedule.
            </Alert>
          )}
        </Box>
      </Box>
      <Box display="flex" gap={1} alignItems="center">
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={createDraft}
          disabled={
            !selectedStaffId || !selectedStartDate || !validationResults.allowed
          }
          loading={createEmptyLoading || createCopyLoading}
        >
          Create Draft
        </LoadingButton>
        {(createEmptyError || createCopyError) && (
          <Box>
            <DetailedAlert
              message={
                "Oops! Something went wrong. Please " +
                "try again. If the problem persists, refresh this page."
              }
            />
          </Box>
        )}
      </Box>
    </LayoutWrapper>
  );
}

function LayoutWrapper(props: { children: React.ReactNode }) {
  return (
    <Box p={2} sx={{ overflowY: "auto" }}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 2,
        }}
      >
        <Typography variant="h6" color="primary">
          New Schedule Draft
        </Typography>
        {props.children}
      </Paper>
    </Box>
  );
}

function isMonday(day: DateTime) {
  return day.weekday === 1;
}

function getStaffName(
  schedule: ScheduleFieldsForListFragment,
  staffMembersById: { [staffId: string]: MotionUserFieldsFragment },
): string {
  return staffMembersById[schedule.staff.id]?.displayName || schedule.staff.id;
}

function getStaffCredentials(
  schedule: ScheduleFieldsForListFragment,
  staffMembersById: { [staffId: string]: MotionUserFieldsFragment },
): string | null {
  return staffMembersById[schedule.staff.id]?.credentialsAbbreviation || null;
}

export function updateApolloScheduleCache(
  cache: ApolloCache<any>,
  newSchedule: ScheduleFieldsFragment,
) {
  // Add to allSchedules
  cache.modify({
    fields: {
      allSchedules(existingSchedules) {
        return [...existingSchedules, newSchedule];
      },
    },
  });
}
