import React from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { VisitNoteOrder } from "../../../../gql/graphql";
import OrdersReadOnly from "../VisitNoteViewer/OrdersReadOnly";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { graphql } from "../../../../gql";
import { useMutation } from "@apollo/client";

type Props = {
  visitNoteId: string;
  persistedOrders: Array<VisitNoteOrder>;
  onClose: () => void;
};

export const FINALIZE_ORDERS_MUTATION = graphql(`
  mutation FinalizeOrders($visitNoteId: String!) {
    finalizeOrders(visitNoteId: $visitNoteId) {
      ...VisitNoteFields
    }
  }
`);
export default function FinalizeOrdersDialog(props: Props) {
  const [finalizeOrders, { error, loading }] = useMutation(
    FINALIZE_ORDERS_MUTATION,
    {
      variables: {
        visitNoteId: props.visitNoteId,
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const maybeClose = () => {
    if (loading) {
      return;
    }
    props.onClose();
  };

  return (
    <Dialog open={true} onClose={maybeClose} maxWidth="md" fullWidth>
      <DialogTitle>Finalize Orders?</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <Alert severity="warning">
            You're about to finalize the order set for this note. Take a moment
            to review the orders below, as you will not be able to edit them
            after finalizing.
          </Alert>
          <OrdersReadOnly orders={props.persistedOrders} />
        </Box>
      </DialogContent>
      <DialogActions>
        {error && (
          <Alert severity="error">
            Oops! Something went wrong. Please try again.
          </Alert>
        )}
        <Button onClick={maybeClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          endIcon={<LockOutlinedIcon />}
          loading={loading}
          onClick={() => finalizeOrders()}
        >
          Looks good, finalize
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
