import { SearchParams, StaffTeam } from "../../../../../../libs/booking";
import { TargetWeek } from "../../../../../../gql/graphql";
import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AppointmentStaffInfo from "../../../../../AppointmentStaffInfo";
import { useCachedMotionStaff } from "../../../../../../hooks/commonQueries";
import { isEmpty, keys } from "lodash";
import SelectStaff from "../../../../../SelectStaff";

type Props = {
  patientId: string;
  searchParameters: SearchParams;
  week: TargetWeek;
  selectedTeam: StaffTeam | null;
  onSelect: (team: StaffTeam) => void;
};

/**
 * Component to add and switch between staff teams.
 */
export default function StaffTeamBrowser(props: Props) {
  const [showAddTeamDialog, setShowAddTeamDialog] = useState(false);
  const [visibleTeams, setVisibleTeams] = useState<Record<string, StaffTeam>>(
    () => {
      if (props.selectedTeam) {
        return { [getTeamId(props.selectedTeam)]: props.selectedTeam };
      }
      return {};
    },
  );

  const handleCloseDialog = (newTeam: StaffTeam | null) => {
    if (newTeam) {
      setVisibleTeams({ ...visibleTeams, [getTeamId(newTeam)]: newTeam });
      props.onSelect(newTeam);
    }
    setShowAddTeamDialog(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="body1" fontWeight="bold">
        Teams
      </Typography>
      {isEmpty(visibleTeams) && (
        <Typography variant="body2" color="text.secondary">
          Add a team to get started.
        </Typography>
      )}
      <List>
        {keys(visibleTeams).map((teamId) => {
          const team = visibleTeams[teamId];
          return (
            <StaffTeamRow
              key={teamId}
              staff={team}
              selected={
                !!props.selectedTeam && teamId === getTeamId(props.selectedTeam)
              }
              onClick={() => props.onSelect(team)}
            />
          );
        })}
      </List>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        sx={{ alignSelf: "flex-start" }}
        onClick={() => setShowAddTeamDialog(true)}
      >
        Add Team
      </Button>
      {showAddTeamDialog && (
        <AddTeamDialog
          visibleTeamIds={new Set(keys(visibleTeams))}
          onAdd={(team) => {
            setVisibleTeams((prev) => ({
              ...prev,
              [getTeamId(team)]: team,
            }));
          }}
          onClose={handleCloseDialog}
        />
      )}
    </Box>
  );
}

/**
 * Selectable team
 */
export function StaffTeamRow(props: {
  staff: StaffTeam;
  selected: boolean;
  onClick: () => void;
}) {
  const staffById = useCachedMotionStaff();
  return (
    <ListItemButton
      selected={props.selected}
      onClick={props.onClick}
      dense
      sx={{ padding: 1 }}
    >
      <AppointmentStaffInfo staff={props.staff} staffById={staffById} />
    </ListItemButton>
  );
}

function getTeamId(team: StaffTeam) {
  return `${team.leadStaffId}_${team.supportingStaffId}`;
}

function AddTeamDialog(props: {
  visibleTeamIds: ReadonlySet<string>;
  onAdd: (team: StaffTeam) => void;
  onClose: (newTeam: StaffTeam | null) => void;
}) {
  const [leadStaffId, setLeadStaffId] = useState<string | null>(null);
  const [supportingStaffId, setSupportingStaffId] = useState<string | null>(
    null,
  );

  const handleLeadStaffChange = (leadStaffId: string | null) => {
    if (leadStaffId === supportingStaffId) {
      setSupportingStaffId(null);
    }
    setLeadStaffId(leadStaffId);
  };

  const tentativeTeam =
    !!leadStaffId && !!supportingStaffId
      ? { leadStaffId, supportingStaffId }
      : null;

  return (
    <Dialog
      open={true}
      onClose={() => props.onClose(null)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add Provider Team</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <Alert severity="info">
            Tips for selecting teams:
            <ul style={{ margin: 0, paddingLeft: 20 }}>
              <li>
                L3 appointments
                <ul style={{ paddingLeft: 20 }}>
                  <li>
                    <strong>Lead</strong> provider should be an{" "}
                    <strong>L2</strong>
                  </li>
                  <li>
                    <strong>Supporting</strong> provider should be the{" "}
                    <strong>L3</strong>
                  </li>
                </ul>
              </li>
            </ul>
          </Alert>
          <Box
            display="grid"
            gridTemplateColumns="auto 1fr"
            gap={2}
            alignItems="center"
          >
            <Typography variant="body2" fontWeight="bold">
              Lead Provider
            </Typography>
            <SelectStaff value={leadStaffId} onChange={handleLeadStaffChange} />
            <Typography variant="body2" fontWeight="bold">
              Supporting Provider
            </Typography>
            <SelectStaff
              value={supportingStaffId}
              onChange={setSupportingStaffId}
              shouldDisableStaffMember={(staffId) => staffId === leadStaffId}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.onClose(null)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => props.onClose(tentativeTeam)}
          disabled={!tentativeTeam}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
