import { StaffTeam } from "../../../../../../libs/booking";
import { Box, Typography } from "@mui/material";
import { StaffTeamRow } from "../../StaffBrowser/StaffTeamBrowser";

type Props = {
  team: StaffTeam;
};

/**
 * A staff "browser" displaying a single staff team.
 * Unlike the single-provider version, we don't display availability here.
 */
export default function ReadOnlyStaffTeamBrowser(props: Props) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body1" fontWeight="bold">
        Providers
      </Typography>
      <StaffTeamRow staff={props.team} selected={true} onClick={() => {}} />
    </Box>
  );
}
