// @flow

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Duration: any,
  Long: any,
  Period: any,
};

export type ActiveConversation = {
  __typename?: 'ActiveConversation',
  id: $ElementType<Scalars, 'String'>,
  lastEventTime: $ElementType<Scalars, 'String'>,
  lastMemberMessageTime?: ?$ElementType<Scalars, 'String'>,
  lastMemberReadTime?: ?$ElementType<Scalars, 'String'>,
  lastMotionMessageTime?: ?$ElementType<Scalars, 'String'>,
  member: MemberDisplayInfo,
  owner?: ?Identity,
  ownerId?: ?$ElementType<Scalars, 'String'>,
  ownerName?: ?$ElementType<Scalars, 'String'>,
  state: ConversationState,
};

export type Address = {
  __typename?: 'Address',
  addressLines: Array<$ElementType<Scalars, 'String'>>,
  city?: ?$ElementType<Scalars, 'String'>,
  state?: ?$ElementType<Scalars, 'String'>,
  zip?: ?$ElementType<Scalars, 'String'>,
};

export type AppointmentHostInfo = ExternalProviderHostInfo | StaffMemberHostInfo;

export const AppointmentMediumValues = Object.freeze({
  AtHome: 'AT_HOME',
  InClinic: 'IN_CLINIC',
  Other: 'OTHER',
  Phone: 'PHONE',
  Virtual: 'VIRTUAL'
});


export type AppointmentMedium = $Values<typeof AppointmentMediumValues>;

export type AppointmentSlot = {
  __typename?: 'AppointmentSlot',
  appointmentMedium: AppointmentMedium,
  appointmentType: AppointmentType,
  clinic?: ?Clinic,
  endTime: $ElementType<Scalars, 'Long'>,
  id: $ElementType<Scalars, 'String'>,
  leadPractitioner: Practitioner,
  startTime: $ElementType<Scalars, 'Long'>,
  supportingPractitioner?: ?Practitioner,
};

export type AppointmentSlotUpdate = {
  appointmentType?: ?AppointmentType,
  locationId?: ?$ElementType<Scalars, 'String'>,
  supportedMediums: Array<AppointmentMedium>,
};

export type AppointmentTimeSlot = {
  ...TimeSlot,
  ...{
    __typename?: 'AppointmentTimeSlot',
    appointmentType: AppointmentType,
    durationInMinutes: $ElementType<Scalars, 'Int'>,
    locationId?: ?$ElementType<Scalars, 'String'>,
    minutesSinceMidnight: $ElementType<Scalars, 'Int'>,
    supportedMediums: Array<AppointmentMedium>,
  }
};

export const AppointmentTypeValues = Object.freeze({
  CareNavigator: 'CARE_NAVIGATOR',
  CareNavigatorFollowup: 'CARE_NAVIGATOR_FOLLOWUP',
  External: 'EXTERNAL',
  Followup: 'FOLLOWUP',
  HealthCoach: 'HEALTH_COACH',
  HealthCoachCheckin: 'HEALTH_COACH_CHECKIN',
  HealthCoachFollowup: 'HEALTH_COACH_FOLLOWUP',
  HealthCoachSpecialist: 'HEALTH_COACH_SPECIALIST',
  HealthCoachSpecialistFollowup: 'HEALTH_COACH_SPECIALIST_FOLLOWUP',
  L3: 'L3',
  L3Followup: 'L3_FOLLOWUP',
  L3WithMd: 'L3_WITH_MD',
  L3WithMdFollowup: 'L3_WITH_MD_FOLLOWUP',
  L3WithPa: 'L3_WITH_PA',
  L3WithPaFollowup: 'L3_WITH_PA_FOLLOWUP',
  Md: 'MD',
  MdFollowup: 'MD_FOLLOWUP',
  MedManagement: 'MED_MANAGEMENT',
  MedManagementFollowup: 'MED_MANAGEMENT_FOLLOWUP',
  Motion_360: 'MOTION_360',
  NewPatient: 'NEW_PATIENT',
  OpioidTaperFollowupMd: 'OPIOID_TAPER_FOLLOWUP_MD',
  OpioidTaperFollowupPa: 'OPIOID_TAPER_FOLLOWUP_PA',
  OpioidTaperMd: 'OPIOID_TAPER_MD',
  OpioidTaperPa: 'OPIOID_TAPER_PA',
  Pa: 'PA',
  PainCoach: 'PAIN_COACH',
  PainCoachFollowup: 'PAIN_COACH_FOLLOWUP',
  PaFollowup: 'PA_FOLLOWUP',
  Pt: 'PT',
  PtFollowup: 'PT_FOLLOWUP',
  PtFollowupMultiBodyPart: 'PT_FOLLOWUP_MULTI_BODY_PART',
  PtReevaluation: 'PT_REEVALUATION',
  Specialist: 'SPECIALIST',
  ZoomPractice: 'ZOOM_PRACTICE'
});


export type AppointmentType = $Values<typeof AppointmentTypeValues>;

export type AppointmentTypeConfiguration = {
  __typename?: 'AppointmentTypeConfiguration',
  appointmentType: AppointmentType,
  durationInMinutes?: ?$ElementType<Scalars, 'Long'>,
  internalName: $ElementType<Scalars, 'String'>,
  leadPractitionerType?: ?PractitionerType,
  supportedMediums: Array<AppointmentMedium>,
  supportingPractitionerTypes: Array<PractitionerType>,
};

export type Assessment = {
  __typename?: 'Assessment',
  assessmentType: $ElementType<Scalars, 'String'>,
  createdAt: $ElementType<Scalars, 'Long'>,
  createdBy: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  outcome?: ?AssessmentOutcome,
  patientAccessToken?: ?PatientAccessToken,
  patientId: $ElementType<Scalars, 'String'>,
  scoringOutput: FormattedScoringOutput,
  status: AssessmentStatus,
  updatedAt?: ?$ElementType<Scalars, 'Long'>,
  updatedBy: $ElementType<Scalars, 'String'>,
};

export type AssessmentAnswerInput = {
  questionId: $ElementType<Scalars, 'String'>,
  value: Array<$ElementType<Scalars, 'String'>>,
};

export type AssessmentConfiguration = {
  __typename?: 'AssessmentConfiguration',
  beta: $ElementType<Scalars, 'Boolean'>,
  description: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  patientFacing: $ElementType<Scalars, 'Boolean'>,
};

export type AssessmentNumericQuestion = {
  ...AssessmentQuestion,
  ...{
    __typename?: 'AssessmentNumericQuestion',
    default?: ?QuestionDefault,
    explanation?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'String'>,
    max?: ?$ElementType<Scalars, 'Int'>,
    min?: ?$ElementType<Scalars, 'Int'>,
    required: $ElementType<Scalars, 'Boolean'>,
    text: $ElementType<Scalars, 'String'>,
    value?: ?Array<$ElementType<Scalars, 'String'>>,
  }
};

export type AssessmentOutcome = {
  __typename?: 'AssessmentOutcome',
  formattedAnswers: Array<FormattedValue>,
  formattedMetrics: Array<FormattedValue>,
  otherOutput: Array<$ElementType<Scalars, 'String'>>,
};

export type AssessmentPage = {
  __typename?: 'AssessmentPage',
  cursor: $ElementType<Scalars, 'String'>,
  first: $ElementType<Scalars, 'Boolean'>,
  last: $ElementType<Scalars, 'Boolean'>,
  steps: Array<AssessmentStep>,
};

export type AssessmentPageInput = {
  answers: Array<AssessmentAnswerInput>,
  cursor: $ElementType<Scalars, 'String'>,
};

export type AssessmentPlainTextQuestion = {
  ...AssessmentQuestion,
  ...{
    __typename?: 'AssessmentPlainTextQuestion',
    default?: ?QuestionDefault,
    explanation?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'String'>,
    multiline: $ElementType<Scalars, 'Boolean'>,
    required: $ElementType<Scalars, 'Boolean'>,
    text: $ElementType<Scalars, 'String'>,
    value?: ?Array<$ElementType<Scalars, 'String'>>,
  }
};

export type AssessmentQuestion = {
  default?: ?QuestionDefault,
  explanation?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  text: $ElementType<Scalars, 'String'>,
  value?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type AssessmentReadOnly = {
  __typename?: 'AssessmentReadOnly',
  style: TextStyle,
  text: $ElementType<Scalars, 'String'>,
};

export type AssessmentSelectOption = {
  __typename?: 'AssessmentSelectOption',
  label: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
};

export type AssessmentSelectQuestion = {
  ...AssessmentQuestion,
  ...{
    __typename?: 'AssessmentSelectQuestion',
    default?: ?QuestionDefault,
    explanation?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'String'>,
    multi: $ElementType<Scalars, 'Boolean'>,
    options: Array<AssessmentSelectOption>,
    required: $ElementType<Scalars, 'Boolean'>,
    text: $ElementType<Scalars, 'String'>,
    value?: ?Array<$ElementType<Scalars, 'String'>>,
  }
};

export type AssessmentSliderOption = {
  __typename?: 'AssessmentSliderOption',
  label: $ElementType<Scalars, 'String'>,
  numericValue: $ElementType<Scalars, 'Int'>,
};

export type AssessmentSliderQuestion = {
  ...AssessmentQuestion,
  ...{
    __typename?: 'AssessmentSliderQuestion',
    default?: ?QuestionDefault,
    explanation?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'String'>,
    options: Array<AssessmentSliderOption>,
    required: $ElementType<Scalars, 'Boolean'>,
    text: $ElementType<Scalars, 'String'>,
    value?: ?Array<$ElementType<Scalars, 'String'>>,
  }
};

export const AssessmentStatusValues = Object.freeze({
  Completed: 'COMPLETED',
  Draft: 'DRAFT'
});


export type AssessmentStatus = $Values<typeof AssessmentStatusValues>;

export type AssessmentStep = AssessmentNumericQuestion | AssessmentPlainTextQuestion | AssessmentReadOnly | AssessmentSelectQuestion | AssessmentSliderQuestion | AssessmentYesNoQuestion;

export type AssessmentSubmissionResult = AssessmentPage | CompletedAssessmentSummary;

export type AssessmentYesNoQuestion = {
  ...AssessmentQuestion,
  ...{
    __typename?: 'AssessmentYesNoQuestion',
    default?: ?QuestionDefault,
    explanation?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'String'>,
    required: $ElementType<Scalars, 'Boolean'>,
    text: $ElementType<Scalars, 'String'>,
    value?: ?Array<$ElementType<Scalars, 'String'>>,
  }
};

export type AvailableSlot = {
  __typename?: 'AvailableSlot',
  endTime: $ElementType<Scalars, 'Long'>,
  locationId?: ?$ElementType<Scalars, 'String'>,
  startTime: $ElementType<Scalars, 'Long'>,
};

export const BodyPartValues = Object.freeze({
  ElbowLeft: 'ELBOW_LEFT',
  ElbowRight: 'ELBOW_RIGHT',
  FootLeft: 'FOOT_LEFT',
  FootRight: 'FOOT_RIGHT',
  HipLeft: 'HIP_LEFT',
  HipRight: 'HIP_RIGHT',
  KneeLeft: 'KNEE_LEFT',
  KneeRight: 'KNEE_RIGHT',
  LowBack: 'LOW_BACK',
  Neck: 'NECK',
  Other: 'OTHER',
  ShoulderLeft: 'SHOULDER_LEFT',
  ShoulderRight: 'SHOULDER_RIGHT',
  ThoracicSpine: 'THORACIC_SPINE',
  WristLeft: 'WRIST_LEFT',
  WristRight: 'WRIST_RIGHT'
});


export type BodyPart = $Values<typeof BodyPartValues>;

export type ClientUpgradeInput = {
  osVersion: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
  versionString: $ElementType<Scalars, 'String'>,
};

export const ClientUpgradeRecommendationValues = Object.freeze({
  ForceUpgrade: 'FORCE_UPGRADE',
  SuggestUpgrade: 'SUGGEST_UPGRADE',
  VersionOk: 'VERSION_OK'
});


export type ClientUpgradeRecommendation = $Values<typeof ClientUpgradeRecommendationValues>;

export type ClientUpgradeResponse = {
  __typename?: 'ClientUpgradeResponse',
  message?: ?$ElementType<Scalars, 'String'>,
  recommendation: ClientUpgradeRecommendation,
};

export type Clinic = {
  __typename?: 'Clinic',
  addressLines: Array<$ElementType<Scalars, 'String'>>,
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
};

export type CompletedAssessmentSummary = {
  __typename?: 'CompletedAssessmentSummary',
  completedAt?: ?$ElementType<Scalars, 'Long'>,
};

export type CompletionMetadata = {
  __typename?: 'CompletionMetadata',
  completedAt: $ElementType<Scalars, 'Long'>,
  completedBy: $ElementType<Scalars, 'String'>,
  completedByName: $ElementType<Scalars, 'String'>,
  publishedElationNoteId: $ElementType<Scalars, 'String'>,
};

export type ConversationChange = {
  __typename?: 'ConversationChange',
  change?: ?$ElementType<Scalars, 'String'>,
};

export const ConversationStateValues = Object.freeze({
  NoReply: 'NO_REPLY',
  Open: 'OPEN',
  Unread: 'UNREAD'
});


export type ConversationState = $Values<typeof ConversationStateValues>;

export const ConversationTypeValues = Object.freeze({
  Default: 'DEFAULT',
  Prepaid: 'PREPAID'
});


export type ConversationType = $Values<typeof ConversationTypeValues>;

export type DailySchedule = {
  __typename?: 'DailySchedule',
  dayOfWeek: DayOfWeek,
  hours: Array<$ElementType<Scalars, 'String'>>,
};

export const DayOfWeekValues = Object.freeze({
  Friday: 'FRIDAY',
  Monday: 'MONDAY',
  Saturday: 'SATURDAY',
  Thursday: 'THURSDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY'
});


export type DayOfWeek = $Values<typeof DayOfWeekValues>;

export const DirectionValues = Object.freeze({
  Backward: 'BACKWARD',
  Forward: 'FORWARD'
});


export type Direction = $Values<typeof DirectionValues>;


export type EffectiveAppointmentSlot = {
  ...EffectiveTimeSlot,
  ...{
    __typename?: 'EffectiveAppointmentSlot',
    appointmentType: AppointmentType,
    endTime: $ElementType<Scalars, 'Long'>,
    locationId?: ?$ElementType<Scalars, 'String'>,
    startTime: $ElementType<Scalars, 'Long'>,
    supportedMediums: Array<AppointmentMedium>,
  }
};

export type EffectiveTimeSlot = {
  endTime: $ElementType<Scalars, 'Long'>,
  startTime: $ElementType<Scalars, 'Long'>,
};

export type EffectiveUnavailableTimeSlot = {
  ...EffectiveTimeSlot,
  ...{
    __typename?: 'EffectiveUnavailableTimeSlot',
    endTime: $ElementType<Scalars, 'Long'>,
    name: $ElementType<Scalars, 'String'>,
    startTime: $ElementType<Scalars, 'Long'>,
  }
};

export type EligiblePractitioners = {
  __typename?: 'EligiblePractitioners',
  leadPractitioners: Array<Practitioner>,
  supportingPractitioners: Array<Practitioner>,
};

export type Event = MedicalAppointment | NonPatientEvent;

export type Exercise = {
  __typename?: 'Exercise',
  duration: $ElementType<Scalars, 'String'>,
  imageUrl?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  reps?: ?$ElementType<Scalars, 'Int'>,
  sets: $ElementType<Scalars, 'Int'>,
};

export type ExerciseEquipment = {
  __typename?: 'ExerciseEquipment',
  iconUrl?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
};

export type ExternalProvider = {
  __typename?: 'ExternalProvider',
  addressLines: Array<$ElementType<Scalars, 'String'>>,
  displayName: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  providerType?: ?$ElementType<Scalars, 'String'>,
  title?: ?$ElementType<Scalars, 'String'>,
};

export type ExternalProviderHostInfo = {
  __typename?: 'ExternalProviderHostInfo',
  location: ExternalProviderLocation,
  provider: ExternalProvider,
  timezone?: ?$ElementType<Scalars, 'String'>,
};

export type ExternalProviderLocation = {
  __typename?: 'ExternalProviderLocation',
  address?: ?Address,
  description: Array<Paragraph>,
  email?: ?$ElementType<Scalars, 'String'>,
  locationInfoUrl?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  phone?: ?$ElementType<Scalars, 'String'>,
};

export type FormattedAssessmentException = {
  __typename?: 'FormattedAssessmentException',
  bodyPart?: ?BodyPart,
  name: $ElementType<Scalars, 'String'>,
};

export type FormattedAssessmentPathway = {
  __typename?: 'FormattedAssessmentPathway',
  bodyPart: BodyPart,
  name: $ElementType<Scalars, 'String'>,
};

export type FormattedScoringOutput = {
  __typename?: 'FormattedScoringOutput',
  exceptions: Array<FormattedAssessmentException>,
  pathways: Array<FormattedAssessmentPathway>,
};

export type FormattedValue = {
  __typename?: 'FormattedValue',
  label: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
};

export type HoursRange = {
  __typename?: 'HoursRange',
  end: $ElementType<Scalars, 'Int'>,
  start: $ElementType<Scalars, 'Int'>,
};

export type Identity = {
  __typename?: 'Identity',
  avatarUrl?: ?$ElementType<Scalars, 'String'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
};

export type IdentityVerificationItems = {
  dob: $ElementType<Scalars, 'String'>,
  lastName: $ElementType<Scalars, 'String'>,
};

export type KemtaiPatientInfo = {
  __typename?: 'KemtaiPatientInfo',
  kemtaiPatientId: $ElementType<Scalars, 'String'>,
  kemtaiPatientProfileUrl: $ElementType<Scalars, 'String'>,
};

export type LogRecord = {
  attributes: Array<LogRecordAttribute>,
  event: $ElementType<Scalars, 'String'>,
  eventAttributes?: ?Array<LogRecordAttribute>,
  page: $ElementType<Scalars, 'String'>,
  session: $ElementType<Scalars, 'String'>,
};

export type LogRecordAttribute = {
  key: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
};


export type MedicalAppointment = {
  __typename?: 'MedicalAppointment',
  appointmentMedium: AppointmentMedium,
  appointmentType: AppointmentType,
  cancelled: $ElementType<Scalars, 'Boolean'>,
  clinicId?: ?$ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  endTime: $ElementType<Scalars, 'Long'>,
  externalNotes?: ?$ElementType<Scalars, 'String'>,
  hostInfo: AppointmentHostInfo,
  id: $ElementType<Scalars, 'String'>,
  patientId: $ElementType<Scalars, 'String'>,
  startTime: $ElementType<Scalars, 'Long'>,
};

export type MedicalAppointmentSlot = {
  __typename?: 'MedicalAppointmentSlot',
  appointmentType: AppointmentType,
  endTime: $ElementType<Scalars, 'Long'>,
  hostInfo: StaffMemberHostInfo,
  startTime: $ElementType<Scalars, 'Long'>,
};

export type MedicalAppointmentToken = {
  __typename?: 'MedicalAppointmentToken',
  appointmentType: AppointmentType,
  available: $ElementType<Scalars, 'Int'>,
  createdAt: $ElementType<Scalars, 'Long'>,
  durationsInMinutes: Array<$ElementType<Scalars, 'Int'>>,
  id: $ElementType<Scalars, 'String'>,
  patientId: $ElementType<Scalars, 'String'>,
  staffIds: Array<$ElementType<Scalars, 'String'>>,
  updatedAt?: ?$ElementType<Scalars, 'Long'>,
};

export type MemberDisplayInfo = {
  __typename?: 'MemberDisplayInfo',
  displayName: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  patientId: $ElementType<Scalars, 'String'>,
};

export type MinimalPatientInfo = {
  __typename?: 'MinimalPatientInfo',
  id: $ElementType<Scalars, 'String'>,
};

export type MotionUser = {
  __typename?: 'MotionUser',
  activeOnZoom: $ElementType<Scalars, 'Boolean'>,
  avatarUrl?: ?$ElementType<Scalars, 'String'>,
  credentialsAbbreviation?: ?$ElementType<Scalars, 'String'>,
  displayName: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  profileImageUrl?: ?$ElementType<Scalars, 'String'>,
  title?: ?$ElementType<Scalars, 'String'>,
};

export type Mutation = {
  __typename?: 'Mutation',
  addExistingAssessment: VisitNote,
  bookNewAppointment: MedicalAppointment,
  cancelAppointmentAdmin?: ?$ElementType<Scalars, 'Boolean'>,
  claimConversation: $ElementType<Scalars, 'Boolean'>,
  closeConversation: $ElementType<Scalars, 'Boolean'>,
  copyExistingSchedule: Schedule,
  createAppointment?: ?MedicalAppointment,
  createAppointmentAdmin?: ?$ElementType<Scalars, 'Boolean'>,
  createAssessmentForPatient: Assessment,
  createAssessmentForVisitNote: VisitNote,
  createExternalAppointmentAdmin?: ?$ElementType<Scalars, 'Boolean'>,
  createInvite: $ElementType<Scalars, 'String'>,
  createKemtaiPatient: KemtaiPatientInfo,
  createKemtaiPatientLoginUrl: $ElementType<Scalars, 'String'>,
  createMedicalAppointmentToken?: ?MedicalAppointmentToken,
  createNewEmptySchedule: Schedule,
  createUrlForWorkout: $ElementType<Scalars, 'String'>,
  createVisitNoteV2: VisitNote,
  deletePendingAssessment: $ElementType<Scalars, 'Boolean'>,
  deletePendingVisitNote: $ElementType<Scalars, 'Boolean'>,
  deleteSchedule?: ?$ElementType<Scalars, 'Boolean'>,
  detachAssessment: VisitNote,
  discardScheduleDraft: $ElementType<Scalars, 'Boolean'>,
  dropCaches: $ElementType<Scalars, 'Boolean'>,
  finalize: VisitNote,
  finalizeOrders: VisitNote,
  joinConversation: $ElementType<Scalars, 'Boolean'>,
  publishScheduleDraft: Schedule,
  recordAnalytics?: ?$ElementType<Scalars, 'Boolean'>,
  rescheduleAppointment?: ?MedicalAppointment,
  rescheduleAppointmentAdmin?: ?$ElementType<Scalars, 'Boolean'>,
  rescheduleExistingAppointment: MedicalAppointment,
  sendBirdAuthForCurrentUser: SendBirdAuthInfo,
  sendBirdInitForCurrentMember: SendBirdMemberInfo,
  sendScheduleLinkToMember?: ?$ElementType<Scalars, 'Boolean'>,
  sendTestPushNotification: $ElementType<Scalars, 'Boolean'>,
  storeToken: $ElementType<Scalars, 'Boolean'>,
  submitAssessmentPage?: ?AssessmentSubmissionResult,
  track: $ElementType<Scalars, 'Boolean'>,
  tryAddStaffAsAlternativeHost?: ?$ElementType<Scalars, 'Boolean'>,
  updateCommunicationPreferences: $ElementType<Scalars, 'Boolean'>,
  updatePatientTimezone: Patient,
  updateScheduleDraft: Schedule,
  updateStatusToReadyForElation: VisitNote,
  updateVisitNote: VisitNote,
  verifyIdentity?: ?MinimalPatientInfo,
};


export type MutationAddExistingAssessmentArgs = {
  assessmentId: $ElementType<Scalars, 'String'>,
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type MutationBookNewAppointmentArgs = {
  appointmentMedium: AppointmentMedium,
  appointmentType: AppointmentType,
  ignoreDoubleBooking: $ElementType<Scalars, 'Boolean'>,
  ignoreLocationConflict: $ElementType<Scalars, 'Boolean'>,
  leadPractitionerId: $ElementType<Scalars, 'String'>,
  locationId?: ?$ElementType<Scalars, 'String'>,
  notifyPatient: $ElementType<Scalars, 'Boolean'>,
  patientId: $ElementType<Scalars, 'String'>,
  patientInstructions?: ?$ElementType<Scalars, 'String'>,
  remindPatient1h: $ElementType<Scalars, 'Boolean'>,
  remindPatient24h: $ElementType<Scalars, 'Boolean'>,
  startTime: $ElementType<Scalars, 'Long'>,
  supportingPractitionerId?: ?$ElementType<Scalars, 'String'>,
};


export type MutationCancelAppointmentAdminArgs = {
  appointmentId: $ElementType<Scalars, 'String'>,
  notifyPatient: $ElementType<Scalars, 'Boolean'>,
};


export type MutationClaimConversationArgs = {
  memberId: $ElementType<Scalars, 'String'>,
};


export type MutationCloseConversationArgs = {
  memberId: $ElementType<Scalars, 'String'>,
};


export type MutationCopyExistingScheduleArgs = {
  sourceScheduleId: $ElementType<Scalars, 'String'>,
  takesEffectDate: $ElementType<Scalars, 'String'>,
  targetStaffId: $ElementType<Scalars, 'String'>,
  timezone: $ElementType<Scalars, 'String'>,
};


export type MutationCreateAppointmentArgs = {
  durationInMinutes: $ElementType<Scalars, 'Int'>,
  staffId: $ElementType<Scalars, 'String'>,
  startTime: $ElementType<Scalars, 'Long'>,
  tokenId: $ElementType<Scalars, 'String'>,
};


export type MutationCreateAppointmentAdminArgs = {
  appointmentDescription: $ElementType<Scalars, 'String'>,
  appointmentMedium: AppointmentMedium,
  appointmentType: AppointmentType,
  ignoreBusy: $ElementType<Scalars, 'Boolean'>,
  leadPractitionerId: $ElementType<Scalars, 'String'>,
  locationId?: ?$ElementType<Scalars, 'String'>,
  notifyPatient: $ElementType<Scalars, 'Boolean'>,
  patientId: $ElementType<Scalars, 'String'>,
  patientInstructions?: ?$ElementType<Scalars, 'String'>,
  remindPatient1h: $ElementType<Scalars, 'Boolean'>,
  remindPatient24h: $ElementType<Scalars, 'Boolean'>,
  startTime: $ElementType<Scalars, 'Long'>,
  supportingPractitionerId?: ?$ElementType<Scalars, 'String'>,
};


export type MutationCreateAssessmentForPatientArgs = {
  assessmentType: $ElementType<Scalars, 'String'>,
  patientFacing: $ElementType<Scalars, 'Boolean'>,
  patientId: $ElementType<Scalars, 'String'>,
};


export type MutationCreateAssessmentForVisitNoteArgs = {
  assessmentType: $ElementType<Scalars, 'String'>,
  patientFacing: $ElementType<Scalars, 'Boolean'>,
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type MutationCreateExternalAppointmentAdminArgs = {
  appointmentDescription: $ElementType<Scalars, 'String'>,
  durationInMinutes: $ElementType<Scalars, 'Int'>,
  externalProviderCrmId: $ElementType<Scalars, 'String'>,
  patientId: $ElementType<Scalars, 'String'>,
  patientInstructions?: ?$ElementType<Scalars, 'String'>,
  startTime: $ElementType<Scalars, 'Long'>,
};


export type MutationCreateInviteArgs = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type MutationCreateKemtaiPatientArgs = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type MutationCreateKemtaiPatientLoginUrlArgs = {
  kemtaiPatientId: $ElementType<Scalars, 'String'>,
};


export type MutationCreateMedicalAppointmentTokenArgs = {
  appointmentType: AppointmentType,
  available: $ElementType<Scalars, 'Int'>,
  durationsInMinutes: Array<$ElementType<Scalars, 'Int'>>,
  patientId: $ElementType<Scalars, 'String'>,
  staffIds: Array<$ElementType<Scalars, 'String'>>,
};


export type MutationCreateNewEmptyScheduleArgs = {
  takesEffectDate: $ElementType<Scalars, 'String'>,
  targetStaffId: $ElementType<Scalars, 'String'>,
  timezone: $ElementType<Scalars, 'String'>,
};


export type MutationCreateUrlForWorkoutArgs = {
  workoutId: $ElementType<Scalars, 'String'>,
};


export type MutationCreateVisitNoteV2Args = {
  appointmentId: $ElementType<Scalars, 'String'>,
  patientId: $ElementType<Scalars, 'String'>,
};


export type MutationDeletePendingAssessmentArgs = {
  assessmentId: $ElementType<Scalars, 'String'>,
};


export type MutationDeletePendingVisitNoteArgs = {
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type MutationDeleteScheduleArgs = {
  ignoreConflictsOn: Array<$ElementType<Scalars, 'String'>>,
  scheduleId: $ElementType<Scalars, 'String'>,
};


export type MutationDetachAssessmentArgs = {
  alsoDelete: $ElementType<Scalars, 'Boolean'>,
  assessmentId: $ElementType<Scalars, 'String'>,
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type MutationDiscardScheduleDraftArgs = {
  scheduleId: $ElementType<Scalars, 'String'>,
};


export type MutationFinalizeArgs = {
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type MutationFinalizeOrdersArgs = {
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type MutationJoinConversationArgs = {
  channelUrl: $ElementType<Scalars, 'String'>,
};


export type MutationPublishScheduleDraftArgs = {
  ignoreConflictsOn: Array<$ElementType<Scalars, 'String'>>,
  scheduleId: $ElementType<Scalars, 'String'>,
};


export type MutationRecordAnalyticsArgs = {
  day: $ElementType<Scalars, 'String'>,
};


export type MutationRescheduleAppointmentArgs = {
  appointmentId: $ElementType<Scalars, 'String'>,
  startTime: $ElementType<Scalars, 'Long'>,
};


export type MutationRescheduleAppointmentAdminArgs = {
  appointmentId: $ElementType<Scalars, 'String'>,
  durationInMinutes: $ElementType<Scalars, 'Int'>,
  ignoreBusy: $ElementType<Scalars, 'Boolean'>,
  notifyPatient: $ElementType<Scalars, 'Boolean'>,
  startTime: $ElementType<Scalars, 'Long'>,
};


export type MutationRescheduleExistingAppointmentArgs = {
  appointmentId: $ElementType<Scalars, 'String'>,
  ignoreDoubleBooking: $ElementType<Scalars, 'Boolean'>,
  ignoreLocationConflict: $ElementType<Scalars, 'Boolean'>,
  notifyPatient: $ElementType<Scalars, 'Boolean'>,
  startTime: $ElementType<Scalars, 'Long'>,
};


export type MutationSendBirdInitForCurrentMemberArgs = {
  conversationType?: ?ConversationType,
};


export type MutationSendScheduleLinkToMemberArgs = {
  memberId: $ElementType<Scalars, 'String'>,
  message: $ElementType<Scalars, 'String'>,
  token: $ElementType<Scalars, 'String'>,
};


export type MutationStoreTokenArgs = {
  tokenInput: TokenInput,
};


export type MutationSubmitAssessmentPageArgs = {
  assessmentId: $ElementType<Scalars, 'String'>,
  authToken?: ?$ElementType<Scalars, 'String'>,
  input?: ?AssessmentPageInput,
};


export type MutationTrackArgs = {
  logRecord: LogRecord,
};


export type MutationTryAddStaffAsAlternativeHostArgs = {
  appointmentId: $ElementType<Scalars, 'String'>,
  staffId: $ElementType<Scalars, 'String'>,
};


export type MutationUpdateCommunicationPreferencesArgs = {
  consentsToEmail: $ElementType<Scalars, 'Boolean'>,
  consentsToSMS: $ElementType<Scalars, 'Boolean'>,
  patientId: $ElementType<Scalars, 'String'>,
};


export type MutationUpdatePatientTimezoneArgs = {
  patientId: $ElementType<Scalars, 'String'>,
  timezone: $ElementType<Scalars, 'String'>,
};


export type MutationUpdateScheduleDraftArgs = {
  schedule?: ?ScheduleUpdate,
};


export type MutationUpdateStatusToReadyForElationArgs = {
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type MutationUpdateVisitNoteArgs = {
  update: VisitNoteUpdate,
};


export type MutationVerifyIdentityArgs = {
  identityVerificationItems: IdentityVerificationItems,
};

export type MyPatientProfileInfo = {
  __typename?: 'MyPatientProfileInfo',
  dob: $ElementType<Scalars, 'String'>,
  firstName: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  lastName: $ElementType<Scalars, 'String'>,
  phone?: ?$ElementType<Scalars, 'String'>,
  zip?: ?$ElementType<Scalars, 'String'>,
};

export type NonPatientEvent = {
  __typename?: 'NonPatientEvent',
  endTime: $ElementType<Scalars, 'Long'>,
  id: $ElementType<Scalars, 'String'>,
  staffId: $ElementType<Scalars, 'String'>,
  startTime: $ElementType<Scalars, 'Long'>,
};

export type OfficeHours = {
  __typename?: 'OfficeHours',
  saturdayOpenHoursEastern?: ?HoursRange,
  weekdayOpenHoursEastern: HoursRange,
};

export const OrderCategoryValues = Object.freeze({
  Coaching: 'COACHING',
  DtPt: 'DT_PT',
  Followup: 'FOLLOWUP',
  Imaging: 'IMAGING',
  L3ConsultEscalation: 'L3_CONSULT_ESCALATION',
  Medication: 'MEDICATION'
});


export type OrderCategory = $Values<typeof OrderCategoryValues>;

export type OrderConfig = {
  __typename?: 'OrderConfig',
  category: OrderCategory,
  groupId?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  level: OrderLevel,
  name: $ElementType<Scalars, 'String'>,
};

export type OrderGroupConfig = {
  __typename?: 'OrderGroupConfig',
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
};

export type OrderInput = {
  bodyParts: Array<BodyPart>,
  orderId: $ElementType<Scalars, 'String'>,
};

export const OrderLevelValues = Object.freeze({
  Joint: 'JOINT',
  Visit: 'VISIT'
});


export type OrderLevel = $Values<typeof OrderLevelValues>;

export type OrderSuggestion = {
  __typename?: 'OrderSuggestion',
  bodyParts: Array<BodyPart>,
  orderId: $ElementType<Scalars, 'String'>,
  visibility: OrderSuggestionVisibility,
};

export const OrderSuggestionVisibilityValues = Object.freeze({
  AddOn: 'ADD_ON',
  Recommended: 'RECOMMENDED'
});


export type OrderSuggestionVisibility = $Values<typeof OrderSuggestionVisibilityValues>;

export type OrdersResponse = {
  __typename?: 'OrdersResponse',
  orderConfigurations: Array<OrderConfig>,
  orderGroupConfigurations: Array<OrderGroupConfig>,
};

export type Paragraph = {
  __typename?: 'Paragraph',
  content: $ElementType<Scalars, 'String'>,
  title?: ?$ElementType<Scalars, 'String'>,
};

export type ParsingWarning = {
  __typename?: 'ParsingWarning',
  message: $ElementType<Scalars, 'String'>,
  path: Array<$ElementType<Scalars, 'String'>>,
};

export type Patient = {
  __typename?: 'Patient',
  communicationPreferences: PatientCommunicationPreferences,
  elationPatientId?: ?$ElementType<Scalars, 'String'>,
  firstName: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  invitationId?: ?$ElementType<Scalars, 'String'>,
  lastName: $ElementType<Scalars, 'String'>,
  primaryCareTeamStaffId: $ElementType<Scalars, 'String'>,
  risaltoUserId?: ?$ElementType<Scalars, 'String'>,
  welkinPatientId?: ?$ElementType<Scalars, 'String'>,
  zip?: ?$ElementType<Scalars, 'String'>,
};

export type PatientAccessToken = {
  __typename?: 'PatientAccessToken',
  expiresAt?: ?$ElementType<Scalars, 'Long'>,
  token: $ElementType<Scalars, 'String'>,
};

export type PatientCommunicationPreferences = {
  __typename?: 'PatientCommunicationPreferences',
  consentsToEmails?: ?$ElementType<Scalars, 'Boolean'>,
  consentsToSMS?: ?$ElementType<Scalars, 'Boolean'>,
  email?: ?$ElementType<Scalars, 'String'>,
  phone?: ?$ElementType<Scalars, 'String'>,
  timezone?: ?$ElementType<Scalars, 'String'>,
};

export const PatientIdTypeValues = Object.freeze({
  Elation: 'ELATION',
  User: 'USER',
  Welkin: 'WELKIN'
});


export type PatientIdType = $Values<typeof PatientIdTypeValues>;

export type PatientInvite = {
  __typename?: 'PatientInvite',
  id: $ElementType<Scalars, 'String'>,
  status?: ?PatientInviteStatus,
};

export const PatientInviteStatusValues = Object.freeze({
  Completed: 'COMPLETED',
  Pending: 'PENDING'
});


export type PatientInviteStatus = $Values<typeof PatientInviteStatusValues>;

export type PatientRecord = {
  __typename?: 'PatientRecord',
  content: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  patientId: $ElementType<Scalars, 'String'>,
  timestamp: $ElementType<Scalars, 'Long'>,
  type: $ElementType<Scalars, 'String'>,
};


export type PlannedSchedule = {
  __typename?: 'PlannedSchedule',
  alternateWeeklySchedule?: ?Array<DailySchedule>,
  inEffectAfter?: ?$ElementType<Scalars, 'Long'>,
  weeklySchedule: Array<DailySchedule>,
};

export type Practitioner = {
  __typename?: 'Practitioner',
  avatarUrl: $ElementType<Scalars, 'String'>,
  credentialsAbbreviation?: ?$ElementType<Scalars, 'String'>,
  displayName: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  licensedStates: Array<$ElementType<Scalars, 'String'>>,
  practitionerType: PractitionerType,
};

export const PractitionerTypeValues = Object.freeze({
  CareNavigator: 'CARE_NAVIGATOR',
  HealthCoach: 'HEALTH_COACH',
  L3: 'L3',
  Md: 'MD',
  Pa: 'PA',
  PainCounselor: 'PAIN_COUNSELOR',
  Pt: 'PT'
});


export type PractitionerType = $Values<typeof PractitionerTypeValues>;

export type ProviderAvailability = {
  __typename?: 'ProviderAvailability',
  availableSlots: Array<AvailableSlot>,
  id: $ElementType<Scalars, 'String'>,
  staffId: $ElementType<Scalars, 'String'>,
};

export type ProviderTeamAvailability = {
  __typename?: 'ProviderTeamAvailability',
  availableSlots: Array<AvailableSlot>,
  id: $ElementType<Scalars, 'String'>,
  leadStaffId: $ElementType<Scalars, 'String'>,
  supportingStaffId: $ElementType<Scalars, 'String'>,
};

export type Query = {
  __typename?: 'Query',
  allSchedules: Array<Schedule>,
  allVisitNotes: Array<VisitNote>,
  appointmentByIdAdmin?: ?MedicalAppointment,
  appointmentTypeConfigurations: Array<AppointmentTypeConfiguration>,
  appointmentsForPatientAdmin: Array<MedicalAppointment>,
  assessment: Assessment,
  assessmentConfigurations: Array<AssessmentConfiguration>,
  assessments: Array<Assessment>,
  assessmentsForPatient: Array<Assessment>,
  assignedProvidersForPatient: Array<ExternalProvider>,
  availableAppointmentSlots: Array<MedicalAppointmentSlot>,
  availableAppointmentSlotsAdmin: Array<AppointmentSlot>,
  availableAppointmentSlotsForRescheduling: Array<MedicalAppointmentSlot>,
  clientUpgradeRecommendation: ClientUpgradeResponse,
  clinic?: ?Clinic,
  clinics: Array<Clinic>,
  conflictsOnDelete: Array<SchedulingConflict>,
  conflictsOnPublishDraft: Array<SchedulingConflict>,
  conversation?: ?ActiveConversation,
  conversations: Array<?ActiveConversation>,
  eligiblePractitioners: EligiblePractitioners,
  exercisesForDate: Array<Session>,
  firstAssessmentPage: AssessmentSubmissionResult,
  infoProperty?: ?$ElementType<Scalars, 'String'>,
  isOfficeHours: $ElementType<Scalars, 'Boolean'>,
  kemtaiPatientInfo?: ?KemtaiPatientInfo,
  masqueradeableMotionUsers: Array<MotionUser>,
  matchingProviders: Array<ProviderAvailability>,
  medicalAppointmentToken?: ?MedicalAppointmentToken,
  medicalAppointmentTokens: Array<MedicalAppointmentToken>,
  medicalAppointmentTokensForCurrentUser: Array<MedicalAppointmentToken>,
  myInfo: MyPatientProfileInfo,
  namespace: $ElementType<Scalars, 'String'>,
  officeHours: OfficeHours,
  orders: OrdersResponse,
  patient: Patient,
  patientIdByExternalId?: ?$ElementType<Scalars, 'String'>,
  patientInvite: PatientInvite,
  patientRecords: Array<PatientRecord>,
  previousAssessmentPage: AssessmentPage,
  providerAvailability: ProviderAvailability,
  providerEffectiveTimeSlots: Array<EffectiveTimeSlot>,
  providerTeamAvailability: ProviderTeamAvailability,
  providerWeeklyEvents: Array<Event>,
  report: Array<Array<$ElementType<Scalars, 'String'>>>,
  reportConfigurations: Array<ReportConfiguration>,
  schedule: Schedule,
  serviceStatusMessage?: ?$ElementType<Scalars, 'String'>,
  staffSchedules: Array<StaffSchedule>,
  staffSchedulesByAppointmentType: Array<StaffMemberSchedule>,
  version: $ElementType<Scalars, 'String'>,
  visitNoteById: VisitNote,
  zoomMeeting: ZoomMeeting,
};


export type QueryAllVisitNotesArgs = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type QueryAppointmentByIdAdminArgs = {
  appointmentId: $ElementType<Scalars, 'String'>,
};


export type QueryAppointmentTypeConfigurationsArgs = {
  beta?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryAppointmentsForPatientAdminArgs = {
  includePastAppointments: $ElementType<Scalars, 'Boolean'>,
  patientId: $ElementType<Scalars, 'String'>,
};


export type QueryAssessmentArgs = {
  assessmentId: $ElementType<Scalars, 'String'>,
};


export type QueryAssessmentsArgs = {
  assessmentIds: Array<$ElementType<Scalars, 'String'>>,
};


export type QueryAssessmentsForPatientArgs = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type QueryAssignedProvidersForPatientArgs = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type QueryAvailableAppointmentSlotsArgs = {
  durationInMinutes: $ElementType<Scalars, 'Int'>,
  searchFromTime: $ElementType<Scalars, 'Long'>,
  searchToTime: $ElementType<Scalars, 'Long'>,
  tokenId: $ElementType<Scalars, 'String'>,
};


export type QueryAvailableAppointmentSlotsAdminArgs = {
  appointmentMedium: AppointmentMedium,
  appointmentType: AppointmentType,
  filterByClinicIds: Array<$ElementType<Scalars, 'String'>>,
  filterByLeadPractitionerIds: Array<$ElementType<Scalars, 'String'>>,
  filterBySupportingPractitionerIds: Array<$ElementType<Scalars, 'String'>>,
  filterByTimeOfDay: Array<TimeOfDay>,
  searchFromDay: $ElementType<Scalars, 'String'>,
  supportingPractitionerType?: ?PractitionerType,
};


export type QueryAvailableAppointmentSlotsForReschedulingArgs = {
  appointmentId: $ElementType<Scalars, 'String'>,
  searchFromTime: $ElementType<Scalars, 'Long'>,
  searchToTime: $ElementType<Scalars, 'Long'>,
};


export type QueryClientUpgradeRecommendationArgs = {
  input: ClientUpgradeInput,
};


export type QueryClinicArgs = {
  clinicId: $ElementType<Scalars, 'String'>,
};


export type QueryConflictsOnDeleteArgs = {
  scheduleId: $ElementType<Scalars, 'String'>,
};


export type QueryConflictsOnPublishDraftArgs = {
  scheduleId: $ElementType<Scalars, 'String'>,
};


export type QueryConversationArgs = {
  memberId: $ElementType<Scalars, 'String'>,
};


export type QueryEligiblePractitionersArgs = {
  appointmentMedium: AppointmentMedium,
  appointmentType: AppointmentType,
  filterByClinicIds: Array<$ElementType<Scalars, 'String'>>,
  supportingPractitionerType?: ?PractitionerType,
};


export type QueryExercisesForDateArgs = {
  date: $ElementType<Scalars, 'String'>,
};


export type QueryFirstAssessmentPageArgs = {
  assessmentId: $ElementType<Scalars, 'String'>,
  authToken?: ?$ElementType<Scalars, 'String'>,
};


export type QueryInfoPropertyArgs = {
  propertyPath: $ElementType<Scalars, 'String'>,
};


export type QueryKemtaiPatientInfoArgs = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type QueryMatchingProvidersArgs = {
  appointmentType: AppointmentType,
  locationIds: Array<$ElementType<Scalars, 'String'>>,
  medium: AppointmentMedium,
  patientId: $ElementType<Scalars, 'String'>,
  week: TargetWeek,
};


export type QueryMedicalAppointmentTokenArgs = {
  id: $ElementType<Scalars, 'String'>,
};


export type QueryMedicalAppointmentTokensArgs = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type QueryPatientArgs = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type QueryPatientIdByExternalIdArgs = {
  externalId: $ElementType<Scalars, 'String'>,
  patientIdType: PatientIdType,
};


export type QueryPatientInviteArgs = {
  id: $ElementType<Scalars, 'String'>,
};


export type QueryPatientRecordsArgs = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type QueryPreviousAssessmentPageArgs = {
  assessmentId: $ElementType<Scalars, 'String'>,
  authToken?: ?$ElementType<Scalars, 'String'>,
  cursor: $ElementType<Scalars, 'String'>,
};


export type QueryProviderAvailabilityArgs = {
  appointmentType: AppointmentType,
  locationIds: Array<$ElementType<Scalars, 'String'>>,
  medium: AppointmentMedium,
  patientId: $ElementType<Scalars, 'String'>,
  staffId: $ElementType<Scalars, 'String'>,
  week: TargetWeek,
};


export type QueryProviderEffectiveTimeSlotsArgs = {
  staffId: $ElementType<Scalars, 'String'>,
  week: TargetWeek,
};


export type QueryProviderTeamAvailabilityArgs = {
  appointmentType: AppointmentType,
  leadStaffId: $ElementType<Scalars, 'String'>,
  locationIds?: ?Array<$ElementType<Scalars, 'String'>>,
  medium: AppointmentMedium,
  patientId: $ElementType<Scalars, 'String'>,
  supportingStaffId: $ElementType<Scalars, 'String'>,
  week: TargetWeek,
};


export type QueryProviderWeeklyEventsArgs = {
  staffId: $ElementType<Scalars, 'String'>,
  week: TargetWeek,
};


export type QueryReportArgs = {
  id: $ElementType<Scalars, 'String'>,
};


export type QueryScheduleArgs = {
  scheduleId: $ElementType<Scalars, 'String'>,
};


export type QueryStaffSchedulesByAppointmentTypeArgs = {
  appointmentType?: ?AppointmentType,
};


export type QueryVisitNoteByIdArgs = {
  id: $ElementType<Scalars, 'String'>,
};


export type QueryZoomMeetingArgs = {
  appointmentId: $ElementType<Scalars, 'String'>,
};

export type QuestionDefault = {
  __typename?: 'QuestionDefault',
  source?: ?$ElementType<Scalars, 'String'>,
  timestamp?: ?$ElementType<Scalars, 'Long'>,
  value: Array<$ElementType<Scalars, 'String'>>,
};

export type ReportConfiguration = {
  __typename?: 'ReportConfiguration',
  description: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
};

export type Schedule = {
  __typename?: 'Schedule',
  alternateWeeklySchedule?: ?Array<ScheduleDay>,
  id: $ElementType<Scalars, 'String'>,
  isDraft: $ElementType<Scalars, 'Boolean'>,
  staff: MotionUser,
  takesEffectDate: $ElementType<Scalars, 'String'>,
  timezone: $ElementType<Scalars, 'String'>,
  updatedAt: $ElementType<Scalars, 'Long'>,
  updatedByName?: ?$ElementType<Scalars, 'String'>,
  weeklySchedule: Array<ScheduleDay>,
};

export type ScheduleDay = {
  __typename?: 'ScheduleDay',
  dayOfWeek: $ElementType<Scalars, 'Int'>,
  hours: Array<TimeSlot>,
};

export type ScheduleDayUpdate = {
  dayOfWeek: $ElementType<Scalars, 'Int'>,
  hours: Array<TimeSlotUpdate>,
};

export type ScheduleUpdate = {
  alternateWeeklySchedule?: ?Array<ScheduleDayUpdate>,
  id: $ElementType<Scalars, 'String'>,
  weeklySchedule: Array<ScheduleDayUpdate>,
};

export type SchedulingConflict = {
  __typename?: 'SchedulingConflict',
  appointmentId: $ElementType<Scalars, 'String'>,
  patientId: $ElementType<Scalars, 'String'>,
  reasons: Array<$ElementType<Scalars, 'String'>>,
};

export type SendBirdAuthInfo = {
  __typename?: 'SendBirdAuthInfo',
  accessToken: $ElementType<Scalars, 'String'>,
  appId: $ElementType<Scalars, 'String'>,
  userId: $ElementType<Scalars, 'String'>,
};

export type SendBirdMemberInfo = {
  __typename?: 'SendBirdMemberInfo',
  authInfo: SendBirdAuthInfo,
  conversationUrl: $ElementType<Scalars, 'String'>,
};

export type Session = {
  __typename?: 'Session',
  equipment: Array<ExerciseEquipment>,
  exercises: Array<?Exercise>,
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  workoutId: $ElementType<Scalars, 'String'>,
};

export type StaffMemberHostInfo = {
  __typename?: 'StaffMemberHostInfo',
  staff: Practitioner,
  supportingStaff?: ?Practitioner,
};

export type StaffMemberSchedule = {
  __typename?: 'StaffMemberSchedule',
  appointmentTypes: Array<$ElementType<Scalars, 'String'>>,
  avatarUrl: $ElementType<Scalars, 'String'>,
  displayName: $ElementType<Scalars, 'String'>,
  licensedStates: Array<$ElementType<Scalars, 'String'>>,
  staffId: $ElementType<Scalars, 'String'>,
  timezone: $ElementType<Scalars, 'String'>,
  workingHours: Array<$ElementType<Scalars, 'String'>>,
};

export type StaffSchedule = {
  __typename?: 'StaffSchedule',
  id: $ElementType<Scalars, 'String'>,
  plannedSchedules: Array<PlannedSchedule>,
  staffDisplayName: $ElementType<Scalars, 'String'>,
  staffId: $ElementType<Scalars, 'String'>,
};

export type Subscription = {
  __typename?: 'Subscription',
  conversationChanges?: ?ConversationChange,
};

export type TargetWeek = {
  week: $ElementType<Scalars, 'Int'>,
  year: $ElementType<Scalars, 'Int'>,
};

export const TextStyleValues = Object.freeze({
  Body: 'BODY',
  H1: 'H1',
  H2: 'H2'
});


export type TextStyle = $Values<typeof TextStyleValues>;

export const TimeOfDayValues = Object.freeze({
  Afternoon: 'AFTERNOON',
  Morning: 'MORNING'
});


export type TimeOfDay = $Values<typeof TimeOfDayValues>;

export type TimeSlot = {
  durationInMinutes: $ElementType<Scalars, 'Int'>,
  minutesSinceMidnight: $ElementType<Scalars, 'Int'>,
};

export type TimeSlotUpdate = {
  appointmentSlotUpdate?: ?AppointmentSlotUpdate,
  durationInMinutes: $ElementType<Scalars, 'Int'>,
  minutesSinceMidnight: $ElementType<Scalars, 'Int'>,
  unavailableTimeSlotUpdate?: ?UnavailableTimeSlotUpdate,
};

export type TokenInput = {
  deviceId: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
  token: $ElementType<Scalars, 'String'>,
};

export type UnavailableTimeSlot = {
  ...TimeSlot,
  ...{
    __typename?: 'UnavailableTimeSlot',
    durationInMinutes: $ElementType<Scalars, 'Int'>,
    minutesSinceMidnight: $ElementType<Scalars, 'Int'>,
    name: $ElementType<Scalars, 'String'>,
  }
};

export type UnavailableTimeSlotUpdate = {
  name: $ElementType<Scalars, 'String'>,
};

export const UserTypeValues = Object.freeze({
  Consumer: 'CONSUMER',
  Employer: 'EMPLOYER'
});


export type UserType = $Values<typeof UserTypeValues>;

export type VisitNote = {
  __typename?: 'VisitNote',
  appointment: MedicalAppointment,
  assessmentPlan: $ElementType<Scalars, 'String'>,
  assessments: Array<Assessment>,
  completionMetadata?: ?CompletionMetadata,
  createdAt: $ElementType<Scalars, 'Long'>,
  createdBy: $ElementType<Scalars, 'String'>,
  createdByName: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  objective: $ElementType<Scalars, 'String'>,
  orderSuggestions: Array<OrderSuggestion>,
  orders: Array<VisitNoteOrder>,
  patientId: $ElementType<Scalars, 'String'>,
  status: VisitNoteStatus,
  subjective: $ElementType<Scalars, 'String'>,
  updatedAt: $ElementType<Scalars, 'Long'>,
  updatedBy: $ElementType<Scalars, 'String'>,
  updatedByName: $ElementType<Scalars, 'String'>,
};

export type VisitNoteOrder = {
  __typename?: 'VisitNoteOrder',
  bodyParts: Array<BodyPart>,
  orderId: $ElementType<Scalars, 'String'>,
};

export const VisitNoteStatusValues = Object.freeze({
  OrdersFinalized: 'ORDERS_FINALIZED',
  ReadyForElation: 'READY_FOR_ELATION',
  Started: 'STARTED',
  Submitted: 'SUBMITTED'
});


export type VisitNoteStatus = $Values<typeof VisitNoteStatusValues>;

export type VisitNoteUpdate = {
  assessmentPlan?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  objective?: ?$ElementType<Scalars, 'String'>,
  orders?: ?Array<OrderInput>,
  subjective?: ?$ElementType<Scalars, 'String'>,
};

export type ZonedDateTime = {
  __typename?: 'ZonedDateTime',
  epochSeconds: $ElementType<Scalars, 'Long'>,
  iso8601: $ElementType<Scalars, 'String'>,
  zoneAbbreviation: $ElementType<Scalars, 'String'>,
  zoneOffsetSeconds: $ElementType<Scalars, 'Int'>,
};

export type ZoomMeeting = {
  __typename?: 'ZoomMeeting',
  hostEmail: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  joinUrl: $ElementType<Scalars, 'String'>,
  settings?: ?ZoomMeetingSettings,
};

export type ZoomMeetingSettings = {
  __typename?: 'ZoomMeetingSettings',
  alternativeHosts: Array<$ElementType<Scalars, 'String'>>,
};

type $Pick<Origin: Object, Keys: Object> = $ObjMapi<Keys, <Key>(k: Key) => $ElementType<Origin, Key>>;

export type ConversationsQueryVariables = {};


export type ConversationsQuery = ({
    ...{ __typename?: 'Query' },
  ...{ conversations: Array<?({
      ...{ __typename?: 'ActiveConversation' },
    ...$Pick<ActiveConversation, { id: *, state: *, ownerId?: *, ownerName?: *, lastEventTime: *, lastMemberReadTime?: *, lastMemberMessageTime?: *, lastMotionMessageTime?: * }>,
    ...{ member: ({
        ...{ __typename?: 'MemberDisplayInfo' },
      ...$Pick<MemberDisplayInfo, { id: *, patientId: *, displayName: * }>
    }) }
  })> }
});

export type ScheduleFieldsForListFragment = ({
    ...{ __typename?: 'Schedule' },
  ...$Pick<Schedule, { id: *, takesEffectDate: *, isDraft: *, timezone: * }>,
  ...{ staff: ({
      ...{ __typename?: 'MotionUser' },
    ...$Pick<MotionUser, { id: * }>
  }) }
});

export type GetAllSchedulesAndStaffQueryVariables = {};


export type GetAllSchedulesAndStaffQuery = ({
    ...{ __typename?: 'Query' },
  ...{ masqueradeableMotionUsers: Array<({
      ...{ __typename?: 'MotionUser' },
    ...$Pick<MotionUser, { id: *, displayName: *, credentialsAbbreviation?: *, avatarUrl?: *, activeOnZoom: * }>
  })>, allSchedules: Array<({
      ...{ __typename?: 'Schedule' },
    ...$Pick<Schedule, { id: *, takesEffectDate: *, isDraft: *, timezone: * }>,
    ...{ staff: ({
        ...{ __typename?: 'MotionUser' },
      ...$Pick<MotionUser, { id: * }>
    }) }
  })> }
});

export type CreateEmptyDraftMutationVariables = {
  targetStaffId: $ElementType<Scalars, 'String'>,
  takesEffectDate: $ElementType<Scalars, 'String'>,
  timezone: $ElementType<Scalars, 'String'>,
};


export type CreateEmptyDraftMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ createNewEmptySchedule: ({
      ...{ __typename?: 'Schedule' },
    ...$Pick<Schedule, { id: *, isDraft: *, takesEffectDate: *, timezone: *, updatedAt: *, updatedByName?: * }>,
    ...{ staff: ({
        ...{ __typename?: 'MotionUser' },
      ...$Pick<MotionUser, { id: *, displayName: *, activeOnZoom: * }>
    }), weeklySchedule: Array<({
        ...{ __typename?: 'ScheduleDay' },
      ...$Pick<ScheduleDay, { dayOfWeek: * }>,
      ...{ hours: Array<({
          ...{ __typename?: 'AppointmentTimeSlot' },
        ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
      }) | ({
          ...{ __typename?: 'UnavailableTimeSlot' },
        ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
      })> }
    })>, alternateWeeklySchedule?: ?Array<({
        ...{ __typename?: 'ScheduleDay' },
      ...$Pick<ScheduleDay, { dayOfWeek: * }>,
      ...{ hours: Array<({
          ...{ __typename?: 'AppointmentTimeSlot' },
        ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
      }) | ({
          ...{ __typename?: 'UnavailableTimeSlot' },
        ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
      })> }
    })> }
  }) }
});

export type CreateDraftFromScheduleMutationVariables = {
  targetStaffId: $ElementType<Scalars, 'String'>,
  sourceScheduleId: $ElementType<Scalars, 'String'>,
  takesEffectDate: $ElementType<Scalars, 'String'>,
  timezone: $ElementType<Scalars, 'String'>,
};


export type CreateDraftFromScheduleMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ copyExistingSchedule: ({
      ...{ __typename?: 'Schedule' },
    ...$Pick<Schedule, { id: *, isDraft: *, takesEffectDate: *, timezone: *, updatedAt: *, updatedByName?: * }>,
    ...{ staff: ({
        ...{ __typename?: 'MotionUser' },
      ...$Pick<MotionUser, { id: *, displayName: *, activeOnZoom: * }>
    }), weeklySchedule: Array<({
        ...{ __typename?: 'ScheduleDay' },
      ...$Pick<ScheduleDay, { dayOfWeek: * }>,
      ...{ hours: Array<({
          ...{ __typename?: 'AppointmentTimeSlot' },
        ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
      }) | ({
          ...{ __typename?: 'UnavailableTimeSlot' },
        ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
      })> }
    })>, alternateWeeklySchedule?: ?Array<({
        ...{ __typename?: 'ScheduleDay' },
      ...$Pick<ScheduleDay, { dayOfWeek: * }>,
      ...{ hours: Array<({
          ...{ __typename?: 'AppointmentTimeSlot' },
        ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
      }) | ({
          ...{ __typename?: 'UnavailableTimeSlot' },
        ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
      })> }
    })> }
  }) }
});

export type ConflictsOnDeleteQueryVariables = {
  scheduleId: $ElementType<Scalars, 'String'>,
};


export type ConflictsOnDeleteQuery = ({
    ...{ __typename?: 'Query' },
  ...{ conflictsOnDelete: Array<({
      ...{ __typename?: 'SchedulingConflict' },
    ...$Pick<SchedulingConflict, { appointmentId: *, patientId: *, reasons: * }>
  })> }
});

export type DeleteScheduleMutationVariables = {
  scheduleId: $ElementType<Scalars, 'String'>,
  ignoreConflictsOn: Array<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
};


export type DeleteScheduleMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { deleteSchedule?: * }>
});

export type DiscardScheduleDraftMutationVariables = {
  scheduleId: $ElementType<Scalars, 'String'>,
};


export type DiscardScheduleDraftMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { discardScheduleDraft: * }>
});

export type GetAllSchedulesQueryVariables = {};


export type GetAllSchedulesQuery = ({
    ...{ __typename?: 'Query' },
  ...{ allSchedules: Array<({
      ...{ __typename?: 'Schedule' },
    ...$Pick<Schedule, { id: *, takesEffectDate: *, isDraft: *, timezone: * }>,
    ...{ staff: ({
        ...{ __typename?: 'MotionUser' },
      ...$Pick<MotionUser, { id: * }>
    }) }
  })> }
});

export type UpdateScheduleDraftMutationVariables = {
  schedule: ScheduleUpdate,
};


export type UpdateScheduleDraftMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ updateScheduleDraft: ({
      ...{ __typename?: 'Schedule' },
    ...$Pick<Schedule, { id: *, isDraft: *, takesEffectDate: *, timezone: *, updatedAt: *, updatedByName?: * }>,
    ...{ staff: ({
        ...{ __typename?: 'MotionUser' },
      ...$Pick<MotionUser, { id: *, displayName: *, activeOnZoom: * }>
    }), weeklySchedule: Array<({
        ...{ __typename?: 'ScheduleDay' },
      ...$Pick<ScheduleDay, { dayOfWeek: * }>,
      ...{ hours: Array<({
          ...{ __typename?: 'AppointmentTimeSlot' },
        ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
      }) | ({
          ...{ __typename?: 'UnavailableTimeSlot' },
        ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
      })> }
    })>, alternateWeeklySchedule?: ?Array<({
        ...{ __typename?: 'ScheduleDay' },
      ...$Pick<ScheduleDay, { dayOfWeek: * }>,
      ...{ hours: Array<({
          ...{ __typename?: 'AppointmentTimeSlot' },
        ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
      }) | ({
          ...{ __typename?: 'UnavailableTimeSlot' },
        ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
      })> }
    })> }
  }) }
});

export type SchedulingConflictFieldsFragment = ({
    ...{ __typename?: 'SchedulingConflict' },
  ...$Pick<SchedulingConflict, { appointmentId: *, patientId: *, reasons: * }>
});

export type ConflictsOnPublishDraftQueryVariables = {
  scheduleId: $ElementType<Scalars, 'String'>,
};


export type ConflictsOnPublishDraftQuery = ({
    ...{ __typename?: 'Query' },
  ...{ conflictsOnPublishDraft: Array<({
      ...{ __typename?: 'SchedulingConflict' },
    ...$Pick<SchedulingConflict, { appointmentId: *, patientId: *, reasons: * }>
  })> }
});

export type PublishScheduleDraftMutationVariables = {
  scheduleId: $ElementType<Scalars, 'String'>,
  ignoreConflictsOn: Array<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
};


export type PublishScheduleDraftMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ publishScheduleDraft: ({
      ...{ __typename?: 'Schedule' },
    ...$Pick<Schedule, { id: *, isDraft: *, takesEffectDate: *, timezone: *, updatedAt: *, updatedByName?: * }>,
    ...{ staff: ({
        ...{ __typename?: 'MotionUser' },
      ...$Pick<MotionUser, { id: *, displayName: *, activeOnZoom: * }>
    }), weeklySchedule: Array<({
        ...{ __typename?: 'ScheduleDay' },
      ...$Pick<ScheduleDay, { dayOfWeek: * }>,
      ...{ hours: Array<({
          ...{ __typename?: 'AppointmentTimeSlot' },
        ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
      }) | ({
          ...{ __typename?: 'UnavailableTimeSlot' },
        ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
      })> }
    })>, alternateWeeklySchedule?: ?Array<({
        ...{ __typename?: 'ScheduleDay' },
      ...$Pick<ScheduleDay, { dayOfWeek: * }>,
      ...{ hours: Array<({
          ...{ __typename?: 'AppointmentTimeSlot' },
        ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
      }) | ({
          ...{ __typename?: 'UnavailableTimeSlot' },
        ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
      })> }
    })> }
  }) }
});

type TimeSlotFields_AppointmentTimeSlot_Fragment = ({
    ...{ __typename?: 'AppointmentTimeSlot' },
  ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
});

type TimeSlotFields_UnavailableTimeSlot_Fragment = ({
    ...{ __typename?: 'UnavailableTimeSlot' },
  ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
});

export type TimeSlotFieldsFragment = TimeSlotFields_AppointmentTimeSlot_Fragment | TimeSlotFields_UnavailableTimeSlot_Fragment;

export type ScheduleDayFieldsFragment = ({
    ...{ __typename?: 'ScheduleDay' },
  ...$Pick<ScheduleDay, { dayOfWeek: * }>,
  ...{ hours: Array<({
      ...{ __typename?: 'AppointmentTimeSlot' },
    ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
  }) | ({
      ...{ __typename?: 'UnavailableTimeSlot' },
    ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
  })> }
});

export type ScheduleFieldsFragment = ({
    ...{ __typename?: 'Schedule' },
  ...$Pick<Schedule, { id: *, isDraft: *, takesEffectDate: *, timezone: *, updatedAt: *, updatedByName?: * }>,
  ...{ staff: ({
      ...{ __typename?: 'MotionUser' },
    ...$Pick<MotionUser, { id: *, displayName: *, activeOnZoom: * }>
  }), weeklySchedule: Array<({
      ...{ __typename?: 'ScheduleDay' },
    ...$Pick<ScheduleDay, { dayOfWeek: * }>,
    ...{ hours: Array<({
        ...{ __typename?: 'AppointmentTimeSlot' },
      ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
    }) | ({
        ...{ __typename?: 'UnavailableTimeSlot' },
      ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
    })> }
  })>, alternateWeeklySchedule?: ?Array<({
      ...{ __typename?: 'ScheduleDay' },
    ...$Pick<ScheduleDay, { dayOfWeek: * }>,
    ...{ hours: Array<({
        ...{ __typename?: 'AppointmentTimeSlot' },
      ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
    }) | ({
        ...{ __typename?: 'UnavailableTimeSlot' },
      ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
    })> }
  })> }
});

export type GetScheduleQueryVariables = {
  scheduleId: $ElementType<Scalars, 'String'>,
};


export type GetScheduleQuery = ({
    ...{ __typename?: 'Query' },
  ...{ schedule: ({
      ...{ __typename?: 'Schedule' },
    ...$Pick<Schedule, { id: *, isDraft: *, takesEffectDate: *, timezone: *, updatedAt: *, updatedByName?: * }>,
    ...{ staff: ({
        ...{ __typename?: 'MotionUser' },
      ...$Pick<MotionUser, { id: *, displayName: *, activeOnZoom: * }>
    }), weeklySchedule: Array<({
        ...{ __typename?: 'ScheduleDay' },
      ...$Pick<ScheduleDay, { dayOfWeek: * }>,
      ...{ hours: Array<({
          ...{ __typename?: 'AppointmentTimeSlot' },
        ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
      }) | ({
          ...{ __typename?: 'UnavailableTimeSlot' },
        ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
      })> }
    })>, alternateWeeklySchedule?: ?Array<({
        ...{ __typename?: 'ScheduleDay' },
      ...$Pick<ScheduleDay, { dayOfWeek: * }>,
      ...{ hours: Array<({
          ...{ __typename?: 'AppointmentTimeSlot' },
        ...$Pick<AppointmentTimeSlot, { appointmentType: *, locationId?: *, supportedMediums: *, minutesSinceMidnight: *, durationInMinutes: * }>
      }) | ({
          ...{ __typename?: 'UnavailableTimeSlot' },
        ...$Pick<UnavailableTimeSlot, { name: *, minutesSinceMidnight: *, durationInMinutes: * }>
      })> }
    })> }
  }) }
});

export type AssessmentFieldsFragment = ({
    ...{ __typename?: 'Assessment' },
  ...$Pick<Assessment, { id: *, patientId: *, assessmentType: *, name: *, createdAt: *, createdBy: *, updatedAt?: *, updatedBy: *, status: * }>,
  ...{ patientAccessToken?: ?({
      ...{ __typename?: 'PatientAccessToken' },
    ...$Pick<PatientAccessToken, { token: *, expiresAt?: * }>
  }) }
});

export type AssessmentsForPatientQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type AssessmentsForPatientQuery = ({
    ...{ __typename?: 'Query' },
  ...{ assessmentsForPatient: Array<({
      ...{ __typename?: 'Assessment' },
    ...$Pick<Assessment, { id: *, patientId: *, assessmentType: *, name: *, createdAt: *, createdBy: *, updatedAt?: *, updatedBy: *, status: * }>,
    ...{ patientAccessToken?: ?({
        ...{ __typename?: 'PatientAccessToken' },
      ...$Pick<PatientAccessToken, { token: *, expiresAt?: * }>
    }) }
  })> }
});

export type CreateAssessmentForPatientMutationVariables = {
  patientId: $ElementType<Scalars, 'String'>,
  assessmentType: $ElementType<Scalars, 'String'>,
  patientFacing: $ElementType<Scalars, 'Boolean'>,
};


export type CreateAssessmentForPatientMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ createAssessmentForPatient: ({
      ...{ __typename?: 'Assessment' },
    ...$Pick<Assessment, { id: *, patientId: *, assessmentType: *, name: *, createdAt: *, createdBy: *, updatedAt?: *, updatedBy: *, status: * }>,
    ...{ patientAccessToken?: ?({
        ...{ __typename?: 'PatientAccessToken' },
      ...$Pick<PatientAccessToken, { token: *, expiresAt?: * }>
    }) }
  }) }
});

export type AssessmentQueryVariables = {
  assessmentId: $ElementType<Scalars, 'String'>,
};


export type AssessmentQuery = ({
    ...{ __typename?: 'Query' },
  ...{ assessment: ({
      ...{ __typename?: 'Assessment' },
    ...$Pick<Assessment, { id: *, patientId: *, assessmentType: *, name: *, createdAt: *, createdBy: *, updatedAt?: *, updatedBy: *, status: * }>,
    ...{ outcome?: ?({
        ...{ __typename?: 'AssessmentOutcome' },
      ...$Pick<AssessmentOutcome, { otherOutput: * }>,
      ...{ formattedAnswers: Array<({
          ...{ __typename?: 'FormattedValue' },
        ...$Pick<FormattedValue, { label: *, value: * }>
      })>, formattedMetrics: Array<({
          ...{ __typename?: 'FormattedValue' },
        ...$Pick<FormattedValue, { label: *, value: * }>
      })> }
    }), patientAccessToken?: ?({
        ...{ __typename?: 'PatientAccessToken' },
      ...$Pick<PatientAccessToken, { token: *, expiresAt?: * }>
    }) }
  }) }
});

export type DeletePendingAssessmentMutationVariables = {
  assessmentId: $ElementType<Scalars, 'String'>,
};


export type DeletePendingAssessmentMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { deletePendingAssessment: * }>
});

export type GetKemtaiPatientInfoQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type GetKemtaiPatientInfoQuery = ({
    ...{ __typename?: 'Query' },
  ...{ kemtaiPatientInfo?: ?({
      ...{ __typename?: 'KemtaiPatientInfo' },
    ...$Pick<KemtaiPatientInfo, { kemtaiPatientId: *, kemtaiPatientProfileUrl: * }>
  }) }
});

export type CreateKemtaiPatientLoginUrlMutationVariables = {
  kemtaiPatientId: $ElementType<Scalars, 'String'>,
};


export type CreateKemtaiPatientLoginUrlMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { createKemtaiPatientLoginUrl: * }>
});

export type CreateKemtaiPatientMutationVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type CreateKemtaiPatientMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ createKemtaiPatient: ({
      ...{ __typename?: 'KemtaiPatientInfo' },
    ...$Pick<KemtaiPatientInfo, { kemtaiPatientId: *, kemtaiPatientProfileUrl: * }>
  }) }
});

export type CreatePatientInviteMutationVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type CreatePatientInviteMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { createInvite: * }>
});

export type PatientRecordsQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type PatientRecordsQuery = ({
    ...{ __typename?: 'Query' },
  ...{ patientRecords: Array<({
      ...{ __typename?: 'PatientRecord' },
    ...$Pick<PatientRecord, { id: *, type: *, timestamp: *, patientId: *, content: * }>
  })> }
});

export type GetProviderAvailabilityQueryVariables = {
  staffId: $ElementType<Scalars, 'String'>,
  week: TargetWeek,
  appointmentType: AppointmentType,
  medium: AppointmentMedium,
  locationIds: Array<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
  patientId: $ElementType<Scalars, 'String'>,
};


export type GetProviderAvailabilityQuery = ({
    ...{ __typename?: 'Query' },
  ...{ providerAvailability: ({
      ...{ __typename?: 'ProviderAvailability' },
    ...$Pick<ProviderAvailability, { id: *, staffId: * }>,
    ...{ availableSlots: Array<({
        ...{ __typename?: 'AvailableSlot' },
      ...$Pick<AvailableSlot, { startTime: *, endTime: *, locationId?: * }>
    })> }
  }) }
});

export type ProviderAvailabilityFieldsFragment = ({
    ...{ __typename?: 'ProviderAvailability' },
  ...$Pick<ProviderAvailability, { id: *, staffId: * }>,
  ...{ availableSlots: Array<({
      ...{ __typename?: 'AvailableSlot' },
    ...$Pick<AvailableSlot, { startTime: *, endTime: *, locationId?: * }>
  })> }
});

export type GetMatchingProvidersQueryVariables = {
  week: TargetWeek,
  appointmentType: AppointmentType,
  medium: AppointmentMedium,
  locationIds: Array<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
  patientId: $ElementType<Scalars, 'String'>,
};


export type GetMatchingProvidersQuery = ({
    ...{ __typename?: 'Query' },
  ...{ matchingProviders: Array<({
      ...{ __typename?: 'ProviderAvailability' },
    ...$Pick<ProviderAvailability, { id: *, staffId: * }>,
    ...{ availableSlots: Array<({
        ...{ __typename?: 'AvailableSlot' },
      ...$Pick<AvailableSlot, { startTime: *, endTime: *, locationId?: * }>
    })> }
  })> }
});

type EventFields_MedicalAppointment_Fragment = ({
    ...{ __typename?: 'MedicalAppointment' },
  ...$Pick<MedicalAppointment, { id: *, patientId: *, startTime: *, endTime: *, appointmentType: *, appointmentMedium: *, clinicId?: * }>
});

type EventFields_NonPatientEvent_Fragment = ({
    ...{ __typename?: 'NonPatientEvent' },
  ...$Pick<NonPatientEvent, { id: *, staffId: *, startTime: *, endTime: * }>
});

export type EventFieldsFragment = EventFields_MedicalAppointment_Fragment | EventFields_NonPatientEvent_Fragment;

export type GetProviderWeeklyEventsQueryVariables = {
  staffId: $ElementType<Scalars, 'String'>,
  week: TargetWeek,
};


export type GetProviderWeeklyEventsQuery = ({
    ...{ __typename?: 'Query' },
  ...{ providerWeeklyEvents: Array<({
      ...{ __typename?: 'MedicalAppointment' },
    ...$Pick<MedicalAppointment, { id: *, patientId: *, startTime: *, endTime: *, appointmentType: *, appointmentMedium: *, clinicId?: * }>
  }) | ({
      ...{ __typename?: 'NonPatientEvent' },
    ...$Pick<NonPatientEvent, { id: *, staffId: *, startTime: *, endTime: * }>
  })> }
});

export type ProviderTeamAvailabilityFieldsFragment = ({
    ...{ __typename?: 'ProviderTeamAvailability' },
  ...$Pick<ProviderTeamAvailability, { id: * }>,
  ...{ availableSlots: Array<({
      ...{ __typename?: 'AvailableSlot' },
    ...$Pick<AvailableSlot, { startTime: *, endTime: *, locationId?: * }>
  })> }
});

export type GetTeamEventsAndAvailabilityQueryVariables = {
  leadStaffId: $ElementType<Scalars, 'String'>,
  supportingStaffId: $ElementType<Scalars, 'String'>,
  week: TargetWeek,
  appointmentType: AppointmentType,
  medium: AppointmentMedium,
  locationIds: Array<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
  patientId: $ElementType<Scalars, 'String'>,
};


export type GetTeamEventsAndAvailabilityQuery = ({
    ...{ __typename?: 'Query' },
  ...{ providerTeamAvailability: ({
      ...{ __typename?: 'ProviderTeamAvailability' },
    ...$Pick<ProviderTeamAvailability, { id: * }>,
    ...{ availableSlots: Array<({
        ...{ __typename?: 'AvailableSlot' },
      ...$Pick<AvailableSlot, { startTime: *, endTime: *, locationId?: * }>
    })> }
  }), leadProviderWeeklyEvents: Array<({
      ...{ __typename?: 'MedicalAppointment' },
    ...$Pick<MedicalAppointment, { id: *, patientId: *, startTime: *, endTime: *, appointmentType: *, appointmentMedium: *, clinicId?: * }>
  }) | ({
      ...{ __typename?: 'NonPatientEvent' },
    ...$Pick<NonPatientEvent, { id: *, staffId: *, startTime: *, endTime: * }>
  })>, supportingProviderWeeklyEvents: Array<({
      ...{ __typename?: 'MedicalAppointment' },
    ...$Pick<MedicalAppointment, { id: *, patientId: *, startTime: *, endTime: *, appointmentType: *, appointmentMedium: *, clinicId?: * }>
  }) | ({
      ...{ __typename?: 'NonPatientEvent' },
    ...$Pick<NonPatientEvent, { id: *, staffId: *, startTime: *, endTime: * }>
  })> }
});

type AppointmentHostInfoFields_ExternalProviderHostInfo_Fragment = ({
    ...{ __typename?: 'ExternalProviderHostInfo' },
  ...$Pick<ExternalProviderHostInfo, { timezone?: * }>,
  ...{ provider: ({
      ...{ __typename?: 'ExternalProvider' },
    ...$Pick<ExternalProvider, { id: *, displayName: * }>
  }) }
});

type AppointmentHostInfoFields_StaffMemberHostInfo_Fragment = ({
    ...{ __typename?: 'StaffMemberHostInfo' },
  ...{ staff: ({
      ...{ __typename?: 'Practitioner' },
    ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
  }), supportingStaff?: ?({
      ...{ __typename?: 'Practitioner' },
    ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
  }) }
});

export type AppointmentHostInfoFieldsFragment = AppointmentHostInfoFields_ExternalProviderHostInfo_Fragment | AppointmentHostInfoFields_StaffMemberHostInfo_Fragment;

export type MedicalAppointmentFieldsFragment = ({
    ...{ __typename?: 'MedicalAppointment' },
  ...$Pick<MedicalAppointment, { id: *, description: *, appointmentType: *, appointmentMedium: *, startTime: *, endTime: *, externalNotes?: *, clinicId?: *, cancelled: * }>,
  ...{ hostInfo: ({
      ...{ __typename?: 'ExternalProviderHostInfo' },
    ...$Pick<ExternalProviderHostInfo, { timezone?: * }>,
    ...{ provider: ({
        ...{ __typename?: 'ExternalProvider' },
      ...$Pick<ExternalProvider, { id: *, displayName: * }>
    }) }
  }) | ({
      ...{ __typename?: 'StaffMemberHostInfo' },
    ...{ staff: ({
        ...{ __typename?: 'Practitioner' },
      ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
    }), supportingStaff?: ?({
        ...{ __typename?: 'Practitioner' },
      ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
    }) }
  }) }
});

export type PatientAppointmentsAdminQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
  includePastAppointments: $ElementType<Scalars, 'Boolean'>,
};


export type PatientAppointmentsAdminQuery = ({
    ...{ __typename?: 'Query' },
  ...{ appointmentsForPatientAdmin: Array<({
      ...{ __typename?: 'MedicalAppointment' },
    ...$Pick<MedicalAppointment, { id: *, description: *, appointmentType: *, appointmentMedium: *, startTime: *, endTime: *, externalNotes?: *, clinicId?: *, cancelled: * }>,
    ...{ hostInfo: ({
        ...{ __typename?: 'ExternalProviderHostInfo' },
      ...$Pick<ExternalProviderHostInfo, { timezone?: * }>,
      ...{ provider: ({
          ...{ __typename?: 'ExternalProvider' },
        ...$Pick<ExternalProvider, { id: *, displayName: * }>
      }) }
    }) | ({
        ...{ __typename?: 'StaffMemberHostInfo' },
      ...{ staff: ({
          ...{ __typename?: 'Practitioner' },
        ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
      }), supportingStaff?: ?({
          ...{ __typename?: 'Practitioner' },
        ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
      }) }
    }) }
  })> }
});

export type SchedulingVisitNotesQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type SchedulingVisitNotesQuery = ({
    ...{ __typename?: 'Query' },
  ...{ allVisitNotes: Array<({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: * }>
    }) }
  })> }
});

export type GetAppointmentByIdQueryVariables = {
  appointmentId: $ElementType<Scalars, 'String'>,
};


export type GetAppointmentByIdQuery = ({
    ...{ __typename?: 'Query' },
  ...{ appointmentByIdAdmin?: ?({
      ...{ __typename?: 'MedicalAppointment' },
    ...$Pick<MedicalAppointment, { id: *, description: *, appointmentType: *, appointmentMedium: *, startTime: *, endTime: *, externalNotes?: *, clinicId?: *, cancelled: * }>,
    ...{ hostInfo: ({
        ...{ __typename?: 'ExternalProviderHostInfo' },
      ...$Pick<ExternalProviderHostInfo, { timezone?: * }>,
      ...{ provider: ({
          ...{ __typename?: 'ExternalProvider' },
        ...$Pick<ExternalProvider, { id: *, displayName: * }>
      }) }
    }) | ({
        ...{ __typename?: 'StaffMemberHostInfo' },
      ...{ staff: ({
          ...{ __typename?: 'Practitioner' },
        ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
      }), supportingStaff?: ?({
          ...{ __typename?: 'Practitioner' },
        ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
      }) }
    }) }
  }) }
});

export type CreateVisitNoteV2MutationVariables = {
  patientId: $ElementType<Scalars, 'String'>,
  appointmentId: $ElementType<Scalars, 'String'>,
};


export type CreateVisitNoteV2Mutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ createVisitNoteV2: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }), assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })>, orders: Array<({
        ...{ __typename?: 'VisitNoteOrder' },
      ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
    })>, orderSuggestions: Array<({
        ...{ __typename?: 'OrderSuggestion' },
      ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
    })>, completionMetadata?: ?({
        ...{ __typename?: 'CompletionMetadata' },
      ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
    }) }
  }) }
});

export type DeletePendingVisitNoteMutationVariables = {
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type DeletePendingVisitNoteMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { deletePendingVisitNote: * }>
});

export type GetAssessmentNameQueryVariables = {
  assessmentId: $ElementType<Scalars, 'String'>,
};


export type GetAssessmentNameQuery = ({
    ...{ __typename?: 'Query' },
  ...{ assessment: ({
      ...{ __typename?: 'Assessment' },
    ...$Pick<Assessment, { id: *, name: * }>
  }) }
});

export type VisitNote_AppointmentFieldsFragment = ({
    ...{ __typename?: 'MedicalAppointment' },
  ...$Pick<MedicalAppointment, { id: *, startTime: * }>
});

export type VisitNote_AssessmentFieldsFragment = ({
    ...{ __typename?: 'Assessment' },
  ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
  ...{ scoringOutput: ({
      ...{ __typename?: 'FormattedScoringOutput' },
    ...{ exceptions: Array<({
        ...{ __typename?: 'FormattedAssessmentException' },
      ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
    })>, pathways: Array<({
        ...{ __typename?: 'FormattedAssessmentPathway' },
      ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
    })> }
  }), patientAccessToken?: ?({
      ...{ __typename?: 'PatientAccessToken' },
    ...$Pick<PatientAccessToken, { expiresAt?: * }>
  }) }
});

export type VisitNoteFieldsFragment = ({
    ...{ __typename?: 'VisitNote' },
  ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
  ...{ appointment: ({
      ...{ __typename?: 'MedicalAppointment' },
    ...$Pick<MedicalAppointment, { id: *, startTime: * }>
  }), assessments: Array<({
      ...{ __typename?: 'Assessment' },
    ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
    ...{ scoringOutput: ({
        ...{ __typename?: 'FormattedScoringOutput' },
      ...{ exceptions: Array<({
          ...{ __typename?: 'FormattedAssessmentException' },
        ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
      })>, pathways: Array<({
          ...{ __typename?: 'FormattedAssessmentPathway' },
        ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
      })> }
    }), patientAccessToken?: ?({
        ...{ __typename?: 'PatientAccessToken' },
      ...$Pick<PatientAccessToken, { expiresAt?: * }>
    }) }
  })>, orders: Array<({
      ...{ __typename?: 'VisitNoteOrder' },
    ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
  })>, orderSuggestions: Array<({
      ...{ __typename?: 'OrderSuggestion' },
    ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
  })>, completionMetadata?: ?({
      ...{ __typename?: 'CompletionMetadata' },
    ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
  }) }
});

export type PatientVisitNotesQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type PatientVisitNotesQuery = ({
    ...{ __typename?: 'Query' },
  ...{ allVisitNotes: Array<({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, createdByName: *, status: * }>,
    ...{ assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: * }>
    })>, appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }) }
  })> }
});

export type UpdateStatusToReadyForElationMutationVariables = {
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type UpdateStatusToReadyForElationMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ updateStatusToReadyForElation: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }), assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })>, orders: Array<({
        ...{ __typename?: 'VisitNoteOrder' },
      ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
    })>, orderSuggestions: Array<({
        ...{ __typename?: 'OrderSuggestion' },
      ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
    })>, completionMetadata?: ?({
        ...{ __typename?: 'CompletionMetadata' },
      ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
    }) }
  }) }
});

export type VisitNoteByIdQueryVariables = {
  id: $ElementType<Scalars, 'String'>,
};


export type VisitNoteByIdQuery = ({
    ...{ __typename?: 'Query' },
  ...{ visitNoteById: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }), assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })>, orders: Array<({
        ...{ __typename?: 'VisitNoteOrder' },
      ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
    })>, orderSuggestions: Array<({
        ...{ __typename?: 'OrderSuggestion' },
      ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
    })>, completionMetadata?: ?({
        ...{ __typename?: 'CompletionMetadata' },
      ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
    }) }
  }) }
});

export type PatientAssessmentsForNoteQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type PatientAssessmentsForNoteQuery = ({
    ...{ __typename?: 'Query' },
  ...{ assessmentsForPatient: Array<({
      ...{ __typename?: 'Assessment' },
    ...$Pick<Assessment, { id: *, name: *, createdAt: * }>
  })> }
});

export type AssessmentConfigurationsQueryVariables = {};


export type AssessmentConfigurationsQuery = ({
    ...{ __typename?: 'Query' },
  ...{ assessmentConfigurations: Array<({
      ...{ __typename?: 'AssessmentConfiguration' },
    ...$Pick<AssessmentConfiguration, { id: *, name: *, description: *, patientFacing: *, beta: * }>
  })> }
});

export type AddExistingAssessmentToVisitNoteMutationVariables = {
  visitNoteId: $ElementType<Scalars, 'String'>,
  assessmentId: $ElementType<Scalars, 'String'>,
};


export type AddExistingAssessmentToVisitNoteMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ addExistingAssessment: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }), assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })>, orders: Array<({
        ...{ __typename?: 'VisitNoteOrder' },
      ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
    })>, orderSuggestions: Array<({
        ...{ __typename?: 'OrderSuggestion' },
      ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
    })>, completionMetadata?: ?({
        ...{ __typename?: 'CompletionMetadata' },
      ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
    }) }
  }) }
});

export type CreateAssessmentForVisitNoteMutationVariables = {
  visitNoteId: $ElementType<Scalars, 'String'>,
  assessmentType: $ElementType<Scalars, 'String'>,
  patientFacing: $ElementType<Scalars, 'Boolean'>,
};


export type CreateAssessmentForVisitNoteMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ createAssessmentForVisitNote: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }), assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })>, orders: Array<({
        ...{ __typename?: 'VisitNoteOrder' },
      ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
    })>, orderSuggestions: Array<({
        ...{ __typename?: 'OrderSuggestion' },
      ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
    })>, completionMetadata?: ?({
        ...{ __typename?: 'CompletionMetadata' },
      ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
    }) }
  }) }
});

export type GetVisitNoteAssessmentsQueryVariables = {
  id: $ElementType<Scalars, 'String'>,
};


export type GetVisitNoteAssessmentsQuery = ({
    ...{ __typename?: 'Query' },
  ...{ visitNoteById: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: * }>,
    ...{ assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })> }
  }) }
});

export type DetachAssessmentMutationVariables = {
  visitNoteId: $ElementType<Scalars, 'String'>,
  assessmentId: $ElementType<Scalars, 'String'>,
  alsoDelete: $ElementType<Scalars, 'Boolean'>,
};


export type DetachAssessmentMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ detachAssessment: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }), assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })>, orders: Array<({
        ...{ __typename?: 'VisitNoteOrder' },
      ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
    })>, orderSuggestions: Array<({
        ...{ __typename?: 'OrderSuggestion' },
      ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
    })>, completionMetadata?: ?({
        ...{ __typename?: 'CompletionMetadata' },
      ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
    }) }
  }) }
});

export type FinalizeOrdersMutationVariables = {
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type FinalizeOrdersMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ finalizeOrders: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }), assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })>, orders: Array<({
        ...{ __typename?: 'VisitNoteOrder' },
      ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
    })>, orderSuggestions: Array<({
        ...{ __typename?: 'OrderSuggestion' },
      ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
    })>, completionMetadata?: ?({
        ...{ __typename?: 'CompletionMetadata' },
      ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
    }) }
  }) }
});

export type UpdateVisitNoteMutationVariables = {
  visitNoteId: $ElementType<Scalars, 'String'>,
  subjective: $ElementType<Scalars, 'String'>,
  objective: $ElementType<Scalars, 'String'>,
  assessmentPlan: $ElementType<Scalars, 'String'>,
  orders?: ?Array<OrderInput> | OrderInput,
};


export type UpdateVisitNoteMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ updateVisitNote: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }), assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })>, orders: Array<({
        ...{ __typename?: 'VisitNoteOrder' },
      ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
    })>, orderSuggestions: Array<({
        ...{ __typename?: 'OrderSuggestion' },
      ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
    })>, completionMetadata?: ?({
        ...{ __typename?: 'CompletionMetadata' },
      ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
    }) }
  }) }
});

export type UpdateVisitNoteSoapOnlyMutationVariables = {
  visitNoteId: $ElementType<Scalars, 'String'>,
  subjective: $ElementType<Scalars, 'String'>,
  objective: $ElementType<Scalars, 'String'>,
  assessmentPlan: $ElementType<Scalars, 'String'>,
};


export type UpdateVisitNoteSoapOnlyMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ updateVisitNote: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }), assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })>, orders: Array<({
        ...{ __typename?: 'VisitNoteOrder' },
      ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
    })>, orderSuggestions: Array<({
        ...{ __typename?: 'OrderSuggestion' },
      ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
    })>, completionMetadata?: ?({
        ...{ __typename?: 'CompletionMetadata' },
      ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
    }) }
  }) }
});

export type FinalizeMutationVariables = {
  visitNoteId: $ElementType<Scalars, 'String'>,
};


export type FinalizeMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ finalize: ({
      ...{ __typename?: 'VisitNote' },
    ...$Pick<VisitNote, { id: *, patientId: *, subjective: *, objective: *, assessmentPlan: *, status: *, createdAt: *, createdByName: * }>,
    ...{ appointment: ({
        ...{ __typename?: 'MedicalAppointment' },
      ...$Pick<MedicalAppointment, { id: *, startTime: * }>
    }), assessments: Array<({
        ...{ __typename?: 'Assessment' },
      ...$Pick<Assessment, { id: *, patientId: *, name: *, status: *, createdAt: *, updatedAt?: * }>,
      ...{ scoringOutput: ({
          ...{ __typename?: 'FormattedScoringOutput' },
        ...{ exceptions: Array<({
            ...{ __typename?: 'FormattedAssessmentException' },
          ...$Pick<FormattedAssessmentException, { name: *, bodyPart?: * }>
        })>, pathways: Array<({
            ...{ __typename?: 'FormattedAssessmentPathway' },
          ...$Pick<FormattedAssessmentPathway, { name: *, bodyPart: * }>
        })> }
      }), patientAccessToken?: ?({
          ...{ __typename?: 'PatientAccessToken' },
        ...$Pick<PatientAccessToken, { expiresAt?: * }>
      }) }
    })>, orders: Array<({
        ...{ __typename?: 'VisitNoteOrder' },
      ...$Pick<VisitNoteOrder, { orderId: *, bodyParts: * }>
    })>, orderSuggestions: Array<({
        ...{ __typename?: 'OrderSuggestion' },
      ...$Pick<OrderSuggestion, { orderId: *, visibility: *, bodyParts: * }>
    })>, completionMetadata?: ?({
        ...{ __typename?: 'CompletionMetadata' },
      ...$Pick<CompletionMetadata, { completedAt: *, completedByName: * }>
    }) }
  }) }
});

export type ReportConfigurationsQueryVariables = {};


export type ReportConfigurationsQuery = ({
    ...{ __typename?: 'Query' },
  ...{ reportConfigurations: Array<({
      ...{ __typename?: 'ReportConfiguration' },
    ...$Pick<ReportConfiguration, { id: *, name: *, description: * }>
  })> }
});

export type ReportQueryVariables = {
  id: $ElementType<Scalars, 'String'>,
};


export type ReportQuery = ({
    ...{ __typename?: 'Query' },
  ...$Pick<Query, { report: * }>
});

export type AppointmentTypeConfigurationFieldsFragment = ({
    ...{ __typename?: 'AppointmentTypeConfiguration' },
  ...$Pick<AppointmentTypeConfiguration, { appointmentType: *, internalName: *, durationInMinutes?: *, leadPractitionerType?: *, supportedMediums: *, supportingPractitionerTypes: * }>
});

export type GetAppointmentTypeConfigQueryVariables = {
  beta?: ?$ElementType<Scalars, 'Boolean'>,
};


export type GetAppointmentTypeConfigQuery = ({
    ...{ __typename?: 'Query' },
  ...{ appointmentTypeConfigurations: Array<({
      ...{ __typename?: 'AppointmentTypeConfiguration' },
    ...$Pick<AppointmentTypeConfiguration, { appointmentType: *, internalName: *, durationInMinutes?: *, leadPractitionerType?: *, supportedMediums: *, supportingPractitionerTypes: * }>
  })> }
});

export type GetClinicsQueryVariables = {};


export type GetClinicsQuery = ({
    ...{ __typename?: 'Query' },
  ...{ clinics: Array<({
      ...{ __typename?: 'Clinic' },
    ...$Pick<Clinic, { id: *, name: *, addressLines: * }>
  })> }
});

export type MotionUserFieldsFragment = ({
    ...{ __typename?: 'MotionUser' },
  ...$Pick<MotionUser, { id: *, displayName: *, credentialsAbbreviation?: *, avatarUrl?: *, activeOnZoom: * }>
});

export type MotionStaffQueryVariables = {};


export type MotionStaffQuery = ({
    ...{ __typename?: 'Query' },
  ...{ masqueradeableMotionUsers: Array<({
      ...{ __typename?: 'MotionUser' },
    ...$Pick<MotionUser, { id: *, displayName: *, credentialsAbbreviation?: *, avatarUrl?: *, activeOnZoom: * }>
  })> }
});

export type PatientFieldsFragment = ({
    ...{ __typename?: 'Patient' },
  ...$Pick<Patient, { id: *, welkinPatientId?: *, elationPatientId?: *, firstName: *, lastName: *, zip?: *, risaltoUserId?: *, invitationId?: *, primaryCareTeamStaffId: * }>,
  ...{ communicationPreferences: ({
      ...{ __typename?: 'PatientCommunicationPreferences' },
    ...$Pick<PatientCommunicationPreferences, { email?: *, phone?: *, timezone?: *, consentsToSMS?: *, consentsToEmails?: * }>
  }) }
});

export type GetPatientQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type GetPatientQuery = ({
    ...{ __typename?: 'Query' },
  ...{ patient: ({
      ...{ __typename?: 'Patient' },
    ...$Pick<Patient, { id: *, welkinPatientId?: *, elationPatientId?: *, firstName: *, lastName: *, zip?: *, risaltoUserId?: *, invitationId?: *, primaryCareTeamStaffId: * }>,
    ...{ communicationPreferences: ({
        ...{ __typename?: 'PatientCommunicationPreferences' },
      ...$Pick<PatientCommunicationPreferences, { email?: *, phone?: *, timezone?: *, consentsToSMS?: *, consentsToEmails?: * }>
    }) }
  }) }
});

export type UpdatePatientTimezoneMutationVariables = {
  patientId: $ElementType<Scalars, 'String'>,
  timezone: $ElementType<Scalars, 'String'>,
};


export type UpdatePatientTimezoneMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ updatePatientTimezone: ({
      ...{ __typename?: 'Patient' },
    ...$Pick<Patient, { id: *, welkinPatientId?: *, elationPatientId?: *, firstName: *, lastName: *, zip?: *, risaltoUserId?: *, invitationId?: *, primaryCareTeamStaffId: * }>,
    ...{ communicationPreferences: ({
        ...{ __typename?: 'PatientCommunicationPreferences' },
      ...$Pick<PatientCommunicationPreferences, { email?: *, phone?: *, timezone?: *, consentsToSMS?: *, consentsToEmails?: * }>
    }) }
  }) }
});

export type MasqueradeQueryQueryVariables = {};


export type MasqueradeQueryQuery = ({
    ...{ __typename?: 'Query' },
  ...{ masqueradeableMotionUsers: Array<({
      ...{ __typename?: 'MotionUser' },
    ...$Pick<MotionUser, { id: *, displayName: *, credentialsAbbreviation?: *, avatarUrl?: * }>
  })> }
});

export type ConversationQueryVariables = {
  memberId: $ElementType<Scalars, 'String'>,
};


export type ConversationQuery = ({
    ...{ __typename?: 'Query' },
  ...{ conversation?: ?({
      ...{ __typename?: 'ActiveConversation' },
    ...$Pick<ActiveConversation, { id: *, state: *, ownerId?: *, ownerName?: *, lastEventTime: *, lastMemberReadTime?: *, lastMemberMessageTime?: *, lastMotionMessageTime?: * }>,
    ...{ member: ({
        ...{ __typename?: 'MemberDisplayInfo' },
      ...$Pick<MemberDisplayInfo, { id: *, displayName: * }>
    }) }
  }) }
});

export type SendBirdAuthForCurrentUserMutationVariables = {};


export type SendBirdAuthForCurrentUserMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ sendBirdAuthForCurrentUser: ({
      ...{ __typename?: 'SendBirdAuthInfo' },
    ...$Pick<SendBirdAuthInfo, { appId: *, userId: *, accessToken: * }>
  }) }
});

export type BookNewAppointmentMutationVariables = {
  patientId: $ElementType<Scalars, 'String'>,
  appointmentType: AppointmentType,
  appointmentMedium: AppointmentMedium,
  startTime: $ElementType<Scalars, 'Long'>,
  leadPractitionerId: $ElementType<Scalars, 'String'>,
  supportingPractitionerId?: ?$ElementType<Scalars, 'String'>,
  locationId?: ?$ElementType<Scalars, 'String'>,
  patientInstructions?: ?$ElementType<Scalars, 'String'>,
  ignoreDoubleBooking: $ElementType<Scalars, 'Boolean'>,
  ignoreLocationConflict: $ElementType<Scalars, 'Boolean'>,
  notifyPatient: $ElementType<Scalars, 'Boolean'>,
  remindPatient24h: $ElementType<Scalars, 'Boolean'>,
  remindPatient1h: $ElementType<Scalars, 'Boolean'>,
};


export type BookNewAppointmentMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ bookNewAppointment: ({
      ...{ __typename?: 'MedicalAppointment' },
    ...$Pick<MedicalAppointment, { id: *, description: *, appointmentType: *, appointmentMedium: *, startTime: *, endTime: *, externalNotes?: *, clinicId?: *, cancelled: * }>,
    ...{ hostInfo: ({
        ...{ __typename?: 'ExternalProviderHostInfo' },
      ...$Pick<ExternalProviderHostInfo, { timezone?: * }>,
      ...{ provider: ({
          ...{ __typename?: 'ExternalProvider' },
        ...$Pick<ExternalProvider, { id: *, displayName: * }>
      }) }
    }) | ({
        ...{ __typename?: 'StaffMemberHostInfo' },
      ...{ staff: ({
          ...{ __typename?: 'Practitioner' },
        ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
      }), supportingStaff?: ?({
          ...{ __typename?: 'Practitioner' },
        ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
      }) }
    }) }
  }) }
});

export type RescheduleExistingAppointmentMutationVariables = {
  appointmentId: $ElementType<Scalars, 'String'>,
  startTime: $ElementType<Scalars, 'Long'>,
  ignoreDoubleBooking: $ElementType<Scalars, 'Boolean'>,
  ignoreLocationConflict: $ElementType<Scalars, 'Boolean'>,
  notifyPatient: $ElementType<Scalars, 'Boolean'>,
};


export type RescheduleExistingAppointmentMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ rescheduleExistingAppointment: ({
      ...{ __typename?: 'MedicalAppointment' },
    ...$Pick<MedicalAppointment, { id: *, description: *, appointmentType: *, appointmentMedium: *, startTime: *, endTime: *, externalNotes?: *, clinicId?: *, cancelled: * }>,
    ...{ hostInfo: ({
        ...{ __typename?: 'ExternalProviderHostInfo' },
      ...$Pick<ExternalProviderHostInfo, { timezone?: * }>,
      ...{ provider: ({
          ...{ __typename?: 'ExternalProvider' },
        ...$Pick<ExternalProvider, { id: *, displayName: * }>
      }) }
    }) | ({
        ...{ __typename?: 'StaffMemberHostInfo' },
      ...{ staff: ({
          ...{ __typename?: 'Practitioner' },
        ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
      }), supportingStaff?: ?({
          ...{ __typename?: 'Practitioner' },
        ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: * }>
      }) }
    }) }
  }) }
});

type EffectiveTimeSlotFields_EffectiveAppointmentSlot_Fragment = ({
    ...{ __typename?: 'EffectiveAppointmentSlot' },
  ...$Pick<EffectiveAppointmentSlot, { appointmentType: *, locationId?: *, supportedMediums: *, startTime: *, endTime: * }>
});

type EffectiveTimeSlotFields_EffectiveUnavailableTimeSlot_Fragment = ({
    ...{ __typename?: 'EffectiveUnavailableTimeSlot' },
  ...$Pick<EffectiveUnavailableTimeSlot, { endTime: *, name: *, startTime: * }>
});

export type EffectiveTimeSlotFieldsFragment = EffectiveTimeSlotFields_EffectiveAppointmentSlot_Fragment | EffectiveTimeSlotFields_EffectiveUnavailableTimeSlot_Fragment;

export type GetProviderEventsAndScheduleQueryVariables = {
  staffId: $ElementType<Scalars, 'String'>,
  week: TargetWeek,
};


export type GetProviderEventsAndScheduleQuery = ({
    ...{ __typename?: 'Query' },
  ...{ providerWeeklyEvents: Array<({
      ...{ __typename?: 'MedicalAppointment' },
    ...$Pick<MedicalAppointment, { id: *, patientId: *, startTime: *, endTime: *, appointmentType: *, appointmentMedium: *, clinicId?: * }>
  }) | ({
      ...{ __typename?: 'NonPatientEvent' },
    ...$Pick<NonPatientEvent, { id: *, staffId: *, startTime: *, endTime: * }>
  })>, providerEffectiveTimeSlots: Array<({
      ...{ __typename?: 'EffectiveAppointmentSlot' },
    ...$Pick<EffectiveAppointmentSlot, { appointmentType: *, locationId?: *, supportedMediums: *, startTime: *, endTime: * }>
  }) | ({
      ...{ __typename?: 'EffectiveUnavailableTimeSlot' },
    ...$Pick<EffectiveUnavailableTimeSlot, { endTime: *, name: *, startTime: * }>
  })> }
});

export type OrdersQueryVariables = {};


export type OrdersQuery = ({
    ...{ __typename?: 'Query' },
  ...{ orders: ({
      ...{ __typename?: 'OrdersResponse' },
    ...{ orderConfigurations: Array<({
        ...{ __typename?: 'OrderConfig' },
      ...$Pick<OrderConfig, { id: *, name: *, category: *, level: *, groupId?: * }>
    })>, orderGroupConfigurations: Array<({
        ...{ __typename?: 'OrderGroupConfig' },
      ...$Pick<OrderGroupConfig, { id: *, name: * }>
    })> }
  }) }
});

export type ConversationFieldsFragment = ({
    ...{ __typename?: 'ActiveConversation' },
  ...$Pick<ActiveConversation, { id: *, state: *, ownerId?: *, ownerName?: *, lastEventTime: *, lastMemberReadTime?: *, lastMemberMessageTime?: *, lastMotionMessageTime?: * }>,
  ...{ member: ({
      ...{ __typename?: 'MemberDisplayInfo' },
    ...$Pick<MemberDisplayInfo, { id: *, displayName: * }>
  }) }
});

export type ClaimConversationMutationVariables = {
  memberId: $ElementType<Scalars, 'String'>,
};


export type ClaimConversationMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { claimConversation: * }>
});

export type CloseConversationMutationVariables = {
  memberId: $ElementType<Scalars, 'String'>,
};


export type CloseConversationMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { closeConversation: * }>
});

export type JoinConversationMutationVariables = {
  channelUrl: $ElementType<Scalars, 'String'>,
};


export type JoinConversationMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { joinConversation: * }>
});

export type AppointmentTokenFieldsFragment = ({
    ...{ __typename?: 'MedicalAppointmentToken' },
  ...$Pick<MedicalAppointmentToken, { id: *, patientId: *, appointmentType: *, available: *, staffIds: *, durationsInMinutes: *, createdAt: *, updatedAt?: * }>
});

export type CreateMedicalAppointmentTokenMutationVariables = {
  patientId: $ElementType<Scalars, 'String'>,
  appointmentType: AppointmentType,
  available: $ElementType<Scalars, 'Int'>,
  durationsInMinutes: Array<$ElementType<Scalars, 'Int'>> | $ElementType<Scalars, 'Int'>,
  staffIds: Array<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
};


export type CreateMedicalAppointmentTokenMutation = ({
    ...{ __typename?: 'Mutation' },
  ...{ createMedicalAppointmentToken?: ?({
      ...{ __typename?: 'MedicalAppointmentToken' },
    ...$Pick<MedicalAppointmentToken, { id: *, patientId: *, appointmentType: *, available: *, staffIds: *, durationsInMinutes: *, createdAt: *, updatedAt?: * }>
  }) }
});

export type MedicalAppointmentTokensQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type MedicalAppointmentTokensQuery = ({
    ...{ __typename?: 'Query' },
  ...{ medicalAppointmentTokens: Array<({
      ...{ __typename?: 'MedicalAppointmentToken' },
    ...$Pick<MedicalAppointmentToken, { id: *, patientId: *, appointmentType: *, available: *, staffIds: *, durationsInMinutes: *, createdAt: *, updatedAt?: * }>
  })> }
});

export type SendScheduleLinkToMemberMutationVariables = {
  message: $ElementType<Scalars, 'String'>,
  memberId: $ElementType<Scalars, 'String'>,
  token: $ElementType<Scalars, 'String'>,
};


export type SendScheduleLinkToMemberMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { sendScheduleLinkToMember?: * }>
});

export type UpdateCommunicationPreferencesMutationVariables = {
  patientId: $ElementType<Scalars, 'String'>,
  consentsToSMS: $ElementType<Scalars, 'Boolean'>,
  consentsToEmail: $ElementType<Scalars, 'Boolean'>,
};


export type UpdateCommunicationPreferencesMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { updateCommunicationPreferences: * }>
});

export type RescheduleAppointmentAdminMutationVariables = {
  appointmentId: $ElementType<Scalars, 'String'>,
  startTime: $ElementType<Scalars, 'Long'>,
  durationInMinutes: $ElementType<Scalars, 'Int'>,
  ignoreBusy: $ElementType<Scalars, 'Boolean'>,
  notifyPatient: $ElementType<Scalars, 'Boolean'>,
};


export type RescheduleAppointmentAdminMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { rescheduleAppointmentAdmin?: * }>
});

export type CancelAppointmentMutationMutationVariables = {
  appointmentId: $ElementType<Scalars, 'String'>,
  notifyPatient: $ElementType<Scalars, 'Boolean'>,
};


export type CancelAppointmentMutationMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { cancelAppointmentAdmin?: * }>
});

export type StaffSchedulesByAppointmentTypeQueryVariables = {
  appointmentType?: ?AppointmentType,
};


export type StaffSchedulesByAppointmentTypeQuery = ({
    ...{ __typename?: 'Query' },
  ...{ staffSchedulesByAppointmentType: Array<({
      ...{ __typename?: 'StaffMemberSchedule' },
    ...$Pick<StaffMemberSchedule, { staffId: *, displayName: *, avatarUrl: *, timezone: *, appointmentTypes: *, workingHours: *, licensedStates: * }>
  })> }
});

export type ServerInfoQueryVariables = {};


export type ServerInfoQuery = ({
    ...{ __typename?: 'Query' },
  ...$Pick<Query, { version: *, namespace: * }>
});

export type DropCachesMutationVariables = {};


export type DropCachesMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { dropCaches: * }>
});

export type PatientInfoQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type PatientInfoQuery = ({
    ...{ __typename?: 'Query' },
  ...{ patient: ({
      ...{ __typename?: 'Patient' },
    ...$Pick<Patient, { id: *, welkinPatientId?: *, elationPatientId?: *, firstName: *, lastName: *, zip?: *, risaltoUserId?: *, invitationId?: *, primaryCareTeamStaffId: * }>,
    ...{ communicationPreferences: ({
        ...{ __typename?: 'PatientCommunicationPreferences' },
      ...$Pick<PatientCommunicationPreferences, { email?: *, phone?: *, timezone?: *, consentsToSMS?: *, consentsToEmails?: * }>
    }) }
  }) }
});

export type PractitionerFieldsFragment = ({
    ...{ __typename?: 'Practitioner' },
  ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: *, avatarUrl: *, licensedStates: * }>
});

export type ClinicFieldsFragment = ({
    ...{ __typename?: 'Clinic' },
  ...$Pick<Clinic, { id: *, name: *, addressLines: * }>
});

export type AvailableAppointmentSlotsQueryVariables = {
  appointmentType: AppointmentType,
  appointmentMedium: AppointmentMedium,
  searchFromDay: $ElementType<Scalars, 'String'>,
  filterByLeadPractitionerIds: Array<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
  supportingPractitionerType?: ?PractitionerType,
  filterBySupportingPractitionerIds: Array<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
  filterByClinicIds: Array<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
  filterByTimeOfDay: Array<TimeOfDay> | TimeOfDay,
};


export type AvailableAppointmentSlotsQuery = ({
    ...{ __typename?: 'Query' },
  ...{ availableAppointmentSlotsAdmin: Array<({
      ...{ __typename?: 'AppointmentSlot' },
    ...$Pick<AppointmentSlot, { id: *, appointmentType: *, appointmentMedium: *, startTime: *, endTime: * }>,
    ...{ leadPractitioner: ({
        ...{ __typename?: 'Practitioner' },
      ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: *, avatarUrl: *, licensedStates: * }>
    }), supportingPractitioner?: ?({
        ...{ __typename?: 'Practitioner' },
      ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: *, avatarUrl: *, licensedStates: * }>
    }), clinic?: ?({
        ...{ __typename?: 'Clinic' },
      ...$Pick<Clinic, { id: *, name: *, addressLines: * }>
    }) }
  })> }
});

export type CreateAppointmentAdminMutationVariables = {
  patientId: $ElementType<Scalars, 'String'>,
  appointmentType: AppointmentType,
  appointmentMedium: AppointmentMedium,
  startTime: $ElementType<Scalars, 'Long'>,
  leadPractitionerId: $ElementType<Scalars, 'String'>,
  supportingPractitionerId?: ?$ElementType<Scalars, 'String'>,
  locationId?: ?$ElementType<Scalars, 'String'>,
  appointmentDescription: $ElementType<Scalars, 'String'>,
  patientInstructions?: ?$ElementType<Scalars, 'String'>,
  ignoreBusy: $ElementType<Scalars, 'Boolean'>,
  notifyPatient: $ElementType<Scalars, 'Boolean'>,
  remindPatient24h: $ElementType<Scalars, 'Boolean'>,
  remindPatient1h: $ElementType<Scalars, 'Boolean'>,
};


export type CreateAppointmentAdminMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { createAppointmentAdmin?: * }>
});

export type CreateExternalAppointmentAdminMutationVariables = {
  patientId: $ElementType<Scalars, 'String'>,
  startTime: $ElementType<Scalars, 'Long'>,
  durationInMinutes: $ElementType<Scalars, 'Int'>,
  appointmentDescription: $ElementType<Scalars, 'String'>,
  patientInstructions?: ?$ElementType<Scalars, 'String'>,
  externalProviderCrmId: $ElementType<Scalars, 'String'>,
};


export type CreateExternalAppointmentAdminMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { createExternalAppointmentAdmin?: * }>
});

export type AssignedProvidersForPatientQueryVariables = {
  patientId: $ElementType<Scalars, 'String'>,
};


export type AssignedProvidersForPatientQuery = ({
    ...{ __typename?: 'Query' },
  ...{ assignedProvidersForPatient: Array<({
      ...{ __typename?: 'ExternalProvider' },
    ...$Pick<ExternalProvider, { id: *, displayName: *, providerType?: *, title?: *, addressLines: * }>
  })> }
});

export type PatientIdByExternalIdQueryVariables = {
  externalId: $ElementType<Scalars, 'String'>,
  patientIdType: PatientIdType,
};


export type PatientIdByExternalIdQuery = ({
    ...{ __typename?: 'Query' },
  ...$Pick<Query, { patientIdByExternalId?: * }>
});

export type GetEligiblePractitionersQueryVariables = {
  appointmentType: AppointmentType,
  appointmentMedium: AppointmentMedium,
  supportingPractitionerType?: ?PractitionerType,
  filterByClinicIds: Array<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
};


export type GetEligiblePractitionersQuery = ({
    ...{ __typename?: 'Query' },
  ...{ eligiblePractitioners: ({
      ...{ __typename?: 'EligiblePractitioners' },
    ...{ leadPractitioners: Array<({
        ...{ __typename?: 'Practitioner' },
      ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: *, avatarUrl: *, licensedStates: * }>
    })>, supportingPractitioners: Array<({
        ...{ __typename?: 'Practitioner' },
      ...$Pick<Practitioner, { id: *, displayName: *, practitionerType: *, avatarUrl: *, licensedStates: * }>
    })> }
  }) }
});

export type GetAllClinicsQueryVariables = {};


export type GetAllClinicsQuery = ({
    ...{ __typename?: 'Query' },
  ...{ clinics: Array<({
      ...{ __typename?: 'Clinic' },
    ...$Pick<Clinic, { id: *, name: *, addressLines: * }>
  })> }
});

export type ClinicForIdQueryQueryVariables = {
  clinicId: $ElementType<Scalars, 'String'>,
};


export type ClinicForIdQueryQuery = ({
    ...{ __typename?: 'Query' },
  ...{ clinic?: ?({
      ...{ __typename?: 'Clinic' },
    ...$Pick<Clinic, { id: *, name: *, addressLines: * }>
  }) }
});

export type ZoomMeetingForIdQueryQueryVariables = {
  appointmentId: $ElementType<Scalars, 'String'>,
};


export type ZoomMeetingForIdQueryQuery = ({
    ...{ __typename?: 'Query' },
  ...{ zoomMeeting: ({
      ...{ __typename?: 'ZoomMeeting' },
    ...$Pick<ZoomMeeting, { id: *, joinUrl: *, hostEmail: * }>,
    ...{ settings?: ?({
        ...{ __typename?: 'ZoomMeetingSettings' },
      ...$Pick<ZoomMeetingSettings, { alternativeHosts: * }>
    }) }
  }) }
});

export type AddStaffAsHostMutationVariables = {
  staffId: $ElementType<Scalars, 'String'>,
  appointmentId: $ElementType<Scalars, 'String'>,
};


export type AddStaffAsHostMutation = ({
    ...{ __typename?: 'Mutation' },
  ...$Pick<Mutation, { tryAddStaffAsAlternativeHost?: * }>
});

export type GetAppointmentTypeConfigurationsQueryVariables = {};


export type GetAppointmentTypeConfigurationsQuery = ({
    ...{ __typename?: 'Query' },
  ...{ appointmentTypeConfigurations: Array<({
      ...{ __typename?: 'AppointmentTypeConfiguration' },
    ...$Pick<AppointmentTypeConfiguration, { appointmentType: *, internalName: *, leadPractitionerType?: *, durationInMinutes?: *, supportingPractitionerTypes: *, supportedMediums: * }>
  })> }
});

export type GetAllStaffSchedulesQueryVariables = {};


export type GetAllStaffSchedulesQuery = ({
    ...{ __typename?: 'Query' },
  ...{ staffSchedules: Array<({
      ...{ __typename?: 'StaffSchedule' },
    ...$Pick<StaffSchedule, { id: *, staffId: *, staffDisplayName: * }>,
    ...{ plannedSchedules: Array<({
        ...{ __typename?: 'PlannedSchedule' },
      ...$Pick<PlannedSchedule, { inEffectAfter?: * }>,
      ...{ weeklySchedule: Array<({
          ...{ __typename?: 'DailySchedule' },
        ...$Pick<DailySchedule, { dayOfWeek: *, hours: * }>
      })>, alternateWeeklySchedule?: ?Array<({
          ...{ __typename?: 'DailySchedule' },
        ...$Pick<DailySchedule, { dayOfWeek: *, hours: * }>
      })> }
    })> }
  })> }
});

export type DailyScheduleFieldsFragment = ({
    ...{ __typename?: 'DailySchedule' },
  ...$Pick<DailySchedule, { dayOfWeek: *, hours: * }>
});
