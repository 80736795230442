// @flow
import React, {useEffect, useState} from "react";
import {
  Typography,
  Box,
  Grid,
  CircularProgress,
  Button,
  Link,
  Snackbar,
  Breadcrumbs, Paper
} from "@mui/material";
import type {Patient} from "../../../flow-typed/graphql/patient";
import {useQuery} from "@apollo/client/react/hooks";
import DetailedAlert from "../../DetailedAlert";
import gql from "graphql-tag";
import {useMutation} from "@apollo/client";
import {LoadingButton} from "@mui/lab";
import SelfImprovementOutlinedIcon from "@mui/icons-material/SelfImprovementOutlined";
import {maxPatientScreenWidth} from "../../../theme";

type Props = {
  patient: Patient
};

const KEMTAI_PATIENT_INFO = gql`
    query getKemtaiPatientInfo($patientId: String!) {
        kemtaiPatientInfo(patientId: $patientId) {
            kemtaiPatientId
            kemtaiPatientProfileUrl
        }
    }
`;

const KEMTAI_PATIENT_LOGIN_URL_MUTATION = gql`
    mutation createKemtaiPatientLoginUrl($kemtaiPatientId: String!) {
        createKemtaiPatientLoginUrl(kemtaiPatientId: $kemtaiPatientId)
    }
`;

const KEMTAI_CREATE_PATIENT_MUTATION = gql`
    mutation createKemtaiPatient($patientId: String!) {
        createKemtaiPatient(patientId: $patientId) {
            kemtaiPatientId
            kemtaiPatientProfileUrl
        }
    }
`;

export default function PatientExercises(props: Props) {
  const [showCopiedToast, setShowCopiedToast] = useState(false);
  const {data, loading, error, refetch} = useQuery(KEMTAI_PATIENT_INFO, {
    variables: {
      patientId: props.patient.id
    },
    notifyOnNetworkStatusChange: true,
  });
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const [createKemtaiPatient, {loading: createLoading, error: createError}] = useMutation(KEMTAI_CREATE_PATIENT_MUTATION, {
    variables: {
      patientId: props.patient.id
    },
    // Update the cache with the new Kemtai patient info
    update(cache, {data: {createKemtaiPatient}}) {
      cache.writeQuery({
        query: KEMTAI_PATIENT_INFO,
        variables: {
          patientId: props.patient.id
        },
        data: {
          kemtaiPatientInfo: createKemtaiPatient
        }
      });
    }
  });

  const [createKemtaiPatientLoginUrl, {data: urlData, loading: urlLoading, error: urlError}] = useMutation(KEMTAI_PATIENT_LOGIN_URL_MUTATION, {
    variables: {
      kemtaiPatientId: data?.kemtaiPatientInfo?.kemtaiPatientId
    },
  });

  useEffect(() => {
    setShowErrorSnackbar(!!urlError || !!createError);
  }, [urlError, createError]);

  if (loading) {
    return (
      <WithHeader>
        <CircularProgress/>
      </WithHeader>
    );
  }

  if (error) {
    return (
      <WithHeader>
        <DetailedAlert
          message="Oops! Failed to retrieve Kemtai info"
          additionalDetails={error}
          retry={_ => refetch()}
        />
      </WithHeader>
    );
  }

  if (!data?.kemtaiPatientInfo) {
    return (
      <WithHeader>
        <Snackbar open={showErrorSnackbar} onClose={_ => setShowErrorSnackbar(false)}>
          <Box>
            <DetailedAlert
              message="Oops! Failed to create Kemtai patient."
              additionalDetails={createError}
              retry={_ => createKemtaiPatient().catch(_ => {})}
            />
          </Box>
        </Snackbar>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="body2">
            This patient does not have a Kemtai profile yet.
          </Typography>
          <LoadingButton
            variant="contained"
            loading={createLoading}
            onClick={createKemtaiPatient}
          >
            Create Kemtai Profile
          </LoadingButton>
        </Box>
      </WithHeader>
    );
  }

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(urlData.createKemtaiPatientLoginUrl)
      .then(setShowCopiedToast(true))
  }

  return (
    <WithHeader>
      <Snackbar
        open={showErrorSnackbar}
        onClose={_ => setShowErrorSnackbar(false)}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <Box>
          <DetailedAlert
            message="Oops! Failed to create login URL."
            additionalDetails={createKemtaiPatientLoginUrl}
            retry={_ => createKemtaiPatientLoginUrl().catch(_ => {})}
          />
        </Box>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body1">
            Kemtai Profile URL
          </Typography>
          <Typography variant="caption">
            (Only for <strong>Motion provider</strong> use)
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Link href={data.kemtaiPatientInfo.kemtaiPatientProfileUrl} target="_blank">
            {data.kemtaiPatientInfo.kemtaiPatientProfileUrl}
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1">
            Kemtai Patient Login URL
          </Typography>
          <Typography variant="caption">
            (Only share with <strong>this</strong> patient)
          </Typography>
        </Grid>
        <Grid item xs={9}>
          {!urlData?.createKemtaiPatientLoginUrl ? (
            <LoadingButton
              variant="contained"
              loading={urlLoading}
              onClick={createKemtaiPatientLoginUrl}
            >
              Create Login URL
            </LoadingButton>
          ) : (
            <Box display="flex">
              <Box flexShrink={1} sx={{overflow: "hidden", textOverflow: "ellipsis"}}>
                <Typography variant="body2" color="text.secondary" noWrap>
                  {urlData.createKemtaiPatientLoginUrl}
                </Typography>
              </Box>
              <Box mx={2} flexShrink={0}>
                <Button onClick={copyToClipboard} variant="outlined" size="small">
                  Copy to Clipboard
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={showCopiedToast}
        autoHideDuration={5000}
        onClose={_ => setShowCopiedToast(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        message="Copied to clipboard"
      />
    </WithHeader>

  );
}

const WithHeader = (props) => {
  return (
    <Box display="flex" flex={1}  justifyContent="center">
      <Box p={2} display="flex" flexDirection="column" gap={2} flex={1} maxWidth={maxPatientScreenWidth}>
        <Breadcrumbs>
          <Link sx={{ display: 'flex', alignItems: 'center', gap:1 }} component="button" color="inherit" underline="none">
            <SelfImprovementOutlinedIcon sx={{height: 18, width: 18}} />
            Exercises
          </Link>
        </Breadcrumbs>
        <Paper sx={{padding: 2, display: 'flex', flexDirection: 'column', gap: 1}}>
          <Typography variant="h6" color="primary">
            Kemtai Patient Info
          </Typography>
          {props.children}
        </Paper>
      </Box>
    </Box>
  );
}
