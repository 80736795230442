import React, { Component } from "react";
import {Button, Card, CardContent} from "@mui/material";
import { Alert } from '@mui/material';

// in case the server and client clocks are out of sync
const CLOCK_DRIFT_MS = 60 * 1000;

const CHECK_INTERVAL_MS = 60 * 60 * 1000;

/**
 * periodically fetch the last modified header from the origin and alert
 * if it is newer than the time this component was loaded
 */
class AppRefresh extends Component {
  state = {
    loaded: new Date().getTime(),
    outdated: false
  };

  componentDidMount() {
    this.timerHandle = setInterval(this.check, CHECK_INTERVAL_MS);
  }

  componentWillUnmount() {
    this.removeTimer();
  }

  removeTimer = () => {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
    }
  };

  check = () => {
    fetch("/", {
      method: "HEAD",
      cache: "no-store"
    })
      .then(response => {
        const lastModifiedStr = response.headers.get("Last-Modified");
        if (!lastModifiedStr) {
          return;
        }

        const lastModified = Date.parse(lastModifiedStr);

        if (lastModified > this.state.loaded + CLOCK_DRIFT_MS) {
          console.log("setting outdated");
          this.removeTimer();
          this.setState({ outdated: true });
        }
      })
      .catch(err => {
        console.log("failed to fetch", err);
      });
  };

  reload = () => {
    window.location.reload(true);
  };

  render() {
    if (!this.state.outdated) {
      return null;
    }

    return (
      <Card>
        <CardContent>
          <Alert severity="error">
            <span>You are using an old version </span>
            <Button onClick={this.reload}>
              Reload
            </Button>
          </Alert>
        </CardContent>
      </Card>
    );
  }
}

export default AppRefresh;
