import { AppointmentMedium, AppointmentType } from "../../../../gql/graphql";
import { Box, Typography } from "@mui/material";
import { sortBy, uniqueId, values } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { AddEditSlotProps } from "./index";
import {
  LocalAppointmentSlot,
  LocalSlot,
} from "../../../../libs/scheduleTemplates";
import SelectMediums, { validateMediums } from "../SelectMediums";
import SelectAppointmentType from "../../../SelectAppointmentType";

type TentativeAppointmentSlotErrors = {
  appointmentTypeError?: string;
  mediumsError?: string;
  clinicError?: string;
};

/**
 * Subcomponent of the AddEditSlotDialog, handling "appointment" slots.
 */
export default function AppointmentSlotBuilder(
  props: AddEditSlotProps & {
    onSlotChange: (validSlot: LocalAppointmentSlot | null) => void;
  },
) {
  const startingSlot: LocalSlot | null = useMemo(
    () => findInitialSlot(props),
    [],
  );
  const [appointmentType, setAppointmentType] =
    useState<AppointmentType | null>(() => {
      if (startingSlot?.type === "appointment" && props.operation === "edit") {
        return startingSlot.appointmentType;
      }
      return null;
    });
  const [mediums, setMediums] = useState<ReadonlySet<AppointmentMedium>>(() => {
    if (startingSlot?.type === "appointment") {
      return new Set(startingSlot.supportedMediums);
    }
    return new Set();
  });
  const [locationId, setLocationId] = useState<string | null>(() => {
    if (startingSlot?.type === "appointment") {
      return startingSlot.locationId || null;
    }
    return null;
  });
  const [errors, setErrors] = useState<TentativeAppointmentSlotErrors>({});

  useEffect(() => {
    const results = tryBuildValidSlot(
      props,
      appointmentType,
      mediums,
      locationId,
    );
    props.onSlotChange(results.validSlot);
    setErrors(results.errors);
  }, [appointmentType, mediums, locationId]);

  const handleChangeAppointmentType = (appointmentType: AppointmentType) => {
    setAppointmentType(appointmentType);
    // Note: we don't clear the locationId to avoid clearing the dropdown.
    // It is excluded from the validSlot if the mediums don't include InClinic.
    if (!(appointmentType in props.appointmentTypeConfigurations)) {
      setMediums(new Set());
      return;
    }

    const config = props.appointmentTypeConfigurations[appointmentType]!;
    const autoSelectedMediums = new Set(config.supportedMediums);
    // Don't auto-select AtHome, mutually exclusive with others.
    autoSelectedMediums.delete(AppointmentMedium.AtHome);
    setMediums(
      adjustedForProvider(autoSelectedMediums, props.providerSupportsVirtual),
    );
  };

  const handleChangeMedium = (
    mediums: ReadonlySet<AppointmentMedium>,
    locationId: string | null,
  ) => {
    setMediums(mediums);
    setLocationId(locationId);
  };

  const allowedMediums: ReadonlySet<AppointmentMedium> = (() => {
    if (
      !appointmentType ||
      !(appointmentType in props.appointmentTypeConfigurations)
    ) {
      return adjustedForProvider(
        new Set<AppointmentMedium>(values(AppointmentMedium)),
        props.providerSupportsVirtual,
      );
    }
    return adjustedForProvider(
      new Set(
        props.appointmentTypeConfigurations[appointmentType]!.supportedMediums,
      ),
      props.providerSupportsVirtual,
    );
  })();

  return (
    <>
      <SelectAppointmentType
        beta={true}
        value={appointmentType}
        onChange={handleChangeAppointmentType}
        error={!!errors.appointmentTypeError}
        helperText={errors.appointmentTypeError}
      />
      <Box pt={2} />
      {!props.providerSupportsVirtual && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ marginBottom: 1 }}
        >
          Note: this provider does not support virtual meetings.
        </Typography>
      )}
      <SelectMediums
        allowedMediums={allowedMediums}
        mediums={mediums}
        locationId={locationId}
        onChange={handleChangeMedium}
        clinicError={errors.clinicError}
        mediumError={errors.mediumsError}
      />
    </>
  );
}

/**
 * Build a valid slot from the current state and the initial props.
 * If a valid slot cannot be built, the returned slot will be null and
 * errors will be populated.
 */
function tryBuildValidSlot(
  props: AddEditSlotProps,
  appointmentType: AppointmentType | null,
  mediums: ReadonlySet<AppointmentMedium>,
  locationId: string | null,
): {
  validSlot: LocalAppointmentSlot | null;
  errors: TentativeAppointmentSlotErrors;
} {
  let appointmentTypeError;
  if (!appointmentType) {
    appointmentTypeError = "Appointment type is required";
  }
  const mediumErrors = validateMediums(mediums, locationId);

  if (
    !!appointmentTypeError ||
    !!mediumErrors.mediumsError ||
    !!mediumErrors.clinicError
  ) {
    return {
      validSlot: null,
      errors: {
        appointmentTypeError,
        ...mediumErrors,
      },
    };
  }

  if (props.operation === "edit") {
    const sourceSlot = props.slots[props.editSlotId]!;
    return {
      validSlot: {
        id: sourceSlot.id,
        type: "appointment",
        dayOfWeek: sourceSlot.dayOfWeek,
        startMinutes: sourceSlot.startMinutes,
        endMinutes:
          sourceSlot.startMinutes +
          props.appointmentTypeConfigurations[appointmentType!]!
            .durationInMinutes,
        appointmentType: appointmentType!,
        locationId: mediums.has(AppointmentMedium.InClinic) ? locationId : null,
        supportedMediums: Array.from(mediums),
      },
      errors: {},
    };
  }

  return {
    validSlot: {
      id: uniqueId(),
      type: "appointment",
      dayOfWeek: props.dayOfWeek,
      startMinutes: props.startMinutes,
      endMinutes:
        props.startMinutes +
        props.appointmentTypeConfigurations[appointmentType!]!
          .durationInMinutes,
      appointmentType: appointmentType!,
      locationId: mediums.has(AppointmentMedium.InClinic) ? locationId : null,
      supportedMediums: Array.from(mediums),
    },
    errors: {},
  };
}

/**
 * Find the slot used to populate the initial state.
 * Either the slot being edited, or the previous slot if adding a new slot.
 */
function findInitialSlot(props: AddEditSlotProps): LocalSlot | null {
  if (props.operation === "edit") {
    return props.slots[props.editSlotId]!;
  }
  // Add operation, start from the previous appointment slot
  const allSlots = values(props.slots);
  const previousAppointmentSlots = allSlots.filter(
    (slot) => slot.type === "appointment" && compareSlots(slot, props) < 0,
  );
  if (previousAppointmentSlots.length === 0) {
    return allSlots.length === 0 ? null : allSlots[0];
  }

  const sortedPreviousSlots = sortBy(
    previousAppointmentSlots,
    (slot) => slot.dayOfWeek,
    (slot) => slot.startMinutes,
  );
  return sortedPreviousSlots[sortedPreviousSlots.length - 1];
}

/**
 * Compare two slots by day of week and start time.
 * Returns a negative number if a comes before b, a positive number if a comes after b,
 * and 0 if they are the same.
 */
function compareSlots(
  a: { dayOfWeek: number; startMinutes: number },
  b: { dayOfWeek: number; startMinutes: number },
) {
  return a.dayOfWeek - b.dayOfWeek || a.startMinutes - b.startMinutes;
}

function adjustedForProvider(
  allowedMediums: ReadonlySet<AppointmentMedium>,
  providerSupportsVirtual: boolean,
): ReadonlySet<AppointmentMedium> {
  const adjusted = new Set(allowedMediums);
  if (!providerSupportsVirtual) {
    adjusted.delete(AppointmentMedium.Virtual);
  }
  return adjusted;
}
