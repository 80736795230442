import { ScheduleFieldsFragment } from "../../../gql/graphql";
import {
  Box,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import ScheduleHeader from "../ScheduleHeader";
import React, { useEffect, useState } from "react";
import {
  formatTimezone,
  LocalSlot,
  toLocalSlots,
} from "../../../libs/scheduleTemplates";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { grey } from "@mui/material/colors";
import WeeklySlotsView from "./WeeklySlotsView";
import { useQuery } from "@apollo/client/react/hooks";
import DetailedAlert from "../../DetailedAlert";
import { keyBy } from "lodash";
import { GET_APPOINTMENT_TYPE_CONFIG } from "../../SelectAppointmentType";
import { maxScheduleHour, minScheduleHour } from "../../../libs/time";

type Props = {
  schedule: ScheduleFieldsFragment;
};

export default function ScheduleViewer(props: Props) {
  const [focusedWeek, setFocusedWeek] = useState<"primary" | "alternate">(
    "primary",
  );
  const [displayedSlots, setDisplayedSlots] = useState<Array<LocalSlot>>(
    toLocalSlots(props.schedule.weeklySchedule),
  );
  const { data, loading, error, refetch } = useQuery(
    GET_APPOINTMENT_TYPE_CONFIG,
    {
      variables: {
        beta: true,
      },
    },
  );

  useEffect(() => {
    // Reset everything when props change.
    setFocusedWeek("primary");
    setDisplayedSlots(toLocalSlots(props.schedule.weeklySchedule));
  }, [props.schedule]);

  const handleChangeWeekFocus = (focusedWeek: "primary" | "alternate") => {
    setFocusedWeek(focusedWeek);
    if (focusedWeek === "primary") {
      setDisplayedSlots(toLocalSlots(props.schedule.weeklySchedule));
    } else {
      setDisplayedSlots(toLocalSlots(props.schedule.alternateWeeklySchedule!));
    }
  };

  if (error) {
    return (
      <DetailedAlert
        message="Oops! Something went wrong. Please try again."
        additionalDetails={error}
        retry={() => refetch()}
      />
    );
  }

  if (loading || !data) {
    return <CircularProgress />;
  }

  const appointmentTypeConfigurations = keyBy(
    data.appointmentTypeConfigurations,
    (config) => config.appointmentType,
  );
  const formattedTimezone = formatTimezone(props.schedule.timezone);

  return (
    <Box display="flex" flexDirection="column" minHeight={0}>
      <ScheduleHeader schedule={props.schedule} />
      <Box display="flex" gap={2} alignItems="center">
        {props.schedule.alternateWeeklySchedule !== null && (
          <>
            <Tabs
              value={focusedWeek}
              onChange={(_, value) => handleChangeWeekFocus(value)}
            >
              <Tab label="Week A" value="primary" />
              <Tab label="Week B" value="alternate" />
            </Tabs>
            <Tooltip
              title={
                <>
                  <Typography variant="caption">
                    Starting from the first day of each month:
                  </Typography>
                  <ul style={{ margin: 0, paddingLeft: 15 }}>
                    <li>
                      <Typography variant="caption">
                        Week A: 1st, 3rd, 5th week of the month
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="caption">
                        Week B: 2nd, 4th week of the month
                      </Typography>
                    </li>
                  </ul>
                </>
              }
            >
              <HelpOutlineIcon fontSize="small" sx={{ color: grey[500] }} />
            </Tooltip>
          </>
        )}
        <Box flex={1} />
        <Typography
          variant="body2"
          color={
            formattedTimezone.differentFromLocal ? "error" : "text.secondary"
          }
          m={1}
        >
          All times shown in <strong>{formattedTimezone.label}</strong>
        </Typography>
      </Box>
      <Divider />
      <WeeklySlotsView
        weeklySlots={displayedSlots}
        minHour={minScheduleHour}
        maxHour={maxScheduleHour}
        appointmentTypeConfigurations={appointmentTypeConfigurations}
      />
    </Box>
  );
}
