import {
  AppointmentType,
  EffectiveTimeSlotFieldsFragment,
} from "../gql/graphql";
import { forOwn, mapValues, sortBy } from "lodash";
import { grey } from "@mui/material/colors";
import { LocalSlot } from "./scheduleTemplates";

/**
 * Returns the color group for the given appointment type.
 * Appointments types likely to be viewed together are grouped together.
 */
function getAppointmentTypeColorGroup(
  appointmentType: AppointmentType,
): string {
  switch (appointmentType) {
    case AppointmentType.CareNavigator:
    case AppointmentType.CareNavigatorFollowup:
    case AppointmentType.ZoomPractice:
      return "care navigators";

    case AppointmentType.HealthCoach:
    case AppointmentType.HealthCoachCheckin:
    case AppointmentType.HealthCoachFollowup:
      return "health coaches";

    case AppointmentType.NewPatient:
    case AppointmentType.Followup:
    case AppointmentType.L3:
    case AppointmentType.L3Followup:
      return "L3, MD, PA";

    case AppointmentType.Pt:
    case AppointmentType.PtFollowup:
    case AppointmentType.PtFollowupMultiBodyPart:
    case AppointmentType.PtReevaluation:
      return "PT";

    case AppointmentType.MedManagement:
    case AppointmentType.MedManagementFollowup:
      return "Pain specialist";

    // Everything else, included sunsetted types
    case AppointmentType.Specialist:
    case AppointmentType.External:
    case AppointmentType.HealthCoachSpecialist:
    case AppointmentType.HealthCoachSpecialistFollowup:
    case AppointmentType.PainCoach:
    case AppointmentType.PainCoachFollowup:
    case AppointmentType.L3WithMd:
    case AppointmentType.L3WithMdFollowup:
    case AppointmentType.L3WithPa:
    case AppointmentType.L3WithPaFollowup:
    case AppointmentType.Md:
    case AppointmentType.MdFollowup:
    case AppointmentType.Motion_360:
    case AppointmentType.OpioidTaperFollowupMd:
    case AppointmentType.OpioidTaperFollowupPa:
    case AppointmentType.OpioidTaperMd:
    case AppointmentType.OpioidTaperPa:
    case AppointmentType.Pa:
    case AppointmentType.PaFollowup:
      return "everything else";
  }
}

/**
 * A function to convert a string to a hash.
 * DJB2, not cryptographically secure, but helpful to randomize
 * deterministically.
 */
function stringToHash(str: string): number {
  let hash = 7159; // 5381; // Prime number to start
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    // Bitwise left shift with overflow and add character's Unicode value
    hash = (hash << 5) + hash + char;
    // Convert to 32bit integer
    hash = hash & hash;
  }
  // Convert to positive and ensure it is a 32-bit integer
  return hash >>> 0;
}

/**
 * Exported for testing only.
 */
export const appointmentColorGroups = (() => {
  const groups: { [key: string]: Array<AppointmentType> } = {};
  Object.values(AppointmentType).forEach((type) => {
    const group = getAppointmentTypeColorGroup(type);
    groups[group] = groups[group] ? [...groups[group], type] : [type];
  });
  return mapValues(groups, (types) =>
    sortBy(types, (type) => stringToHash(type)),
  );
})();

/**
 * For each group of appointment types, generate a palette of hues.
 * For small groups, the palette is narrower to keep it aesthetically pleasing.
 * Large groups use the full hue spectrum.
 * Colors might overlap between groups, but appointment types from
 * different groups are unlikely to be viewed together.
 */
const appointmentTypePalette = (() => {
  const colors: { [key: string]: number } = {};

  forOwn(appointmentColorGroups, (group, groupName) => {
    let currentHue = stringToHash(groupName) % 300;
    const hueStep = 300 / group.length > 60 ? 60 : 300 / group.length;
    group.forEach((type) => {
      colors[type] = currentHue;
      currentHue = Math.ceil(currentHue + hueStep);
    });
  });
  return colors as { [key in AppointmentType]: number };
})();

/**
 * Returns the color for the given appointment type.
 */
export function getAppointmentTypeColor(
  appointmentType: AppointmentType,
  variant: "main" | "dark" | "light" = "main",
  transparency: number = 1,
): string {
  const hue = appointmentTypePalette[appointmentType];
  const { saturation, lightness } =
    variant === "dark"
      ? {
          saturation: 55,
          lightness: 75,
        }
      : variant === "light"
      ? {
          saturation: 40,
          lightness: 95,
        }
      : {
          saturation: 45,
          lightness: 85,
        };

  return `hsla(${hue}, ${saturation}%, ${lightness}%, ${transparency})`;
}
/**
 * Returns the background color for the given time slot.
 */
export function getSlotBackgroundColor(slot: LocalSlot): string {
  if (slot.type === "unavailable") {
    return grey[100];
  }

  return getAppointmentTypeColor(slot.appointmentType, "main");
}

export function getEffectiveSlotBackgroundColor(
  effectiveSlot: EffectiveTimeSlotFieldsFragment,
): string {
  if (effectiveSlot.__typename === "EffectiveAppointmentSlot") {
    return getAppointmentTypeColor(effectiveSlot.appointmentType, "light");
  }

  return grey[100];
}
