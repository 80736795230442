// @flow checked 01/24/23
import React from "react";
import {useQuery} from "@apollo/client";
import {groupBy, some} from "lodash";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Alert, Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import {AVAILABLE_APPOINTMENT_SLOTS} from "../../../../../../../libs/queries";
import DetailedAlert from "../../../../../../DetailedAlert";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Slot from "../../../Slot";
import type {AppointmentSlot} from "../../../../../../../generated/flow_types";
import type {DraftAppointment} from "../../../Stepper";
import {notNull} from "../../../../../../../libs/util";
import {DateTime} from "luxon";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
  appointmentDetails: DraftAppointment,
  displayTimezone: string,
  onSlotChange: (?AppointmentSlot) => void
};

const AvailableAppointments = (props: Props) => {
  const {
    appointmentMedium,
    selectedAppointmentConfig,
    filterByClinicIds,
    filterByTimeOfDay,
    filterByLeadPractitionerIds,
    filterBySupportingPractitionerIds,
    searchFromDay,
    selectedSlot,
    supportingPractitionerType,
  } = props.appointmentDetails;

  const {data, error, loading, refetch} = useQuery(
    AVAILABLE_APPOINTMENT_SLOTS,
    {
      variables: {
        appointmentMedium,
        appointmentType: notNull(selectedAppointmentConfig).appointmentType,
        filterByClinicIds,
        filterByLeadPractitionerIds,
        filterByTimeOfDay,
        filterBySupportingPractitionerIds,
        searchFromDay,
        supportingPractitionerType: supportingPractitionerType,
      },
      fetchPolicy: "network-only",
    }
  );

  const handleSelectedSlot = (slot: AppointmentSlot) => {
    if (slot.id === selectedSlot?.id) {
      props.onSlotChange(null);
      return;
    }
    props.onSlotChange(slot);
  };

  if (loading) {
    return (
      <Box my={2} sx={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress/>
      </Box>
    );
  }

  if (error || !data) {
    return (
      <DetailedAlert
        message="Oops! Failed to get Available Slots. Please try again."
        retry={() => refetch()}
        additionalDetails={error}
      />
    );
  }

  const arrayToSort = [...data.availableAppointmentSlotsAdmin];

  const sortedList = arrayToSort?.sort((a, b) => a.startTime - b.startTime);

  const groups = groupBy(sortedList, (slot) =>
    DateTime
      .fromSeconds(slot.startTime, {zone: props.displayTimezone})
      .toISODate()
  );

  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      slots: groups[date],
    };
  });

  if (!groupArrays.length) {
    return (
      <Box my={2}>
        <Alert severity="warning">
          No available slots found. Try searching with different filters or for
          the next week.
        </Alert>
      </Box>
    );
  }

  return (
    <div style={{marginTop: 16}}>
      {groupArrays.map((group) => (
        <Group
          key={group.date}
          day={group.date}
          slots={group.slots}
          onSlotClick={handleSelectedSlot}
          displayTimezone={props.displayTimezone}
          selectedSlotId={selectedSlot?.id}
        />
      ))}
      <Box m={1}>
        <Typography variant="body2" fontStyle="italic" color="gray">
          Filter by date to see more slots
        </Typography>
      </Box>
    </div>
  );
};

export default AvailableAppointments;

type GroupProps = {
  day: string, // yyyy-mm-dd
  slots: AppointmentSlot[],
  onSlotClick: (AppointmentSlot) => void,
  displayTimezone: string,
  selectedSlotId: ?string,
};

const Group = (props: GroupProps) => {
  const groupDay = DateTime.fromISO(props.day, {zone: props.displayTimezone});
  const isToday = DateTime.now().hasSame(groupDay, 'day');
  const isTomorrow = DateTime.now().plus({day: 1}).hasSame(groupDay, 'day');

  const hasSelectedDay = props.selectedSlotId && some(props.slots, slot => slot.id === props.selectedSlotId);

  return (
    <Accordion defaultExpanded={hasSelectedDay}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        id={`group-${props.day}-header`}
        aria-controls={`group-${props.day}-content`}
      >
        <Typography color="primary">
          {isToday
            ? "Today"
            : isTomorrow
              ? "Tomorrow"
              : groupDay.toLocaleString({weekday: 'long'})}
          <span style={{color: "#8BA5CD", margin: "0 8px"}}>
            {groupDay.toLocaleString(DateTime.DATE_SHORT)}
            <FiberManualRecordIcon sx={{fontSize: 8, margin: "0 12px"}}/>
            {props.slots.length} available slot{props.slots.length > 1 ? "s" : ""}
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails id={`group-${props.day}-content`}>
        {props.slots.map((slot) => (
          <Slot
            key={slot.id}
            appointmentMedium={slot.appointmentMedium}
            clinic={slot.clinic}
            startTime={slot.startTime}
            endTime={slot.endTime}
            leadPractitioner={slot.leadPractitioner}
            supportingPractitioner={slot.supportingPractitioner}
            onClick={() => props.onSlotClick(slot)}
            showDate={false}
            displayTimezone={props.displayTimezone}
            selected={props.selectedSlotId === slot.id}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
