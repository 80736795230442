// @flow checked 01/24/23
import React from "react";
import { AppointmentTypeValues } from "../../../../../generated/flow_types";
import External from "./External";
import Internal from "./Internal";
import type {DraftAppointment} from "../Stepper";
import type {AppointmentTypeConfiguration, ExternalProvider, Patient} from "../../../../../generated/flow_types";
import type {Dispatch, SetStateAction} from "../../../../../flow-typed/react";

type Props = {
  appointmentConfig: AppointmentTypeConfiguration,
  patient: Patient,
  appointmentDetails: DraftAppointment,
  onStartDayChange: (string) => void,
  onStartTimeChange: (string) => void,
  onDurationChange: (durationInMinutes: number) => void,
  onExternalProviderChange: (ExternalProvider) => void,
  setAppointmentDetails: Dispatch<SetStateAction<DraftAppointment>>,
  displayTimezone: string,
};

const SecondStep = (props: Props) => {
  const isExternal =
    props.appointmentConfig.appointmentType ===
    AppointmentTypeValues.External;

  return (
    <div>
      {isExternal ? (
        <External
          appointmentDetails={props.appointmentDetails}
          patient={props.patient}
          onStartDayChange={props.onStartDayChange}
          onStartTimeChange={props.onStartTimeChange}
          onDurationChange={props.onDurationChange}
          onExternalProviderChange={props.onExternalProviderChange}
          displayTimezone={props.displayTimezone}/>
      ) : (
        <Internal
          appointmentDetails={props.appointmentDetails}
          patient={props.patient}
          setAppointmentDetails={props.setAppointmentDetails}
          displayTimezone={props.displayTimezone}
        />
      )}
    </div>
  );
};

export default SecondStep;
