// @flow

import React from "react";
import { Alert, FormControl, Grid, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { TabNames } from "../initialState";
import { AppointmentTypeValues } from "../../../../../generated/flow_types";
import type {
  AppointmentTypeConfiguration,
  Patient,
} from "../../../../../generated/flow_types";
import type {
  FunctionComponent,
  Dispatch,
  SetStateAction,
} from "../../../../../flow-typed/react";
import type {
  Notification,
  ToggleEvent,
} from "../../PatientNotificationPreferences";

import PatientNotifications from "../../PatientNotificationPreferences";

type Props = {
  appointmentDetails: {
    appointmentDescription: string,
    selectedAppointmentConfig: AppointmentTypeConfiguration,
    notifyPatient: boolean,
    patientInstructions: string,
    remindPatient1h: boolean,
    remindPatient24h: boolean,
    startDay: string,
    startTime: string,
    tab: number,
  },
  patient: Patient,
  setAppointmentDetails: Dispatch<SetStateAction<any>>,
};

const ThirdStep: FunctionComponent<Props> = (props: Props) => {
  const { patient, appointmentDetails, setAppointmentDetails } = props;
  const { patientInstructions } = appointmentDetails;
  const isExternal =
    appointmentDetails.selectedAppointmentConfig.appointmentType ===
    AppointmentTypeValues.External;
  const isSpecificTime = appointmentDetails.tab === TabNames.specificTimes;

  const handleTextField = ({ target: { name, value } }) => {
    setAppointmentDetails((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSwitch = (args: { checked: boolean, name: string }) => {
    setAppointmentDetails((state) => ({
      ...state,
      [args.name]: args.checked,
    }));
  };

  const isRetroactive =
    DateTime.fromFormat(
      `${appointmentDetails.startDay} ${appointmentDetails.startTime}`,
      "yyyy-MM-dd h:mm a"
    ) < DateTime.now() && isSpecificTime;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {isRetroactive && (
            <Alert severity="warning" style={{ marginBottom: "48px" }}>
              No communication will be sent to the patient since the appointment
              is retroactive.
            </Alert>
          )}
          <FormControl fullWidth>
            <TextField
              disabled={isRetroactive}
              label="Appointment Description"
              name="appointmentDescription"
              onChange={handleTextField}
              variant="outlined"
              value={appointmentDetails.appointmentDescription}
            />
          </FormControl>
        </Grid>
        <Grid item mb={2} xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={isRetroactive}
              error={patientInstructions.length > 1024}
              helperText={`${patientInstructions.length}/1024 characters`}
              label="Patient Instructions (optional)"
              multiline
              name="patientInstructions"
              onChange={handleTextField}
              rows={3}
              value={appointmentDetails.patientInstructions}
            />
          </FormControl>
        </Grid>
      </Grid>
      {(() => {
        if (isRetroactive) return <></>;
        let disabledReason: ?string = null;
        if (isExternal) {
          disabledReason =
            "External appointments don't support notifications yet.";
        }
        if (
          !patient.communicationPreferences.consentsToSMS &&
          !patient.communicationPreferences.consentsToEmails
        ) {
          disabledReason =
            "The patient does not consent to being contacted via email or SMS.";
        }

        const notifications: Notification[] = [
          {
            name: "Confirmation",
            onSwitch: (event: ToggleEvent) => {
              handleSwitch({
                checked: event.target.checked,
                name: "notifyPatient",
              });
            },
            value: appointmentDetails.notifyPatient,
          },
          {
            name: "24h Reminder",
            onSwitch: (event: ToggleEvent) => {
              handleSwitch({
                checked: event.target.checked,
                name: "remindPatient24h",
              });
            },
            value: appointmentDetails.remindPatient24h,
          },
          {
            name: "1h Reminder",
            onSwitch: (event: ToggleEvent) => {
              handleSwitch({
                checked: event.target.checked,
                name: "remindPatient1h",
              });
            },
            value: appointmentDetails.remindPatient1h,
          },
        ];

        return (
          <PatientNotifications
            communicationPreferences={patient.communicationPreferences}
            notifications={notifications}
            disabledReason={disabledReason}
          />
        );
      })()}
    </div>
  );
};

export default ThirdStep;
