import React, {useEffect} from "react";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_PATIENT_ID_BY_EXTERNAL_ID} from "../../libs/queries";
import {CircularProgress} from "@mui/material";
import DetailedAlert from "../DetailedAlert";

/**
 * Finds a patient by external id (welkin, elation, user...) and
 * redirects to the patient view, keeping any specified subpath.
 * E.g. /redirect/welkin/[welkin_id]/chat --> /patient/[patient_id]/chat
 */
export default function PatientSearchRedirect() {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const {data, error, loading, refetch} = useQuery(GET_PATIENT_ID_BY_EXTERNAL_ID, {
    variables: {
      externalId: match.params.externalId,
      patientIdType: match.params.externalIdType.toUpperCase()
    },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (data?.patientIdByExternalId) {
      const redirectTab = location.pathname.replace(/\/redirect\/[^/]*\/[^/]*/, '');
      history.push(`/patient/${data.patientIdByExternalId}${redirectTab}`);
    }
  }, [data]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <DetailedAlert
      message="Oops! Could not retrieve patient info. Are you logged in?"
      additionalDetails={error}
      retry={() => refetch().then(/* Ignore promise */)}
      />;
  }

  if (!!data && !data.patientIdByExternalId) {
    return <DetailedAlert message="Oops! Could not find a patient with this id." />;
  }

  // The react effect handles redirection when data is populated.
  return null;
}
