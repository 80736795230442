// @flow
import React from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { PATIENT_INFO_QUERY } from "../../libs/queries";
import { Box, Collapse, Paper } from "@mui/material";
import LoadingScreen from "../LoadingScreen";
import PatientInfo from "./PatientInfo";
import PatientExercises from "./PatientExercises";
import PatientSchedule from "./PatientSchedule";
import DetailedAlert from "../DetailedAlert";
import PatientRecords from "./PatientRecords";
import type { ApolloResult } from "../../flow-typed/apollo";
import type { Patient } from "../../generated/flow_types";
import PatientAssessmentsSwitch from "./PatientAssessments";
import { SendbirdConversationProvider } from "../sendbird/SendbirdConversationProvider";
import PatientDrawer from "./PatientDrawer/PatientDrawer";
import PatientChat from "./PatientChat/PatientChat";
import PatientVisitNotes from "./PatientVisitNotes";
import { maxPatientScreenWidth } from "../../theme";
import { useSearchParams } from "../../hooks/searchParams";

type PatientInfoQuery = {
  patient: Patient,
};

export default function PatientActionsScreen() {
  const match = useRouteMatch();
  const { data, loading, error, refetch }: ApolloResult<PatientInfoQuery> =
    useQuery(PATIENT_INFO_QUERY, {
      variables: {
        patientId: match.params.patientId,
      },
      notifyOnNetworkStatusChange: true,
      errorPolicy: "all",
    });

  const [searchParams] = useSearchParams();

  if (loading) {
    return <LoadingScreen />;
  }

  if (!data) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <Paper sx={{ maxWidth: maxPatientScreenWidth, padding: 2, flex: 1 }}>
          <DetailedAlert
            message="Oops! Could not load patient info."
            additionalDetails={error}
            retry={() => {
              refetch().then(/* ignore promise results */);
            }}
          />
        </Paper>
      </Box>
    );
  }

  const patient = data.patient;

  return (
    <Box display="flex" flex={1} style={{ minHeight: 0 }}>
      <SendbirdConversationProvider memberId={patient.risaltoUserId}>
        <Collapse in={!searchParams.focused} orientation="horizontal">
          <PatientDrawer patient={patient} />
        </Collapse>
        <Switch>
          <Route path={`${match.path}/info`}>
            <PatientInfo patient={patient} />
          </Route>
          <Route path={`${match.path}/chat`}>
            <PatientChat
              showTextWillNotBeSentWarning={
                !patient.communicationPreferences.consentsToSMS
              }
              showPhoneNumberMissingWarning={
                !patient.communicationPreferences.phone
              }
              memberId={patient.risaltoUserId}
              pollConversationState={true}
              showLinkToProfile={false}
            />
          </Route>
          <Route path={`${match.path}/exercises`}>
            <PatientExercises patient={patient} />
          </Route>
          <Route path={`${match.path}/assessments`}>
            <PatientAssessmentsSwitch patient={patient} />
          </Route>
          <Route path={`${match.path}/schedule`}>
            <PatientSchedule patient={patient} />
          </Route>
          <Route path={`${match.path}/records`}>
            <PatientRecords patient={patient} />
          </Route>
          <Route path={`${match.path}/visit-notes`}>
            <PatientVisitNotes patient={patient} />
          </Route>
          {/*Redirect other routes*/}
          <Redirect to={`${match.url}/info`} />
        </Switch>
      </SendbirdConversationProvider>
    </Box>
  );
}
