import moment from "moment";
import { TimeOfDayValues } from "../../../../generated/flow_types";
import { timestamps } from "../../../../libs/timestamps";

const now = new Date();
const formattedDate = moment(now).format("YYYY-MM-DD");

// Current time in millis
const timeNow = +moment(now, "h:mm A").format("x");

// Timestamps in milliseconds
const timesInMillis = timestamps.map(
  ({ name }) => +moment(name, "h:mm A").format("x")
);

const returnClosestTime = (arr, time) =>
  arr.reduce((prev, curr) =>
    Math.abs(curr - time) < Math.abs(prev - time) ? curr : prev
  );

const closestTime = moment(returnClosestTime(timesInMillis, timeNow)).format(
  "h:mm A"
);

const filterByTimeOfDay = Object.values(TimeOfDayValues);

export const TabNames = Object.freeze({
  availableSlots: "availableSlots",
  specificTimes: "specificTimes",
});

export const initialState = {
  appointmentDescription: "",
  appointmentMedium: null,
  clinic: null,
  durationInMinutes: null,
  externalProvider: null,
  filterByClinicIds: [],
  filterByTimeOfDay,
  filterByLeadPractitionerIds: [],
  filterBySupportingPractitionerIds: [],
  leadPractitioner: null,
  notifyPatient: true,
  patientInstructions: "",
  searchFromDay: formattedDate,
  selectedAppointmentConfig: null,
  selectedSlot: null,
  remindPatient1h: true,
  remindPatient24h: true,
  startDay: formattedDate,
  startTime: closestTime,
  supportingPractitioner: null,
  supportingPractitionerType: null,
  tab: TabNames.availableSlots,
};
