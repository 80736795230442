import {
  AppointmentConfigMap,
  HighlightRule,
  LocalSlot,
} from "../../../libs/scheduleTemplates";
import { AppointmentMedium, AppointmentType } from "../../../gql/graphql";
import { includes, keyBy, some, values } from "lodash";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_CLINICS } from "../../SelectClinic";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { getAppointmentTypeColor } from "../../../libs/colors";
import { grey } from "@mui/material/colors";
import EditIcon from "@mui/icons-material/Edit";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ComputerIcon from "@mui/icons-material/Computer";
import { formatDuration } from "../ScheduleDraftEditor/WeeklyInsightsEdit";

type Props = {
  slots: Array<LocalSlot>;
  appointmentTypeConfigurations: AppointmentConfigMap;
  onClickEditAppointmentType?: (appointmentType: AppointmentType) => void;
  onClickEditLocation?: (locationId: string) => void;
  onHighlightChange: (rule: HighlightRule | null) => void;
};

/**
 * Reusable component to display insights about a set of slots.
 */
export default function SlotInsights(props: Props) {
  const { data } = useQuery(GET_CLINICS);

  if (!some(props.slots, (slot) => slot.type === "appointment")) {
    return (
      <Box display="flex" flexDirection="column" gap={1} width={300}>
        <Typography variant="h6">Week Insights</Typography>
        <Typography variant="body2">
          Add slots to the grid to see insights.
        </Typography>
      </Box>
    );
  }

  const clinicsById = keyBy(data?.clinics, (clinic) => clinic.id);
  const insights = compileSlotInsights(values(props.slots));

  // Needed for ts null checks
  const onClickEditAppointmentType = props.onClickEditAppointmentType;
  const onClickEditLocation = props.onClickEditLocation;

  return (
    <Box display="flex" flexDirection="column" gap={1} width={300}>
      <Typography variant="h6">Week Insights</Typography>
      {Object.entries(insights.byAppointmentType).map(
        ([appointmentType, stats]) => {
          const type = appointmentType as AppointmentType;
          return (
            <Box
              key={appointmentType}
              display="flex"
              alignItems="center"
              gap={1}
              sx={(theme) => ({
                "&:hover": {
                  "& .editButton": {
                    visibility: "visible",
                  },
                  "& .badge": {
                    border: `2px solid ${theme.palette.primary.main}`,
                  },
                },
              })}
              onMouseOver={() =>
                props.onHighlightChange({
                  type: "appointmentType",
                  appointmentType: type,
                })
              }
              onMouseLeave={() => props.onHighlightChange(null)}
            >
              <Box
                width={10}
                height={10}
                sx={{
                  backgroundColor: getAppointmentTypeColor(type),
                }}
                borderRadius={10}
                border={`1px solid ${grey[400]}`}
                className="badge"
                boxSizing="border-box"
              />
              <Box
                display="flex"
                gap={1}
                alignItems="baseline"
                justifyContent="flex-start"
                flex={1}
              >
                <Typography variant="body2">
                  {props.appointmentTypeConfigurations[type]?.internalName ||
                    appointmentType}
                  :
                </Typography>
                <Typography variant="body2">{stats.count}</Typography>
                <Typography variant="caption" color="text.secondary">
                  ({formatDuration(stats.durationInMinutes)})
                </Typography>
              </Box>
              {!!onClickEditAppointmentType && (
                <IconButton
                  size="small"
                  className="editButton"
                  sx={{ visibility: "hidden" }}
                  onClick={() => onClickEditAppointmentType(type)}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
            </Box>
          );
        },
      )}
      <Divider />
      {Object.entries(insights.byModalities.inClinic).map(
        ([locationId, stats]) => {
          return (
            <Box
              key={locationId}
              display="flex"
              alignItems="center"
              gap={1}
              sx={(theme) => ({
                "&:hover": {
                  "& .editButton": {
                    visibility: "visible",
                  },
                  "& .icon": {
                    color: theme.palette.primary.main,
                  },
                },
              })}
              onMouseOver={() =>
                props.onHighlightChange({
                  type: "location",
                  locationId,
                })
              }
              onMouseLeave={() => props.onHighlightChange(null)}
            >
              <HomeWorkOutlinedIcon
                fontSize="small"
                sx={{ color: grey[500] }}
                className="icon"
              />
              <Box display="flex" alignItems="baseline" gap={1} flex={1}>
                <Typography variant="body2">
                  {clinicsById[locationId]?.name || locationId}:
                </Typography>
                <Typography variant="body2">{stats.count}</Typography>
                <Typography variant="caption" color="text.secondary">
                  ({formatDuration(stats.durationInMinutes)})
                </Typography>
              </Box>
              {!!onClickEditLocation && (
                <IconButton
                  size="small"
                  className="editButton"
                  sx={{ visibility: "hidden" }}
                  onClick={() => onClickEditLocation(locationId)}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
            </Box>
          );
        },
      )}
      {insights.byModalities.atHome.count > 0 && (
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          sx={(theme) => ({
            "&:hover": {
              "& .icon": {
                color: theme.palette.primary.main,
              },
            },
          })}
          onMouseOver={() =>
            props.onHighlightChange({
              type: "inHome",
            })
          }
          onMouseLeave={() => props.onHighlightChange(null)}
        >
          <HomeOutlinedIcon
            fontSize="small"
            sx={{ color: grey[500] }}
            className="icon"
          />
          <Typography variant="body2">In Home:</Typography>
          <Typography variant="body2">
            {insights.byModalities.atHome.count}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            ({formatDuration(insights.byModalities.atHome.durationInMinutes)})
          </Typography>
        </Box>
      )}
      {insights.byModalities.remoteOnly.count > 0 && (
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          sx={(theme) => ({
            "&:hover": {
              "& .icon": {
                color: theme.palette.primary.main,
              },
            },
          })}
          onMouseOver={() =>
            props.onHighlightChange({
              type: "remote",
            })
          }
          onMouseLeave={() => props.onHighlightChange(null)}
        >
          <ComputerIcon
            fontSize="small"
            sx={{ color: grey[500] }}
            className="icon"
          />
          <Typography variant="body2">Remote:</Typography>
          <Typography variant="body2">
            {insights.byModalities.remoteOnly.count}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            (
            {formatDuration(insights.byModalities.remoteOnly.durationInMinutes)}
            )
          </Typography>
        </Box>
      )}
      <Divider />
      <Box display="flex" alignItems="baseline" gap={1}>
        <Typography variant="body2" fontWeight="bold">
          Total Bookable:
        </Typography>
        <Typography variant="body2" fontWeight="bold">
          {insights.totalCount}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          ({formatDuration(insights.totalDurationInMinutes)})
        </Typography>
      </Box>
    </Box>
  );
}

type SlotStats = {
  count: number;
  durationInMinutes: number;
};

type SlotInsights = {
  totalCount: number;
  totalDurationInMinutes: number;
  byAppointmentType: {
    [key in AppointmentType]?: SlotStats;
  };
  byModalities: {
    inClinic: {
      [locationId: string]: SlotStats;
    };
    atHome: SlotStats;
    remoteOnly: SlotStats;
  };
};

export function compileSlotInsights(slots: Array<LocalSlot>): SlotInsights {
  let totalCount = 0;
  let totalDurationInMinutes = 0;
  const byAppointmentType: {
    [key in AppointmentType]?: SlotStats;
  } = {};
  const byModalities: {
    inClinic: { [locationId: string]: SlotStats };
    atHome: SlotStats;
    remoteOnly: SlotStats;
  } = {
    inClinic: {},
    atHome: { count: 0, durationInMinutes: 0 },
    remoteOnly: { count: 0, durationInMinutes: 0 },
  };

  slots.forEach((slot) => {
    if (slot.type !== "appointment") {
      return;
    }
    totalCount++;
    totalDurationInMinutes += slot.endMinutes - slot.startMinutes;

    const appointmentType = slot.appointmentType;
    byAppointmentType[appointmentType] = addSlot(
      byAppointmentType[appointmentType],
      slot,
    );

    if (
      includes(slot.supportedMediums, AppointmentMedium.InClinic) &&
      slot.locationId
    ) {
      byModalities.inClinic[slot.locationId] = addSlot(
        byModalities.inClinic[slot.locationId],
        slot,
      );
    } else if (includes(slot.supportedMediums, AppointmentMedium.AtHome)) {
      byModalities.atHome = addSlot(byModalities.atHome, slot);
    } else {
      byModalities.remoteOnly = addSlot(byModalities.remoteOnly, slot);
    }
  });

  return {
    totalCount,
    totalDurationInMinutes,
    byAppointmentType,
    byModalities,
  };
}

/**
 * Returns a new SlotStats object with the given slot added to it.
 */
function addSlot(stats: SlotStats | undefined, slot: LocalSlot): SlotStats {
  if (!stats) {
    return {
      count: 1,
      durationInMinutes: slot.endMinutes - slot.startMinutes,
    };
  }
  return {
    count: stats.count + 1,
    durationInMinutes:
      stats.durationInMinutes + slot.endMinutes - slot.startMinutes,
  };
}
