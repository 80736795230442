import {
  maxScheduleHour,
  minScheduleHour,
  MotionTimezone,
} from "../../../../../../libs/time";
import {
  SearchParams,
  SlotSelection,
  StaffTeam,
} from "../../../../../../libs/booking";
import { AppointmentMedium, TargetWeek } from "../../../../../../gql/graphql";
import { graphql } from "../../../../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import { BaseScheduleGrid } from "../BaseScheduleGrid";
import GridToastErrorOrLoading from "../GridToastErrorOrLoading";
import React from "react";

type Props = {
  timezone: MotionTimezone;
  team: StaffTeam;
  week: TargetWeek;
  searchParameters: SearchParams;
  patientId: string;
  onSelect: (selection: SlotSelection) => void;
  rescheduledAppointmentId: string | undefined;
};

graphql(`
  fragment ProviderTeamAvailabilityFields on ProviderTeamAvailability {
    id
    availableSlots {
      startTime
      endTime
      locationId
    }
  }
`);

const GET_TEAM_EVENTS_AND_AVAILABILITY = graphql(`
  query GetTeamEventsAndAvailability(
    $leadStaffId: String!
    $supportingStaffId: String!
    $week: TargetWeek!
    $appointmentType: AppointmentType!
    $medium: AppointmentMedium!
    $locationIds: [String!]!
    $patientId: String!
  ) {
    providerTeamAvailability(
      leadStaffId: $leadStaffId
      supportingStaffId: $supportingStaffId
      week: $week
      appointmentType: $appointmentType
      medium: $medium
      locationIds: $locationIds
      patientId: $patientId
    ) {
      ...ProviderTeamAvailabilityFields
    }
    leadProviderWeeklyEvents: providerWeeklyEvents(
      staffId: $leadStaffId
      week: $week
    ) {
      ...EventFields
    }
    supportingProviderWeeklyEvents: providerWeeklyEvents(
      staffId: $supportingStaffId
      week: $week
    ) {
      ...EventFields
    }
  }
`);

export default function StaffTeamScheduleGrid(props: Props) {
  const { data, loading, error, refetch } = useQuery(
    GET_TEAM_EVENTS_AND_AVAILABILITY,
    {
      variables: {
        leadStaffId: props.team.leadStaffId,
        supportingStaffId: props.team.supportingStaffId,
        week: props.week,
        appointmentType: props.searchParameters.appointmentType,
        medium: props.searchParameters.medium,
        locationIds:
          props.searchParameters.medium === AppointmentMedium.InClinic
            ? Array.from(props.searchParameters.clinicIds)
            : [],
        patientId: props.patientId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    },
  );

  return (
    <BaseScheduleGrid
      staff={props.team}
      minHour={minScheduleHour}
      maxHour={maxScheduleHour}
      week={props.week}
      timezone={props.timezone}
      searchParameters={props.searchParameters}
      toast={
        <GridToastErrorOrLoading
          loading={loading}
          error={!!error}
          retry={() => refetch().then(/* ignore results */)}
        />
      }
      leadStaffEvents={data?.leadProviderWeeklyEvents ?? []}
      supportingStaffEvents={data?.supportingProviderWeeklyEvents ?? []}
      rescheduledAppointmentId={props.rescheduledAppointmentId}
      availableSlots={data?.providerTeamAvailability?.availableSlots ?? []}
      onSelect={props.onSelect}
    />
  );
}
