// @flow
import {
    FormControlLabel,
    FormControl,
    FormGroup, Switch, Typography
} from "@mui/material";
import React from "react";
import type {PatientCommunicationPreferences} from "../../../../generated/flow_types";
import type {FunctionComponent} from "../../../../flow-typed/react";

export const AppointmentEvent = Object.freeze({
    SCHEDULED: 'scheduled',
    RESCHEDULED: 'rescheduled',
    CANCELLED: 'canceled'
  });

type NotificationProps = {
  notifications: Notification[],
  communicationPreferences: PatientCommunicationPreferences,
  disabledReason: ?string,
}

export type Notification = {
  name: string,
  value: boolean,
  onSwitch: (event: ToggleEvent) => void
}

export type ToggleEvent = {target: { checked: boolean } }
  
const PatientNotifications: FunctionComponent<NotificationProps> = (props: NotificationProps) => {
  const {notifications, communicationPreferences, disabledReason} = props;

  const via: string[] = []
  communicationPreferences.consentsToEmails && via.push('Email');
  communicationPreferences.consentsToSMS && via.push('SMS');

  return (
    <FormControl>
      <Typography
        color="primary"
        style={{ fontSize: 20, fontWeight: 500 }}
      >
        Patient Notifications
      </Typography>
      {(() => {
      if (disabledReason !== null) {
        return (
        <Typography color="primary" style={{ marginTop: 16 }}>
          {disabledReason}
        </Typography>
      )} else {
        return (
          <div>
          <Typography color="primary" style={{ marginTop: 16 }}>
            {
              `Sent via: ${via.join(' & ')}`
            }
          </Typography>
          <FormGroup style={{ marginTop: 16 }}>
            {
              notifications.map(notification => {
                return (<FormControlLabel
                  control={<Switch checked={notification.value} />}
                  label={notification.name}
                  key={notification.name}
                  name={notification.name}
                  onChange={notification.onSwitch}
                />)
              })
            }
          </FormGroup>
        </div>
      )}
      })()}
    </FormControl>
  )
}

export default PatientNotifications;