// @flow checked 01/24/23
import React, { useState } from "react";
import { Button, Tab, Typography } from "@mui/material";
import { TabList } from "@mui/lab";
import AvailableAppointments from "./AvailableSlots";
import PractitionersFilterModal from "./PractitionersFilterModal";
import SpecificTime from "./SpecificTime";
import TabContext from "@mui/lab/TabContext";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AppointmentMediumValues } from "../../../../../../generated/flow_types";
import { TabNames } from "../../initialState";
import type {DraftAppointment} from "../../Stepper";
import type {AppointmentSlot, Patient, TimeOfDay} from "../../../../../../generated/flow_types";
import type {Dispatch, SetStateAction} from "../../../../../../flow-typed/react";
import {notNull} from "../../../../../../libs/util";
import ClinicsFilterModal from "./ClinicsFilterModal";
import DateFilterModal from "./DateFilterModal";

type Props = {
  appointmentDetails: DraftAppointment,
  setAppointmentDetails: Dispatch<SetStateAction<DraftAppointment>>,
  patient: Patient,
  displayTimezone: string
};

const Internal = (props: Props) => {
  const { appointmentMedium, tab } = props.appointmentDetails;

  const [dateModal, setDateModal] = useState(false);
  const [clinicsModal, setClinicsModal] = useState(false);
  const [practitionersModal, setPractitionersModal] = useState(false);

  const toggleDateModal = () => {
    setDateModal((open) => !open);
  };

  const toggleClinicsModal = () => {
    setClinicsModal((open) => !open);
  };

  const togglePractitionersModal = () => {
    setPractitionersModal((open) => !open);
  };

  const handleTabChange = (event, value) => {
    props.setAppointmentDetails((state) => ({
      ...state,
      selectedSlot: null,
      tab: value,
    }));
  };

  const handlePractitionerFiltersChanged = (filterByLeadPractitionerIds: string[], filterBySupportingPractitionerIds:string[]) => {
    props.setAppointmentDetails((state) => ({
      ...state,
      filterByLeadPractitionerIds: filterByLeadPractitionerIds,
      filterBySupportingPractitionerIds: filterBySupportingPractitionerIds,
      selectedSlot: null,
    }));
  };

  const handleClinicsFilterChanged = (filterByClinicIds: string[]) => {
    props.setAppointmentDetails((state) => ({
      ...state,
      filterByClinicIds: filterByClinicIds,
      filterByLeadPractitionerIds: [],
      filterBySupportingPractitionerIds: [],
      selectedSlot: null,
    }));
  };

  const handleDateFilterChanged = (searchFromDay: string, filterByTimeOfDay: TimeOfDay[]) => {
    props.setAppointmentDetails((state) => ({
      ...state,
      searchFromDay: searchFromDay,
      filterByTimeOfDay: filterByTimeOfDay,
      selectedSlot: null,
    }))
  };

  const handleSelectedSlotChanged = (slot: ?AppointmentSlot) => {
    props.setAppointmentDetails((state) => ({
      ...state,
      selectedSlot: slot,
    }))
  }

  const availableSlots = tab === TabNames.availableSlots;
  const isInClinic = appointmentMedium === AppointmentMediumValues.InClinic;

  return (
    <div>
      <TabContext value={tab}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "300px 1fr",
          }}
        >
          <TabList onChange={handleTabChange}>
            <Tab label="Available Slots" value={TabNames.availableSlots} />
            <Tab label="Specific Time" value={TabNames.specificTimes} />
          </TabList>
          {availableSlots && (
            <div
              style={{
                alignItems: "center",
                display: "grid",
                gap: "16px",
                gridAutoFlow: "column",
                justifyContent: "end",
                textAlign: "right",
              }}
            >
              <Typography color="primary">Filters:</Typography>
              <div>
                <Button onClick={toggleDateModal}>
                  <FilterListIcon sx={{ fontSize: 16, marginRight: "3px" }} />
                  Date & Time
                </Button>
              </div>
              {isInClinic && (
                <div>
                  <Button onClick={toggleClinicsModal}>
                    <FilterListIcon sx={{ fontSize: 16, marginRight: "3px" }} />
                    Clinics
                  </Button>
                </div>
              )}
              <div>
                <Button onClick={togglePractitionersModal}>
                  <FilterListIcon sx={{ fontSize: 16, marginRight: "3px" }} />
                  Practitioners
                </Button>
              </div>
            </div>
          )}
        </div>
        {availableSlots ? (
          <AvailableAppointments
            appointmentDetails={props.appointmentDetails}
            displayTimezone={props.displayTimezone}
            onSlotChange={handleSelectedSlotChanged}
          />
        ) : (
          <SpecificTime
            appointmentDetails={props.appointmentDetails}
            setAppointmentDetails={props.setAppointmentDetails}
            displayTimezone={props.displayTimezone}
          />
        )}
      </TabContext>
      {dateModal && (
        <DateFilterModal
          searchFromDay={props.appointmentDetails.searchFromDay}
          filterByTimeOfDay={props.appointmentDetails.filterByTimeOfDay}
          onClose={toggleDateModal}
          onFiltersChange={handleDateFilterChanged}
        />
      )}
      {clinicsModal && (
        <ClinicsFilterModal
          filterByClinicIds={props.appointmentDetails.filterByClinicIds}
          onFiltersChange={handleClinicsFilterChanged}
          onClose={toggleClinicsModal}
        />
      )}
      {practitionersModal && (
        <PractitionersFilterModal
          appointmentMedium={notNull(props.appointmentDetails.appointmentMedium)}
          appointmentConfig={notNull(props.appointmentDetails.selectedAppointmentConfig)}
          supportingPractitionerType={props.appointmentDetails.supportingPractitionerType}
          filterByClinicIds={props.appointmentDetails.filterByClinicIds}
          filterByLeadPractitionerIds={props.appointmentDetails.filterByLeadPractitionerIds}
          filterBySupportingPractitionerIds={props.appointmentDetails.filterBySupportingPractitionerIds}
          onFiltersChange={handlePractitionerFiltersChanged}
          onClose={togglePractitionersModal}
        />
      )}
    </div>
  );
};

export default Internal;
