import {
  AppointmentType,
  MedicalAppointmentFieldsFragment,
  Patient,
  TargetWeek,
} from "../../../../gql/graphql";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_APPOINTMENT_TYPE_CONFIG } from "../../../SelectAppointmentType";
import { MOTION_STAFF } from "../../../SelectStaff";
import { GET_CLINICS } from "../../../SelectClinic";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DetailedAlert from "../../../DetailedAlert";
import {
  currentTargetWeek,
  getMotionTimezoneOrNull,
  targetWeekFromDateTime,
} from "../../../../libs/time";
import ViewEditPatientTimezone from "../../../ViewEditPatientTimezone";
import {
  AppointmentStaff,
  isStaffTeam,
  SearchParams,
  SlotSelection,
} from "../../../../libs/booking";
import FinalizeAppointment from "../CreateAppointment/FinalizeAppointment";
import { DateTime } from "luxon";
import AvailabilityBrowser from "../CreateAppointment/AvailabilityBrowser";
import { SearchParameterChips } from "../CreateAppointment";
import { GET_APPOINTMENT_BY_ID } from "../ViewEditAppointment";

type Props = {
  patient: Patient;
};

/**
 * Main component for appointment rescheduling.
 */
export default function RescheduleAppointment(props: Props) {
  const match = useRouteMatch<{ appointmentId: string }>();
  const history = useHistory();
  const rescheduledAppointmentId = match.params.appointmentId;
  const patientTimezone = getMotionTimezoneOrNull(
    props.patient.communicationPreferences.timezone,
  );
  const [selectedWeek, setSelectedWeek] = useState<TargetWeek>(
    currentTargetWeek(),
  );
  const [slotSelection, setSlotSelection] = useState<SlotSelection | null>(
    null,
  );
  const [needsPatientTimezone, setNeedsPatientTimezone] = useState(
    !patientTimezone,
  );
  const { data, loading, error, refetch } = useQuery(GET_APPOINTMENT_BY_ID, {
    variables: {
      appointmentId: rescheduledAppointmentId,
    },
    notifyOnNetworkStatusChange: true,
  });

  // Initialize the cache for appointment config, staff, and clinics.
  const {
    loading: appointmentConfigLoading,
    error: appointmentConfigError,
    refetch: appointmentConfigRefetch,
  } = useQuery(GET_APPOINTMENT_TYPE_CONFIG, {
    variables: {
      beta: true,
    },
    notifyOnNetworkStatusChange: true,
  });
  const {
    loading: staffLoading,
    error: staffError,
    refetch: staffRefetch,
  } = useQuery(MOTION_STAFF, {
    notifyOnNetworkStatusChange: true,
  });
  const {
    loading: clinicLoading,
    error: clinicError,
    refetch: clinicRefetch,
  } = useQuery(GET_CLINICS, {
    notifyOnNetworkStatusChange: true,
  });

  const appointment = data?.appointmentByIdAdmin;
  useEffect(() => {
    if (appointment && patientTimezone) {
      setSelectedWeek(
        targetWeekFromDateTime(
          DateTime.fromSeconds(appointment.startTime, {
            zone: patientTimezone,
          }),
        ),
      );
    }
  }, [appointment, patientTimezone]);

  // Force setting the timezone if needed.
  if (needsPatientTimezone) {
    return (
      <WithLayout
        patientId={props.patient.id}
        rescheduledAppointmentId={rescheduledAppointmentId}
        disableBack
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="flex-start"
        >
          <Alert severity="warning">
            Please set the timezone for this patient before proceeding.
          </Alert>
          <Box width={400}>
            <ViewEditPatientTimezone
              patientId={props.patient.id}
              size="small"
            />
          </Box>
          <Button
            variant="contained"
            disabled={!patientTimezone}
            onClick={() => setNeedsPatientTimezone(false)}
          >
            Continue
          </Button>
        </Box>
      </WithLayout>
    );
  }

  if (
    loading ||
    !appointment ||
    appointmentConfigLoading ||
    staffLoading ||
    clinicLoading
  ) {
    return (
      <WithLayout
        patientId={props.patient.id}
        rescheduledAppointmentId={rescheduledAppointmentId}
        disableBack
      >
        <CircularProgress />
      </WithLayout>
    );
  }

  if (error || appointmentConfigError || staffError || clinicError) {
    return (
      <WithLayout
        patientId={props.patient.id}
        rescheduledAppointmentId={rescheduledAppointmentId}
        disableBack
      >
        <DetailedAlert
          message="Oops! Failed to load configuration. Please try again."
          additionalDetails={
            appointmentConfigError || staffError || clinicError
          }
          retry={() => {
            if (error) {
              refetch().then(/* ignore promise */);
            }
            if (appointmentConfigError) {
              appointmentConfigRefetch().then(/* ignore promise */);
            }
            if (staffError) {
              staffRefetch().then(/* ignore promise */);
            }
            if (clinicError) {
              clinicRefetch().then(/* ignore promise */);
            }
          }}
        />
      </WithLayout>
    );
  }

  const staff = getStaffFromAppointment(appointment);
  const searchParameters = getSearchParametersFromAppointment(appointment);

  if (!searchParameters) {
    return (
      <WithLayout
        patientId={props.patient.id}
        rescheduledAppointmentId={rescheduledAppointmentId}
        disableBack
      >
        <Alert severity="error">
          Oops! This appointment type is not supported for rescheduling. If you
          need to reschedule this appointment, cancel it and create a new one,
          or reach out to the engineering team for help.
        </Alert>
      </WithLayout>
    );
  }

  if (appointment.cancelled) {
    return (
      <WithLayout
        patientId={props.patient.id}
        rescheduledAppointmentId={rescheduledAppointmentId}
        disableBack
      >
        <Alert severity="warning">
          This appointment is <strong>cancelled</strong> and cannot be
          rescheduled.
        </Alert>
      </WithLayout>
    );
  }

  const handleSelectSlot = (selection: SlotSelection) => {
    setSlotSelection(selection);
    history.push(`${match.url}/confirm`);
  };

  return (
    <Switch>
      {/* Weekly Schedule Grid */}
      <Route exact path={`${match.path}/`}>
        <WithLayout
          patientId={props.patient.id}
          rescheduledAppointmentId={rescheduledAppointmentId}
          headerInfo={<SearchParameterChips searchParams={searchParameters} />}
          disableBack
        >
          <AvailabilityBrowser
            patientId={props.patient.id}
            timezone={patientTimezone!}
            searchParameters={searchParameters}
            selectedWeek={selectedWeek}
            onSelectWeek={setSelectedWeek}
            onSelectSlot={handleSelectSlot}
            mode="reschedule"
            selectedStaff={staff}
            rescheduledAppointmentId={rescheduledAppointmentId}
          />
        </WithLayout>
      </Route>
      {!!slotSelection && (
        <Route path={`${match.path}/confirm`}>
          <WithLayout
            patientId={props.patient.id}
            rescheduledAppointmentId={rescheduledAppointmentId}
          >
            <FinalizeAppointment
              patient={props.patient}
              timezone={patientTimezone!}
              searchParameters={searchParameters}
              slotSelection={slotSelection}
              week={selectedWeek}
              staff={staff}
              rescheduledAppointmentId={rescheduledAppointmentId}
            />
          </WithLayout>
        </Route>
      )}
    </Switch>
  );
}

type LayoutProps = {
  patientId: string;
  rescheduledAppointmentId: string;
  headerInfo?: React.ReactNode;
  children: React.ReactNode;
  disableBack?: boolean;
};

function WithLayout(props: LayoutProps) {
  const history = useHistory();
  return (
    <Paper
      sx={{
        margin: 1,
        padding: 1,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: 2,
        minHeight: 0,
        overflowY: "auto",
      }}
    >
      <Box display="flex" gap={2} alignItems="baseline">
        <Typography variant="h6" color="primary">
          Reschedule Appointment
        </Typography>
        {props.headerInfo || null}
        <Box display="flex" gap={1} alignItems="center" marginLeft="auto">
          {!props.disableBack && (
            <Button
              variant="outlined"
              onClick={() => history.goBack()}
              startIcon={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}
          <Button
            variant="outlined"
            color="error"
            onClick={() =>
              history.push(
                `/patient/${props.patientId}/schedule/${props.rescheduledAppointmentId}`,
              )
            }
          >
            Cancel
          </Button>
        </Box>
      </Box>
      {props.children}
    </Paper>
  );
}

function getStaffFromAppointment(
  appointment: MedicalAppointmentFieldsFragment,
): AppointmentStaff {
  const host = appointment.hostInfo;
  if (host.__typename === "StaffMemberHostInfo") {
    return !!host.supportingStaff
      ? {
          leadStaffId: host.staff.id,
          supportingStaffId: host.supportingStaff.id,
        }
      : host.staff.id;
  }
  throw new Error(
    "Found appointment with an external provider while " +
      "rescheduling, which is unexpected.",
  );
}

/**
 * Extracts search parameters from an appointment for use in the availability
 * browser.
 * Returns null if the appointment type is not supported for rescheduling.
 */
function getSearchParametersFromAppointment(
  appointment: MedicalAppointmentFieldsFragment,
): SearchParams | null {
  const supportedAppointmentType = toSupportedAppointmentType(
    appointment.appointmentType,
  );
  if (!supportedAppointmentType) {
    return null;
  }
  const staff = getStaffFromAppointment(appointment);
  return {
    appointmentType: supportedAppointmentType,
    medium: appointment.appointmentMedium,
    clinicIds: appointment.clinicId
      ? new Set([appointment.clinicId])
      : new Set(),
    multiDisciplinary: isStaffTeam(staff),
  };
}

/**
 * Maps an appointment type to an equivalent appointment type supported by
 * the new booking flow.
 * Once the old booking flow is sunsetted and enough time has passed, this
 * function can be removed.
 */
function toSupportedAppointmentType(
  appointmentType: AppointmentType,
): AppointmentType | null {
  switch (appointmentType) {
    // Sunsetted types without beta equivalent
    case AppointmentType.External:
    case AppointmentType.HealthCoachSpecialist:
    case AppointmentType.HealthCoachSpecialistFollowup:
    case AppointmentType.L3Followup:
    case AppointmentType.L3WithMdFollowup:
    case AppointmentType.L3WithPaFollowup:
    case AppointmentType.Motion_360:
    case AppointmentType.OpioidTaperFollowupMd:
    case AppointmentType.OpioidTaperFollowupPa:
    case AppointmentType.OpioidTaperMd:
    case AppointmentType.OpioidTaperPa:
    case AppointmentType.PainCoach:
    case AppointmentType.PainCoachFollowup:
    case AppointmentType.Specialist:
      return null;
    // Sunsetted types with a beta equivalent
    case AppointmentType.L3WithMd:
      return AppointmentType.L3;
    case AppointmentType.L3WithPa:
      return AppointmentType.L3;
    case AppointmentType.Md:
    case AppointmentType.Pa:
      return AppointmentType.NewPatient;
    case AppointmentType.MdFollowup:
    case AppointmentType.PaFollowup:
      return AppointmentType.Followup;
    // All other types should be supported
    default:
      return appointmentType;
  }
}
