import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client/react/hooks";
import { LoadingButton } from "@mui/lab";
import {
  GET_CLINIC_BY_ID,
  GET_ZOOM_MEETING_BY_ID,
  ADD_HOST_TO_ZOOM_MEETING,
} from "../../../../libs/queries";
import DetailedAlert from "../../../DetailedAlert";
import { DateTime } from "luxon";
import { useAuthentication } from "../../../../hooks/authHooks";
import {
  AppointmentMedium,
  MedicalAppointmentFieldsFragment,
  Patient,
} from "../../../../gql/graphql";

type Props = {
  appointment: MedicalAppointmentFieldsFragment;
  patient: Patient;
};

export function LocationDetails(props: Props) {
  const appointment = props.appointment;
  const isPast = DateTime.fromSeconds(appointment.startTime) <= DateTime.now();

  const renderLocationDetails = () => {
    switch (appointment.appointmentMedium) {
      case AppointmentMedium.InClinic:
        return InClinicLocationDetails({ clinicId: appointment.clinicId!! });
      case AppointmentMedium.Virtual:
        if (isPast) {
          return (
            <Typography variant="body2">
              (Zoom meeting information is not available for past appointments.)
            </Typography>
          );
        }
        return ZoomMeetingLocationDetails({ appointmentId: appointment.id });
      case AppointmentMedium.Phone: {
        const phoneNo = props.patient.communicationPreferences?.phone;
        return (
          <Typography variant="body2">
            {phoneNo ? `Phone: ${phoneNo}` : "Patient phone number not found"}
          </Typography>
        );
      }
      case AppointmentMedium.AtHome:
        return <Typography variant="body2">"At Home"</Typography>;
      case AppointmentMedium.Other:
      default:
        return <Typography variant="body2">"N/A"</Typography>;
    }
  };

  return renderLocationDetails();
}

const InClinicLocationDetails = (props: { clinicId: string }) => {
  const { data, loading, error, refetch } = useQuery(GET_CLINIC_BY_ID, {
    variables: {
      clinicId: props.clinicId,
    },
  });
  if (loading) {
    return <CircularProgress />;
  }
  if (error || !props.clinicId) {
    return (
      <DetailedAlert
        message="Oops! Failed to load location information. Please try again."
        retry={() => refetch()}
        additionalDetails={error}
      />
    );
  }
  const clinic = data.clinic;
  return (
    <Card variant="outlined">
      <GridContainer>
        <Grid item xs={3} style={styles.gridCell}>
          <GridRowName>Clinic</GridRowName>
        </Grid>
        <Grid item xs={9} style={styles.gridCell}>
          <GridRowData>{clinic.name}</GridRowData>
        </Grid>
        <Grid item xs={3} style={styles.gridCell}>
          <GridRowName>Address</GridRowName>
        </Grid>
        <Grid item xs={9} style={styles.gridCell}>
          <GridRowData>
            {clinic.addressLines.filter((l: any) => l.length > 0).join(", ")}
          </GridRowData>
        </Grid>
      </GridContainer>
    </Card>
  );
};

const ZoomMeetingLocationDetails = (props: { appointmentId: string }) => {
  const { session } = useAuthentication();
  const { data, loading, error, refetch } = useQuery(GET_ZOOM_MEETING_BY_ID, {
    variables: {
      appointmentId: props.appointmentId,
    },
  });
  const [showToast, setShowToast] = useState(false);
  const onCloseToast = () => {
    setShowToast(false);
  };
  const [showStartMeetingDialog, setShowStartMeetingDialog] = useState(false);
  const onCloseStartMeetingDialog = () => {
    setShowStartMeetingDialog(false);
  };
  if (loading) {
    return <CircularProgress />;
  }
  if (
    error ||
    !props.appointmentId ||
    !data.zoomMeeting ||
    !session?.getIdToken()
  ) {
    return (
      <DetailedAlert
        message="Oops! Failed to load location information. Please try again."
        retry={() => refetch()}
        additionalDetails={error}
      />
    );
  }
  const meeting = data.zoomMeeting;
  const currentUserEmail = session.getIdToken().payload.email;

  const isHost =
    currentUserEmail == meeting.hostEmail ||
    meeting.settings.alternativeHosts.includes(currentUserEmail);

  const copyIdToClipboard = () => {
    navigator.clipboard
      .writeText(meeting.joinUrl)
      .then(() => setShowToast(true));
  };

  const onClickStartMeeting = () => {
    if (isHost) {
      window.open(meeting.joinUrl);
    } else {
      setShowStartMeetingDialog(true);
    }
  };

  return (
    <Card variant="outlined">
      <GridContainer>
        <Grid item xs={12} style={styles.gridCell}>
          <GridRowName>Zoom Meeting</GridRowName>
        </Grid>
        <Grid item xs={3} style={styles.gridCell}>
          <GridRowName>Patient Url</GridRowName>
        </Grid>
        <Grid item xs={5} style={styles.gridCell}>
          <Typography fontSize={13}>{meeting.joinUrl}</Typography>
        </Grid>
        <Grid item xs={4} style={styles.gridCell}>
          <Button onClick={copyIdToClipboard}>
            <FileCopyIcon style={{ color: "gray" }} />
          </Button>
        </Grid>
        <StartMeetingComponent
          isHost={isHost}
          startMeeting={onClickStartMeeting}
        />
      </GridContainer>
      {showStartMeetingDialog && (
        <StartMeetingAsNonHostDialog
          staffId={currentUserEmail.replace("@motionmsk.com", "")}
          appointmentId={props.appointmentId}
          joinUrl={meeting.joinUrl}
          onCloseStartMeetingDialog={onCloseStartMeetingDialog}
        />
      )}
      <Snackbar
        open={showToast}
        autoHideDuration={6000}
        onClose={onCloseToast}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message="Copied to clipboard"
      />
    </Card>
  );
};

const StartMeetingComponent = (props: {
  isHost: boolean;
  startMeeting: () => void;
}) => {
  return (
    <>
      <Grid item xs={3} style={styles.gridCell}>
        <GridRowName>Start meeting</GridRowName>
        {props.isHost ? (
          <GridRowData>You are the host.</GridRowData>
        ) : (
          <GridRowData style={{ color: "orange" }}>
            You are not a host of this meeting.
          </GridRowData>
        )}
      </Grid>
      <Grid item xs={9} style={styles.gridCell}>
        <Button onClick={props.startMeeting} variant="contained">
          Start Meeting Now
        </Button>
      </Grid>
    </>
  );
};

const StartMeetingAsNonHostDialog = (props: {
  staffId: String;
  appointmentId: String;
  joinUrl: string;
  onCloseStartMeetingDialog: () => void;
}) => {
  const [mutateFunction, { data, loading, error }] = useMutation(
    ADD_HOST_TO_ZOOM_MEETING,
    {
      variables: {
        staffId: props.staffId,
        appointmentId: props.appointmentId,
      },
      refetchQueries: [
        {
          query: GET_ZOOM_MEETING_BY_ID,
          variables: { appointmentId: props.appointmentId },
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const [isLoading, setIsLoading] = useState<boolean>(loading);

  useEffect(() => {
    if (data?.tryAddStaffAsAlternativeHost) {
      window.open(props.joinUrl);
      props.onCloseStartMeetingDialog();
    }
  }, [data]);

  useEffect(() => {
    setIsLoading(loading && !error);
  }, [loading, error]);

  return (
    <Dialog
      open={true}
      onClose={props.onCloseStartMeetingDialog}
      aria-labelledby="start-dialog-title"
      aria-describedby="start-dialog-descr"
    >
      <DialogTitle id="start-dialog-title">
        {error
          ? "An error occurred"
          : "Are you sure you want to host this meeting?"}
      </DialogTitle>
      {error ? (
        <>
          <DialogContent>
            <DialogContentText id="start-dialog-descr">
              You could not be added as a host to this meeting. You may have
              insufficient privileges. You can still use the patient URL to join
              once a host has started the meeting.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={props.onCloseStartMeetingDialog}
              variant="outlined"
            >
              OK
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <DialogContentText id="start-dialog-descr">
              You are not currently hosting this meeting.
              <br />
              If you click yes, you will be added as a host and the meeting will
              open in a new tab.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              onClick={props.onCloseStartMeetingDialog}
              variant="outlined"
              sx={(styles as any).button}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoading}
              onClick={() => mutateFunction}
              variant="contained"
              sx={(styles as any).button}
            >
              Yes, Start Meeting
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const GridContainer = (props: { children: any }) => {
  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      style={{ margin: "5px 5px 5px", padding: "none" }}
    >
      {props.children}
    </Grid>
  );
};

const GridRowName = (props: { children: any }) => {
  return (
    <Typography variant="body2" style={{ fontWeight: "bold" }}>
      {props.children}
    </Typography>
  );
};

const GridRowData = (props: { children: any; style?: any }) => {
  return (
    <Typography variant="body2" style={props.style}>
      {props.children}
    </Typography>
  );
};

const styles = {
  gridCell: { padding: "5px 5px" },
};
