import { createTheme } from "@mui/material/styles";

export const fonts = {
  regular: "Eina03-Regular,Avenir Next,sans",
  semiBold: "Eina03-SemiBold,Avenir Next,sans",
};

export const styles = {
  card: {
    maxHeight: "95%",
    overflowY: "scroll",
  },
};

export const maxPatientScreenWidth = 1200;

export const motionMuiTheme = createTheme({
  palette: {
    primary: {
      light: "#9095cf",
      main: "#61679e",
      dark: "#333d6f",
      contrastText: "#fff",
    },
    secondary: {
      light: "#e3ffff",
      main: "#b0d2ea",
      dark: "#80a1b8",
      contrastText: "#000",
    },
    error: {
      light: "#ec8488",
      main: "#f05459",
    },
  },
});
