import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Link,
  Paper,
  Typography,
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { maxPatientScreenWidth } from "../../../theme";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { Link as RouterLink } from "react-router-dom";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import { useQuery } from "@apollo/client";
import { PATIENT_APPOINTMENTS } from "../PatientSchedule/UpcomingAppointments";
import DetailedAlert from "../../DetailedAlert";
import { useHistory } from "react-router-dom";
import { keyBy, orderBy } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import { AppointmentDescription } from "../Common/AppointmentDescription";
import { useMutation } from "@apollo/client";
import { PATIENT_VISIT_NOTES } from "./PatientVisitNotes";
import {
  AppointmentHostInfoFieldsFragment,
  MedicalAppointmentFieldsFragment,
  PatientVisitNotesQuery,
  StaffMemberHostInfo,
} from "../../../gql/graphql";
import { DateTime } from "luxon";
import { grey } from "@mui/material/colors";
import { graphql } from "../../../gql";

type Props = {
  patientId: string;
};

export default function CreateVisitNote(props: Props) {
  const [showPastAppointments, setShowPastAppointments] = useState(false);
  const {
    data: visitNotesData,
    loading: visitNotesLoading,
    error: visitNotesError,
    refetch: visitNotesRefetch,
  } = useQuery(PATIENT_VISIT_NOTES, {
    variables: { patientId: props.patientId },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: appointmentsData,
    loading: appointmentsLoading,
    error: appointmentsError,
    refetch: allAppointmentsRefetch,
  } = useQuery(PATIENT_APPOINTMENTS, {
    variables: {
      patientId: props.patientId,
      includePastAppointments: true,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  if (visitNotesLoading || appointmentsLoading) {
    return (
      <BreadcrumbsWrapper patientId={props.patientId}>
        <CircularProgress />
      </BreadcrumbsWrapper>
    );
  }

  if (visitNotesError || appointmentsError) {
    const s = !!visitNotesError
      ? { retry: visitNotesRefetch, error: visitNotesError }
      : { retry: allAppointmentsRefetch, error: appointmentsError };
    return (
      <BreadcrumbsWrapper patientId={props.patientId}>
        <DetailedAlert
          severity="error"
          message="Oops! Failed to retrieve data. Please try again."
          additionalDetails={s.error}
          retry={async () => await s.retry()}
        />
      </BreadcrumbsWrapper>
    );
  }

  const appointments = !showPastAppointments
    ? appointmentsData?.appointmentsForPatientAdmin.filter((appointment) => {
        return appointment.endTime > Math.floor(Date.now() / 1000);
      })
    : appointmentsData?.appointmentsForPatientAdmin;

  return (
    <BreadcrumbsWrapper patientId={props.patientId}>
      <Typography>Select an appointment:</Typography>
      <CreateVisitNoteAppointmentSelect
        patientId={props.patientId}
        visitNotesData={visitNotesData}
        appointmentsData={appointments}
      />
      {!showPastAppointments && (
        <Box>
          <Button onClick={(_) => setShowPastAppointments(true)} size="small">
            Show Past Appointments
          </Button>
        </Box>
      )}
    </BreadcrumbsWrapper>
  );
}

function BreadcrumbsWrapper(props: {
  patientId: string;
  children: React.ReactNode;
}) {
  return (
    <Box
      display="flex"
      flex={1}
      sx={{ overflowY: "auto" }}
      justifyContent="center"
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        flex={1}
        maxWidth={maxPatientScreenWidth}
      >
        <Breadcrumbs separator={<NavigateNextOutlinedIcon fontSize="small" />}>
          <Link
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            component={RouterLink}
            color="inherit"
            to={`/patient/${props.patientId}/visit-notes`}
          >
            <NoteAltOutlinedIcon sx={{ height: 18, width: 18 }} />
            Visit Notes
          </Link>
          <Link
            component="button"
            color="inherit"
            underline="none"
            sx={{ cursor: "unset" }}
          >
            New
          </Link>
        </Breadcrumbs>
        <Paper
          sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Typography variant="h6" color="primary">
            New Visit Note
          </Typography>
          {props.children}
        </Paper>
      </Box>
    </Box>
  );
}

const styles = {
  head: {
    backgroundColor: grey[200],
    fontWeight: "bold",
  },
};

type AppointmentSelectProps = {
  patientId: string;
  visitNotesData?: PatientVisitNotesQuery | undefined;
  appointmentsData?: MedicalAppointmentFieldsFragment[];
};

export const CREATE_VISIT_NOTE = graphql(`
  mutation CreateVisitNoteV2($patientId: String!, $appointmentId: String!) {
    createVisitNoteV2(patientId: $patientId, appointmentId: $appointmentId) {
      ...VisitNoteFields
    }
  }
`);

function CreateVisitNoteAppointmentSelect(props: AppointmentSelectProps) {
  const history = useHistory();
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = React.useState<
    string | null
  >(null);
  const [hoveredAppointmentId, setHoveredAppointmentId] = React.useState<
    string | null
  >(null);
  const [createVisitNote, { loading: createLoading, error: createError }] =
    useMutation(CREATE_VISIT_NOTE, {
      notifyOnNetworkStatusChange: true,
      // Add new entry to cache
      update(cache, { data }) {
        const createdNote = data?.createVisitNoteV2;
        const cachedNotesData = cache.readQuery({
          query: PATIENT_VISIT_NOTES,
          variables: { patientId: props.patientId },
        });

        if (!createdNote || !cachedNotesData?.allVisitNotes) {
          return;
        }
        cache.writeQuery({
          query: PATIENT_VISIT_NOTES,
          variables: { patientId: props.patientId },
          data: {
            allVisitNotes: [...cachedNotesData.allVisitNotes, createdNote],
          },
        });
      },
    });

  useEffect(() => {
    setShowErrorSnackbar(!!createError);
  }, [createError]);

  const sortedAppointments = orderBy(
    props.appointmentsData,
    [(appointment) => appointment.startTime],
    ["desc"],
  );

  if (sortedAppointments.length === 0) {
    return (
      <Alert severity="warning">
        There are no appointments to display for this patient. An appointment is
        required to create a visit note.
      </Alert>
    );
  }

  const visitNotesByAppointmentId = keyBy(
    props.visitNotesData?.allVisitNotes,
    (note) => note.appointment.id,
  );

  const handleCreate = (appointmentId: string) => {
    setSelectedAppointmentId(appointmentId);
    createVisitNote({
      variables: {
        patientId: props.patientId,
        appointmentId,
      },
    }).then(
      (result) => {
        const createdNote = result.data?.createVisitNoteV2;
        if (createdNote) {
          history.push(
            `/patient/${props.patientId}/visit-notes/${createdNote.id}`,
          );
        }
      },
      (_) => setSelectedAppointmentId(null),
    );
  };

  return (
    <Box display="flex" gap={2} alignItems="center">
      <Snackbar
        open={showErrorSnackbar}
        onClose={(_) => setShowErrorSnackbar(false)}
      >
        <Box>
          <Alert severity="error">
            Oops! Something went wrong. Please try again.
          </Alert>
        </Box>
      </Snackbar>
      <TableContainer
        component={Paper}
        onMouseLeave={() => setHoveredAppointmentId(null)}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.head}>Date ▾</TableCell>
              <TableCell sx={styles.head}>Provider</TableCell>
              <TableCell sx={styles.head}>Description</TableCell>
              <TableCell sx={styles.head} />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAppointments.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <i>No upcoming appointments</i>
                </TableCell>
              </TableRow>
            )}
            {sortedAppointments.map((appointment) => (
              <TableRow
                hover
                key={appointment.id}
                onMouseOver={() => setHoveredAppointmentId(appointment.id)}
              >
                <TableCell>{formattedStartTime(appointment)}</TableCell>
                <TableCell>{getHostName(appointment.hostInfo)}</TableCell>
                <TableCell>
                  <AppointmentDescription appointment={appointment} />
                </TableCell>
                <TableCell>
                  {appointment.id in visitNotesByAppointmentId ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={"center"}
                      height={"36.5px"}
                      minWidth={"204px"}
                      gap={1}
                    >
                      <Link
                        component={RouterLink}
                        variant="body2"
                        to={`/patient/${props.patientId}/visit-notes/${
                          visitNotesByAppointmentId[appointment.id].id
                        }`}
                      >
                        Open existing note
                      </Link>
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={"center"}
                      height={"36.5px"}
                      minWidth={"204px"}
                      gap={1}
                    >
                      {(hoveredAppointmentId === appointment.id ||
                        selectedAppointmentId == appointment.id) && (
                        <LoadingButton
                          size="small"
                          variant="outlined"
                          color="primary"
                          loading={
                            selectedAppointmentId == appointment.id &&
                            createLoading
                          }
                          disabled={createLoading}
                          onClick={() => handleCreate(appointment.id)}
                        >
                          SELECT AND CREATE NOTE
                        </LoadingButton>
                      )}
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const getHostName = (hostInfo: AppointmentHostInfoFieldsFragment) => {
  return hostInfo.__typename === "ExternalProviderHostInfo"
    ? `${hostInfo.provider.displayName} (External Provider)`
    : `${(hostInfo as StaffMemberHostInfo).staff.displayName}`;
};

function formattedStartTime(
  appointment: MedicalAppointmentFieldsFragment,
): string {
  return DateTime.fromSeconds(appointment.startTime).toLocaleString(
    DateTime.DATETIME_MED,
  );
}
