import {
  AppointmentConfigMap,
  getPositionFromTop,
  HighlightRule,
  LocalSlot,
} from "../../../../libs/scheduleTemplates";
import { Box, BoxProps } from "@mui/material";
import TimeSlotEdit from "../TimeSlotEdit";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import React from "react";
import { convertMinutesToHeight } from "../../../../libs/time";

type Props = {
  minHour: number;
  maxHour: number;
  day: number;
  slots: Array<LocalSlot>;
  appointmentTypeConfigurations: AppointmentConfigMap;
  movingSlotId: string | undefined;
  overlaps: { [slotId: string]: number };
  onClick: (slotId: string) => void;
  highlightRule: HighlightRule | null;
};

export default function DailySlotsEdit(props: Props) {
  const heightInPixels = convertMinutesToHeight(
    (props.maxHour - props.minHour) * 60,
  );

  return (
    <DroppableBox
      id={props.day.toString()}
      height={`${heightInPixels}px`}
      borderLeft={(theme) => `1px solid ${theme.palette.divider}`}
      position="relative"
      flex={1}
    >
      {props.slots.map((slot) => (
        <DraggableBox
          id={slot.id}
          key={slot.id}
          position="absolute"
          top={getPositionFromTop(props.minHour, slot)}
          right={(theme) => theme.spacing(1)}
          width={(theme) =>
            slot.id in props.overlaps
              ? `${Math.round(33 + 33 / props.overlaps[slot.id])}%`
              : `calc(100% - ${theme.spacing(1)})`
          }
          zIndex={slot.id in props.overlaps ? props.overlaps[slot.id] : 0}
        >
          <TimeSlotEdit
            slot={slot}
            variant={slot.id === props.movingSlotId ? "moveSource" : "normal"}
            appointmentTypeConfigurations={props.appointmentTypeConfigurations}
            error={slot.id in props.overlaps}
            onClick={() => props.onClick(slot.id)}
            highlightRule={props.highlightRule}
          />
        </DraggableBox>
      ))}
    </DroppableBox>
  );
}

function DraggableBox(
  props: {
    id: string;
  } & BoxProps,
) {
  const { id, ...other } = props;
  const { setNodeRef, listeners, attributes } = useDraggable({
    id,
  });

  return <Box ref={setNodeRef} {...other} {...listeners} {...attributes} />;
}

function DroppableBox(props: { id: string } & BoxProps) {
  const { id, ...other } = props;
  const { setNodeRef } = useDroppable({ id });

  return <Box ref={setNodeRef} {...other} />;
}
