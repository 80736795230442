import React from "react";
import UpcomingAppointments from "./UpcomingAppointments";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import ViewEditAppointment from "./ViewEditAppointment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import NewAppointment from "./NewAppointment/Stepper";
import { enableNewBooking } from "../../../config";
import { Patient } from "../../../gql/graphql";
import CreateAppointment from "./CreateAppointment";
import RescheduleAppointment from "./RescheduleAppointment";

type Props = {
  patient: Patient;
};

export default function PatientSchedule(props: Props) {
  const match = useRouteMatch();

  // Note: moment is used for the adapter because luxon hardcodes Monday as the start of week
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Switch>
        <Route path={`${match.path}/new`}>
          <NewAppointment patient={props.patient} />
        </Route>
        <Route path={`${match.path}/create`}>
          <CreateAppointment patient={props.patient} />
        </Route>
        <Route exact path={`${match.path}/`}>
          <UpcomingAppointments patient={props.patient} />
        </Route>
        <Route exact path={`${match.path}/:appointmentId`}>
          <ViewEditAppointment patient={props.patient} />
        </Route>
        {enableNewBooking() && (
          <Route path={`${match.path}/:appointmentId/reschedule`}>
            <RescheduleAppointment patient={props.patient} />
          </Route>
        )}
        <Redirect to={`${match.url}`} />
      </Switch>
    </LocalizationProvider>
  );
}
