import React from "react";
import { Patient } from "../../../gql/graphql";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import PatientVisitNotes from "./PatientVisitNotes";
import ViewOrEditVisitNote from "./ViewOrEditVisitNote";
import CreateVisitNote from "./CreateVisitNote";

type Props = {
  patient: Patient;
};

const PatientAssessmentsSwitch: React.FC<Props> = (props: Props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        <CreateVisitNote patientId={props.patient.id} />
      </Route>
      <Route path={`${match.path}/:visitNoteId`}>
        <ViewOrEditVisitNote patient={props.patient} />
      </Route>
      <Route exact path={`${match.path}/`}>
        <PatientVisitNotes patient={props.patient} />
      </Route>
      <Redirect to={`${match.url}`} />
    </Switch>
  );
};

export default PatientAssessmentsSwitch;
