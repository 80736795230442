// @flow
import * as React from "react";
import type {
  Patient,
  PatientRecord,
  PatientRecordsQuery,
} from "../../../generated/flow_types";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client/react/hooks";
import DetailedAlert from "../../DetailedAlert";
import gql from "graphql-tag";
import { sortBy } from "lodash";
import { DateTime } from "luxon";
import { grey } from "@mui/material/colors";
import ReactJson from "react-json-view";
import type { ApolloResult } from "../../../flow-typed/apollo";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { maxPatientScreenWidth } from "../../../theme";

type Props = {
  patient: Patient,
};

const PATIENT_RECORDS = gql`
  query patientRecords($patientId: String!) {
    patientRecords(patientId: $patientId) {
      id
      type
      timestamp
      patientId
      content
    }
  }
`;

export default function PatientRecords(props: Props) {
  const { data, loading, error, refetch }: ApolloResult<PatientRecordsQuery> =
    useQuery(PATIENT_RECORDS, {
      variables: {
        patientId: props.patient.id,
      },
      notifyOnNetworkStatusChange: true,
    });

  if (loading) {
    return (
      <WithHeader>
        <CircularProgress />
      </WithHeader>
    );
  }

  if (error) {
    return (
      <WithHeader>
        <DetailedAlert
          message="Oops! Failed to retrieve records"
          additionalDetails={error}
          retry={(_) => refetch()}
        />
      </WithHeader>
    );
  }

  const sortedRecords: PatientRecord[] = sortBy(
    data?.patientRecords,
    (r) => -1 * r.timestamp,
  );

  return (
    <WithHeader>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.head}>Timestamp ▾</TableCell>
              <TableCell sx={styles.head}>Type</TableCell>
              <TableCell sx={styles.head}>Raw Contents</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRecords.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <i>No records</i>
                </TableCell>
              </TableRow>
            )}
            {sortedRecords.map((record) => {
              return (
                <TableRow key={record.id}>
                  <TableCell>
                    <Typography variant="body2">
                      {DateTime.fromSeconds(record.timestamp).toLocaleString(
                        DateTime.DATETIME_SHORT,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>{record.type}</TableCell>
                  <TableCell>
                    <ReactJson
                      src={JSON.parse(record.content)}
                      name="document"
                      collapsed={true}
                      displayDataTypes={false}
                      enableClipboard={false}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </WithHeader>
  );
}

const WithHeader = (props: { children?: React.Node }) => {
  return (
    <Box
      display="flex"
      flex={1}
      sx={{ overflowY: "auto" }}
      justifyContent="center"
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        flex={1}
        maxWidth={maxPatientScreenWidth}
      >
        <Breadcrumbs>
          <Link
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            component="button"
            color="inherit"
            underline="none"
          >
            <DescriptionOutlinedIcon sx={{ height: 18, width: 18 }} />
            Records
          </Link>
        </Breadcrumbs>
        <Paper
          sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 1 }}
        >
          <Typography variant="h6" color="primary">
            Patient Records
          </Typography>
          {props.children}
        </Paper>
      </Box>
    </Box>
  );
};

const styles = {
  head: {
    backgroundColor: grey[200],
    fontWeight: "bold",
  },
};
