import { OrderCategory, OrderSuggestion } from "../../../../gql/graphql";
import {
  EditableOrderLine,
  LineErrors,
  OrderLineConfig,
} from "../../../../libs/orderLines";
import { Box, Collapse, Divider, Link, Typography } from "@mui/material";
import { orderCategoryNames } from "../../../../libs/constants";
import { ReactNode, useState } from "react";
import OrdersEditorLine from "./OrdersEditorLine";
import AddOrderDialog from "./AddOrderDialog";
import { isEmpty, pickBy, values } from "lodash";
import { TransitionGroup } from "react-transition-group";

type Props = {
  category: OrderCategory;
  linesConfig: { [lineId: string]: OrderLineConfig };
  lines: { [lineId: string]: EditableOrderLine };
  suggestions: { [orderId: string]: OrderSuggestion };
  lineErrors: { [lineId: string]: LineErrors };
  onChangeLine: (line: EditableOrderLine) => void;
  onDeleteLine: (lineId: string) => void;
};

/**
 * A single section of editable orders.
 */
export default function OrdersEditorSection(props: Props) {
  const [showAddOrderDialog, setShowAddOrderDialog] = useState(false);
  const linesInCategory = pickBy(
    props.linesConfig,
    (lineConfig) => lineConfig.category === props.category,
  );
  if (isEmpty(linesInCategory)) {
    return (
      <SectionLayout category={props.category}>
        <Typography variant="body2" color="textSecondary">
          No eligible order
        </Typography>
      </SectionLayout>
    );
  }

  // We iterate through lines to retain the order in which they were added.
  const visibleLines = values(props.lines).flatMap((line) => {
    if (line.lineId in linesInCategory) {
      return [linesInCategory[line.lineId]];
    }
    return [];
  });
  const handleChangeLine = (line: EditableOrderLine) => {
    props.onChangeLine(line);
  };

  const handleDeleteLine = (lineId: string) => {
    props.onDeleteLine(lineId);
  };

  const handleCloseAddOrderDialog = (
    selected: { lineId: string; orderId: string } | null,
  ) => {
    if (selected) {
      props.onChangeLine({
        lineId: selected.lineId,
        bodyParts: [],
        selectedOrderId: selected.orderId,
      });
    }
    setShowAddOrderDialog(false);
  };

  return (
    <>
      <SectionLayout category={props.category}>
        <Box display="flex" flexDirection="column">
          <TransitionGroup>
            {visibleLines.map((lineConfig) => (
              <Collapse key={lineConfig.lineId}>
                <OrdersEditorLine
                  key={lineConfig.lineId}
                  lineConfig={lineConfig}
                  suggestions={props.suggestions}
                  value={props.lines[lineConfig.lineId]}
                  onChange={handleChangeLine}
                  onDelete={() => handleDeleteLine(lineConfig.lineId)}
                  errors={props.lineErrors[lineConfig.lineId]}
                />
              </Collapse>
            ))}
          </TransitionGroup>
          <Box p={1}>
            <Link
              component="button"
              variant="body2"
              onClick={() => setShowAddOrderDialog(true)}
            >
              Add an order…
            </Link>
          </Box>
        </Box>
      </SectionLayout>
      {showAddOrderDialog && (
        <AddOrderDialog
          category={props.category}
          linesConfig={props.linesConfig}
          visibleLines={props.lines}
          onClose={handleCloseAddOrderDialog}
        />
      )}
    </>
  );
}

function SectionLayout(props: {
  category: OrderCategory;
  children?: ReactNode;
}) {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" gap={1} alignItems="center">
        <Divider textAlign="center" sx={{ flex: 1 }}>
          <Typography variant="body2" color="textSecondary">
            {orderCategoryNames[props.category]}
          </Typography>
        </Divider>
      </Box>
      {props.children}
    </Box>
  );
}
