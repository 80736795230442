import { Box, Typography } from "@mui/material";
import {
  AppointmentConfigMap,
  formatMinutesSinceMidnight,
  getSlotName,
  HighlightRule,
  isHighlighted,
  LocalSlot,
} from "../../../../libs/scheduleTemplates";
import { grey } from "@mui/material/colors";
import { getSlotBackgroundColor } from "../../../../libs/colors";
import React from "react";
import { convertMinutesToHeight } from "../../../../libs/time";

type Props = {
  slot: LocalSlot;
  appointmentTypeConfigurations: AppointmentConfigMap;
  variant?: "normal" | "moveTarget" | "moveSource" | "copyTarget";
  error?: boolean;
  onClick?: () => void;
  highlightRule?: HighlightRule | null;
};

export default function TimeSlotEdit(props: Props) {
  const heightInPixels = convertMinutesToHeight(
    props.slot.endMinutes - props.slot.startMinutes,
  );
  const dense = heightInPixels <= 30;

  const showTime =
    props.variant === "moveTarget" || props.variant === "copyTarget";

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onClick?.();
  };

  return (
    <Box
      height={`${heightInPixels}px`}
      overflow="hidden"
      display="flex"
      alignItems="stretch"
      sx={{ opacity: props.variant === "moveSource" ? 0.5 : 1 }}
      onClick={onClick}
    >
      <Box
        mb="1px"
        paddingY={dense ? 0 : 1}
        paddingX={1}
        display="flex"
        gap={1}
        flex={1}
        border={(theme) =>
          isHighlighted(props.slot, props.highlightRule)
            ? `2px solid ${theme.palette.primary.main}`
            : props.error
            ? `2px solid red`
            : `1px solid ${grey[400]}`
        }
        borderRadius={1}
        overflow="hidden"
        sx={{
          backgroundColor: getSlotBackgroundColor(props.slot),
          cursor:
            props.variant === "moveTarget"
              ? "grabbing"
              : props.variant === "copyTarget"
              ? "copy"
              : "pointer",
        }}
        alignItems={dense ? "center" : "flex-start"}
      >
        {!showTime && (
          <Typography
            variant="caption"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {getSlotName(props.slot, props.appointmentTypeConfigurations)}
          </Typography>
        )}
        {showTime && (
          <Typography variant="caption" overflow="hidden">
            {formatMinutesSinceMidnight(props.slot.startMinutes)}
            {" - "}
            {formatMinutesSinceMidnight(props.slot.endMinutes)}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
