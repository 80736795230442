import { Button, Tooltip } from "@mui/material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

type Props = {
  upcomingScheduleId: string | null;
};

export default function DeleteUpcomingScheduleButton(props: Props) {
  const match = useRouteMatch();
  if (!props.upcomingScheduleId) {
    return (
      <Tooltip title="Cannot delete the currrent schedule">
        <span>
          <Button
            disabled={true}
            color="error"
            variant="outlined"
            size="small"
            startIcon={<EventBusyIcon />}
          >
            Delete
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <Button
      color="error"
      variant="outlined"
      size="small"
      startIcon={<EventBusyIcon />}
      component={RouterLink}
      to={`${match.path}/delete`}
    >
      Delete
    </Button>
  );
}
