import { DesktopTimePicker } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DateTime, Duration } from "luxon";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import StaffAvailabilityHelper from "../StaffAvailabilityHelper";
import {
  AppointmentMedium,
  AppointmentSlot,
  AppointmentType,
} from "../../../../gql/graphql";
import { ProposedSlot } from "../ViewEditAppointment/NewSlot";

type Props = {
  staffId?: string;
  supportingPractitionerId: string | null;
  supportingPractitionerType: string | null;
  clinicId: string | null;
  appointmentType: AppointmentType;
  appointmentMedium: AppointmentMedium;
  patientTimezone: string | null;
  defaultDurationInMinutes: number;
  selectedSlot: ProposedSlot;
  onSelectedSlotChanged: (slot: ProposedSlot) => void;
  resetSelectedSlot: () => void;
};

/** Component to select / edit appointment date and time. Option to see available times or pick manually. */
export default function AppointmentDateTimeSelector(props: Props) {
  // Selected tab
  const isAvailabilityAllowed = !!props.staffId;
  const initialSelectedTab =
    !isAvailabilityAllowed || !props.selectedSlot.checkedForAvailability
      ? TabNames.MANUAL
      : TabNames.AVAILABILITY;
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
  const isExternal = props.appointmentType === AppointmentType.External;

  // Selected day. State needed to hold day while time is unselected.
  const selectedStartMoment = moment.unix(
    props.selectedSlot.startTimeInSeconds,
  );
  const [calendarDay, setCalendarDay] = useState(selectedStartMoment);

  // Selected Duration. State needed to hold duration while time is unselected.
  const [selectedDurationInMinutes, setSelectedDurationInMinutes] = useState(
    props.selectedSlot.durationInMinutes,
  );

  const handleCalendarDayChange = (newDay: Moment) => {
    setCalendarDay(newDay);

    if (selectedTab === TabNames.AVAILABILITY) {
      // Clear any selected slot since we need to refresh availability
      props.resetSelectedSlot();
    } else {
      // Update selected slot to new day, without changing time.
      props.onSelectedSlotChanged({
        ...props.selectedSlot,
        startTimeInSeconds: combineDayAndTime(
          newDay.unix(),
          props.selectedSlot.startTimeInSeconds,
          props.patientTimezone || null,
        ),
      });
    }
  };

  const handleDurationChange = (durationInMinutes: number) => {
    setSelectedDurationInMinutes(durationInMinutes);

    if (props.selectedSlot.checkedForAvailability) {
      // Selected slot is an "available" slot, need to refresh
      props.resetSelectedSlot();
    } else {
      // Update selected slot to new duration, without changing anything else.
      props.onSelectedSlotChanged({
        ...props.selectedSlot,
        durationInMinutes: durationInMinutes,
      });
    }
  };

  const handleManualTimeChange = (manualTime: Moment | null) => {
    if (!manualTime || !manualTime.isValid()) {
      // Clear selected slot to ensure the error is corrected.
      props.resetSelectedSlot();
      return;
    }
    // At this stage we should have a valid slot
    const selectedStart = combineDayAndTime(
      calendarDay.unix(),
      manualTime.unix(),
      props.patientTimezone || null,
    );

    props.onSelectedSlotChanged({
      startTimeInSeconds: selectedStart,
      durationInMinutes: selectedDurationInMinutes,
      checkedForAvailability: false,
    });
  };

  const handleAvailableSlotClicked = (slot: AppointmentSlot) => {
    props.onSelectedSlotChanged({
      startTimeInSeconds: slot.startTime,
      durationInMinutes: Math.round((slot.endTime - slot.startTime) / 60),
      checkedForAvailability: true,
    });
  };

  const handleTabSwitch = (_: any, newTab: string) => {
    setSelectedTab(newTab);
    if (newTab === TabNames.AVAILABILITY) {
      // Clear the manually picked slot since it might not be available.
      props.resetSelectedSlot();
    } else {
      props.onSelectedSlotChanged({
        ...props.selectedSlot,
        checkedForAvailability: false,
      });
    }
  };

  const patientTimezoneAbbr = !props.patientTimezone
    ? "unknown"
    : calendarDay.tz(props.patientTimezone).zoneAbbr();

  return (
    <Box display="flex" flexDirection="row" alignItems="baseline">
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={calendarDay}
        minDate={moment()}
        onChange={(value, _) => handleCalendarDayChange(value!!)}
        slotProps={{ textField: { variant: "outlined" } } as any}
      />
      <Box style={styles.timeWrapper as any}>
        <Tabs
          value={selectedTab}
          onChange={handleTabSwitch}
          sx={{ borderBottom: "1px solid", borderColor: "rgba(0, 0, 0, 0.23)" }}
        >
          <Tab value={TabNames.MANUAL} label="Specific Time" />
          <Tab
            value={TabNames.AVAILABILITY}
            label="Available Slots"
            disabled={!isAvailabilityAllowed}
          />
        </Tabs>
        <Box display="flex" flexDirection="row" flexGrow={1}>
          <FormControl sx={{ width: "200px", minWidth: "100px", margin: 2 }}>
            <InputLabel id="duration-label">Duration</InputLabel>
            <Select
              disabled={!isExternal}
              labelId="duration-label"
              label="Duration"
              value={selectedDurationInMinutes}
              onChange={(e) => handleDurationChange(e.target.value as number)}
            >
              {[15, 30, 45, 60, 75].map((durationInMinutes) => (
                <MenuItem key={durationInMinutes} value={durationInMinutes}>
                  {Duration.fromObject({
                    minutes: durationInMinutes,
                  }).toHuman()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedTab === TabNames.MANUAL && (
            <FormControl sx={{ margin: 2 }}>
              <DesktopTimePicker
                label={`Start Time (${patientTimezoneAbbr})`}
                minutesStep={5}
                value={selectedStartMoment}
                onChange={handleManualTimeChange}
                slotProps={{ textField: { variant: "outlined" } }}
              />
            </FormControl>
          )}
          {selectedTab === TabNames.AVAILABILITY && (
            <Box sx={styles.availability}>
              <StaffAvailabilityHelper
                staffId={props.staffId!!}
                supportingPractitionerId={props.supportingPractitionerId}
                supportingPractitionerType={props.supportingPractitionerType}
                clinicId={props.clinicId}
                appointmentType={props.appointmentType}
                appointmentMedium={props.appointmentMedium}
                date={calendarDay}
                durationInMinutes={selectedDurationInMinutes}
                highlightedStart={props.selectedSlot.startTimeInSeconds}
                patientTimezone={props.patientTimezone}
                onAvailableSlotClicked={handleAvailableSlotClicked}
              />
            </Box>
          )}
        </Box>
        {selectedTab === TabNames.MANUAL && (
          <Box sx={{ marginBottom: 2 }}>
            <Typography
              variant="caption"
              sx={{ paddingX: 1, color: "text.secondary" }}
            >
              Availability will <strong>not</strong> be checked before booking.
            </Typography>
          </Box>
        )}
        <Box sx={styles.note}>
          <Typography variant="caption">
            Patient timezone is <strong>{patientTimezoneAbbr}</strong>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  timeWrapper: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: 5,
    width: "100%",
  },
  note: {
    paddingY: "3px",
    paddingX: 1,
    backgroundColor: "#eff0f1",
    borderRadius: "0 0 5px 5px",
  },
  availability: {
    border: "1px solid",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: "5px",
    margin: 2,
  },
};

const TabNames = {
  AVAILABILITY: "availability",
  MANUAL: "manual",
};

function combineDayAndTime(
  day: number,
  time: number,
  timezone: string | null,
): number {
  const timeTyped = DateTime.fromSeconds(time).setZone(timezone || undefined);
  return DateTime.fromSeconds(day)
    .setZone(timezone || undefined)
    .set({
      hour: timeTyped.hour,
      minute: timeTyped.minute,
      second: 0,
    })
    .toUnixInteger();
}
