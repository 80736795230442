import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { graphql } from "../../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import DetailedAlert from "../../../DetailedAlert";
import { sortBy } from "lodash";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  AssessmentStatus,
  VisitNote_AssessmentFieldsFragment,
} from "../../../../gql/graphql";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import AddIcon from "@mui/icons-material/Add";
import { getProviderEditAssessmentLink } from "../../PatientAssessments/PendingAssessments";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { DetachAssessmentDialog } from "./DetachAssessmentDialog";
import AddAssessmentDialog from "./AddAssessmentDialog";
import { useSearchParams } from "../../../../hooks/searchParams";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

type Props = {
  patientId: string;
  visitNoteId: string;
};

export const GET_VISIT_NOTE_ASSESSMENTS_QUERY = graphql(`
  query GetVisitNoteAssessments($id: String!) {
    visitNoteById(id: $id) {
      id
      assessments {
        ...VisitNote_AssessmentFields
      }
    }
  }
`);

/**
 * Component to manage assessments for a visit note.
 */
export default function AssessmentsEditor(props: Props) {
  const [searchParams] = useSearchParams();
  const [detachAssessment, setDetachAssessment] =
    useState<VisitNote_AssessmentFieldsFragment | null>(null);
  const [showAddAssessmentDialog, setShowAddAssessmentDialog] = useState(false);
  const { data, loading, error, refetch } = useQuery(
    GET_VISIT_NOTE_ASSESSMENTS_QUERY,
    {
      variables: { id: props.visitNoteId },
      notifyOnNetworkStatusChange: true,
    },
  );

  if (error) {
    return (
      <DetailedAlert
        message="Oops! Something went wrong. Please try again."
        retry={refetch}
        additionalDetails={error}
      />
    );
  }

  const assessments = data?.visitNoteById?.assessments;
  if (!assessments) {
    return <CircularProgress />;
  }

  const sortedAssessments = sortBy(
    assessments,
    (assessment) => -assessment.createdAt,
  );

  const headerStyle = { fontWeight: "bold", backgroundColor: grey[100] };
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={headerStyle}>Assessment</TableCell>
            {!searchParams.focused && (
              <TableCell style={headerStyle}>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography variant="body2" fontWeight="bold">
                    Status
                  </Typography>
                  <IconButton
                    onClick={() => refetch()}
                    size="small"
                    disabled={loading}
                  >
                    <RefreshIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </TableCell>
            )}
            <TableCell style={{ ...headerStyle }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAssessments.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="body2" color="text.secondary">
                  No assessment yet
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            sortedAssessments.map((assessment) => (
              <AssessmentRow
                key={assessment.id}
                assessment={assessment}
                showStatus={!searchParams.focused}
                onDetachClick={() => setDetachAssessment(assessment)}
              />
            ))
          )}
        </TableBody>
      </Table>
      <Box alignSelf="flex-end">
        <Button
          variant="outlined"
          disabled={loading}
          startIcon={<AddIcon />}
          size="small"
          onClick={() => setShowAddAssessmentDialog(true)}
        >
          Add Assessment
        </Button>
      </Box>
      {!!detachAssessment && (
        <DetachAssessmentDialog
          visitNoteId={props.visitNoteId}
          assessment={detachAssessment}
          onClose={() => setDetachAssessment(null)}
        />
      )}
      {showAddAssessmentDialog && (
        <AddAssessmentDialog
          patientId={props.patientId}
          visitNoteId={props.visitNoteId}
          onClose={() => setShowAddAssessmentDialog(false)}
        />
      )}
    </Box>
  );
}

function AssessmentRow(props: {
  assessment: VisitNote_AssessmentFieldsFragment;
  showStatus: boolean;
  onDetachClick: () => void;
}) {
  return (
    <TableRow hover>
      <TableCell>{props.assessment.name}</TableCell>
      {props.showStatus && (
        <TableCell>
          {props.assessment.status === AssessmentStatus.Completed
            ? "Completed"
            : "In Progress"}
        </TableCell>
      )}
      <TableCell style={{ textAlign: "end" }}>
        <AssessmentActions
          assessment={props.assessment}
          onDetachClick={props.onDetachClick}
        />
      </TableCell>
    </TableRow>
  );
}

function AssessmentActions(props: {
  assessment: VisitNote_AssessmentFieldsFragment;
  onDetachClick: () => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const completed = props.assessment.status === AssessmentStatus.Completed;
  const patientFacing = !!props.assessment.patientAccessToken;

  if (searchParams.focused) {
    if (searchParams.assessmentId === props.assessment.id) {
      return (
        <Typography variant="body2" color="text.secondary" fontStyle="italic">
          Open in side view
        </Typography>
      );
    }
    if (!completed && !patientFacing) {
      return (
        <Button
          size="small"
          endIcon={<KeyboardDoubleArrowRightIcon />}
          onClick={() =>
            setSearchParams({
              focused: true,
              assessmentId: props.assessment.id,
            })
          }
        >
          Switch
        </Button>
      );
    }
    // No action
    return null;
  }

  const onDetachClick = () => {
    setAnchorEl(null);
    props.onDetachClick();
  };
  return (
    <>
      <Button
        size="small"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Actions
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <OpenMenuItems
          assessment={props.assessment}
          completed={completed}
          patientFacing={patientFacing}
          onClose={() => setAnchorEl(null)}
        />
        <MenuItem onClick={onDetachClick}>
          <ListItemIcon>
            <RemoveCircleOutlineOutlinedIcon color="error" />
          </ListItemIcon>
          Detach from note…
        </MenuItem>
      </Menu>
    </>
  );
}

function OpenMenuItems(props: {
  assessment: VisitNote_AssessmentFieldsFragment;
  completed: boolean;
  patientFacing: boolean;
  onClose: () => void;
}) {
  const [_, setSearchParams] = useSearchParams();
  if (props.completed) {
    const handleReviewInNewTab = () => {
      props.onClose();
      window.open(
        `/patient/${props.assessment.patientId}/assessments/${props.assessment.id}`,
        "_blank",
      );
    };
    return (
      <>
        <MenuItem onClick={handleReviewInNewTab}>
          <ListItemIcon>
            <OpenInNewIcon />
          </ListItemIcon>
          Review
        </MenuItem>
        <Divider />
      </>
    );
  }

  if (props.patientFacing) {
    return (
      <>
        <MenuItem disabled>
          <i>Can't edit a patient-facing assessment.</i>
        </MenuItem>
        <Divider />
      </>
    );
  }

  // Editable provider-facing
  const handleEditInSideView = () => {
    props.onClose();
    setSearchParams({ focused: true, assessmentId: props.assessment.id });
  };

  const handleEditInNewTab = () => {
    props.onClose();
    window.open(getProviderEditAssessmentLink(props.assessment.id), "_blank");
  };

  return (
    <>
      <MenuItem onClick={handleEditInSideView}>
        <ListItemIcon>
          <VerticalSplitIcon />
        </ListItemIcon>
        Edit in side view
      </MenuItem>
      <MenuItem onClick={handleEditInNewTab}>
        <ListItemIcon>
          <OpenInNewIcon />
        </ListItemIcon>
        Edit in new tab
      </MenuItem>
      <Divider />
    </>
  );
}
