import { ApolloError } from "@apollo/client";
import DetailedAlert from "../../../../../DetailedAlert";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import {
  allIssuesAcknowledged,
  BookingIssue,
} from "../../../../../../libs/booking";
import { grey } from "@mui/material/colors";

type Props = {
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  bookingIssues: ReadonlySet<BookingIssue> | null;
  issuesAcknowledged: ReadonlySet<BookingIssue>;
  onAcknowledgementChange: (issue: BookingIssue, acknowledged: boolean) => void;
  multiDisciplinary: boolean;
};

export default function BookingIssuesSummary(props: Props) {
  if (props.error) {
    return (
      <DetailedAlert
        message="Failed to load provider schedule."
        additionalDetails={props.error}
        retry={props.refetch}
      />
    );
  }
  if (props.loading) {
    return (
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="body2" color="text.secondary">
          Loading…
        </Typography>
        <CircularProgress size={18} sx={{ color: grey[500] }} />
      </Box>
    );
  }
  if (!props.bookingIssues) {
    return (
      <Typography variant="body2" color="text.secondary">
        Potential booking issues will be displayed here.
      </Typography>
    );
  }
  if (props.bookingIssues.size === 0) {
    return <Alert severity="success">No booking issues found.</Alert>;
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Alert severity="warning">
        <Typography variant="body2">
          Please review and acknowledge these potential issues before finalizing
          the appointment.
        </Typography>
      </Alert>
      <FormGroup>
        {props.bookingIssues.has(BookingIssue.DoubleBooking) && (
          <FormControlLabel
            control={
              <Checkbox
                checked={props.issuesAcknowledged.has(
                  BookingIssue.DoubleBooking,
                )}
                onChange={(_, checked) =>
                  props.onAcknowledgementChange(
                    BookingIssue.DoubleBooking,
                    checked,
                  )
                }
              />
            }
            label={
              <Typography variant="body2">
                This appointment will{" "}
                <strong>overlap with at least one other event</strong> in the{" "}
                {props.multiDisciplinary
                  ? "providers' calendars"
                  : "provider's calendar"}
                . Please review the overlapping events to ensure that this
                appointment is feasible.
              </Typography>
            }
          />
        )}
        {props.bookingIssues.has(BookingIssue.MultipleLocations) && (
          <FormControlLabel
            control={
              <Checkbox
                checked={props.issuesAcknowledged.has(
                  BookingIssue.MultipleLocations,
                )}
                onChange={(_, checked) =>
                  props.onAcknowledgementChange(
                    BookingIssue.MultipleLocations,
                    checked,
                  )
                }
              />
            }
            label={
              <Typography variant="body2">
                {props.multiDisciplinary
                  ? "The lead provider "
                  : "This provider "}
                might be in a <strong>different location on this day</strong>.
                Please review the provider's full day to ensure that this
                appointment is feasible.
              </Typography>
            }
          />
        )}
      </FormGroup>
      {!allIssuesAcknowledged(
        props.bookingIssues,
        props.issuesAcknowledged,
      ) && (
        <Typography variant="body2" color="error">
          All issues must be acknowledged before booking.
        </Typography>
      )}
    </Box>
  );
}
