import * as React from "react";
import { useLazyQuery } from "@apollo/client";
import { Box, Divider, Paper } from "@mui/material";
import { SendbirdChannel } from "../SendbirdChannel";
import SendbirdChannelInfo from "../SendbirdChannelInfo";
import { ManageConversationButton } from "./ManageConversationButton";
import gql from "graphql-tag";
import { Alert } from "@mui/material";
import {
  ConversationQueryVariables,
  ConversationQuery,
} from "../../../gql/graphql";
import { useAuthentication } from "../../../hooks/authHooks";

type Props = {
  memberId: string;
  pollConversationState: boolean;
  showLinkToProfile: boolean;
  showTextWillNotBeSentWarning: boolean;
  showPhoneNumberMissingWarning: boolean;
};
/**
 * Component for a single Sendbird conversation.
 */
export const Conversation = (props: Props) => {
  const { session } = useAuthentication();

  const [getConversation, { data }] = useLazyQuery<
    ConversationQuery,
    ConversationQueryVariables
  >(CONVERSATION, {
    variables: {
      memberId: props.memberId,
    },
  });

  React.useEffect(() => {
    if (props.memberId) {
      getConversation().then(/*ignore*/);
    }
  }, []);

  const currentSub = session?.getIdToken()?.payload?.sub;
  const currentUserIsOwner =
    !!currentSub && data?.conversation?.ownerId === currentSub;

  return (
    <Paper
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: 2,
        minHeight: 0,
        overflow: "auto",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <SendbirdChannelInfo showLinkToProfile={props.showLinkToProfile} />
        {!!props.memberId && (
          <ManageConversationButton
            memberId={props.memberId}
            poll={props.pollConversationState}
          />
        )}
      </Box>
      {(props.showTextWillNotBeSentWarning ||
        props.showPhoneNumberMissingWarning) && (
        <Box display="flex" justifyContent="center" gap={2}>
          {props.showTextWillNotBeSentWarning ? (
            <Alert
              severity="warning"
              style={{ width: "100%", justifyContent: "center" }}
            >
              This patient has not opted-in to receive text messages. They will
              not be notified of any messages sent via the chat.
            </Alert>
          ) : props.showPhoneNumberMissingWarning ? (
            <Alert
              severity="warning"
              style={{ width: "100%", justifyContent: "center" }}
            >
              This patient does not have a phone number registered with Motion
              yet. They will not be notified of any messages sent via the chat.
            </Alert>
          ) : null}
        </Box>
      )}
      <Divider />
      <Box display="flex" sx={{ minHeight: 0 }} gap={3}>
        <SendbirdChannel
          memberId={props.memberId}
          disableSendMessages={!currentUserIsOwner}
        />
      </Box>
    </Paper>
  );
};

export const CONVERSATION = gql`
  query conversation($memberId: String!) {
    conversation(memberId: $memberId) {
      id
      member {
        id
        displayName
      }
      state
      ownerId
      ownerName
      lastEventTime
      lastMemberReadTime
      lastMemberMessageTime
      lastMotionMessageTime
    }
  }
`;
