import {
  convertMinutesToHeight,
  dateTimeFromTargetWeek,
  MotionTimezone,
  MotionWeekDay,
  sanitizeAndGroupByDay,
  validateAndGroupByDay,
} from "../../../../../../libs/time";
import {
  AvailableSlot,
  EventFieldsFragment,
  TargetWeek,
} from "../../../../../../gql/graphql";
import React, { useMemo, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import GridTimes from "../../../../../ManageSchedules/ScheduleDraftEditor/GridTimes";
import GridLines from "../../../../../ManageSchedules/ScheduleDraftEditor/GridLines";
import StaffScheduleDay from "../StaffScheduleDay";
import { grey } from "@mui/material/colors";
import { DateTime } from "luxon";
import {
  AppointmentStaff,
  isStaffTeam,
  SearchParams,
  SlotSelection,
} from "../../../../../../libs/booking";
import GridToastBase from "../GridToastBase";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { AvatarWithTooltip } from "../../FinalizeAppointment/DailyScheduleView";

type BaseProps = {
  minHour: number;
  maxHour: number;
  week: TargetWeek;
  toast?: string | React.ReactNode;
};

type EmptyGridProps = BaseProps & {
  staff: null;
};

type PopulatedGridProps = BaseProps & {
  staff: AppointmentStaff;
  searchParameters: SearchParams;
  timezone: MotionTimezone;
  // Events
  leadStaffEvents: Array<EventFieldsFragment>;
  supportingStaffEvents: Array<EventFieldsFragment> | null;
  rescheduledAppointmentId: string | undefined;
  // Availability
  availableSlots: Array<AvailableSlot>;
  onSelect: (selection: SlotSelection) => void;
};

type Props = EmptyGridProps | PopulatedGridProps;
function isPopulated(props: Props): props is PopulatedGridProps {
  return props.staff !== null;
}

const timeLabelsWidth = 55;

/**
 * Main component to render a schedule week as a time grid.
 */
export function BaseScheduleGrid(props: Props) {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setIsScrolled(event.currentTarget.scrollTop > 10);
  };

  const {
    leadStaffEventsByDay,
    supportingStaffEventsByDay,
    availableSlotsByDay,
  } = compileEventsAndAvailability(props);

  return (
    <Box display="flex" flexDirection="column" minHeight={0} flex={1}>
      {/*  Header */}
      <Box
        display="flex"
        p={0.5}
        borderBottom={isScrolled ? "1px solid #dedede" : "none"}
        position="relative"
      >
        <Box width={timeLabelsWidth} />
        <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" flex={1}>
          {Array.from({ length: 5 }, (_, i) => i + 1).map((dayOfWeek) => {
            const onClick = isPopulated(props)
              ? () =>
                  props.onSelect({
                    type: "manual",
                    week: props.week,
                    weekday: dayOfWeek as MotionWeekDay,
                  })
              : undefined;
            return (
              <Day
                key={dayOfWeek}
                week={props.week}
                dayOfWeek={dayOfWeek as MotionWeekDay}
                onClick={onClick}
              />
            );
          })}
          <AvatarHeaderRow staff={props.staff} />
        </Box>
        {!!props.toast && (
          <Box
            position="absolute"
            top={props.staff && isStaffTeam(props.staff) ? 100 : 80}
            left="50%"
            sx={{ transform: "translateX(-50%)" }}
            zIndex={1000}
          >
            {typeof props.toast === "string" ? (
              <GridToastBase>{props.toast}</GridToastBase>
            ) : (
              props.toast
            )}
          </Box>
        )}
      </Box>
      {/*  Time grid, scrollable */}
      <Box
        display="flex"
        minHeight={0}
        sx={{ overflowY: "auto" }}
        flex={1}
        pt={1}
        pb={2}
        onScroll={handleScroll}
      >
        <Box width={timeLabelsWidth}>
          <GridTimes startHour={props.minHour} endHour={props.maxHour} />
        </Box>
        <Box position="relative" flex={1}>
          <Box position="absolute" top={0} left={0} width="100%">
            <GridLines
              startHour={props.minHour}
              endHour={props.maxHour}
              backgroundColor={grey[50]}
            />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(5, 1fr)"
            position="relative"
          >
            {Array(5)
              .fill(null)
              .map((_, i) => {
                if (!isPopulated(props)) {
                  const heightInPixels = convertMinutesToHeight(
                    (props.maxHour - props.minHour) * 60,
                  );
                  return (
                    <Box
                      key={i}
                      height={`${heightInPixels}px`}
                      borderLeft={(theme) =>
                        `1px solid ${theme.palette.divider}`
                      }
                    />
                  );
                }
                return (
                  <StaffScheduleDay
                    key={i}
                    staff={props.staff}
                    minHour={props.minHour}
                    maxHour={props.maxHour}
                    timezone={props.timezone}
                    leadStaffEvents={
                      leadStaffEventsByDay[(i + 1) as MotionWeekDay] || []
                    }
                    supportingStaffEvents={
                      supportingStaffEventsByDay
                        ? supportingStaffEventsByDay[
                            (i + 1) as MotionWeekDay
                          ] || []
                        : null
                    }
                    rescheduledAppointmentId={props.rescheduledAppointmentId}
                    includeAvailability={true}
                    availableSlots={
                      availableSlotsByDay[(i + 1) as MotionWeekDay] || []
                    }
                    searchParameters={props.searchParameters}
                    onSelect={(slot) =>
                      props.onSelect({ type: "available", slot })
                    }
                  />
                );
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Day(props: {
  week: TargetWeek;
  dayOfWeek: MotionWeekDay;
  onClick?: () => void;
}) {
  const arbitraryTimezone = MotionTimezone.NEW_YORK;
  const date = dateTimeFromTargetWeek(
    arbitraryTimezone,
    props.week,
    props.dayOfWeek,
  );
  const now = DateTime.now().setZone(arbitraryTimezone);
  const isPast = date.startOf("day") < now.startOf("day");
  const isToday = date.hasSame(now, "day");

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={
        !!props.onClick
          ? {
              "&:hover": {
                "& .bookManuallyButton": {
                  visibility: "visible",
                },
              },
            }
          : {}
      }
    >
      <Typography variant="caption" color="text.secondary">
        {date.toLocaleString({ weekday: "short" })}
      </Typography>
      {/* 3-column layout keeps the day label centered */}
      <Box display="grid" gridTemplateColumns="1fr auto 1fr">
        <Box />
        <Typography
          variant="h6"
          color={isPast ? grey[400] : undefined}
          borderRadius={5}
          paddingX={0.5}
          flex="0 0 auto"
          sx={isToday ? { backgroundColor: grey[200] } : undefined}
        >
          {date.toLocaleString({ day: "numeric" })}
        </Typography>
        <Tooltip
          title="Book Manually..."
          sx={{ visibility: "hidden" }}
          className="bookManuallyButton"
        >
          <IconButton onClick={props.onClick} size="small">
            <EditCalendarIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

function AvatarHeaderRow(props: { staff: AppointmentStaff | null }) {
  const { staff } = props;
  if (!staff || !isStaffTeam(staff)) {
    return null;
  }
  return (
    <>
      {Array.from({ length: 5 }).map((_, i) => (
        <Box key={i} display="flex" justifyContent="space-evenly">
          <AvatarWithTooltip staffId={staff.leadStaffId} size="xs" />
          <AvatarWithTooltip staffId={staff.supportingStaffId} size="xs" />
        </Box>
      ))}
    </>
  );
}

function compileEventsAndAvailability(props: Props): {
  leadStaffEventsByDay: Partial<
    Record<MotionWeekDay, Array<EventFieldsFragment>>
  >;
  supportingStaffEventsByDay: Partial<
    Record<MotionWeekDay, Array<EventFieldsFragment>>
  > | null;
  availableSlotsByDay: Partial<Record<MotionWeekDay, Array<AvailableSlot>>>;
} {
  const leadStaffEvents = isPopulated(props) ? props.leadStaffEvents : [];
  const supportingStaffEvents = isPopulated(props)
    ? props.supportingStaffEvents
    : null;
  const availableSlots = isPopulated(props) ? props.availableSlots : [];
  const timezone = isPopulated(props)
    ? props.timezone
    : MotionTimezone.NEW_YORK;

  const leadStaffEventsByDay = useMemo(
    () =>
      sanitizeAndGroupByDay(
        leadStaffEvents,
        props.week,
        timezone,
        props.minHour,
        props.maxHour,
      ),
    [leadStaffEvents, props.week, timezone, props.minHour, props.maxHour],
  );

  const supportingStaffEventsByDay = useMemo(
    () =>
      supportingStaffEvents
        ? sanitizeAndGroupByDay(
            supportingStaffEvents,
            props.week,
            timezone,
            props.minHour,
            props.maxHour,
          )
        : null,
    [supportingStaffEvents, props.week, timezone, props.minHour, props.maxHour],
  );

  const availableSlotsByDay = useMemo(
    () =>
      validateAndGroupByDay(
        availableSlots,
        props.week,
        timezone,
        props.minHour,
        props.maxHour,
      ),
    [availableSlots, props.week, timezone, props.minHour, props.maxHour],
  );

  return {
    leadStaffEventsByDay,
    supportingStaffEventsByDay,
    availableSlotsByDay,
  };
}
