
      export default {
  "possibleTypes": {
    "AppointmentHostInfo": [
      "ExternalProviderHostInfo",
      "StaffMemberHostInfo"
    ],
    "AssessmentQuestion": [
      "AssessmentNumericQuestion",
      "AssessmentPlainTextQuestion",
      "AssessmentSelectQuestion",
      "AssessmentSliderQuestion",
      "AssessmentYesNoQuestion"
    ],
    "AssessmentStep": [
      "AssessmentNumericQuestion",
      "AssessmentPlainTextQuestion",
      "AssessmentReadOnly",
      "AssessmentSelectQuestion",
      "AssessmentSliderQuestion",
      "AssessmentYesNoQuestion"
    ],
    "AssessmentSubmissionResult": [
      "AssessmentPage",
      "CompletedAssessmentSummary"
    ],
    "EffectiveTimeSlot": [
      "EffectiveAppointmentSlot",
      "EffectiveUnavailableTimeSlot"
    ],
    "Event": [
      "MedicalAppointment",
      "NonPatientEvent"
    ],
    "TimeSlot": [
      "AppointmentTimeSlot",
      "UnavailableTimeSlot"
    ]
  }
}
    