import { AppointmentMedium, AppointmentType } from "../../../../gql/graphql";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { some } from "lodash";
import SelectMediums, { validateMediums } from "../SelectMediums";
import SelectAppointmentType from "../../../SelectAppointmentType";
import { AppointmentConfigMap } from "../../../../libs/scheduleTemplates";

type Props = {
  originalAppointmentType: AppointmentType;
  appointmentTypeConfigurations: AppointmentConfigMap;
  onClose: () => void;
  onSubmit: (
    newAppointmentType: AppointmentType,
    mediumsUpdate: {
      mediums: ReadonlySet<AppointmentMedium>;
      locationId: string | null;
    } | null,
  ) => void;
};

export default function SwapAppointmentTypeDialog(props: Props) {
  const [newAppointmentType, setNewAppointmentType] =
    useState<AppointmentType | null>(null);

  const [mediums, setMediums] = useState<ReadonlySet<AppointmentMedium>>(
    new Set(),
  );
  const [locationId, setLocationId] = useState<string | null>(null);

  useEffect(() => {
    if (!newAppointmentType) {
      setMediums(new Set());
    } else {
      setMediums(
        new Set(
          props.appointmentTypeConfigurations[
            newAppointmentType
          ]!.supportedMediums,
        ),
      );
    }
  }, [newAppointmentType]);

  const handleChangeMedium = (
    mediums: ReadonlySet<AppointmentMedium>,
    locationId: string | null,
  ) => {
    setMediums(mediums);
    setLocationId(locationId);
  };

  const mediumsUpdateNeeded = mediumsNeedUpdate(
    props.originalAppointmentType,
    newAppointmentType,
    props.appointmentTypeConfigurations,
  );

  const mediumErrors = mediumsUpdateNeeded
    ? validateMediums(mediums, locationId)
    : {};
  const hasErrors = some(mediumErrors, (error) => error !== undefined);

  const handleSubmit = () => {
    if (!newAppointmentType || hasErrors) {
      return;
    }
    if (mediumsUpdateNeeded) {
      props.onSubmit(newAppointmentType, {
        mediums,
        locationId: mediums.has(AppointmentMedium.InClinic) ? locationId : null,
      });
    } else {
      props.onSubmit(newAppointmentType, null);
    }
  };

  return (
    <DialogWrapper
      onClose={props.onClose}
      onSubmit={handleSubmit}
      submitDisabled={!newAppointmentType || hasErrors}
    >
      <Box display="flex" flexDirection="column" gap={2} mt={1}>
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body2">Replace</Typography>
          <Typography variant="body2" fontWeight="bold">
            {props.appointmentTypeConfigurations[props.originalAppointmentType]
              ?.internalName || props.originalAppointmentType}
          </Typography>
          <Typography variant="body2">with</Typography>
          <SelectAppointmentType
            beta={true}
            value={newAppointmentType}
            onChange={setNewAppointmentType}
            label="New Appointment Type"
            shouldDisableType={(config) =>
              config.appointmentType === props.originalAppointmentType
            }
          />
        </Box>
        {mediumsUpdateNeeded && (
          <>
            <Typography variant="body2" color="text.secondary">
              This new appointment type supports different modalities. Please
              specify new mediums to use for all updated slots.
            </Typography>
            <SelectMediums
              allowedMediums={
                newAppointmentType
                  ? new Set(
                      props.appointmentTypeConfigurations[
                        newAppointmentType
                      ]!.supportedMediums,
                    )
                  : new Set()
              }
              mediums={mediums}
              locationId={locationId}
              onChange={handleChangeMedium}
              clinicError={mediumErrors.clinicError}
              mediumError={mediumErrors.mediumsError}
            />
          </>
        )}
        <Typography variant="body2" color="text.secondary">
          All matching slots in this schedule will be updated.
        </Typography>
      </Box>
    </DialogWrapper>
  );
}

function DialogWrapper(props: {
  children: React.ReactNode;
  onClose: () => void;
  onSubmit: () => void;
  submitDisabled: boolean;
}) {
  return (
    <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>Replace Appointment Type</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={() => props.onSubmit()}
          color="primary"
          disabled={props.submitDisabled}
          variant="contained"
        >
          Replace
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Returns true if the mediums need to be updated when swapping appointment types.
 */
function mediumsNeedUpdate(
  oldAppointmentType: AppointmentType,
  newAppointmentType: AppointmentType | null,
  appointmentTypeConfigurations: AppointmentConfigMap,
): boolean {
  if (!newAppointmentType) {
    return false;
  }
  if (!(oldAppointmentType in appointmentTypeConfigurations)) {
    return true;
  }

  const oldSupportedMediums =
    appointmentTypeConfigurations[oldAppointmentType]!.supportedMediums;
  const newSupportedMediums = new Set(
    appointmentTypeConfigurations[newAppointmentType]!.supportedMediums,
  );

  // If every medium from the old type is still supported, no need to update.
  return some(
    oldSupportedMediums,
    (medium) => !newSupportedMediums.has(medium),
  );
}
