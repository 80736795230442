import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import moment, { Moment } from "moment";
import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { AVAILABLE_APPOINTMENT_SLOTS } from "../../../../libs/queries";
import { AppointmentSlot } from "../../../../gql/graphql";

type Props = {
  staffId: string;
  appointmentType: string;
  appointmentMedium: string;
  supportingPractitionerId: string | null;
  supportingPractitionerType: string | null;
  clinicId: string | null;
  date: Moment;
  durationInMinutes: number;
  highlightedStart: number | null;
  patientTimezone: string | null;
  onAvailableSlotClicked: (slot: AppointmentSlot) => void;
};

/**
 * Shows availability info for the given staff member.
 */
export default function StaffAvailabilityHelper(props: Props) {
  const searchFromDay = moment.max(moment(), props.date.startOf("day"));
  const endOfDay: number = searchFromDay.endOf("day").unix(); // unix timestamp in seconds

  const [getAvailableAppointments, { loading, data, error }] = useLazyQuery(
    AVAILABLE_APPOINTMENT_SLOTS,
    {
      fetchPolicy: "network-only",
    },
  );

  const refreshAvailableSlots = () => {
    getAvailableAppointments({
      variables: {
        appointmentType: props.appointmentType,
        appointmentMedium: props.appointmentMedium,
        searchFromDay: searchFromDay.format("YYYY-MM-DD"),
        filterByLeadPractitionerIds: [props.staffId],
        supportingPractitionerType: props.supportingPractitionerType,
        filterBySupportingPractitionerIds: props.supportingPractitionerId
          ? [props.supportingPractitionerId]
          : [],
        filterByClinicIds: props.clinicId ? [props.clinicId] : [],
        filterByTimeOfDay: [],
      },
    }).then(/* ignore promise result */);
  };

  // Refresh time slots when staff or date changes.
  useEffect(() => {
    refreshAvailableSlots();
  }, [props.staffId, props.durationInMinutes, props.date.startOf("day")]);

  const availableSlots =
    data?.availableAppointmentSlotsAdmin.filter(
      (s: any) => s.startTime <= endOfDay,
    ) || [];

  const isSelected = (slot: AppointmentSlot): boolean => {
    return slot.startTime === props.highlightedStart;
  };

  return (
    <Box sx={styles.progressWrapper}>
      <List
        dense
        sx={{
          width: "100%",
          maxHeight: `220px`,
          overflow: "auto",
          marginBottom: 1,
        }}
        subheader={
          <ListSubheader sx={{ lineHeight: "inherit" }}>
            <Typography
              variant="body1"
              mt={1}
              mb={0}
              sx={{ fontWeight: "bold" }}
            >
              Available Slots
            </Typography>
          </ListSubheader>
        }
      >
        {availableSlots.map((slot: any) => (
          <ListItem
            button
            key={slot.startTime}
            onClick={(_) => props.onAvailableSlotClicked(slot)}
            sx={isSelected(slot) ? styles.selectedSlot : null}
          >
            <ListItemText>
              {DateTime.fromSeconds(slot.startTime)
                .setZone(props.patientTimezone || undefined)
                .toFormat("h:mm")}
              {" - "}
              {DateTime.fromSeconds(slot.endTime)
                .setZone(props.patientTimezone || undefined)
                .toFormat("h:mm a ZZZZ")}
            </ListItemText>
          </ListItem>
        ))}
        {!error && !loading && !availableSlots.length && (
          <ListItem>
            <ListItemText>
              No available times for the selected day/duration.
            </ListItemText>
          </ListItem>
        )}
        {error && (
          <ListItem>
            <ListItemText primaryTypographyProps={{ color: "error.main" }}>
              Failed to retrieve availability.
              <br />
              Please reload the page and try again.
            </ListItemText>
          </ListItem>
        )}
      </List>
      {loading && (
        <CircularProgress color="primary" size={36} sx={styles.slotsProgress} />
      )}
    </Box>
  );
}

const styles = {
  progressWrapper: {
    position: "relative",
    width: "100%",
    minHeight: "150px",
    minWidth: "180px",
  },
  slotsProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-10px",
    marginLeft: "-18px",
  },
  selectedSlot: {
    fontWeight: "bold",
    backgroundColor: "#E5E8EC",
  },
};
