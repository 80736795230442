import { useQuery } from "@apollo/client/react/hooks";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import SelectClinic, { GET_CLINICS } from "../../../SelectClinic";
import React from "react";
import DetailedAlert from "../../../DetailedAlert";
import { keyBy } from "lodash";

type Props = {
  originalLocationId: string;
  onClose: () => void;
  onSubmit: (newLocationId: string) => void;
};

export default function SwapLocationDialog(props: Props) {
  const [newLocationId, setNewLocationId] = React.useState<string | null>(null);
  const { data, loading, error, refetch } = useQuery(GET_CLINICS);

  if (error) {
    return (
      <DialogWrapper onClose={props.onClose}>
        <DetailedAlert
          message="Oops! Something went wrong. Please try again."
          additionalDetails={error}
          retry={() => refetch()}
        />
      </DialogWrapper>
    );
  }

  if (loading || !data) {
    return (
      <DialogWrapper onClose={props.onClose}>
        <CircularProgress />
      </DialogWrapper>
    );
  }

  const clinicsById = keyBy(data.clinics, (clinic) => clinic.id);
  return (
    <DialogWrapper
      onClose={props.onClose}
      onSubmit={newLocationId ? () => props.onSubmit(newLocationId) : undefined}
    >
      <Box display="flex" flexDirection="column" gap={2} mt={1}>
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body2">Replace</Typography>
          <Typography variant="body2" fontWeight="bold">
            {clinicsById[props.originalLocationId].name}
          </Typography>
          <Typography variant="body2">with</Typography>
          <SelectClinic
            multiple={false}
            selectedClinicId={newLocationId}
            onSelectedClinicChange={setNewLocationId}
            size="small"
            shouldDisableClinic={(clinicId) =>
              clinicId === props.originalLocationId
            }
            sx={{ flex: 1 }}
          />
        </Box>
        <Typography variant="body2" color="text.secondary">
          All matching slots in this schedule will be updated.
        </Typography>
      </Box>
    </DialogWrapper>
  );
}

function DialogWrapper(props: {
  onClose: () => void;
  onSubmit?: () => void;
  children: React.ReactNode;
}) {
  return (
    <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>Replace Location</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onSubmit?.()}
          disabled={!props.onSubmit}
        >
          Replace
        </Button>
      </DialogActions>
    </Dialog>
  );
}
