// @flow
import {DateTime} from "luxon";
import type {DailySchedule, DayOfWeek, PlannedSchedule, StaffSchedule} from "../generated/flow_types";
import {find, groupBy, mapValues, sortBy} from "lodash";

export type DailyHours = {
  dayISO: string,
  explanation: string,
  hours: string[]
};

export function getEffectiveHours(staffSchedule: StaffSchedule, epochSecond: number): DailyHours[] {
  const date = DateTime.fromSeconds(epochSecond);

  let startOfWeek;
  if (date.weekday === 7) {
    startOfWeek = date.plus({day: 1}).startOf('week');
  } else {
    startOfWeek = date.startOf('week');
  }

  return [...new Array(6)].map((_, index) =>
    getDailyHours(staffSchedule, startOfWeek.plus({day: index}).toFormat('kkkk-LL-dd')));
}

function getDailyHours(staffSchedule: StaffSchedule, dayISO: string): DailyHours {
  const date = DateTime.fromISO(dayISO).startOf('day');

  // Sort by decreasing "in effect" date and pick the first one that applies
  const sortedPlannedSchedules = sortBy(staffSchedule.plannedSchedules, plan => -1 * (plan.inEffectAfter || 0));
  const effectiveSchedule: PlannedSchedule = find(sortedPlannedSchedules, schedule =>
    DateTime.fromSeconds(schedule.inEffectAfter || 0) <= date
  );

  if (!effectiveSchedule) {
    return {
      explanation: 'No planned schedule takes effect before the given date',
      hours: [],
      dayISO
    };
  }

  // Find the effective working hours for the day
  const nthWeekInMonth = Math.floor((date.day - 1) / 7) + 1; // e.g. 2nd Monday of the month
  let weeklySchedule;
  const explanations = [];
  const dayDescription = `${nthWeekInMonth}${nth(nthWeekInMonth)} ${date.toFormat("cccc")} in month`;
  if (!effectiveSchedule.alternateWeeklySchedule) {
    weeklySchedule = effectiveSchedule.weeklySchedule;
    explanations.push(`Using single week work hours`);
  } else if (nthWeekInMonth % 2 === 0) {
    weeklySchedule = effectiveSchedule.alternateWeeklySchedule
    explanations.push(`Using alternate week work hours (${dayDescription})`);
  } else {
    weeklySchedule = effectiveSchedule.weeklySchedule;
    explanations.push(`Using regular week work hours (${dayDescription})`);
  }

  const hours = find(weeklySchedule, schedule => schedule.dayOfWeek === date.toFormat("cccc").toUpperCase())?.hours || []
  return {
    hours,
    explanation: explanations.join(" || "),
    dayISO
  }
}

const nth = function (d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

