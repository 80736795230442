import {
  AssessmentStatus,
  VisitNote_AssessmentFieldsFragment,
} from "../../../../gql/graphql";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { graphql } from "../../../../gql";
import { useState } from "react";
import { Reference, StoreObject, useMutation } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import DetailedAlert from "../../../DetailedAlert";

type Props = {
  visitNoteId: string;
  assessment: VisitNote_AssessmentFieldsFragment;
  onClose: () => void;
};

export const DETACH_ASSESSMENT_MUTATION = graphql(`
  mutation DetachAssessment(
    $visitNoteId: String!
    $assessmentId: String!
    $alsoDelete: Boolean!
  ) {
    detachAssessment(
      visitNoteId: $visitNoteId
      assessmentId: $assessmentId
      alsoDelete: $alsoDelete
    ) {
      ...VisitNoteFields
    }
  }
`);

/**
 * Dialog to detach an assessment from a note, and optionally delete it.
 */
export function DetachAssessmentDialog(props: Props) {
  const [deleteAssessment, setDeleteAssessment] = useState(false);
  const allowDeletion = props.assessment.status !== AssessmentStatus.Completed;

  const [detachAssessment, { loading, error }] = useMutation(
    DETACH_ASSESSMENT_MUTATION,
    {
      variables: {
        visitNoteId: props.visitNoteId,
        assessmentId: props.assessment.id,
        alsoDelete: deleteAssessment,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const maybeClose = () => {
    if (loading) return;
    props.onClose();
  };

  const handleDetachAssessment = async () => {
    await detachAssessment({
      update(cache, _, options) {
        // Remove from patient assessments if it was deleted
        if (options?.variables?.alsoDelete) {
          cache.modify({
            fields: {
              assessmentsForPatient(patientAssessments, { readField }) {
                return patientAssessments.filter(
                  (aiRef: Reference | StoreObject | undefined) =>
                    readField("id", aiRef) !== props.assessment.id,
                );
              },
            },
          });
        }
      },
    });
    props.onClose();
  };

  return (
    <Dialog open={true} onClose={maybeClose} maxWidth="md" fullWidth>
      <DialogTitle>Detach Assessment</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1}>
          <DialogContentText>
            The "{props.assessment.name}" assessment will be removed from this
            note.
          </DialogContentText>
          {allowDeletion && (
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deleteAssessment}
                    onChange={(e) => setDeleteAssessment(e.target.checked)}
                    disabled={loading}
                  />
                }
                label="Also permanently delete the assessment"
              />
            </FormControl>
          )}
          {deleteAssessment ? (
            <Alert severity="warning">
              This will also <b>permanently</b> delete the assessment. This
              action cannot be undone.
            </Alert>
          ) : (
            <Alert severity="info">
              The assessment will still be accessible from the Assessments page
              and can be re-added to the note if needed.
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {error && (
          <Box>
            <DetailedAlert
              message="Oops! Something went wrong. Please try again."
              additionalDetails={error}
            />
          </Box>
        )}
        <Button onClick={maybeClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          color={deleteAssessment ? "error" : "primary"}
          onClick={handleDetachAssessment}
        >
          Detach {deleteAssessment && "and Delete"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
