import { Reference, StoreObject, useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client/react/hooks";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";

import { keyBy, some } from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { enableNewBooking, welkinPortalBaseUrl } from "../../../../config";
import { CANCEL_APPOINTMENT_MUTATION } from "../../../../libs/queries";
import DetailedAlert from "../../../DetailedAlert";
import type {
  Notification,
  ToggleEvent,
} from "../PatientNotificationPreferences";
import PatientNotifications from "../PatientNotificationPreferences";
import { LocationDetails } from "./locationDetails";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { Link as RouterLink } from "react-router-dom";
import { maxPatientScreenWidth } from "../../../../theme";
import { graphql } from "../../../../gql";
import {
  AppointmentHostInfoFieldsFragment,
  AppointmentType,
  Patient,
  StaffMemberHostInfo,
} from "../../../../gql/graphql";
import { styles } from "./styles";
import { NewSlot } from "./NewSlot";
import { getAppointmentTypeLabel } from "../../../../libs/booking";
import { GET_APPOINTMENT_TYPE_CONFIG } from "../../../SelectAppointmentType";
import { amber } from "@mui/material/colors";

type Props = {
  patient: Patient;
};

const SCHEDULING_VISIT_NOTES_QUERY = graphql(`
  query SchedulingVisitNotes($patientId: String!) {
    allVisitNotes(patientId: $patientId) {
      id
      appointment {
        id
      }
    }
  }
`);

export const GET_APPOINTMENT_BY_ID = graphql(`
  query GetAppointmentById($appointmentId: String!) {
    appointmentByIdAdmin(appointmentId: $appointmentId) {
      ...MedicalAppointmentFields
    }
  }
`);

export default function VewEditAppointment(props: Props) {
  const match = useRouteMatch<{ appointmentId: string }>();
  const history = useHistory();
  const appointmentId = match.params.appointmentId;
  const [rescheduleMode, setRescheduleMode] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [notifyPatientCancelled, setNotifyPatientCancelled] = useState(true);
  const [preventUpdatingPastAppointment, setPreventUpdatingPastAppointments] =
    useState(true);

  const { data, loading, error, refetch } = useQuery(GET_APPOINTMENT_BY_ID, {
    variables: {
      appointmentId: appointmentId,
    },
  });

  const {
    data: visitNotes,
    loading: visitNotesLoading,
    error: visitNotesError,
  } = useQuery(SCHEDULING_VISIT_NOTES_QUERY, {
    variables: {
      patientId: props.patient.id,
    },
  });
  const hasVisitNote = some(
    visitNotes?.allVisitNotes,
    (note) => note.appointment.id === appointmentId,
  );

  const { data: appointmentTypeConfigurationData } = useQuery(
    GET_APPOINTMENT_TYPE_CONFIG,
    {
      variables: {
        beta: true,
      },
    },
  );

  const [
    cancelAppointment,
    {
      data: cancelAppointmentResult,
      loading: cancelAppointmentLoading,
      error: cancelAppointmentError,
    },
  ] = useMutation(CANCEL_APPOINTMENT_MUTATION, {
    notifyOnNetworkStatusChange: true,
    update(cache) {
      // Remove from cached list of patient appointments.
      cache.modify({
        fields: {
          appointmentsForPatientAdmin(existingAppointmentRefs, { readField }) {
            return existingAppointmentRefs.filter(
              (appointmentRef: Reference | StoreObject | undefined) =>
                readField("id", appointmentRef) !== appointmentId,
            );
          },
        },
      });
      // Evict from the cache to force a refresh if the user navigates back to
      // this page. Needed to refresh the "cancelled" status.
      const cacheId = cache.identify({
        id: appointmentId,
        __typename: "MedicalAppointment",
      });
      cache.evict({ id: cacheId });
      cache.gc();
    },
  });

  useEffect(() => {
    if (cancelAppointmentResult?.cancelAppointmentAdmin) {
      history.push(`/patient/${props.patient.id}/schedule?canceled=success`);
    }
  }, [cancelAppointmentResult]);

  useEffect(() => {
    if (cancelAppointmentError) {
      setDeleteDialogOpen(false);
    }
  }, [cancelAppointmentError]);

  const handleSwitchCancelledNotification = (checked: boolean) => {
    setNotifyPatientCancelled(checked);
  };

  if (loading) {
    return (
      <BreadcrumbWrapper startTime={null}>
        <CircularProgress />
      </BreadcrumbWrapper>
    );
  }

  const appointment = data?.appointmentByIdAdmin;
  if (error || !appointment) {
    return (
      <BreadcrumbWrapper startTime={null}>
        <DetailedAlert
          message="Oops! Failed to load appointment. Please try again."
          retry={() => refetch()}
          additionalDetails={error}
        />
      </BreadcrumbWrapper>
    );
  }

  const handleCancelAppointment = () => {
    cancelAppointment({
      variables: {
        appointmentId: appointmentId,
        notifyPatient: notifyPatientCancelled,
      },
    }).then(/*ignore promise results*/);
  };

  const appointmentTypeName = getAppointmentTypeLabel(
    appointment.appointmentType,
    keyBy(
      appointmentTypeConfigurationData?.appointmentTypeConfigurations,
      (config) => config.appointmentType,
    ),
    false,
  );

  const isExternal = appointment.appointmentType === AppointmentType.External;
  const isPast = DateTime.fromSeconds(appointment.startTime) <= DateTime.now();
  const isEnded = DateTime.fromSeconds(appointment.endTime) <= DateTime.now();
  const isCancelled = appointment.cancelled;

  let canceledAppointmentNotificationDisabledReason: string | null = null;
  let rescheduledAppointmentNotificationDisabledReason: string | null = null;
  if (isExternal) {
    canceledAppointmentNotificationDisabledReason =
      rescheduledAppointmentNotificationDisabledReason =
        "External appointments don't support notifications yet.";
  } else if (
    !props.patient.communicationPreferences.consentsToSMS &&
    !props.patient.communicationPreferences.consentsToEmails
  ) {
    canceledAppointmentNotificationDisabledReason =
      rescheduledAppointmentNotificationDisabledReason =
        "The patient does not consent to being contacted via email or SMS.";
  } else if (isPast) {
    canceledAppointmentNotificationDisabledReason =
      "No notification will be sent when cancelling past appointments.";
  }

  const cancelledNotifications: Notification[] = [
    {
      name: "Cancellation Confirmation",
      onSwitch: (event: ToggleEvent) => {
        handleSwitchCancelledNotification(event.target.checked);
      },
      value: notifyPatientCancelled,
    },
  ];

  const handleStartReschedule = () => {
    if (enableNewBooking()) {
      history.push(`${match.url}/reschedule`);
    } else {
      setRescheduleMode(true);
    }
  };

  return (
    <BreadcrumbWrapper startTime={appointment.startTime}>
      {/** Visit notes error */}
      <Snackbar
        open={!!visitNotesError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Oops! The operation failed. Please reload the page and try again.
        </Alert>
      </Snackbar>
      {/** Cancel appointment error */}
      <Snackbar
        open={!!cancelAppointmentError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Oops! An error occurred when updating the webpage. Please{" "}
          <Link href={`/patient/${props.patient.id}/schedule`}>click here</Link>{" "}
          to navigate back to the list of appointments and verify that the
          appointment was cancelled.
        </Alert>
      </Snackbar>
      <Typography variant="h6" color="primary">
        Appointment Details
      </Typography>
      {isCancelled && (
        <Alert severity="warning">
          This appointment has been <strong>cancelled</strong>.
        </Alert>
      )}
      <Grid container spacing={1} alignItems="center" sx={{ width: 1 }}>
        <Grid item xs={3}>
          <Typography variant="body2" sx={styles.fieldName}>
            Appointment Type
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2">{appointmentTypeName}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" sx={styles.fieldName}>
            Host
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2">
            {getHostName(appointment.hostInfo)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" sx={styles.fieldName}>
            Supporting Practitioner
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2">
            {(appointment.hostInfo as any).supportingStaff?.displayName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" sx={styles.fieldName}>
            Appointment Time
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          {isCancelled && (
            <Chip
              size="small"
              label="Cancelled"
              sx={{ backgroundColor: amber[200] }}
            />
          )}
          <Typography
            variant="body2"
            sx={{ textDecoration: isCancelled ? "line-through" : "none" }}
          >
            {renderAppointmentTime(
              appointment,
              props.patient.communicationPreferences.timezone ||
                Intl.DateTimeFormat().resolvedOptions().timeZone,
            )}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" sx={styles.fieldName}>
            Location Details
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <LocationDetails appointment={appointment} patient={props.patient} />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" sx={styles.fieldName}>
            Additional Notes for Patient
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2">{appointment.externalNotes}</Typography>
        </Grid>
      </Grid>
      {/* No show */}
      {isPast && preventUpdatingPastAppointment && (
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body2" color="text.secondary">
            This appointment {isEnded ? "took place" : "started"}{" "}
            {DateTime.fromSeconds(appointment.startTime).toRelative()}.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(_) => setPreventUpdatingPastAppointments(false)}
          >
            Update as no-show
          </Button>
        </Box>
      )}
      {/* Reschedule */}
      {rescheduleMode && (
        <NewSlot
          appointment={appointment}
          patientId={props.patient.id}
          communicationPreferences={props.patient.communicationPreferences}
          rescheduledAppointmentNotificationDisabledReason={
            rescheduledAppointmentNotificationDisabledReason
          }
          setRescheduleMode={setRescheduleMode}
        />
      )}
      {/*  View Mode Action Buttons */}
      {!rescheduleMode &&
        !isCancelled &&
        !(isPast && preventUpdatingPastAppointment) && (
          <Box display="flex" justifyContent="flex-end">
            <Box>
              <Button
                variant="outlined"
                disabled={cancelAppointmentError !== undefined}
                startIcon={<EventAvailableIcon />}
                onClick={handleStartReschedule}
                sx={styles.button}
              >
                Reschedule
              </Button>
              <LoadingButton
                loading={visitNotesLoading || cancelAppointmentLoading}
                disabled={
                  hasVisitNote ||
                  visitNotesError !== undefined ||
                  cancelAppointmentError !== undefined
                }
                variant="contained"
                color="error"
                startIcon={<EventBusyIcon />}
                onClick={(_) => setDeleteDialogOpen(true)}
                sx={[styles.button, styles.danger]}
              >
                Cancel Appointment
              </LoadingButton>
              {hasVisitNote && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    maxWidth={"400px"}
                    textAlign={"right"}
                  >
                    This appointment already has a visit note, so cancellation
                    is disabled. If you need to cancel it, you can go to "visit
                    notes" and delete the corresponding visit note first.
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        )}
      <Dialog
        open={deleteDialogOpen}
        onClose={(_) => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-descr"
      >
        <DialogTitle id="delete-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <PatientNotifications
            communicationPreferences={props.patient.communicationPreferences}
            notifications={cancelledNotifications}
            disabledReason={canceledAppointmentNotificationDisabledReason}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(_) => setDeleteDialogOpen(false)}
            variant="outlined"
            disabled={cancelAppointmentLoading}
            sx={styles.button}
          >
            Keep Appointment
          </Button>
          <LoadingButton
            loading={cancelAppointmentLoading}
            onClick={handleCancelAppointment}
            autoFocus
            variant="contained"
            startIcon={<EventBusyIcon />}
            sx={[styles.button, styles.danger]}
          >
            Cancel Appointment
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </BreadcrumbWrapper>
  );
}

const renderAppointmentTime = (
  appointment: { startTime: number; endTime: number },
  patientTimezone: string,
): string => {
  const durationInMinutes = Math.round(
    (appointment.endTime - appointment.startTime) / 60,
  );
  const startDateTime = DateTime.fromSeconds(appointment.startTime).setZone(
    patientTimezone,
  );
  return `${startDateTime.toFormat(
    "DDDD - t ZZZZ",
  )} (${durationInMinutes} minutes)`;
};

const getHostName = (hostInfo: AppointmentHostInfoFieldsFragment) => {
  if (hostInfo.__typename === "ExternalProviderHostInfo") {
    const profileUrl = `${welkinPortalBaseUrl()}/profiles/pdt-external-provider/${
      hostInfo.provider.id
    }`;
    return (
      <>
        {`${hostInfo.provider.displayName} (External Provider)`}
        <Link href={profileUrl} target="_blank" sx={{ marginLeft: 1 }}>
          View in Welkin
        </Link>
      </>
    );
  }
  return `${(hostInfo as StaffMemberHostInfo).staff.displayName} (Risalto)`;
};

const BreadcrumbWrapper = (props: {
  startTime: number | null;
  children: any;
}) => {
  const match = useRouteMatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const history = useHistory();

  return (
    <Box
      display="flex"
      flex={1}
      sx={{ overflowY: "auto" }}
      justifyContent="center"
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        flex={1}
        maxWidth={maxPatientScreenWidth}
      >
        <Breadcrumbs separator={<NavigateNextOutlinedIcon fontSize="small" />}>
          <Link
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            component={RouterLink}
            color="inherit"
            to={`/patient/${(match.params as any).patientId}/schedule`}
          >
            <CalendarMonthOutlinedIcon sx={{ height: 18, width: 18 }} />
            Schedule
          </Link>
          <Link
            component="button"
            color="inherit"
            underline="none"
            sx={{ cursor: "unset" }}
          >
            {props.startTime
              ? DateTime.fromSeconds(props.startTime).toLocaleString(
                  DateTime.DATETIME_SHORT,
                )
              : "loading"}
          </Link>
        </Breadcrumbs>
        <Paper
          sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 1 }}
        >
          {query.get("rescheduled") === "success" && (
            <Alert
              severity="success"
              onClose={(_) => history.push(location.pathname)}
            >
              Appointment rescheduled.
            </Alert>
          )}
          {props.children}
        </Paper>
      </Box>
    </Box>
  );
};
