import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import DailySlotsEdit from "../DailySlotsEdit";
import GridLines from "../GridLines";
import GridTimes from "../GridTimes";
import { AppointmentMedium, AppointmentType } from "../../../../gql/graphql";
import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  Modifier,
  pointerWithin,
  ClientRect,
  DragOverlay,
  useSensor,
  PointerSensor,
  useSensors,
} from "@dnd-kit/core";
import { Transform } from "@dnd-kit/utilities";
import {
  AppointmentConfigMap,
  getDayOfWeekName,
  HighlightRule,
  LocalSlot,
} from "../../../../libs/scheduleTemplates";
import React, { useEffect, useState } from "react";
import { groupBy } from "lodash";
import TimeSlotEdit from "../TimeSlotEdit";
import AddEditSlotDialog from "../AddEditSlotDialog";
import DuplicateDayDialog from "../DuplicateDayDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WeeklyInsightsEdit from "../WeeklyInsightsEdit";
import {
  timeIncrementInMinutes,
  timeIncrementToPixels,
} from "../../../../libs/time";

type Props = {
  minHour: number;
  businessHourStart: number;
  businessHourEnd: number;
  maxHour: number;
  appointmentTypeConfigurations: AppointmentConfigMap;
  slots: { [slotId: string]: LocalSlot };
  onMoveSlot: (
    slotId: string,
    day: number,
    offsetMinutes: number,
    copy: boolean,
  ) => void;
  onSaveSlot: (slot: LocalSlot) => void;
  onDeleteSlot: (slotId: string) => void;
  onDuplicateDay: (
    sourceDayOfWeek: number,
    targetDaysOfWeek: Array<number>,
  ) => void;
  overlaps: { [slotId: string]: number };
  onSwapLocations: (originalLocationId: string, newLocationId: string) => void;
  onSwapAppointmentTypes: (
    originalAppointmentType: AppointmentType,
    newAppointmentType: AppointmentType,
    mediumsUpdate: {
      mediums: ReadonlySet<AppointmentMedium>;
      locationId: string | null;
    } | null,
  ) => void;
  providerSupportsVirtual: boolean;
};

const timeLabelsWidth = 55;

export default function WeeklySlotsEdit(props: Props) {
  const [targetSlot, setTargetSlot] = useState<LocalSlot | null>(null);
  const [copying, setCopying] = useState(false);
  const [editingSlotId, setEditingSlotId] = useState<string | null>(null);
  const [addingSlot, setAddingSlot] = useState<{
    dayOfWeek: number;
    startMinutes: number;
  } | null>(null);
  const [duplicatingDay, setDuplicatingDay] = useState<number | null>(null);
  const [highlightRule, setHighlightRule] = useState<HighlightRule | null>(
    null,
  );
  const [isScrolled, setIsScrolled] = useState(false);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: timeIncrementToPixels,
    },
  });
  const sensors = useSensors(pointerSensor);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Alt") {
        setCopying(true);
      }
    };
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Alt") {
        setCopying(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  });

  const handleDragEnd = (event: DragEndEvent) => {
    setTargetSlot(null);
    const slotId = event.active.id;
    if (event.over == null || typeof slotId !== "string") {
      return;
    }
    const targetDayOfWeek =
      typeof event.over.id === "string"
        ? parseInt(event.over.id)
        : event.over.id;
    const offsetMinutes =
      Math.round(event.delta.y / timeIncrementToPixels) *
      timeIncrementInMinutes;
    props.onMoveSlot(slotId, targetDayOfWeek, offsetMinutes, copying);
  };

  const handleDragMove = (event: DragMoveEvent) => {
    const slotId = event.active.id;
    if (typeof slotId !== "string") {
      return;
    }
    const targetSlot = props.slots[slotId];
    const offsetMinutes =
      Math.round(event.delta.y / timeIncrementToPixels) *
      timeIncrementInMinutes;
    setTargetSlot({
      ...targetSlot,
      startMinutes: targetSlot.startMinutes + offsetMinutes,
      endMinutes: targetSlot.endMinutes + offsetMinutes,
    });
  };

  const slotsByDay = groupBy(props.slots, (slot) => slot.dayOfWeek);

  const handleClickAddSlot = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { clientX, clientY } = event;
    const rect = event.currentTarget.getBoundingClientRect();
    const y = clientY - rect.top; // y position within the element
    const offsetMinutes =
      Math.round((y + 1) / timeIncrementToPixels) * timeIncrementInMinutes -
      timeIncrementInMinutes;
    const x = clientX - rect.left; // x position within the element
    const day = Math.floor((x / rect.width) * 5) + 1;
    setEditingSlotId(null);
    setAddingSlot({
      dayOfWeek: day,
      startMinutes: props.minHour * 60 + offsetMinutes,
    });
  };

  const handleClickEditSlot = (slotId: string) => {
    setEditingSlotId(slotId);
    setAddingSlot(null);
  };

  const handleSaveSlot = (slot: LocalSlot) => {
    props.onSaveSlot(slot);
    setEditingSlotId(null);
    setAddingSlot(null);
  };

  const handleDeleteSlot = () => {
    if (editingSlotId) {
      props.onDeleteSlot(editingSlotId);
      setEditingSlotId(null);
      setAddingSlot(null);
    }
  };

  const handleDuplicateDay = (targetDays: Array<number>) => {
    if (targetDays.length > 0) {
      props.onDuplicateDay(duplicatingDay!, targetDays);
    }
    setDuplicatingDay(null);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setIsScrolled(event.currentTarget.scrollTop > 10);
  };

  return (
    <Box display="flex" gap={1} minHeight={0} pt={1} px={1}>
      {/* Main panel */}
      <Box display="flex" flexDirection="column" minHeight={0} flex={1}>
        {/* Header */}
        <Box
          display="flex"
          p={0.5}
          borderBottom={isScrolled ? "1px solid #dedede" : "none"}
        >
          <Box width={timeLabelsWidth} />
          <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" flex={1}>
            {Array.from({ length: 5 }, (_, i) => i + 1).map((dayOfWeek) => (
              <Box
                key={dayOfWeek}
                display="flex"
                gap={1}
                justifyContent="center"
              >
                <Typography>{getDayOfWeekName(dayOfWeek)}</Typography>
                <Tooltip title="Copy to other days...">
                  <IconButton
                    onClick={() => setDuplicatingDay(dayOfWeek)}
                    size="small"
                  >
                    <ContentCopyIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
          </Box>
        </Box>
        {/*  Grid */}
        <Box
          display="flex"
          minHeight={0}
          sx={{ overflowY: "auto" }}
          flex={1}
          pt={1}
          pb={2}
          onScroll={handleScroll}
        >
          <Box width={timeLabelsWidth}>
            <GridTimes startHour={props.minHour} endHour={props.maxHour} />
          </Box>
          <Box position="relative" flex={1}>
            <DndContext
              onDragEnd={handleDragEnd}
              onDragCancel={() => setTargetSlot(null)}
              onDragMove={handleDragMove}
              modifiers={[modifier]}
              collisionDetection={pointerWithin}
              sensors={sensors}
            >
              <Box
                display="grid"
                gridTemplateColumns="repeat(5, 1fr)"
                zIndex={1}
                position="relative"
                onClick={handleClickAddSlot}
              >
                {Array(5)
                  .fill(null)
                  .map((_, i) => (
                    <DailySlotsEdit
                      key={i}
                      day={i + 1}
                      slots={slotsByDay[`${i + 1}`] || []}
                      minHour={props.minHour}
                      maxHour={props.maxHour}
                      appointmentTypeConfigurations={
                        props.appointmentTypeConfigurations
                      }
                      movingSlotId={targetSlot?.id}
                      overlaps={props.overlaps}
                      onClick={handleClickEditSlot}
                      highlightRule={highlightRule}
                    />
                  ))}
              </Box>
              <DragOverlay
                dropAnimation={null}
                style={{ cursor: copying ? "copy" : "grabbing" }}
              >
                {targetSlot && (
                  <TimeSlotEdit
                    slot={targetSlot}
                    variant={copying ? "copyTarget" : "moveTarget"}
                    appointmentTypeConfigurations={
                      props.appointmentTypeConfigurations
                    }
                  />
                )}
              </DragOverlay>
            </DndContext>
            <Box position="absolute" top={0} left={0} width="100%" zIndex={0}>
              <GridLines startHour={props.minHour} endHour={props.maxHour} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box p={2}>
        <WeeklyInsightsEdit
          slots={props.slots}
          appointmentTypeConfigurations={props.appointmentTypeConfigurations}
          onSwapAppointmentTypes={props.onSwapAppointmentTypes}
          onSwapLocations={props.onSwapLocations}
          onHighlightChange={setHighlightRule}
        />
      </Box>
      {editingSlotId && (
        <AddEditSlotDialog
          operation="edit"
          appointmentTypeConfigurations={props.appointmentTypeConfigurations}
          slots={props.slots}
          editSlotId={editingSlotId}
          onClose={() => setEditingSlotId(null)}
          onSaveSlot={handleSaveSlot}
          onDeleteSlot={handleDeleteSlot}
          providerSupportsVirtual={props.providerSupportsVirtual}
        />
      )}
      {addingSlot && (
        <AddEditSlotDialog
          operation="add"
          appointmentTypeConfigurations={props.appointmentTypeConfigurations}
          slots={props.slots}
          onSaveSlot={handleSaveSlot}
          onClose={() => setAddingSlot(null)}
          dayOfWeek={addingSlot.dayOfWeek}
          startMinutes={addingSlot.startMinutes}
          providerSupportsVirtual={props.providerSupportsVirtual}
        />
      )}
      {duplicatingDay && (
        <DuplicateDayDialog
          sourceDay={duplicatingDay}
          onDuplicate={handleDuplicateDay}
        />
      )}
    </Box>
  );
}

const modifier: Modifier = (args) => {
  if (args.active == null) {
    return args.transform;
  }
  const { draggingNodeRect, transform } = args;
  if (draggingNodeRect == null || args.over == null) {
    return transform;
  }
  const boundingRectTransform = restrictToBoundingRect(
    transform,
    draggingNodeRect,
    args.over.rect,
  );
  // Round up/down to nearest increment.
  return {
    ...boundingRectTransform,
    y:
      Math.ceil(boundingRectTransform.y / timeIncrementToPixels) *
      timeIncrementToPixels,
  };
};

// Copied from @dnd-kit utilities
function restrictToBoundingRect(
  transform: Transform,
  rect: ClientRect,
  boundingRect: ClientRect,
): Transform {
  const value = {
    ...transform,
  };

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top;
  } else if (
    rect.bottom + transform.y >=
    boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom;
  }

  // Pin the droppable to the right edge of the bounding rect (minus the margin)
  value.x = boundingRect.left + boundingRect.width - rect.right - 8;
  return value;
}
