import { EventFieldsFragment } from "../../../../../../../gql/graphql";
import { Box, Link, Popover, Tooltip, Typography } from "@mui/material";
import {
  getHeightInPixels,
  MotionTimezone,
} from "../../../../../../../libs/time";
import { grey } from "@mui/material/colors";
import { getAppointmentTypeColor } from "../../../../../../../libs/colors";
import {
  useCachedAppointmentTypeConfigurations,
  useCachedClinics,
} from "../../../../../../../hooks/commonQueries";
import React, { useState } from "react";
import { DateTime, Interval } from "luxon";
import {
  getAppointmentTypeLabel,
  isMedicalAppointment,
} from "../../../../../../../libs/booking";
import AppointmentMediumInfo from "../../../../../../AppointmentMediumInfo";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EditIcon from "@mui/icons-material/Edit";

type Props = {
  event: EventFieldsFragment;
  timezone: MotionTimezone;
  flagLocation: boolean;
  rescheduling: boolean;
  style?: "normal" | "contrast";
};

/**
 * Renders a single event for a staff member.
 */
export default function StaffMemberEvent(props: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const heightInPixels = getHeightInPixels(props.event);
  const dense = heightInPixels <= 30;

  const appointmentTypeConfig = useCachedAppointmentTypeConfigurations(true);

  return (
    <>
      <Box
        height={`${heightInPixels}px`}
        display="flex"
        alignItems="stretch"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Box
          mb="1px"
          paddingY={dense ? 0 : 0.5}
          paddingX={0.5}
          border={`1px solid ${
            props.style === "contrast" ? grey[700] : grey[500]
          }`}
          borderRadius={1}
          bgcolor={getEventBackgroundColor(props.event, props.rescheduling)}
          flex={1}
          display="flex"
          alignItems="flex-start"
          boxShadow={!!anchorEl ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : undefined}
          overflow="hidden"
        >
          <Box display="flex" alignItems="flex-start" gap={1} flex={1}>
            {props.flagLocation && (
              <WarningAmberIcon
                color="error"
                sx={{ fontSize: dense ? "14px" : "20px" }}
              />
            )}
            <Typography variant="caption" color="text.secondary">
              {props.event.__typename === "MedicalAppointment"
                ? getAppointmentTypeLabel(
                    props.event.appointmentType,
                    appointmentTypeConfig,
                    false,
                  )
                : "Blocked"}
            </Typography>
            {props.rescheduling && (
              <Tooltip title="Original appointment being rescheduled">
                <EditIcon
                  color="action"
                  sx={{ fontSize: dense ? "14px" : "20px", marginLeft: "auto" }}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <EventDetails
          event={props.event}
          timezone={props.timezone}
          flagLocation={props.flagLocation}
        />
      </Popover>
    </>
  );
}

function getEventBackgroundColor(
  event: EventFieldsFragment,
  rescheduling: boolean,
): string {
  return event.__typename === "MedicalAppointment"
    ? getAppointmentTypeColor(
        event.appointmentType,
        "main",
        rescheduling ? 0.5 : 1,
      )
    : grey[200];
}

function EventDetails(props: {
  event: EventFieldsFragment;
  timezone: MotionTimezone;
  flagLocation: boolean;
}) {
  const appointmentTypeConfig = useCachedAppointmentTypeConfigurations(true);
  const medium = isMedicalAppointment(props.event)
    ? props.event.appointmentMedium
    : null;
  const clinicId = isMedicalAppointment(props.event)
    ? props.event.clinicId || null
    : null;

  const start = DateTime.fromSeconds(props.event.startTime, {
    zone: props.timezone,
  });
  const end = DateTime.fromSeconds(props.event.endTime, {
    zone: props.timezone,
  });
  return (
    <Box p={2} display="flex" flexDirection="column" gap={1} maxWidth={300}>
      <Box
        display="flex"
        gap={2}
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="body1">
          {props.event.__typename === "MedicalAppointment"
            ? getAppointmentTypeLabel(
                props.event.appointmentType,
                appointmentTypeConfig,
                false,
              )
            : "Blocked"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {Interval.fromDateTimes(start, end).toLocaleString(
            DateTime.TIME_SIMPLE,
          )}
        </Typography>
      </Box>
      {props.event.__typename === "NonPatientEvent" && (
        <Typography variant="body2" color="text.secondary">
          This provider is not available at this time. Check their Google
          calendar for more information.
        </Typography>
      )}
      {medium && (
        <Box display="flex" alignItems="center" gap={1}>
          <AppointmentMediumInfo
            medium={medium}
            clinicId={clinicId}
            clinicsById={useCachedClinics()}
            typography="body2"
          />
          {props.flagLocation && <WarningAmberIcon color="error" />}
        </Box>
      )}
      {isMedicalAppointment(props.event) && (
        <ViewAppointmentLink
          appointmentId={props.event.id}
          patientId={props.event.patientId}
        />
      )}
    </Box>
  );
}

function ViewAppointmentLink(props: {
  appointmentId: string;
  patientId: string;
}) {
  return (
    <Link
      href={`/patient/${props.patientId}/schedule/${props.appointmentId}`}
      target="_blank"
      variant="body2"
    >
      View appointment
    </Link>
  );
}
