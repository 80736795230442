// @flow checked 01/24/23
import React, { useState } from "react";
import moment from "moment";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { StaticDatePicker } from '@mui/x-date-pickers'
import CloseIcon from "@mui/icons-material/Close";
import { TimeOfDayValues } from "../../../../../../../generated/flow_types";
import type {TimeOfDay} from "../../../../../../../generated/flow_types";
import type {Dispatch, SetStateAction} from "../../../../../../../flow-typed/react";

const style = {
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  left: "50%",
  p: 2,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: 368,
};

type Props = {
  searchFromDay: string,
  filterByTimeOfDay: TimeOfDay[],
  onClose: () => void,
  onFiltersChange: (searchFromDay: string, filterByTimeOfDay: TimeOfDay[]) => void
};

const DateFilterModal = (props: Props) => {
  const [date, setDate] = useState(props.searchFromDay);
  const [time: TimeOfDay[], setTime: Dispatch<SetStateAction<TimeOfDay[]>>] = useState(props.filterByTimeOfDay);

  const timeOptions = [TimeOfDayValues.Morning, TimeOfDayValues.Afternoon];

  const handleApply = () => {
    props.onFiltersChange(date, time);
    props.onClose();
  };

  const handleClear = () => {
    setDate(moment().format("YYYY-MM-DD"));
    setTime([]);
  };

  const handleDateChange = (selected: moment$Moment) => {
    const formattedDay = selected.format("YYYY-MM-DD");
    setDate(formattedDay);
  };

  const handleSelect = ({ target: { value } }) => {
    setTime(value);
  };

  return (
    <Modal onClose={props.onClose} open={true}>
      <Box sx={style}>
        <Grid container>
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>
              <Typography color="primary" variant="h6" component="h2">
                Filter Date & Time
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={props.onClose}>
                <CloseIcon color="primary" />
              </IconButton>
            </Grid>
            <ListItem divider style={{ margin: "-8px 0 30px" }} />
          </Grid>
          <FormControl fullWidth>
            <InputLabel
              htmlFor="demo-simple-select-helper1"
              id="demo-simple-select-helper-label"
            >
              Slot Times
            </InputLabel>
            <Select
              id="demo-simple-select-helper1"
              label="Start Time"
              multiple
              onChange={handleSelect}
              placeholder="Start Time"
              renderValue={(selected) => selected.join(", ")}
              value={time}
            >
              {timeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={time.indexOf(option) > -1} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
            <Typography color="primary" style={{ margin: "24px 0 8px" }}>
              Choose start date below.
            </Typography>
            <div
              style={{
                border: "1px solid #DBDDE5",
                borderRadius: "4px",
                height: 358,
                margin: "auto",
                width: 368,
              }}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                onChange={handleDateChange}
                slotProps={{ textField: { variant: 'outlined' } }}
                value={moment(date)}
              />
            </div>
          </FormControl>
        </Grid>
        <Grid container mt={3} style={{ textAlign: "right" }}>
          <Grid item xs={12}>
            <Button onClick={handleClear} style={{ marginRight: "8px" }}>
              CLEAR
            </Button>
            <Button onClick={handleApply} variant="contained">
              APPLY
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DateFilterModal;
