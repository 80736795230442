// @flow
import * as React from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {useQuery} from "@apollo/client/react/hooks";
import gql from "graphql-tag";
import DetailedAlert from "../DetailedAlert";
import {Conversation} from "../sendbird/Conversation";
import {sortBy} from "lodash";
import {SendbirdConversationProvider} from "../sendbird/SendbirdConversationProvider";
import type {ApolloResult} from "../../flow-typed/apollo";
import type {ActiveConversation, Patient} from "../../generated/flow_types";
import {DateTime} from "luxon";
import {PATIENT_INFO_QUERY} from "../../libs/queries";

type PatientInfoQuery = {
  patient: Patient
};

type SelectedMember = {
  memberId: string,
  patientId: string
}

const CONVERSATIONS = gql`
    query Conversations {
        conversations {
            id
            member {
                id
                patientId
                displayName
            }
            state
            ownerId
            ownerName
            lastEventTime
            lastMemberReadTime
            lastMemberMessageTime
            lastMotionMessageTime
        }
    }`;

export default function AllConversations() {
  const [selectedMember, setSelectedMember] = React.useState(null)

  const {data, error, loading, refetch} = useQuery(CONVERSATIONS, {
    pollInterval: 10000
  });

  if (loading) {
    return (
      <WithLayout>
        <Paper sx={{padding: 2}}>
          <CircularProgress/>
        </Paper>
      </WithLayout>
    );
  }

  if (error) {
    return (
      <WithLayout>
        <Paper sx={{padding: 2}}>
          <DetailedAlert
            message="Failed to retrieve conversations"
            additionalDetails={error}
            retry={_ => refetch().catch(_ => {
            })}
          />
        </Paper>
      </WithLayout>
    );
  }

  const sortedConversations = sortBy(data?.conversations, c => c.lastMemberMessageTime)
  const pendingMotionAction = sortedConversations.filter(c => c.state === 'UNREAD' || c.state === 'NO_REPLY');
  const pendingPatientAction = sortedConversations.filter(c => c.state === 'OPEN');

  return (
    <WithLayout selectedMember={selectedMember}>
      <Paper sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        overflowY: "auto",
        gap: 1,
      }}>
        <Typography variant="h6" color="primary">Active Conversations</Typography>
        <Typography variant="body1">Pending Motion Action</Typography>
        <ConversationsTable
          conversations={pendingMotionAction}
          selectedMemberId={selectedMember?.memberId || null}
          onConversationSelected={(member) => {setSelectedMember({memberId: member.id, patientId: member.patientId})}}
        />
        <Box m={1}/>
        <Typography variant="body1">Pending Patient Response</Typography>
        <ConversationsTable
          conversations={pendingPatientAction}
          selectedMemberId={selectedMember?.memberId || null}
          onConversationSelected={(member) => {setSelectedMember({memberId: member.id, patientId: member.patientId})}}
        />
      </Paper>
    </WithLayout>
  );
}

const WithLayout = (props: { selectedMember?: ?SelectedMember, children: any }) => {
  return (
    <Box flex={1} sx={{
      alignSelf: 'stretch',
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      gap: 2,
      justifyItems: 'stretch',
      minHeight: 0,
      padding: 2
    }}>
      {props.children}
      {props.selectedMember ? (
        <SelectedConversation selectedMember={props.selectedMember}/>
      ) : (
        <Typography variant="body2" color="text.secondary">Select a conversation</Typography>
      )}
    </Box>
  );
}

const ConversationsTable = (props: {
  conversations: Array<ActiveConversation>,
  selectedMemberId: ?string,
  onConversationSelected: (member: {id: string, patientId: ?string}) => void
}) => {
  if (props.conversations.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary" fontStyle="italic">Nothing to show</Typography>
    );
  }
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Last Patient Message ▼</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.conversations.map(c => (
          <TableRow
            key={c.id}
            hover
            selected={props.selectedMemberId === c.member.id}
            onClick={_ => props.onConversationSelected(c.member)}
          >
            <TableCell>
              <Typography variant="body2">
                {c.member.displayName}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {getRelativeTime(c.lastMemberMessageTime)}
              </Typography>
            </TableCell>
          </TableRow>
        ))
        }

      </TableBody>
    </Table>
  );
}

const SelectedConversation = (props: {selectedMember: SelectedMember}) => {

  const {data}: ApolloResult<PatientInfoQuery> = useQuery(PATIENT_INFO_QUERY, {
    variables: {
      patientId: props.selectedMember.patientId
    },
    notifyOnNetworkStatusChange: true
  });

  const patient = data?.patient;

  return <SendbirdConversationProvider memberId={props.selectedMember.memberId}>
    <Conversation
      memberId={props.selectedMember.memberId}
      pollConversationState={false}
      showLinkToProfile={true}
      showTextWillNotBeSentWarning={patient ? !patient.communicationPreferences.consentsToSMS : false}
      showPhoneNumberMissingWarning={patient ? !patient.communicationPreferences.phone : false}
    />
  </SendbirdConversationProvider>;
}

function getRelativeTime(timestampISO: ?string): string {
  if (!timestampISO) {
    return 'N/A';
  }
  const date = DateTime.fromISO(timestampISO);
  return date.toUnixInteger() === 0 ? 'N/A' : date.toRelative();
}
