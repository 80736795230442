import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import { values } from "lodash";
import { bodyPartNames } from "../../libs/constants";
import { BodyPart } from "../../gql/graphql";

type Props = {
  value: Array<BodyPart>;
  onValueChange: (bodyParts: Array<BodyPart>) => void;
  error: string | null;
  disabled?: boolean;
};

export default function JointMultiSelect(props: Props) {
  return (
    <FormControl
      sx={{ minWidth: 150, flexShrink: 0 }}
      size="small"
      error={!!props.error}
    >
      <InputLabel id="joint-label">Joint(s)</InputLabel>
      <Select
        labelId="joint-label"
        multiple
        input={<OutlinedInput id="select-joints" label="Joint(s)" />}
        value={props.value}
        onChange={(event) =>
          props.onValueChange(event.target.value as Array<BodyPart>)
        }
        renderValue={(selected) => <JointChipsTruncated joints={selected} />}
        autoWidth
        disabled={props.disabled}
        sx={{ backgroundColor: "white" }}
      >
        {values(BodyPart).map((bodyPart) => (
          <MenuItem key={bodyPart} value={bodyPart}>
            <Checkbox checked={props.value.includes(bodyPart)} />
            <ListItemText primary={bodyPartNames[bodyPart] || bodyPart} />
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
}

type JointChipsTruncatedProps = {
  joints: Array<BodyPart>;
};

function JointChipsTruncated(props: JointChipsTruncatedProps) {
  const maxChips = 1;
  const displayedJoints = props.joints.slice(0, maxChips);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {displayedJoints.map((bodyPart) => (
        <Chip
          key={bodyPart}
          label={bodyPartNames[bodyPart] || bodyPart}
          size="small"
        />
      ))}
      {props.joints.length > maxChips && (
        <Chip label={`+${props.joints.length - maxChips}`} size="small" />
      )}
    </Box>
  );
}
