import {
  BodyPart,
  OrderConfig,
  OrderLevel,
  VisitNoteOrder,
} from "../../../../gql/graphql";
import { CircularProgress, Typography } from "@mui/material";
import { useOrderLines } from "../../../../hooks/orderLinesConfiguration";
import DetailedAlert from "../../../DetailedAlert";
import { isEmpty, values } from "lodash";
import {
  EditableOrderLine,
  OrderLineConfig,
} from "../../../../libs/orderLines";
import { bodyPartNames } from "../../../../libs/constants";

type Props = {
  orders: Array<VisitNoteOrder>;
};

/**
 * A read-only view of orders.
 */
export default function OrdersReadOnly(props: Props) {
  const { lines, linesConfig, loading, error, refetch } = useOrderLines(
    props.orders,
    [],
  );

  if (error) {
    return (
      <DetailedAlert
        message="Oops! Something went wrong. Please try again."
        additionalDetails={error}
        retry={refetch}
      />
    );
  }

  if (loading || !lines || !linesConfig) {
    return <CircularProgress />;
  }

  if (isEmpty(lines)) {
    return (
      <Typography variant="body2" color="text.secondary">
        No orders selected
      </Typography>
    );
  }

  return (
    <ul style={{ paddingLeft: 20, margin: 0 }}>
      {values(lines).map((line) => {
        const textParts = getTextParts(line, linesConfig);
        if (!textParts) {
          return null;
        }
        return (
          <li key={line.lineId}>
            <Typography variant="body2" component="span" fontWeight="bold">
              {textParts.orderName}
            </Typography>
            {textParts.bodyParts && (
              <>
                {/* Unbreakable space for clean copy-pasting. */}
                {"\u00A0\u00A0\u00A0"}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                >
                  {textParts.bodyParts}
                </Typography>
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
}

function getTextParts(
  line: EditableOrderLine,
  linesConfig: { [lineId: string]: OrderLineConfig },
): { orderName: string; bodyParts: string | null } | null {
  const lineConfig = linesConfig[line.lineId];
  if (!lineConfig) {
    return null;
  }
  if (lineConfig.type === "standalone") {
    return {
      orderName: lineConfig.order.name,
      bodyParts: getSelectedBodyPartsText(lineConfig.order, line.bodyParts),
    };
  }

  const selectedOrder = lineConfig.orders.find(
    (order) => order.id === line.selectedOrderId,
  );
  if (!selectedOrder) {
    // Should never happen
    return null;
  }
  return {
    orderName: selectedOrder.name,
    bodyParts: getSelectedBodyPartsText(selectedOrder, line.bodyParts),
  };
}

function getSelectedBodyPartsText(
  order: OrderConfig,
  bodyParts: Array<BodyPart>,
): string | null {
  if (order.level !== OrderLevel.Joint || bodyParts.length === 0) {
    return null;
  }

  return bodyParts.map((bodyPart) => bodyPartNames[bodyPart]).join(", ");
}
