import { useHistory, useLocation } from "react-router-dom";

type MotionSearchParams = {
  focused: boolean;
  assessmentId: string | null;
  staffId: string | null;
};

/**
 * Similar to React Router 6's "useSearchParams", but with type-safe parameters.
 */
export function useSearchParams(): [
  MotionSearchParams,
  (newParams: Partial<MotionSearchParams>) => void,
] {
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const params: MotionSearchParams = {
    focused: search.get("focused") === "true",
    assessmentId: search.get("assessmentId") || null,
    staffId: search.get("staffId") || null,
  };

  const setter = (newParams: Partial<MotionSearchParams>) => {
    for (const [key, value] of Object.entries(newParams)) {
      if (value === null || value === false) {
        search.delete(key);
      } else if (value !== undefined) {
        search.set(key, serialize(value));
      }
    }
    history.push({ search: search.toString() });
  };

  return [params, setter];
}

function serialize(value: boolean | string): string {
  if (value === true) {
    return "true";
  } else if (value === false) {
    return "false";
  } else {
    return value;
  }
}
