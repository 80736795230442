// @flow
import * as React from 'react';
import gql from "graphql-tag";
import type {MotionUser} from "../../../../generated/flow_types";
import {useQuery} from "@apollo/client/react/hooks";
import {
  Avatar, Box, Button,
  CircularProgress,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, FormControl, InputLabel,
  MenuItem, Select, TextField, Typography
} from "@mui/material";
import DetailedAlert from "../../../DetailedAlert";
import {useState} from "react";
import SendIcon from '@mui/icons-material/Send';
import {sortBy} from "lodash";

type Props = {
  message: string,
  onSendAs: (user: MotionUser) => void,
  onClose: () => void,
};

// Visible for testing.
export const MASQUERADE_USERS = gql`
    query masqueradeQuery {
        masqueradeableMotionUsers {
            id
            displayName
            credentialsAbbreviation
            avatarUrl
        }
    }
`;

export default function MasqueradeDialog(props: Props) {
  const {data, loading, error, refetch} = useQuery(MASQUERADE_USERS);
  const [selectedUserId, setSelectedUserId] = useState(null);

  if (loading) {
    return (
      <UserDialog onClose={props.onClose}>
        <DialogContent><CircularProgress /></DialogContent>
      </UserDialog>
    );
  }

  if (error) {
    return (
      <UserDialog onClose={props.onClose}>
        <DialogContent>
          <DetailedAlert
            message="Failed to load users"
            details={error}
            retry={_ => refetch().catch(_ => {})}
          />
        </DialogContent>
      </UserDialog>
    );
  }

  const users = sortBy(data?.masqueradeableMotionUsers, user => user.displayName);
  return (
    <UserDialog onClose={props.onClose}>
      <DialogContent>
        <Box mt={1} gap={2} display="flex" flexDirection="column">
          <TextField disabled={true} fullWidth value={props.message} label="Message" multiline={true} rows={2}/>
          <FormControl fullWidth>
            <InputLabel id="masquerade-user-select-label">Masquerade as…</InputLabel>
            <Select
              labelId="masquerade-user-select-label"
              label="Masquerade as…"
              fullWidth
              value={selectedUserId || ""}
              renderValue={userId => <MotionUserItem user={users.find(u => u.id === userId)} small/>}
              onChange={e => setSelectedUserId(e.target.value)}
            >
              {users.map(user => (
                <MenuItem key={user.id} value={user.id}>
                  <MotionUserItem user={user} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="caption">
            Refer to clinical SOPs for guidance on when it is appropriate to masquerade.
            All messages retain actual sender information for auditing purposes.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={_ => props.onClose()}>Cancel</Button>
        <Button
          startIcon={<SendIcon />}
          variant="contained"
          onClick={_ => props.onSendAs(users.find(u => u.id === selectedUserId))}
          disabled={!selectedUserId}
        >
          Send
        </Button>
      </DialogActions>
    </UserDialog>
  );
};

const UserDialog = (props: {onClose: () => void}) => {
  return (
    <Dialog open={true} onClose={props.onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Send As…</DialogTitle>
      {props.children}
    </Dialog>
  );
}

const MotionUserItem = (props: { user: MotionUser, small?: boolean }) => {
  const initials = props.user.displayName.split(" ").map(n => n[0]).join("");
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Avatar alt={props.user.displayName} src={props.user.avatarUrl} sx={props.small ? {width: 24, height: 24} : null}>
        <Typography sx={props.small ? {fontSize: 12} : null}>{initials}</Typography>
      </Avatar>
      <Typography>{props.user.displayName}</Typography>
    </Box>
  );
}
