import {
  getTopPositionInPixels,
  MotionTimezone,
} from "../../../../../../../libs/time";
import { EventFieldsFragment } from "../../../../../../../gql/graphql";
import { Box } from "@mui/material";
import StaffMemberEvent from "../StaffMemberEvent";
import { locationConflicts } from "../../../../../../../libs/booking";

type Props = {
  minHour: number;
  maxHour: number;
  timezone: MotionTimezone;
  events: Array<EventFieldsFragment>;
  // The location id of an appointment being booked/rescheduled, if any.
  tentativeAppointmentLocationId?: string | null;
  rescheduledAppointmentId: string | undefined;
  style?: "normal" | "contrast";
};

/**
 * Renders daily events for a single staff member.
 */
export default function StaffMemberDailyEvents(props: Props) {
  // Using height 0 to prevent the wrapper from blocking elements underneath.
  return (
    <Box height={0} position="relative" display="flex" flex={1}>
      {props.events.map((event) => (
        <Box
          key={event.id}
          position="absolute"
          top={getTopPositionInPixels(event, props.timezone, props.minHour)}
          width="100%"
        >
          <StaffMemberEvent
            event={event}
            timezone={props.timezone}
            style={props.style}
            flagLocation={locationConflicts(
              props.tentativeAppointmentLocationId,
              event,
            )}
            rescheduling={
              !!props.rescheduledAppointmentId &&
              event.id === props.rescheduledAppointmentId
            }
          />
        </Box>
      ))}
    </Box>
  );
}
