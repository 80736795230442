import React from "react";
import { CognitoUserSession } from "amazon-cognito-identity-js";

export type AuthenticationContextType = {
  /** The current session */
  session: CognitoUserSession | null;
  /** Refresh the current session from refresh tokens */
  refreshSession: () => Promise<CognitoUserSession>;
  /** Whether the Okta flow has been initiated */
  pendingOktaAuth: boolean;
  /**
   * Start a new Okta auth flow, optionally clearing out the current session
   * (which will unmount any authenticated route)
   */
  startNewAuth: (forceLogOut: boolean) => Promise<void>;
  /** Whether the refresh token has expired. */
  refreshTokenExpired: boolean;
};

export const AuthenticationContext =
  React.createContext<AuthenticationContextType>({
    session: null,
    refreshSession: () => Promise.reject("Not implemented"),
    pendingOktaAuth: false,
    startNewAuth: (_) => Promise.resolve(),
    refreshTokenExpired: false,
  });

export const useAuthentication = (): AuthenticationContextType => {
  return React.useContext(AuthenticationContext);
};

export enum UserRole {
  Admin = "Admin",
  Clinician = "Clinician",
  Ops = "Ops",
}

/**
 * Returns the (non-validated!) roles from the id token claims.
 */
export const useRoles = (): ReadonlySet<UserRole> => {
  const { session } = useAuthentication();
  if (!session) {
    return new Set();
  }

  const groupsPayload = session.getIdToken().payload["custom:external_groups"];
  if (!groupsPayload) {
    return new Set();
  }
  const externalGroups = new Set(groupsPayload.split(","));
  const roles: Set<UserRole> = new Set();
  // These names are defined in Okta as values of the "coreGroups" custom attribute
  if (externalGroups.has("core-admin")) {
    roles.add(UserRole.Admin);
  }
  if (externalGroups.has("core-clinician")) {
    roles.add(UserRole.Clinician);
  }
  if (externalGroups.has("core-ops")) {
    roles.add(UserRole.Ops);
  }
  return roles;
};

/**
 * Returns true if the user is authorized to manage schedules.
 */
export const useCanManageSchedules = (): boolean => {
  const roles = useRoles();
  return roles.has(UserRole.Admin) || roles.has(UserRole.Ops);
};
