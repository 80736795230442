import { fonts } from "../../theme";

const chatBase = {
  container: {
    display: "flex",
    alignItems: "flex-end"
  },
  bubble: {
    display: "inline-block",
    overflow: "hidden",
    borderRadius: 10
  },
  text: {
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 14,
    paddingBottom: 14,
    fontFamily: fonts.regular,
    fontSize: 14
  },
  nametag: {
    fontFamily: fonts.semiBold,
    color: "#3E4AB8",
    fontSize: 12
  },
  timestamp: {
    display: "block",
    fontSize: 11,
    color: "#bbb",
    textAlign: "left",
    marginTop: 4
  }
};

export default {
  me: {
    container: {
      flexDirection: "row-reverse",
      ...chatBase.container
    },
    bubble: {
      ...chatBase.bubble,
      backgroundColor: "#eee",
      borderBottomRightRadius: 0
    },
    text: {
      color: "#555558",
      ...chatBase.text
    },
    nametag: {
      ...chatBase.nametag,
      textAlign: "right",
      marginRight: 78
    },
    timestamp: {
      ...chatBase.timestamp,
      textAlign: "right",
      marginRight: 58
    }
  },
  other: {
    container: {
      ...chatBase.container
    },
    bubble: {
      ...chatBase.bubble,
      backgroundColor: "#3E4AB8",
      borderBottomLeftRadius: 0
    },
    text: {
      color: "#eef",
      ...chatBase.text
    },
    nametag: {
      ...chatBase.nametag,
      marginLeft: 26
    },
    timestamp: {
      ...chatBase.timestamp,
      marginLeft: 16
    }
  },
  link: {
    line: { marginTop: 13, marginBottom: 13 },
    text: {
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 17,
      fontFamily: fonts.semiBold,
      fontSize: 14,
      color: "#7ACCCC"
    }
  }
};
