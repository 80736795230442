import { ApolloError } from "@apollo/client";
import {
  buildOrderLinesConfiguration,
  EditableOrderLine,
  getOrderLines,
  OrderLineConfig,
} from "../libs/orderLines";
import { OrderSuggestion, VisitNoteOrder } from "../gql/graphql";
import { graphql } from "../gql";
import { useQuery } from "@apollo/client/react/hooks";
import { useMemo } from "react";
import { keyBy } from "lodash";

export type OrderLinesConfiguration = {
  linesConfig: { [lineId: string]: OrderLineConfig } | null;
  lines: { [lineId: string]: EditableOrderLine } | null;
  suggestionsByOrderId: { [orderId: string]: OrderSuggestion };
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<any>;
};

export const ORDER_QUERY = graphql(`
  query orders {
    orders {
      orderConfigurations {
        id
        name
        category
        level
        groupId
      }
      orderGroupConfigurations {
        id
        name
      }
    }
  }
`);

export function useOrderLines(
  orders: Array<VisitNoteOrder>,
  orderSuggestions: Array<OrderSuggestion>,
): OrderLinesConfiguration {
  const { data, loading, error, refetch } = useQuery(ORDER_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const suggestionsByOrderId = useMemo(() => {
    return keyBy(orderSuggestions, (suggestion) => suggestion.orderId);
  }, [orderSuggestions]);

  // useMemo needed here to avoid infinite re-render loop
  const { linesConfig, lines } = useMemo(() => {
    if (!data) {
      return {
        linesConfig: null,
        lines: null,
      };
    }
    const config = buildOrderLinesConfiguration(
      data.orders.orderConfigurations,
      data.orders.orderGroupConfigurations,
    );
    return {
      linesConfig: keyBy(config, (config) => config.lineId),
      lines: keyBy(getOrderLines(orders, config), (line) => line.lineId),
    };
  }, [data]);

  return {
    linesConfig,
    lines,
    suggestionsByOrderId,
    loading,
    error,
    refetch,
  };
}
