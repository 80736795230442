import { TargetWeek } from "../../../../../gql/graphql";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  currentTargetWeek,
  dateTimeFromTargetWeek,
  decrementWeek,
  incrementWeek,
  MotionTimezone,
  MotionWeekDay,
  targetWeekFromDateTime,
} from "../../../../../libs/time";
import React, { useEffect, useState } from "react";
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { formatTimezone } from "../../../../../libs/scheduleTemplates";

type Props = {
  week: TargetWeek;
  onChange: (week: TargetWeek) => void;
  timezone: MotionTimezone;
};

/**
 * Component to select a week.
 */
export default function WeekSelector(props: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState<MotionWeekDay>(1);
  const [disabled, setDisabled] = useState(false);

  const previousWeek = () => {
    props.onChange(decrementWeek(props.week));
  };

  const nextWeek = () => {
    props.onChange(incrementWeek(props.week));
  };

  const thisWeek = () => {
    props.onChange(currentTargetWeek());
  };

  /**
   * Disable the buttons for a short time after a change.
   * This helps avoid spamming the backend with tons of requests.
   */
  useEffect(() => {
    setDisabled(true);
    const timeout = setTimeout(() => {
      setDisabled(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [props.week]);

  const handleDayPicked = (value: DateTime | null) => {
    if (!value) {
      return;
    }
    // Luxon shows weeks starting on Mondays. If Sunday is selected, assume the user means to select the week that ends on Sunday.
    const selected = value.weekday === 7 ? value.minus({ day: 1 }) : value;
    setSelectedDayOfWeek(selected.weekday as MotionWeekDay);
    props.onChange(targetWeekFromDateTime(selected));
    setAnchorEl(null);
  };

  const formattedTimezone = formatTimezone(props.timezone);
  const handleDateClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (disabled) {
      return;
    }
    setAnchorEl(e.currentTarget);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box display="flex" alignItems="center" gap={1} paddingX={1}>
        <Button
          variant="outlined"
          onClick={thisWeek}
          size="small"
          disabled={disabled}
        >
          Today
        </Button>
        <Box display="flex" alignItems="center">
          <IconButton onClick={previousWeek} disabled={disabled}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton onClick={nextWeek} disabled={disabled}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <Typography
          variant="h6"
          onClick={handleDateClick}
          sx={{ cursor: "pointer" }}
        >
          {getMonthLabel(props.week)}
        </Typography>
        <Chip size="small" label={`Week ${props.week.week}`} />
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          <DateCalendar
            value={dateTimeFromTargetWeek(
              props.timezone,
              props.week,
              selectedDayOfWeek,
            )}
            timezone={props.timezone}
            onChange={handleDayPicked}
          />
        </Popover>
        <Typography
          variant="body2"
          color={
            formattedTimezone.differentFromLocal ? "error" : "text.secondary"
          }
          sx={{ marginLeft: "auto" }}
        >
          All times shown in <strong>{formattedTimezone.label}</strong>
        </Typography>
      </Box>
    </LocalizationProvider>
  );
}

function getMonthLabel(week: TargetWeek): string {
  const arbitraryTimezone = MotionTimezone.NEW_YORK;
  const monday = dateTimeFromTargetWeek(arbitraryTimezone, week, 1);
  const friday = dateTimeFromTargetWeek(arbitraryTimezone, week, 5);
  if (monday.month === friday.month) {
    return monday.toLocaleString({ month: "long", year: "numeric" });
  }

  if (monday.year === friday.year) {
    return `${monday.toLocaleString({
      month: "short",
    })} - ${friday.toLocaleString({ month: "short", year: "numeric" })}`;
  }

  return `${monday.toLocaleString({
    month: "short",
    year: "numeric",
  })} - ${friday.toLocaleString({ month: "short", year: "numeric" })}`;
}
