import {
  AssessmentStatus,
  OrderInput,
  VisitNoteFieldsFragment,
  VisitNoteOrder,
  VisitNoteStatus as VisitNoteState,
} from "../../../../gql/graphql";
import { graphql } from "../../../../gql";
import { useState } from "react";
import { useAutoMutation } from "../../../../hooks/autosave";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { maxPatientScreenWidth } from "../../../../theme";
import BlockNavigation from "../../../BlockNavigation";
import { VisitNoteBreadcrumbs } from "../ViewOrEditVisitNote";
import Grid from "@mui/material/Unstable_Grid2";
import OrdersEditor from "./OrdersEditor";
import AutoSaveStatus from "../../../AutoSaveStatus";
import OrdersReadOnly from "../VisitNoteViewer/OrdersReadOnly";
import VisitNoteStatusChip, {
  VisitNoteStatusLabel,
} from "../VisitNoteStatusChip";
import AssessmentsEditor from "./AssessmentsEditor";
import { every } from "lodash";
import { ReviewAndSubmitModal } from "../ReviewAndSubmitModal";

type Props = {
  note: VisitNoteFieldsFragment;
};

const UPDATE_VISIT_NOTE_MUTATION = graphql(`
  mutation UpdateVisitNote(
    $visitNoteId: String!
    $subjective: String!
    $objective: String!
    $assessmentPlan: String!
    $orders: [OrderInput!]
  ) {
    updateVisitNote(
      update: {
        id: $visitNoteId
        subjective: $subjective
        objective: $objective
        assessmentPlan: $assessmentPlan
        orders: $orders
      }
    ) {
      ...VisitNoteFields
    }
  }
`);

export default function VisitNoteEditor(props: Props) {
  const [subjective, setSubjective] = useState(props.note.subjective);
  const [objective, setObjective] = useState(props.note.objective);
  const [assessmentPlan, setAssessmentPlan] = useState(
    props.note.assessmentPlan,
  );
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [orders, setOrders] = useState<Array<OrderInput>>(
    props.note.orders.map(toOrderInput),
  );

  const mutationResults = useAutoMutation(UPDATE_VISIT_NOTE_MUTATION, {
    visitNoteId: props.note.id,
    subjective,
    objective,
    assessmentPlan,
    orders: props.note.status == VisitNoteState.OrdersFinalized ? null : orders,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex={1}
    >
      {showWarningModal && (
        <ReviewAndSubmitModal
          note={props.note}
          goBack={() => setShowWarningModal(false)}
        />
      )}
      {/*  Scrollable box for everything except bottom action bar */}
      <Box
        display="flex"
        flex={1}
        sx={{ overflowY: "auto" }}
        justifyContent="center"
      >
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          gap={2}
          flex={1}
          maxWidth={maxPatientScreenWidth}
        >
          <BlockNavigation
            when={mutationResults.dirty}
            message="Are you sure? Changes you made may not be saved."
          />
          <VisitNoteBreadcrumbs startTime={props.note.appointment.startTime} />
          <Grid container spacing={2}>
            <Grid md={7} xs={12}>
              <Paper sx={{ padding: 2 }}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography variant="h6" color="primary">
                    SOAP
                  </Typography>
                  <TextField
                    label="Subjective"
                    value={subjective}
                    onChange={(e) => setSubjective(e.target.value)}
                    multiline
                    minRows={4}
                    maxRows={8}
                  />
                  <TextField
                    label="Objective"
                    value={objective}
                    onChange={(e) => setObjective(e.target.value)}
                    multiline
                    minRows={4}
                    maxRows={8}
                  />
                  <TextField
                    label="Assessment/Plan"
                    value={assessmentPlan}
                    onChange={(e) => setAssessmentPlan(e.target.value)}
                    multiline
                    minRows={4}
                    maxRows={8}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid md={5} xs={12}>
              <Paper
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box display="flex" gap={2} alignItems="center">
                  <Typography variant="h6" color="primary">
                    Assessments
                  </Typography>
                  <VisitNoteStatusChip
                    status={
                      props.note.assessments.length === 0
                        ? VisitNoteStatusLabel.NOT_STARTED
                        : every(
                            props.note.assessments,
                            (assessment) =>
                              assessment.status === AssessmentStatus.Completed,
                          )
                        ? VisitNoteStatusLabel.COMPLETED
                        : VisitNoteStatusLabel.IN_PROGRESS
                    }
                  />
                </Box>
                <AssessmentsEditor
                  patientId={props.note.patientId}
                  visitNoteId={props.note.id}
                />
              </Paper>
            </Grid>
            <Grid xs={12}>
              <Paper
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box display="flex" gap={2} alignItems="center">
                  <Typography variant="h6" color="primary">
                    Orders
                  </Typography>
                  <VisitNoteStatusChip
                    status={
                      props.note.status === VisitNoteState.OrdersFinalized
                        ? VisitNoteStatusLabel.COMPLETED
                        : orders.length > 0
                        ? VisitNoteStatusLabel.IN_PROGRESS
                        : VisitNoteStatusLabel.NOT_STARTED
                    }
                  />
                </Box>
                {props.note.status == VisitNoteState.OrdersFinalized ? (
                  <OrdersReadOnly orders={props.note.orders} />
                ) : (
                  <OrdersEditor
                    visitNoteId={props.note.id}
                    persistedOrders={props.note.orders}
                    orderSuggestions={props.note.orderSuggestions}
                    onOrdersChange={setOrders}
                    dirty={mutationResults.dirty}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/*  Action bar, pinned to bottom */}
      <Box
        p={2}
        display="flex"
        gap={2}
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #dedede",
          borderLeft: "1px solid #dedede",
        }}
        justifyContent="flex-end"
      >
        <AutoSaveStatus results={mutationResults} />
        <Button
          onClick={() => setShowWarningModal(true)}
          variant="contained"
          disabled={
            mutationResults.dirty ||
            props.note.status !== VisitNoteState.OrdersFinalized ||
            props.note.assessments.find(
              (assessment) => assessment.status !== AssessmentStatus.Completed,
            ) !== undefined
          }
        >
          Review
        </Button>
      </Box>
    </Box>
  );
}

function toOrderInput(order: VisitNoteOrder): OrderInput {
  return {
    orderId: order.orderId,
    bodyParts: order.bodyParts,
  };
}
