import { BodyPart, OrderCategory } from "../gql/graphql";

export const bodyPartNames: { [key in BodyPart]: string } = {
  [BodyPart.ElbowLeft]: "Elbow (left)",
  [BodyPart.ElbowRight]: "Elbow (right)",
  [BodyPart.FootLeft]: "Foot (left)",
  [BodyPart.FootRight]: "Foot (right)",
  [BodyPart.HipLeft]: "Hip (left)",
  [BodyPart.HipRight]: "Hip (right)",
  [BodyPart.KneeLeft]: "Knee (left)",
  [BodyPart.KneeRight]: "Knee (right)",
  [BodyPart.LowBack]: "Low Back",
  [BodyPart.Neck]: "Neck",
  [BodyPart.ShoulderLeft]: "Shoulder (left)",
  [BodyPart.ShoulderRight]: "Shoulder (right)",
  [BodyPart.WristLeft]: "Wrist (left)",
  [BodyPart.WristRight]: "Wrist (right)",
  [BodyPart.Other]: "Other",
  [BodyPart.ThoracicSpine]: "Mid Back",
};

export const orderCategoryNames: { [key in OrderCategory]: string } = {
  [OrderCategory.Coaching]: "PREVAIL Coaching",
  [OrderCategory.DtPt]: "PREVAIL Physical / Digital Therapy",
  [OrderCategory.Medication]: "Medication",
  [OrderCategory.L3ConsultEscalation]: "L3 Consult / Escalation",
  [OrderCategory.Imaging]: "Imaging",
  [OrderCategory.Followup]: "Follow-Ups",
};
