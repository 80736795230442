// @flow
import * as React from 'react';
import {useContext} from "react";
import {SendbirdConversationContext} from "../SendbirdConversationProvider/SendbirdConversationProvider";
import {Avatar, Box, Link, Skeleton, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

type Props = {
  showLinkToProfile: boolean
};

export default function SendbirdChannelInfo(props: Props) {
  const context = useContext(SendbirdConversationContext);

  if(context.patientMember == null) {
    return (
      <Box display="flex" gap={1} flexShrink={0}>
        <Skeleton variant="circular" width={40} height={40} />
        <Box display="flex" flexDirection="column" gap={1}>
          <Skeleton width={100} />
          <Skeleton width={200} />
        </Box>
      </Box>
    );
  }

  const initials = context.patientMember.nickname.split(' ').map(n => n[0]).join('');
  return (
    <Box display="flex" gap={1} flexShrink={0}>
      <Avatar>{initials}</Avatar>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body2">{context.patientMember.nickname}</Typography>
          {props.showLinkToProfile && (
            <Link
              component={RouterLink}
              to={`/redirect/user/${context.patientMember.userId.replace("member-", "")}`}
              sx={{fontSize: '12px'}}
              target="_blank"
            >
              Go to Profile
            </Link>
          )}
        </Box>
        <Typography variant="caption" color="text.secondary">
          Status: {context.patientMember.connectionStatus}
        </Typography>
      </Box>
    </Box>
  );
}
