import { ScheduleFieldsFragment } from "../../../gql/graphql";
import ScheduleDraftEditor from "../ScheduleDraftEditor";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ScheduleDraftPublisher from "../ScheduleDraftPublisher";

type Props = {
  scheduleDraft: ScheduleFieldsFragment;
};

/**
 * Main component to switch between editing and publishing a schedule draft.
 */
export default function EditPublishScheduleDraft(props: Props) {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/publish`}>
        <ScheduleDraftPublisher scheduleDraftId={props.scheduleDraft.id} />
      </Route>
      <Route exact path={match.path}>
        <ScheduleDraftEditor
          scheduleDraft={props.scheduleDraft}
          // Key is needed to fully reset the editor when the schedule changes
          key={props.scheduleDraft.id}
        />
      </Route>
      <Redirect to={match.path} />
    </Switch>
  );
}
