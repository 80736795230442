import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Reference, StoreObject, useMutation } from "@apollo/client";
import { graphql } from "../../../gql";
import LoadingButton from "@mui/lab/LoadingButton";
import DetailedAlert from "../../DetailedAlert";

type Props = {
  visitNoteId: string;
  onClose: () => void;
};

export const DELETE_VISIT_NOTE_MUTATION = graphql(`
  mutation deletePendingVisitNote($visitNoteId: String!) {
    deletePendingVisitNote(visitNoteId: $visitNoteId)
  }
`);

export default function DeleteVisitNoteDialog(props: Props) {
  const [deletePendingNote, { loading, error }] = useMutation(
    DELETE_VISIT_NOTE_MUTATION,
    {
      variables: {
        visitNoteId: props.visitNoteId,
      },
      notifyOnNetworkStatusChange: true,
      // Remove from cache after successful deletion.
      update(cache) {
        cache.modify({
          fields: {
            allVisitNotes(existingNotes, { readField }) {
              return existingNotes.filter(
                (noteRef: Reference | StoreObject | undefined) =>
                  props.visitNoteId !== readField("id", noteRef),
              );
            },
          },
        });
      },
    },
  );
  const tryClose = () => {
    if (loading) {
      return;
    }
    props.onClose();
  };

  const handleDelete = async () => {
    await deletePendingNote();
    props.onClose();
  };

  return (
    <Dialog open={true} onClose={tryClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This visit note will be permanently deleted. This action cannot be
          undone.
        </DialogContentText>
        {error && (
          <DetailedAlert
            message="Oops. Something went wrong. Please try again."
            additionalDetails={error}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={tryClose} disabled={loading}>
          Keep note
        </Button>
        <LoadingButton onClick={handleDelete} color="error" loading={loading}>
          Delete Forever
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
