import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const ResolveConversationWarningModal: React.FC<{
  submit: () => void;
  goBack: () => void;
}> = (props) => {
  return (
    <div>
      <Dialog open={true} onClose={props.goBack}>
        <DialogTitle>
          {"Are you sure this conversation is resolved?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This patient has unread messages from us. If you close the
            conversation now, them may not be notified. Proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.goBack}>Go Back</Button>
          <Button
            onClick={() => {
              props.submit();
              props.goBack();
            }}
          >
            Mark as Resolved
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
