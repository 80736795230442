import React, { useEffect } from "react";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { env } from "../../config";
import axios from "axios";
import { AuthenticationContext } from "../../hooks/authHooks";

type Props = {
  children: React.ReactNode;
};

export const TOKENS_REFRESHED_EVENT = "TOKENS_REFRESHED";

const userPoolId = env("REACT_APP_COGNITO_USER_POOL_ID") || "";
const clientId = env("REACT_APP_COGNITO_CLIENT_ID") || "";
const redirectUri = env("REACT_APP_COGNITO_REDIRECT_URI") || "";
const domain = env("REACT_APP_COGNITO_DOMAIN");

const userPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: clientId,
});

/**
 * Main component to manage authentication.
 */
export default function AuthManager(props: Props) {
  const [session, setSession] = React.useState<CognitoUserSession | null>(null);
  const [pendingOktaAuth, setPendingOktaAuth] = React.useState<boolean>(false);
  const [refreshTokenExpired, setRefreshTokenExpired] =
    React.useState<boolean>(false);

  const refreshSession = async (): Promise<CognitoUserSession> => {
    try {
      const session = await getOrRefreshCognitoSession();
      setSession(session);
      setRefreshTokenExpired(false);
      return session;
    } catch (e) {
      setRefreshTokenExpired(true);
      throw e;
    } finally {
      setPendingOktaAuth(false);
    }
  };

  const startNewAuth = async (forceLogOut: boolean) => {
    if (forceLogOut) {
      try {
        await cognitoLogOut();
      } catch (e) {
        // This can happen if the previous attempt to refresh the session failed.
        console.error("Error logging out", e);
      }
      setSession(null);
    }
    setPendingOktaAuth(true);
    openOktaAuth();
  };

  // Initial load: get or refresh session from storage, or start new auth flow
  useEffect(() => {
    refreshSession().catch(() => {
      setPendingOktaAuth(true);
      openOktaAuth();
    });
    const handleOktaCompletedEvent = (event: MessageEvent<any>) => {
      // Control the event origin to prevent XSS
      if (
        event.origin !== document.location.origin ||
        event.data !== TOKENS_REFRESHED_EVENT
      ) {
        return;
      }
      refreshSession().then().catch();
    };

    // Set up listeners for completed Okta flows
    window.addEventListener("message", handleOktaCompletedEvent);
    return () => {
      window.removeEventListener("message", handleOktaCompletedEvent);
    };
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        session,
        pendingOktaAuth,
        startNewAuth,
        refreshSession,
        refreshTokenExpired,
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
}

/**
 * Refresh the cognito session, using the refresh token if necessary.
 * This will fail if the refresh token has expired or is not set, in which
 * case the caller should initiate a new auth flow, see {@link openOktaAuth}.
 */
function getOrRefreshCognitoSession(): Promise<CognitoUserSession> {
  const currentUser = userPool.getCurrentUser();
  if (!currentUser) {
    return Promise.reject("No current user");
  }
  return new Promise((resolve, reject) => {
    currentUser.getSession(
      (error: Error | null, session: CognitoUserSession | null) => {
        if (!!error || session == null) {
          reject(error);
          return;
        }
        resolve(session);
      },
    );
  });
}

/**
 * Log the user out, forcing a new auth flow.
 */
function cognitoLogOut(): Promise<void> {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser == null) {
      reject("No user");
      return;
    }

    cognitoUser.signOut(() => resolve());
  });
}

/**
 * Exchange an Okta auth code for new Cognito tokens, and save a new session
 * locally.
 * Note: this one is NOT called as part of the AuthManager lifecycle, since it
 * happens in a separate window.
 */
export async function initializeSessionFromCode(
  code: string,
): Promise<CognitoUserSession> {
  const data = `grant_type=authorization_code&client_id=${clientId}&redirect_uri=${redirectUri}&code=${code}`;
  try {
    const response = await axios({
      method: "POST",
      url: `https://${domain}/oauth2/token`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    });
    const tokensData = {
      IdToken: response.data["id_token"],
      AccessToken: response.data["access_token"],
      RefreshToken: response.data["refresh_token"],
    };

    // Parse the id token to get the username (required for creating a CognitoUser)
    let payload = tokensData.IdToken.split(".")[1];
    payload = JSON.parse(atob(payload));
    const username = payload["cognito:username"];
    const newCognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });
    const newSession = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: tokensData.IdToken }),
      AccessToken: new CognitoAccessToken({
        AccessToken: tokensData.AccessToken,
      }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: tokensData.RefreshToken,
      }),
    });
    newCognitoUser.setSignInUserSession(newSession);
    return newSession;
  } catch (error) {
    console.log("Error converting code to token", error);
    throw error;
  }
}

function openOktaAuth() {
  const url = `https://${domain}/oauth2/authorize?identity_provider=okta&redirect_uri=${redirectUri}&response_type=CODE&client_id=${clientId}&scope=email%20openid%20profile`;
  window.open(
    url,
    "_blank",
    "menubar=no,resizable=no,toolbar=no,status=no,resizable=1,width=450,height=500",
  );
}
