import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Link,
} from "@mui/material";
import Alert, { AlertColor } from "@mui/material/Alert";
import type { ApolloClientError } from "../../flow-typed/apollo";
import { ApolloError } from "@apollo/client";

type Props = {
  message: string;
  additionalDetails?: ApolloClientError | ApolloError;
  retry?: () => void;
  severity?: AlertColor;
};

export default function DetailedAlert(props: Props) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      sx={{ width: "100%" }}
    >
      <Box display="flex">
        <Alert
          severity={props.severity || "error"}
          action={
            props.retry ? (
              <Button color="inherit" size="small" onClick={props.retry}>
                Retry
              </Button>
            ) : null
          }
          sx={{ width: "100%" }}
        >
          <Box display="flex" justifyItems="center">
            {props.message}
            <Link
              style={{ marginLeft: 10 }}
              component="button"
              variant="body2"
              onClick={() => setShowDetails(true)}
            >
              details
            </Link>
          </Box>
        </Alert>
      </Box>
      <Dialog open={showDetails} onClose={() => setShowDetails(false)}>
        <DialogContent>
          <DialogContentText>
            {JSON.stringify(props.additionalDetails)}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
