import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Collapse,
} from "@mui/material";
import "./App.css";
import AppRefresh from "./components/AppRefresh";
import Main from "./components/Main";
import PropsRoute from "./PropsRoute";
import PatientActionScreen from "./components/PatientActions/PatientActionsScreen";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import Search from "./components/Search";
import PatientSearchRedirect from "./components/PatientSearchRedirect";
import { MinimalRisaltoScreen } from "./components/MinimalRisaltoUI/MinimalRisaltoScreen";
import { useQuery } from "@apollo/client/react/hooks";
import { SERVER_INFO_QUERY } from "./libs/queries";
import AllConversations from "./components/AllConversations";
import AuthStatus from "./components/AuthStatus";
import { useAuthentication } from "./hooks/authHooks";
import { useSearchParams } from "./hooks/searchParams";
import { ManageSchedules } from "./components/ManageSchedules";
import { initRum, useRecordPageView } from "./hooks/telemetry";
import ReportsScreen from "./components/ReportsScreen";

initRum();

const App = () => {
  useRecordPageView();
  const { session, pendingOktaAuth } = useAuthentication();
  const [drawerOpened, setDrawerOpened] = useState(false);

  const history = useHistory();
  const [searchParams] = useSearchParams();
  const { data: serverInfoData } = useQuery(SERVER_INFO_QUERY);
  const serverInfo = serverInfoData || {};

  const setFavicon = (size: number, suffix: string) => {
    const link = document.getElementById("favicon" + size);
    link?.setAttribute(
      "href",
      link.getAttribute("data-prefix") + "/favicon" + size + suffix + ".png",
    );
  };

  const renderFavicon = (namespace: string) => {
    const suffix =
      namespace === "prod" ? "" : namespace === "test" ? "test" : "dev";
    setFavicon(16, suffix);
    setFavicon(32, suffix);
  };

  const openDrawer = () => {
    setDrawerOpened(true);
  };
  const closeDrawer = () => {
    setDrawerOpened(false);
  };

  const namespace = serverInfo.namespace || "";
  useEffect(() => {
    renderFavicon(namespace);
    document.title = `Risalto ${namespace || "??"} UI`;
  }, [serverInfo]);

  // If the session has never been set, show a dedicated screen (nothing else we can show).
  // Otherwise, the AuthStatus component handles messaging.
  if (pendingOktaAuth && !session) {
    return (
      <MinimalRisaltoScreen
        title="Welcome Risalto Staff!"
        message="Please complete the login flow in the Okta window that just opened."
      >
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: 10 }}
        >
          Not seeing the login window? Follow these instructions to enable
          popups in your browser, then reload this page:
        </Typography>
        <ul>
          <li>
            <Typography variant="body2" color="textSecondary">
              <a
                href="https://images.risaltohealth.net/portal/chrome_popup_instructions.png"
                target="_blank"
              >
                Chrome
              </a>
            </Typography>
          </li>
          <li>
            <Typography variant="body2" color="textSecondary">
              <a
                href="https://images.risaltohealth.net/portal/safari_popup_instructions.png"
                target="_blank"
              >
                Safari
              </a>
            </Typography>
          </li>
        </ul>
      </MinimalRisaltoScreen>
    );
  }
  if (!session) {
    return (
      <MinimalRisaltoScreen
        title="Welcome Risalto Staff!"
        message="Please reload this page to authenticate."
      />
    );
  }

  return (
    <>
      <AuthStatus />
      <Sidebar
        open={drawerOpened}
        onClose={closeDrawer}
        serverInfo={serverInfo}
      />
      <Box sx={styles.app}>
        <Collapse in={!searchParams.focused} orientation="vertical">
          <AppBar position="static" elevation={1}>
            <Toolbar variant="dense">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={openDrawer}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Typography
                sx={styles.brand}
                variant="h6"
                noWrap
                onClick={() => history.push("/")}
              >
                Risalto {namespace === "prod" ? "" : "- " + namespace}
              </Typography>
              <Search />
              <Box sx={styles.grow} />
              <Typography variant="body1">
                {session.getIdToken().payload.email}
              </Typography>
            </Toolbar>
          </AppBar>
        </Collapse>

        <div>
          <AppRefresh />
        </div>

        <Box sx={styles.body}>
          <Switch>
            <Route
              path="/redirect/:externalIdType/:externalId"
              component={PatientSearchRedirect}
            />
            <Route path="/conversations" component={AllConversations} />
            <PropsRoute exact path="/" component={Main} userSession={session} />
            <Route path="/patient/:patientId" component={PatientActionScreen} />
            <Route path="/schedules" component={ManageSchedules} />
            <Route path="/reports" component={ReportsScreen} />
          </Switch>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  app: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    height: "100vh",
    backgroundColor: "#eef2f6",
  },
  body: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: 0, // Needed for flexGrow + scroll
  },
  brand: {
    display: {
      xs: "none",
      sm: "block",
    },
  },
  grow: {
    flexGrow: 1,
  },
};

export default withRouter(App);
