import React, {useEffect, useState} from "react";
import { alpha } from '@mui/material/styles';
import {
  IconButton,
  InputBase,
  Divider,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  Snackbar
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useLazyQuery} from "@apollo/client";
import {GET_PATIENT_ID_BY_EXTERNAL_ID} from "../../libs/queries";
import { Alert } from '@mui/material';
import {useHistory} from "react-router-dom";

const styles = {
  search: {
    position: 'relative',
    borderRadius: 1,
    backgroundColor: theme => alpha(theme.palette.common.white, 0.15),
    '&:hover': theme => ({
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    }),
    marginRight: 2,
    marginLeft: {
      xs: 0,
      sm: 3
    },
    minWidth: '500px',
    width: {
      xs: '100%',
      sm: 'auto'
    },
  },
  input: {
    marginLeft: 1,
    flex: 1,
    color: 'inherit'
  },
  iconButton: {
    padding: '10px',
  },
  divider: {
    height: '28px',
    margin: '4px',
  },
};

export const idTypes = {
  WELKIN: 'WELKIN',
  ELATION: 'ELATION'
};

export default function Search() {
  const [searchInput, setSearchInput] = useState("");
  const [idType, setIdType] = useState(idTypes.WELKIN);
  const history = useHistory();

  const [getPatientIdByExternalId, {loading, data, error}] = useLazyQuery(
    GET_PATIENT_ID_BY_EXTERNAL_ID,
    {
      notifyOnNetworkStatusChange: true
    }
  );

  useEffect(() => {
    if (data?.patientIdByExternalId) {
      setSearchInput("");
      history.push(`/patient/${data.patientIdByExternalId}/info`);
    }
  }, [data])

  const onSearchChange = (e) => {
    setSearchInput(e.target.value);
  }

  const onSearchKeyDown = (e) => {
    if (e.keyCode === 13 && !!searchInput) {
      findPatient();
    }
  };

  const findPatient = () => {
    getPatientIdByExternalId({
      variables: {
        externalId: searchInput,
        patientIdType: idType
      }
    });
  };

  return (
    <React.Fragment>
      <Box display="flex" sx={styles.search}>
        <InputBase
          sx={styles.input}
          placeholder="Find patient by ID"
          inputProps={{ 'aria-label': 'find patient by id' }}
          value={searchInput}
          onChange={onSearchChange}
          onKeyDown={onSearchKeyDown}
        />
        <Select
          style={{color: 'inherit'}}
          value={idType}
          variant="standard"
          onChange={(event) => {setIdType(event.target.value)}}
        >
          <MenuItem value={idTypes.WELKIN}>Welkin ID</MenuItem>
          <MenuItem value={idTypes.ELATION}>Elation ID</MenuItem>
        </Select>
        <Divider sx={styles.divider} orientation="vertical" />
        {
          loading ? (
            <Box m={1}>
              <CircularProgress size={24} color="primary"/>
            </Box>

          ) : (<IconButton
            sx={styles.iconButton}
            aria-label="find"
            color="inherit"
            disabled={!searchInput}
            onClick={findPatient}
            size="large">
            <SearchIcon />
          </IconButton>)
        }
      </Box>
      <Snackbar open={!!error || (!!data && !data.patientIdByExternalId)}>
        <Alert severity="error">Oops! Could not find patient with this ID.</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
