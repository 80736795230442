import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";

type Props = {
  // If true, the navigation will be blocked
  when: boolean;
  message?: string;
};

const BlockNavigation = (props: Props) => {
  return (
    <>
      <Prompt message={props.message || "Are you sure?"} when={props.when} />
      <BlockUnload when={props.when} />
    </>
  );
};

export default BlockNavigation;

function BlockUnload(props: Props) {
  useEffect(() => {
    if (props.when) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [props.when]);
  return null;
}
