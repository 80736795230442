import {
  convertMinutesToHeight,
  getHeightInPixels,
  getTopPositionInPixels,
  MotionTimezone,
} from "../../../../../../../libs/time";
import { EffectiveTimeSlotFieldsFragment } from "../../../../../../../gql/graphql";
import { Box, Popover, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { getEffectiveSlotBackgroundColor } from "../../../../../../../libs/colors";
import React, { useState } from "react";
import { useCachedAppointmentTypeConfigurations } from "../../../../../../../hooks/commonQueries";
import {
  getEffectiveSlotName,
  locationConflicts,
} from "../../../../../../../libs/booking";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EffectiveSlotDetails from "../EffectiveSlotDetails";

type Props = {
  minHour: number;
  maxHour: number;
  timezone: MotionTimezone;
  slots: Array<EffectiveTimeSlotFieldsFragment>;
  // The location id of an appointment being booked/rescheduled, if any.
  tentativeAppointmentLocationId?: string | null;
};

/**
 * Renders daily schedule slots for a single staff member.
 */
export default function StaffMemberDailySlots(props: Props) {
  const heightInPixels = convertMinutesToHeight(
    (props.maxHour - props.minHour) * 60,
  );

  return (
    <Box
      height={`${heightInPixels}px`}
      position="relative"
      display="flex"
      flex={1}
    >
      {props.slots.map((slot) => (
        <Box
          key={slot.startTime}
          position="absolute"
          top={getTopPositionInPixels(slot, props.timezone, props.minHour)}
          width="100%"
        >
          <StaffMemberEffectiveSlot
            slot={slot}
            timezone={props.timezone}
            flag={locationConflicts(props.tentativeAppointmentLocationId, slot)}
          />
        </Box>
      ))}
    </Box>
  );
}

/**
 * Renders a single effective slot for a staff member.
 */
function StaffMemberEffectiveSlot(props: {
  slot: EffectiveTimeSlotFieldsFragment;
  timezone: MotionTimezone;
  flag: boolean;
}) {
  const [selected, setSelected] = useState<HTMLDivElement | null>(null);
  const { slot, timezone, flag } = props;
  const appointmentTypeConfig = useCachedAppointmentTypeConfigurations(true);
  const heightInPixels = getHeightInPixels(slot);
  const dense = heightInPixels <= 30;

  return (
    <>
      <Box
        height={`${heightInPixels}px`}
        overflow="hidden"
        display="flex"
        alignItems="stretch"
        onClick={(e) => setSelected(e.currentTarget)}
      >
        <Box
          mb="1px"
          paddingY={dense ? 0 : 1}
          paddingX={1}
          display="flex"
          gap={1}
          flex={1}
          border={
            !!selected ? `1px solid ${grey[500]}` : `1px dotted ${grey[400]}`
          }
          overflow="hidden"
          bgcolor={getEffectiveSlotBackgroundColor(slot)}
          alignItems={dense ? "center" : "flex-start"}
        >
          {flag && <WarningAmberIcon color="error" fontSize="small" />}
          <Typography
            variant="caption"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            color={!!selected ? "text.secondary" : grey[400]}
          >
            {getEffectiveSlotName(slot, appointmentTypeConfig)}
          </Typography>
        </Box>
      </Box>

      {selected && (
        <Popover
          open={true}
          anchorEl={selected}
          onClose={() => setSelected(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <EffectiveSlotDetails
            slot={slot}
            flagLocation={flag}
            timezone={timezone}
          />
        </Popover>
      )}
    </>
  );
}
