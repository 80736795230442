import React, { useState } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { PATIENT_VISIT_NOTES } from "../PatientVisitNotes";
import { graphql } from "../../../../gql";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import DetailedAlert from "../../../DetailedAlert";
import { keyBy, sortBy, values } from "lodash";
import { DateTime } from "luxon";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import { useMutation } from "@apollo/client";
import { PATIENT_ASSESSMENTS } from "../../PatientAssessments/PatientAssessments";
import Chip from "@mui/material/Chip";
import {
  ExperimentalFeature,
  useExperimentalFeatures,
} from "../../../../hooks/experimentalFeatures";

type Props = {
  patientId: string;
  visitNoteId: string;
  onClose: () => void;
};

// Limited query about assessments for this patient
export const PATIENT_ASSESSMENTS_FOR_NOTE = graphql(`
  query PatientAssessmentsForNote($patientId: String!) {
    assessmentsForPatient(patientId: $patientId) {
      id
      name
      createdAt
    }
  }
`);

export const ASSESSMENT_CONFIGURATIONS = graphql(`
  query assessmentConfigurations {
    assessmentConfigurations {
      id
      name
      description
      patientFacing
      beta
    }
  }
`);

export const ADD_EXISTING_ASSESSMENT_MUTATION = graphql(`
  mutation AddExistingAssessmentToVisitNote(
    $visitNoteId: String!
    $assessmentId: String!
  ) {
    addExistingAssessment(
      visitNoteId: $visitNoteId
      assessmentId: $assessmentId
    ) {
      ...VisitNoteFields
    }
  }
`);

export const CREATE_ASSESSMENT_FOR_NOTE_MUTATION = graphql(`
  mutation CreateAssessmentForVisitNote(
    $visitNoteId: String!
    $assessmentType: String!
    $patientFacing: Boolean!
  ) {
    createAssessmentForVisitNote(
      visitNoteId: $visitNoteId
      assessmentType: $assessmentType
      patientFacing: $patientFacing
    ) {
      ...VisitNoteFields
    }
  }
`);

/**
 * Dialog to add a new or existing assessment to a note.
 */
export default function AddAssessmentDialog(props: Props) {
  const [selectedAssessment, setSelectedAssessment] =
    useState<AssessmentInput | null>(null);
  const [patientFacing, setPatientFacing] = useState(false);
  const { checkExperimentalFeatureEnabled } = useExperimentalFeatures();
  const showBetaAssessments = checkExperimentalFeatureEnabled(
    ExperimentalFeature.betaAssessments,
  );

  const {
    data: visitNotesData,
    loading: visitNotesLoading,
    error: visitNotesError,
    refetch: visitNotesRefetch,
  } = useQuery(PATIENT_VISIT_NOTES, {
    variables: { patientId: props.patientId },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: assessmentsData,
    loading: assessmentsLoading,
    error: assessmentsError,
    refetch: assessmentsRefetch,
  } = useQuery(PATIENT_ASSESSMENTS_FOR_NOTE, {
    variables: { patientId: props.patientId },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: assessmentConfigurationsData,
    loading: assessmentConfigurationsLoading,
    error: assessmentConfigurationsError,
    refetch: assessmentConfigurationsRefetch,
  } = useQuery(ASSESSMENT_CONFIGURATIONS, {
    notifyOnNetworkStatusChange: true,
  });

  const [
    addExistingAssessment,
    { loading: addExistingLoading, error: addExistingError },
  ] = useMutation(ADD_EXISTING_ASSESSMENT_MUTATION, {
    notifyOnNetworkStatusChange: true,
  });

  const [createAssessment, { loading: createLoading, error: createError }] =
    useMutation(CREATE_ASSESSMENT_FOR_NOTE_MUTATION, {
      notifyOnNetworkStatusChange: true,
    });

  const handleRefetch = async () => {
    if (visitNotesError) {
      await visitNotesRefetch();
    }
    if (assessmentsError) {
      await assessmentsRefetch();
    }
    if (assessmentConfigurationsError) {
      await assessmentConfigurationsRefetch();
    }
  };

  const mutationLoading = createLoading || addExistingLoading;
  if (
    visitNotesLoading ||
    assessmentsLoading ||
    assessmentConfigurationsLoading
  ) {
    return (
      <DialogWrapper
        onClose={props.onClose}
        mutationLoading={mutationLoading}
        disabled={!selectedAssessment}
      >
        <CircularProgress />
      </DialogWrapper>
    );
  }

  if (visitNotesError || assessmentsError || assessmentConfigurationsError) {
    return (
      <DialogWrapper
        onClose={props.onClose}
        mutationLoading={mutationLoading}
        disabled={!selectedAssessment}
      >
        <DetailedAlert
          message="Oops! Something went wrong. Please try again."
          retry={handleRefetch}
          additionalDetails={visitNotesError ?? assessmentsError}
        />
      </DialogWrapper>
    );
  }

  const assessmentIdsAlreadyTied = new Set(
    visitNotesData?.allVisitNotes
      ?.flatMap((note) => note.assessments)
      ?.map((a) => a.id),
  );
  const sortedAssessments = sortBy(
    assessmentsData?.assessmentsForPatient,
    (a) => -a.createdAt,
  );

  const configurationsById = keyBy(
    assessmentConfigurationsData?.assessmentConfigurations,
    (c) => c.id,
  );

  const handleAssessmentChange = (event: SelectChangeEvent) => {
    setSelectedAssessment(getInput(event.target.value));
    setPatientFacing(false);
  };

  const handleAdd = async () => {
    if (!selectedAssessment) {
      return;
    }
    if (selectedAssessment.type === "existing") {
      await addExistingAssessment({
        variables: {
          visitNoteId: props.visitNoteId,
          assessmentId: selectedAssessment.assessmentId,
        },
      });
    } else {
      await createAssessment({
        variables: {
          visitNoteId: props.visitNoteId,
          assessmentType: selectedAssessment.assessmentType,
          patientFacing,
        },
        refetchQueries: [
          {
            query: PATIENT_ASSESSMENTS,
            variables: {
              patientId: props.patientId,
            },
          },
        ],
      });
    }
    props.onClose();
  };

  return (
    <DialogWrapper
      onClose={props.onClose}
      mutationLoading={mutationLoading}
      disabled={!selectedAssessment}
      onClickAdd={handleAdd}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="body2">
          Select an existing assessment to add to the note or a new one to
          create.
        </Typography>
        <Box display="flex" gap={2} alignItems="center">
          <FormControl sx={{ minWidth: 350, alignSelf: "flex-start" }}>
            <InputLabel id="assessment-select-label">Assessment</InputLabel>
            <Select
              labelId="assessment-select-label"
              label="Assessment"
              value={toInputId(selectedAssessment)}
              onChange={handleAssessmentChange}
              disabled={mutationLoading}
            >
              <ListSubheader
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                Existing Assessments
              </ListSubheader>
              {sortedAssessments.length === 0 ? (
                <MenuItem disabled>No assessments</MenuItem>
              ) : (
                sortedAssessments.map((assessment) => (
                  <MenuItem
                    key={assessment.id}
                    value={`existing_${assessment.id}`}
                    disabled={assessmentIdsAlreadyTied.has(assessment.id)}
                  >
                    <Box
                      flex={1}
                      display="flex"
                      alignItems="center"
                      gap={2}
                      justifyContent="space-between"
                    >
                      <Typography>{assessment.name}</Typography>
                      {assessmentIdsAlreadyTied.has(assessment.id) ? (
                        <Typography variant="caption">
                          already in a note
                        </Typography>
                      ) : (
                        <Typography variant="caption">
                          created{" "}
                          {DateTime.fromSeconds(
                            assessment.createdAt,
                          ).toRelative()}
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                ))
              )}
              <Divider />
              <ListSubheader
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                New Assessment
              </ListSubheader>
              {values(configurationsById)
                .filter((config) => !config.beta || showBetaAssessments)
                .map((configuration) => (
                  <MenuItem
                    key={configuration.id}
                    value={`new_${configuration.id}`}
                  >
                    {configuration.beta && (
                      <Chip
                        label="Beta 🚧"
                        size="small"
                        sx={{ marginRight: 1 }}
                      />
                    )}
                    {configuration.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {selectedAssessment?.type === "new" && (
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={patientFacing}
                    onChange={(e) => setPatientFacing(e.target.checked)}
                    disabled={
                      mutationLoading ||
                      configurationsById[selectedAssessment.assessmentType]
                        ?.patientFacing !== true
                    }
                  />
                }
                label="Create as patient-facing assessment"
              />
            </FormControl>
          )}
        </Box>
        {(createError || addExistingError) && (
          <DetailedAlert
            message="Oops! Something went wrong. Please try again."
            additionalDetails={createError ?? addExistingError}
          />
        )}
      </Box>
    </DialogWrapper>
  );
}

function DialogWrapper(props: {
  onClose: () => void;
  children: React.ReactNode;
  disabled: boolean;
  mutationLoading: boolean;
  onClickAdd?: () => void;
}) {
  const maybeClose = () => {
    if (!props.mutationLoading) {
      props.onClose();
    }
  };
  return (
    <Dialog open={true} onClose={maybeClose} maxWidth="md">
      <DialogTitle>Add Motion Assessment</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={maybeClose} disabled={props.mutationLoading}>
          Cancel
        </Button>
        <LoadingButton
          startIcon={<AddIcon />}
          loading={props.mutationLoading}
          disabled={props.disabled}
          variant="contained"
          onClick={props.onClickAdd}
        >
          Add to Note
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

type AssessmentInput = NewAssessmentInput | ExistingAssessmentInput;
type NewAssessmentInput = {
  type: "new";
  assessmentType: string;
};
type ExistingAssessmentInput = {
  type: "existing";
  assessmentId: string;
};

function toInputId(input: AssessmentInput | null): string {
  if (!input) {
    return "";
  }
  if (input.type === "new") {
    return `new_${input.assessmentType}`;
  }
  return `existing_${input.assessmentId}`;
}
function getInput(optionId: string | null): AssessmentInput | null {
  if (!optionId) {
    return null;
  }
  const [type, id] = optionId.split(/_(.+)/);
  if (type === "new") {
    return {
      type: "new",
      assessmentType: id,
    };
  }

  return {
    type: "existing",
    assessmentId: id,
  };
}
