import {
  AppointmentMedium,
  Patient,
  TargetWeek,
} from "../../../../gql/graphql";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import NewAppointmentParamSelector from "./NewAppointmentParamSelector";
import {
  AppointmentStaff,
  getAppointmentTypeLabel,
  isSearchValid,
  SearchParams,
  SlotSelection,
} from "../../../../libs/booking";
import AvailabilityBrowser from "./AvailabilityBrowser";
import FinalizeAppointment from "./FinalizeAppointment";
import { useQuery } from "@apollo/client/react/hooks";
import { MOTION_STAFF } from "../../../SelectStaff";
import DetailedAlert from "../../../DetailedAlert";
import { GET_APPOINTMENT_TYPE_CONFIG } from "../../../SelectAppointmentType";
import SearchIcon from "@mui/icons-material/Search";
import { getMediumName } from "../../../../libs/scheduling";
import { GET_CLINICS } from "../../../SelectClinic";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import ViewEditPatientTimezone from "../../../ViewEditPatientTimezone";
import {
  currentTargetWeek,
  getMotionTimezoneOrNull,
} from "../../../../libs/time";
import {
  useCachedAppointmentTypeConfigurations,
  useCachedClinics,
} from "../../../../hooks/commonQueries";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

type Props = {
  patient: Patient;
};

/**
 * Main component for new appointment scheduling.
 */
export default function CreateAppointment(props: Props) {
  const match = useRouteMatch();
  const history = useHistory();

  const [searchParams, setSearchParams] = useState<Partial<SearchParams>>({});
  const [slotSelection, setSlotSelection] = useState<SlotSelection | null>(
    null,
  );
  const [selectedStaff, setSelectedStaff] = useState<AppointmentStaff | null>(
    null,
  );
  const [selectedWeek, setSelectedWeek] = useState<TargetWeek>(
    currentTargetWeek(),
  );

  // Initialize the cache for appointment config, staff, and clinics.
  const {
    loading: appointmentConfigLoading,
    error: appointmentConfigError,
    refetch: appointmentConfigRefetch,
  } = useQuery(GET_APPOINTMENT_TYPE_CONFIG, {
    variables: {
      beta: true,
    },
    notifyOnNetworkStatusChange: true,
  });
  const {
    loading: staffLoading,
    error: staffError,
    refetch: staffRefetch,
  } = useQuery(MOTION_STAFF, {
    notifyOnNetworkStatusChange: true,
  });
  const {
    loading: clinicLoading,
    error: clinicError,
    refetch: clinicRefetch,
  } = useQuery(GET_CLINICS, {
    notifyOnNetworkStatusChange: true,
  });

  if (appointmentConfigLoading || staffLoading || clinicLoading) {
    return (
      <WithLayout patientId={props.patient.id} disableBack>
        <CircularProgress />
      </WithLayout>
    );
  }

  if (appointmentConfigError || staffError || clinicError) {
    return (
      <WithLayout patientId={props.patient.id} disableBack>
        <DetailedAlert
          message="Oops! Failed to load configuration. Please try again."
          additionalDetails={
            appointmentConfigError || staffError || clinicError
          }
          retry={() => {
            if (appointmentConfigError) {
              appointmentConfigRefetch().then(/* ignore promise */);
            }
            if (staffError) {
              staffRefetch().then(/* ignore promise */);
            }
            if (clinicError) {
              clinicRefetch().then(/* ignore promise */);
            }
          }}
        />
      </WithLayout>
    );
  }

  const patientTimezone = getMotionTimezoneOrNull(
    props.patient.communicationPreferences.timezone,
  );
  const step1Complete = isSearchValid(searchParams) && !!patientTimezone;
  const step2Complete = !!selectedStaff && !!slotSelection;

  const handleSearchParamsChange = (params: Partial<SearchParams>) => {
    if (params.multiDisciplinary !== searchParams.multiDisciplinary) {
      setSelectedStaff(null);
    }
    setSearchParams(params);
    setSlotSelection(null);
  };

  const handleSelectSlot = (selection: SlotSelection) => {
    setSlotSelection(selection);
    history.push(`${match.url}/confirm`);
  };
  return (
    <Switch>
      {/* Step 1*/}
      <Route exact path={`${match.path}/`}>
        <WithLayout patientId={props.patient.id} disableBack>
          <Box display="flex" flexDirection="column" gap={4} padding={2}>
            <NewAppointmentParamSelector
              params={searchParams}
              onChange={handleSearchParamsChange}
            />
            <Divider />
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" gap={2} alignItems="center">
                <Box flex={1} maxWidth={400}>
                  <ViewEditPatientTimezone
                    patientId={props.patient.id}
                    size="small"
                  />
                </Box>
                {!patientTimezone && (
                  <Alert severity="warning">
                    Please set the timezone for this patient before proceeding.
                  </Alert>
                )}
              </Box>
            </Box>
            <Button
              variant="contained"
              disabled={!isSearchValid(searchParams) || !patientTimezone}
              sx={{ alignSelf: "flex-start" }}
              startIcon={<SearchIcon />}
              onClick={() => history.push(`${match.url}/availability`)}
            >
              Go
            </Button>
          </Box>
        </WithLayout>
      </Route>
      {step1Complete && (
        <Route path={`${match.path}/availability`}>
          <WithLayout
            patientId={props.patient.id}
            headerInfo={<SearchParameterChips searchParams={searchParams} />}
          >
            <AvailabilityBrowser
              patientId={props.patient.id}
              mode="create"
              timezone={patientTimezone}
              searchParameters={searchParams}
              selectedStaff={selectedStaff}
              onSelectStaff={setSelectedStaff}
              selectedWeek={selectedWeek}
              onSelectWeek={setSelectedWeek}
              onSelectSlot={handleSelectSlot}
            />
          </WithLayout>
        </Route>
      )}
      {step1Complete && step2Complete && (
        <Route path={`${match.path}/confirm`}>
          <WithLayout patientId={props.patient.id}>
            <FinalizeAppointment
              patient={props.patient}
              timezone={patientTimezone}
              searchParameters={searchParams}
              slotSelection={slotSelection}
              week={selectedWeek}
              staff={selectedStaff}
            />
          </WithLayout>
        </Route>
      )}
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}

type LayoutProps = {
  patientId: string;
  headerInfo?: React.ReactNode;
  children: React.ReactNode;
  disableBack?: boolean;
};

function WithLayout(props: LayoutProps) {
  const history = useHistory();
  return (
    <Paper
      sx={{
        margin: 1,
        padding: 1,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: 2,
        minHeight: 0,
        overflowY: "auto",
      }}
    >
      <Box display="flex" gap={2} alignItems="baseline">
        <Typography variant="h6" color="primary">
          New Appointment
        </Typography>
        {props.headerInfo || null}
        <Box display="flex" gap={1} alignItems="center" marginLeft="auto">
          {!props.disableBack && (
            <Button
              variant="outlined"
              onClick={() => history.goBack()}
              startIcon={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}
          <Button
            variant="outlined"
            color="error"
            onClick={() => history.push(`/patient/${props.patientId}/schedule`)}
          >
            Cancel
          </Button>
        </Box>
      </Box>
      {props.children}
    </Paper>
  );
}

export function SearchParameterChips(props: { searchParams: SearchParams }) {
  const appointmentTypeConfigurations =
    useCachedAppointmentTypeConfigurations(true);
  const clinics = useCachedClinics();
  return (
    <Box display="flex" gap={1} alignItems="center">
      <Chip
        size="small"
        label={getAppointmentTypeLabel(
          props.searchParams.appointmentType,
          appointmentTypeConfigurations,
        )}
      />
      {props.searchParams.medium === AppointmentMedium.InClinic ? (
        <>
          {Array.from(props.searchParams.clinicIds).map((clinicId) => (
            <Chip
              size="small"
              key={clinicId}
              label={clinics[clinicId]?.name || clinicId}
            />
          ))}
        </>
      ) : (
        <Chip size="small" label={getMediumName(props.searchParams.medium)} />
      )}
    </Box>
  );
}
