import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useState } from "react";
import { includes } from "lodash";
import { getDayOfWeekName } from "../../../../libs/scheduleTemplates";

type Props = {
  sourceDay: number;
  onDuplicate: (targetDays: Array<number>) => void;
};

export default function DuplicateDayDialog(props: Props) {
  const [targetDays, setTargetDays] = useState<Array<number>>([]);

  const toggleDay = (day: number, checked: boolean) => {
    if (checked && !includes(targetDays, day)) {
      setTargetDays([...targetDays, day]);
    } else if (!checked) {
      setTargetDays(targetDays.filter((d) => d !== day));
    }
  };

  return (
    <Dialog open={true} onClose={() => props.onDuplicate([])}>
      <DialogTitle>Duplicate Day</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the days to duplicate the {getDayOfWeekName(props.sourceDay)}{" "}
          schedule to. Any existing slots on the selected days will be lost.
        </DialogContentText>
        <FormGroup>
          {Array.from({ length: 5 }, (_, i) => i + 1).map((dayOfWeek) => (
            <FormControlLabel
              key={dayOfWeek}
              control={
                <Checkbox
                  checked={targetDays.includes(dayOfWeek)}
                  disabled={dayOfWeek === props.sourceDay}
                  onChange={(event) =>
                    toggleDay(dayOfWeek, event.target.checked)
                  }
                />
              }
              label={getDayOfWeekName(dayOfWeek)}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onDuplicate([])}>Cancel</Button>
        <Button
          onClick={() => props.onDuplicate(targetDays)}
          variant="contained"
          disabled={targetDays.length === 0}
        >
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
