// @flow
import * as React from "react";
import {Box, Link, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import MasqueradeDialog from "./MasqueradeDialog";
import {useRef} from "react";
import SendIcon from '@mui/icons-material/Send';

type Props = {
  draftMessage: string,
  selectedFile: File | null,
  loading: boolean,
  disabled: boolean,

  onDraftMessageChange: (message: string) => void,
  onSelectedFileChange: (file: File | null) => void,
  onSend: (metadata: ?any) => void,
};

export default function Compose(props: Props) {
  const [showMasqueradeDialog, setShowMasqueradeDialog] = React.useState(false);
  const effectiveDisabled = props.disabled || (props.draftMessage.length === 0 && !props.selectedFile);
  const fileInputRef = useRef();

  const handleSendAs = (user) => {
    setShowMasqueradeDialog(false);
    props.onSend({
      masquerade_as_user: {
        nickname: user.displayName,
        credentials_abbreviation: user.credentialsAbbreviation,
        profile_url: user.avatarUrl
      }
    });
  };

  const handleFileButtonClick = () => {
    props.onSelectedFileChange(null);
    fileInputRef.current?.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    props.onSelectedFileChange(file);
  };

  const disableImagePicker = props.disabled || props.loading || !!props.draftMessage.length;
  return (
    <Box display="flex" gap={1}>
      <Box flexGrow={1}>
        <TextField
          multiline
          rows={2}
          fullWidth
          placeholder={props.selectedFile
            ? `Attached image ${props.selectedFile.name}`
            : props.disabled ? "You must claim this conversation to send messages": "Type a message"}
          value={props.draftMessage}
          disabled={props.disabled || props.loading || !!props.selectedFile}
          onChange={event => props.onDraftMessageChange(event.target.value)}
        />
        {props.selectedFile && (
          <Link
            component="button"
            variant="body2"
            onClick={_ => props.onSelectedFileChange(null)}
            color={disableImagePicker ? "#dedede": "text.secondary"}
            sx={disableImagePicker ? {fontSize: 12, cursor: "default"} : {fontSize: 12}}
            disabled={disableImagePicker}
          >
            Remove image
          </Link>
        )}
        {!props.selectedFile && (
          <Link
            component="button"
            variant="body2"
            onClick={handleFileButtonClick}
            color={disableImagePicker ? "#dedede": "text.secondary"}
            sx={disableImagePicker ? {fontSize: 12, cursor: "default"} : {fontSize: 12}}
            disabled={disableImagePicker}
          >
            Or select an image…
          </Link>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <LoadingButton
          startIcon={<SendIcon/>}
          variant="contained"
          loading={props.loading}
          disabled={effectiveDisabled}
          onClick={_ => props.onSend(null)}
        >
          Send
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          loading={props.loading}
          disabled={effectiveDisabled}
          onClick={_ => setShowMasqueradeDialog(true)}
        >
          Send As…
        </LoadingButton>
      </Box>
      {showMasqueradeDialog && (
        <MasqueradeDialog
          message={props.draftMessage || `Attached image ${props.selectedFile.name}`}
          onSendAs={handleSendAs}
          onClose={_ => setShowMasqueradeDialog(false)}
        />
      )}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/png, image/jpeg"
        style={{display: "none"}}
        onChange={handleFileChange}
      />
    </Box>
  );
}
