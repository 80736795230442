// @flow checked 01/24/23
import React, {useState} from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ListItem,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useQuery} from "@apollo/client";
import {GET_ELIGIBLE_PRACTITIONERS} from "../../../../../../../libs/queries";
import DetailedAlert from "../../../../../../DetailedAlert";
import {getPractitionerTypeName} from "../../../../../../../libs/scheduling";
import type {
  AppointmentMedium,
  AppointmentTypeConfiguration,
  PractitionerType
} from "../../../../../../../generated/flow_types";

const style = {
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  left: "50%",
  p: 2,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: 368,
};

type Props = {
  appointmentMedium: AppointmentMedium,
  appointmentConfig: AppointmentTypeConfiguration,
  supportingPractitionerType: ?PractitionerType,
  filterByClinicIds: string[],
  filterByLeadPractitionerIds: string[],
  filterBySupportingPractitionerIds: string[],
  onFiltersChange: (filterByLeadPractitionerIds: string[], filterBySupportingPractitionerIds: string[]) => void,
  onClose: () => void,
};

const PractitionersModal = (props: Props) => {
  const hasSupporting = !!props.supportingPractitionerType;

  const {data, error, loading, refetch} = useQuery(
    GET_ELIGIBLE_PRACTITIONERS,
    {
      variables: {
        appointmentMedium: props.appointmentMedium,
        appointmentType: props.appointmentConfig.appointmentType,
        filterByClinicIds: props.filterByClinicIds,
        supportingPractitionerType: props.supportingPractitionerType,
      },
    }
  );

  const [leadPractitioners, setLeadPractitioners] = useState(
    props.filterByLeadPractitionerIds
  );
  const [allLeadPractitioners, setAllLeadPractitioners] = useState(false);
  const [allSupportingPractitioners, setAllSupportingPractitioners] = useState(false);
  const [supportingPractitioners: string[], setSupportingPractitioners] = useState(
    props.filterBySupportingPractitionerIds
  );

  const handleApply = () => {
    props.onFiltersChange(leadPractitioners, supportingPractitioners);
    props.onClose();
  };

  const handleClear = () => {
    setLeadPractitioners([]);
    setSupportingPractitioners([]);
    setAllSupportingPractitioners(false);
    setAllLeadPractitioners(false);
  };

  const handleLeadCheckbox = ({target: {checked, name}}) => {
    if (name === "all") {
      if (checked) {
        setAllLeadPractitioners(true);

        const allLeadPractitioners = data.eligiblePractitioners.leadPractitioners?.map(
          ({id}) => id
        );

        setLeadPractitioners(allLeadPractitioners);
      } else {
        setAllLeadPractitioners(false);
        setLeadPractitioners([]);
      }

      return;
    }

    if (checked) {
      setLeadPractitioners((state) => [...state, name]);
    } else {
      const newLeadList = leadPractitioners.filter(
        (practitioner) => practitioner !== name
      );
      setLeadPractitioners(newLeadList);
      setAllLeadPractitioners(false);
    }
  };

  const handleSupportingCheckbox = ({target: {checked, name}}) => {
    if (name === "all") {
      if (checked) {
        setAllSupportingPractitioners(true);

        const allSupportingPractitioners = data.eligiblePractitioners.supportingPractitioners?.map(
          ({id}) => id
        );

        setSupportingPractitioners(allSupportingPractitioners);
      } else {
        setAllSupportingPractitioners(false);
        setSupportingPractitioners([]);
      }

      return;
    }

    if (checked) {
      setSupportingPractitioners((state) => [...state, name]);
    } else {
      const newSupportingList = supportingPractitioners.filter(
        (practitioner) => practitioner !== name
      );
      setSupportingPractitioners(newSupportingList);
      setAllSupportingPractitioners(false);
    }
  };

  if (loading) {
    return (
      <Modal onClose={props.onClose} open={true}>
        <Box sx={{...style, display: 'flex', justifyContent: 'center'}}>
          <CircularProgress/>
        </Box>
      </Modal>
    );
  }

  if (error || !data) {
    return (
      <Modal onClose={props.onClose} open={true}>
        <Box sx={style}>
          <DetailedAlert
            message="Oops! Failed to get Practitioners. Please try again."
            retry={() => refetch()}
            additionalDetails={error}
          />
        </Box>
      </Modal>
    );
  }

  if (!data.eligiblePractitioners.leadPractitioners?.length) {
    return (
      <Modal onClose={props.onClose} open={true}>
        <Box sx={style}>
          <Alert severity="warning">No practitioners available</Alert>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal onClose={props.onClose} open={true}>
      <Box sx={!hasSupporting ? style : {...style, width: 740}}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
          <Typography color="primary" variant="h6" component="h2">
            Filter Practitioners
          </Typography>
          <IconButton onClick={props.onClose}>
            <CloseIcon color="primary"/>
          </IconButton>
        </Box>
        <Grid container gap={1}>
          <Box style={{flexGrow: 1}} m={2}>
            <Typography color="primary" style={{marginBottom: 16}}>
              Lead ({getPractitionerTypeName(props.appointmentConfig.leadPractitionerType)})
            </Typography>
            <FormControl fullWidth style={{overflow: "auto"}}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox/>}
                  checked={allLeadPractitioners}
                  name="all"
                  label="Select All"
                  onChange={handleLeadCheckbox}
                />
                <ListItem divider style={{margin: "-12px 0 6px"}}/>
                {data.eligiblePractitioners.leadPractitioners?.map(
                  ({displayName, id}) => (
                    <FormControlLabel
                      control={<Checkbox/>}
                      checked={leadPractitioners?.indexOf(id) > -1}
                      key={id}
                      label={displayName}
                      name={id}
                      onChange={handleLeadCheckbox}
                    />
                  )
                )}
              </FormGroup>
            </FormControl>
          </Box>
          {hasSupporting && (
            <Box style={{flexGrow: 1}} m={2}>
              <Typography color="primary" style={{marginBottom: 16}}>
                Supporting{" "}
                {props.supportingPractitionerType &&
                `(${getPractitionerTypeName(props.supportingPractitionerType)})`}
              </Typography>
              <FormControl fullWidth style={{overflow: "auto"}}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox/>}
                    checked={allSupportingPractitioners}
                    name="all"
                    label="Select All"
                    onChange={handleSupportingCheckbox}
                  />
                  <ListItem divider style={{margin: "-12px 0 6px"}}/>
                  {data.eligiblePractitioners.supportingPractitioners?.map(
                    ({displayName, id}) => (
                      <FormControlLabel
                        control={<Checkbox/>}
                        checked={supportingPractitioners?.indexOf(id) > -1}
                        key={id}
                        label={displayName}
                        name={id}
                        onChange={handleSupportingCheckbox}
                      />
                    )
                  )}
                </FormGroup>
              </FormControl>
            </Box>
          )}
        </Grid>
        <Grid container mt={3} style={{textAlign: "right"}}>
          <Grid item xs={12}>
            <Button onClick={handleClear} style={{marginRight: "8px"}}>
              CLEAR
            </Button>
            <Button onClick={handleApply} variant="contained">
              APPLY
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PractitionersModal;
