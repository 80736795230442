import React from "react";
import { graphql } from "../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import { useMutation } from "@apollo/client";
import ScheduleConflicts from "../ScheduleConflicts";
import { ALL_SCHEDULES_QUERY } from "../ScheduleDraftEditor/ViewPublishedScheduleButton";

graphql(`
  fragment SchedulingConflictFields on SchedulingConflict {
    appointmentId
    patientId
    reasons
  }
`);

type Props = {
  scheduleDraftId: string;
};

const CONFLICTS_ON_PUBLISH = graphql(`
  query ConflictsOnPublishDraft($scheduleId: String!) {
    conflictsOnPublishDraft(scheduleId: $scheduleId) {
      ...SchedulingConflictFields
    }
  }
`);

const PUBLISH_SCHEDULE_DRAFT = graphql(`
  mutation PublishScheduleDraft(
    $scheduleId: String!
    $ignoreConflictsOn: [String!]!
  ) {
    publishScheduleDraft(
      scheduleId: $scheduleId
      ignoreConflictsOn: $ignoreConflictsOn
    ) {
      ...ScheduleFields
    }
  }
`);

export default function ScheduleDraftPublisher(props: Props) {
  const { data, loading, error, refetch } = useQuery(CONFLICTS_ON_PUBLISH, {
    variables: { scheduleId: props.scheduleDraftId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const [publish, { error: publishError, loading: publishLoading }] =
    useMutation(PUBLISH_SCHEDULE_DRAFT);

  const handlePublish = async (ignoredConflicts: ReadonlySet<string>) => {
    try {
      await publish({
        variables: {
          scheduleId: props.scheduleDraftId,
          ignoreConflictsOn: Array.from(ignoredConflicts),
        },
        refetchQueries: [
          {
            query: ALL_SCHEDULES_QUERY,
          },
        ],
      });
    } catch (e) {
      // Most likely a new conflict. Refetch automatically.
      await refetch();
    }
  };

  return (
    <ScheduleConflicts
      operation="publish"
      scheduleId={props.scheduleDraftId}
      conflictsQueryResults={{
        conflicts: data?.conflictsOnPublishDraft,
        loading,
        error,
        refetch,
      }}
      onSubmit={handlePublish}
      submitLoading={publishLoading}
      submitError={publishError}
    />
  );
}
