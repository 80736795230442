import {
  AppointmentConfigMap,
  getSlotName,
  HighlightRule,
  isHighlighted,
  LocalSlot,
} from "../../../../libs/scheduleTemplates";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { getSlotBackgroundColor } from "../../../../libs/colors";
import React from "react";
import { convertMinutesToHeight } from "../../../../libs/time";

type Props = {
  slot: LocalSlot;
  appointmentTypeConfigurations: AppointmentConfigMap;
  highlightRule?: HighlightRule | null;
};

export default function TimeSlotView(props: Props) {
  const heightInPixels = convertMinutesToHeight(
    props.slot.endMinutes - props.slot.startMinutes,
  );
  const dense = heightInPixels <= 30;

  return (
    <Box
      height={`${heightInPixels}px`}
      overflow="hidden"
      display="flex"
      alignItems="stretch"
    >
      <Box
        mb="1px"
        paddingY={dense ? 0 : 1}
        paddingX={1}
        display="flex"
        gap={1}
        flex={1}
        border={(theme) =>
          isHighlighted(props.slot, props.highlightRule)
            ? `2px solid ${theme.palette.primary.main}`
            : `1px solid ${grey[400]}`
        }
        borderRadius={1}
        overflow="hidden"
        bgcolor={getSlotBackgroundColor(props.slot)}
        alignItems={dense ? "center" : "flex-start"}
      >
        <Typography
          variant="caption"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {getSlotName(props.slot, props.appointmentTypeConfigurations)}
        </Typography>
      </Box>
    </Box>
  );
}
