import { useQuery } from "@apollo/client/react/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import Tile from "../../../Tile";
import RefreshIcon from "@mui/icons-material/Refresh";
import { VISIT_NOTE_BY_ID } from "../ViewOrEditVisitNote";
import {
  AddLineSuggestion,
  ChangeLineSuggestion,
  EditableOrderLine,
  getAutoSelectedOrderIds,
  getSuggestedActions,
  isAddSuggestion,
  isChangeSuggestion,
  OrderLineConfig,
  SuggestedAction,
} from "../../../../libs/orderLines";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { amber, grey, indigo, yellow } from "@mui/material/colors";
import { every, pickBy } from "lodash";
import {
  AssessmentStatus,
  OrderSuggestion,
  VisitNote_AssessmentFieldsFragment,
} from "../../../../gql/graphql";
import { ReactNode, useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import StarIcon from "@mui/icons-material/Star";
import { bodyPartNames } from "../../../../libs/constants";
import {
  ExperimentalFeature,
  useExperimentalFeatures,
} from "../../../../hooks/experimentalFeatures";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLazyRecordEvent } from "../../../../hooks/telemetry";

type Props = {
  visitNoteId: string;
  lines: { [lineId: string]: EditableOrderLine };
  linesConfig: { [lineId: string]: OrderLineConfig };
  suggestions: { [orderId: string]: OrderSuggestion };
  onChangeLines: (lines: Array<EditableOrderLine>) => void;
};

export default function OrderSuggestions(props: Props) {
  const { checkExperimentalFeatureEnabled, toggleExperimentalFeature } =
    useExperimentalFeatures();
  const { data, loading, refetch } = useQuery(VISIT_NOTE_BY_ID, {
    variables: { id: props.visitNoteId },
    notifyOnNetworkStatusChange: true,
  });

  const showOrderSuggestions = checkExperimentalFeatureEnabled(
    ExperimentalFeature.showOldOrderSuggestions,
  );
  const assessments = data?.visitNoteById?.assessments ?? null;
  const completedAllAssessments = every(
    assessments,
    (assessment) => assessment.status === AssessmentStatus.Completed,
  );

  if (assessments?.length === 0) {
    return (
      <OrderSuggestionsTile
        refetch={refetch}
        loading={loading}
        showSuggestions={showOrderSuggestions}
      >
        <Typography variant="body2" color="textSecondary">
          Add assessments to see{" "}
          {showOrderSuggestions ? "recommendations" : "insights"}.
        </Typography>
      </OrderSuggestionsTile>
    );
  }

  const suggestions = getSuggestedActions(
    props.lines,
    props.linesConfig,
    props.suggestions,
  );

  const handleChangeLines = (
    lines: Array<{ lineId: string; orderId: string }>,
  ) => {
    props.onChangeLines(
      lines.map((line) => ({
        lineId: line.lineId,
        selectedOrderId: line.orderId,
        bodyParts: props.suggestions[line.orderId].bodyParts,
      })),
    );
  };

  const addSuggestions = suggestions.filter(isAddSuggestion);
  const addRecommendedSuggestions = addSuggestions.filter(
    (suggestion) => suggestion.visibility === "recommended",
  );
  const addAddOnSuggestions = addSuggestions.filter(
    (suggestion) => suggestion.visibility === "add-on",
  );
  const changeSuggestions = suggestions.filter(isChangeSuggestion);

  return (
    <OrderSuggestionsTile
      refetch={refetch}
      loading={loading}
      showSuggestions={showOrderSuggestions}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <OrderSuggestionDeprecationNote
          showOrderSuggestions={showOrderSuggestions}
          toggleShowOrderSuggestions={() =>
            toggleExperimentalFeature(
              ExperimentalFeature.showOldOrderSuggestions,
            )
          }
        />
        {showOrderSuggestions && (
          <>
            {!completedAllAssessments && (
              <Typography variant="body2" color="textSecondary">
                Partial recommendations based on all available assessment
                answers so far. Continue filling out assessments to get more
                recommendations.
              </Typography>
            )}
            <AddSuggestedLines
              title="Add Recommended Orders"
              color="primary"
              suggestions={addRecommendedSuggestions}
              onAdd={handleChangeLines}
            />
            <ChangeLineSuggestions
              suggestions={changeSuggestions}
              onChange={(lineId, orderId) =>
                handleChangeLines([{ lineId, orderId }])
              }
            />
            <AddSuggestedLines
              title="Add-ons"
              suggestions={addAddOnSuggestions}
              onAdd={handleChangeLines}
            />
          </>
        )}
        {assessments && (
          <>
            {showOrderSuggestions && <Divider />}
            <AssessmentInsights assessments={assessments} />
          </>
        )}
      </Box>
    </OrderSuggestionsTile>
  );
}

type AddLinesSectionProps = {
  title: string;
  color?: "primary";
  suggestions: Array<AddLineSuggestion>;
  onAdd: (newLines: Array<{ lineId: string; orderId: string }>) => void;
};

function AddSuggestedLines(props: AddLinesSectionProps) {
  const [selectedOrderIds, setSelectedOrderIds] = useState<{
    [lineId: string]: string;
  }>({});

  useEffect(() => {
    // Clean up selected orders when suggestions change
    setSelectedOrderIds((selectedOrderIds) =>
      pickBy(selectedOrderIds, (orderId, lineId) =>
        props.suggestions.some(
          (suggestion) =>
            suggestion.line.lineId === lineId &&
            suggestion.line.type === "group" &&
            suggestion.line.orders.some((order) => order.id === orderId),
        ),
      ),
    );
  }, [props.suggestions]);

  const getSelectedOrderId = (lineConfig: OrderLineConfig): string | null => {
    if (lineConfig.type === "standalone") {
      return lineConfig.order.id;
    }
    if (lineConfig.orders.length === 1) {
      return lineConfig.orders[0].id;
    }
    return selectedOrderIds[lineConfig.lineId] ?? null;
  };

  const handleAddOne = (lineConfig: OrderLineConfig) => {
    const orderId = getSelectedOrderId(lineConfig);
    if (orderId) {
      props.onAdd([{ lineId: lineConfig.lineId, orderId }]);
    }
  };

  const handleAddAll = () => {
    const newLinesToAdd = props.suggestions.flatMap((suggestion) => {
      const orderId = getSelectedOrderId(suggestion.line);
      if (orderId) {
        return [{ lineId: suggestion.line.lineId, orderId }];
      }
      return [];
    });
    if (newLinesToAdd.length > 0) {
      props.onAdd(newLinesToAdd);
    }
  };

  const handleSelectOrder = (lineId: string, orderId: string) => {
    setSelectedOrderIds((selectedOrderIds) => ({
      ...selectedOrderIds,
      [lineId]: orderId,
    }));
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        justifyContent="space-between"
        p={1}
        sx={{
          backgroundColor: props.color === "primary" ? indigo[50] : grey[100],
          borderRadius: 1,
        }}
      >
        <Typography variant="body1" color={props.color}>
          {props.title}{" "}
          {props.suggestions.length > 0 && `(${props.suggestions.length})`}
        </Typography>
        {props.suggestions.length > 0 && (
          <Link
            component="button"
            fontSize="small"
            onClick={handleAddAll}
            variant="caption"
          >
            add all
          </Link>
        )}
      </Box>
      {props.suggestions.length === 0 && (
        <Typography
          variant="body2"
          color="textSecondary"
          fontStyle="italic"
          ml={1}
        >
          No suggestions ✓
        </Typography>
      )}
      {props.suggestions.map((suggestion) => (
        <SuggestedLine
          key={suggestion.line.lineId}
          suggestion={suggestion}
          selectedOrderId={getSelectedOrderId(suggestion.line)}
          onAccept={() => handleAddOne(suggestion.line)}
          onSelectedOrderChange={(orderId) =>
            handleSelectOrder(suggestion.line.lineId, orderId)
          }
        />
      ))}
    </Box>
  );
}

type ChangeLineSuggestionsProps = {
  suggestions: Array<ChangeLineSuggestion>;
  onChange: (lineId: string, orderId: string) => void;
};

function ChangeLineSuggestions(props: ChangeLineSuggestionsProps) {
  const [selectedOrderIds, setSelectedOrderIds] = useState<{
    [lineId: string]: string;
  }>(() => getAutoSelectedOrderIds(props.suggestions));

  useEffect(() => {
    // Remove selected orders that don't exist anymore, and auto-select where possible.
    setSelectedOrderIds((selectedOrderIds) => {
      const sanitizedSelectedOrderIds = pickBy(
        selectedOrderIds,
        (orderId, lineId) =>
          props.suggestions.some(
            (suggestion) =>
              suggestion.line.lineId === lineId &&
              suggestion.line.orders.some((order) => order.id === orderId),
          ),
      );
      return {
        ...sanitizedSelectedOrderIds,
        ...getAutoSelectedOrderIds(props.suggestions),
      };
    });
  }, [props.suggestions]);

  const handleSelectOrder = (lineId: string, orderId: string) => {
    setSelectedOrderIds((selectedOrderIds) => ({
      ...selectedOrderIds,
      [lineId]: orderId,
    }));
  };

  const handleAccept = (lineId: string) => {
    const orderId = selectedOrderIds[lineId];
    if (orderId) {
      props.onChange(lineId, orderId);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        justifyContent="space-between"
        p={1}
        sx={{
          backgroundColor: indigo[50],
          borderRadius: 1,
        }}
      >
        <Typography variant="body1" color="primary">
          Update Selected Orders{" "}
          {props.suggestions.length > 0 && `(${props.suggestions.length})`}
        </Typography>
      </Box>
      {props.suggestions.length === 0 && (
        <Typography
          variant="body2"
          color="textSecondary"
          fontStyle="italic"
          ml={1}
        >
          No suggestions ✓
        </Typography>
      )}
      {props.suggestions.map((suggestion) => (
        <SuggestedLine
          key={suggestion.line.lineId}
          suggestion={suggestion}
          selectedOrderId={selectedOrderIds[suggestion.line.lineId] ?? null}
          onAccept={() => handleAccept(suggestion.line.lineId)}
          onSelectedOrderChange={(orderId) =>
            handleSelectOrder(suggestion.line.lineId, orderId)
          }
        />
      ))}
    </Box>
  );
}

type SuggestedLineProps = {
  suggestion: SuggestedAction;
  selectedOrderId: string | null;
  onAccept: () => void;
  onSelectedOrderChange: (orderId: string) => void;
};
function SuggestedLine(props: SuggestedLineProps) {
  const line = props.suggestion.line;
  const { label, selectOptions, groupName } = (() => {
    if (line.type === "standalone") {
      return { label: line.order.name, selectOptions: null, groupName: null };
    }
    if (props.suggestion.type === "change" || line.orders.length > 1) {
      return {
        label: null,
        selectOptions: line.orders,
        groupName: line.group.name,
      };
    }
    return {
      label: line.orders[0].name,
      selectOptions: null,
      groupName: null,
    };
  })();

  return (
    <Box display="flex" gap={1} p={0.5} alignItems="center">
      <Typography variant="body2">•</Typography>
      {!!label && (
        <Typography variant="body2" flexGrow={1}>
          {label}
        </Typography>
      )}
      {!!selectOptions && (
        <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
          <InputLabel id="suggestion-label" sx={{ typography: "body2" }}>
            {groupName!}
          </InputLabel>
          <Select
            labelId="suggestion-label"
            sx={{ typography: "body2" }}
            label={groupName!}
            value={props.selectedOrderId || ""}
            onChange={(e) => props.onSelectedOrderChange(e.target.value)}
            renderValue={(orderId) =>
              selectOptions!.find((order) => order.id === orderId)!.name
            }
          >
            {props.suggestion.type === "change" && (
              <MenuItem value="" disabled>
                <Box display="flex" alignItems="center" gap={1}>
                  {props.suggestion.oldOrderName}
                  <Chip size="small" label="selected" />
                </Box>
              </MenuItem>
            )}
            {selectOptions.map((order) => (
              <MenuItem key={order.id} value={order.id}>
                <Box display="flex" alignItems="center" gap={1}>
                  {order.name}
                  {props.suggestion.type === "change" && (
                    <Tooltip title="Recommended">
                      <StarIcon sx={{ color: yellow[700] }} fontSize="small" />
                    </Tooltip>
                  )}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Link
        component="button"
        variant="caption"
        fontSize="small"
        onClick={props.onAccept}
        sx={(theme) =>
          !props.selectedOrderId
            ? {
                color: theme.palette.action.disabled,
                pointerEvents: "none", // Prevents hover and click events
                cursor: "default",
                textDecoration: "none",
              }
            : {}
        }
      >
        {props.suggestion.type === "change" ? "accept" : "add"}
      </Link>
    </Box>
  );
}

function OrderSuggestionsTile(props: {
  children?: ReactNode;
  showSuggestions: boolean;
  refetch: () => void;
  loading: boolean;
}) {
  return (
    <Tile
      title={
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <TipsAndUpdatesIcon fontSize="small" sx={{ color: amber[400] }} />
          <Typography variant="body1">
            {props.showSuggestions ? "Suggestions" : "Insights"}
          </Typography>
          <IconButton
            onClick={() => props.refetch()}
            size="small"
            disabled={props.loading}
          >
            <RefreshIcon fontSize="inherit" />
          </IconButton>
        </Box>
      }
    >
      {props.children}
    </Tile>
  );
}

type AssessmentInsightsProps = {
  assessments: Array<VisitNote_AssessmentFieldsFragment>;
};

function AssessmentInsights(props: AssessmentInsightsProps) {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="body1">Assessment Insights</Typography>
      <ul style={{ margin: 0, paddingLeft: 20 }}>
        {props.assessments.map((assessment) => (
          <li key={assessment.id}>
            <Typography variant="body2">{assessment.name}</Typography>
            <ul style={{ margin: 0, paddingLeft: 20 }}>
              {assessment.scoringOutput.exceptions.length === 0 &&
                assessment.scoringOutput.pathways.length === 0 && (
                  <li>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontStyle="italic"
                    >
                      No insights yet
                    </Typography>
                  </li>
                )}
              {assessment.scoringOutput.exceptions.map((exception) => (
                <li key={exception.name}>
                  <Typography variant="body2" component="span">
                    Exception:{" "}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {exception.name}{" "}
                  </Typography>
                  {exception.bodyPart && (
                    <Chip
                      size="small"
                      label={bodyPartNames[exception.bodyPart]}
                    />
                  )}
                </li>
              ))}
              {assessment.scoringOutput.pathways.map((pathway) => (
                <li key={pathway.name}>
                  <Typography variant="body2" component="span">
                    Pathway:{" "}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {pathway.name}{" "}
                  </Typography>
                  {pathway.bodyPart && (
                    <Chip
                      size="small"
                      label={bodyPartNames[pathway.bodyPart]}
                    />
                  )}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </Box>
  );
}

function OrderSuggestionDeprecationNote(props: {
  showOrderSuggestions: boolean;
  toggleShowOrderSuggestions: () => void;
}) {
  const recordEvent = useLazyRecordEvent();
  const [expanded, setExpanded] = useState(false);

  const toggleShowOrderSuggestions = () => {
    recordEvent("com.motion.toggle_order_suggestions", {
      visible: !props.showOrderSuggestions,
    });
    setExpanded(false);
    props.toggleShowOrderSuggestions();
  };

  return (
    <Accordion
      disableGutters
      square
      elevation={0}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.divider}`,
        "&::before": {
          display: "none",
        },
      })}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        sx={{
          backgroundColor: "#e5f6fd",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
        }}
      >
        <InfoOutlinedIcon sx={{ mr: 1, color: "info.main" }} fontSize="small" />
        <Typography variant="body2" sx={{ color: "#014361" }}>
          {props.showOrderSuggestions
            ? "Order suggestions will be removed soon."
            : "Looking for order suggestions?"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({ borderTop: `1px solid ${theme.palette.divider}` })}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          alignItems="flex-start"
        >
          <Typography variant="body2">
            <b>Order suggestions will be deprecated soon</b>. If you rely on
            this feature, please let Allison or Sam know!
          </Typography>
          {props.showOrderSuggestions ? (
            <Link
              component="button"
              onClick={toggleShowOrderSuggestions}
              variant="body2"
            >
              Click here to hide suggestions.
            </Link>
          ) : (
            <>
              <Typography variant="body2">
                If you do want to use suggestions until then,
              </Typography>
              <Link
                component="button"
                onClick={toggleShowOrderSuggestions}
                variant="body2"
                sx={{ textAlign: "left" }}
              >
                click here to re-enable the feature.
              </Link>
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
