// @flow checked 01/24/23
import React from "react";
import type {AppointmentMedium, Clinic, Practitioner} from "../../../../../generated/flow_types";
import {AppointmentMediumValues} from "../../../../../generated/flow_types";
import {DateTime} from "luxon";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {Avatar, Box, Chip, Typography} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AppointmentMediumLabel from "../../AppointmentMediumLabel";

type Props = {
  appointmentMedium: AppointmentMedium,
  clinic: ?Clinic,
  startTime: number, // unix seconds
  endTime: number, // unix seconds
  leadPractitioner: Practitioner,
  supportingPractitioner: ?Practitioner,
  onClick: () => void,
  showDate: boolean,
  displayTimezone: string,
  selected: boolean,
};

/**
 * Renders a single slot
 */
const Slot = (props: Props) => {
  const isInClinic = props.appointmentMedium === AppointmentMediumValues.InClinic;
  const slotDate = DateTime.fromSeconds(props.startTime, {zone: props.displayTimezone}).toFormat('LL/dd/kkkk')
  const slotStartTime = DateTime.fromSeconds(props.startTime, {zone: props.displayTimezone})
    .toFormat("h:mm a");
  const slotEndTime = DateTime.fromSeconds(props.endTime, {zone: props.displayTimezone})
    .toFormat("h:mm a ZZZZ");

  return (
    <div
      onClick={props.onClick}
      style={{
        alignItems: "center",
        border: props.selected ? "1px solid #61679e" : "1px solid transparent",
        borderRadius: 4,
        boxShadow: !props.selected && "0px 1px 2px -1px rgba(66, 68, 90, 1)",
        cursor: "pointer",
        display: "grid",
        gap: 10,
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: 10,
        textAlign: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#F6F7FA",
          borderRadius: 8,
          display: "grid",
          padding: 12,
          textAlign: "left",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "grid",
            gridTemplateColumns: "auto 1fr",
          }}
        >
          <AccessTimeIcon
            sx={{ color: "#8BA5CD", fontSize: 18, marginRight: 1 }}
          />
          <Typography
            color="primary"
            style={{ fontSize: 20, lineHeight: "32px" }}
          >
            {`${slotStartTime} - ${slotEndTime}`}
          </Typography>
        </div>
        {props.showDate && (
          <div
            style={{
              alignItems: "center",
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              marginTop: 8,
            }}
          >
            <DateRangeIcon
              sx={{ color: "#8BA5CD", fontSize: 18, marginRight: 1 }}
            />
            <Typography color="primary">{slotDate}</Typography>
          </div>
        )}
        <div
          style={{
            alignItems: "center",
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            marginTop: 8,
          }}
        >
          {isInClinic && !!props.clinic && (
            <>
              <AppointmentMediumLabel
                appointmentMedium={props.appointmentMedium}
                iconOnly={true}
              />
              <Box>
                <Typography color="primary">{props.clinic.name}</Typography>
                <Typography color="primary" variant="caption">
                  {props.clinic.addressLines.join(", ")}
                </Typography>
              </Box>
            </>
          )}
          {!isInClinic && (
            <AppointmentMediumLabel
              appointmentMedium={props.appointmentMedium}
              iconOnly={false}
              color="primary"
            />
          )}
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "grid",
          justifySelf: "center",
          gridTemplateColumns: "50px 1fr",
          textAlign: "left",
        }}
      >
        <Avatar src={props.leadPractitioner.avatarUrl} />
        <div style={{ display: "grid", gap: 4 }}>
          <Typography color="primary" variant="body">
            {props.leadPractitioner.displayName}
          </Typography>
          <Chip
            color="primary"
            label="Lead Practitioner"
            size="small"
            style={{ color: "white" }}
          />
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "grid",
          justifySelf: "center",
          gridTemplateColumns: "50px 1fr",
          textAlign: "left",
        }}
      >
        {props.supportingPractitioner && (
          <>
            <Avatar src={props.supportingPractitioner.avatarUrl} />
            <div style={{ display: "grid", gap: 4 }}>
              <Typography color="primary" variant="body">
                {props.supportingPractitioner.displayName}
              </Typography>
              <Chip
                label="Supporting Practitioner"
                size="small"
                style={{ color: "#61679e" }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Slot;
