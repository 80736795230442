import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";

type Props = {
  onClose: () => void;
  onMerge: (keepAlternate: boolean) => void;
};

/**
 * Dialog to confirm merging an A/B week schedule into a simple schedule.
 */
export function MergeScheduleDialog(props: Props) {
  const [choice, setChoice] = useState<"keepPrimary" | "keepAlternate">(
    "keepPrimary",
  );
  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Merge Schedule</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1" color="text.secondary">
            This will merge your "A" and "B" weeks into a single schedule. Which
            schedule do you want to keep?
          </Typography>
          <RadioGroup
            aria-labelledby="operation-label"
            name="radio-buttons-group"
            value={choice}
            onChange={(event) =>
              setChoice(event.target.value as "keepPrimary" | "keepAlternate")
            }
          >
            <FormControlLabel
              value="keepPrimary"
              control={<Radio />}
              label={'Keep primary ("A") schedule'}
            />
            <FormControlLabel
              value="keepAlternate"
              control={<Radio />}
              label={'Keep alternate ("B") schedule'}
            />
          </RadioGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => props.onMerge(choice === "keepAlternate")}
          color="primary"
        >
          Merge
        </Button>
      </DialogActions>
    </Dialog>
  );
}
