import React from "react";
import Chip from "@mui/material/Chip";
import { amber, teal, yellow } from "@mui/material/colors";

export enum VisitNoteStatusLabel {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
}

type Props = {
  status: VisitNoteStatusLabel;
};

export default function VisitNoteStatusChip(props: Props) {
  switch (props.status) {
    case VisitNoteStatusLabel.NOT_STARTED:
      return (
        <Chip
          size="small"
          sx={{ backgroundColor: amber[200] }}
          label="Not Started"
        />
      );
    case VisitNoteStatusLabel.IN_PROGRESS:
      return (
        <Chip
          size="small"
          sx={{ backgroundColor: amber[200] }}
          label="In Progress"
        />
      );
    case VisitNoteStatusLabel.COMPLETED:
      return (
        <Chip
          size="small"
          sx={{ backgroundColor: teal[400], color: "#fff" }}
          label="Completed"
        />
      );
  }
}
