import { useEffect, useState } from "react";
import { DocumentNode, MutationResult, useMutation } from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { OperationVariables } from "@apollo/client/core";
import useDeepCompareEffect from "use-deep-compare-effect";
import { DateTime } from "luxon";

export type AutoMutationResults<TData> = MutationResult<TData> & {
  dirty: boolean;
  lastSaveTime: DateTime | null;
  retry: () => Promise<void>;
};

/**
 * A hook that runs a mutation when variables change, but only after a debounce period.
 * Useful for implementing auto-save. This hooks guarantees the following:
 * - The mutation will run at least once after variables change
 * - No more than 1 mutation will be in-flight at a time
 * - The mutation will not run more than once per debounce period
 * @param mutation The mutation to run
 * @param variables The variables to pass to the mutation
 * @param debounceTimeInMs The time to wait after the last variable change before running the mutation
 */
export function useAutoMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  debounceTimeInMs: number = 3000,
): AutoMutationResults<TData> {
  const [lastUpdateTime, setLastUpdateTime] = useState<number | null>(null);
  const [lastUpdateTimeDebounced, setLastUpdateTimeDebounced] =
    useState<number>(0);
  const [lastSaveTime, setLastSaveTime] = useState<number>(0);

  const [mutate, results] = useMutation(mutation, {
    variables: variables,
  });

  // Variables have changed
  useDeepCompareEffect(() => {
    // Skip the initial render
    if (lastUpdateTime === null) {
      setLastUpdateTime(0);
      return;
    }

    const updateTimestamp = Date.now();
    setLastUpdateTime(updateTimestamp);

    const timeout = setTimeout(() => {
      setLastUpdateTimeDebounced(updateTimestamp);
    }, debounceTimeInMs);
    return () => {
      clearTimeout(timeout);
    };
  }, [variables]);

  // The effect that actually runs the mutation. Only 2 potential triggers:
  // - A previous update is debounced
  // - A previous mutation completed
  useEffect(() => {
    if (
      lastUpdateTimeDebounced === 0 ||
      lastUpdateTimeDebounced <= lastSaveTime ||
      results.loading
    ) {
      return;
    }

    mutate().then(() => {
      setLastSaveTime(lastUpdateTimeDebounced);
    });
  }, [lastSaveTime, lastUpdateTimeDebounced]);

  return {
    ...results,
    dirty:
      lastUpdateTime != null &&
      lastUpdateTime > 0 &&
      lastUpdateTime > lastSaveTime,
    lastSaveTime: lastSaveTime === 0 ? null : DateTime.fromMillis(lastSaveTime),
    retry: () =>
      mutate().then(() => {
        setLastSaveTime(Date.now());
      }),
  };
}
