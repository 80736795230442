import {
  AppointmentConfigMap,
  getDayOfWeekName,
  HighlightRule,
  LocalSlot,
} from "../../../../libs/scheduleTemplates";
import React, { useState } from "react";
import { Box, Popover, Typography } from "@mui/material";
import GridTimes from "../../ScheduleDraftEditor/GridTimes";
import GridLines from "../../ScheduleDraftEditor/GridLines";
import DailySlotsView from "../DailySlotsView";
import { groupBy } from "lodash";
import SlotDetails from "../SlotDetails";
import SlotInsights from "../../SlotInsights";

type Props = {
  weeklySlots: Array<LocalSlot>;
  minHour: number;
  maxHour: number;
  appointmentTypeConfigurations: AppointmentConfigMap;
};

const timeLabelsWidth = 55;

export default function WeeklySlotsView(props: Props) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [highlightRule, setHighlightRule] = useState<HighlightRule | null>(
    null,
  );
  const [selectedSlot, setSelectedSlot] = useState<{
    slot: LocalSlot;
    anchor: HTMLElement;
  } | null>(null);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setIsScrolled(event.currentTarget.scrollTop > 10);
  };

  const slotsByDay = groupBy(props.weeklySlots, (slot) => slot.dayOfWeek);

  return (
    <Box display="flex" gap={1} minHeight={0} px={1}>
      <Box display="flex" flexDirection="column" minHeight={0} flex={1}>
        {/*  Header */}
        <Box
          display="flex"
          p={0.5}
          borderBottom={isScrolled ? "1px solid #dedede" : "none"}
        >
          <Box width={timeLabelsWidth} />
          <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" flex={1}>
            {Array.from({ length: 5 }, (_, i) => i + 1).map((dayOfWeek) => (
              <Box
                key={dayOfWeek}
                display="flex"
                gap={1}
                justifyContent="center"
              >
                <Typography>{getDayOfWeekName(dayOfWeek)}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        {/*  Grid */}
        <Box
          display="flex"
          minHeight={0}
          sx={{ overflowY: "auto" }}
          flex={1}
          pt={1}
          pb={2}
          onScroll={handleScroll}
        >
          <Box width={timeLabelsWidth}>
            <GridTimes startHour={props.minHour} endHour={props.maxHour} />
          </Box>
          <Box position="relative" flex={1}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(5, 1fr)"
              zIndex={1}
              position="relative"
            >
              {Array(5)
                .fill(null)
                .map((_, i) => (
                  <DailySlotsView
                    key={i}
                    slots={slotsByDay[`${i + 1}`] || []}
                    minHour={props.minHour}
                    maxHour={props.maxHour}
                    appointmentTypeConfigurations={
                      props.appointmentTypeConfigurations
                    }
                    onSlotClick={(anchor, slot) =>
                      setSelectedSlot({ slot, anchor })
                    }
                    highlightRule={highlightRule}
                  />
                ))}
            </Box>
            <Box position="absolute" top={0} left={0} width="100%" zIndex={0}>
              <GridLines startHour={props.minHour} endHour={props.maxHour} />
            </Box>
          </Box>
        </Box>
        {!!selectedSlot && (
          <Popover
            open={true}
            anchorEl={selectedSlot.anchor}
            onClose={() => setSelectedSlot(null)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
          >
            <SlotDetails
              slot={selectedSlot.slot}
              appointmentTypeConfigurations={
                props.appointmentTypeConfigurations
              }
            />
          </Popover>
        )}
      </Box>
      <Box p={2}>
        <SlotInsights
          slots={props.weeklySlots}
          appointmentTypeConfigurations={props.appointmentTypeConfigurations}
          onHighlightChange={setHighlightRule}
        />
      </Box>
    </Box>
  );
}
