import React from "react";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import VisibilityChip from "../VisibilityChip";
import { DateTime } from "luxon";
import { green, grey } from "@mui/material/colors";
import { formsBaseUrl } from "../../../../config";
import QRCode from "react-qr-code";
import { Reference, StoreObject, useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import gql from "graphql-tag";
import DetailedAlert from "../../../DetailedAlert";
import { Assessment } from "../../../../gql/graphql";

type Props = {
  patientId: string;
  sortedPendingAssessments: Assessment[];
  highlightedAssessmentId: String | null;
};

const styles = {
  header: {
    backgroundColor: grey[200],
    fontWeight: "bold",
  },
  row: {
    transition: "background-color 400ms ease",
  },
  highlightedRow: {
    transition: "background-color 400ms ease",
    backgroundColor: green[50],
  },
};

export default function (props: Props) {
  const [showPatientLinkForAssessment, setShowPatientLinkForAssessment] =
    React.useState<Assessment | null>(null);
  const [confirmDeleteAssessment, setConfirmDeleteAssessment] =
    React.useState<String | null>(null);

  const [deletePendingAssessment, { error, loading }] = useMutation(
    DELETE_PENDING_ASSESSMENT_MUTATION,
  );

  const deleteAssessment = () => {
    if (!confirmDeleteAssessment) {
      return;
    }
    deletePendingAssessment({
      variables: {
        assessmentId: confirmDeleteAssessment,
      },
      // Remove from cache after successful deletion.
      update(cache) {
        cache.modify({
          fields: {
            assessmentsForPatient(existingAssessments, { readField }) {
              return existingAssessments.filter(
                (aiRef: Reference | StoreObject | undefined) =>
                  confirmDeleteAssessment !== readField("id", aiRef),
              );
            },
          },
        });
      },
    }).then((_) => setConfirmDeleteAssessment(null));
  };

  const cancelDelete = () => {
    if (loading) {
      return;
    }
    setConfirmDeleteAssessment(null);
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={styles.header}>Assessment</TableCell>
              <TableCell style={styles.header}>Shared With</TableCell>
              <TableCell style={styles.header}>Created On</TableCell>
              <TableCell style={styles.header}>Last Updated On ▼</TableCell>
              <TableCell style={styles.header}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.sortedPendingAssessments.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="body2" color="text.secondary">
                    No assessments in progress
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              props.sortedPendingAssessments.map((assessment) => (
                <AssessmentRow
                  key={assessment.id}
                  assessment={assessment}
                  highlightedAssessmentId={props.highlightedAssessmentId}
                  openPatientLinkModal={setShowPatientLinkForAssessment}
                  openDeletionModal={setConfirmDeleteAssessment}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!!showPatientLinkForAssessment && (
        <Dialog
          open={true}
          onClose={(_) => setShowPatientLinkForAssessment(null)}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle>Patient-Facing Assessment Link</DialogTitle>
          <DialogContent>
            <Alert severity="warning">
              <Typography variant="body2">
                Do not share the link below with anyone other than <b>this</b>{" "}
                patient, including Motion staff members!
              </Typography>
            </Alert>
            <Typography variant="body2" fontWeight="bold" my={1}>
              Note: this link expires on:{" "}
              {DateTime.fromSeconds(
                showPatientLinkForAssessment.patientAccessToken!!.expiresAt,
              ).toLocaleString(DateTime.DATETIME_MED)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {getPatientEditAssessmentLink(showPatientLinkForAssessment)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <QRCode
                  value={getPatientEditAssessmentLink(
                    showPatientLinkForAssessment,
                  )}
                  size={256}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={(_) => setShowPatientLinkForAssessment(null)}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {confirmDeleteAssessment && (
        <Dialog open={true} onClose={cancelDelete}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Any progress on this assessment will be lost. This action cannot
              be undone.
            </DialogContentText>
            {error && (
              <DetailedAlert
                message="Something went wrong. Please reload the page and retry."
                additionalDetails={error}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={cancelDelete}
              disabled={loading}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={deleteAssessment}
              loading={loading}
            >
              Delete Forever
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

const EditLink = (args: {
  assessment: Assessment;
  openModal: (assessment: Assessment) => void;
}) => {
  const { assessment, openModal } = args;
  return assessment.patientAccessToken ? (
    <Link
      component="button"
      variant="body2"
      onClick={(_) => openModal(assessment)}
    >
      Get Patient Link
    </Link>
  ) : (
    <Link
      variant="body2"
      href={getProviderEditAssessmentLink(assessment.id)}
      target="_blank"
    >
      Edit
    </Link>
  );
};

export const getProviderEditAssessmentLink = (assessmentId: string): string => {
  return `${formsBaseUrl()}/admin/assessments/${assessmentId}`;
};

const getPatientEditAssessmentLink = (assessment: Assessment): string => {
  return `${formsBaseUrl()}/assessments/${assessment.id}?t=${
    assessment.patientAccessToken!!.token
  }`;
};

const DELETE_PENDING_ASSESSMENT_MUTATION = gql`
  mutation deletePendingAssessment($assessmentId: String!) {
    deletePendingAssessment(assessmentId: $assessmentId)
  }
`;

const ExpiredChip = () => {
  return (
    <Chip
      size="small"
      label="expired"
      sx={{
        color: "#5E3436",
        backgroundColor: "#FDF2F3",
      }}
    />
  );
};

const AssessmentRow = (args: {
  assessment: Assessment;
  highlightedAssessmentId: String | null;
  openPatientLinkModal: (assessment: Assessment | null) => void;
  openDeletionModal: (id: String | null) => void;
}) => {
  const {
    assessment,
    highlightedAssessmentId,
    openPatientLinkModal,
    openDeletionModal,
  } = args;
  const patientFacing = !!assessment.patientAccessToken;

  return (
    <TableRow
      key={assessment.id}
      style={
        assessment.id === highlightedAssessmentId
          ? styles.highlightedRow
          : styles.row
      }
    >
      <TableCell>{assessment.name} </TableCell>
      <TableCell>
        <VisibilityChip patientFacing={!!assessment.patientAccessToken} />
      </TableCell>
      <TableCell>
        {DateTime.fromSeconds(assessment.createdAt).toLocaleString(
          DateTime.DATETIME_MED,
        )}
      </TableCell>
      <TableCell>
        {DateTime.fromSeconds(assessment.updatedAt).toLocaleString(
          DateTime.DATETIME_MED,
        )}
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="flex-end">
          {patientFacing &&
          DateTime.fromSeconds(assessment.patientAccessToken!!.expiresAt) <
            DateTime.now() ? (
            <ExpiredChip />
          ) : (
            <EditLink
              assessment={assessment}
              openModal={openPatientLinkModal}
            />
          )}
          <Link
            component="button"
            variant="body2"
            color="error"
            sx={{ marginLeft: 2 }}
            onClick={(_) => openDeletionModal(assessment.id)}
          >
            Delete
          </Link>
        </Box>
      </TableCell>
    </TableRow>
  );
};
