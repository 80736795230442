import { red } from "@mui/material/colors";

export const styles = {
  fieldName: {
    fontWeight: "bold",
    marginRight: 1,
  },
  button: {
    margin: 1,
  },
  danger: {
    backgroundColor: red[700],
    color: "white",
    "&:hover": {
      backgroundColor: red[300],
    },
  },
};
