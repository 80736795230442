import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

type Props = {
  children: string | React.ReactNode;
  severity?: "info" | "error" | "warning";
};

/**
 * Base component for a small toast.
 */
export default function GridToastBase(props: Props) {
  const { color, backgroundColor } =
    props.severity === "warning"
      ? { color: "#663c00", backgroundColor: "#fff4e5" }
      : props.severity === "error"
      ? { color: "#5f2120", backgroundColor: "#fdeded" }
      : // "info" is the default
        { color: "#014361", backgroundColor: "#e5f6fd" };

  return (
    <Box
      paddingY={1}
      paddingX={2}
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: 5,
        border: `1px solid ${grey[400]}`,
        color: color,
      }}
    >
      {typeof props.children === "string" ? (
        <Typography variant="body2" color="inherit">
          {props.children}
        </Typography>
      ) : (
        props.children
      )}
    </Box>
  );
}
