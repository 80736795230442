// @flow
import React, { useState } from "react";
import {
  Box,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Link,
  List,
  ListItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Breadcrumbs,
  Paper,
} from "@mui/material";
import { elationPortalBaseUrl, welkinPortalBaseUrl } from "../../../config";
import PatientInvite from "../PatientInvite";
import { useMutation } from "@apollo/client/react/hooks";
import { UPDATE_COMMUNICATION_PREFERENCES_MUTATION } from "../../../libs/queries";
import { PATIENT_INFO_QUERY } from "../../../libs/queries";
import DetailedAlert from "../../DetailedAlert";
import type { Patient } from "../../../generated/flow_types";
import LoadingButton from "@mui/lab/LoadingButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { maxPatientScreenWidth } from "../../../theme";

type Props = {
  patient: Patient,
};

const styles = {
  header: {
    fontWeight: "bold",
  },
};

export default function PatientInfo(props: Props) {
  const [
    communicationPreferencesEditable,
    makeCommunicationPreferencesEditable,
  ] = useState(false);
  const [consentsToSMS, setConsentsToSMS] = useState(
    props.patient.communicationPreferences.consentsToSMS,
  );
  const [consentsToEmail, setConsentsToEmail] = useState(
    props.patient.communicationPreferences.consentsToEmails,
  );

  const [
    updateCommunicationPreferences,
    { loading: internalLoading, error: internalError },
  ] = useMutation(UPDATE_COMMUNICATION_PREFERENCES_MUTATION, {
    onCompleted: () => {
      if (!internalError) {
        makeCommunicationPreferencesEditable(false);
      }
    },
    refetchQueries: [
      {
        query: PATIENT_INFO_QUERY,
        variables: { patientId: props.patient.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Box
      display="flex"
      flex={1}
      sx={{ overflowY: "auto" }}
      justifyContent="center"
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        flex={1}
        maxWidth={maxPatientScreenWidth}
      >
        <Snackbar open={!!internalError}>
          <Box>
            <DetailedAlert
              message="Oops! Failed to update communication preferences. Please try again."
              additionalDetails={internalError}
            />
          </Box>
        </Snackbar>
        <Breadcrumbs>
          <Link
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            component="button"
            color="inherit"
            underline="none"
          >
            <InfoOutlinedIcon sx={{ height: 18, width: 18 }} />
            Info
          </Link>
        </Breadcrumbs>
        <Paper
          sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 1 }}
        >
          <Typography variant="h6" color="primary">
            Patient Information
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={styles.header}>Patient Full Name</TableCell>
                <TableCell>{`${props.patient.firstName} ${props.patient.lastName}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles.header}>Primary Care Navigator</TableCell>
                <TableCell>{props.patient.primaryCareTeamStaffId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles.header}>Risalto patient Id</TableCell>
                <TableCell>{props.patient.id}</TableCell>
              </TableRow>
              {props.patient.risaltoUserId ? (
                <TableRow>
                  <TableCell sx={styles.header}>
                    Motion MSK App User Id
                  </TableCell>
                  <TableCell>{props.patient.risaltoUserId}</TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell sx={styles.header}>
                    Invite to Motion MSK App
                    <br />
                    <Typography variant="caption">
                      (Only share with <strong>this</strong> patient)
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <PatientInvite patient={props.patient} />
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell sx={styles.header}>Welkin Patient Id</TableCell>
                <TableCell>
                  {props.patient.welkinPatientId}
                  {!!props.patient.welkinPatientId && (
                    <Link
                      href={`${welkinPortalBaseUrl()}/patient/${
                        props.patient.welkinPatientId
                      }`}
                      target="_blank"
                      sx={{ marginLeft: 1 }}
                    >
                      View in Welkin
                    </Link>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles.header}>Elation Patient Id</TableCell>
                <TableCell>
                  {props.patient.elationPatientId}
                  {!!props.patient.elationPatientId && (
                    <Link
                      href={`${elationPortalBaseUrl()}/patient/${
                        props.patient.elationPatientId
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ marginLeft: 1 }}
                    >
                      View in Elation
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>

        <Paper
          sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 1 }}
        >
          <Typography variant="h6" color="primary">
            Communication Preferences{" "}
            {communicationPreferencesEditable ? (
              <Button
                onClick={() => {
                  setConsentsToSMS(
                    props.patient.communicationPreferences.consentsToSMS,
                  );
                  setConsentsToEmail(
                    props.patient.communicationPreferences.consentsToEmails,
                  );
                  makeCommunicationPreferencesEditable(false);
                }}
              >
                CANCEL
              </Button>
            ) : (
              <Button
                onClick={() => makeCommunicationPreferencesEditable(true)}
              >
                EDIT
              </Button>
            )}
          </Typography>
          <List>
            <ListItem>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!communicationPreferencesEditable}
                      checked={consentsToSMS}
                      onChange={(_) => {
                        setConsentsToSMS(!consentsToSMS);
                      }}
                    />
                  }
                  label="Receive SMS messages"
                />
              </FormGroup>
            </ListItem>
            <ListItem>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!communicationPreferencesEditable}
                      checked={consentsToEmail}
                      onChange={(_) => {
                        setConsentsToEmail(!consentsToEmail);
                      }}
                    />
                  }
                  label="Receive emails"
                />
              </FormGroup>
            </ListItem>
            {communicationPreferencesEditable && (
              <ListItem>
                <LoadingButton
                  loading={internalLoading}
                  onClick={() => {
                    updateCommunicationPreferences({
                      variables: {
                        patientId: props.patient.id,
                        consentsToSMS: consentsToSMS,
                        consentsToEmail: consentsToEmail,
                      },
                    });
                  }}
                >
                  Submit
                </LoadingButton>
              </ListItem>
            )}
          </List>
        </Paper>
      </Box>
    </Box>
  );
}
