export function env(key: string, defaultValue?: string): string | undefined {
  if (
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_AMPLIFY !== "true"
  ) {
    // The docker file creates a config.js file which initializes window.config
    // with values from .env file.
    // See index.html for the script tag that loads config.js.
    // @ts-ignore
    return window.config[key] || defaultValue;
  } else {
    return process.env[key] || defaultValue;
  }
}

export function envBool(key: string, defaultValue: boolean): boolean {
  const strValue = env(key);
  if (strValue === "true") {
    return true;
  } else if (strValue === "false") {
    return false;
  } else {
    if (strValue !== undefined) {
      console.error("Unexpected value in envBool for key", key);
    }
    return defaultValue;
  }
}

export function serverHttpUrl() {
  return serverUrl("http");
}

function serverUrl(insecureScheme: "http" | "ws") {
  const scheme =
    insecureScheme + (envBool("REACT_APP_SERVER_SECURE", false) ? "s" : "");

  const server = env("REACT_APP_SERVER", "localhost:8080");
  return `${scheme}://${server}`;
}

function environmentName(): EnvName {
  const envNameStr = env("REACT_APP_ENV");
  return EnvName[envNameStr as keyof typeof EnvName] || EnvName.DEV;
}

enum EnvName {
  DEV = "DEV",
  TEST = "TEST",
  PROD = "PROD",
}

// Below are environment-aware values kept in code.
export function welkinPortalBaseUrl(): string {
  return getEnvAwareValue({
    [EnvName.DEV]: "https://care.live.welkincloud.io/risalto/dev",
    [EnvName.TEST]: "https://care.live.welkincloud.io/risalto/sandbox",
    [EnvName.PROD]: "https://care.live.welkincloud.io/risalto/prod",
  });
}

export function elationPortalBaseUrl(): string {
  return getEnvAwareValue({
    [EnvName.DEV]: "https://sandbox.elationemr.com",
    [EnvName.TEST]: "https://sandbox.elationemr.com",
    [EnvName.PROD]: "https://app.elationemr.com",
  });
}

export function motionmskAppBaseUrl(): string {
  return getEnvAwareValue({
    [EnvName.DEV]: "http://localhost:19006",
    [EnvName.TEST]: "https://app.test.motionmsk.com",
    [EnvName.PROD]: "https://app.motionmsk.com",
  });
}

export function formsBaseUrl(): string {
  return getEnvAwareValue({
    [EnvName.DEV]: "http://localhost:3001",
    [EnvName.TEST]: "https://forms.test.motionmsk.com",
    [EnvName.PROD]: "https://forms.motionmsk.com",
  });
}

function getEnvAwareValue(envToValueMap: { [key in EnvName]: any }) {
  return envToValueMap[environmentName()];
}

// TODO(PFA-1219) retire
export function enableNewBooking(): boolean {
  return true;
}
