import { SearchParams } from "../../../../../../libs/booking";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { graphql } from "../../../../../../gql";
import {
  AppointmentMedium,
  MotionUser,
  ProviderAvailability,
  TargetWeek,
} from "../../../../../../gql/graphql";
import { useQuery } from "@apollo/client/react/hooks";
import SelectStaff from "../../../../../SelectStaff";
import { useEffect, useState } from "react";
import { isEmpty, keyBy, keys, sortBy } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import DetailedAlert from "../../../../../DetailedAlert";
import { ApolloError } from "@apollo/client";
import { useCachedMotionStaff } from "../../../../../../hooks/commonQueries";

type Props = {
  patientId: string;
  searchParameters: SearchParams;
  week: TargetWeek;
  selectedStaffId: string | null;
  onSelect: (staffId: string) => void;
};

graphql(`
  fragment ProviderAvailabilityFields on ProviderAvailability {
    id
    staffId
    availableSlots {
      startTime
      endTime
      locationId
    }
  }
`);

const GET_MATCHING_PROVIDERS = graphql(`
  query GetMatchingProviders(
    $week: TargetWeek!
    $appointmentType: AppointmentType!
    $medium: AppointmentMedium!
    $locationIds: [String!]!
    $patientId: String!
  ) {
    matchingProviders(
      week: $week
      appointmentType: $appointmentType
      medium: $medium
      locationIds: $locationIds
      patientId: $patientId
    ) {
      ...ProviderAvailabilityFields
    }
  }
`);

/**
 * Component to browse and select a staff member.
 */
export default function StaffMemberBrowser(props: Props) {
  const [visibleStaff, setVisibleStaff] = useState<ReadonlySet<string>>(
    !!props.selectedStaffId ? new Set([props.selectedStaffId]) : new Set(),
  );
  const [availabilityByStaffId, setAvailabilityByStaffId] = useState<Record<
    string,
    ProviderAvailability
  > | null>(null);
  const [addingProvider, setAddingProvider] = useState<boolean>(false);
  const { data, loading, error, refetch } = useQuery(GET_MATCHING_PROVIDERS, {
    variables: {
      week: props.week,
      appointmentType: props.searchParameters.appointmentType,
      medium: props.searchParameters.medium,
      locationIds:
        props.searchParameters.medium === AppointmentMedium.InClinic
          ? Array.from(props.searchParameters.clinicIds)
          : [],
      patientId: props.patientId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  // Update availability when the query state changes.
  // We use state to keep the availability info when the query is reloading.
  useEffect(() => {
    if (error) {
      // Clear availability so that all providers remain visible in the "Other"
      // section.
      setAvailabilityByStaffId({});
    }

    if (data?.matchingProviders) {
      const sortedByAvailability = sortBy(
        data.matchingProviders,
        (matching) => -matching.availableSlots.length,
      );
      setAvailabilityByStaffId(
        keyBy(sortedByAvailability, (matching) => matching.staffId),
      );
    }
  }, [data, loading, error]);

  // Add new matching providers to the list of visible providers.
  // This keeps the list of providers from changing week to week.
  useEffect(() => {
    setVisibleStaff(
      (prev) => new Set([...Array.from(prev), ...keys(availabilityByStaffId)]),
    );
  }, [availabilityByStaffId]);

  const staffById = useCachedMotionStaff();

  const handleAddStaff = (staffId: string | null) => {
    setAddingProvider(false);
    if (staffId) {
      props.onSelect(staffId);
      setVisibleStaff((prev) => new Set(prev).add(staffId));
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="body1" fontWeight="bold">
          Best Matches
        </Typography>
        {loading && <CircularProgress size={16} />}
      </Box>
      <MatchingProvidersList
        availabilityByStaffId={availabilityByStaffId}
        staffById={staffById}
        selectedStaffId={props.selectedStaffId}
        loading={loading}
        error={error}
        refetchMatchingProviders={refetch}
        onSelect={props.onSelect}
      />
      <Divider sx={{ marginY: 1 }} />
      <Typography variant="body1" fontWeight="bold">
        Other
      </Typography>
      <List>
        {Array.from(visibleStaff)
          .filter(
            (staffId) =>
              !!availabilityByStaffId && !(staffId in availabilityByStaffId),
          )
          .sort((a, b) =>
            staffById[a].displayName.localeCompare(staffById[b].displayName),
          )
          .map((staffId) => (
            <StaffMemberRow
              key={staffId}
              staff={staffById[staffId]}
              selected={staffId === props.selectedStaffId}
              onClick={() => props.onSelect(staffId)}
            />
          ))}
      </List>
      {addingProvider ? (
        <SelectStaff
          value={null}
          onChange={handleAddStaff}
          shouldDisableStaffMember={(staffId) => visibleStaff.has(staffId)}
        />
      ) : (
        <Button
          variant="outlined"
          size="small"
          onClick={() => setAddingProvider(true)}
          sx={{ alignSelf: "flex-start" }}
          startIcon={<AddIcon />}
        >
          Add Provider
        </Button>
      )}
    </Box>
  );
}

type MatchingProvidersListProps = {
  availabilityByStaffId: Record<string, ProviderAvailability> | null;
  staffById: Record<string, MotionUser>;
  selectedStaffId: string | null;
  loading: boolean;
  error: ApolloError | undefined;
  refetchMatchingProviders: () => void;
  onSelect: (staffId: string) => void;
};
/**
 * Displays the list of matching providers.
 */
function MatchingProvidersList(props: MatchingProvidersListProps) {
  if (props.error) {
    return (
      <DetailedAlert
        message="Something went wrong."
        additionalDetails={props.error}
        retry={() => props.refetchMatchingProviders()}
      />
    );
  }

  const availabilityByStaffId = props.availabilityByStaffId;
  if (availabilityByStaffId === null) {
    return (
      <Box p={1}>
        <Typography variant="body2" color="text.secondary">
          Loading availability...
        </Typography>
      </Box>
    );
  }

  if (isEmpty(availabilityByStaffId)) {
    return (
      <Box p={1}>
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: props.loading ? "#d3d3d3" : theme.palette.text.secondary,
          })}
        >
          No matching providers found.
        </Typography>
      </Box>
    );
  }

  return (
    <List>
      {keys(availabilityByStaffId).map((staffId) => (
        <StaffMemberRow
          key={staffId}
          staff={props.staffById[staffId]}
          availableSlots={availabilityByStaffId[staffId].availableSlots.length}
          loadingAvailability={props.loading}
          selected={staffId === props.selectedStaffId}
          onClick={() => props.onSelect(staffId)}
        />
      ))}
    </List>
  );
}

type StaffMemberRowProps = {
  staff: MotionUser;
  selected: boolean;
  onClick: () => void;
  availableSlots?: number;
  loadingAvailability?: boolean;
};
/**
 * Displays a selectable staff member.
 */
export function StaffMemberRow(props: StaffMemberRowProps) {
  return (
    <Tooltip
      title={props.staff.displayName}
      enterDelay={1000}
      enterNextDelay={1000}
      placement="left"
    >
      <ListItemButton
        selected={props.selected}
        onClick={props.onClick}
        dense
        sx={{ padding: 1 }}
      >
        <Box display="flex" alignItems="center" gap={1} minWidth={0}>
          <Avatar
            src={props.staff.avatarUrl || undefined}
            sx={{ width: 30, height: 30, fontSize: 14 }}
          >
            {props.staff.displayName
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </Avatar>
          <Box display="flex" flexDirection="column" minWidth={0}>
            <Box display="flex" alignItems="baseline" gap={1} minWidth={0}>
              <Typography
                variant="body1"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {props.staff.displayName}
              </Typography>
              {!!props.staff.credentialsAbbreviation && (
                <Typography variant="caption" color="textSecondary">
                  {props.staff.credentialsAbbreviation}
                </Typography>
              )}
            </Box>
            {props.availableSlots !== undefined && (
              <Typography
                variant="body2"
                sx={(theme) => ({
                  color: props.loadingAvailability
                    ? "#d3d3d3"
                    : theme.palette.text.secondary,
                })}
              >
                {props.availableSlots} available{" "}
                {props.availableSlots === 1 ? "slot" : "slots"}
              </Typography>
            )}
          </Box>
        </Box>
      </ListItemButton>
    </Tooltip>
  );
}
