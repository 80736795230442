import { graphql } from "../../gql";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client/react/hooks";
import DetailedAlert from "../DetailedAlert";
import { keyBy, keys, sortBy } from "lodash";
import React, { useMemo } from "react";
import { SxProps } from "@mui/system";

type BaseProps = {
  size?: "small" | "medium";
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  shouldDisableClinic?: (clinicId: string) => boolean;
};

type SingleSelectProps = BaseProps & {
  multiple: false;
  selectedClinicId: string | null;
  onSelectedClinicChange: (clinicId: string | null) => void;
};

type MultiSelectProps = BaseProps & {
  multiple: true;
  selectedClinicIds: string[];
  onSelectedClinicChange: (clinicIds: string[]) => void;
};

type Props = SingleSelectProps | MultiSelectProps;

export const GET_CLINICS = graphql(`
  query getClinics {
    clinics {
      id
      name
      addressLines
    }
  }
`);

/**
 * Generic component to select clinics.
 * Supports both single and multi-select.
 */
export default function SelectClinic(props: Props) {
  const { data, loading, error, refetch } = useQuery(GET_CLINICS, {
    notifyOnNetworkStatusChange: true,
  });

  const clinicsById = useMemo(() => {
    const sortedClinics = sortBy(data?.clinics, (clinic) => clinic.name);
    return keyBy(sortedClinics, (clinic) => clinic.id);
  }, [data]);

  if (loading) {
    return (
      <Select
        value="loading"
        disabled
        size={props.size}
        fullWidth={props.fullWidth}
        sx={props.sx}
      >
        <MenuItem value="loading">
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body1">Loading…</Typography>
            <CircularProgress size={18} color="inherit" />
          </Box>
        </MenuItem>
      </Select>
    );
  }

  if (error) {
    return (
      <Box>
        <DetailedAlert
          message="Oops! Failed to load clinics. Please try again."
          additionalDetails={error}
          retry={() => refetch()}
        />
      </Box>
    );
  }

  const shouldDisableClinic = (clinicId: string) => {
    return props.shouldDisableClinic?.(clinicId) || false;
  };

  const handleChange = (
    _event: React.SyntheticEvent,
    value: string | string[] | null,
  ) => {
    if (props.multiple) {
      if (value !== null && !Array.isArray(value)) {
        console.error("Expected multiple values but got a single value");
        return;
      }
      props.onSelectedClinicChange((value || []) as string[]);
      return;
    }

    if (value !== null && typeof value !== "string") {
      console.error(`Expected single string value but got ${value}`);
      return;
    }
    props.onSelectedClinicChange(value);
  };

  return (
    <Autocomplete
      multiple={props.multiple}
      options={keys(clinicsById)}
      autoHighlight
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.multiple ? "Clinics" : "Clinic"}
          error={props.error}
          helperText={props.helperText}
          fullWidth
        />
      )}
      getOptionLabel={(clinicId) => clinicsById[clinicId]?.name || clinicId}
      renderOption={(props, clinicId) => {
        const clinic = clinicsById[clinicId];
        return (
          <MenuItem
            {...props}
            value={clinicId}
            disabled={shouldDisableClinic(clinicId)}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography>{clinic.name}</Typography>
              <Typography variant="caption" color="text.secondary">
                {clinic.addressLines.join(", ")}
              </Typography>
            </Box>
          </MenuItem>
        );
      }}
      value={props.multiple ? props.selectedClinicIds : props.selectedClinicId}
      onChange={handleChange}
      size={props.size}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      sx={props.sx}
      componentsProps={{ popper: { style: { width: "fit-content" } } }}
    />
  );
}
