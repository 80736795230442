import { makeVar } from "@apollo/client";
import { useReactiveVar } from "@apollo/client/react/hooks";

export enum ExperimentalFeature {
  patientRecords = "patientRecords",
  betaAssessments = "betaAssessments",
  showOldOrderSuggestions = "showOldOrderSuggestions",
}

type ExperimentalFeaturesHookResult = {
  checkExperimentalFeatureEnabled: (feature: ExperimentalFeature) => boolean;
  toggleExperimentalFeature: (feature: ExperimentalFeature) => void;
};

const loadFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("experimentalFeatures") || "{}");
  } catch (e) {
    console.log("Failed to load experimental features from local storage", e);
    return {};
  }
};

const experimentFeaturesVar = makeVar<{
  [key in ExperimentalFeature]: boolean;
}>(loadFromLocalStorage());

export const useExperimentalFeatures = (): ExperimentalFeaturesHookResult => {
  const experimentalFeatures = useReactiveVar(experimentFeaturesVar);
  const toggleExperimentalFeature = (feature: ExperimentalFeature) => {
    const newFeatures = {
      ...experimentalFeatures,
      [feature]: !experimentalFeatures[feature],
    };
    localStorage.setItem("experimentalFeatures", JSON.stringify(newFeatures));
    experimentFeaturesVar(newFeatures);
  };

  const checkExperimentalFeatureEnabled = (feature: ExperimentalFeature) => {
    return experimentalFeatures[feature];
  };

  return {
    checkExperimentalFeatureEnabled,
    toggleExperimentalFeature,
  };
};
