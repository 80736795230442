import { ScheduleFieldsFragment } from "../../../gql/graphql";
import { graphql } from "../../../gql";
import { useQuery } from "@apollo/client/react/hooks";
import { Reference, StoreObject, useMutation } from "@apollo/client";
import ScheduleConflicts from "../ScheduleConflicts";
import { useHistory } from "react-router-dom";

type Props = {
  schedule: ScheduleFieldsFragment;
};

const CONFLICTS_ON_DELETE = graphql(`
  query ConflictsOnDelete($scheduleId: String!) {
    conflictsOnDelete(scheduleId: $scheduleId) {
      ...SchedulingConflictFields
    }
  }
`);

const DELETE_PUBLISHED_SCHEDULE = graphql(`
  mutation DeleteSchedule(
    $scheduleId: String!
    $ignoreConflictsOn: [String!]!
  ) {
    deleteSchedule(
      scheduleId: $scheduleId
      ignoreConflictsOn: $ignoreConflictsOn
    )
  }
`);

export default function DeletePublishedSchedule(props: Props) {
  const history = useHistory();
  const { data, loading, error, refetch } = useQuery(CONFLICTS_ON_DELETE, {
    variables: { scheduleId: props.schedule.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const [deleteSchedule, { error: deleteError, loading: deleteLoading }] =
    useMutation(DELETE_PUBLISHED_SCHEDULE, {
      update(cache) {
        // Evict by id
        cache.evict({ id: cache.identify(props.schedule) });
        // Remove from allSchedules
        cache.modify({
          fields: {
            allSchedules(existingSchedules, { readField }) {
              return existingSchedules.filter(
                (scheduleRef: Reference | StoreObject | undefined) =>
                  props.schedule.id !== readField("id", scheduleRef),
              );
            },
          },
        });
      },
    });

  const handleDelete = async (ignoredConflicts: ReadonlySet<string>) => {
    try {
      await deleteSchedule({
        variables: {
          scheduleId: props.schedule.id,
          ignoreConflictsOn: Array.from(ignoredConflicts),
        },
      });
      history.push(`/schedules`);
    } catch (e) {
      // Most likely a new conflict. Refetch automatically.
      await refetch();
    }
  };

  return (
    <ScheduleConflicts
      operation="delete"
      scheduleId={props.schedule.id}
      conflictsQueryResults={{
        conflicts: data?.conflictsOnDelete,
        loading,
        error,
        refetch,
      }}
      onSubmit={handleDelete}
      submitLoading={deleteLoading}
      submitError={deleteError}
    />
  );
}
