// @flow
import React, { PureComponent } from "react";
import { isMember } from "../utils";
import styles from "../theme";

type Props = {
  message: Object
};

class LinkMessageRow extends PureComponent<Props> {
  render() {
    const { message } = this.props;
    const data = JSON.parse(message.data);

    const style = isMember(message) ? styles.other : styles.me;
    return (
      <div style={style.bubble}>
        <div style={{ ...style.text, paddingBottom: "0px" }}>
          {message.message}
        </div>
        <hr style={styles.link.line} />
        <div style={styles.link.text}>{data.text}</div>
      </div>
    );
  }
}

export default LinkMessageRow;
