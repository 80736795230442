import { AwsRum } from "aws-rum-web";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

let awsRum: AwsRum | null = null;

export function initRum() {
  try {
    const guestRoleArn = process.env.REACT_APP_RUM_GUEST_ROLE_ARN;
    const identityPoolId = process.env.REACT_APP_RUM_IDENTITY_POOL_ID;
    const appMonitorId = process.env.REACT_APP_RUM_APP_MONITOR_ID;

    if (!guestRoleArn || !identityPoolId || !appMonitorId) {
      console.warn("Missing environment variables for RUM, skipping setup");
      return;
    }

    const config = {
      sessionSampleRate: 1,
      guestRoleArn: guestRoleArn,
      identityPoolId: identityPoolId,
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      disableAutoPageView: true,
    };
    awsRum = new AwsRum(appMonitorId, "1.0.0", "us-east-1", config);
  } catch (error) {
    console.log("Error setting up RUM", error);
  }
}

export const useRecordPageView = () => {
  const location = useLocation();
  useEffect(() => {
    // Strip the URL of UUIDs and patient IDs before recording the page view.
    const sanitized = location.pathname
      .replace(/\/patient\/[^/]+/, "/patient/:patientId")
      .replace(/\/[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}/g, "/:uuid");
    awsRum?.recordPageView(sanitized);
  }, [location.pathname]);
};

export const useLazyRecordEvent = (): ((
  eventType: string,
  eventData: object,
) => void) => {
  return (eventType: string, eventData: object) =>
    awsRum?.recordEvent(eventType, eventData);
};
