import {
  AppointmentStaff,
  SearchParams,
  SlotSelection,
} from "../../../../../libs/booking";
import { MotionTimezone } from "../../../../../libs/time";
import { Box } from "@mui/material";
import StaffBrowser from "../StaffBrowser";
import { TargetWeek } from "../../../../../gql/graphql";
import WeekSelector from "../WeekSelector";
import StaffScheduleGrid from "../StaffScheduleGrid";
import ReadOnlyStaffBrowser from "../ReadOnlyStaffBrowser";

type BaseProps = {
  patientId: string;
  timezone: MotionTimezone;
  searchParameters: SearchParams;
  selectedWeek: TargetWeek;
  onSelectWeek: (week: TargetWeek) => void;
  onSelectSlot: (selection: SlotSelection) => void;
};

type CreateAppointmentProps = BaseProps & {
  mode: "create";
  selectedStaff: AppointmentStaff | null;
  onSelectStaff: (staff: AppointmentStaff) => void;
};

type RescheduleAppointmentProps = BaseProps & {
  mode: "reschedule";
  selectedStaff: AppointmentStaff;
  rescheduledAppointmentId: string;
};

type Props = CreateAppointmentProps | RescheduleAppointmentProps;

/**
 * Main component for browsing availability for a given search,
 * across multiple days and multiple providers (unless restricted.)
 */
export default function AvailabilityBrowser(props: Props) {
  return (
    <Box display="grid" gridTemplateColumns="260px 1fr" flex={1} minHeight={0}>
      <Box
        minHeight={0}
        pr={1}
        sx={(theme) => ({
          overflowY: "auto",
          borderRight: `1px solid ${theme.palette.divider}`,
        })}
      >
        {props.mode === "create" ? (
          <StaffBrowser
            patientId={props.patientId}
            searchParameters={props.searchParameters}
            week={props.selectedWeek}
            selectedStaff={props.selectedStaff}
            onSelect={props.onSelectStaff}
          />
        ) : (
          <ReadOnlyStaffBrowser
            patientId={props.patientId}
            searchParameters={props.searchParameters}
            week={props.selectedWeek}
            selectedStaff={props.selectedStaff}
          />
        )}
      </Box>
      <Box display="flex" flexDirection="column" minHeight={0}>
        <WeekSelector
          week={props.selectedWeek}
          onChange={props.onSelectWeek}
          timezone={props.timezone}
        />
        <StaffScheduleGrid
          timezone={props.timezone}
          staff={props.selectedStaff}
          searchParameters={props.searchParameters}
          week={props.selectedWeek}
          patientId={props.patientId}
          onSelect={props.onSelectSlot}
          rescheduledAppointmentId={
            props.mode === "reschedule"
              ? props.rescheduledAppointmentId
              : undefined
          }
        />
      </Box>
    </Box>
  );
}
