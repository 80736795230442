import { SearchParams } from "../../../../../libs/booking";
import { AppointmentMedium } from "../../../../../gql/graphql";
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import SelectClinic from "../../../../SelectClinic";
import SelectAppointmentType from "../../../../SelectAppointmentType";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import { getMediumName } from "../../../../../libs/scheduling";

type Props = {
  params: Partial<SearchParams>;
  onChange: (params: Partial<SearchParams>) => void;
};

/**
 * Allows the user to select search parameters a new appointment.
 */
export default function NewAppointmentParamSelector(props: Props) {
  const handleMediumChange = (medium: AppointmentMedium) => {
    props.onChange({
      ...props.params,
      medium,
      clinicIds:
        medium !== AppointmentMedium.InClinic
          ? new Set()
          : props.params.clinicIds,
    });
  };

  const handleMultiDisciplinaryChange = (multiDisciplinary: boolean) => {
    // Phone is not supported for multidisciplinary appointments.
    const newMedium =
      multiDisciplinary && props.params.medium === AppointmentMedium.Phone
        ? undefined
        : props.params.medium;
    props.onChange({
      ...props.params,
      medium: newMedium,
      multiDisciplinary,
    });
  };

  return (
    <Box
      display="grid"
      gap={2}
      gridTemplateColumns="minmax(auto, 400px) minmax(auto, 500px)"
    >
      {/* TODO(PFA-1167) shouldDisableType not needed once duration is non-nullable */}
      <SelectAppointmentType
        beta={true}
        value={props.params.appointmentType || null}
        onChange={(value) =>
          props.onChange({ ...props.params, appointmentType: value })
        }
        shouldDisableType={(config) => !config.durationInMinutes}
      />
      <Box />
      <FormControl fullWidth>
        <InputLabel id="medium-label">How will the patient join?</InputLabel>
        <Select
          labelId="medium-label"
          label="How will the patient join?"
          value={props.params.medium || ""}
          onChange={(e) =>
            handleMediumChange(e.target.value as AppointmentMedium)
          }
        >
          {[
            AppointmentMedium.InClinic,
            AppointmentMedium.Virtual,
            AppointmentMedium.Phone,
            AppointmentMedium.AtHome,
          ].map((medium) => (
            <MenuItem
              key={medium}
              value={medium}
              disabled={
                props.params.multiDisciplinary &&
                medium === AppointmentMedium.Phone
              }
              sx={{ display: "flex", alignItems: "baseline", gap: 1 }}
            >
              {getMediumName(medium)}
              {props.params.multiDisciplinary &&
                medium === AppointmentMedium.Phone && (
                  <Typography variant="caption">
                    (not supported for multi-disciplinary booking)
                  </Typography>
                )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {props.params.medium === AppointmentMedium.InClinic ? (
        <Box>
          <SelectClinic
            fullWidth
            multiple={true}
            selectedClinicIds={Array.from(props.params.clinicIds || [])}
            onSelectedClinicChange={(value) =>
              props.onChange({
                ...props.params,
                clinicIds: new Set(value),
              })
            }
          />
        </Box>
      ) : (
        <Box />
      )}
      <Box display="flex" gap={1}>
        <FormControlLabel
          control={
            <Switch
              checked={props.params.multiDisciplinary || false}
              onChange={(_, checked) => handleMultiDisciplinaryChange(checked)}
            />
          }
          label="Multi-disciplinary"
        />
        <Tooltip
          arrow
          placement="bottom"
          title="Select this option if two providers are needed for this appointment."
        >
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
