import { ScheduleFieldsFragment } from "../../../../gql/graphql";
import { LoadingButton } from "@mui/lab";
import { useQuery } from "@apollo/client/react/hooks";
import { ALL_SCHEDULES_QUERY } from "../../ScheduleDraftEditor/ViewPublishedScheduleButton";
import { find } from "lodash";
import OpenCreateDraftButton from "./OpenCreateDraftButton";
import { DateTime } from "luxon";
import { Box } from "@mui/material";
import DeleteUpcomingScheduleButton from "./DeleteUpcomingScheduleButton";
import DetailedAlert from "../../../DetailedAlert";

type Props = {
  publishedSchedule: ScheduleFieldsFragment;
};

/**
 * Buttons to:
 * - create or open a draft from the given published schedule.
 * - delete the published schedule (only if upcoming.)
 */
export default function PublishedScheduleActions(props: Props) {
  const { data, loading, error, refetch } = useQuery(ALL_SCHEDULES_QUERY);

  if (error) {
    return (
      <DetailedAlert
        message="Oops! Something went wrong."
        additionalDetails={error}
        retry={() => refetch()}
      />
    );
  }

  if (loading) {
    return (
      <Box display="flex" gap={1} alignItems="center">
        <LoadingButton variant="contained" size="small" loading={true}>
          Create Draft
        </LoadingButton>
        <LoadingButton variant="contained" size="small" loading={true}>
          Delete
        </LoadingButton>
      </Box>
    );
  }

  const existingDraft = find(
    data?.allSchedules,
    (schedule) =>
      schedule.takesEffectDate === props.publishedSchedule.takesEffectDate &&
      schedule.staff.id === props.publishedSchedule.staff.id &&
      schedule.isDraft,
  );

  const scheduleIsUpcoming =
    DateTime.fromISO(props.publishedSchedule.takesEffectDate) > DateTime.now();

  return (
    <Box display="flex" gap={1} alignItems="center">
      <OpenCreateDraftButton
        publishedSchedule={props.publishedSchedule}
        existingDraftScheduleId={existingDraft?.id || null}
      />
      <DeleteUpcomingScheduleButton
        upcomingScheduleId={
          scheduleIsUpcoming ? props.publishedSchedule.takesEffectDate : null
        }
      />
    </Box>
  );
}
